import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Card, Box, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import UserSelect from "../../components/UserSelect";
//---------------------------issue{No.600} start-----------------------------
import ClientSelect from "../../components/ClientSelect";
import ReportDataTable from "./ReportDataTable";
//---------------------------issue{No.600} end-------------------------------
import DateSearch from "../../components/DateSearch";
import BackButton from "../../components/BackButton";
import BackHomeButton from "../../components/BackHomeButton";
import { UserContext } from "../../providers/UserProvider";
import DataTable from "../../components/DataTable";
//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
        //---------------------------issue{No.147} start-----------------------------
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        buttonHeight: {
            height: "37px",
            marginBottom: "0",
            lineHeight: "37px",
        },
        //---------------------------issue{No.147} end-------------------------------
        //---------------------------issue{No.600} start-----------------------------
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        //---------------------------issue{No.600} end-------------------------------
    })
);

//見出しの配列定義
const headers = ["日付", "編集"]; // 日別日報一覧

// Issue#147
// const columns = ["date"];

// 初期状態
const initReportsDateList = { result: [], success: true };

function Report() {
    //---------------------------issue{No.147} start-----------------------------
    // ログインしているユーザーのid（idカラムの数値）を取得
    const { userInfo } = useContext(UserContext);
    const userId = userInfo.user.id;
    const userAuthority = userInfo.user.authority;
    //---------------------------issue{No.147} end-------------------------------

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の職員のId
    const { user } = useParams();

    //---------------------------issue{No.147} start-----------------------------
    // user の人数を管理する
    const [usersCount, setUsersCount] = useState(0);
    //---------------------------issue{No.147} end-------------------------------

    // 選択された職員のidを格納するステート
    const [selectedUser, setSelectedUser] = useState("");

    //report の状態を管理する
    const [reportsDateList, setReportsDateList] = useState(initReportsDateList);

    //---------------------------issue{No.147} start-----------------------------
    // データ取得が実行済みであることを保存する
    const [loadComplete, setLoadComplete] = useState(false);
    //---------------------------issue{No.147} end-------------------------------

    // 検索された情報一覧を保存するステート
    const [searchedReports, setSearchedReports] = useState([]);

    const [userName, setUserName] = useState("");

    //report を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //---------------------------issue{No.600} start-----------------------------
    // 選択された利用者のidを格納するステート
    const [selectedClient, setSelectedClient] = useState("");

    // 選択された利用者の名前を保持するステート
    const [clientName, setClientName] = useState("");
    //---------------------------issue{No.600} end-------------------------------

    const [clientSelectMode, setClientSelectMode] = useState(true);

    // const getDate = async (id) => {
    //     const url = `reports_get_date/${id}`;
    //     const res = await api.get(url);
    //     console.log(res);
    // };

    // getDate(6);

    useEffect(() => {
        //---------------------------issue{No.147} start-----------------------------
        if (user && !selectedUser) {
            // もし、他の職員のshowから、パラメータ付きのURLで、このコンポーネントへアクセスした時
            // 選択済み利用者をステートに保存
            setSelectedUser(user);
            // 日付での日報一覧表示
            setClientSelectMode(false);
        } else if (!user && !selectedUser && userId) {
            // ホームのメニューから日報の一覧を開いたときに
            // ログイン中のユーザーを選択済みとして扱う
            setSelectedUser(userId);
        }
        //---------------------------issue{No.147} end-------------------------------
    }, []);

    //---------------------------issue{No.147} start-----------------------------
    // Usersの情報を取得し人数をステートにセットする
    const getUsersCount = async () => {
        const res = await api.get("users");
        setUsersCount(res.data.result.length);
    };
    //---------------------------issue{No.147} end-------------------------------

    //---------------------------issue{No.600} start-----------------------------
    // 日報を取得しステート Report にセットする
    const getReportsDateList = async (id) => {
        const url = `reports_get_date/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setReportsDateList(res.data);
            setLoadComplete(true);
        }
    };
    //---------------------------issue{No.600} end-------------------------------

    // 担当職員ユーザの名前を取得しステート userName にセットする
    const getUserName = async (id) => {
        const url = `reports/user/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            const userResult = res.data.result;
            setUserName(userResult.user_name);
        }
    };

    // 選択した利用者の名前を取得しステート clientName にセットする
    const getClientName = async (id) => {
        const url = `reports/client/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            const name = clientResult.child_name ?? clientResult.client_name;
            setClientName(name);
        }
    };

    //---------------------------issue{No.600} start-----------------------------
    const handleOnClickChangeSelectMode = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setClientSelectMode(!clientSelectMode);
    };
    //---------------------------issue{No.600} end-------------------------------

    //---------------------------issue{No.600} start-----------------------------
    //利用者名クリック時、利用者情報表示画面へ
    const handleOnClickShow = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (selectedClient) {
            // URL(/client/show/${client.id})をたたく（RouterConfig.js）
            navigate(`/client_select/show/${selectedClient}`);
        }
    };
    //---------------------------issue{No.600} end-------------------------------

    //  登録ボタンクリック時
    const handleOnClickRegister = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(report_register)をたたく（RouterConfig.js）
        navigate(`/report_register/${selectedUser}`);
    };

    const handleClickClientRegister = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(report_register)をたたく（RouterConfig.js）
        navigate(`/report_register_client/${selectedClient}`);
    };

    // 一覧の行クリック時、日報表示画面へ
    const handleOnRowClick = (e, date) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行の日報のdateと一致するreportステートのアセスメント情報を変数に代入
        const report = reportsDateList.result.find((report) => report.date === date);
        // reportに値が存在する（0, "", undefined, nullでない）場合
        if (report) {
            // URL(/report/show/${report.date})をたたく（RouterConfig.js）
            navigate(`/report/show/${selectedUser}/${date}`);
        }
    };

    // 一覧の編集ボタンクリック時、日報編集画面へ
    const handleOnClickEdit = (e, date) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // URL(/report/edit/${selectedUser}/${date})をたたく（RouterConfig.js）
        navigate(`/report/edit/${selectedUser}/${date}`);
    };

    //---------------------------issue{No.147} start-----------------------------
    // 職員一覧の戻るボタンがクリックされたとき、選択済み職員をログインユーザーに切り替え、
    // レンダリングされるコンポーネントをログインユーザーの日報一覧に書き換える
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        setSearchedReports([]);
        setSelectedUser(userId);
        setSelectedClient("");
    };
    //---------------------------issue{No.147} end-------------------------------

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/home");
    };
    //---------------------------issue{No.539} end-------------------------------

    //---------------------------issue{No.147} start-----------------------------
    // 職員一覧ボタンがクリックされたとき、選択済み職員をリセットし、
    // レンダリングされるコンポーネントを書き換える
    const handleOnClickUserList = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        setClientSelectMode(false);
        setSearchedReports([]);
        setSelectedUser("");
        setReportsDateList(initReportsDateList);
    };
    //---------------------------issue{No.147} end-------------------------------

    //---------------------------issue{No.147} start-----------------------------
    // コンポーネント読み込み時に、職員の人数を取得する
    useEffect(() => {
        getUsersCount();
    }, []);
    //---------------------------issue{No.147} end-------------------------------

    //report に応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!searchedReports || !reportsDateList.success) {
            return;
        }

        const newRows = searchedReports.map((report) => {
            return {
                id: report.id,
                date: report.date,
                isActive: true,
                data: {
                    date: report.date,
                    editBtn:
                        selectedUser == userId ? (
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={(e) => handleOnClickEdit(e, report.date)}>
                                編集
                            </Button>
                        ) : (
                            <p className={classes.buttonHeight}>-</p>
                        ),
                },
            };
        });
        setRows(newRows);
    }, [searchedReports]);

    // 選択済み利用者の状態に応じて、データを取得
    useEffect(() => {
        if (selectedUser) {
            getReportsDateList(selectedUser);
            getUserName(selectedUser);

            //---------------------------issue{No.147} start-----------------------------
            // 選択済み利用者の状態に応じて、データを取得
            // もし、URLにuserパラメータが含まれていない場合に、アドレスバーを書き替える
            if (!user && selectedUser != userId) {
                const url = `${location.pathname}/${selectedUser}`;
                history.pushState(null, null, url);
            }
            //---------------------------issue{No.147} end-------------------------------
        }
    }, [selectedUser]);

    // 利用者が選択されたとき
    useEffect(() => {
        if (selectedClient) {
            getClientName(selectedClient);
        }
    }, [selectedClient]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                {loadComplete && (
                    <div className='card'>
                        {clientSelectMode ? (
                            <>
                                {/* -------------------- 以下、クライアント選択画面 -------------------- */}
                                {!selectedClient ? (
                                    <>
                                        {/* -------------------- issue539 start -------------------- */}
                                        <div className='card-header d-flex'>
                                            <h1 className={classes.title}>日報一覧（利用者表示）</h1>
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-secondary fs-3 lh-1'
                                                    onClick={(e) => handleOnClickClose(e)}>
                                                    ×
                                                </button>
                                            </div>
                                        </div>
                                        <Box className='card-body' sx={{ display: "flex" }}>
                                            <Box sx={{ width: "max-content", ml: "auto" }}>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={(e) => handleOnClickRegister(e)}>
                                                    日報登録
                                                </Button>
                                            </Box>
                                            <Box sx={{ width: "max-content", ml: 2 }}>
                                                <Button
                                                    variant='contained'
                                                    onClick={(e) => handleOnClickChangeSelectMode(e)}>
                                                    日付表示
                                                </Button>
                                            </Box>
                                        </Box>
                                        {/* -------------------- issue539 end -------------------- */}
                                        <ClientSelect setSelectedClient={setSelectedClient} />
                                        {/* -------------------- issue508 start -------------------- */}
                                        <div className='card-footer'>
                                            <BackHomeButton />
                                        </div>
                                        {/* -------------------- issue508 end -------------------- */}
                                    </>
                                ) : (
                                    // クライアント選択済み
                                    <>
                                        <div className='card-header d-flex'>
                                            <h1 className={classes.title}>
                                                日報一覧（
                                                <Tooltip title='利用者情報を確認する。' placement='right-end' arrow>
                                                    <span
                                                        className={classes.clientName}
                                                        onClick={(e) => {
                                                            handleOnClickShow(e);
                                                        }}>
                                                        {clientName}
                                                    </span>
                                                </Tooltip>
                                                ）
                                            </h1>
                                            {/* -------------------- issue539 start -------------------- */}
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-secondary fs-3 lh-1'
                                                    onClick={(e) => handleOnClickBack(e)}>
                                                    ×
                                                </button>
                                            </div>
                                            {/* -------------------- issue539 end -------------------- */}
                                        </div>
                                        <Card className={classes.card}>
                                            <Box className='card-body' sx={{ display: "flex" }}>
                                                <Box sx={{ width: "max-content", ml: "auto" }}>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={(e) => handleOnClickRegister(e)}>
                                                        日報登録
                                                    </Button>
                                                </Box>
                                                <Box sx={{ width: "max-content", ml: 2 }}>
                                                    <Button
                                                        variant='contained'
                                                        color='primary'
                                                        onClick={(e) => handleClickClientRegister(e)}>
                                                        利用者日報登録
                                                    </Button>
                                                </Box>
                                                <Box sx={{ width: "max-content", ml: 2 }}>
                                                    <Button
                                                        variant='contained'
                                                        onClick={(e) => handleOnClickChangeSelectMode(e)}>
                                                        日付表示
                                                    </Button>
                                                </Box>
                                            </Box>
                                            {/* <DateSearch
                                                data={proposedPlans_1.result}
                                                setSearchedData={setSearchedProposedPlans_1}
                                            /> */}
                                            {/* テーブル部分の定義 */}
                                            <ReportDataTable selectedClient={selectedClient} clientName={clientName} />
                                        </Card>
                                        <div className='card-footer'>
                                            <Button variant='contained' onClick={(e) => handleOnClickBack(e)}>
                                                戻る
                                            </Button>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                {/* -------------------- 以下、日付選択画面 -------------------- */}
                                {!selectedUser ? (
                                    <>
                                        <div className='card-header d-flex'>
                                            <h1 className={classes.title}>職員一覧</h1>
                                            {/* -------------------- issue539 start -------------------- */}
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-secondary fs-3 lh-1'
                                                    onClick={(e) => handleOnClickBack(e)}>
                                                    ×
                                                </button>
                                            </div>
                                            {/* -------------------- issue539 end -------------------- */}
                                        </div>
                                        <UserSelect setSelectedUser={setSelectedUser} />
                                        <div className='card-footer'>
                                            <Button variant='contained' onClick={(e) => handleOnClickBack(e)}>
                                                戻る
                                            </Button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {/* -------------------- issue539 start -------------------- */}
                                        {selectedUser == userId ? (
                                            <div className='card-header d-flex'>
                                                <h1 className={classes.title}>日報一覧（日付表示）</h1>
                                                <div className='ms-auto'>
                                                    <button
                                                        className='btn btn-outline-secondary fs-3 lh-1'
                                                        onClick={(e) => handleOnClickChangeSelectMode(e)}>
                                                        ×
                                                    </button>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='card-header d-flex'>
                                                <h1 className={classes.title}>職員の日報一覧（{userName}）</h1>
                                                <div className='ms-auto'>
                                                    <button
                                                        className='btn btn-outline-secondary fs-3 lh-1'
                                                        onClick={(e) => handleOnClickUserList(e)}>
                                                        ×
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                        {/* -------------------- issue539 end -------------------- */}
                                        <Box className='card-body' sx={{ display: "flex" }}>
                                            {selectedUser == userId && usersCount > 1 && userAuthority < 40 && (
                                                <Button variant='contained' onClick={(e) => handleOnClickUserList(e)}>
                                                    職員の日報を見る
                                                </Button>
                                            )}
                                            {selectedUser == userId && (
                                                <>
                                                    <Box sx={{ width: "max-content", ml: "auto" }}>
                                                        <Button
                                                            variant='contained'
                                                            color='primary'
                                                            onClick={(e) => handleOnClickRegister(e)}>
                                                            日報登録
                                                        </Button>
                                                    </Box>
                                                    <Box sx={{ width: "max-content", ml: 2 }}>
                                                        <Button
                                                            variant='contained'
                                                            onClick={(e) => handleOnClickChangeSelectMode(e)}>
                                                            利用者表示
                                                        </Button>
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                        {Object.keys(reportsDateList.result).length > 0 && (
                                            <Card className={classes.card}>
                                                <DateSearch
                                                    data={reportsDateList.result}
                                                    setSearchedData={setSearchedReports}
                                                />
                                                {/* テーブル部分の定義 */}
                                                <DataTable
                                                    headers={headers}
                                                    rows={rows}
                                                    handleOnRowClick={(e, row) => handleOnRowClick(e, row.date)}
                                                />
                                            </Card>
                                        )}
                                        <div className='card-footer'>
                                            {selectedUser === userId ? (
                                                <Button
                                                    variant='contained'
                                                    onClick={(e) => handleOnClickChangeSelectMode(e)}>
                                                    戻る
                                                </Button>
                                            ) : (
                                                <Button variant='contained' onClick={(e) => handleOnClickUserList(e)}>
                                                    戻る
                                                </Button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default Report;
