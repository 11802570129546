import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDataList, {rowEnclosureButton} from "../../components/ClientSelectToDataList";

// 見出しの配列定義
const headers = ['報告書作成日', '週間計画表'];

export default function Monitoring_1() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDataList
        pageName='モニタリング報告書'
        targetURL='monitoring_1'
        headerDataList={headers}
        clientID={client}
        accessibleDeleted={true}
        rowItems={rowData => rowEnclosureButton(rowData, 'monitoring_2', '週間計画表')} />;
}