import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";

import NewOutputExcelButton from "../../components/NewOutputExcelButton";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        tableLeft: {
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            width: "5%",
            paddingRight: "3px"
        },
        tableRight: {
            width: "93%"
        },
        timeList: {
            height: "1rem",
            lineHeight: "1rem"
        },
        cell: {
            height: "1rem",
            padding: "0 .5rem",
            boxShadow: "0 0 1px 0 #000 inset",
            backgroundClip: "content-box"
        },
        contentTitle: {
            wordBreak: "break-word",
            lineHeight: "1rem",
            textAlign: "center",
            fontSize: ".8rem",
            color: "#fff",
            textShadow: "1px 1px 2px #000",
            zIndex: "0"
        },
        bgColor1: { background: "#3d3dff", backgroundClip: "content-box" },
        bgColor2: { background: "#007b43", backgroundClip: "content-box" },
        bgColor3: { background: "#f8b500", backgroundClip: "content-box" },
        bgColor4: { background: "#e45e32", backgroundClip: "content-box" },
        bgColor5: { background: "#d3381c", backgroundClip: "content-box" },
        bgColor6: { background: "#68be8d", backgroundClip: "content-box" },
        bgColor7: { background: "#65318e", backgroundClip: "content-box" },
        bgColor8: { background: "#0095d9", backgroundClip: "content-box" },
        bgColor9: { background: "#ec6d71", backgroundClip: "content-box" },
        bgColor10: { background: "#ebd842", backgroundClip: "content-box" },
        bgColor11: { background: "#9e3d3f", backgroundClip: "content-box" }
    })
);

const day = ["月", "火", "水", "木", "金", "土", "日・祝"];

const timeList = {
    1: "5:00",
    2: "",
    3: "6:00",
    4: "",
    5: "7:00",
    6: "",
    7: "8:00",
    8: "",
    9: "9:00",
    10: "",
    11: "10:00",
    12: "",
    13: "11:00",
    14: "",
    15: "12:00",
    16: "",
    17: "13:00",
    18: "",
    19: "14:00",
    20: "",
    21: "15:00",
    22: "",
    23: "16:00",
    24: "",
    25: "17:00",
    26: "",
    27: "18:00",
    28: "",
    29: "19:00",
    30: "",
    31: "20:00",
    32: "",
    33: "21:00",
    34: "",
    35: "22:00",
    36: "",
    37: "23:00",
    38: "",
    39: "0:00",
    40: "",
    41: "1:00",
    42: "",
    43: "2:00",
    44: "",
    45: "3:00",
    46: "",
    47: "4:00",
    48: ""
};

// timeFieldステートの初期値作成用のテンプレート
const cellList = {
    1: { title: "", content: "" },
    2: { title: "", content: "" },
    3: { title: "", content: "" },
    4: { title: "", content: "" },
    5: { title: "", content: "" },
    6: { title: "", content: "" },
    7: { title: "", content: "" },
    8: { title: "", content: "" },
    9: { title: "", content: "" },
    10: { title: "", content: "" },
    11: { title: "", content: "" },
    12: { title: "", content: "" },
    13: { title: "", content: "" },
    14: { title: "", content: "" },
    15: { title: "", content: "" },
    16: { title: "", content: "" },
    17: { title: "", content: "" },
    18: { title: "", content: "" },
    19: { title: "", content: "" },
    20: { title: "", content: "" },
    21: { title: "", content: "" },
    22: { title: "", content: "" },
    23: { title: "", content: "" },
    24: { title: "", content: "" },
    25: { title: "", content: "" },
    26: { title: "", content: "" },
    27: { title: "", content: "" },
    28: { title: "", content: "" },
    29: { title: "", content: "" },
    30: { title: "", content: "" },
    31: { title: "", content: "" },
    32: { title: "", content: "" },
    33: { title: "", content: "" },
    34: { title: "", content: "" },
    35: { title: "", content: "" },
    36: { title: "", content: "" },
    37: { title: "", content: "" },
    38: { title: "", content: "" },
    39: { title: "", content: "" },
    40: { title: "", content: "" },
    41: { title: "", content: "" },
    42: { title: "", content: "" },
    43: { title: "", content: "" },
    44: { title: "", content: "" },
    45: { title: "", content: "" },
    46: { title: "", content: "" },
    47: { title: "", content: "" },
    48: { title: "", content: "" }
};

// timeFieldステートの初期値を作成する関数
// オブジェクトの関連付け（参照渡し）が残らないように処理
const lazyInitTimeField = () => {
    let initTimeField = {};
    for (let ii = 1; ii <= 7; ii++) {
        const newColumn = JSON.parse(JSON.stringify(cellList));
        initTimeField[ii] = newColumn;
    }
    return initTimeField;
};

// 初期状態
const initProposedPlan_1 = { result: [], success: true };

// Laravel側にデータを送る際にそのデータをまとめる入れ物用のオブジェクトのひな形
const initProposedPlan_2 = {
    data: []
};

function ProposedPlan_2Show() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、サービス等利用計画案情報のid
    const { client, id } = useParams();

    const pageName = "サービス等利用計画案・障害児支援利用計画案【週間計画表】";

    // 登録先のサービス等利用計画案情報を表示するためのステート
    const [proposedPlan_1, setProposedPlan_1] = useState(initProposedPlan_1);

    // データ取得が実行済みであることを保存する
    const [loadComplete, setLoadComplete] = useState(false);

    //proposedPlan_2 の状態を管理する
    const [proposedPlan_2, setProposedPlan_2] = useState(initProposedPlan_2);

    // 選択された利用者のidを格納するステート
    const [selectedClient, setSelectedClient] = useState(client);

    // React上で表示する各セルの状態を管理するためのステート
    const [timeField, setTimeField] = useState(lazyInitTimeField);

    const [downloadFileName, setDownloadFileName] = useState(pageName);

    // makeStyleで作成したセルの色付け用のCSSクラスの呼び出しに使う数値を保持するRefオブジェクト
    // 変数にしてもいいかもしれないが・・・
    const bgColor = useRef(1);

    // makeStyleで作成済みのセルの色に関するCSSクラスを
    // 作成済みの内容のcontentの値と紐づけて保管するRefオブジェクト
    const colorClasses = useRef({});

    //---------------------------issue{No.325} start-----------------------------
    const [downloadState, setDownloadState] = useState("");
    //---------------------------issue{No.325} end-------------------------------

    // 登録先のサービス等利用計画案情報を取得しステート proposedPlan_1 にセットする
    const getProposedPlan_1Data = async (id) => {
        const url = `proposed_plans_1/show/${id}`;
        const res = await api.get(url);
        setProposedPlan_1({ ...proposedPlan_1, result: res.data.result });
        const createdDate = res.data.result.date.replace(/-/g, "");
        const clientName = res.data.result.client_name;
        setDownloadFileName(`${createdDate}_${clientName}_${pageName}`);
    };

    // サービス等利用計画案情報を取得しステート ProposedPlan_2 にセットする
    const getProposedPlan_2Data = async (proposedPlanId) => {
        const url = `proposed_plans_2/show/${proposedPlanId}`;
        const res = await api.get(url);
        setProposedPlan_2({ ...proposedPlan_2, data: [...res.data.result] });
        setLoadComplete(true);
    };

    //  登録ボタンクリック時
    const handleOnClickRegister = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        navigate(`/proposed_plan_2_register/${selectedClient}/${id}`);
    };

    const handleOnClickEdit = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        navigate(`/proposed_plan_2/edit/${selectedClient}/${id}`);
    };

    //閉じるボタンをクリックしたら、情報一覧録画面に遷移する
    const handleOnClickBack = (e) => {
        e.stopPropagation();

        navigate(`/proposed_plan_1/${client}`);
    };

    const makeTable = () => {
        const { data } = proposedPlan_2;

        // セルの状態を管理するステートを複製する
        const newTimeField = JSON.parse(JSON.stringify(timeField));

        for (let item of data) {
            const content = item.content;
            const dayWeek = item.day_week;
            const startTime = item.schedule_start_time;
            const endTime = item.schedule_end_time;
            for (let ii = startTime; ii <= endTime; ii++) {
                if (ii === item.schedule_start_time) {
                    newTimeField[dayWeek][startTime].title = content;
                }
                newTimeField[dayWeek][ii].content = content;
            }
            drawColor(content);
        }

        setTimeField(newTimeField);
    };

    // 背景色用のCSSクラス名を結び付けたオブジェクトを新たに作成し追加する
    const drawColor = (content) => {
        if (!colorClasses.current[content]) {
            // オブジェクトの複製
            const beforeColorClasses = JSON.parse(JSON.stringify(colorClasses.current));
            // 新しいプロパティを作成
            const colorClass = { [content]: `bgColor${bgColor.current}` };
            // オブジェクトの結合
            const newColorClasses = { ...beforeColorClasses, ...colorClass };
            // Refオブジェクトに値を上書きして更新
            colorClasses.current = newColorClasses;

            // 次に使用する背景色用のCSSクラスのナンバーを更新
            if (bgColor.current == 11) {
                bgColor.current = 1;
            } else {
                bgColor.current++;
            }
        }
    };

    useEffect(() => {
        getProposedPlan_1Data(id);
        getProposedPlan_2Data(id);
    }, []);

    useEffect(() => {
        if (proposedPlan_2.data.length > 0) {
            makeTable();
        }
    }, [proposedPlan_2]);

    //---------------------------issue{No.325} start-----------------------------
    const fileDownload = async (e) => {
        e.stopPropagation();

        const requestData = proposedPlan_2.data.map((data, index) => {
            const obj = {
                index: index,
                day_week: data["day_week"],
                schedule_start_time: data["schedule_start_time"],
                schedule_end_time: data["schedule_end_time"],
                content: data["content"]
            };
            return obj;
        });

        const data = { ...proposedPlan_1.result, week_data: requestData, file_type: "proposed_plan_2" };

        setDownloadState("waiting");
        const url = "export_file";
        await api
            .post(url, data)
            .then(async (res) => {
                setDownloadState("doing");
                if (res.data.success) {
                    const date = proposedPlan_1.result.date.replace(/-/g, "");
                    const clientName = proposedPlan_1.result.client_name;
                    const fileName = `${date}_${clientName}_サービス等利用計画案_週間計画表.xlsx`;

                    const deleteUrl = "delete_file";
                    const downloadUrl = "download_file";
                    const data = { path: res.data.result };
                    const option = { responseType: "blob" }; // important

                    // ダウンロード実行完了後、必ずExportしたファイルを削除する
                    api.post(deleteUrl, data);

                    // ダウンロードを実行する
                    await api.post(downloadUrl, data, option).then((res) => {
                        const url = window.URL.createObjectURL(res.data);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();

                        setTimeout(() => {
                            setDownloadState("");
                        }, 2500);
                    });
                }
            })
            .catch(() => {
                setDownloadState("");
                swal("ファイルの作成に失敗", "", "error");
            });
    };
    //---------------------------issue{No.325} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>
                            サービス等利用計画案 - 【週間計画表】情報（
                            <span className={classes.clientName}>
                                {proposedPlan_1.result.client_name}|{proposedPlan_1.result.date}
                            </span>
                            ）
                        </h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    {loadComplete && (
                        <>
                            {proposedPlan_2.data.length > 0 ? (
                                <div>
                                    {/* -------------------- issue325 start -------------------- */}
                                    <div className='p-3 mb-4'>
                                        <NewOutputExcelButton
                                            fileName={downloadFileName}
                                            api_url={`proposed_plans_2/${id}/export`}
                                            buttonTextIsFileName={false}
                                        />
                                    </div>
                                    {/* -------------------- issue325 end -------------------- */}
                                    <p className='text-center'>作成済みの内容を表示中</p>
                                    <h3 className='text-center p-2'>【週間計画表】</h3>
                                    <div className='w-100 d-flex'>
                                        <div className={classes.tableLeft}>
                                            <div className='fs-6 pt-2 pb-3 mb-1'>時刻</div>
                                            {Object.keys(timeList).map((row) => (
                                                <div key={row} className={classes.timeList}>
                                                    {timeList[row]}
                                                </div>
                                            ))}
                                        </div>
                                        <div className={classes.tableRight}>
                                            <div className='row g-0 d-flex'>
                                                {day.map((day, index) => (
                                                    <h4 key={index} className='col lh-lg m-0 text-center fw-bold'>
                                                        {day}
                                                    </h4>
                                                ))}
                                            </div>
                                            <div className='overflow-hidden'>
                                                {Object.keys(timeField).length > 0 &&
                                                    Object.keys(timeField[1]).map((row) => {
                                                        return (
                                                            <div key={row} className='row g-0'>
                                                                {Object.keys(timeField).map((column) => {
                                                                    return (
                                                                        <div
                                                                            key={column}
                                                                            id={`${column}-${row}`}
                                                                            className={`col ${
                                                                                timeField[column][row].content
                                                                                    ? classes[
                                                                                          colorClasses.current[
                                                                                              timeField[column][row]
                                                                                                  .content
                                                                                          ]
                                                                                      ]
                                                                                    : ""
                                                                            } bg-gradient ${classes.cell} ${
                                                                                timeField[column][row].title
                                                                                    ? classes.contentTitle
                                                                                    : ""
                                                                            }`}>
                                                                            {(() => {
                                                                                if (timeField[column][row].title) {
                                                                                    const title =
                                                                                        timeField[column][row].title;
                                                                                    const texts = title
                                                                                        .split(/(\n)/)
                                                                                        .map((item, index) => {
                                                                                            return (
                                                                                                <React.Fragment
                                                                                                    key={index}>
                                                                                                    {item.match(
                                                                                                        /\n/
                                                                                                    ) ? (
                                                                                                        <br />
                                                                                                    ) : (
                                                                                                        item
                                                                                                    )}
                                                                                                </React.Fragment>
                                                                                            );
                                                                                        });
                                                                                    return (
                                                                                        <React.Fragment>
                                                                                            {texts}
                                                                                        </React.Fragment>
                                                                                    );
                                                                                }
                                                                            })()}
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center my-4'>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='large'
                                            onClick={(e) => handleOnClickEdit(e)}>
                                            編集する
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <div className='card-body'>
                                    <div className='text-center mb-4'>
                                        <p className='fs-5 p-2'>【週間計画表】が作成されていません。</p>
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            size='large'
                                            onClick={(e) => handleOnClickRegister(e)}>
                                            週間計画表を作成する
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <div className='card-footer'>
                                <Button variant='contained' onClick={(e) => handleOnClickBack(e)}>
                                    戻る
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProposedPlan_2Show;
