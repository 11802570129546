import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";
import formatSupportLevelList from "../../common/formatSupportLevelList";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        figure: {
            marginBottom: "0!important",
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center",
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px",
        },
    })
);

// 表示用の各データに応じた値を配列で用意
const residence_types = { 1: "単身", 2: "同居有", 3: "GH", 4: "入所施設", 5: "医療機関", 6: "その他" };
const genders = { 1: "男性", 2: "女性" };
const disability_level_1_list = { 1: "1級", 2: "2級", 3: "3級", 4: "4級", 5: "5級", 6: "6級" };
const disability_level_2_list = { 1: "A1", 2: "A2", 3: "A3", 4: "B1", 5: "B2" };
const disability_level_3_list = { 1: "1級", 2: "2級", 3: "3級" };
const support_law_list = { 1: "申請中", 2: "有" };
const pension_types = { 0: "未受給または申請中", 1: "基礎", 2: "厚生" };
const pension_level_list = { 1: { 1: "1級", 2: "2級" }, 2: { 1: "1級", 2: "2級", 3: "3級" } };
const other_income_list = { 1: "生活保護", 9: "その他手当" };
const work_list = { 1: "就労継続支援A型", 2: "就労継続支援B型", 3: "一般就労", 4: "障害者雇用" };
const medical_list = { 1: "障害者医療証", 2: "自立支援医療", 3: "生活保護（医療補助）" };

function Assessment_1DeletedShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、アセスメント情報のid
    const { client, id } = useParams();

    // 表示するassessment_1 の状態を管理する
    const [assessment_1DeletedShowData, setAssessment_1DeletedShowData] = useState([]);
    const [assessment_1Data, setAssessment_1Data] = useState([]);
    const [beforeFamily, setBeforeFamily] = useState("");
    const [beforeSocial, setBeforeSocial] = useState("");
    const [loadFinish, setLoadFinish] = useState(false);

    //情報を取得しステート assessment_1 にセットする
    const getAssessment_1Data = async (id) => {
        const url = `assessments_1/${id}/deleted`;
        const res = await api.get(url);
        setAssessment_1Data(res.data.result);
        setAssessment_1Row(res.data.result);
    };

    // ステートに値をセット
    const setAssessment_1Row = (assessment_1) => {
        const assessmentDateFormat = dateFormat(assessment_1.date, true);
        const assessmentDate = assessmentDateFormat.format_date;

        const clientGender = genders[assessment_1.client_gender];
        const residenceType = residence_types[assessment_1.residence_type];

        const clientBirthdayFormat = dateFormat(assessment_1.client_birthday, true);
        const clientBirthday = clientBirthdayFormat.format_date;

        let disabilityLevel1 = "";
        if (assessment_1.disability_1) {
            disabilityLevel1 = disability_level_1_list[assessment_1.disability_level_1];
        }

        let disabilityLevel2 = "";
        if (assessment_1.disability_2) {
            disabilityLevel2 = disability_level_2_list[assessment_1.disability_level_2];
        }

        let disabilityLevel3 = "";
        if (assessment_1.disability_3) {
            disabilityLevel3 = disability_level_3_list[assessment_1.disability_level_3];
        }

        const supportLaw = support_law_list[assessment_1.support_law];
        const pensionType = pension_types[assessment_1.pension_type];

        let pensionLevel = "";
        if (assessment_1.pension_type) {
            pensionLevel = pension_level_list[assessment_1.pension_type][assessment_1.pension_level];
        }

        const otherIncome = other_income_list[assessment_1.other_income];
        const clientWork = work_list[assessment_1.work];

        let employmentInsurance = "";
        if (assessment_1.employment_insurance) {
            employmentInsurance = "有";
        }

        let welfarePension = "";
        if (assessment_1.welfare_pension) {
            welfarePension = "有";
        }

        let healthInsurance = "";
        if (assessment_1.health_insurance) {
            healthInsurance = "有";
        }

        const clientMedical = medical_list[assessment_1.medical];

        let medicalOther = "";
        if (assessment_1.medical_other) {
            medicalOther = "有";
        }

        const supportLevel = formatSupportLevelList(assessment_1.support_law, assessment_1.support_level); // issue546

        setAssessment_1DeletedShowData([
            { label: "会社名", value: assessment_1.company_name },
            { label: "事業所名", value: assessment_1.offices_office_name },
            { label: "担当職員", value: assessment_1.users_user_name },
            { label: "利用者ID", value: assessment_1.client_id },
            { label: "作成日", value: assessmentDate },
            { label: "相談支援事業者名", value: assessment_1.office_name },
            { label: "計画作成担当者", value: assessment_1.user_name },
            { label: "概要（支援経過・現状と課題等）", value: assessment_1.summary },
            { label: "利用者氏名", value: assessment_1.client_name },
            { label: "生年月日", value: clientBirthday },
            { label: "年齢", value: assessment_1.client_age + "歳" },
            { label: "性別", value: clientGender },
            { label: "住所", value: assessment_1.client_address },
            { label: "住所種別", value: residenceType },
            { label: "その他の内容", value: assessment_1.residence_type_detail },
            { label: "本人の連絡先", value: assessment_1.client_tel },
            { label: "緊急連絡先", value: assessment_1.emergency_tel },
            { label: "障害または疾患名", value: assessment_1.disability_name },
            { label: "総合支援法", value: supportLaw },
            { label: "障害支援区分", value: supportLevel }, // issue546
            { label: "身体障害者手帳", value: disabilityLevel1 },
            { label: "療育手帳", value: disabilityLevel2 },
            { label: "精神障害手帳", value: disabilityLevel3 },
            { label: "障害年金", value: pensionType },
            { label: "障害年金（級）", value: pensionLevel },
            { label: "その他収入", value: otherIncome },
            { label: "その他収入の内容", value: assessment_1.other_income_detail },
            { label: "就労", value: clientWork },
            { label: "勤務先", value: assessment_1.workplace },
            { label: "雇用保険", value: employmentInsurance },
            { label: "厚生年金", value: welfarePension },
            { label: "健康保険", value: healthInsurance },
            { label: "医療", value: clientMedical },
            { label: "医療その他", value: medicalOther },
            { label: "医療その他の内容", value: assessment_1.medical_other_detail },
            // { label: "家族構成", value: assessment_1.family_structure },
            // { label: "社会関係図", value: assessment_1.social_relationship_diagram },
            { label: "生活歴", value: assessment_1.life_history },
            { label: "医療の状況", value: assessment_1.medical_situation },
            { label: "本人の主訴", value: assessment_1.self_complaint },
            { label: "家族の主訴", value: assessment_1.family_complaint },
            { label: "公的支援　名称", value: assessment_1.public_support_name },
            { label: "公的支援　提供機関・提供者", value: assessment_1.public_support_provider },
            { label: "公的支援　支援内容", value: assessment_1.public_support_detail },
            { label: "公的支援　頻度", value: assessment_1.public_support_frequency },
            { label: "公的支援　連絡先・備考", value: assessment_1.public_support_contact },
            { label: "その他の支援　名称", value: assessment_1.other_support_name },
            { label: "その他の支援　提供機関・提供者", value: assessment_1.other_support_provider },
            { label: "その他の支援　支援内容", value: assessment_1.other_support_detail },
            { label: "その他の支援　頻度", value: assessment_1.other_support_frequency },
            { label: "その他の支援　連絡先・備考", value: assessment_1.other_support_contact },
            { label: "主な日常生活上の活動", value: assessment_1.daily_routine },
            { label: "週単位以外のサービス", value: assessment_1.other_service },
        ]);
    };

    // 閉じるボタンをクリックしたら、【削除済み】アセスメント情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/assessment_1_deleted/${client}`);
    };

    // 以前登録した画像を取得
    const getBeforeFamily = async () => {
        // コントローラーに渡す値を作成
        const company = assessment_1Data.company_id;
        const office = assessment_1Data.office_id;
        const family2 = assessment_1Data.family_structure;
        const data = { path: `family/${company}/${office}/${client}/${family2}` };
        if (family2 != null) {
            const res = await api.post("file", data);
            // 値が存在する場合state(=imgのurl)を更新
            if (res) {
                const gotData = res.data.result;
                const adjustedData = JSON.parse(gotData); // 受け取ったデータをjsonに
                const mimeType = adjustedData.mimeType;
                const fileText = adjustedData.fileText;
                const beforeFamilyUrl = "data:" + mimeType + ";base64," + fileText;
                setBeforeFamily(beforeFamilyUrl);
            }
        }
    };

    const getBeforeSocial = async () => {
        // コントローラーに渡す値を作成
        const company = assessment_1Data.company_id;
        const office = assessment_1Data.office_id;
        const social2 = assessment_1Data.social_relationship_diagram;
        const data = { path: `social/${company}/${office}/${client}/${social2}` };
        if (social2 != null) {
            const res = await api.post("file", data);
            // 値が存在する場合state(=imgのurl)を更新
            if (res) {
                const gotData = res.data.result;
                const adjustedData = JSON.parse(gotData); // 受け取ったデータをjsonに
                const mimeType = adjustedData.mimeType;
                const fileText = adjustedData.fileText;
                const beforeFamilyUrl = "data:" + mimeType + ";base64," + fileText;
                setBeforeSocial(beforeFamilyUrl);
            }
        }
    };

    useEffect(async () => {
        if (Object.keys(assessment_1DeletedShowData).length > 1) {
            await getBeforeFamily();
            await getBeforeSocial();
            setLoadFinish(true);
        }
    }, [assessment_1DeletedShowData]);

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getAssessment_1Data(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】アセスメント情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <div className='my-5 d-flex justify-content-around'>
                            <div>
                                <figure className={classes.figure}>家族構成</figure>
                                <div className={classes.beforeWrapper}>
                                    {loadFinish && (
                                        <img
                                            className={classes.before}
                                            src={beforeFamily == "" ? "/img/no_image.jpg" : beforeFamily}
                                        />
                                    )}
                                </div>
                            </div>
                            <div>
                                <figure className={classes.figure}>社会関係図</figure>
                                <div className={classes.beforeWrapper}>
                                    {loadFinish && (
                                        <img
                                            className={classes.before}
                                            src={beforeSocial == "" ? "/img/no_image.jpg" : beforeSocial}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <DataShow data={assessment_1DeletedShowData}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Assessment_1DeletedShow;
