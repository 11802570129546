import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import DataShowForRegister from "../../components/DataShowForRegister";
import dateFormat from "../../common/dateFormat";
import NewOutputExcelButton from "../../components/NewOutputExcelButton";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
        },
        figure: {
            marginBottom: "0!important",
            fontWeight: "bold"
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center"
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px"
        }
    })
);

export default function ServicePersonnelMeetingShow(props) {
    const { idFromRegister = undefined, forRegister = false, setRefCurrent = undefined } = props;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、サービス等利用計画案情報のid
    const { client, id } = useParams();

    const pageName = "サービス担当者会議記録";

    // 表示するservicePersonnelMeeting の状態を管理する
    const [servicePersonnelMeetingData, setServicePersonnelMeetingData] = useState([]);
    const [servicePersonnelMeetingShowData, setServicePersonnelMeetingShowData] = useState([]);

    const [downloadState, setDownloadState] = useState("");
    const [downloadFileName, setDownloadFileName] = useState("");

    // "hh:ii:ss"を"hh:ii"に変更
    const formatTime = (time) => {
        const timeArr = time.split(":");
        const result = `${timeArr[0]}:${timeArr[1]}`;
        return result;
    };

    //情報を取得しステート servicePersonnelMeeting にセットする
    const getServicePersonnelMeetingData = async (id) => {
        const url = `service_personnel_meetings/show/${id}`;
        const res = await api.get(url);

        if (res.data.success) {
            setServicePersonnelMeetingData(res.data.result);
            setServicePersonnelMeetingRow(res.data.result);
            const createdDate = res.data.result.date.replace(/-/g, "");
            const clientName = res.data.result.client_name;
            setDownloadFileName(`${createdDate}_${clientName}_${pageName}`);
        }
    };

    // ステートに値をセット
    const setServicePersonnelMeetingRow = (servicePersonnelMeeting) => {
        const date = dateFormat(servicePersonnelMeeting?.date, true);
        const start_time = formatTime(servicePersonnelMeeting?.start_time);
        const end_time = formatTime(servicePersonnelMeeting?.end_time);
        const division = servicePersonnelMeeting?.division == 1 ? "サービス利用支援" : "継続サービス利用支援";
        const attendeeArray = [...Array(8)].map((_, ii) => [
            {
                label: `会議出席者【${ii + 1}】所属(職種)`,
                value: servicePersonnelMeeting?.[`attendee_affiliation_${ii + 1}`] ?? ""
            },
            {
                label: `会議出席者【${ii + 1}】氏名`,
                value: servicePersonnelMeeting?.[`attendee_name_${ii + 1}`] ?? ""
            }
        ]);
        //「2次元配列」を「1次元配列」に変換
        const attendee = attendeeArray.flat();
        const data = [
            { label: "開催年月日", value: date.format_date },
            { label: "利用者氏名", value: servicePersonnelMeeting?.client_name },
            { label: "相談支援専門員氏名", value: servicePersonnelMeeting?.user_name },
            { label: "開催時間", value: `${start_time}～${end_time}` },
            { label: "開催場所", value: servicePersonnelMeeting?.venue },
            { label: "区分", value: division },
            ...attendee,
            {
                label: "検討した項目",
                value: servicePersonnelMeeting?.considered_item
            },
            { label: "検討した内容", value: servicePersonnelMeeting?.considered_detail },
            { label: "検討した結果", value: servicePersonnelMeeting?.considered_result },
            { label: "その他", value: servicePersonnelMeeting?.other }
        ];

        setServicePersonnelMeetingShowData(data);

        if (forRegister) {
            setRefCurrent(data);
        }
    };

    // 閉じるボタンをクリックしたら、サービス等利用計画案情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/service_personnel_meeting/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getServicePersonnelMeetingData(idFromRegister ? idFromRegister : id);
    }, []);

    return (
        <>
            {forRegister ? (
                <DataShowForRegister data={servicePersonnelMeetingShowData}></DataShowForRegister>
            ) : (
                <div className='row justify-content-center'>
                    <div className='col-md-10 col-lg-10 mx-auto'>
                        <div className='card'>
                            <div className='card-header d-flex'>
                                <h1 className={classes.title}>サービス担当者会議記録情報</h1>
                                <div className='ms-auto'>
                                    <button
                                        className='btn btn-outline-secondary fs-3 lh-1'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                            </div>
                            <div className='card-body'>
                                {servicePersonnelMeetingShowData.length > 0 ? (
                                    <>
                                        <NewOutputExcelButton
                                            fileName={downloadFileName}
                                            api_url={`service_personnel_meetings/${id}/export`}
                                            buttonTextIsFileName={false}
                                        />
                                        <DataShow data={servicePersonnelMeetingShowData}></DataShow>
                                    </>
                                ) : (
                                    <p>読み込み中です。</p>
                                )}
                            </div>
                            <div className='card-footer'>
                                <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                    閉じる
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
