import React, { useContext } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { UserContext } from "../providers/UserProvider";
import FunctionList from "../components/FunctionList";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontSize: "1.2rem",
        },
    })
);

function Home() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    const { userInfo } = useContext(UserContext);
    const user = userInfo.user;
    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header'>
                        <h1>ホーム {user.user_name}</h1>
                    </div>
                    <div className='card-body'>
                        {/* <FunctionList category='機能一覧' /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
