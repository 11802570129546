import React from "react";
import { Button } from "@material-ui/core";
import Docxtemplater from "docxtemplater";
import expressionParser from "docxtemplater/expressions.js";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";

const loadFile =(url, callback) =>
    PizZipUtils.getBinaryContent(url, callback);

// nullを取得した際の返り値に空文字列を指定しています
const nullGetter = (part, scopeManager) => "";

/**
 * docx形式のWord fileを出力します
 * https://docxtemplater.com/docs/faq/#docxtemplater-in-a-react-project
 * @param formatFile フォーマット Word ファイル名
 * @param outputName 出力ファイル名
 * @param inputTexts キーにDBでの列名を,値に取得した記述内容を格納した連想配列
 */
export default function OutputWordButton(formatFile, outputName, inputTexts ={}) {
        const generateDocument = () => {
            loadFile("/word/" + formatFile,
                function (error, content) {
                    if (error) {
                        swal("ファイルの作成に失敗", "", "error");
                        throw error;
                    }
                    // Word fileを取得します
                    const doc = new Docxtemplater(new PizZip(content), {
                        paragraphLoop: true,
                        linebreaks: true,
                        parser: expressionParser,
                        nullGetter
                    });

                    const date = inputTexts?.date.replace(/-/g, ""); // ハイフンを省いた日付
                    // 利用者児童名が存在すれば児童名を,居なくば利用者名を取得します
                    const clientName = inputTexts?.child_name ?? inputTexts?.client_name;
                    // 受け取った記述内容から日付と利用者名を出力時のファイル名に使います
                    const fileName = `${date}_${clientName}_${outputName}.docx`;

                    // 記述内容にNULL等の無効な値があるとWordに出力する際にundefinedが表示されてしまうので
                    // nullGetterと同様に無効な値を空文字列に置換えます
                    Object.keys(inputTexts).map(columnName => inputTexts[columnName] = inputTexts[columnName] ?? "");

                    // 記述内容のkey名に対応するWord file内の{}区切りの文字列を対応する値に置換えます
                    // render the document(replace all occurrences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render(inputTexts);

                    // Data-URI形式でword documentを出力します
                    const blob = doc.getZip().generate({
                        type: "blob",
                        mimeType:
                            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    saveAs(blob, fileName);
                    swal(`docxファイルのダウンロード完了`, `${fileName}`, "success");
                }
            );
        };

        return <Button variant='contained' color='primary' className='mb-4' onClick={generateDocument}>WORDでダウンロード</Button>;
};