import React, { useEffect, useState } from "react";

const FreeWordSearch = (props) => {
    // 検索ワードを格納するステート
    const [searchWord, setSearchWord] = useState("");
    // 検索ワードの個数を格納するステート
    const [searchWordCount, setSearchWordCount] = useState(0);
    // データベースの応答を待ち、検索を待機する状態を宣言するステート
    const [waitForResponse, setWaitForResponse] = useState(false);
    // propsから、親コンポーネントの関数を取得
    const { searchColumns, data, setSearchedData } = props;

    // 検索ワードの入力が変更される度に、検索ワードを取得し、ステートを更新
    const handleChangeSearchWord = (e) => {
        // 前後の空白文字を削除
        let words = e.target.value.trim();
        // 検索ワードを空白文字で区切って配列に格納
        let splitWords = words.split(/\s/);

        // ステートを更新
        setSearchWordCount(splitWords.length);
        setSearchWord(splitWords);
    };

    // オブジェクトの各データについて、検索ワード毎に判定する関数
    // searchInAllWords(オブジェクト, "キー名")
    const searchInAllWords = (object, column) => {
        let result = [];
        // 検索ワードを含むかの判定
        for (let i = 0; i < searchWord.length; i++) {
            // データが数値の場合もあるので文字列に変換している
            if (String(object[column]).indexOf(searchWord[i]) !== -1) {
                result[i] = true;
            } else {
                result[i] = false;
            }
        }

        // 戻り値を振り分けるために、検索ワードに対していくつTRUEになったかをカウントする
        let trueCount = 0;
        for (let index in result) {
            if (result[index]) {
                trueCount++;
            }
        }

        if (trueCount === searchWordCount) {
            // 全ての検索ワードで一致した場合
            return true;
        } else if (trueCount >= 1) {
            // 一部の検索ワードにヒットした場合
            // 結果を配列で返す
            return result;
        } else if (trueCount === 0) {
            // 一つもヒットしなかった場合
            return false;
        }
    };

    // 検索ワードによる検索を行う関数
    const getSearchedUsers = () => {
        // 検索結果を格納する配列
        let result = [];
        // もし検索ワードが空("")の場合
        if (searchWord === "") {
            // 全てのデータを検索結果として代入
            result = data;
            // 以下、検索ワードがある場合
        } else if (searchWord) {
            // フリーワード検索
            // オブジェクトを展開しつつ、検索対象のカラムの値に検索ワードが含まれているかを検証している
            for (let key in data) {
                // 検索結果を格納する多次元配列
                let searchResult = [];
                for (let i = 0; i < searchColumns.length; i++) {
                    // 検索対象のカラムの配列定義(searchColumns)を元に
                    // オブジェクト内の対象のデータを検索し、その検索結果のみを多次元配列に収納する
                    searchResult[i] = searchInAllWords(data[key], searchColumns[i]);
                }
                // 検索ワードの全てに一致するまでの一致したカウント
                let ambiguousCount = 0;
                // 一致の確認の取れた検索ワードを次回判定しないように、状態を保存する配列を宣言
                let filledWord = [...Array(searchWordCount)].map(() => true);
                // 検索結果を展開
                for (let index in searchResult) {
                    // 一つのデータで検索ワード全てに一致していた場合
                    if (searchResult[index] === true) {
                        // 検索結果に、そのデータを持つ行のデータを追加する
                        result.push(data[key]);
                        break;
                    }
                    // 複数のデータで検索ワードに一致していた場合の処理
                    if (Array.isArray(searchResult[index])) {
                        // 検索ワードを一つずつ判定していく
                        for (let wordIndex = 0; wordIndex < searchWordCount; wordIndex++) {
                            // データが、いくつかの検索ワードに一致していて、かつ
                            // まだ一致したとして扱われていない検索ワードでの一致だった場合
                            if (searchResult[index][wordIndex] && filledWord[wordIndex]) {
                                // 一致したカウントを増やす
                                ambiguousCount++;
                                // 増やした時点で、一致した数が全ての検索ワードの個数と等しくなった場合
                                if (ambiguousCount === searchWordCount) {
                                    // 検索結果に、そのデータを持つ行のデータを追加する
                                    result.push(data[key]);
                                    break;
                                }
                                // 次からの判定で、今回一致の確認ができた検索ワードを判定に含めない
                                filledWord[wordIndex] = false;
                            }
                        }
                    }
                }
            }
        }
        // 検索結果をステートにセット
        setSearchedData(result);
    };

    // 検索ワードが更新されるたびに、以下の副作用フックを実行
    useEffect(() => {
        // データベースからのデータの取得が済んでいるか
        if (data.length > 0) {
            // データの取得が済んでいる場合、検索を実行
            getSearchedUsers();
        } else {
            // データの取得が済んでいない場合は、
            // データベースの応答を待ち、検索を待機する状態にする
            setWaitForResponse(true);
        }
    }, [searchWord]);

    // データベースからのデータの取得が済む前に検索が行なわれた場合のための副作用フック
    useEffect(() => {
        // データベースからのデータの取得が済んだとき、かつ検索の実行が待機されているとき
        if (waitForResponse && data.length > 0) {
            // 検索結果取得の実行
            getSearchedUsers();
        }
        //---------------------------issue{No.59} start-----------------------------
        // 表示可能なデータが0件になった場合
        if (data.length === 0) {
            setSearchedData([]);
        }
        //---------------------------issue{No.59} end-------------------------------
    }, [data]);

    return (
        <div className='col-md-3 mx-auto mt-3 mb-4'>
            <input
                id='search_word'
                className='form-control'
                type='text'
                name='search_word'
                placeholder='検索'
                onChange={(e) => handleChangeSearchWord(e)}
            />
        </div>
    );
};

export default FreeWordSearch;
