import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDataList, {rowEnclosureButton} from "../../components/ClientSelectToDataList";

// 見出しの配列定義
const headers = ['作成日'];

export default function NursingOfficeAlignment() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDataList
        pageName='居宅介護支援事業所等連携'
        targetURL='nursing_office_alignment'
        headerDataList={headers}
        clientID={client} />;
}