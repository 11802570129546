import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
import {
    Box,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";
import { UserContext } from "../../providers/UserProvider";
import FreeWordSearch from "../../components/FreeWordSearch";
import DataTable from "../../components/DataTable";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        row: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004",
        },
        rowNum: {
            display: "block",
            marginRight: "auto",
            fontSize: "1rem",
            lineHeight: "2rem",
        },
    })
);

// 初期状態
const initReport = { success: true };

// 初期状態
const initClientData = { result: [], success: true };

//見出しの配列定義
const headers = ["利用者氏名", "フリガナ", "担当職員名"];

// 検索対象のカラムの配列定義
const columns = ["client_name", "client_ruby", "user_name"];

// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

export default function ReportRegisterPerson() {
    // ログイン中のユーザーIDを定義
    const { userInfo } = useContext(UserContext);
    const user = userInfo.user.id;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 利用者のidと、前回登録したデータのidを保持している
    const { client } = useParams();

    // 日付けの入力制限のための日付の値の定数を取得
    const { MIN_DATE, MAX_DATE } = MIN_MAX_DATE;

    // 入力値を管理する
    const [report, setReport] = useState(initReport);

    // 日報の行ごとの入力欄の表示個数を管理する
    const [rowCount, setRowCount] = useState(1);

    const [clientData, setClientData] = useState(initClientData);

    const getClientData = async (id) => {
        const url = `reports/client/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setClientData(res.data.result);
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 登録ボタン押下時
    const reportSubmit = async (data) => {
        let confirm = false;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "登録しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // 先に二つ目以降の登録数分だけ、配列を作成する
        const reportData = [];

        for (let ii = 1; ii <= rowCount; ii++) {
            const obj = {
                company_id: clientData.company_id,
                office_id: clientData.office_id,
                user_id: clientData.user_id,
                client_id: clientData.client_id,
                date: data[`date_${ii}`],
                start_time: data[`start_time_${ii}`] ? data[`start_time_${ii}`] : null,
                end_time: data[`end_time_${ii}`] ? data[`end_time_${ii}`] : null,
                task: data[`task_${ii}`] ? data[`task_${ii}`] : null,
                detail: data[`detail_${ii}`] ? data[`detail_${ii}`] : null,
            };

            reportData.push(obj);
        }

        //登録
        const url = "reports";
        const res = await api.post(url, reportData);
        if (res.status === 422) {
            setReport({
                ...report,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, "本日も一日お疲れ様でした。", "success").then(() => {
                navigate(`/report/${user}`);
            });
        }
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getClientData(client);
    }, []);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        control,
        clearErrors,
    } = useForm();

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        setRowCount((rowCount) => rowCount + 1);
    };

    // 入力欄の行を入れ替える関数
    const handleOnClickRowChange = (e, num) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 行が一つ下がる入力欄についての入力欄のnameにあたる値一覧を作成して格納
        const higherRow = {
            date: `date_${num}`,
            task: `task_${num}`,
            start_time: `start_time_${num}`,
            end_time: `end_time_${num}`,
            client_name: `client_name_${num}`,
            detail: `detail_${num}`,
        };

        // 行が一つ上がる入力欄についての入力欄のnameにあたる値一覧を作成して格納
        const lowerRow = {
            date: `date_${num + 1}`,
            task: `task_${num + 1}`,
            start_time: `start_time_${num + 1}`,
            end_time: `end_time_${num + 1}`,
            client_name: `client_name_${num + 1}`,
            detail: `detail_${num + 1}`,
        };

        // 入れ替えの際に、値を一時的に控えておく定数オブジェクト
        const reserveValues = {
            date: null,
            task: null,
            start_time: null,
            end_time: null,
            client_name: null,
            detail: null,
        };

        // 行が下がる側の入力欄の値を控えておく
        for (let key of Object.keys(higherRow)) {
            const value = getValues(higherRow[key]);
            reserveValues[key] = value;
        }

        // 行の下の入力欄の値を上の入力欄に移し替える
        for (let key of Object.keys(higherRow)) {
            const newValue = getValues(lowerRow[key]);
            setValue(higherRow[key], newValue, { shouldValidate: false });
        }

        // 控えておいた値を行の下の入力欄に差し替える
        for (let key of Object.keys(reserveValues)) {
            setValue(lowerRow[key], reserveValues[key], { shouldValidate: false });
        }
    };

    // 入力欄の各行の右上にある✕ボタン押下時に、入力欄を削除する処理
    const handleOnClickRowDelete = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この内容を削除しますか？",
            buttons: {
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
        }).then((value) => {
            if (value === "delete") {
                // バリデーションメッセージをリセットする
                clearErrors();
                // 対象の行の入力欄を空欄にする
                RowInputClear(num);
                // 現在表示されている行の個数分、行を入れ替える関数を順に実行し、繰上げを行い
                // ✕ボタンが押下され、上記の関数によって空欄になった入力欄を最下位に下げる
                for (let ii = num; ii < rowCount; ii++) {
                    handleOnClickRowChange(false, ii);
                }
                // 表示される行の入力欄を減らす
                setRowCount((rowCount) => rowCount - 1);
            }
        });
    };

    // 対象の行の入力欄を空欄にする関数
    const RowInputClear = (num) => {
        // 対象の行の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = [`date_${num}`, `start_time_${num}`, `end_time_${num}`, `client_name_${num}`, `detail_${num}`];

        // ラジオボックスのみ、個別に初期化
        setValue(`task_${num}`, "0", { shouldValidate: false });

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, "", { shouldValidate: false });
        }
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>日報登録</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 pt-1 mb-5'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(reportSubmit)}>
                            <div className='d-flex mt-5 mb-2'>
                                <h4 className='d-flex align-items-center border border-2 px-3 fs-5 fw-bold'>
                                    日報内容
                                </h4>
                                <p className='text-secondary fs-6 ms-3'>
                                    <span>（保存後は並び替えて表示されます。）</span>
                                </p>
                            </div>
                            {Array.from({ length: rowCount }, (_, index) => (
                                <>
                                    <div className={classes.row}>
                                        <span className={classes.rowNum}>【{index + 1}】</span>
                                        {rowCount > 1 && (
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickRowDelete(e, index + 1);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        )}
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor={`date_${index + 1}`}>
                                            日付
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id={`date_${index + 1}`}
                                            type='date'
                                            min={MIN_DATE}
                                            max={today}
                                            className='form-control mb-1'
                                            {...register(`date_${index + 1}`, {
                                                required: {
                                                    value: true,
                                                    message: "*日付を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors[`date_${index + 1}`] && errors[`date_${index + 1}`]?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {report.success ? "" : report.error_list[`date_${index + 1}`]}
                                        </small>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-3'>分類</label>
                                        <RequiredMark />
                                        <br />
                                        <Controller
                                            name={`task_${index + 1}`}
                                            control={control}
                                            rules={{ required: { value: true, message: "*分類を入力してください。" } }}
                                            render={({ field, fieldState }) => (
                                                <FormControl component='fieldset'>
                                                    <RadioGroup
                                                        row
                                                        aria-label={`task_${index + 1}`}
                                                        {...field}
                                                        value={field.value}>
                                                        <FormControlLabel
                                                            checked={field.value == "1"}
                                                            value='1'
                                                            control={<Radio />}
                                                            label='面談'
                                                            labelPlacement='end'
                                                        />
                                                        <FormControlLabel
                                                            checked={field.value == "2"}
                                                            value='2'
                                                            control={<Radio />}
                                                            label='モニタリング'
                                                            labelPlacement='end'
                                                        />
                                                        <FormControlLabel
                                                            checked={field.value == "3"}
                                                            value='3'
                                                            control={<Radio />}
                                                            label='電話対応'
                                                            labelPlacement='end'
                                                        />
                                                        <FormControlLabel
                                                            checked={field.value == "4"}
                                                            value='4'
                                                            control={<Radio />}
                                                            label='連絡事項'
                                                            labelPlacement='end'
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors[`task_${index + 1}`] && errors[`task_${index + 1}`]?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {report.success ? "" : report.error_list[`task_${index + 1}`]}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>
                                            実施時間
                                            {/* <RequiredMark /> */}
                                        </label>
                                        <div className='d-flex justify-content-start'>
                                            <input
                                                id={`start_time_${index + 1}`}
                                                type='time'
                                                className='form-control mb-1'
                                                {...register(`start_time_${index + 1}`, {})}
                                            />
                                            <span className='d-block p-2 lh-lg align-items-center'>～</span>
                                            <input
                                                id={`end_time_${index + 1}`}
                                                type='time'
                                                className='form-control mb-1'
                                                {...register(`end_time_${index + 1}`, {})}
                                            />
                                        </div>
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors[`start_time_${index + 1}`] &&
                                                errors[`start_time_${index + 1}`]?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {report.success ? "" : report.error_list[`start_time_${index + 1}`]}
                                        </small>
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors[`end_time_${index + 1}`] &&
                                                errors[`end_time_${index + 1}`]?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {report.success ? "" : report.error_list[`end_time_${index + 1}`]}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor={`detail_${index + 1}`}>
                                            内容
                                            <RequiredMark />
                                        </label>
                                        <textarea
                                            id={`detail_${index + 1}`}
                                            className='form-control mb-1'
                                            {...register(`detail_${index + 1}`, {
                                                required: {
                                                    value: true,
                                                    message: "*内容を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors[`detail_${index + 1}`] && errors[`detail_${index + 1}`]?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {report.success ? "" : report.error_list[`detail_${index + 1}`]}
                                        </small>
                                    </div>
                                </>
                            ))}
                            <div className='w-100 mb-5'>
                                <hr />
                                <div className='text-center'>
                                    <button
                                        type='button'
                                        className='btn btn-outline-primary'
                                        onClick={(e) => {
                                            handleOnClickAddInput(e);
                                        }}>
                                        <span className='fw-bold fs-5 px-1'>＋</span>
                                        入力欄を追加する
                                    </button>
                                </div>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    登録
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
