import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";
import DataShowForRegister from "../../components/DataShowForRegister";
import NewOutputExcelButton from "../../components/NewOutputExcelButton";
import formatSupportLevelList from "../../common/formatSupportLevelList";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        figure: {
            marginBottom: "0!important",
            fontWeight: "bold",
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center",
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px",
        },
    })
);

// 表示用の各データに応じた値を配列で用意
const frequency_list = [
    { value: "01", label: "１ヶ月毎" },
    { value: "02", label: "２ヶ月毎" },
    { value: "03", label: "３ヶ月毎" },
    { value: "04", label: "４ヶ月毎" },
    { value: "05", label: "５ヶ月毎" },
    { value: "06", label: "半年毎" },
    { value: "12", label: "１年毎" },
];

//---------------------------issue{No.440} start-----------------------------
function Monitoring_1Show(props) {
    const { idFromRegister = undefined, forRegister = false, setRefCurrent = undefined } = props;
    //---------------------------issue{No.440} end-------------------------------

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、モニタリング報告書情報のid
    const { client, id } = useParams();

    const pageName = "モニタリング報告書";

    // 表示するmonitoring_1 の状態を管理する
    const [monitoring_1Data, setMonitoring_1Data] = useState([]);
    const [monitoring_1ShowData, setMonitoring_1ShowData] = useState([]);

    //---------------------------issue{No.325} start-----------------------------
    const [downloadState, setDownloadState] = useState("");
    //---------------------------issue{No.325} end-------------------------------
    const [downloadFileName, setDownloadFileName] = useState(pageName);

    //情報を取得しステート monitoring_1 にセットする
    const getMonitoring_1Data = async (id) => {
        const url = `monitorings_1/show/${id}`;
        const res = await api.get(url);
        if (res) {
            //---------------------------issue{No.325} start-----------------------------
            setMonitoring_1Data({ ...res.data.result, file_type: "monitoring_1" });
            //---------------------------issue{No.325} end-------------------------------
            setMonitoring_1Row(res.data.result);
            const createdDate = res.data.result.date.replace(/-/g, "");
            const clientName = res.data.result.client_name;
            setDownloadFileName(`${createdDate}_${clientName}_${pageName}`);

            setDischargeAdditionRow(res.data.result);
        }
    };

    // ステートに値をセット
    const setMonitoring_1Row = (monitoring_1) => {
        const supportLevel = formatSupportLevelList(monitoring_1.support_law, monitoring_1.support_level);

        const maximumAmount = `${monitoring_1.maximum_amount}円`;

        const creationDateFormat = dateFormat(monitoring_1.creation_date, true);
        const creationDate = creationDateFormat.format_date;

        const monitoringDateFormat = dateFormat(monitoring_1.monitoring_date, true);
        const monitoringDate = monitoringDateFormat.format_date;

        const monitoring_1DateFormat = dateFormat(monitoring_1.date, true);
        const monitoring_1Date = monitoring_1DateFormat.format_date;

        const monitoringLastDateFormat = dateFormat(monitoring_1.last_date, true);
        const monitoringLastDate = monitoringLastDateFormat.format_date;

        let monitoringFrequency =
            frequency_list[frequency_list.findIndex((item) => item.value === monitoring_1.frequency)]?.label;

        let serviceType1 = "";
        if (monitoring_1.service_type_1) {
            serviceType1 = "有";
        }

        let serviceType2 = "";
        if (monitoring_1.service_type_2) {
            serviceType2 = "有";
        }

        let serviceType3 = "";
        if (monitoring_1.service_type_3) {
            serviceType3 = "有";
        }

        let serviceType4 = "";
        if (monitoring_1.service_type_4) {
            serviceType4 = "有";
        }

        let serviceType5 = "";
        if (monitoring_1.service_type_5) {
            serviceType5 = "有";
        }

        let serviceVolume1 = "";
        if (monitoring_1.service_volume_1) {
            serviceVolume1 = "有";
        }

        let serviceVolume2 = "";
        if (monitoring_1.service_volume_2) {
            serviceVolume2 = "有";
        }

        let serviceVolume3 = "";
        if (monitoring_1.service_volume_3) {
            serviceVolume3 = "有";
        }

        let serviceVolume4 = "";
        if (monitoring_1.service_volume_4) {
            serviceVolume4 = "有";
        }

        let serviceVolume5 = "";
        if (monitoring_1.service_volume_5) {
            serviceVolume5 = "有";
        }

        let weeklyPlan1 = "";
        if (monitoring_1.weekly_plan_1) {
            weeklyPlan1 = "有";
        }

        let weeklyPlan2 = "";
        if (monitoring_1.weekly_plan_2) {
            weeklyPlan2 = "有";
        }

        let weeklyPlan3 = "";
        if (monitoring_1.weekly_plan_3) {
            weeklyPlan3 = "有";
        }

        let weeklyPlan4 = "";
        if (monitoring_1.weekly_plan_4) {
            weeklyPlan4 = "有";
        }

        let weeklyPlan5 = "";
        if (monitoring_1.weekly_plan_5) {
            weeklyPlan5 = "有";
        }

        const startMonthFormat = dateFormat(monitoring_1.start_date, true, "Y-m");
        const startMonth = startMonthFormat.format_date;

        //---------------------------issue{No.440} start-----------------------------
        const data = [
            { label: "会社名", value: monitoring_1.company_name },
            { label: "事業所名", value: monitoring_1.offices_office_name },
            { label: "担当職員", value: monitoring_1.users_user_name },
            { label: "利用者ID", value: monitoring_1.client_id },
            { label: "利用者氏名", value: monitoring_1.client_name },
            { label: "障害支援区分", value: supportLevel },
            { label: "利用者負担上限額", value: maximumAmount },
            { label: "障害福祉サービス受給者証番号", value: monitoring_1.recipient_no_2 },
            { label: "地域相談支援受給者番号", value: monitoring_1.recipient_no_3 },
            { label: "通所受給者番号", value: monitoring_1.recipient_no_4 },
            { label: "計画作成日", value: creationDate },
            { label: "計画作成担当者", value: monitoring_1.user_name },
            { label: "モニタリング実施日", value: monitoringDate },
            { label: "モニタリング報告書作成日", value: monitoring_1Date },
            { label: "前回のモニタリング実施日", value: monitoringLastDate },
            { label: "モニタリング期間", value: monitoringFrequency },
            { label: "総合的な援助の方針", value: monitoring_1.plan },
            { label: "全体の状況", value: monitoring_1.situation },
            { label: "【1】支援目標", value: monitoring_1.target_1 },
            { label: "【1】達成時期", value: monitoring_1.achievement_period_1 },
            { label: "【1】サービス提供状況", value: monitoring_1.service_1 },
            { label: "【1】本人の感想・満足度", value: monitoring_1.impression_1 },
            { label: "【1】支援目標の達成度", value: monitoring_1.achievement_1 },
            { label: "【1】今後の課題・解決方法", value: monitoring_1.task_1 },
            { label: "【1】サービス種類の変更", value: serviceType1 },
            { label: "【1】サービス量の変更", value: serviceVolume1 },
            { label: "【1】週間計画の変更", value: weeklyPlan1 },
            { label: "【1】その他留意事項", value: monitoring_1.other_1 },
            { label: "【2】支援目標", value: monitoring_1.target_2 },
            { label: "【2】達成時期", value: monitoring_1.achievement_period_2 },
            { label: "【2】サービス提供状況", value: monitoring_1.service_2 },
            { label: "【2】本人の感想・満足度", value: monitoring_1.impression_2 },
            { label: "【2】支援目標の達成度", value: monitoring_1.achievement_2 },
            { label: "【2】今後の課題・解決方法", value: monitoring_1.task_2 },
            { label: "【2】サービス種類の変更", value: serviceType2 },
            { label: "【2】サービス量の変更", value: serviceVolume2 },
            { label: "【2】週間計画の変更", value: weeklyPlan2 },
            { label: "【2】その他留意事項", value: monitoring_1.other_2 },
            { label: "【3】支援目標", value: monitoring_1.target_3 },
            { label: "【3】達成時期", value: monitoring_1.achievement_period_3 },
            { label: "【3】サービス提供状況", value: monitoring_1.service_3 },
            { label: "【3】本人の感想・満足度", value: monitoring_1.impression_3 },
            { label: "【3】支援目標の達成度", value: monitoring_1.achievement_3 },
            { label: "【3】今後の課題・解決方法", value: monitoring_1.task_3 },
            { label: "【3】サービス種類の変更", value: serviceType3 },
            { label: "【3】サービス量の変更", value: serviceVolume3 },
            { label: "【3】週間計画の変更", value: weeklyPlan3 },
            { label: "【3】その他留意事項", value: monitoring_1.other_3 },
            { label: "【4】支援目標", value: monitoring_1.target_4 },
            { label: "【4】達成時期", value: monitoring_1.achievement_period_4 },
            { label: "【4】サービス提供状況", value: monitoring_1.service_4 },
            { label: "【4】本人の感想・満足度", value: monitoring_1.impression_4 },
            { label: "【4】支援目標の達成度", value: monitoring_1.achievement_4 },
            { label: "【4】今後の課題・解決方法", value: monitoring_1.task_4 },
            { label: "【4】サービス種類の変更", value: serviceType4 },
            { label: "【4】サービス量の変更", value: serviceVolume4 },
            { label: "【4】週間計画の変更", value: weeklyPlan4 },
            { label: "【4】その他留意事項", value: monitoring_1.other_4 },
            { label: "【5】支援目標", value: monitoring_1.target_5 },
            { label: "【5】達成時期", value: monitoring_1.achievement_period_5 },
            { label: "【5】サービス提供状況", value: monitoring_1.service_5 },
            { label: "【5】本人の感想・満足度", value: monitoring_1.impression_5 },
            { label: "【5】支援目標の達成度", value: monitoring_1.achievement_5 },
            { label: "【5】今後の課題・解決方法", value: monitoring_1.task_5 },
            { label: "【5】サービス種類の変更", value: serviceType5 },
            { label: "【5】サービス量の変更", value: serviceVolume5 },
            { label: "【5】週間計画の変更", value: weeklyPlan5 },
            { label: "【5】その他留意事項", value: monitoring_1.other_5 },
            { label: "計画開始年月", value: startMonth },
            { label: "主な日常生活上の活動", value: monitoring_1.daily_routine },
            { label: "週単位以外のサービス", value: monitoring_1.other_service },
            { label: "サービス提供によって実現する生活の全体像", value: monitoring_1.total },
        ];

        setMonitoring_1ShowData(data);

        if (forRegister) {
            setRefCurrent(data);
        }
        //---------------------------issue{No.440} end-------------------------------
    };

    // 閉じるボタンをクリックしたら、モニタリング報告書情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/monitoring_1/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        //---------------------------issue{No.440} start-----------------------------
        getMonitoring_1Data(idFromRegister ? idFromRegister : id);
        //---------------------------issue{No.440} end-------------------------------
    }, []);

    //---------------------------issue{No.325} start-----------------------------
    // const fileDownload = async (e) => {
    //     e.stopPropagation();

    //     setDownloadState("waiting");
    //     const url = "export_file";
    //     await api
    //         .post(url, monitoring_1Data)
    //         .then(async (res) => {
    //             setDownloadState("doing");
    //             if (res.data.success) {
    //                 const date = monitoring_1Data.date.replace(/-/g, "");
    //                 const clientName = monitoring_1Data.client_name;
    //                 const fileName = `${date}_${clientName}_モニタリング報告書.xlsx`;

    //                 const deleteUrl = "delete_file";
    //                 const downloadUrl = "download_file";
    //                 const data = { path: res.data.result };
    //                 const option = { responseType: "blob" }; // important

    //                 // ダウンロード実行完了後、必ずExportしたファイルを削除する
    //                 api.post(deleteUrl, data);

    //                 // ダウンロードを実行する
    //                 await api.post(downloadUrl, data, option).then((res) => {
    //                     const url = window.URL.createObjectURL(res.data);
    //                     const link = document.createElement("a");
    //                     link.href = url;
    //                     link.setAttribute("download", fileName);
    //                     document.body.appendChild(link);
    //                     link.click();
    //                     link.remove();

    //                     setTimeout(() => {
    //                         setDownloadState("");
    //                     }, 2500);
    //                 });
    //             }
    //         })
    //         .catch(() => {
    //             setDownloadState("");
    //             swal("ファイルの作成に失敗", "", "error");
    //         });
    // };
    //---------------------------issue{No.325} end-------------------------------

    return (
        <>
            {forRegister ? (
                <DataShowForRegister data={monitoring_1ShowData}></DataShowForRegister>
            ) : (
                <div className='row justify-content-center'>
                    <div className='col-md-10 col-lg-10 mx-auto'>
                        <div className='card'>
                            <div className='card-header d-flex'>
                                <h1 className={classes.title}>モニタリング報告書情報</h1>
                                {/* -------------------- issue539 start -------------------- */}
                                <div className='ms-auto'>
                                    <button
                                        className='btn btn-outline-secondary fs-3 lh-1'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                                {/* -------------------- issue539 end -------------------- */}
                            </div>
                            <div className='card-body'>
                                {/* -------------------- issue325 start -------------------- */}
                                {Object.keys(monitoring_1Data).length > 1 && (
                                    <NewOutputExcelButton
                                        fileName={downloadFileName}
                                        api_url={`monitorings_1/${id}/export`}
                                        buttonTextIsFileName={false}
                                    />
                                )}
                                {/* -------------------- issue325 end -------------------- */}
                                <DataShow data={monitoring_1ShowData}></DataShow>
                            </div>
                            <div className='card-footer'>
                                <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                    閉じる
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Monitoring_1Show;
