import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
//---------------------------issue{No.513} start-----------------------------
import { usePostalJp } from "use-postal-jp";
//---------------------------issue{No.513} end-------------------------------
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import { Box, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Zoom } from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { Button, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" }
        }
    })
);

// 初期状態
const initConsultationRequest = { success: true };

// 初期状態
const initOffice = { success: true };

//---------------------------issue{No.513} start-----------------------------
// 郵便番号が不正の場合に表示するメッセージ
const zipCodeErrorMessage = "存在しない郵便番号です";
//---------------------------issue{No.513} end-------------------------------

// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

function ConsultationRequestRegister() {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 利用者のidと、前回作成したデータのidを保持している
    const { client, id } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付けの入力制限のための日付の値の定数を取得
    const { MIN_DATE, MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // 入力値を管理する
    const [consultationRequest, setConsultationRequest] = useState(initConsultationRequest);

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // watch内で使用する利用者の生年月日
    const clientBirthdayRef = useRef(""); // issue516

    //---------------------------issue{No.513} start-----------------------------
    // 別途is_childフィールの値のみ、フラグにする
    // 初期値はtrue（表示に関わるため）
    const [isChild, setIsChild] = useState(true);
    //---------------------------issue{No.513} end-------------------------------

    // office の状態を管理する
    const [office, setOffice] = useState(initOffice);

    // 変更時の表示切り替え用ステート
    const [selectedDivision, setSelectedDivision] = useState(1);

    //---------------------------issue{No.311} start-----------------------------
    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");
    //---------------------------issue{No.311} end-------------------------------

    const [changeDateMessage, setChangeDateMessage] = useState(""); // issue516

    //---------------------------issue{No.513} start-----------------------------
    // 事業所情報を書き換える必要がある場合に、書き換え可能にする(readOnly属性を切り替える)
    // デフォルト値はfalse（readOnly属性はfalseを!でひっくり返した値のtrueで編集不可）
    const [officeEdit, setOfficeEdit] = useState(false);
    //---------------------------issue{No.513} end-------------------------------

    // 情報を取得しステート consultationRequest にセットする
    const getLastConsultationRequestData = async (id) => {
        const url = `consultation_requests/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setConsultationRequest({
                ...res.data.result,
                error_list: null,
                success: res.data.success
            });
        }
    };

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `consultation_requests/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id,
                client_ruby: clientResult.client_ruby,
                client_name: clientResult.client_name,
                client_birthday: clientResult.client_birthday,
                client_zip_code: clientResult.client_zip_code,
                client_address:
                    clientResult.client_address1 + clientResult.client_address2 + clientResult.client_address3 ?? "",
                client_tel: clientResult.client_tel,
                child_ruby: clientResult.child_ruby,
                child_name: clientResult.child_name,
                child_birthday: clientResult.child_birthday,
                relationship: clientResult.relationship
            });

            // is_childのみ別処理を行う
            if (clientResult.is_child == 1) {
                setIsChild(true);
            } else {
                setIsChild(false);
            }
            //---------------------------issue{No.513} end-------------------------------

            //---------------------------issue{No.516} start-------------------------------
            clientBirthdayRef.current =
                clientResult.is_child == 1 ? clientResult.child_birthday : clientResult.client_birthday;
            //---------------------------issue{No.516} end-------------------------------
        }
    };

    // 事業所情報を取得しステート office にセットする
    const getOfficeData = async () => {
        const url = `consultation_requests/office/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const officeResult = res.data.result;
            setOffice({
                office_ruby: officeResult.office_ruby,
                office_name: officeResult.office_name,
                office_zip_code: officeResult.office_zip_code,
                //---------------------------issue{No.513} start-----------------------------
                office_address1: officeResult.office_address1,
                office_address2: officeResult.office_address2,
                office_address3: officeResult.office_address3 ?? "",
                //---------------------------issue{No.513} end-------------------------------
                office_tel: officeResult.office_tel
            });
        }
    };

    //---------------------------issue{No.311} start-----------------------------
    const getDateList = async (clientId) => {
        const url = `consultation_requests_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            const result = res.data.result.map((item) => {
                return item.date;
            });
            dateList.current = result;
        }

        // データ取得時に本日の日付がすでに作成されていないかを判定
        setDateMessage(dateRegisteringErrorMessage(today, clientBirthdayRef.current, dateList.current));
    };
    //---------------------------issue{No.311} end-------------------------------

    // //利用者名クリック時、利用者情報表示画面へ
    // const handleOnClickShow = (e) => {
    //     // イベントの伝搬を中止
    //     e.stopPropagation();

    //     if (client) {
    //         // URL(/client/show/${client.id})をたたく（RouterConfig.js）
    //         navigate(`/client_select/show/${client}`);
    //     }
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 作成ボタン押下時
    const consultationRequestSubmit = async (data) => {
        //---------------------------issue{No.311} start-----------------------------
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "作成内容を見直してください",
                timer: 2000
            });
            return;
        }
        //---------------------------issue{No.311} end-------------------------------

        //---------------------------issue{No.513} start-----------------------------
        // 住所取得のAPI通信が行われている最中の場合
        // 処理を抜けてアラートを表示する
        if (loading) {
            swal({
                icon: "warning",
                title: "住所の取得中です",
                text: "再度お試しください",
                timer: 2000
            });
            return;
        }

        // 住所取得にエラーがある場合
        if (clientAddress1 === "なし") {
            swal({
                icon: "warning",
                title: "郵便番号が不正です",
                text: "利用者情報の郵便番号を見直してください",
                timer: 2000
            });
            return;
        }

        // 住所取得にエラーがある場合
        if (officeAddress1 === "なし") {
            swal({
                icon: "warning",
                title: "郵便番号が不正です",
                text: "事業所情報の郵便番号を見直してください",
                timer: 2000
            });
            return;
        }
        //---------------------------issue{No.513} end-------------------------------

        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "作成しますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        //---------------------------issue{No.513} start-----------------------------
        // ConsultationRequestControllerに渡す値をセット
        const consultationRequestData = {
            ...clientData,
            submission: data.submission,
            date: data.date,
            division: data.division,
            reason: data.reason,
            change_date: data.change_date,
            office_ruby: data.office_ruby,
            office_name: data.office_name,
            relationship2: data.relationship2,
            office_zip_code: data.office_zip_code,
            office_address:
                data.office_address1 + data.office_address2 + (data.office_address3 ? data.office_address3 : ""),
            office_tel: data.office_tel
        };
        //---------------------------issue{No.513} end-------------------------------

        if (getValues("division") == 1) {
            consultationRequestData["reason"] = null;
            consultationRequestData["change_date"] = null;
        }

        // 作成
        const url = "consultation_requests";
        const res = await api.post(url, consultationRequestData);
        if (res.status === 422) {
            setConsultationRequest({
                ...consultationRequest,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/consultation_request/${client}`);
            });
        }
    };

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        getClientData(client);
        getOfficeData(client);
        getDateList(client);
    }, []);

    // ボタンクリック時に前回作成時の内容を、インプット内に引用する。
    const handleOnClickLastData = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 前回のデータを取得する
        if (id) {
            getLastConsultationRequestData(id);
        }
    };

    // 前回作成時のデータを引用する
    useEffect(() => {
        if (Object.keys(consultationRequest).length > 1) {
            // すべてのエラーを削除
            clearErrors();
            setValue("submission", consultationRequest.submission, { shouldValidate: false });
            setValue("division", consultationRequest.division, { shouldValidate: false });
            setSelectedDivision(consultationRequest.division);
            setValue("reason", consultationRequest.reason, { shouldValidate: false });
            setValue("change_date", consultationRequest.change_date, { shouldValidate: false });
        }
    }, [consultationRequest]);

    //---------------------------issue{No.513} start-----------------------------
    // use-postal-jpの初期設定
    const [zipCode, setZipCode] = useState("");
    const [address, loading, error] = usePostalJp(zipCode, zipCode && zipCode.length === 7);
    const [zipCodeSubject, setZipCodeSubject] = useState("");

    // 読み込み時の処理実行についてのフラグ
    const [clientZipCodeFlag, setClientZipCodeFlag] = useState(false);
    const [officeZipCodeFlag, setOfficeZipCodeFlag] = useState(false);

    // 郵便番号により見つかった住所を検証用に保持しておくステート
    const [clientAddress1, setClientAddress1] = useState("");
    const [officeAddress1, setOfficeAddress1] = useState("");

    // 郵便番号による住所取得が行われるたびに、以下の副作用フックを実行
    useEffect(() => {
        if (clientZipCodeFlag && officeZipCodeFlag) {
            // 以下、通常時のaddress用処理
            if (zipCodeSubject === "client") {
                const setClientAddress = async () => {
                    // addressに正しく値が取得できている場合に、tryが実行される
                    try {
                        // addressから、必要な情報を分割代入
                        const { prefecture, address1, address2 } = address;
                        // 文字列の連結
                        const clientAddress = prefecture + address1 + address2;

                        setClientAddress1(clientAddress);
                    } catch {
                        setClientAddress1("なし");
                    }
                };
                // 上記の関数を実行
                setClientAddress();
            }

            if (zipCodeSubject === "office") {
                // 以下、事業所の側の住所取得
                const setOfficeAddress = () => {
                    // addressに正しく値が取得できている場合に、tryが実行される
                    try {
                        // addressから、必要な情報を分割代入
                        const { prefecture, address1, address2 } = address;
                        // 文字列の連結
                        const officeAddress = prefecture + address1 + address2;
                        // react-hook-formのsetValueでaddress1を更新
                        setValue("office_address1", officeAddress, {
                            shouldValidate: true
                        });
                        setOfficeAddress1(officeAddress);
                    } catch {
                        setOfficeAddress1("なし");
                    }
                };
                // 上記の関数を実行
                setOfficeAddress();
            }
        }
    }, [address]);

    // 以下、読み込み時に郵便番号による住所取得を行う処理
    useEffect(() => {
        if (!clientZipCodeFlag || !officeZipCodeFlag) {
            if (zipCodeSubject === "client") {
                const setClientAddress = async () => {
                    // addressに正しく値が取得できている場合に、tryが実行される
                    try {
                        // addressから、必要な情報を分割代入
                        const { prefecture, address1, address2 } = address;
                        // 文字列の連結
                        const clientAddress = prefecture + address1 + address2;
                        setClientAddress1(clientAddress);
                        // 以下のセットステートより読み込み時の初期値の処理を完了する
                        setClientZipCodeFlag(true);
                    } catch {
                        // 取得は実行されたが、エラーとなった場合
                        setClientAddress1("なし");
                        // 以下のセットステートより読み込み時の初期値の処理を完了する
                        setClientZipCodeFlag(true);
                    }
                };

                // 上記の関数を実行
                setClientAddress();

                // 以下、非同期で読み込みが完了するのを待ち、officeの住所取得へつなげる処理
                const asyncLoadingFunc = () => {
                    return new Promise((resolve, reject) => {
                        fetch(!loading)
                            .then(() => {
                                return resolve("完了！");
                            })
                            .catch(() => {
                                return reject("エラー");
                            });
                    });
                };

                asyncLoadingFunc()
                    .then(() => {
                        setZipCodeSubject("office");
                        setZipCode(office.office_zip_code);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }

            if (zipCodeSubject === "office") {
                const setOfficeAddress = async () => {
                    // addressに正しく値が取得できている場合に、tryが実行される
                    try {
                        // addressから、必要な情報を分割代入
                        const { prefecture, address1, address2 } = address;
                        // 文字列の連結
                        const officeAddress = prefecture + address1 + address2;
                        setOfficeAddress1(officeAddress);
                        // 以下のセットステートより読み込み時の初期値の処理を完了する
                        setOfficeZipCodeFlag(true);
                    } catch {
                        // 取得は実行されたが、エラーとなった場合
                        setOfficeAddress1("なし");
                        // 以下のセットステートより読み込み時の初期値の処理を完了する
                        setOfficeZipCodeFlag(true);
                    }
                };

                // 上記の関数を実行
                setOfficeAddress();
            }
        }
    }, [address, error]);
    //---------------------------issue{No.513} end-------------------------------

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        //---------------------------issue{No.513} start-----------------------------
        trigger,
        //---------------------------issue{No.513} end-------------------------------
        watch,
        control,
        clearErrors
    } = useForm();

    //---------------------------issue{No.513} start-----------------------------
    useEffect(() => {
        if (Object.keys(clientData).length > 1) {
            setValue("client_ruby", clientData.client_ruby, { shouldValidate: false });
            setValue("client_name", clientData.client_name, { shouldValidate: false });
            setValue("client_birthday", clientData.client_birthday, { shouldValidate: false });
            setValue("client_zip_code", clientData.client_zip_code, { shouldValidate: false });
            setValue("client_address", clientData.client_address, { shouldValidate: false });
            setValue("client_tel", clientData.client_tel, { shouldValidate: false });
            setValue("child_ruby", clientData.child_ruby, { shouldValidate: false });
            setValue("child_name", clientData.child_name, { shouldValidate: false });
            setValue("child_birthday", clientData.child_birthday, { shouldValidate: false });
            setValue("relationship", clientData.relationship, { shouldValidate: false });

            // 読み込み時に一度、郵便番号による住所取得を行う
            setZipCodeSubject("client");
            setZipCode(clientData.client_zip_code);
        }
    }, [clientData]);

    useEffect(() => {
        if (Object.keys(office).length > 1) {
            setValue("office_ruby", office.office_ruby, { shouldValidate: false });
            setValue("office_name", office.office_name, { shouldValidate: false });
            setValue("office_zip_code", office.office_zip_code, { shouldValidate: false });
            setValue("office_address1", office.office_address1, { shouldValidate: false });
            setValue("office_address2", office.office_address2, { shouldValidate: false });
            setValue("office_address3", office.office_address3, { shouldValidate: false });
            setValue("office_tel", office.office_tel, { shouldValidate: false });
        }
    }, [office]);
    //---------------------------issue{No.513} end-------------------------------

    // inputの入力値が変わる度にそれぞれの項目に応じた処理を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                switch (name) {
                    case "division":
                        setSelectedDivision(value.division);
                        break;
                    case "date":
                        setDateMessage(
                            dateRegisteringErrorMessage(value.date, clientBirthdayRef.current, dateList.current)
                        );
                        break;
                    case "change_date":
                        setChangeDateMessage(dateRegisteringErrorMessage(value.change_date, clientBirthdayRef.current));
                        break;
                    case "client_zip_code":
                        trigger("client_zip_code"); // バリデーション実行
                        if (value.client_zip_code.length === 7) {
                            // 郵便番号をステートにセットし住所を取得します
                            setZipCodeSubject("client");
                            setZipCode(value.client_zip_code);
                        }
                        break;
                    case "office_zip_code":
                        trigger("office_zip_code"); // バリデーション実行
                        if (value.office_zip_code.length === 7) {
                            // 郵便番号をステートにセットし住所を取得します
                            setZipCodeSubject("office");
                            setZipCode(value.office_zip_code);
                        }
                        break;
                    default:
                        break;
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>相談支援依頼作成</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <div className='d-flex justify-content-between mb-4'>
                            <p className='fs-6 pt-1'>
                                <RequiredMark />
                                <span className='text-secondary'>は必須項目です。</span>
                            </p>
                            {id && (
                                <Button
                                    type='button'
                                    variant='contained'
                                    color='primary'
                                    onClick={(e) => {
                                        handleOnClickLastData(e);
                                    }}>
                                    以前に作成済みの内容を入力する
                                    <br />
                                    （※利用者と事業所の情報は対象外）
                                </Button>
                            )}
                        </div>
                        <form onSubmit={handleSubmit(consultationRequestSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='submission'>
                                    届出先
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>（敬称は省略して入力）</small>
                                </label>
                                <input
                                    id='submission'
                                    type='text'
                                    placeholder='例：福祉事務所長'
                                    className='form-control mb-1'
                                    {...register("submission", {
                                        required: {
                                            value: true,
                                            message: "*届出先を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.submission && errors.submission?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.submission}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    届出年月日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    defaultValue={today}
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*届出年月日を入力してください。"
                                        }
                                    })}
                                />
                                {/* 日付の重複によるエラーメッセージ */}
                                <small className='text-danger'>{dateMessage && dateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.date}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='division'>
                                    新規/変更区分
                                    <RequiredMark />
                                </label>
                                <br />
                                <Controller
                                    id='division'
                                    name='division'
                                    defaultValue=''
                                    control={control}
                                    rules={{ required: "*選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <RadioGroup {...field} className='mb-4'>
                                            <FormControlLabel
                                                value='1'
                                                control={<Radio checked={field.value == 1 ? true : false} />}
                                                label='新規'
                                            />
                                            <FormControlLabel
                                                value='2'
                                                control={<Radio checked={field.value == 2 ? true : false} />}
                                                label='変更'
                                            />
                                        </RadioGroup>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.division && errors.division?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.division}
                                </small>
                            </div>
                            {selectedDivision == "2" && (
                                <>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='reason'>
                                            変更理由
                                            <RequiredMark />
                                        </label>
                                        <textarea
                                            id='reason'
                                            className='form-control mb-1'
                                            {...register("reason", {
                                                required: {
                                                    value: true,
                                                    message: "*変更理由を入力してください。"
                                                },
                                                validate: {
                                                    message: (value) => {
                                                        const trimValue = value.trim();
                                                        if (!trimValue) {
                                                            return `*変更理由を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                        } else {
                                                            return null;
                                                        }
                                                    }
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.reason && errors.reason?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {consultationRequest.success ? "" : consultationRequest.error_list.reason}
                                        </small>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1' htmlFor='change_date'>
                                            変更年月日
                                        </label>
                                        <input
                                            id='change_date'
                                            type='date'
                                            min={clientBirthdayRef.current}
                                            max={MAX_DATE}
                                            defaultValue={today}
                                            className='form-control mb-1'
                                            {...register("change_date")}
                                        />
                                        {/* 指定生年月日以前の値入力時のエラー */}
                                        <small className='text-danger'>{changeDateMessage && changeDateMessage}</small>
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.change_date && errors.change_date?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {consultationRequest.success
                                                ? ""
                                                : consultationRequest.error_list.change_date}
                                        </small>
                                    </div>
                                </>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、利用者情報による内容</span>
                            </h3>

                            <h4 className='mb-4'>
                                <span className='border border-2 px-3 fs-5 fw-bold'>申請者</span>
                            </h4>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_ruby'>
                                    利用者氏名（カナ）
                                </label>
                                <input
                                    id='client_ruby'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_ruby", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名（カナ）を入力してください。"
                                        },
                                        pattern: {
                                            value: /^[ァ-ヶ]+[ァ-ヶー]*　?[ァ-ヶ]+[ァ-ヶー]*$/,
                                            message: "*入力内容を確認してください。"
                                        }
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                </label>
                                <input
                                    id='client_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_name", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名を入力してください。"
                                        }
                                    })}
                                />
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='client_birthday'>
                                    生年月日
                                </label>
                                <input
                                    id='client_birthday'
                                    type='date'
                                    min={clientData.client_birthday}
                                    max={clientData.client_birthday}
                                    readOnly
                                    className='form-control mb-1'
                                    {...register("client_birthday", {
                                        required: {
                                            value: true,
                                            message: "*生年月日を入力してください。"
                                        }
                                    })}
                                />
                            </div>
                            <h4 className='mb-4'>
                                <span className='border border-2 px-3 fs-5 fw-bold'>居住地</span>
                            </h4>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_zip_code'>
                                    郵便番号
                                </label>
                                <br />
                                {/* 住所自動入力のエラーメッセージ */}
                                <small className='text-danger'>
                                    {clientAddress1 === "なし" && "※" + zipCodeErrorMessage}
                                </small>
                                <input
                                    id='client_zip_code'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_zip_code", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を入力してください"
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        maxLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください"
                                        }
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_address1'>
                                    住所
                                </label>
                                <input
                                    id='client_address'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_address", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を確認してください"
                                        }
                                    })}
                                />
                            </div>
                            {isChild ? (
                                <>
                                    <h4 className='mb-4'>
                                        <span className='border border-2 px-3 fs-5 fw-bold'>
                                            申請に係る児童について
                                        </span>
                                    </h4>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='child_ruby'>
                                            児童氏名（カナ）
                                        </label>
                                        <input
                                            id='child_ruby'
                                            type='text'
                                            className='form-control mb-1'
                                            readOnly
                                            {...register("child_ruby", {
                                                required: {
                                                    value: true,
                                                    message: "*児童氏名（カナ）  を入力してください"
                                                },
                                                pattern: {
                                                    value: /^[ァ-ヶ]+[ァ-ヶー]*　?[ァ-ヶ]+[ァ-ヶー]*$/,
                                                    message: "*入力内容を確認してください。"
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='child_name'>
                                            児童氏名
                                        </label>
                                        <input
                                            id='child_name'
                                            type='text'
                                            className='form-control mb-1'
                                            readOnly
                                            {...register("child_name", {
                                                required: {
                                                    value: true,
                                                    message: "*児童氏名を入力してください。"
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='child_birthday'>
                                            児童生年月日
                                        </label>
                                        <input
                                            id='child_birthday'
                                            type='date'
                                            min={clientData.child_birthday}
                                            max={clientData.child_birthday}
                                            readOnly
                                            className='form-control mb-1'
                                            {...register("child_birthday", {
                                                required: {
                                                    value: true,
                                                    message: "*児童生年月日を入力してください。"
                                                }
                                            })}
                                        />
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1' htmlFor='relationship'>
                                            児童続柄
                                        </label>
                                        <input
                                            id='relationship'
                                            type='text'
                                            readOnly
                                            className='form-control mb-1'
                                            {...register("relationship", {
                                                required: {
                                                    value: true,
                                                    message: "*児童続柄を入力してください。"
                                                }
                                            })}
                                        />
                                    </div>
                                </>
                            ) : (
                                <h4 className='border-bottom border-secondary mb-5'>
                                    <small className='fs-6 fw-normal text-secondary'>
                                        申請に係る児童氏名および、児童に関する入力はありません。
                                    </small>
                                </h4>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、事業所情報による内容</span>
                            </h3>
                            <h4 className='mb-4'>
                                <span className='border border-2 px-3 fs-5 fw-bold'>事業所名</span>
                            </h4>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_ruby'>
                                    フリガナ
                                    <RequiredMark />
                                    {officeEdit && (
                                        <small className='fw-normal text-secondary'>
                                            （全角カタカナのみ、空白文字・スペース禁止）
                                        </small>
                                    )}
                                </label>
                                <input
                                    id='office_ruby'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly={!officeEdit}
                                    {...register("office_ruby", {
                                        required: {
                                            value: true,
                                            message: "*フリガナを入力してください。"
                                        },
                                        pattern: {
                                            value: /^[ァ-ヶ]+[ァ-ヶー]*$/,
                                            message: "*全角カタカナのみで入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_ruby && errors.office_ruby?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.office_ruby}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_name'>
                                    事業所名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='office_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly={!officeEdit}
                                    {...register("office_name", {
                                        required: {
                                            value: true,
                                            message: "*事業所名を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_name && errors.office_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.office_name}
                                </small>
                            </div>
                            <h4 className='mb-4'>
                                <span className='border border-2 px-3 fs-5 fw-bold'>住所</span>
                            </h4>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_zip_code'>
                                    郵便番号
                                    <RequiredMark />
                                    {officeEdit && (
                                        <small className='fw-normal text-secondary'>
                                            （半角数字のみ、ハイフンなし）
                                        </small>
                                    )}
                                </label>
                                <br />
                                {/* 住所自動入力のエラーメッセージ */}
                                <small className='text-danger'>
                                    {officeAddress1 === "なし" && "※" + zipCodeErrorMessage}
                                </small>
                                <input
                                    id='office_zip_code'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly={!officeEdit}
                                    {...register("office_zip_code", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を入力してください"
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        maxLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_zip_code && errors.office_zip_code?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.office_zip_code}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address1'>
                                    住所1
                                    <RequiredMark />
                                    {officeEdit && (
                                        <small className='fw-normal text-secondary'>
                                            （都道府県、市区町村）※郵便番号による自動入力
                                        </small>
                                    )}
                                </label>
                                <input
                                    id='office_address1'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='郵便番号による自動入力'
                                    readOnly
                                    {...register("office_address1", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を確認してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address1 && errors.office_address1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.office_address1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address2'>
                                    住所2
                                    <RequiredMark />
                                    {officeEdit && (
                                        <small className='fw-normal text-secondary'>（丁目、番地、号）</small>
                                    )}
                                </label>
                                <input
                                    id='office_address2'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly={!officeEdit}
                                    {...register("office_address2", {
                                        required: {
                                            value: true,
                                            message: "*住所を入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address2 && errors.office_address2?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.office_address2}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address3'>
                                    住所3
                                    {officeEdit && (
                                        <small className='fw-normal text-secondary'>
                                            （建物名、階数、部屋番号など）
                                        </small>
                                    )}
                                </label>
                                <input
                                    id='office_address3'
                                    type='text'
                                    className='form-control'
                                    readOnly={!officeEdit}
                                    {...register("office_address3")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address3 && errors.office_address3?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.office_address3}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='office_tel'>
                                    電話番号
                                    <RequiredMark />
                                    {officeEdit && (
                                        <small className='fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                    )}
                                </label>
                                <input
                                    id='office_tel'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly={!officeEdit}
                                    placeholder='000-000-0000'
                                    {...register("office_tel", {
                                        required: {
                                            value: true,
                                            message: "*電話番号を入力してください"
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください"
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください"
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_tel && errors.office_tel?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {consultationRequest.success ? "" : consultationRequest.error_list.office_tel}
                                </small>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    作成
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsultationRequestRegister;
