import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

const initReport = { result: [], success: true };

const taskList = { 1: "面談", 2: "モニタリング", 3: "電話対応", 4: "連絡事項" };

function ReportShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 表示対象の日報のid
    const { user, date } = useParams();

    // report の状態を管理する
    const [report, setReport] = useState(initReport);

    // 一覧情報を取得しステート report にセットする
    const getUsersReportData = async (userId, date) => {
        const url = `reports/users_report/${userId}/${date}`;
        const res = await api.get(url);
        setReport(res.data);
    };

    // 閉じるボタンをクリックしたら、事業所情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();
        navigate(`/report/${user}`);
    };

    // 画面初期表示時に事業所情報を取得する
    useEffect(() => {
        getUsersReportData(user, date);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>日報情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label='simple table'>
                                {/* ヘッダー部分 */}
                                <TableHead className={classes.tableHead}>
                                    <TableRow>
                                        <TableCell align='center'>分類</TableCell>
                                        <TableCell align='center'>時間</TableCell>
                                        <TableCell align='center'>名前</TableCell>
                                        <TableCell align='center' className='w-50'>
                                            内容
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {/* ボディ部分 */}
                                <TableBody>
                                    {Object.keys(report.result).length > 0 &&
                                        report.result.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell align='center'>{taskList[item["task"]]}</TableCell>
                                                <TableCell align='center' size={"small"}>
                                                    {item["start_time"] === null ? "" : item["start_time"].substr(0, 5)}
                                                    ～{item["end_time"] === null ? "" : item["end_time"].substr(0, 5)}
                                                </TableCell>
                                                <TableCell align='center'>
                                                    {item["child_name"] ? item["child_name"] : item["client_name"]}
                                                </TableCell>
                                                <TableCell align='center' className='w-50'>
                                                    {item["detail"]}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportShow;
