import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../api/api";
import DataShow from "./DataShow";
import dateFormat from "../common/dateFormat";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

// 表示用の各データに応じた値を配列で用意
const authorities = { 0: "DNS管理者", 10: "DNS運用者", 20: "会社代表者", 30: "事業所管理者", 40: "事業所スタッフ" };

function UserSelectShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 表示対象のユーザid
    const { id } = useParams();

    // 表示するuser の状態を管理する
    const [userShowData, setUserShowData] = useState([]);

    //一覧情報を取得しステート user にセットする
    const getUserData = async (id) => {
        const url = `users/${id}`;
        const res = await api.get(url);
        setUserRow(res.data.result);
    };

    // ステートに値をセット
    const setUserRow = (user) => {
        const userAuthority = authorities[user.authority];

        setUserShowData([
            { label: "会社コード", value: user.company_name },
            { label: "事業所コード", value: user.office_name },
            { label: "ユーザＩＤ", value: user.user_id },
            { label: "ユーザ名", value: user.user_name },
            { label: "権限", value: userAuthority },
            { label: "メールアドレス", value: user.mail },
        ]);
    };

    // 閉じるボタンをクリックしたら、ユーザ情報一覧画面に戻る
    const handleOnClickClose = (e) => {
        e.stopPropagation();
        navigate(-1);
    };

    // 画面初期表示時にユーザ情報を取得する
    useEffect(() => {
        getUserData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>ユーザー情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={userShowData}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserSelectShow;
