import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 650,
        },
        tableHead: {
            // backgroundColor: purple['A100'],
        },
        tableCell: {
            padding: 3,
        },
        //---------------------------issue{No.450} start-----------------------------
        clickableCell: {
            cursor: "pointer",
            padding: 3,
        },
        //---------------------------issue{No.450} end-------------------------------
        tableRowActive: {
            // cursor: "pointer", // issue#450
            "&:hover": {
                background: "#e3f2fd",
                boxShadow: "0 0 1px 1px #c5cae9 inset",
            },
        },
        tableRowInActive: {
            // cursor: "pointer", // issue#450
            backgroundColor: "#aaa",
            "&:hover": {
                background: "#bbb",
            },
        },
    })
);

export default function DataTable(props) {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //親コンポーネントからpropsで受け取る
    const { headers = [], rows = [], handleOnRowClick = (e, row) => {} } = props;

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                {/* ヘッダー部分 */}
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        {headers.map((item, index) => (
                            <TableCell className={classes.tableCell} align='center' key={index}>
                                {item}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                {/* ボディ部分 */}
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                            // issue#450
                            onClick={(e) => handleOnRowClick(e, row)}
                            className={row.isActive ? classes.tableRowActive : classes.tableRowInActive}>
                            {Object.keys(row.data).map(function (key, i) {
                                return (
                                    //---------------------------issue{No.450} start-----------------------------
                                    <TableCell
                                        className={
                                            row.data[key].type?.options?.name !== "MuiButton"
                                                ? classes.clickableCell
                                                : classes.tableCell
                                        }
                                        align='center'
                                        key={i}
                                        onClick={(e) => {
                                            row.data[key].type?.options?.name !== "MuiButton" &&
                                                handleOnRowClick(e, row);
                                        }}>
                                        {row.data[key]}
                                    </TableCell>
                                    //---------------------------issue{No.450} end-------------------------------
                                );
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
