import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const useStyles = makeStyles((theme) =>
    createStyles({
        labelCell: {
            backgroundColor: "#e3f7fe",
            fontSize: "1rem",
            fontWeight: "bold",
        },
        valueCell: {
            fontSize: "1rem",
        },
    })
);

export default function DataShowForRegister(props) {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //親コンポーネントから表示するデータを受け取る
    const { data = [] } = props;

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label='simple table'>
                {data.map((item, index) => (
                    <TableBody key={index}>
                        <TableRow>
                            <TableCell className={classes.labelCell}>{item.label}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.valueCell}>
                                {item.value && (
                                    <TextareaAutosize
                                        aria-label='Show data textarea'
                                        className='form-control'
                                        value={item.value}
                                        readOnly
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                ))}
            </Table>
        </TableContainer>
    );
}
