import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import { Box, Button, Tooltip, Select, MenuItem, Zoom } from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" }
        },
        priority: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004"
        },
        priorityNum: { lineHeight: "2rem" }
    })
);

// 初期状態
const initDischargeAddition = { success: true };

// 日付をyyyymmddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + m + d;
}
// 日付を取得
const numToday = Number(dayFormat(new Date()));

function DischargeAdditionEdit() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //利用者idと相談支援支給申請のidを保持している
    const { client, id } = useParams();

    // 日付けの入力制限のための日付の値の定数を取得
    const { MIN_DATE, MAX_DATE } = MIN_MAX_DATE;

    // 入力値を管理する
    const [dischargeAddition, setDischargeAddition] = useState(initDischargeAddition);

    //clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    //---------------------------issue{No.513} start-----------------------------
    // 最新のクライアント情報に更新するボタンの表示の判定に使う
    const [clientChangeable, setClientChangeable] = useState(false);

    // レコードと最新のクライアント情報を比較し見つかった変更箇所の値を管理する
    const [implementClientData, setImplementClientData] = useState({});

    // 最新のクライアント情報をリクエスト送信に含めるかを管理する
    const [runImplementClientChanges, setRunImplementClientChanges] = useState(false);

    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    // 今日以前の直近の作成済みレコードの日付を数値で保存する
    // const [mostRecentDate, setMostRecentDate] = useState(0);

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 優先順位ごとの入力欄の表示個数を管理する
    const [priorityCount, setPriorityCount] = useState(1);

    //情報を取得しステート dischargeAdditionにセットする
    const getDischargeAdditionData = async (id) => {
        const url = `discharge_additions/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 支援目標の入力欄の表示個数を格納する
            let newPriorityCount = 1;
            // 支援目標の登録数に応じて、必要な入力欄の個数を調べる
            for (let ii = 2; ii < 4; ii++) {
                if (res.data.result[`pic_${ii}`]) {
                    newPriorityCount = ii;
                }
            }
            // 結果をステートにセットし、入力欄を表示させる
            setPriorityCount(newPriorityCount);

            setDischargeAddition({
                ...res.data.result,
                error_list: null,
                success: res.data.success
            });
        }
    };

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `discharge_additions/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                user_name: clientResult.user_name
            });
        }
    };
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name
                };
            });
            setUsers(usersName);
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 保存ボタン押下時
    const dischargeAdditionSubmit = async (data) => {
        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;

        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // DischargeAdditionControllerに渡す値をセット
        const dischargeAdditionData = {
            company_id: clientData.company_id,
            office_id: clientData.office_id,
            user_id: clientData.user_id,
            client_id: clientData.client_id,
            client_name: clientData.client_name,
            date: data.date,
            user_name: data.user_name,
            pic_1: data.pic_1,
            pic_2: data?.pic_2 ? data.pic_2 : null,
            pic_3: data?.pic_3 ? data.pic_3 : null,
            date_1: data.date_1,
            date_2: data?.date_2 ? data.date_2 : null,
            date_3: data?.date_3 ? data.date_3 : null,
            time_1: data.time_1,
            time_2: data?.time_2 ? data.time_2 : null,
            time_3: data?.time_3 ? data.time_3 : null,
            location_1: data.location_1,
            location_2: data?.location_2 ? data.location_2 : null,
            location_3: data?.location_3 ? data.location_3 : null,
            detail_1: data.detail_1,
            detail_2: data?.detail_2 ? data.detail_2 : null,
            detail_3: data?.detail_3 ? data.detail_3 : null,
            reflection_matter: data.reflection_matter
        };
        //保存
        const url = `discharge_additions/${id}`;
        const res = await api.put(url, dischargeAdditionData);
        if (res.status === 422) {
            setDischargeAddition({
                ...dischargeAddition,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/discharge_addition/${client}`);
            });
        }
    };

    //---------------------------issue{No.513} start-----------------------------
    // 更新内容を引用する準備ができたらステートを更新する
    useEffect(() => {
        if (Object.keys(implementClientData).length > 0) {
            setClientChangeable(true);
            setCitingClient("ready");
        }
    }, [implementClientData]);
    //---------------------------issue{No.513} end-------------------------------

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getDischargeAdditionData(id);
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        // getDateList(client);
    }, []);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        //---------------------------issue{No.471} start-----------------------------
        control,
        //---------------------------issue{No.471} end-------------------------------
        //---------------------------issue{No.453} start-----------------------------
        watch
        //---------------------------issue{No.453} end-------------------------------
    } = useForm();

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(dischargeAddition).length > 1 && !getValues("date")) {
            setValue("date", dischargeAddition.date, { shouldValidate: false });
            setValue("user_name", dischargeAddition.user_name, { shouldValidate: false });
            setValue("pic_1", dischargeAddition.pic_1, { shouldValidate: false });
            setValue("pic_2", dischargeAddition.pic_2, { shouldValidate: false });
            setValue("pic_3", dischargeAddition.pic_3, { shouldValidate: false });
            setValue("date_1", dischargeAddition.date_1, { shouldValidate: false });
            setValue("date_2", dischargeAddition.date_2, { shouldValidate: false });
            setValue("date_3", dischargeAddition.date_3, { shouldValidate: false });
            setValue("time_1", dischargeAddition.time_1, { shouldValidate: false });
            setValue("time_2", dischargeAddition.time_2, { shouldValidate: false });
            setValue("time_3", dischargeAddition.time_3, { shouldValidate: false });
            setValue("location_1", dischargeAddition.location_1, { shouldValidate: false });
            setValue("location_2", dischargeAddition.location_2, { shouldValidate: false });
            setValue("location_3", dischargeAddition.location_3, { shouldValidate: false });
            setValue("detail_1", dischargeAddition.detail_1, { shouldValidate: false });
            setValue("detail_2", dischargeAddition.detail_2, { shouldValidate: false });
            setValue("detail_3", dischargeAddition.detail_3, { shouldValidate: false });
            setValue("reflection_matter", dischargeAddition.reflection_matter, { shouldValidate: false });
            setValue("client_name", dischargeAddition.client_name, { shouldValidate: false });
        }
    }, [dischargeAddition]);

    const dateCollation = (inputDate) => {
        const result = dateList.current.includes(inputDate);
        if (result) {
            return true;
        } else {
            return false;
        }
    };
    //---------------------------issue{No.453} end-------------------------------

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        if (priorityCount < 3) {
            setPriorityCount((priorityCount) => priorityCount + 1);
        }
    };
    const handleOnClickPriorityChange = (e, num) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const higherPriority = {
            pic: `pic_${num}`,
            date: `date_${num}`,
            time: `time_${num}`,
            location: `location_${num}`,
            detail: `detail_${num}`
        };

        // 優先順位が一つ上がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const lowerPriority = {
            pic: `pic_${num + 1}`,
            date: `date_${num + 1}`,
            time: `time_${num + 1}`,
            location: `location_${num + 1}`,
            detail: `detail_${num + 1}`
        };

        // 入れ替えの際に、値を一時的に控えておく定数オブジェクト
        const reserveValues = {
            pic: null,
            date: null,
            time: null,
            location: null,
            detail: null
        };

        // 優先順位が下がる側の入力欄の値を控えておく
        for (let key of Object.keys(higherPriority)) {
            const value = getValues(higherPriority[key]);
            reserveValues[key] = value;
        }

        // 優先順位の下の入力欄の値を上の入力欄に移し替える
        for (let key of Object.keys(higherPriority)) {
            const newValue = getValues(lowerPriority[key]);
            setValue(higherPriority[key], newValue, { shouldValidate: false });
        }

        // 控えておいた値を優先順位の下の入力欄に差し替える
        for (let key of Object.keys(reserveValues)) {
            setValue(lowerPriority[key], reserveValues[key], { shouldValidate: false });
        }
    };

    // 支援目標の各優先順位の右上にある✕ボタン押下時に、支援目標を削除したり空欄に戻したりする処理
    const handleOnClickPriorityClear = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この支援目標を削除して続く優先順位を上に詰める、もしくはこの支援目標に関する入力内容をクリアしますか？",
            buttons: {
                catch: {
                    text: "入力内容をクリア",
                    value: "clear",
                    visible: true,
                    className:
                        "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                    closeModal: true
                },
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        }).then((value) => {
            switch (value) {
                case "clear":
                    // 選択範囲をクリアする
                    PriorityInputClear(num);
                    break;

                case "cancel":
                    break;

                case "delete":
                    // 先ず、対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    // 現在表示されている優先順位の個数分、優先順位を入れ替える関数を順に実行し、繰上げを行い
                    // ✕ボタンが押下され、上記の関数によって空欄になった入力欄を最下位に下げる
                    for (let ii = num; ii < priorityCount; ii++) {
                        handleOnClickPriorityChange(false, ii);
                    }
                    // 表示される優先順位の入力欄を減らす
                    setPriorityCount((priorityCount) => priorityCount - 1);
                    break;
                default:
                    break;
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const PriorityInputClear = (num) => {
        // 対象の優先順位の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = [
            `pic_${num}`,
            `date_${num}`,
            `time_${num}`,
            `location_${num}`,
            `detail_${num}`,
            `reflection_matter_${num}`
        ];

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, null, { shouldValidate: false });
        }
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>退院・退所加算 編集</h1>
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                    </div>
                    <div className='card-body'>
                        {runImplementClientChanges && (
                            <div className='text-end mb-2'>
                                <p className='d-inline-block text-decoration-underline'>
                                    ※保存時に現在の利用者情報の担当職員が適用されます。
                                </p>
                            </div>
                        )}
                        <div className='card-body'>
                            <p className='fs-6 pt-1 mb-5'>
                                <RequiredMark />
                                <span className='text-secondary'>は必須項目です。</span>
                            </p>
                        </div>
                        <form onSubmit={handleSubmit(dischargeAdditionSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    作成日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    className='form-control mb-1'
                                    readOnly={true}
                                    {...register("date", {})}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='client_name'
                                    type='text'
                                    className='form-control mb-1'
                                    defaultValue={clientData.client_name}
                                    readOnly={true}
                                    {...register("client_name", {})}
                                />
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1'>
                                    相談支援専門員氏名
                                    <RequiredMark />
                                </label>
                                {Object.keys(users).length > 0 && (
                                    <Controller
                                        name='user_name'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*相談支援専門員名を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        {Object.keys(users).length === 0 ? (
                                                            <>ユーザーが登録されていません</>
                                                        ) : (
                                                            <>選択してください</>
                                                        )}
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.user_name}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                )}
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                <small className='text-danger'>
                                    {dischargeAddition.success ? "" : dischargeAddition.error_list.user_name}
                                </small>
                            </div>
                            <hr />
                            <div className={classes.priority}>
                                <span className={classes.priorityNum}>【1】</span>
                                {priorityCount > 1 && (
                                    <>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            並び順を下げる<span className='fs-6 px-1'>▼</span>
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 1);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='pic_1'>
                                    機関名（担当者）
                                    <RequiredMark />
                                </label>
                                <input
                                    id='pic_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("pic_1", {
                                        required: {
                                            value: true,
                                            message: "*機関名（担当者）を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.pic_1 && errors.pic_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {dischargeAddition.success ? "" : dischargeAddition.error_list.pic_1}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='date_1'>
                                    年月日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date_1'
                                    type='date'
                                    className='form-control mb-1'
                                    {...register("date_1", {
                                        required: {
                                            value: true,
                                            message: "*年月日を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date_1 && errors.date_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {dischargeAddition.success ? "" : dischargeAddition.error_list.date_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='time_1'>
                                    時間
                                    <RequiredMark />
                                </label>
                                <input
                                    id='time_1'
                                    type='time'
                                    className='form-control mb-1'
                                    {...register("time_1", {
                                        required: {
                                            value: true,
                                            message: "*時間を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.time_1 && errors.time_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {dischargeAddition.success ? "" : dischargeAddition.error_list.time_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='location_1'>
                                    場所
                                    <RequiredMark />
                                </label>
                                <input
                                    id='location_1'
                                    className='form-control mb-1'
                                    {...register("location_1", {
                                        required: {
                                            value: true,
                                            message: "*場所を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.location_1 && errors.location_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {dischargeAddition.success ? "" : dischargeAddition.error_list.location_1}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='detail_1'>
                                    情報交換等の内容
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='detail_1'
                                    className='form-control mb-1'
                                    {...register("detail_1", {
                                        required: {
                                            value: true,
                                            message: "*情報交換等の内容を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.detail_1 && errors.detail_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {dischargeAddition.success ? "" : dischargeAddition.error_list.detail_1}
                                </small>
                            </div>

                            {priorityCount >= 2 && (
                                <>
                                    <span className={classes.priorityNum}>【2】</span>
                                    <div className={classes.priority}>
                                        {priorityCount > 2 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 2);
                                                }}>
                                                並び順を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>並び順を上げる
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 2);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1' htmlFor='pic_2'>
                                            機関名（担当者）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='pic_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("pic_2", {
                                                required: {
                                                    value: true,
                                                    message: "*機関名（担当者）を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.pic_2 && errors.pic_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.pic_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1' htmlFor='date_2'>
                                            年月日
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='date_2'
                                            type='date'
                                            className='form-control mb-1'
                                            {...register("date_2", {
                                                required: {
                                                    value: true,
                                                    message: "*年月日を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.date_2 && errors.date_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.date_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='time_2'>
                                            時間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='time_2'
                                            type='time'
                                            className='form-control mb-1'
                                            {...register("time_2", {
                                                required: {
                                                    value: true,
                                                    message: "*時間を入力してくださいい。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.time_2 && errors.time_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.time_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='location_2'>
                                            場所
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='location_2'
                                            className='form-control mb-1'
                                            {...register("location_2", {
                                                required: {
                                                    value: true,
                                                    message: "*場所を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.location_2 && errors.location_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.location_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1' htmlFor='detail_2'>
                                            情報交換等の内容
                                            <RequiredMark />
                                        </label>
                                        <textarea
                                            id='detail_2'
                                            className='form-control mb-1'
                                            {...register("detail_2", {
                                                required: {
                                                    value: true,
                                                    message: "*情報交換等の内容を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.detail_2 && errors.detail_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.detail_2}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount === 3 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【3】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 2);
                                            }}>
                                            並び順を上げる<span className='fs-6 px-1'>▲</span>
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 3);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1' htmlFor='pic_3'>
                                            機関名（担当者）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='pic_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("pic_3", {
                                                required: {
                                                    value: true,
                                                    message: "*機関名（担当者）を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.pic_3 && errors.pic_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.pic_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1' htmlFor='date_3'>
                                            年月日
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='date_3'
                                            type='date'
                                            className='form-control mb-1'
                                            {...register("date_3", {
                                                required: {
                                                    value: true,
                                                    message: "*年月日を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.date_3 && errors.date_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.date_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='time_3'>
                                            時間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='time_3'
                                            type='time'
                                            className='form-control mb-1'
                                            {...register("time_3", {
                                                required: {
                                                    value: true,
                                                    message: "*時間を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.time_3 && errors.time_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.time_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='location_3'>
                                            場所
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='location_3'
                                            className='form-control mb-1'
                                            {...register("location_3", {
                                                required: {
                                                    value: true,
                                                    message: "*場所を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.location_3 && errors.location_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.location_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1' htmlFor='detail_3'>
                                            情報交換等の内容
                                            <RequiredMark />
                                        </label>
                                        <textarea
                                            id='detail_3'
                                            className='form-control mb-1'
                                            {...register("detail_3", {
                                                required: {
                                                    value: true,
                                                    message: "*情報交換等の内容を入力してください。"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.detail_3 && errors.detail_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {dischargeAddition.success ? "" : dischargeAddition.error_list.detail_3}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount < 3 && (
                                <div className='w-100 mb-5'>
                                    <hr />
                                    <div className='text-center'>
                                        <button
                                            type='button'
                                            className='btn btn-outline-primary'
                                            onClick={(e) => {
                                                handleOnClickAddInput(e);
                                            }}>
                                            <span className='fw-bold fs-5 px-1'>＋</span>
                                            支援目標を追加する
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr />
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='reflection_matter'>
                                    情報交換の結果からサービス等利用計画書に反映されるべき事項
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='reflection_matter'
                                    className='form-control mb-1'
                                    {...register("reflection_matter", {
                                        required: {
                                            value: true,
                                            message: "*反映事項を入力してください。"
                                        }
                                    })}></textarea>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.reflection_matter && errors.reflection_matter?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {dischargeAddition.success ? "" : dischargeAddition.error_list.reflection_matter}
                                </small>
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default DischargeAdditionEdit;
