import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

function OfficeDeletedShow() {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 表示対象の【削除済み】事業所情報のid
    const { id } = useParams();

    // office の状態を管理する
    const [office, setOffice] = useState([]);

    //一覧情報を取得しステート office にセットする
    const getOfficeData = async (id) => {
        const url = `offices/${id}/deleted`;
        const res = await api.get(url);
        setOfficeRow(res.data.result);
    };

    // 各ステートに値をセット
    const setOfficeRow = (office) => {
        setOffice([
            { label: "法人番号", value: office.company_id },
            { label: "会社名", value: office.company_name },
            { label: "事業所コード", value: office.office_id },
            { label: "事業所番号", value: office.office_no },
            { label: "事業所名", value: office.office_name },
            { label: "事業所名（カナ）", value: office.office_ruby },
            { label: "郵便番号", value: office.office_zip_code },
            { label: "住所１", value: office.office_address1 },
            { label: "住所２", value: office.office_address2 },
            { label: "住所３", value: office.office_address3 },
            { label: "電話番号", value: office.office_tel },
            { label: "ＦＡＸ番号", value: office.office_fax },
            { label: "事業名", value: office.business_name },
            { label: "管理者名", value: office.manager_name },
        ]);
    };

    // 閉じるボタンをクリックしたら、【削除済み】事業所情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // URLをたたく
        navigate(`/office_deleted`);
    };

    //画面に到着したら getOfficeData を呼ぶ
    useEffect(() => {
        getOfficeData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】事業所情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={office}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OfficeDeletedShow;
