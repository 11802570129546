import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Box, Button } from "@material-ui/core";

function BackHomeButton() {
    //画面遷移用
    const navigate = useNavigate();

    // 戻るボタンをクリックしたら、情報一覧画面に戻る
    const handleOnClickBackHome = (e) => {
        e.stopPropagation();
        navigate("/home");
    };

    return (
        <Box sx={{ width: "max-content", mr: 1 }}>
            <Button variant='contained' onClick={(e) => handleOnClickBackHome(e)}>
                戻る
            </Button>
        </Box>
    );
}

export default BackHomeButton;
