import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import menuConfig from "../layout/menuConfig";
import { UserContext } from "../providers/UserProvider";

export default function MenuButton(props) {
    const { userInfo } = useContext(UserContext);
    const isLogin = userInfo.isLogin;

    //画面遷移するため
    const navigate = useNavigate();
    //プロパティ受け取り
    const category = props.category;

    const handleClick = (e, path) => {
        e.stopPropagation();
        navigate(path);
    };

    const menuItems = menuConfig.filter((item) => {
        if (item.category === category && item.auth === isLogin) {
            return item;
        }
    });

    if (menuItems.length !== 1) {
        return null;
    }

    return (
        <Button className={`m-1`} onClick={(e) => handleClick(e, menuItems[0].path)}>
            {menuItems[0].category}
        </Button>
    );
}
