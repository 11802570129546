import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { Button, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataTable from "../../components/DataTable";
import BackButton from "../../components/BackButton"; // issue514

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

//見出しの配列定義
const headers = ["ユーザーID", "名前", "会社名", "事業所名", "権限", "復活"];

// ユーザ情報用配列の初期状態
const initUsers = { result: [], success: true };

function UserDeleted() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //Users の状態を管理する
    const [users, setUsers] = useState(initUsers);

    //Users を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //情報を取得しステート users にセットする
    const getUsersData = async () => {
        const url = `users_deleted`;
        const res = await api.get(url);
        setUsers(res.data);
    };

    // 一覧の行クリック時、【削除済み】ユーザ情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行のユーザのid(key)と一致するUsersステートのユーザ情報を変数に代入
        const user = users.result.find((user) => user.id === id);
        // userに値が存在する（0, "", undefined, nullでない）場合
        if (user) {
            // URL（/user_deleted/show/${user.id}）のリクエスト（RouterConfig.js）
            navigate(`/user_deleted/show/${id}`);
        }
    };

    // 一覧の復活ボタンクリック時、論理削除済みユーザ情報を復活
    const handleOnClickRestore = async (e, id) => {
        e.stopPropagation();

        //復活
        const url = `users/${id}/restore`;
        const res = await api.put(url);
        if (res.data.success) {
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 復活したユーザを除外した全ユーザを配列化
                const newUsers = users.result.filter((user) => user.id !== id);
                // ステートを更新
                setUsers({ result: newUsers, success: true });
            });
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/users");
    };
    //---------------------------issue{No.539} end-------------------------------

    // 画面初期表示時にユーザ情報を取得する
    useEffect(() => {
        getUsersData();
    }, []);

    //Usersに応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!users || !users.success) {
            return;
        }

        const newRows = users.result.map((user) => {
            return {
                id: user.id,
                isActive: user.deleted_at === null,
                data: {
                    userId: user.user_id,
                    user_name: user.user_name,
                    company_name: user.company_name,
                    office_name: user.office_name,
                    authority: user.authority_name,
                    deleteBtn: (
                        <Button variant='contained' onClick={(e) => handleOnClickRestore(e, user.id)}>
                            復活
                        </Button>
                    ),
                },
            };
        });
        setRows(newRows);
    }, [users]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】ユーザー情報一覧</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        {/* テーブル部分の定義 */}
                        <DataTable
                            headers={headers}
                            rows={rows}
                            handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                        />
                    </Card>
                    {/* -------------------- issue514 start -------------------- */}
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton />
                        </div>
                    </div>
                    {/* -------------------- issue514 start -------------------- */}
                </div>
            </div>
        </div>
    );
}

export default UserDeleted;
