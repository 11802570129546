export default function dateFormat(date, japaneseCalendar = false, usePart = "Y-m-d") {
    if (!date) {
        return { format_date: "", obj: {} };
    }

    if (String(date).match(/^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) === null) {
        return { format_date: "", obj: {} };
    }

    const ymd = date.split("-");
    const numericallyDate = Number(ymd[0] + ymd[1] + ymd[2]);
    let formatDate = "";
    if (japaneseCalendar) {
        if (numericallyDate >= 20190501) {
            let japaneseYear = Number(ymd[0].slice(-2)) - 18;
            if (japaneseYear === 1) {
                japaneseYear = "元";
            }
            ymd[0] = { 元号: "令和", year: japaneseYear };
        } else if (numericallyDate >= 19890108) {
            let japaneseYear = Number((ymd[0].slice(-2) + 12).slice(-2));
            if (japaneseYear === 1) {
                japaneseYear = "元";
            }
            ymd[0] = { 元号: "平成", year: japaneseYear };
        } else if (numericallyDate >= 19261225) {
            let japaneseYear = Number(ymd[0].slice(-2)) - 25;
            if (japaneseYear === 1) {
                japaneseYear = "元";
            }
            ymd[0] = { 元号: "昭和", year: japaneseYear };
        } else if (numericallyDate >= 19120101) {
            let japaneseYear = Number(ymd[0].slice(-2)) - 11;
            if (japaneseYear === 1) {
                japaneseYear = "元";
            }
            ymd[0] = { 元号: "大正", year: japaneseYear };
        } else if (numericallyDate >= 18680101) {
            let japaneseYear = Number((ymd[0].slice(-2) + 33).slice(-2));
            if (japaneseYear === 1) {
                japaneseYear = "元";
            }
            ymd[0] = { 元号: "明治", year: japaneseYear };
        } else {
            const year = ymd[0];
            ymd[0] = { 元号: "", year: year };
        }
    } else {
        const year = ymd[0];
        ymd[0] = { 元号: "", year: year };
    }

    if (usePart === "Y-m") {
        formatDate = `${ymd[0]["元号"]}${ymd[0]["year"]}年${Number(ymd[1])}月`;
    } else {
        formatDate = `${ymd[0]["元号"]}${ymd[0]["year"]}年${Number(ymd[1])}月${Number(ymd[2])}日`;
    }

    const result = { format_date: formatDate, obj: ymd };
    return result;
}
