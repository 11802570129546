import React from "react";
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@material-ui/core";

//内容の配列定義
const scheduleDetail = ["", "計画相談", "モニタリング"];
const headers = ["利用者氏名", "行政区画", "内容"];

export default function ScheduleTable(props) {
    const { data, isOther } = props;

    return (
        <div className='mb-5'>
            {isOther && <p className='ms-4 fw-bold'>{data.user_name}</p>}
            <div className='row justify-content-around'>
                {/* 今月の予定 */}
                <div className='col-5'>
                    {data.this_month.length > 0 ? (
                        <>
                            <p className='mb-2'>今月の予定</p>
                            <Paper>
                                <TableContainer>
                                    <Table>
                                        <TableHead className='bg-primary bg-opacity-25'>
                                            <TableRow>
                                                {headers.map((header, headerIndex) => (
                                                    <TableCell key={`headerIndex_${headerIndex}`}>{header}</TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.this_month.map((thisMonth, thisMonthIndex) => (
                                                <TableRow key={`thisMonthIndex_${thisMonthIndex}`}>
                                                    <TableCell>
                                                        {thisMonth.child_name ?? thisMonth.client_name}
                                                    </TableCell>
                                                    <TableCell>{thisMonth.name}</TableCell>
                                                    <TableCell>{scheduleDetail[thisMonth.schedule_no]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </>
                    ) : (
                        <p>今月の予定はありません</p>
                    )}
                </div>
                {/* 今月の予定 END */}
                {/* 来月の予定 */}
                <div className='col-5'>
                    {data.next_month.length > 0 ? (
                        <>
                            <p className='mb-2'>来月の予定</p>
                            <Paper>
                                <TableContainer>
                                    <Table>
                                        <TableHead className='bg-success bg-opacity-25'>
                                            <TableRow>
                                                {headers.map((header2, headerIndex2) => (
                                                    <TableCell key={`headerIndex2_${headerIndex2}`}>
                                                        {header2}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.next_month.map((nextMonth, nextMonthIndex) => (
                                                <TableRow key={`nextMonthIndex_${nextMonthIndex}`}>
                                                    <TableCell>
                                                        {nextMonth.child_name ?? nextMonth.client_name}
                                                    </TableCell>
                                                    <TableCell>{nextMonth.name}</TableCell>
                                                    <TableCell>{scheduleDetail[nextMonth.schedule_no]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </>
                    ) : (
                        <p>来月の予定はありません</p>
                    )}
                </div>
                {/* 来月の予定 END */}
            </div>
        </div>
    );
}
