import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import { Button, Tooltip, Select, MenuItem, Slide, Fade } from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import { UserContext } from "../../providers/UserProvider";
import BackButton from "../../components/BackButton";
import DataShowForRegister from "../../components/DataShowForRegister";
import ProposedPlan_1Show from "../proposed_plan_1/ProposedPlan_1Show";
import Monitoring_1Show from "../monitoring_1/Monitoring_1Show";
import Plan_1Show from "./Plan_1Show";
import { MIN_MAX_DATE } from "../../common/constants";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";
import formatSupportLevelList from "../../common/formatSupportLevelList";

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        dataLink: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        priority: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004",
        },
        priorityNum: { lineHeight: "2rem" },
    })
);

// 初期状態
const initPlan_1 = { success: true };

//---------------------------issue{No.440} start-----------------------------
// 初期状態
const initLastPlan_1 = { success: true };

// 初期状態
const initProposedPlan_1 = { success: true };

// 初期状態
const initMonitoring_1 = { success: true };
//---------------------------issue{No.440} end-------------------------------

//---------------------------issue{No.513} start-----------------------------
//---------------------------issue{No.513} end-------------------------------

// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

function Plan_1Register() {
    //---------------------------issue{No.471} start-----------------------------
    // ログイン中のユーザー情報を受け取る
    const { userInfo } = useContext(UserContext);
    //---------------------------issue{No.471} end-------------------------------

    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 利用者のidと、前回作成したデータのidを保持している
    const { client, id } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付の入力制限を設ける為に日付の上下限値として用いる定数を読み込みます
    const { MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // 入力値を管理する
    const [plan_1, setPlan_1] = useState(initPlan_1);

    //---------------------------issue{No.440} start-----------------------------
    // 以前の作成時の値を管理する
    const [lastPlan_1, setLastPlan_1] = useState(initLastPlan_1);

    // 作成時に参照するサービス等利用計画案の値を管理する
    const [proposedPlan_1, setProposedPlan_1] = useState(initProposedPlan_1);

    // 作成時に参照するモニタリング報告書の値を管理する
    const [monitoring_1, setMonitoring_1] = useState(initMonitoring_1);

    // 引用に関して
    const [citing, setCiting] = useState("");
    // 作成する状況を判断
    const [updateMode, setUpdateMode] = useState(false);

    // 参照するデータを所持しておくRefオブジェクト
    const lastPlan_1Ref = useRef(null);
    const setLastPlan_1RefCurrent = (data) => {
        lastPlan_1Ref.current = data;
    };

    const proposedPlan_1Ref = useRef(null);
    const setProposedPlan_1RefCurrent = (data) => {
        proposedPlan_1Ref.current = data;
    };

    const monitoring_1Ref = useRef(null);
    const setMonitoring_1RefCurrent = (data) => {
        monitoring_1Ref.current = data;
    };

    // MUIのSlideタグの属性値を操作する
    const [slideOn, setSlideOn] = useState(false);

    // MUIのFadeタグの属性値を操作する（初期値：true）
    const [fadeOn, setFadeOn] = useState(true);

    // 参照用のコンポーネントの見出しに使う文字列を管理する
    const [slideTitle, setSlideTitle] = useState("");

    // 参照しているデータの識別子
    const [displayDataNum, setDisplayDataNum] = useState(0);
    //---------------------------issue{No.440} end-------------------------------

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // watch内で使用する利用者の生年月日
    const clientBirthdayRef = useRef(""); // issue516

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");

    // -------------------- issue516 start --------------------
    const [startDateMessage, setStartDateMessage] = useState("");
    const [startMonthMessage, setStartMonthMessage] = useState("");
    // -------------------- issue516 end --------------------

    // 優先順位ごとの入力欄の表示個数を管理する
    const [priorityCount, setPriorityCount] = useState(1);

    // 情報を取得しステート lastPlan_1にセットする
    const getLastPlan_1Data = async (id) => {
        const url = `plans_1/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            //---------------------------issue{No.505} start-----------------------------
            if (!res.data.result) {
                setLastPlan_1({ ...lastPlan_1, success: false });
                return;
            }
            //---------------------------issue{No.505} end-------------------------------

            // 支援目標の入力欄の表示個数を格納する
            let newPriorityCount = 1;
            // 支援目標の登録数に応じて、必要な入力欄の個数を調べる
            for (let ii = 2; ii <= 5; ii++) {
                if (res.data.result[`target_${ii}`]) {
                    newPriorityCount = ii;
                }
            }

            //---------------------------issue{No.440} start-----------------------------
            setLastPlan_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
                newPriorityCount: newPriorityCount,
            });
            //---------------------------issue{No.509} start-----------------------------
        }
        //---------------------------issue{No.509} end-------------------------------
    };

    // 情報を取得しステート proposedPlan_1にセットする
    const getProposedPlan_1Data = async (clientId) => {
        const url = `proposed_plans_1_get_latest/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            if (!res.data.result) {
                await swal({
                    icon: "warning",
                    title: "サービス等利用計画案が存在しないため続行できません",
                    text: "このメッセージを閉じて「サービス等利用計画一覧」ページに戻ります。",
                });
                navigate(`/plan_1/${client}`);

                //---------------------------issue{No.505} start-----------------------------
                return;
                //---------------------------issue{No.505} end-------------------------------
            }

            // 支援目標の入力欄の表示個数を格納する
            let newPriorityCount = 1;
            // 支援目標の登録数に応じて、必要な入力欄の個数を調べる
            for (let ii = 2; ii <= 5; ii++) {
                if (res.data.result[`target_${ii}`]) {
                    newPriorityCount = ii;
                }
            }

            setProposedPlan_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
                newPriorityCount: newPriorityCount,
            });
        }
    };

    // 情報を取得しステート monitoring_1にセットする
    const getMonitoring_1Data = async (clientId) => {
        const url = `monitorings_1_get_latest/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            //---------------------------issue{No.505} start-----------------------------
            if (!res.data.result) {
                setMonitoring_1({ ...monitoring_1, success: false });
                return;
            }
            //---------------------------issue{No.505} end-------------------------------

            // 支援目標の入力欄の表示個数を格納する
            let newPriorityCount = 1;
            // 支援目標の登録数に応じて、必要な入力欄の個数を調べる
            for (let ii = 2; ii <= 5; ii++) {
                if (res.data.result[`target_${ii}`]) {
                    newPriorityCount = ii;
                }
            }

            setMonitoring_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
                newPriorityCount: newPriorityCount,
            });
        }
    };
    //---------------------------issue{No.440} end-------------------------------

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `plans_1/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                client_birthday: clientResult.is_child ? clientResult.child_birthday : clientResult.client_birthday, // issue516
                support_law: clientResult.support_law,
                support_level: clientResult.support_level,
                office_name: clientResult.office_name,
                //---------------------------issue{No.513} start-----------------------------
                // user_name: clientResult.user_name,
                //---------------------------issue{No.513} end-------------------------------
                recipient_no_2: clientResult.recipient_no_2,
                recipient_no_3: clientResult.recipient_no_3,
                recipient_no_4: clientResult.recipient_no_4,
                start_month: clientResult.start_month,
            });
            //---------------------------issue{No.516} start-------------------------------
            clientBirthdayRef.current =
                clientResult.is_child == 1 ? clientResult.child_birthday : clientResult.client_birthday;
            //---------------------------issue{No.516} end-------------------------------
        }
    };

    //---------------------------issue{No.471} start-----------------------------
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name,
                };
            });
            setUsers(usersName);
        }
    };
    //---------------------------issue{No.471} end-------------------------------

    const getDateList = async (clientId) => {
        const url = `plans_1_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            const result = res.data.result.map((item) => {
                return item.date;
            });
            dateList.current = result;
        }

        // データ取得時に本日の日付がすでに作成されていないかを判定
        setDateMessage(dateRegisteringErrorMessage(today, clientBirthdayRef.current, dateList.current));
    };

    // //利用者名クリック時、利用者情報表示画面へ
    // const handleOnClickShow = async (e) => {
    //     // イベントの伝搬を中止
    //     e.stopPropagation();

    //     // 確認のアラートを表示する
    //     let confirm = false;
    //     await swal({
    //         icon: "warning",
    //         title: "入力内容は破棄されます",
    //         text: "利用者情報を確認するため、ページを離れますか？",
    //         buttons: true,
    //     }).then((result) => {
    //         if (result) {
    //             confirm = true;
    //         }
    //     });

    //     // キャンセル処理
    //     if (!confirm) {
    //         return;
    //     }

    //     if (client) {
    //         // URL(/client/show/${client.id})をたたく（RouterConfig.js）
    //         navigate(`/client_select/show/${client}`);
    //     }
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 作成ボタン押下時
    const plan_1Submit = async (data) => {
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "作成内容を見直してください",
                timer: 2000,
            });
            return;
        }

        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;
        //---------------------------issue{No.513} end-------------------------------

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "作成しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // Plan_1Controllerに渡す値をセット
        const plan_1Data = {
            ...clientData,
            date: data.date,
            //---------------------------issue{No.471} start-----------------------------
            user_name: data.user_name,
            //---------------------------issue{No.471} end-------------------------------
            maximum_amount: data.maximum_amount,
            wish: data.wish,
            plan_1: data.plan_1,
            plan_2: data.plan_2,
            plan_3: data.plan_3,
            task_1: data.task_1,
            task_2: data.task_2 ? data.task_2 : null,
            task_3: data.task_3 ? data.task_3 : null,
            task_4: data.task_4 ? data.task_4 : null,
            task_5: data.task_5 ? data.task_5 : null,
            target_1: data.target_1,
            target_2: data.target_2 ? data.target_2 : null,
            target_3: data.target_3 ? data.target_3 : null,
            target_4: data.target_4 ? data.target_4 : null,
            target_5: data.target_5 ? data.target_5 : null,
            achievement_period_1: data.achievement_period_1,
            achievement_period_2: data.achievement_period_2 ? data.achievement_period_2 : null,
            achievement_period_3: data.achievement_period_3 ? data.achievement_period_3 : null,
            achievement_period_4: data.achievement_period_4 ? data.achievement_period_4 : null,
            achievement_period_5: data.achievement_period_5 ? data.achievement_period_5 : null,
            type_1: data.type_1,
            type_2: data.type_2 ? data.type_2 : null,
            type_3: data.type_3 ? data.type_3 : null,
            type_4: data.type_4 ? data.type_4 : null,
            type_5: data.type_5 ? data.type_5 : null,
            provider_1: data.provider_1,
            provider_2: data.provider_2 ? data.provider_2 : null,
            provider_3: data.provider_3 ? data.provider_3 : null,
            provider_4: data.provider_4 ? data.provider_4 : null,
            provider_5: data.provider_5 ? data.provider_5 : null,
            role_1: data.role_1,
            role_2: data.role_2 ? data.role_2 : null,
            role_3: data.role_3 ? data.role_3 : null,
            role_4: data.role_4 ? data.role_4 : null,
            role_5: data.role_5 ? data.role_5 : null,
            evaluation_period_1: data.evaluation_period_1,
            evaluation_period_2: data.evaluation_period_2 ? data.evaluation_period_2 : null,
            evaluation_period_3: data.evaluation_period_3 ? data.evaluation_period_3 : null,
            evaluation_period_4: data.evaluation_period_4 ? data.evaluation_period_4 : null,
            evaluation_period_5: data.evaluation_period_5 ? data.evaluation_period_5 : null,
            note_1: data.note_1 ?? null,
            note_2: data.note_2 ? data.note_2 : null,
            note_3: data.note_3 ? data.note_3 : null,
            note_4: data.note_4 ? data.note_4 : null,
            note_5: data.note_5 ? data.note_5 : null,
            start_date: data.start_date,
            daily_routine: data.daily_routine,
            other_service: data.other_service,
            total: data.total,
        };

        // 作成
        const url = "plans_1";
        const res = await api.post(url, plan_1Data);
        if (res.status === 422) {
            setPlan_1({
                ...plan_1,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/plan_1/${client}`);
            });
        }
    };

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        //---------------------------issue{No.440} start-----------------------------
        // 以前の作成時のデータを取得する
        if (id) {
            getLastPlan_1Data(id);
        }
        // 関連するテーブルから最新のレコードを取得
        getProposedPlan_1Data(client);
        getMonitoring_1Data(client);
        //---------------------------issue{No.440} end-------------------------------
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        getDateList(client);
    }, []);

    //---------------------------issue{No.509} start-----------------------------
    // 読み込み時に、前回のサービス等利用計画が取得されたときは、アラートで、引用対象を選択してもらう
    useEffect(() => {
        const beforeLoadingAlert = async () => {
            // データの取得が実行済みである場合
            if (Object.keys(lastPlan_1).length > 1 && Object.keys(proposedPlan_1).length > 1) {
                await swal({
                    icon: "info",
                    title: "確認",
                    text: "作成にあたって、引用する内容を選択してください。",
                    buttons: {
                        cancel: {
                            text: "戻る",
                            value: "cancel",
                            visible: true,
                            className: "me-2",
                            closeModal: true,
                        },
                        catch: {
                            text: "以前の計画",
                            value: "last_plan",
                            visible: true,
                            className: "me-2 bg-success",
                            closeModal: true,
                        },
                        confirm: {
                            text: "計画案",
                            value: true,
                            visible: true,
                            className: "bg-primary",
                            closeModal: true,
                        },
                    },
                }).then(async (value) => {
                    if (value === "last_plan") {
                        setUpdateMode(true);

                        // 引用可能、およびコンポーネントの表示
                        setCiting("ready");
                    } else if (value === "cancel") {
                        await swal({
                            title: "新規作成を中止します",
                            text: "このメッセージを閉じて「サービス等利用計画一覧」ページに戻ります。",
                        });
                        navigate(`/plan_1/${client}`);
                        return;
                    } else if (value) {
                        setCiting("ready");
                    }
                });
            } else if (Object.keys(proposedPlan_1).length > 1) {
                setCiting("ready");
            }
        };
        // 関数を実行
        beforeLoadingAlert();
    }, [lastPlan_1, proposedPlan_1]);
    //---------------------------issue{No.509} end-------------------------------

    // サービス等利用計画案から取得したデータを入力フォームの値管理用のステートに格納する
    const handleOnClickCitingData = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "入力されている内容は上書きされる場合があります。続けますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        setPriorityCount(proposedPlan_1.newPriorityCount);

        const newPlan_1 = {
            maximum_amount: null,
            wish: proposedPlan_1.wish,
            plan_1: proposedPlan_1.plan_1,
            plan_2: proposedPlan_1.plan_2,
            plan_3: proposedPlan_1.plan_3,
            task_1: proposedPlan_1.task_1,
            task_2: proposedPlan_1.task_2,
            task_3: proposedPlan_1.task_3,
            task_4: proposedPlan_1.task_4,
            task_5: proposedPlan_1.task_5,
            target_1: proposedPlan_1.target_1,
            target_2: proposedPlan_1.target_2,
            target_3: proposedPlan_1.target_3,
            target_4: proposedPlan_1.target_4,
            target_5: proposedPlan_1.target_5,
            achievement_period_1: proposedPlan_1.achievement_period_1,
            achievement_period_2: proposedPlan_1.achievement_period_2,
            achievement_period_3: proposedPlan_1.achievement_period_3,
            achievement_period_4: proposedPlan_1.achievement_period_4,
            achievement_period_5: proposedPlan_1.achievement_period_5,
            type_1: proposedPlan_1.type_1,
            type_2: proposedPlan_1.type_2,
            type_3: proposedPlan_1.type_3,
            type_4: proposedPlan_1.type_4,
            type_5: proposedPlan_1.type_5,
            provider_1: proposedPlan_1.provider_1,
            provider_2: proposedPlan_1.provider_2,
            provider_3: proposedPlan_1.provider_3,
            provider_4: proposedPlan_1.provider_4,
            provider_5: proposedPlan_1.provider_5,
            role_1: proposedPlan_1.role_1,
            role_2: proposedPlan_1.role_2,
            role_3: proposedPlan_1.role_3,
            role_4: proposedPlan_1.role_4,
            role_5: proposedPlan_1.role_5,
            evaluation_period_1: proposedPlan_1.evaluation_period_1,
            evaluation_period_2: proposedPlan_1.evaluation_period_2,
            evaluation_period_3: proposedPlan_1.evaluation_period_3,
            evaluation_period_4: proposedPlan_1.evaluation_period_4,
            evaluation_period_5: proposedPlan_1.evaluation_period_5,
            note_1: proposedPlan_1.note_1,
            note_2: proposedPlan_1.note_2,
            note_3: proposedPlan_1.note_3,
            note_4: proposedPlan_1.note_4,
            note_5: proposedPlan_1.note_5,
            start_date: proposedPlan_1.start_date,
            daily_routine: proposedPlan_1.daily_routine,
            other_service: proposedPlan_1.other_service,
            total: proposedPlan_1.total,
        };

        setPlan_1({ ...plan_1, ...newPlan_1 });

        // 実行済みとして、ステートを書き換える
        setCiting("done");
    };

    // ボタンクリック時に以前の作成時の内容を、インプット内に引用する。
    const handleOnClickCitingLastData = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "入力されている内容は上書きされる場合があります。続けますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        setPriorityCount(lastPlan_1.newPriorityCount);

        const newPlan_1 = {
            maximum_amount: lastPlan_1.maximum_amount,
            wish: lastPlan_1.wish,
            plan_1: lastPlan_1.plan_1,
            plan_2: lastPlan_1.plan_2,
            plan_3: lastPlan_1.plan_3,
            task_1: lastPlan_1.task_1,
            task_2: lastPlan_1.task_2,
            task_3: lastPlan_1.task_3,
            task_4: lastPlan_1.task_4,
            task_5: lastPlan_1.task_5,
            target_1: lastPlan_1.target_1,
            target_2: lastPlan_1.target_2,
            target_3: lastPlan_1.target_3,
            target_4: lastPlan_1.target_4,
            target_5: lastPlan_1.target_5,
            achievement_period_1: lastPlan_1.achievement_period_1,
            achievement_period_2: lastPlan_1.achievement_period_2,
            achievement_period_3: lastPlan_1.achievement_period_3,
            achievement_period_4: lastPlan_1.achievement_period_4,
            achievement_period_5: lastPlan_1.achievement_period_5,
            type_1: lastPlan_1.type_1,
            type_2: lastPlan_1.type_2,
            type_3: lastPlan_1.type_3,
            type_4: lastPlan_1.type_4,
            type_5: lastPlan_1.type_5,
            provider_1: lastPlan_1.provider_1,
            provider_2: lastPlan_1.provider_2,
            provider_3: lastPlan_1.provider_3,
            provider_4: lastPlan_1.provider_4,
            provider_5: lastPlan_1.provider_5,
            role_1: lastPlan_1.role_1,
            role_2: lastPlan_1.role_2,
            role_3: lastPlan_1.role_3,
            role_4: lastPlan_1.role_4,
            role_5: lastPlan_1.role_5,
            evaluation_period_1: lastPlan_1.evaluation_period_1,
            evaluation_period_2: lastPlan_1.evaluation_period_2,
            evaluation_period_3: lastPlan_1.evaluation_period_3,
            evaluation_period_4: lastPlan_1.evaluation_period_4,
            evaluation_period_5: lastPlan_1.evaluation_period_5,
            note_1: lastPlan_1.note_1,
            note_2: lastPlan_1.note_2,
            note_3: lastPlan_1.note_3,
            note_4: lastPlan_1.note_4,
            note_5: lastPlan_1.note_5,
            start_date: null,
            daily_routine: lastPlan_1.daily_routine,
            other_service: lastPlan_1.other_service,
            total: lastPlan_1.total,
        };

        setPlan_1({ ...plan_1, ...newPlan_1 });

        // 実行済みとして、ステートを書き換える
        setCiting("done");
    };
    //---------------------------issue{No.440} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(clientData).length > 1) {
            if (clientData.office_name !== null) {
                setValue("office_name", clientData.office_name, { shouldValidate: false });
            }
            if (clientData.client_name !== null) {
                setValue("client_name", clientData.client_name, { shouldValidate: false });
            }
            if (clientData.recipient_no_2 !== null) {
                setValue("recipient_no_2", clientData.recipient_no_2, { shouldValidate: false });
            }
            if (clientData.recipient_no_3 !== null) {
                setValue("recipient_no_3", clientData.recipient_no_3, { shouldValidate: false });
            }
            if (clientData.recipient_no_4 !== null) {
                setValue("recipient_no_4", clientData.recipient_no_4, { shouldValidate: false });
            }
            if (clientData.start_month !== null) {
                setValue("start_month", clientData.start_month, { shouldValidate: false });
            }
        }
    }, [clientData]);
    //---------------------------issue{No.513} end-------------------------------

    // 作成時の状況に応じて計画案、または作成時のデータを引用する
    useEffect(() => {
        if (Object.keys(plan_1).length > 1) {
            // すべてのエラーを削除
            clearErrors();
            setValue("maximum_amount", plan_1.maximum_amount, { shouldValidate: false });
            setValue("wish", plan_1.wish, { shouldValidate: false });
            setValue("plan_1", plan_1.plan_1, { shouldValidate: false });
            setValue("plan_2", plan_1.plan_2, { shouldValidate: false });
            setValue("plan_3", plan_1.plan_3, { shouldValidate: false });
            for (let ii = 1; ii <= priorityCount; ii++) {
                setValue(`task_${ii}`, plan_1[`task_${ii}`], { shouldValidate: false });
                setValue(`target_${ii}`, plan_1[`target_${ii}`], {
                    shouldValidate: false,
                });
                setValue(`achievement_period_${ii}`, plan_1[`achievement_period_${ii}`], {
                    shouldValidate: false,
                });
                setValue(`type_${ii}`, plan_1[`type_${ii}`], { shouldValidate: false });
                setValue(`provider_${ii}`, plan_1[`provider_${ii}`], { shouldValidate: false });
                setValue(`role_${ii}`, plan_1[`role_${ii}`], { shouldValidate: false });
                setValue(`evaluation_period_${ii}`, plan_1[`evaluation_period_${ii}`], {
                    shouldValidate: false,
                });
                setValue(`note_${ii}`, plan_1[`note_${ii}`], { shouldValidate: false });
            }
            //---------------------------issue{No.440} start-----------------------------
            setValue("start_date", plan_1.start_date, { shouldValidate: false });
            //---------------------------issue{No.440} end-------------------------------
            setValue("daily_routine", plan_1.daily_routine, { shouldValidate: false });
            setValue("other_service", plan_1.other_service, { shouldValidate: false });
            setValue("total", plan_1.total, { shouldValidate: false });
        }
    }, [plan_1]);

    //---------------------------issue{No.471} start-----------------------------
    // データ取得後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (users.length > 0) {
            setValue("user_name", userInfo.user.user_name, { shouldValidate: true });
        }
    }, [users]);
    //---------------------------issue{No.471} end-------------------------------

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        //---------------------------issue{No.471} start-----------------------------
        control,
        //---------------------------issue{No.471} end-------------------------------
        clearErrors,
    } = useForm();

    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                switch (name) {
                    case "date":
                        setDateMessage(
                            dateRegisteringErrorMessage(value.date, clientBirthdayRef.current, dateList.current)
                        );
                        break;
                    case "start_date":
                        setStartDateMessage(dateRegisteringErrorMessage(value.start_date, clientBirthdayRef.current));
                        break;
                    default:
                        break;
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        if (priorityCount < 5) {
            setPriorityCount((priorityCount) => priorityCount + 1);
        }
    };

    // 支援目標の優先順位を入れ替える関数
    const handleOnClickPriorityChange = (e, num) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const higherPriority = {
            task: `task_${num}`,
            target: `target_${num}`,
            achievement_period: `achievement_period_${num}`,
            type: `type_${num}`,
            provider: `provider_${num}`,
            role: `role_${num}`,
            evaluation_period: `evaluation_period_${num}`,
            note: `note_${num}`,
        };

        // 優先順位が一つ上がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const lowerPriority = {
            task: `task_${num + 1}`,
            target: `target_${num + 1}`,
            achievement_period: `achievement_period_${num + 1}`,
            type: `type_${num + 1}`,
            provider: `provider_${num + 1}`,
            role: `role_${num + 1}`,
            evaluation_period: `evaluation_period_${num + 1}`,
            note: `note_${num + 1}`,
        };

        // 入れ替えの際に、値を一時的に控えておく定数オブジェクト
        const reserveValues = {
            task: null,
            target: null,
            achievement_period: null,
            type: null,
            provider: null,
            role: null,
            evaluation_period: null,
            note: null,
        };

        // 優先順位が下がる側の入力欄の値を控えておく
        for (let key of Object.keys(higherPriority)) {
            const value = getValues(higherPriority[key]);
            reserveValues[key] = value;
        }

        // 優先順位の下の入力欄の値を上の入力欄に移し替える
        for (let key of Object.keys(higherPriority)) {
            const newValue = getValues(lowerPriority[key]);
            setValue(higherPriority[key], newValue, { shouldValidate: false });
        }

        // 控えておいた値を優先順位の下の入力欄に差し替える
        for (let key of Object.keys(reserveValues)) {
            setValue(lowerPriority[key], reserveValues[key], { shouldValidate: false });
        }
    };

    // 支援目標の各優先順位の右上にある✕ボタン押下時に、支援目標を削除したり空欄に戻したりする処理
    const handleOnClickPriorityClear = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この支援目標を削除して続く優先順位を上に詰める、もしくはこの支援目標に関する入力内容をクリアしますか？",
            buttons: {
                catch: {
                    text: "入力内容をクリア",
                    value: "clear",
                    visible: true,
                    className:
                        "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                    closeModal: true,
                },
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
        }).then((value) => {
            switch (value) {
                case "clear":
                    // 選択範囲をクリアする
                    PriorityInputClear(num);
                    break;

                case "cancel":
                    break;

                case "delete":
                    // 先ず、対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    // 現在表示されている優先順位の個数分、優先順位を入れ替える関数を順に実行し、繰上げを行い
                    // ✕ボタンが押下され、上記の関数によって空欄になった入力欄を最下位に下げる
                    for (let ii = num; ii < priorityCount; ii++) {
                        handleOnClickPriorityChange(false, ii);
                    }
                    // 表示される優先順位の入力欄を減らす
                    setPriorityCount((priorityCount) => priorityCount - 1);
                    break;
                default:
                    break;
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const PriorityInputClear = (num) => {
        // 対象の優先順位の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = [
            `task_${num}`,
            `target_${num}`,
            `achievement_period_${num}`,
            `type_${num}`,
            `provider_${num}`,
            `role_${num}`,
            `evaluation_period_${num}`,
            `note_${num}`,
        ];

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, null, { shouldValidate: false });
        }
    };

    //---------------------------issue{No.440} start-----------------------------

    // 参照用のコンポーネントを閉じる
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setSlideOn(false);

        setTimeout(() => {
            setSlideTitle("");
            setDisplayDataNum(0);
        }, 250);
    };

    // 参照用のコンポーネントを開くきっかけとなるステートの値を更新し参照用コンポーネントを表示する
    const handleOnClickDataShow = (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setDisplayDataNum(num);

        // MUIのFadeタグの属性値を切り替えて、アニメーションを実行させる
        if (slideOn) {
            setFadeOn(false);
            setTimeout(() => {
                setFadeOn(true);
            }, 100);
        }

        switch (num) {
            case 1:
                setSlideTitle(`サービス等利用計画案（${proposedPlan_1.date}）`);
                break;
            case 2:
                setSlideTitle(`モニタリング報告書（${monitoring_1.date}）`);
                break;
            case 3:
                setSlideTitle(`サービス等利用計画（${lastPlan_1.date}）`);
                break;
            default:
                break;
        }
    };

    // MUIのSlideタグの属性値を切り替えて、アニメーションを実行させる
    useEffect(() => {
        if (slideTitle && !slideOn) {
            setSlideOn(true);
        }
    }, [slideTitle]);
    //---------------------------issue{No.440} end-------------------------------

    return (
        <div className='d-flex row justify-content-center'>
            {slideOn && (
                <div className='col-md-6 col-lg-6 mx-auto'>
                    <Slide
                        className='position-sticky top-0 vh-100'
                        direction='right'
                        in={slideOn}
                        mountOnEnter
                        unmountOnExit>
                        <div>
                            <div className='card d-block'>
                                <div className='card-header position-sticky top-0 d-flex justify-content-between bg-light'>
                                    <span className={classes.title}>{slideTitle}情報</span>
                                    <button
                                        className='btn btn-outline-primary btn-sm'
                                        type='button'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                                <Fade in={fadeOn}>
                                    <div className='card-body overflow-auto h-75'>
                                        {displayDataNum == 1 && (
                                            <>
                                                {proposedPlan_1Ref.current === null ? (
                                                    <ProposedPlan_1Show
                                                        idFromRegister={proposedPlan_1.id}
                                                        forRegister={true}
                                                        setRefCurrent={setProposedPlan_1RefCurrent}
                                                    />
                                                ) : (
                                                    <DataShowForRegister
                                                        data={proposedPlan_1Ref.current}></DataShowForRegister>
                                                )}
                                            </>
                                        )}
                                        {displayDataNum == 2 && (
                                            <>
                                                {monitoring_1Ref.current === null ? (
                                                    <Monitoring_1Show
                                                        idFromRegister={monitoring_1.id}
                                                        forRegister={true}
                                                        setRefCurrent={setMonitoring_1RefCurrent}
                                                    />
                                                ) : (
                                                    <DataShowForRegister
                                                        data={monitoring_1Ref.current}></DataShowForRegister>
                                                )}
                                            </>
                                        )}
                                        {displayDataNum == 3 && (
                                            <>
                                                {lastPlan_1Ref.current === null ? (
                                                    <Plan_1Show
                                                        idFromRegister={lastPlan_1.id}
                                                        forRegister={true}
                                                        setRefCurrent={setLastPlan_1RefCurrent}
                                                    />
                                                ) : (
                                                    <DataShowForRegister
                                                        data={lastPlan_1Ref.current}></DataShowForRegister>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Fade>
                                <div className='card-footer bg-light d-flex justify-content-between'>
                                    <div
                                        className='btn-group'
                                        role='group'
                                        aria-label='Show data select toggle button group'>
                                        {Object.keys(proposedPlan_1).length > 10 && (
                                            <label
                                                className={`btn btn-${displayDataNum == 1 ? "" : "outline-"}primary`}>
                                                利用計画案
                                                <input
                                                    type='radio'
                                                    className='btn-check'
                                                    name='radio_btn'
                                                    autoComplete='off'
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 1);
                                                    }}
                                                />
                                            </label>
                                        )}
                                        {Object.keys(monitoring_1).length > 10 && (
                                            <label
                                                className={`btn btn-${displayDataNum == 2 ? "" : "outline-"}primary`}>
                                                モニタリング報告書
                                                <input
                                                    type='radio'
                                                    className='btn-check'
                                                    name='radio_btn'
                                                    autoComplete='off'
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 2);
                                                    }}
                                                />
                                            </label>
                                        )}
                                        {Object.keys(lastPlan_1).length > 10 && (
                                            <label
                                                className={`btn btn-${displayDataNum == 3 ? "" : "outline-"}primary`}>
                                                以前の利用計画
                                                <input
                                                    type='radio'
                                                    className='btn-check'
                                                    name='radio_btn'
                                                    autoComplete='off'
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 3);
                                                    }}
                                                />
                                            </label>
                                        )}
                                    </div>
                                    <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                        閉じる
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Slide>
                </div>
            )}
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}> サービス等利用計画作成</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <div className='d-flex justify-content-between mb-5'>
                            <div className='card w-50 p-2 bg-light'>
                                <p>各機能の最新から参照する</p>
                                {Object.keys(proposedPlan_1).length > 10 && (
                                    <Tooltip
                                        title='サービス等利用計画案を画面の左側に表示する。'
                                        placement='left-end'
                                        arrow>
                                        <div
                                            className={classes.dataLink}
                                            onClick={(e) => {
                                                handleOnClickDataShow(e, 1);
                                            }}>
                                            サービス等利用計画案
                                            <br />（{proposedPlan_1.date}）
                                        </div>
                                    </Tooltip>
                                )}
                                <br />
                                {Object.keys(monitoring_1).length > 10 && (
                                    <Tooltip
                                        title='モニタリング報告を画面の左側に表示する。'
                                        placement='left-end'
                                        arrow>
                                        <div
                                            className={classes.dataLink}
                                            onClick={(e) => {
                                                handleOnClickDataShow(e, 2);
                                            }}>
                                            モニタリング報告書
                                            <br />（{monitoring_1.date}）
                                        </div>
                                    </Tooltip>
                                )}
                                <br />
                                {Object.keys(lastPlan_1).length > 10 && (
                                    <Tooltip
                                        title='以前のサービス等利用計画を画面の左側に表示する。'
                                        placement='left-end'
                                        arrow>
                                        <div
                                            className={classes.dataLink}
                                            onClick={(e) => {
                                                handleOnClickDataShow(e, 3);
                                            }}>
                                            以前のサービス等利用計画
                                            <br />（{lastPlan_1.date}）
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <div className='w-50 ps-3'>
                                {Object.keys(proposedPlan_1).length > 1 && !updateMode && (
                                    <>
                                        {citing === "ready" && (
                                            <Tooltip
                                                title={`サービス等利用計画案（${proposedPlan_1.date}）の内容を以下の入力欄に引用します。`}
                                                placement='bottom'
                                                arrow>
                                                <Button
                                                    className='mb-3'
                                                    type='button'
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={(e) => {
                                                        handleOnClickCitingData(e);
                                                    }}>
                                                    以前のサービス等利用計画案の内容を引用する
                                                    <br />
                                                    （※利用者の情報は対象外）
                                                </Button>
                                            </Tooltip>
                                        )}
                                        <Tooltip
                                            title='サービス等利用計画案を画面の左側に表示する。'
                                            placement='bottom'
                                            arrow>
                                            <button
                                                className='d-block btn btn-primary'
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, 1);
                                                }}>
                                                サービス等利用計画案（{proposedPlan_1.date}）を並べて表示する
                                            </button>
                                        </Tooltip>
                                        {citing === "done" && (
                                            <button className='d-block btn btn-outline-secondary mt-3' disabled>
                                                サービス等利用計画案（{proposedPlan_1.date}）の内容を引用済み
                                            </button>
                                        )}
                                    </>
                                )}
                                {updateMode && (
                                    <>
                                        {Object.keys(lastPlan_1).length > 1 && citing === "ready" && (
                                            <Tooltip
                                                title={`サービス等利用計画（${lastPlan_1.date}）の内容を以下の入力欄に引用します。`}
                                                placement='bottom'
                                                arrow>
                                                <Button
                                                    className='mb-3 bg-success'
                                                    type='button'
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={(e) => {
                                                        handleOnClickCitingLastData(e);
                                                    }}>
                                                    以前のサービス等利用計画の内容を引用する
                                                    <br />
                                                    （※利用者の情報は対象外）
                                                </Button>
                                            </Tooltip>
                                        )}
                                        <Tooltip
                                            title='モニタリング報告書を画面の左側に表示する。'
                                            placement='bottom'
                                            arrow>
                                            <button
                                                className='d-block btn btn-primary'
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, 3);
                                                }}>
                                                サービス等利用計画（{lastPlan_1.date}）を並べて表示する
                                            </button>
                                        </Tooltip>
                                        {citing === "done" && (
                                            <button className='d-block btn btn-outline-secondary mt-3' disabled>
                                                以前のサービス等利用計画（{lastPlan_1.date}）の内容を引用済み
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <p className='fs-6 pt-1'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(plan_1Submit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    計画作成日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    defaultValue={today}
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*計画作成日を入力してください。",
                                        },
                                    })}
                                />
                                {/* 日付の重複によるエラーメッセージ */}
                                <small className='text-danger'>{dateMessage && dateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.date}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    計画作成担当者
                                    <RequiredMark />
                                </label>
                                {Object.keys(users).length > 0 && (
                                    <Controller
                                        name='user_name'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*計画作成担当者を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        {Object.keys(users).length === 0 ? (
                                                            <>ユーザーが登録されていません</>
                                                        ) : (
                                                            <>選択してください</>
                                                        )}
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.user_name}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                )}
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.user_name}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='maximum_amount'>
                                    利用者負担上限額
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>（半角数字のみ）</small>
                                </label>
                                <input
                                    id='maximum_amount'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    {...register("maximum_amount", {
                                        required: {
                                            value: true,
                                            message: "*利用者負担上限額を入力してください。",
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.maximum_amount && errors.maximum_amount?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.maximum_amount}
                                </small>
                            </div>
                            <hr />
                            <h3 className='border-bottom border-secondary'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、利用者情報による内容</span>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                </label>
                                <input
                                    id='client_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_name", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            {clientData?.support_law === 2 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1'>障害支援区分</label>
                                    <div className='form-control mb-1'>
                                        {formatSupportLevelList(clientData.support_law, clientData.support_level)}
                                    </div>
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_name'>
                                    相談支援事業者名
                                </label>
                                <input
                                    id='office_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("office_name", {
                                        required: {
                                            value: true,
                                            message: "*相談支援事業者名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_2'>
                                    障害福祉サービス受給者証番号
                                </label>
                                <input
                                    id='recipient_no_2'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_2", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_3'>
                                    地域相談支援受給者番号
                                </label>
                                <input
                                    id='recipient_no_3'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_3", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_4'>
                                    通所受給者番号
                                </label>
                                <input
                                    id='recipient_no_4'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_4", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='start_month'>
                                    モニタリング期間(開始年月)
                                </label>
                                <input
                                    id='start_month'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("start_month")}
                                />
                            </div>
                            <hr />
                            <h3 className='border-bottom border-secondary mb-3'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、サービス等利用計画案についての項目</span>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='wish'>
                                    利用者及びその家族の生活に対する意向（希望する生活）
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='wish'
                                    className='form-control mb-1'
                                    {...register("wish", {
                                        required: {
                                            value: true,
                                            message:
                                                "*利用者及びその家族の生活に対する意向（希望する生活）を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*利用者及びその家族の生活に対する意向（希望する生活）を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.wish && errors.wish?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.wish}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='plan_1'>
                                    総合的な支援の方針
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='plan_1'
                                    className='form-control mb-1'
                                    {...register("plan_1", {
                                        required: {
                                            value: true,
                                            message: "*総合的な支援の方針を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*総合的な支援の方針を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.plan_1 && errors.plan_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.plan_1}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='plan_2'>
                                    長期目標
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='plan_2'
                                    className='form-control mb-1'
                                    {...register("plan_2", {
                                        required: {
                                            value: true,
                                            message: "*長期目標を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*長期目標を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.plan_2 && errors.plan_2?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.plan_2}</small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='plan_3'>
                                    短期目標
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='plan_3'
                                    className='form-control mb-1'
                                    {...register("plan_3", {
                                        required: {
                                            value: true,
                                            message: "*短期目標を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*短期目標を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.plan_3 && errors.plan_3?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.plan_3}</small>
                            </div>
                            <hr />
                            <h4 className='d-inline-block border border-2 px-3 fs-5 fw-bold mb-4'>支援目標</h4>
                            <p className='d-inline text-secondary fs-6 ms-3'>（優先順位ごとに入力）</p>
                            <div className={classes.priority}>
                                <span className={classes.priorityNum}>【1】</span>
                                {priorityCount > 1 && (
                                    <>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 1);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='task_1'>
                                    解決すべき課題（本人のニーズ）
                                    <RequiredMark />
                                </label>
                                <input
                                    id='task_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("task_1", {
                                        required: {
                                            value: true,
                                            message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.task_1 && errors.task_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.task_1}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='target_1'>
                                    支援目標
                                    <RequiredMark />
                                </label>
                                <input
                                    id='target_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("target_1", {
                                        required: {
                                            value: true,
                                            message: "*支援目標を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.target_1 && errors.target_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.target_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='achievement_period_1'>
                                    達成期間
                                    <RequiredMark />
                                </label>
                                <input
                                    id='achievement_period_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("achievement_period_1", {
                                        required: {
                                            value: true,
                                            message: "*達成期間を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.achievement_period_1 && errors.achievement_period_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.achievement_period_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='type_1'>
                                    種類・内容・量
                                    <RequiredMark />
                                </label>
                                <input
                                    id='type_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("type_1", {
                                        required: {
                                            value: true,
                                            message: "*種類・内容・量を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.type_1 && errors.type_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.type_1}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='provider_1'>
                                    提供事業者名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='provider_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("provider_1", {
                                        required: {
                                            value: true,
                                            message: "*提供事業者名を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.provider_1 && errors.provider_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.provider_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='role_1'>
                                    課題解決のための本人の役割
                                    <RequiredMark />
                                </label>
                                <input
                                    id='role_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("role_1", {
                                        required: {
                                            value: true,
                                            message: "*課題解決のための本人の役割を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.role_1 && errors.role_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.role_1}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='evaluation_period_1'>
                                    評価時期
                                    <RequiredMark />
                                </label>
                                <input
                                    id='evaluation_period_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("evaluation_period_1", {
                                        required: {
                                            value: true,
                                            message: "*評価時期を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.evaluation_period_1 && errors.evaluation_period_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.evaluation_period_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='note_1'>
                                    その他留意事項
                                </label>
                                <input id='note_1' type='text' className='form-control mb-1' {...register("note_1")} />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.note_1 && errors.note_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.note_1}</small>
                            </div>
                            {priorityCount >= 2 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【2】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 2 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 2);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 2);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_2'>
                                            解決すべき課題（本人のニーズ）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_2", {
                                                required: {
                                                    value: true,
                                                    message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_2 && errors.task_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.task_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_2'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_2", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_2 && errors.target_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.target_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_2'>
                                            達成期間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_2", {
                                                required: {
                                                    value: true,
                                                    message: "*達成期間を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_2 && errors.achievement_period_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.achievement_period_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='type_2'>
                                            種類・内容・量
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='type_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("type_2", {
                                                required: {
                                                    value: true,
                                                    message: "*種類・内容・量を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.type_2 && errors.type_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.type_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='provider_2'>
                                            提供事業者名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='provider_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("provider_2", {
                                                required: {
                                                    value: true,
                                                    message: "*提供事業者名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.provider_2 && errors.provider_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.provider_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='role_2'>
                                            課題解決のための本人の役割
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='role_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("role_2", {
                                                required: {
                                                    value: true,
                                                    message: "*課題解決のための本人の役割を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.role_2 && errors.role_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.role_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='evaluation_period_2'>
                                            評価時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='evaluation_period_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("evaluation_period_2", {
                                                required: {
                                                    value: true,
                                                    message: "*評価時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.evaluation_period_2 && errors.evaluation_period_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.evaluation_period_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='note_2'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='note_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("note_2")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.note_2 && errors.note_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.note_2}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount >= 3 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【3】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 2);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 3 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 3);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 3);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_3'>
                                            解決すべき課題（本人のニーズ）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_3", {
                                                required: {
                                                    value: true,
                                                    message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_3 && errors.task_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.task_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_3'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_3", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_3 && errors.target_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.target_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_3'>
                                            達成期間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_3", {
                                                required: {
                                                    value: true,
                                                    message: "*達成期間を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_3 && errors.achievement_period_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.achievement_period_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='type_3'>
                                            種類・内容・量
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='type_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("type_3", {
                                                required: {
                                                    value: true,
                                                    message: "*種類・内容・量を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.type_3 && errors.type_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.type_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='provider_3'>
                                            提供事業者名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='provider_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("provider_3", {
                                                required: {
                                                    value: true,
                                                    message: "*提供事業者名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.provider_3 && errors.provider_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.provider_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='role_3'>
                                            課題解決のための本人の役割
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='role_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("role_3", {
                                                required: {
                                                    value: true,
                                                    message: "*課題解決のための本人の役割を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.role_3 && errors.role_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.role_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='evaluation_period_3'>
                                            評価時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='evaluation_period_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("evaluation_period_3", {
                                                required: {
                                                    value: true,
                                                    message: "*評価時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.evaluation_period_3 && errors.evaluation_period_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.evaluation_period_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='note_3'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='note_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("note_3")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.note_3 && errors.note_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.note_3}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount >= 4 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【4】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 3);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 4 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 4);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 4);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_4'>
                                            解決すべき課題（本人のニーズ）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_4", {
                                                required: {
                                                    value: true,
                                                    message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_4 && errors.task_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.task_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_4'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_4", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_4 && errors.target_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.target_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_4'>
                                            達成期間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_4", {
                                                required: {
                                                    value: true,
                                                    message: "*達成期間を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_4 && errors.achievement_period_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.achievement_period_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='type_4'>
                                            種類・内容・量
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='type_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("type_4", {
                                                required: {
                                                    value: true,
                                                    message: "*種類・内容・量を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.type_4 && errors.type_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.type_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='provider_4'>
                                            提供事業者名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='provider_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("provider_4", {
                                                required: {
                                                    value: true,
                                                    message: "*提供事業者名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.provider_4 && errors.provider_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.provider_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='role_4'>
                                            課題解決のための本人の役割
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='role_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("role_4", {
                                                required: {
                                                    value: true,
                                                    message: "*課題解決のための本人の役割を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.role_4 && errors.role_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.role_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='evaluation_period_4'>
                                            評価時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='evaluation_period_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("evaluation_period_4", {
                                                required: {
                                                    value: true,
                                                    message: "*評価時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.evaluation_period_4 && errors.evaluation_period_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.evaluation_period_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='note_4'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='note_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("note_4")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.note_4 && errors.note_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.note_4}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount === 5 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【5】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 4);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 5);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_5'>
                                            解決すべき課題（本人のニーズ）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_5", {
                                                required: {
                                                    value: true,
                                                    message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_5 && errors.task_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.task_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_5'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_5", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_5 && errors.target_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.target_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_5'>
                                            達成期間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_5", {
                                                required: {
                                                    value: true,
                                                    message: "*達成期間を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_5 && errors.achievement_period_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.achievement_period_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='type_5'>
                                            種類・内容・量
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='type_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("type_5", {
                                                required: {
                                                    value: true,
                                                    message: "*種類・内容・量を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.type_5 && errors.type_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.type_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='provider_5'>
                                            提供事業者名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='provider_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("provider_5", {
                                                required: {
                                                    value: true,
                                                    message: "*提供事業者名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.provider_5 && errors.provider_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.provider_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='role_5'>
                                            課題解決のための本人の役割
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='role_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("role_5", {
                                                required: {
                                                    value: true,
                                                    message: "*課題解決のための本人の役割を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.role_5 && errors.role_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.role_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='evaluation_period_5'>
                                            評価時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='evaluation_period_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("evaluation_period_5", {
                                                required: {
                                                    value: true,
                                                    message: "*評価時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.evaluation_period_5 && errors.evaluation_period_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.evaluation_period_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='note_5'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='note_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("note_5")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.note_5 && errors.note_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.note_5}
                                        </small>
                                    </div>
                                    <hr className='mb-5' />
                                </>
                            )}
                            {priorityCount < 5 && (
                                <div className='w-100 mb-5'>
                                    <hr />
                                    <div className='text-center'>
                                        <button
                                            type='button'
                                            className='btn btn-outline-primary'
                                            onClick={(e) => {
                                                handleOnClickAddInput(e);
                                            }}>
                                            <span className='fw-bold fs-5 px-1'>＋</span>
                                            支援目標を追加する
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary'>
                                <span className='fs-2 text-danger'>■</span>
                                <span className='fs-4'>以下、週間計画表に関する項目</span>
                                <small className='fs-6 fw-normal text-secondary'>（二枚目の様式について）</small>
                            </h3>
                            <p className='fs-6 fw-normal text-secondary mb-5'>
                                ※週間計画表の表部分は、新規作成後に別途作成します
                            </p>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='start_date'>
                                    計画開始年月
                                    <RequiredMark />
                                </label>
                                <input
                                    id='start_date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    className='form-control mb-1'
                                    {...register("start_date", {
                                        required: {
                                            value: true,
                                            message: "*計画開始年月を入力してください。",
                                        },
                                    })}
                                />
                                {/* 生年月日以前の日付指定時のエラーメッセージ */}
                                <small className='text-danger'>{startDateMessage && startDateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.start_date && errors.start_date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.start_date}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='daily_routine'>
                                    主な日常生活上の活動
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='daily_routine'
                                    className='form-control mb-1'
                                    {...register("daily_routine", {
                                        required: {
                                            value: true,
                                            message: "*主な日常生活上の活動を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*主な日常生活上の活動を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.daily_routine && errors.daily_routine?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.daily_routine}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_service'>
                                    週単位以外のサービス
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='other_service'
                                    className='form-control mb-1'
                                    {...register("other_service", {
                                        required: {
                                            value: true,
                                            message: "*週単位以外のサービスを入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*週単位以外のサービスを入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_service && errors.other_service?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.other_service}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='total'>
                                    サービス提供によって実現する生活の全体像
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='total'
                                    className='form-control mb-1'
                                    {...register("total", {
                                        required: {
                                            value: true,
                                            message: "*サービス提供によって実現する生活の全体像を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*サービス提供によって実現する生活の全体像を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.total && errors.total?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.total}</small>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    作成
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Plan_1Register;
