import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";

import { api } from "../api/api";
import getApiUrlFromTargetUrl from "../common/getApiUrlFromTargetUrl";

import ClientSelectShowLinkInPageTitle from "./ClientSelectShowLinkInPageTitle";
import DataTable from "./DataTable";
import PageCardHeaderAndFooter from "./PageCardHeaderAndFooter";
import PageOutline from "./PageOutline";

// 見出しボタン部分の配列定義
const headerButtons = ["復活"];

// 初期状態
const emptyDataList = { result: [], success: false };

/**
 * 引数に受け取った利用者に紐付く削除済み情報を一覧表示する画面
 * @param {string} pageName 目的の情報名:画面上部の見出しに表示します
 * @param {string} targetURL 目的の情報に対応するURL文字列
 * (api通信時は'_'の手前若しくは末尾に's'を付けるので付いてないURLを渡して下さい)
 * @param {Array} headerDataList 情報一覧の見出し用の配列
 * @param {*} clientID 表示対象の利用者のID
 * @param {Object} rowItems データ行の連想配列に追加したい項目とbutton等の持つ処理
 * 削除済み情報一覧画面に追加表示したい項目があらば設定して下さい
 * @returns 利用者に紐付く削除済みの情報一覧画面を表示します
 */
export default function ClientSelectToDeletedDataList({
    pageName = 'モニタリング報告書',
    targetURL = 'monitoring_1',
    headerDataList = ['報告書作成日'],
    clientID = '',
    rowItems = (rowData) => (rowData)
}) {
    // 画面遷移用
    const navigate = useNavigate();

    // 選択された利用者に紐付く削除済み情報を管理するステート
    const [targetDataPerClient, setTargetDataPerClient] = useState(emptyDataList);

    // 引数に渡された目的の情報を指すtargetURLからapi通信用のURLを生成します
    const apiURL = getApiUrlFromTargetUrl(targetURL);

    // api通信でDBから選択された利用者に紐付く削除済み情報を取得しステート targetDataPerClient にセットする
    const getTargetData = async (clientId) => {
        const res = await api.get(`${apiURL}_deleted/${clientId}`);

        if (res.data.success) {
            setTargetDataPerClient(res.data);
        }
    };

    // 一覧の行のどこかをクリックするとその行に対応する削除済み情報表示画面へ遷移します
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行のid(key)と一致するtargetDataPerClientステートのidを変数に代入
        const targetData = targetDataPerClient.result.find((item) => item.id === id);
        // 値が存在する(0, "", undefined, nullでない)場合
        if (targetData) {
            // URL(/${targetURL}_deleted/show/${clientID}/${id})を叩く(RouterConfig.js)
            navigate(`/${targetURL}_deleted/show/${clientID}/${id}`);
        }
    };

    // 一覧の復活ボタンクリック時に論理削除済み対象レコードを復活する
    const handleOnClickRestore = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "削除した項目を復活しますか?",
            buttons: ["キャンセル", "実行"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        // 復活
        const res = await api.put(`${apiURL}/${id}/restore`);
        // 成否の確認
        if (res.data.success) {
            // 復活成功時はモーダルウィンドウで削除成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 復活したデータを除外した全データを配列化
                const remainingData = targetDataPerClient.result.filter((item) => item.id !== id);
                // ステートを更新
                setTargetDataPerClient({ result: remainingData, success: true });
            });
        } else {
            // 削除失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };

    // targetDataPerClient に応じて画面表示用の配列を生成
    const rows = useMemo(() => {
        if (!targetDataPerClient.success || targetDataPerClient.result.length < 1) {
            return [];
        }

        // 取得したデータに対応する行を逐次生成します
        const newRows = targetDataPerClient.result.map(data => ({
            id: data.id,
            isActive: !data?.deleted_at,
            data: {
                date: data.date,
                // 画面毎の独自項目rowItemsを引数から受け取れていた場合は行中に追加します
                ...(rowItems() ? rowItems(data): null),
                deleteBtn: (
                    <Button variant='contained' onClick={(e) => handleOnClickRestore(e, data.id)}>
                        復活
                    </Button>
                )
            }
        }));
        return newRows;
    }, [targetDataPerClient]);

    // 選択済み利用者の状態に応じて,利用者に紐付く削除済みデータを取得
    useEffect(() => clientID &&  getTargetData(clientID), [clientID]);

    // 目的の削除済み情報一覧画面
    const targetDataList = (
        <PageOutline>
            <PageCardHeaderAndFooter
                pageName={<ClientSelectShowLinkInPageTitle pageName={pageName} clientID={clientID} />}
                closePath={`/${targetURL}/${clientID}`} >
                {/* テーブル部分の定義 */}
                <DataTable
                    headers={headerDataList.concat(headerButtons)}
                    rows={rows}
                    handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                />
            </PageCardHeaderAndFooter>
        </PageOutline>
    );

    return targetDataList;
}