import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";
import DataShowForRegister from "../../components/DataShowForRegister";
import NewOutputExcelButton from "../../components/NewOutputExcelButton";
import formatSupportLevelList from "../../common/formatSupportLevelList";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        figure: {
            marginBottom: "0!important",
            fontWeight: "bold",
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center",
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px",
        },
    })
);

// 表示用の各データに応じた値を配列で用意
//---------------------------issue{No.440} start-----------------------------
function ProposedPlan_1Show(props) {
    const { idFromRegister = undefined, forRegister = false, setRefCurrent = undefined } = props;
    //---------------------------issue{No.440} end-------------------------------

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、サービス等利用計画案情報のid
    const { client, id } = useParams();

    const pageName = "サービス等利用計画案";

    // 表示するproposedPlan_1 の状態を管理する
    const [proposedPlan_1Data, setProposedPlan_1Data] = useState([]);
    const [proposedPlan_1ShowData, setProposedPlan_1ShowData] = useState([]);

    //---------------------------issue{No.325} start-----------------------------
    const [downloadState, setDownloadState] = useState("");
    //---------------------------issue{No.325} end-------------------------------
    const [downloadFileName, setDownloadFileName] = useState(pageName);

    //情報を取得しステート proposedPlan_1 にセットする
    const getProposedPlan_1Data = async (id) => {
        const url = `proposed_plans_1/show/${id}`;
        const res = await api.get(url);

        if (res) {
            //---------------------------issue{No.325} start-----------------------------
            setProposedPlan_1Data({ ...res.data.result, file_type: "proposed_plan_1" });
            //---------------------------issue{No.325} end-------------------------------
            setProposedPlan_1Row(res.data.result);
            const createdDate = res.data.result.date.replace(/-/g, "");
            const clientName = res.data.result.client_name;
            setDownloadFileName(`${createdDate}_${clientName}_${pageName}`);
        }
    };

    // ステートに値をセット
    const setProposedPlan_1Row = (proposedPlan_1) => {
        const proposedPlanDateFormat = dateFormat(proposedPlan_1.date, true);
        const proposedPlanDate = proposedPlanDateFormat.format_date;

        const supportLevel = formatSupportLevelList(proposedPlan_1.support_law, proposedPlan_1.support_level);

        const monitoringStartMonthFormat = dateFormat(proposedPlan_1.start_month, true, "Y-m");
        const monitoringStartMonth = monitoringStartMonthFormat.format_date;

        const startMonthFormat = dateFormat(proposedPlan_1.start_date, true, "Y-m");
        const startMonth = startMonthFormat.format_date;

        //---------------------------issue{No.440} start-----------------------------
        const data = [
            { label: "会社名", value: proposedPlan_1.company_name },
            { label: "事業所名", value: proposedPlan_1.offices_office_name },
            { label: "担当職員", value: proposedPlan_1.users_user_name },
            { label: "利用者ID", value: proposedPlan_1.client_id },
            { label: "利用者氏名", value: proposedPlan_1.client_name },
            { label: "障害支援区分", value: supportLevel },
            { label: "障害福祉サービス受給者証番号", value: proposedPlan_1.recipient_no_2 },
            { label: "地域相談支援受給者番号", value: proposedPlan_1.recipient_no_3 },
            { label: "通所受給者番号", value: proposedPlan_1.recipient_no_4 },
            { label: "計画案作成日", value: proposedPlanDate },
            { label: "計画作成担当者", value: proposedPlan_1.user_name },
            { label: "モニタリング期間（開始年月）", value: monitoringStartMonth },
            { label: "利用者及びその家族の生活に対する意向（希望する生活）", value: proposedPlan_1.wish },
            { label: "総合的な支援の方針", value: proposedPlan_1.plan_1 },
            { label: "長期目標", value: proposedPlan_1.plan_2 },
            { label: "短期目標", value: proposedPlan_1.plan_3 },
            { label: "【1】解決すべき課題（本人のニーズ）", value: proposedPlan_1.task_1 },
            { label: "【1】支援目標", value: proposedPlan_1.target_1 },
            { label: "【1】達成期間", value: proposedPlan_1.achievement_period_1 },
            { label: "【1】福祉サービス等", value: proposedPlan_1.service_1 },
            { label: "【1】課題解決のための本人の役割", value: proposedPlan_1.role_1 },
            { label: "【1】評価時期", value: proposedPlan_1.evaluation_period_1 },
            { label: "【1】その他留意事項", value: proposedPlan_1.note_1 },
            { label: "【2】解決すべき課題（本人のニーズ）", value: proposedPlan_1.task_2 },
            { label: "【2】支援目標", value: proposedPlan_1.target_2 },
            { label: "【2】達成期間", value: proposedPlan_1.achievement_period_2 },
            { label: "【2】福祉サービス等", value: proposedPlan_1.service_2 },
            { label: "【2】課題解決のための本人の役割", value: proposedPlan_1.role_2 },
            { label: "【2】評価時期", value: proposedPlan_1.evaluation_period_2 },
            { label: "【2】その他留意事項", value: proposedPlan_1.note_2 },
            { label: "【3】解決すべき課題（本人のニーズ）", value: proposedPlan_1.task_3 },
            { label: "【3】支援目標", value: proposedPlan_1.target_3 },
            { label: "【3】達成期間", value: proposedPlan_1.achievement_period_3 },
            { label: "【3】福祉サービス等", value: proposedPlan_1.service_3 },
            { label: "【3】課題解決のための本人の役割", value: proposedPlan_1.role_3 },
            { label: "【3】評価時期", value: proposedPlan_1.evaluation_period_3 },
            { label: "【3】その他留意事項", value: proposedPlan_1.note_3 },
            { label: "【4】解決すべき課題（本人のニーズ）", value: proposedPlan_1.task_4 },
            { label: "【4】支援目標", value: proposedPlan_1.target_4 },
            { label: "【4】達成期間", value: proposedPlan_1.achievement_period_4 },
            { label: "【4】福祉サービス等", value: proposedPlan_1.service_4 },
            { label: "【4】課題解決のための本人の役割", value: proposedPlan_1.role_4 },
            { label: "【4】評価時期", value: proposedPlan_1.evaluation_period_4 },
            { label: "【4】その他留意事項", value: proposedPlan_1.note_4 },
            { label: "【5】解決すべき課題（本人のニーズ）", value: proposedPlan_1.task_5 },
            { label: "【5】支援目標", value: proposedPlan_1.target_5 },
            { label: "【5】達成期間", value: proposedPlan_1.achievement_period_5 },
            { label: "【5】福祉サービス等", value: proposedPlan_1.service_5 },
            { label: "【5】課題解決のための本人の役割", value: proposedPlan_1.role_5 },
            { label: "【5】評価時期", value: proposedPlan_1.evaluation_period_5 },
            { label: "【5】その他留意事項", value: proposedPlan_1.note_5 },
            { label: "計画開始年月", value: startMonth },
            { label: "主な日常生活上の活動", value: proposedPlan_1.daily_routine },
            { label: "週単位以外のサービス", value: proposedPlan_1.other_service },
            { label: "サービス提供によって実現する生活の全体像", value: proposedPlan_1.total },
        ];

        setProposedPlan_1ShowData(data);

        if (forRegister) {
            setRefCurrent(data);
        }
        //---------------------------issue{No.440} end-------------------------------
    };

    // 閉じるボタンをクリックしたら、サービス等利用計画案情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/proposed_plan_1/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        //---------------------------issue{No.440} start-----------------------------
        getProposedPlan_1Data(idFromRegister ? idFromRegister : id);
        //---------------------------issue{No.440} end-------------------------------
    }, []);

    //---------------------------issue{No.325} start-----------------------------
    const fileDownload = async (e) => {
        e.stopPropagation();

        setDownloadState("waiting");
        const url = "export_file";
        await api
            .post(url, proposedPlan_1Data)
            .then(async (res) => {
                setDownloadState("doing");
                if (res.data.success) {
                    const date = proposedPlan_1Data.date.replace(/-/g, "");
                    const clientName = proposedPlan_1Data.client_name;
                    const fileName = `${date}_${clientName}_サービス等利用計画案.xlsx`;

                    const deleteUrl = "delete_file";
                    const downloadUrl = "download_file";
                    const data = { path: res.data.result };
                    const option = { responseType: "blob" }; // important

                    // ダウンロード実行完了後、必ずExportしたファイルを削除する
                    api.post(deleteUrl, data);

                    // ダウンロードを実行する
                    await api.post(downloadUrl, data, option).then((res) => {
                        const url = window.URL.createObjectURL(res.data);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();

                        setTimeout(() => {
                            setDownloadState("");
                        }, 2500);
                    });
                }
            })
            .catch(() => {
                setDownloadState("");
                swal("ファイルの作成に失敗", "", "error");
            });
    };
    //---------------------------issue{No.325} end-------------------------------

    return (
        <>
            {forRegister ? (
                <DataShowForRegister data={proposedPlan_1ShowData}></DataShowForRegister>
            ) : (
                <div className='row justify-content-center'>
                    <div className='col-md-10 col-lg-10 mx-auto'>
                        <div className='card'>
                            <div className='card-header d-flex'>
                                <h1 className={classes.title}>サービス等利用計画案情報</h1>
                                {/* -------------------- issue539 start -------------------- */}
                                <div className='ms-auto'>
                                    <button
                                        className='btn btn-outline-secondary fs-3 lh-1'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                                {/* -------------------- issue539 end -------------------- */}
                            </div>
                            <div className='card-body'>
                                {/* -------------------- issue325 start -------------------- */}
                                {Object.keys(proposedPlan_1Data).length > 1 && (
                                    <NewOutputExcelButton
                                        fileName={downloadFileName}
                                        api_url={`proposed_plans_1/${id}/export`}
                                        buttonTextIsFileName={false}
                                    />
                                )}
                                {/* -------------------- issue325 end -------------------- */}
                                <DataShow data={proposedPlan_1ShowData}></DataShow>
                            </div>
                            <div className='card-footer'>
                                <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                    閉じる
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ProposedPlan_1Show;
