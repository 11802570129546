import React from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { api } from "../../api/api";
import { getPriorityCount, leaveOutEmptyPriority } from "../../components/MultipleInputForms";
import TextInputForm from "../../components/TextInputForm";

// サービス担当者会議記録内での共通要素
export const SERVICE_PERSONNEL_MEETING_PAGE_NAME = 'サービス担当者会議記録';
export const SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COUNT = 8;

// 優先順位の入力対象たる値の列名から通し番号部分を省いた名前の一覧
export const SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES = ["attendee_affiliation", "attendee_name"];

// 初期状態
const emptyDataList = { success: false };

// サービス担当者会議記録情報を取得して返します
export const getServicePersonnelMeeting = async (id, setPriorityCount = e => e) => {
    const res = await api.get(`service_personnel_meetings/show/${id}`);
    if (res.data.success) {
        // 会議出席者の登録数の更新処理が渡されている場合
        if (setPriorityCount()) {
            // 会議主席者の登録数を取得します
            const newPriorityCount = getPriorityCount(res.data.result, `${SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES[0]}_`, SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COUNT);
            // 結果をステートにセットして会議主席者の入力欄の表示数を反映します
            setPriorityCount(newPriorityCount);
        }

        // 会議出席者のデータの抜けや登録されていない要素を省きます
        const resultTrimmedEmpty = leaveOutEmptyPriority(res.data.result, SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES, SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COUNT);

        return {
            ...resultTrimmedEmpty,
            error_list: null,
            success: res.data.success
        };
    }

    return emptyDataList;
};

// 優先順位が存在する入力欄
export const ServicePersonnelMeetingPriorityInputRows = ({priorityNum = 1, priorityFormData = {emptyDataList}}) => {
    // react-hook-formの使用する機能を継承します
    const {register, watch, formState: {errors}} = useFormContext();

    const attendeeAffiliation = useWatch({name: `${SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES[0]}_${priorityNum}`});
    const attendeeName = useWatch({name: `${SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES[1]}_${priorityNum}`});

    return (<>
        <TextInputForm
            inputLabelID={`${SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES[0]}_${priorityNum}`}
            inputLabelName="所属(職種)"
            defaultValue=""
            requiredFlag={Boolean(priorityNum == 1 || attendeeAffiliation || attendeeName)}
            formData={priorityFormData}
        />
        <TextInputForm
            inputLabelID={`${SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES[1]}_${priorityNum}`}
            inputLabelName="氏名"
            defaultValue=""
            requiredFlag={Boolean(priorityNum == 1 || attendeeAffiliation || attendeeName)}
            formData={priorityFormData}
        />
    </>);
}