import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";
import formatSupportLevelList from "../../common/formatSupportLevelList";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        figure: {
            marginBottom: "0!important",
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center",
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px",
        },
    })
);

// 表示用の各データに応じた値を配列で用意
function Plan_1DeletedShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、サービス等利用計画情報のid
    const { client, id } = useParams();

    // 表示するplan_1 の状態を管理する
    const [plan_1DeletedShowData, setPlan_1DeletedShowData] = useState([]);
    const [plan_1Data, setPlan_1Data] = useState([]);

    //情報を取得しステート plan_1 にセットする
    const getPlan_1Data = async (id) => {
        const url = `plans_1/${id}/deleted`;
        const res = await api.get(url);
        setPlan_1Data(res.data.result);
        setPlan_1Row(res.data.result);
    };

    // ステートに値をセット
    const setPlan_1Row = (plan_1) => {
        const planDateFormat = dateFormat(plan_1.date, true);
        const planDate = planDateFormat.format_date;

        const supportLevel = formatSupportLevelList(plan_1.support_law, plan_1.support_level);

        const maximumAmount = `${plan_1.maximum_amount}円`;

        const monitoringStartMonthFormat = dateFormat(plan_1.start_month, true, "Y-m");
        const monitoringStartMonth = monitoringStartMonthFormat.format_date;

        const startMonthFormat = dateFormat(plan_1.start_date, true, "Y-m");
        const startMonth = startMonthFormat.format_date;

        setPlan_1DeletedShowData([
            { label: "会社名", value: plan_1.company_name },
            { label: "事業所名", value: plan_1.office_name },
            { label: "担当職員", value: plan_1.user_name },
            { label: "利用者ID", value: plan_1.client_id },
            { label: "利用者氏名", value: plan_1.client_name },
            { label: "障害支援区分", value: supportLevel },
            { label: "利用者負担上限額", value: maximumAmount },
            { label: "障害福祉サービス受給者証番号", value: plan_1.recipient_no_2 },
            { label: "地域相談支援受給者番号", value: plan_1.recipient_no_3 },
            { label: "通所受給者番号", value: plan_1.recipient_no_4 },
            { label: "計画作成日", value: planDate },
            { label: "モニタリング期間（開始年月）", value: monitoringStartMonth },
            { label: "利用者及びその家族の生活に対する意向（希望する生活）", value: plan_1.wish },
            { label: "総合的な支援の方針", value: plan_1.plan_1 },
            { label: "長期目標", value: plan_1.plan_2 },
            { label: "短期目標", value: plan_1.plan_3 },
            { label: "【1】解決すべき課題（本人のニーズ）", value: plan_1.task_1 },
            { label: "【1】支援目標", value: plan_1.target_1 },
            { label: "【1】達成期間", value: plan_1.achievement_period_1 },
            { label: "【1】種類・内容・量", value: plan_1.type_1 },
            { label: "【1】提供事業者名", value: plan_1.provider_1 },
            { label: "【1】課題解決のための本人の役割", value: plan_1.role_1 },
            { label: "【1】評価時期", value: plan_1.evaluation_period_1 },
            { label: "【1】その他留意事項", value: plan_1.note_1 },
            { label: "【2】解決すべき課題（本人のニーズ）", value: plan_1.task_2 },
            { label: "【2】支援目標", value: plan_1.target_2 },
            { label: "【2】達成期間", value: plan_1.achievement_period_2 },
            { label: "【2】種類・内容・量", value: plan_1.type_2 },
            { label: "【2】提供事業者名", value: plan_1.provider_2 },
            { label: "【2】課題解決のための本人の役割", value: plan_1.role_2 },
            { label: "【2】評価時期", value: plan_1.evaluation_period_2 },
            { label: "【2】その他留意事項", value: plan_1.note_2 },
            { label: "【3】解決すべき課題（本人のニーズ）", value: plan_1.task_3 },
            { label: "【3】支援目標", value: plan_1.target_3 },
            { label: "【3】達成期間", value: plan_1.achievement_period_3 },
            { label: "【3】種類・内容・量", value: plan_1.type_3 },
            { label: "【3】提供事業者名", value: plan_1.provider_3 },
            { label: "【3】課題解決のための本人の役割", value: plan_1.role_3 },
            { label: "【3】評価時期", value: plan_1.evaluation_period_3 },
            { label: "【3】その他留意事項", value: plan_1.note_3 },
            { label: "【4】解決すべき課題（本人のニーズ）", value: plan_1.task_4 },
            { label: "【4】支援目標", value: plan_1.target_4 },
            { label: "【4】達成期間", value: plan_1.achievement_period_4 },
            { label: "【4】種類・内容・量", value: plan_1.type_4 },
            { label: "【4】提供事業者名", value: plan_1.provider_4 },
            { label: "【4】課題解決のための本人の役割", value: plan_1.role_4 },
            { label: "【4】評価時期", value: plan_1.evaluation_period_4 },
            { label: "【4】その他留意事項", value: plan_1.note_4 },
            { label: "【5】解決すべき課題（本人のニーズ）", value: plan_1.task_5 },
            { label: "【5】支援目標", value: plan_1.target_5 },
            { label: "【5】達成期間", value: plan_1.achievement_period_5 },
            { label: "【5】種類・内容・量", value: plan_1.type_5 },
            { label: "【5】提供事業者名", value: plan_1.provider_5 },
            { label: "【5】課題解決のための本人の役割", value: plan_1.role_5 },
            { label: "【5】評価時期", value: plan_1.evaluation_period_5 },
            { label: "【5】その他留意事項", value: plan_1.note_5 },
            { label: "計画開始年月", value: startMonth },
            { label: "主な日常生活上の活動", value: plan_1.daily_routine },
            { label: "週単位以外のサービス", value: plan_1.other_service },
            { label: "サービス提供によって実現する生活の全体像", value: plan_1.total },
        ]);
    };

    // 閉じるボタンをクリックしたら、【削除済み】サービス等利用計画情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/plan_1_deleted/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getPlan_1Data(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】サービス等利用計画情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={plan_1DeletedShowData}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Plan_1DeletedShow;
