import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { Button, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataTable from "../../components/DataTable";
import BackButton from "../../components/BackButton"; // issue514

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

//見出しの配列定義
const headers = ["利用者氏名", "フリガナ", "担当職員名", "事業所名", "会社名", "復活"];

// 会社情報用配列の初期状態
const initClients = { result: [], success: true };

function ClientDeleted() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //clients の状態を管理する
    const [clients, setClients] = useState(initClients);

    //clients を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //情報を取得しステート clients にセットする
    const getClientsData = async () => {
        const url = `clients_deleted`;
        const res = await api.get(url);
        setClients(res.data);
    };

    //一覧の行クリック時、利用者情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行の利用者のid(key)と一致するclientsステートの利用者情報を変数に代入
        const client = clients.result.find((client) => client.id === id);
        // clientに値が存在する（0, "", undefined, nullでない）場合
        if (client) {
            // URL（/client_deleted/show/${client.id}）のリクエスト（RouterConfig.js）
            navigate(`/client_deleted/show/${id}`);
        }
    };

    //一覧の復活ボタンクリック時、論理削除済み利用者情報を復活
    const handleOnClickRestore = async (e, id) => {
        e.stopPropagation();

        //復活
        const url = `clients/${id}/restore`;
        const res = await api.put(url);
        if (res.data.success) {
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 復活した利用者を除外した全利用者を配列化
                const newClients = clients.result.filter((clients) => clients.id !== id);
                // ステートを更新
                setClients({ result: newClients, success: true });
            });
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/client");
    };
    //---------------------------issue{No.539} end-------------------------------

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        getClientsData();
    }, []);

    //clients に応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!clients || !clients.success) {
            return;
        }

        const newRows = clients.result.map((client) => {
            return {
                id: client.id,
                isActive: client.deleted_at === null,
                data: {
                    client_name: client.is_child ? client.child_name : client.client_name,
                    client_ruby: client.is_child ? client.child_ruby : client.client_ruby,
                    user_name: client.user_name,
                    office_name: client.office_name,
                    company_name: client.company_name,
                    deleteBtn: (
                        <Button variant='contained' onClick={(e) => handleOnClickRestore(e, client.id)}>
                            復活
                        </Button>
                    ),
                },
            };
        });
        setRows(newRows);
    }, [clients]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】利用者情報一覧</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        {/* テーブル部分の定義 */}
                        <DataTable
                            headers={headers}
                            rows={rows}
                            handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                        />
                    </Card>
                    {/* -------------------- issue514 start -------------------- */}
                    {/* -------------------- issue539 start -------------------- */}
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickBack(e)}>
                            戻る
                        </Button>
                    </div>
                    {/* -------------------- issue539 start -------------------- */}
                    {/* -------------------- issue514 start -------------------- */}
                </div>
            </div>
        </div>
    );
}

export default ClientDeleted;
