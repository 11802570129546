import React from "react";
import {useParams} from "react-router-dom";
import ClientSelectToDataList from "../../components/ClientSelectToDataList";

//見出しの配列定義
const headers = ["作成日"];

export default function InstitutionAlignment() {
    //表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDataList
        pageName="医療・保育・教育機関等連携"
        targetURL='institution_alignment'
        headerDataList={headers}
        clientID={client} />;
}