import React, { useState, useEffect, useContext, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card, Select, MenuItem } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
// 追記ここまで
import BackButton from "../../components/BackButton";
import { UserContext } from "../../providers/UserProvider";

import ScheduleRegisterTable from "../../components/schedule/ScheduleRegisterTable";

import swal from "sweetalert";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

// ステートの初期状態
const initState = { result: [], success: false };

// reducer初期値
const initPostData = [];

const today = new Date();
const thisYear = today.getFullYear();
const thisMonth = today.getMonth() + 1;
const defaultYear = thisMonth < 4 ? thisYear - 1 : thisYear; // 初期選択年度調整
const years = Array.from({ length: 5 }, (_, index) => thisYear - index);

// ディープコピーを行う関数
const deepCopy = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return obj; // オブジェクトでない場合やnullの場合はそのまま返す
    }

    if (Array.isArray(obj)) {
        // 配列の場合は要素をディープコピーして新しい配列を作成
        return obj.map((item) => deepCopy(item));
    }

    // オブジェクトの場合は各プロパティをディープコピーして新しいオブジェクトを作成
    const copiedObject = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copiedObject[key] = deepCopy(obj[key]);
        }
    }

    return copiedObject;
};

// reducer用の関数
// 追加
const createPostData = (state, data) => {
    const result = [...state, data];
    return result;
};

// 更新
const updatePostData = (state, data) => {
    // reducerの全要素を繰り返して新しい配列に
    const result = state.map((value) => {
        const isSameClient = value.client_id === data.client_id ? true : false;
        const isSameTargetMonth = value.target_month === data.target_month ? true : false;
        const isSameColumnNo = value.column_no === data.column_no ? true : false;
        const isSame = isSameClient && isSameTargetMonth && isSameColumnNo ? true : false;

        // 更新要素の場合、新しい入力値を返す
        if (isSame) {
            return data;
        } else {
            return value;
        }
    });
    return result;
};

// 削除
const removeInsertData = (state, data) => {
    // 削除対象以外の要素を取り出し、配列化
    const result = state.filter((value) => {
        return !(
            value.client_id === data.client_id &&
            value.target_month === data.target_month &&
            value.column_no === data.column_no
        );
    });
    return result;
};

const reducerFunc = (postData, action) => {
    //reducer関数にincrement、increment、reset処理を書く
    //どの処理を渡すかはactionを渡すことによって判断する
    switch (action.type) {
        case "createPostData":
            return createPostData(postData, action.data);
        case "updatePostData":
            return updatePostData(postData, action.data);
        case "removeInsertData":
            return removeInsertData(postData, action.data);
        case "reset":
            return initPostData;
        default:
            return false;
    }
};

export default function ScheduleRegister() {
    const { userInfo } = useContext(UserContext);
    const userId = userInfo.user.user_id;
    const userName = userInfo.user.user_name;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //Schedule の状態を管理する
    const [schedule, setSchedule] = useState(deepCopy(initState));
    const [users, setUsers] = useState(deepCopy(initState));
    const [targetUser, setTargetUser] = useState(userId); // 担当者
    const [targetUserData, setTargetUserData] = useState(userName);
    const [targetYear, setTargetYear] = useState(defaultYear); // 対象年度
    const [canShow, setCanShow] = useState(false);

    // コントローラー用の値管理
    const [postData, dispatch] = useReducer(reducerFunc, initPostData);

    // ユーザー一覧情報取得
    const getUsers = async () => {
        const res = await api.get("schedules_user");
        if (res.data.success) {
            setUsers(res.data);
        }
    };

    // プルダウンメニュー（対象年度）変更時の処理
    const handleOnChangeTargetYear = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setTargetYear(value);
    };

    // プルダウンメニュー（担当者）変更時の処理
    const handleOnChangeTargetUser = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setTargetUser(value);
    };

    // 担当者・対象年度をもとにスケジュールデータ取得
    const handleOnClickSelectUser = (e) => {
        e.preventDefault();
        getScheduleData();
    };

    const getScheduleData = async () => {
        const url = `schedules/${targetUser}/${targetYear}`;
        const res = await api.get(url);
        if (res.data.success) {
            setSchedule(res.data.result.clients_data);
            setTargetUserData(res.data.result.user_data);
            setCanShow(true);
        }
    };

    //  登録ボタンクリック時
    const scheduleSubmit = async (e) => {
        e.preventDefault();

        let confirm = false;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "登録しますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        //作成
        const url = "schedules";
        const res = await api.post(url, postData);
        if (res.data.success) {
            swal(res.data.message, "", "success").then(() => {
                resetUserData();
            });
        } else {
            await swal({
                title: "エラー",
                text: "問題が生じたためホーム画面に戻ります。",
                icon: "warning"
            }).then(() => {
                navigate("/home");
            });
        }
    };

    // 再選択ボタン押下時の処理
    const handleOnClickReset = (e) => {
        e.preventDefault();
        resetUserData();
    };

    // ステート等を初期化
    const resetUserData = () => {
        setCanShow(false);
        setSchedule(deepCopy(initState));
        setTargetUser(userId);
        setTargetUserData({});
        setTargetYear(defaultYear);
        dispatch({ type: "reset", data: initPostData });
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/schedule");
    };

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>スケジュール登録</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        {users.success && (
                            <>
                                <Box
                                    className='card-body'
                                    sx={{
                                        display: "flex",
                                        width: "max-content"
                                    }}>
                                    {canShow ? (
                                        <>
                                            <h2 className='d-inline-block pe-5'>{`${targetYear}年度　${targetUserData.user_name}`}</h2>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={(e) => handleOnClickReset(e)}>
                                                再選択
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Select
                                                id='targetYear'
                                                className='me-5 px-2'
                                                name='targetYear'
                                                defaultValue={defaultYear}
                                                onChange={(e) => handleOnChangeTargetYear(e)}>
                                                {years.map((year, yearIndex) => {
                                                    return (
                                                        <MenuItem key={yearIndex} value={year}>
                                                            {`${year}年度`}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <Select
                                                id='targetUser'
                                                className='me-3 px-2'
                                                name='targetUser'
                                                defaultValue={userId}
                                                onChange={(e) => handleOnChangeTargetUser(e)}>
                                                {users.result.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.user_id}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <Button
                                                variant='contained'
                                                color='primary'
                                                onClick={(e) => handleOnClickSelectUser(e)}>
                                                選択
                                            </Button>
                                        </>
                                    )}
                                </Box>
                            </>
                        )}
                        {/* テーブル部分の定義 */}
                        {canShow && (
                            <>
                                <ScheduleRegisterTable
                                    data={schedule}
                                    user={targetUserData}
                                    thisYear={targetYear}
                                    postData={postData}
                                    dispatch={dispatch}
                                />
                                {postData.length > 0 && (
                                    <div className='text-center my-4'>
                                        <Button variant='contained' color='primary' onClick={(e) => scheduleSubmit(e)}>
                                            登録
                                        </Button>
                                    </div>
                                )}
                            </>
                        )}
                    </Card>
                    {/* -------------------- issue508 start -------------------- */}
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton />
                        </div>
                    </div>
                    {/* -------------------- issue508 start -------------------- */}
                </div>
            </div>
        </div>
    );
}
