import React from "react";
import { useFormContext } from "react-hook-form";

import InputValidationErrorMessageArea from "./InputValidationErrorMessageArea";
import RequiredMark from "./RequiredMark";

// formの初期状態
const emptyDataList = {success: false};

// 登録・編集画面等における文字列の入力欄
export default function TextInputForm({inputLabelID = 'venue', inputLabelName = '開催場所', defaultValue = '', requiredFlag = false, formData = emptyDataList}) {
    // react-hook-formの使用する機能を継承します
    const {register} = useFormContext();

    return (<div className='form-group mb-4'>
        <label className='fw-bold mb-1' htmlFor={inputLabelID}>{inputLabelName}{requiredFlag && <RequiredMark />}</label>
        <input
            id={inputLabelID}
            type='text'
            defaultValue={defaultValue}
            className='form-control mb-1'
            {...register(
                inputLabelID,
                {
                    required: {
                        value: requiredFlag,
                        message: `*${inputLabelName}を入力して下さい`
                    }
                }
            )}
        />
        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
        {requiredFlag && <InputValidationErrorMessageArea labelID={inputLabelID} formData={formData} />}
    </div>);
}