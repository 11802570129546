import React, { useState, useEffect } from "react";
import { Table, TableHead, TableBody, TableRow, TableCell, Paper } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import ScheduleRegisterTableInputRow from "./ScheduleRegisterTableInputRow";

//内容の配列定義
const headers = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
const defaultArray = {
    schedule_no: 0,
    id: null
};

const months = 12;

const defaultColumnNumbers = Array(months).fill(defaultArray); // 12か月分の値

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        wrapper: {
            display: "flex",
            padding: "1rem",
            overflow: "scroll",
            maxHeight: "500px"
        }
    })
);

// ディープコピーを行う関数
const deepCopy = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return obj; // オブジェクトでない場合やnullの場合はそのまま返す
    }

    if (Array.isArray(obj)) {
        // 配列の場合は要素をディープコピーして新しい配列を作成
        return obj.map((item) => deepCopy(item));
    }

    // オブジェクトの場合は各プロパティをディープコピーして新しいオブジェクトを作成
    const copiedObject = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copiedObject[key] = deepCopy(obj[key]);
        }
    }

    return copiedObject;
};

// 予定表部分の表示用データ作成
export default function ScheduleRegisterTable(props) {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    const { data, user, thisYear, postData, dispatch } = props;
    const [showDataArray, setShowDataArray] = useState([]);

    // 各利用者の表示用配列作成
    const makeShowData = (clientsData) => {
        const results = clientsData.map((clientData) => {
            const thisYearArray = deepCopy(defaultColumnNumbers);
            const nextYearArray = deepCopy(defaultColumnNumbers);

            // 利用者情報
            const client = {
                client_id: clientData.client_id,
                client_name: clientData.client_name
            };

            // 指定年度・翌年度のデータを取り出す
            const thisYear = clientData.this_year;
            const nextYear = clientData.next_year;

            const thisYearLength = thisYear.length;
            const nextYearLength = nextYear.length;

            // 指定年度のプルダウン用配列の値を更新
            if (thisYearLength > 0) {
                let thisYearII = 0;
                for (let ii = 0; ii < months; ii++) {
                    if (ii == thisYear[thisYearII]?.column_no) {
                        thisYearArray[ii].schedule_no = thisYear[thisYearII].schedule_no;
                        thisYearArray[ii].id = thisYear[thisYearII].id;
                        thisYearII++;

                        if (thisYearLength === thisYearII) {
                            break;
                        }
                    }
                }
            }

            // 翌年度のプルダウン用配列の値を更新
            if (nextYearLength > 0) {
                let nextYearII = 0;
                for (let ii = 0; ii < months; ii++) {
                    if (ii == nextYear[nextYearII]?.column_no) {
                        nextYearArray[ii].schedule_no = nextYear[nextYearII].schedule_no;
                        nextYearArray[ii].id = nextYear[nextYearII].id;
                        nextYearII++;

                        if (nextYearLength === nextYearII) {
                            break;
                        }
                    }
                }
            }

            // 利用者1人分の表示用配列作成
            const result = {
                client: client,
                thisYearData: thisYearArray,
                nextYearData: nextYearArray
            };

            return result;
        });

        setShowDataArray(results);
    };

    useEffect(() => {
        makeShowData(data);
    }, [data]);

    return (
        <div className={classes.wrapper}>
            <Paper>
                <Table className='table-bordered'>
                    <TableHead>
                        <TableRow>
                            <TableCell className='bg-secondary bg-opacity-25 p-2'></TableCell>
                            <TableCell className='bg-primary bg-opacity-25 text-center p-2' colSpan='9'>
                                {thisYear}年
                            </TableCell>
                            <TableCell className='bg-primary bg-opacity-25 text-center p-2' colSpan='3'>
                                {thisYear + 1}年
                            </TableCell>
                            <TableCell className='bg-success bg-opacity-25 text-center p-2' colSpan='9'>
                                {thisYear + 1}年
                            </TableCell>
                            <TableCell className='bg-success bg-opacity-25 text-center p-2' colSpan='3'>
                                {thisYear + 2}年
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className='text-nowrap p-2 text-center'>氏名</TableCell>
                            {headers.map((thisYearHead, thisYearHeadIndex) => (
                                <TableCell className='bg-primary bg-opacity-25 text-center p-2' key={thisYearHeadIndex}>
                                    {thisYearHead}
                                </TableCell>
                            ))}
                            {headers.map((nextYearHead, nextYearHeadIndex) => (
                                <TableCell className='bg-success bg-opacity-25 text-center p-2' key={nextYearHeadIndex}>
                                    {nextYearHead}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* 1人分ずつ配列を展開 */}
                        {showDataArray.map((showData, showDataIndex) => (
                            <TableRow key={`showDataIndex_${showDataIndex}`}>
                                <TableCell className='text-nowrap p-2'>{showData.client.client_name}</TableCell>
                                {/* 指定年度 */}
                                <ScheduleRegisterTableInputRow
                                    showData={showData}
                                    targetYear={thisYear}
                                    targetName='thisYearData'
                                    user={user}
                                    postData={postData}
                                    dispatch={dispatch}
                                />
                                {/* 翌年度 */}
                                <ScheduleRegisterTableInputRow
                                    showData={showData}
                                    targetYear={thisYear + 1}
                                    targetName='nextYearData'
                                    user={user}
                                    postData={postData}
                                    dispatch={dispatch}
                                />
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}
