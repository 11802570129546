import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import DataShowForRegister from "../../components/DataShowForRegister";
import dateFormat from "../../common/dateFormat";
import NewOutputExcelButton from "../../components/NewOutputExcelButton";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        figure: {
            marginBottom: "0!important",
            fontWeight: "bold"
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center"
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px"
        }
    })
);

//---------------------------issue{No.440} start-----------------------------
export default function HospitalizationInfoDocumentShow(props) {
    const { idFromRegister = undefined, forRegister = false, setRefCurrent = undefined } = props;
    //---------------------------issue{No.440} end-------------------------------

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、入院時情報提供書情報のid
    const { client, id } = useParams();

    // 表示するhospitalizationInfoDocument の状態を管理する
    const [hospitalizationInfoDocumentData, setHospitalizationInfoDocumentData] = useState([]);
    const [hospitalizationInfoDocumentShowData, setHospitalizationInfoDocumentShowData] = useState([]);

    //---------------------------issue{No.325} start-----------------------------
    const [downloadState, setDownloadState] = useState("");
    //---------------------------issue{No.325} end-------------------------------
    const [downloadFileName, setDownloadFileName] = useState("");

    // "yyyy-mm-dd"を"yyyy年mm月dd日"に変更
    const formatDate = (date) => {
        const dateArr = date.split("-");
        const result = `${dateArr[0]}年${dateArr[1]}月${dateArr[2]}日`;
        return result;
    };

    //情報を取得しステート hospitalizationInfoDocument にセットする
    const getHospitalizationInfoDocumentData = async (id) => {
        const url = `hospitalization_info_documents/show/${id}`;
        const res = await api.get(url);

        if (res) {
            //---------------------------issue{No.325} start-----------------------------
            setHospitalizationInfoDocumentData(res.data.result);
            //---------------------------issue{No.325} end-------------------------------
            setHospitalizationInfoDocumentRow(res.data.result);
            setDownloadFileName(`${res.data.result.date}_${res.data.result.client_name}_入院情報提供書`);
        }
    };

    // ステートに値をセット
    const setHospitalizationInfoDocumentRow = (hospitalizationInfoDocument) => {
        const date = formatDate(hospitalizationInfoDocument.date);
        const date1 = formatDate(hospitalizationInfoDocument.date_1);
        const date2 =
            hospitalizationInfoDocument.date_2 == null ? null : formatDate(hospitalizationInfoDocument.date_2);
        const date3 =
            hospitalizationInfoDocument.date_3 == null ? null : formatDate(hospitalizationInfoDocument.date_3);
        const date4 =
            hospitalizationInfoDocument.date_4 == null ? null : formatDate(hospitalizationInfoDocument.date_4);
        const date5 =
            hospitalizationInfoDocument.date_5 == null ? null : formatDate(hospitalizationInfoDocument.date_5);
        const date6 =
            hospitalizationInfoDocument.date_6 == null ? null : formatDate(hospitalizationInfoDocument.date_6);

        //---------------------------issue{No.440} start-----------------------------
        const data = [
            { label: "作成日", value: date },
            { label: "利用者氏名", value: hospitalizationInfoDocument.client_name },
            { label: "相談支援専門員氏名", value: hospitalizationInfoDocument.user_name },
            { label: "提供先機関名", value: hospitalizationInfoDocument.institution_name },
            { label: "家族構成等", value: hospitalizationInfoDocument.family_structure },
            { label: "生活歴", value: hospitalizationInfoDocument.life_history },
            { label: "【1】病歴・障害歴（年月）", value: date1 },
            { label: "【1】病歴・障害歴（事項）", value: hospitalizationInfoDocument.detail_1 },
            { label: "【2】病歴・障害歴（年月）", value: date2 },
            { label: "【2】病歴・障害歴（事項）", value: hospitalizationInfoDocument.detail_2 },
            { label: "【3】病歴・障害歴（年月）", value: date3 },
            { label: "【3】病歴・障害歴（事項）", value: hospitalizationInfoDocument.detail_3 },
            { label: "【4】病歴・障害歴（年月）", value: date4 },
            { label: "【4】病歴・障害歴（事項）", value: hospitalizationInfoDocument.detail_4 },
            { label: "【5】病歴・障害歴（年月）", value: date5 },
            { label: "【5】病歴・障害歴（事項）", value: hospitalizationInfoDocument.detail_5 },
            { label: "【6】病歴・障害歴（年月）", value: date6 },
            { label: "【6】病歴・障害歴（事項）", value: hospitalizationInfoDocument.detail_6 },
            { label: "医療機関利用状況", value: hospitalizationInfoDocument.usage_situation },
            { label: "生活状況の概要", value: hospitalizationInfoDocument.living_conditions },
            { label: "生活基盤に関する領域", value: hospitalizationInfoDocument.life_base },
            { label: "健康・身体に関する領域", value: hospitalizationInfoDocument.health },
            { label: "日常生活に関する領域", value: hospitalizationInfoDocument.daily_life },
            { label: "コミュニケーションスキルに関する領域", value: hospitalizationInfoDocument.communication_skill },
            { label: "社会生活技能に関する領域", value: hospitalizationInfoDocument.social_skill },
            { label: "社会参加に関する領域", value: hospitalizationInfoDocument.social_involvement },
            { label: "教育・就労に関する領域", value: hospitalizationInfoDocument.daily_work },
            { label: "家族支援に関する領域", value: hospitalizationInfoDocument.family_support }
        ];

        setHospitalizationInfoDocumentShowData(data);

        if (forRegister) {
            setRefCurrent(data);
        }
        //---------------------------issue{No.440} end-------------------------------
    };

    // 閉じるボタンをクリックしたら、入院時情報提供書情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/hospitalization_info_document/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        //---------------------------issue{No.440} start-----------------------------
        getHospitalizationInfoDocumentData(idFromRegister ? idFromRegister : id);
        //---------------------------issue{No.440} end-------------------------------
    }, []);

    return (
        <>
            {forRegister ? (
                <DataShowForRegister data={hospitalizationInfoDocumentShowData}></DataShowForRegister>
            ) : (
                <div className='row justify-content-center'>
                    <div className='col-md-10 col-lg-10 mx-auto'>
                        <div className='card'>
                            <div className='card-header d-flex'>
                                <h1 className={classes.title}>入院時情報提供書情報</h1>
                                {/* -------------------- issue539 start -------------------- */}
                                <div className='ms-auto'>
                                    <button
                                        className='btn btn-outline-secondary fs-3 lh-1'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                            </div>
                            <div className='card-body'>
                                <NewOutputExcelButton
                                    fileName={downloadFileName}
                                    api_url={`hospitalization_info_documents/${id}/export`}
                                    buttonTextIsFileName={false}
                                />
                                <DataShow data={hospitalizationInfoDocumentShowData}></DataShow>
                            </div>
                            <div className='card-footer'>
                                <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                    閉じる
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
