import React from "react";

import { Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import BackButton from "./BackButton";
import HeaderPageTitle from "./HeaderPageTitle";

// カード要素のスタイルの定義
const cardStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        }
    })
);

const homePath = '/home';
// children要素を外側から囲むヘッダとフッタ
export default function PageCardHeaderAndFooter({pageName='', closePath = homePath, onClickClose = e => e, children}) {
    // 定義したスタイルを利用するための設定
    const classesCard = cardStyles();

    // 戻るボタンを持つヘッダとフッタ
    const headerAndFooter = (
        <>
            <HeaderPageTitle closePath={closePath} onClickClose={(e) => onClickClose(e)} >{pageName}
            </HeaderPageTitle>
            <Card className={classesCard.card}>
                {children}
            </Card>
            <div className='card-footer'>
                <BackButton path={closePath} onClickAction={(e) => onClickClose(e)} />
            </div>
        </>
    );
    return headerAndFooter;
}