import { SUPPORT_LEVEL_LIST } from "./constants";

const formatSupportLevelListForEdit = (law, level) => {
    if (law == 2 && level > 0) {
        return SUPPORT_LEVEL_LIST[level];
    } else {
        return "なし";
    }
};

export default formatSupportLevelListForEdit;
