import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import { Box, Button, Tooltip, Select, MenuItem, Slide, Fade, Zoom } from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import { UserContext } from "../../providers/UserProvider";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" }
        },
        dataLink: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" }
        },
        priority: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004"
        },
        priorityNum: { lineHeight: "2rem" },
        //---------------------------issue{No.440} start-----------------------------
        figure: {
            marginBottom: "0!important",
            fontWeight: "bold"
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center"
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px"
        }
        //---------------------------issue{No.440} end-------------------------------
    })
);

// 初期状態
const initHospitalizationInfoDocument = { success: true };

// 初期状態
const initLastHospitalizationInfoDocument = { success: true };

// 初期状態
const initClientData = { result: [], success: true };

// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

// 最大値に使う年月日を取得
function getMaxDay(date) {
    const y = date.getFullYear() + 1;
    date.setDate(date.getDate() - 1);
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 定数に格納
const MAX_DATE = getMaxDay(new Date());

export default function NursingOfficeAlignmentRegister() {
    //---------------------------issue{No.471} start-----------------------------
    // ログイン中のユーザー情報を受け取る
    const { userInfo } = useContext(UserContext);
    //---------------------------issue{No.471} end-------------------------------

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 利用者のidと、前回作成したデータのidを保持している
    const { client, id } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付けの入力制限のための日付の値の定数を取得
    const { MIN_DATE, MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // 入力値を管理する
    const [hospitalizationInfoDocument, setHospitalizationInfoDocument] = useState(initHospitalizationInfoDocument);

    //---------------------------issue{No.440} start-----------------------------
    // 入力値を管理する
    const [lastHospitalizationInfoDocument, setLastHospitalizationInfoDocument] = useState(
        initLastHospitalizationInfoDocument
    );

    const lastHospitalizationInfoDocumentRef = useRef(null);
    const setLastHospitalizationInfoDocumentRefCurrent = (data) => {
        lastHospitalizationInfoDocumentRef.current = data;
    };

    // 読み込みが完了しているか
    const [loaded, setLoaded] = useState(false);

    //---------------------------issue{No.440} end-------------------------------

    //clientData の状態を管理する
    const [clientData, setClientData] = useState(initClientData);

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");

    // -------------------- issue516 start --------------------
    const [startDateMessage, setStartDateMessage] = useState("");
    const [startMonthMessage, setStartMonthMessage] = useState("");
    // -------------------- issue516 end --------------------

    // 優先順位ごとの入力欄の表示個数を管理する
    const [priorityCount, setPriorityCount] = useState(1);

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `hospitalization_info_documents/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                user_name: clientResult.user_name
            });
        }
    };

    //---------------------------issue{No.471} start-----------------------------
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name
                };
            });
            setUsers(usersName);
        }
    };
    //---------------------------issue{No.471} end-------------------------------

    const getDateList = async (clientId) => {
        const url = `hospitalization_info_documents_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            const result = res.data.result.map((item) => {
                return item.date;
            });
            dateList.current = result;
        }

        const duplicate = dateCollation(today);
        if (duplicate) {
            setDateMessage("すでに作成済みの日付です。");
        } else {
            setDateMessage("");
        }
    };

    const dateCollation = (inputDate) => {
        const result = dateList.current.includes(inputDate);
        if (result) {
            return true;
        } else {
            return false;
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 作成ボタン押下時
    const hospitalizationInfoDocumentSubmit = async (data) => {
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: "すでに作成されている日付です",
                text: "作成内容を見直してください",
                timer: 2000
            });
            return;
        }

        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "作成しますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // HospitalizationInfoDocumentControllerに渡す値をセット
        const hospitalizationInfoDocumentData = {
            company_id: clientData.company_id,
            office_id: clientData.office_id,
            user_id: clientData.user_id,
            client_id: clientData.client_id,
            client_name: clientData.client_name,
            date: data.date,
            user_name: data.user_name,
            institution_name: data.institution_name,
            family_structure: data.family_structure,
            life_history: data.life_history,
            date_1: data.date_1,
            date_2: data?.date_2 ? data.date_2 : null,
            date_3: data?.date_3 ? data.date_3 : null,
            date_4: data?.date_4 ? data.date_4 : null,
            date_5: data?.date_5 ? data.date_5 : null,
            date_6: data?.date_6 ? data.date_6 : null,
            detail_1: data.detail_1,
            detail_2: data?.detail_2 ? data.detail_2 : null,
            detail_3: data?.detail_3 ? data.detail_3 : null,
            detail_4: data?.detail_4 ? data.detail_4 : null,
            detail_5: data?.detail_5 ? data.detail_5 : null,
            detail_6: data?.detail_6 ? data.detail_6 : null,
            usage_situation: data.usage_situation,
            living_conditions: data.living_conditions,
            life_base: data.life_base,
            health: data.health,
            daily_life: data.daily_life,
            communication_skill: data.communication_skill,
            social_skill: data.social_skill,
            social_involvement: data.social_involvement,
            daily_work: data.daily_work,
            family_support: data.family_support
        };

        //作成
        const url = "hospitalization_info_documents";
        const res = await api.post(url, hospitalizationInfoDocumentData);
        if (res.status === 422) {
            setHospitalizationInfoDocument({
                ...hospitalizationInfoDocument,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/hospitalization_info_document/${client}`);
            });
        }
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        //---------------------------issue{No.440} end-------------------------------
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        getDateList(client);
    }, []);

    //---------------------------issue{No.440} start-----------------------------

    // ボタンクリック時に以前の作成時の内容を、インプット内に引用する。
    const handleOnClickCitingLastData = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "入力されている内容は上書きされる場合があります。続けますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        setPriorityCount(lastHospitalizationInfoDocument.newPriorityCount);

        setHospitalizationInfoDocument({ ...hospitalizationInfoDocument, ...lastHospitalizationInfoDocument });

        // 実行済みとして、ステートを書き換える
        setCiting("done");
    };

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        //---------------------------issue{No.471} start-----------------------------
        control,
        //---------------------------issue{No.471} end-------------------------------
        clearErrors
    } = useForm();

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        if (priorityCount < 7) {
            setPriorityCount((priorityCount) => priorityCount + 1);
        }
    };

    // 支援目標の優先順位を入れ替える関数
    const handleOnClickPriorityChange = (e, num) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const higherPriority = {
            date: `date_${num}`,
            detail: `detail_${num}`
        };

        // 優先順位が一つ上がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const lowerPriority = {
            date: `date_${num + 1}`,
            detail: `detail_${num + 1}`
        };

        // 入れ替えの際に、値を一時的に控えておく定数オブジェクト
        const reserveValues = {
            date: null,
            detail: null
        };

        // 優先順位が下がる側の入力欄の値を控えておく
        for (let key of Object.keys(higherPriority)) {
            const value = getValues(higherPriority[key]);
            reserveValues[key] = value;
        }

        // 優先順位の下の入力欄の値を上の入力欄に移し替える
        for (let key of Object.keys(higherPriority)) {
            const newValue = getValues(lowerPriority[key]);
            setValue(higherPriority[key], newValue, { shouldValidate: false });
        }

        // 控えておいた値を優先順位の下の入力欄に差し替える
        for (let key of Object.keys(reserveValues)) {
            setValue(lowerPriority[key], reserveValues[key], { shouldValidate: false });
        }
    };

    // 支援目標の各優先順位の右上にある✕ボタン押下時に、支援目標を削除したり空欄に戻したりする処理
    const handleOnClickPriorityClear = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この項目を削除して続く優先順位を上に詰める、もしくはこの項目に関する入力内容をクリアしますか？",
            buttons: {
                catch: {
                    text: "入力内容をクリア",
                    value: "clear",
                    visible: true,
                    className:
                        "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                    closeModal: true
                },
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        }).then((value) => {
            switch (value) {
                case "clear":
                    // 選択範囲をクリアする
                    PriorityInputClear(num);
                    break;

                case "cancel":
                    break;

                case "delete":
                    // 先ず、対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    // 現在表示されている優先順位の個数分、優先順位を入れ替える関数を順に実行し、繰上げを行い
                    // ✕ボタンが押下され、上記の関数によって空欄になった入力欄を最下位に下げる
                    for (let ii = num; ii < priorityCount; ii++) {
                        handleOnClickPriorityChange(false, ii);
                    }
                    // 表示される優先順位の入力欄を減らす
                    setPriorityCount((priorityCount) => priorityCount - 1);
                    break;
                default:
                    break;
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const PriorityInputClear = (num) => {
        // 対象の優先順位の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = [`date_${num}`, `detail_${num}`];

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, null, { shouldValidate: false });
        }
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>入院時情報提供書作成</h1>
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 pt-1'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(hospitalizationInfoDocumentSubmit)}>
                            <div className='mb-5'>
                                <h2 className='h4'>基本情報</h2>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='date'>
                                        作成日
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='date'
                                        type='date'
                                        readOnly={true}
                                        defaultValue={today}
                                        className='form-control mb-1'
                                        {...register("date", {
                                            required: {
                                                value: true,
                                                message: "*作成日を入力してください。"
                                            }
                                        })}
                                    />
                                    {/* 日付の重複によるエラーメッセージ */}
                                    <small className='text-danger'>{dateMessage && dateMessage}</small>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.date}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='client_name'>
                                        利用者氏名
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='client_name'
                                        type='text'
                                        className='form-control mb-1'
                                        defaultValue={clientData.client_name}
                                        readOnly={true}
                                        {...register("client_name", {})}
                                    />
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1'>
                                        作成担当者
                                        <RequiredMark />
                                    </label>
                                    {Object.keys(users).length > 0 && (
                                        <Controller
                                            name='user_name'
                                            defaultValue={clientData.user_name}
                                            control={control}
                                            rules={{ required: "*計画作成担当者を選択してください。" }}
                                            render={({ field, fieldState }) => (
                                                <>
                                                    <Select
                                                        {...field}
                                                        value={field.value}
                                                        className='form-control mb-1'>
                                                        <MenuItem value='' disabled>
                                                            {Object.keys(users).length === 0 ? (
                                                                <>ユーザーが登録されていません</>
                                                            ) : (
                                                                <>選択してください</>
                                                            )}
                                                        </MenuItem>
                                                        {users.map((item, index) => (
                                                            <MenuItem key={index} value={item.user_name}>
                                                                {item.user_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </>
                                            )}
                                        />
                                    )}
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.user_name && errors.user_name?.message}
                                    </small>
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.user_name}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='institution_name'>
                                        提供先機関名
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='institution_name'
                                        type='text'
                                        className='form-control mb-1'
                                        {...register("institution_name", {
                                            required: {
                                                value: true,
                                                message: "*提供先機関名を入力してください。"
                                            }
                                        })}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.institution_name && errors.institution_name?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.institution_name}
                                    </small>
                                </div>
                            </div>
                            <hr />
                            {/* 利用者本人の概要 */}
                            <div className='mb-5'>
                                <h2 className='h4'>利用者本人の概要</h2>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='family_structure'>
                                        家族構成等
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='family_structure'
                                        className='form-control mb-1'
                                        {...register("family_structure", {
                                            required: {
                                                value: true,
                                                message: "*家族構成等を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.family_structure && errors.family_structure?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.family_structure}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='life_history'>
                                        生活歴
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='life_history'
                                        className='form-control mb-1'
                                        {...register("life_history", {
                                            required: {
                                                value: true,
                                                message: "*生活歴を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.life_history && errors.life_history?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.life_history}
                                    </small>
                                </div>
                                <h3 className='h5'>病歴・障害歴</h3>
                                {/* 1行目 */}
                                <div className={classes.priority}>
                                    <span className={classes.priorityNum}>【1】</span>
                                    {priorityCount > 1 && (
                                        <>
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 1);
                                                }}>
                                                並び順を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityClear(e, 1);
                                                    }}>
                                                    <span className='fw-bold'>×</span>
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='date_1'>
                                        年月
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='date_1'
                                        type='date'
                                        className='form-control mb-1'
                                        {...register("date_1", {
                                            required: {
                                                value: true,
                                                message: "*年月日を入力してください。"
                                            }
                                        })}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>{errors.date_1 && errors.date_1?.message}</small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.date_1}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='detail_1'>
                                        事項
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='detail_1'
                                        type='text'
                                        className='form-control mb-1'
                                        {...register("detail_1", {
                                            required: {
                                                value: true,
                                                message: "*事項を入力してください。"
                                            }
                                        })}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>{errors.detail_1 && errors.detail_1?.message}</small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.detail_1}
                                    </small>
                                </div>
                                {/* 1行目 END */}
                                {/* 2行目 */}
                                {priorityCount >= 2 && (
                                    <>
                                        <span className={classes.priorityNum}>【2】</span>
                                        <div className={classes.priority}>
                                            {priorityCount > 2 && (
                                                <button
                                                    className='btn btn-secondary btn-sm mx-1'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityChange(e, 2);
                                                    }}>
                                                    並び順を下げる<span className='fs-6 px-1'>▼</span>
                                                </button>
                                            )}
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 1);
                                                }}>
                                                <span className='fs-6 px-1'>▲</span>並び順を上げる
                                            </button>
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityClear(e, 2);
                                                    }}>
                                                    <span className='fw-bold'>×</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='date_2'>
                                                年月日
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='date_2'
                                                type='date'
                                                className='form-control mb-1'
                                                {...register("date_2", {
                                                    required: {
                                                        value: true,
                                                        message: "*年月日を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.date_2 && errors.date_2?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.date_2}
                                            </small>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='detail_2'>
                                                事項
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='detail_2'
                                                type='text'
                                                className='form-control mb-1'
                                                {...register("detail_2", {
                                                    required: {
                                                        value: true,
                                                        message: "*事項を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.detail_2 && errors.detail_2?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.detail_2}
                                            </small>
                                        </div>
                                    </>
                                )}
                                {/* 2行目 END */}
                                {/* 3行目 */}
                                {priorityCount >= 3 && (
                                    <>
                                        <span className={classes.priorityNum}>【3】</span>
                                        <div className={classes.priority}>
                                            {priorityCount > 3 && (
                                                <button
                                                    className='btn btn-secondary btn-sm mx-1'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityChange(e, 3);
                                                    }}>
                                                    並び順を下げる<span className='fs-6 px-1'>▼</span>
                                                </button>
                                            )}
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 2);
                                                }}>
                                                <span className='fs-6 px-1'>▲</span>並び順を上げる
                                            </button>
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityClear(e, 3);
                                                    }}>
                                                    <span className='fw-bold'>×</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='date_3'>
                                                年月日
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='date_3'
                                                type='date'
                                                className='form-control mb-1'
                                                {...register("date_3", {
                                                    required: {
                                                        value: true,
                                                        message: "*年月日を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.date_3 && errors.date_3?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.date_3}
                                            </small>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='detail_3'>
                                                事項
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='detail_3'
                                                type='text'
                                                className='form-control mb-1'
                                                {...register("detail_3", {
                                                    required: {
                                                        value: true,
                                                        message: "*事項を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.detail_3 && errors.detail_3?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.detail_3}
                                            </small>
                                        </div>
                                    </>
                                )}
                                {/* 3行目 END */}
                                {/* 4行目 */}
                                {priorityCount >= 4 && (
                                    <>
                                        <span className={classes.priorityNum}>【4】</span>
                                        <div className={classes.priority}>
                                            {priorityCount > 4 && (
                                                <button
                                                    className='btn btn-secondary btn-sm mx-1'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityChange(e, 4);
                                                    }}>
                                                    並び順を下げる<span className='fs-6 px-1'>▼</span>
                                                </button>
                                            )}
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 3);
                                                }}>
                                                <span className='fs-6 px-1'>▲</span>並び順を上げる
                                            </button>
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityClear(e, 4);
                                                    }}>
                                                    <span className='fw-bold'>×</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='date_4'>
                                                年月日
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='date_4'
                                                type='date'
                                                className='form-control mb-1'
                                                {...register("date_4", {
                                                    required: {
                                                        value: true,
                                                        message: "*年月日を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.date_4 && errors.date_4?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.date_4}
                                            </small>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='detail_4'>
                                                事項
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='detail_4'
                                                type='text'
                                                className='form-control mb-1'
                                                {...register("detail_4", {
                                                    required: {
                                                        value: true,
                                                        message: "*事項を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.detail_4 && errors.detail_4?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.detail_4}
                                            </small>
                                        </div>
                                    </>
                                )}
                                {/* 4行目 END */}
                                {/* 5行目 */}
                                {priorityCount >= 5 && (
                                    <>
                                        <span className={classes.priorityNum}>【5】</span>
                                        <div className={classes.priority}>
                                            {priorityCount > 5 && (
                                                <button
                                                    className='btn btn-secondary btn-sm mx-1'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityChange(e, 5);
                                                    }}>
                                                    並び順を下げる<span className='fs-6 px-1'>▼</span>
                                                </button>
                                            )}
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 4);
                                                }}>
                                                <span className='fs-6 px-1'>▲</span>並び順を上げる
                                            </button>
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityClear(e, 5);
                                                    }}>
                                                    <span className='fw-bold'>×</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='date_5'>
                                                年月日
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='date_5'
                                                type='date'
                                                className='form-control mb-1'
                                                {...register("date_5", {
                                                    required: {
                                                        value: true,
                                                        message: "*年月日を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.date_5 && errors.date_5?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.date_5}
                                            </small>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='detail_5'>
                                                事項
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='detail_5'
                                                type='text'
                                                className='form-control mb-1'
                                                {...register("detail_5", {
                                                    required: {
                                                        value: true,
                                                        message: "*事項を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.detail_5 && errors.detail_5?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.detail_5}
                                            </small>
                                        </div>
                                    </>
                                )}
                                {/* 5行目 END */}
                                {/* 6行目 */}
                                {priorityCount === 6 && (
                                    <>
                                        <div className={classes.priority}>
                                            <span className={classes.priorityNum}>【6】</span>
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 5);
                                                }}>
                                                <span className='fs-6 px-1'>▲</span>並び順を上げる
                                            </button>
                                            <div className='ms-auto'>
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    type='button'
                                                    onClick={(e) => {
                                                        handleOnClickPriorityClear(e, 6);
                                                    }}>
                                                    <span className='fw-bold'>×</span>
                                                </button>
                                            </div>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='date_6'>
                                                年月日
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='date_6'
                                                type='date'
                                                className='form-control mb-1'
                                                {...register("date_6", {
                                                    required: {
                                                        value: true,
                                                        message: "*年月日を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.date_6 && errors.date_6?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.date_6}
                                            </small>
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='location_3'>
                                                事項
                                                <RequiredMark />
                                            </label>
                                            <input
                                                id='detail_6'
                                                type='text'
                                                className='form-control mb-1'
                                                {...register("detail_6", {
                                                    required: {
                                                        value: true,
                                                        message: "*事項を入力してください。"
                                                    }
                                                })}
                                            />
                                            {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {errors.detail_6 && errors.detail_6?.message}
                                            </small>
                                            {/* サーバー側のバリデーションによるエラーメッセージ */}
                                            <small className='text-danger'>
                                                {hospitalizationInfoDocument.success
                                                    ? ""
                                                    : hospitalizationInfoDocument.error_list.detail_6}
                                            </small>
                                        </div>
                                    </>
                                )}
                                {/* 6行目 END */}
                                {priorityCount < 6 && (
                                    <div className='w-100 mb-5'>
                                        <hr />
                                        <div className='text-center'>
                                            <button
                                                type='button'
                                                className='btn btn-outline-primary'
                                                onClick={(e) => {
                                                    handleOnClickAddInput(e);
                                                }}>
                                                <span className='fw-bold fs-5 px-1'>＋</span>
                                                支援目標を追加する
                                            </button>
                                        </div>
                                    </div>
                                )}
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='usage_situation'>
                                        医療機関利用状況
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='usage_situation'
                                        className='form-control mb-1'
                                        {...register("usage_situation", {
                                            required: {
                                                value: true,
                                                message: "*医療機関利用状況を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.usage_situation && errors.usage_situation?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.usage_situation}
                                    </small>
                                </div>
                            </div>
                            {/* 利用者本人の概要 END */}
                            <hr />
                            <div className='form-group mb-4'>
                                <h2 className='h4'>現在の生活状況の概要（本人及び介護者の一日の流れ等）</h2>
                                <label className='fw-bold mb-1' htmlFor='living_conditions'>
                                    現在の生活状況の概要（本人及び介護者の一日の流れ等）
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='living_conditions'
                                    className='form-control mb-1'
                                    {...register("living_conditions", {
                                        required: {
                                            value: true,
                                            message:
                                                "*現在の生活状況の概要（本人及び介護者の一日の流れ等）を入力してください。"
                                        }
                                    })}></textarea>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.living_conditions && errors.living_conditions?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {hospitalizationInfoDocument.success
                                        ? ""
                                        : hospitalizationInfoDocument.error_list.living_conditions}
                                </small>
                            </div>
                            <hr />
                            {/* 利用者の状況 */}
                            <div>
                                <h2 className='h4'>利用者の状況（支援の有無と具体的な支援方法について）</h2>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='life_base'>
                                        1.生活基盤に関する領域
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='life_base'
                                        className='form-control mb-1'
                                        {...register("life_base", {
                                            required: {
                                                value: true,
                                                message: "*1.生活基盤に関する領域を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.life_base && errors.life_base?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.life_base}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='health'>
                                        2.健康・身体に関する領域
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='health'
                                        className='form-control mb-1'
                                        {...register("health", {
                                            required: {
                                                value: true,
                                                message: "*2.健康・身体に関する領域を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>{errors.health && errors.health?.message}</small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.health}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='daily_life'>
                                        3.日常生活に関する領域
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='daily_life'
                                        className='form-control mb-1'
                                        {...register("daily_life", {
                                            required: {
                                                value: true,
                                                message: "*3.日常生活に関する領域を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.daily_life && errors.daily_life?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.daily_life}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='communication_skill'>
                                        4.コミュニケーションスキルに関する領域
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='communication_skill'
                                        className='form-control mb-1'
                                        {...register("communication_skill", {
                                            required: {
                                                value: true,
                                                message: "*4.コミュニケーションスキルに関する領域を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.communication_skill && errors.communication_skill?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.communication_skill}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='social_skill'>
                                        5.社会生活技能に関する領域
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='social_skill'
                                        className='form-control mb-1'
                                        {...register("social_skill", {
                                            required: {
                                                value: true,
                                                message: "*5.社会生活技能に関する領域を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.social_skill && errors.social_skill?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.social_skill}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='social_involvement'>
                                        6.社会参加に関する領域
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='social_involvement'
                                        className='form-control mb-1'
                                        {...register("social_involvement", {
                                            required: {
                                                value: true,
                                                message: "*6.社会参加に関する領域を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.social_involvement && errors.social_involvement?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.social_involvement}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='daily_work'>
                                        7.教育・就労に関する領域
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='daily_work'
                                        className='form-control mb-1'
                                        {...register("daily_work", {
                                            required: {
                                                value: true,
                                                message: "*7.教育・就労に関する領域を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.daily_work && errors.daily_work?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.daily_work}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='family_support'>
                                        8.家族支援に関する領域
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='family_support'
                                        className='form-control mb-1'
                                        {...register("family_support", {
                                            required: {
                                                value: true,
                                                message: "*8.家族支援に関する領域を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.family_support && errors.family_support?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {hospitalizationInfoDocument.success
                                            ? ""
                                            : hospitalizationInfoDocument.error_list.family_support}
                                    </small>
                                </div>
                            </div>
                            {/* 利用者の状況 END */}
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    作成
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
