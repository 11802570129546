import React from "react";
import { TableRow, TableCell, Checkbox } from "@material-ui/core";

//内容の配列定義
const months = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
const frequency_list = {
    "01": "1ヶ月毎",
    "02": "2ヶ月毎",
    "03": "3ヶ月毎",
    "04": "4ヶ月毎",
    "05": "5ヶ月毎",
    "06": "半年毎",
    12: "１年毎"
};

// 予定表部分の表示用データ作成
export default function ScheduleBulkRegisterInputRow(props) {
    const { client, dispatch } = props;
    const id = client.id;
    const clientName = client.client_name;
    const referenceMonth = `${months[client.reference_month]}月`;
    const frequency = frequency_list[client.frequency];
    const schedule = client.schedule;
    const hasSchedule = schedule.length > 0 ? true : false;

    // チェックボックスの値変更時
    const handleOnchangeCheckBox = (e) => {
        const isChecked = e.target.checked;
        const typeName = isChecked ? "addData" : "removeData";
        const data = isChecked ? schedule : id;
        // dispatchを呼び出す
        dispatch({ type: typeName, data: data });
    };

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    color='primary'
                    disabled={!hasSchedule}
                    defaultChecked={hasSchedule}
                    onChange={(e) => handleOnchangeCheckBox(e)}
                />
            </TableCell>
            <TableCell>{clientName}</TableCell>
            <TableCell>{referenceMonth}</TableCell>
            <TableCell>{frequency}</TableCell>
        </TableRow>
    );
}
