import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";

import formatSupportLevelList from "../../common/formatSupportLevelList";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

// 表示用の各データに応じた値を配列で用意
const residence_types = { 1: "単身", 2: "同居有", 3: "GH", 4: "入所施設", 5: "医療機関", 6: "その他" };
const genders = { 1: "男性", 2: "女性" };
const disability_level_1_list = { 1: "1級", 2: "2級", 3: "3級", 4: "4級", 5: "5級", 6: "6級" };
const disability_level_2_list = { 1: "A1", 2: "A2", 3: "A3", 4: "B1", 5: "B2" };
const disability_level_3_list = { 1: "1級", 2: "2級", 3: "3級" };
const support_law_list = { 1: "申請中", 2: "有" };
const pension_types = { 0: "未受給または申請中", 1: "基礎", 2: "厚生" };
const pension_level_list = { 1: { 1: "1級", 2: "2級" }, 2: { 1: "1級", 2: "2級", 3: "3級" } };
const other_income_list = { 1: "生活保護", 9: "その他手当" };
const work_list = { 1: "就労継続支援A型", 2: "就労継続支援B型", 3: "一般就労", 4: "障害者雇用" };
const medical_list = { 1: "障害者医療証", 2: "自立支援医療", 3: "生活保護（医療補助）" };

const frequency_list = {
    "01": "1ヶ月毎",
    "02": "2ヶ月毎",
    "03": "3ヶ月毎",
    "04": "4ヶ月毎",
    "05": "5ヶ月毎",
    "06": "半年毎",
    12: "１年毎",
}; // issue454
const reference_month_list = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

// スケジュール登録用（日時）
const now = new Date();
const thisYear = now.getFullYear();
const nextYear = thisYear + 1;
const numThisMonth = now.getMonth() + 1;

export default function ClientShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のId
    const { id } = useParams();

    // 表示するclient の状態を管理する
    const [clientShowData, setClientShowData] = useState([]);
    const [clientData, setClientData] = useState([]);
    const [canRegisterSchedule, setCanRegisterSchedule] = useState(false);

    //一覧情報を取得しステート client にセットする
    const getClientData = async (id) => {
        const url = `clients/${id}`;
        const res = await api.get(url);
        setClientRow(res.data.result);

        // state canRegisterSchedule更新用
        const data = res.data.result;
        const canRegister = data.reference_month !== null && data.frequency !== null;
        setClientData(data);
        setCanRegisterSchedule(canRegister);
    };

    // ステートに値をセット
    const setClientRow = (client) => {
        const clientGender = genders[client.client_gender];
        const residenceType = residence_types[client.residence_type];

        const clientBirthdayFormat = dateFormat(client.client_birthday, true);
        const clientBirthday = clientBirthdayFormat.format_date;

        const childGender = genders[client.child_gender];

        const childBirthdayFormat = dateFormat(client.child_birthday, true);
        const childBirthday = childBirthdayFormat.format_date;

        let disabilityLevel1 = "";
        if (client.disability_1) {
            disabilityLevel1 = disability_level_1_list[client.disability_level_1];
        }

        let disabilityLevel2 = "";
        if (client.disability_2) {
            disabilityLevel2 = disability_level_2_list[client.disability_level_2];
        }

        let disabilityLevel3 = "";
        if (client.disability_3) {
            disabilityLevel3 = disability_level_3_list[client.disability_level_3];
        }

        const supportLaw = support_law_list[client.support_law];
        const supportLevel = formatSupportLevelList(client.support_law, client.support_level);
        const pensionType = pension_types[client.pension_type];

        let pensionLevel = "";
        if (client.pension_type) {
            pensionLevel = pension_level_list[client.pension_type][client.pension_level];
        }

        const startDateFormat = dateFormat(client.start_date, true);
        const startDate = startDateFormat.format_date;

        const otherIncome = other_income_list[client.other_income];
        const clientWork = work_list[client.work];

        let employmentInsurance = "";
        if (client.employment_insurance) {
            employmentInsurance = "有";
        }

        let welfarePension = "";
        if (client.welfare_pension) {
            welfarePension = "有";
        }

        let healthInsurance = "";
        if (client.health_insurance) {
            healthInsurance = "有";
        }

        const clientMedical = medical_list[client.medical];

        let medicalOther = "";
        if (client.medical_other) {
            medicalOther = "有";
        }

        const startMonthFormat = dateFormat(client.start_month, true, "Y-m");
        const startMonth = startMonthFormat.format_date;

        const frequency = client.frequency ? frequency_list[client.frequency] : ""; // issue454
        const referenceMonth =
            client.reference_month !== null ? `${reference_month_list[client.reference_month]}月` : "";

        setClientShowData([
            { label: "会社", value: client.company_name },
            { label: "事業所", value: client.office_name },
            { label: "担当職員", value: client.user_name },
            { label: "利用者ID", value: client.client_id },
            { label: "利用者氏名", value: client.client_name },
            { label: "利用者氏名（カナ）", value: client.client_ruby },
            { label: "性別", value: clientGender },
            { label: "生年月日", value: clientBirthday },
            { label: "電話番号", value: client.client_tel },
            { label: "緊急連絡先", value: client.emergency_tel },
            { label: "郵便番号", value: client.client_zip_code },
            { label: "住所１", value: client.client_address1 },
            { label: "住所２", value: client.client_address2 },
            { label: "住所３", value: client.client_address3 },
            { label: "行政区", value: client.political_jurisdiction_name },
            { label: "住所区分", value: residenceType },
            { label: "その他の内容", value: client.residence_type_detail },
            { label: "児童氏名", value: client.child_name },
            { label: "児童氏名（カナ）", value: client.child_ruby },
            { label: "児童生年月日", value: childBirthday },
            { label: "児童続柄", value: client.relationship },
            { label: "児童性別", value: childGender },
            { label: "身体障害者手帳", value: disabilityLevel1 },
            { label: "療育手帳", value: disabilityLevel2 },
            { label: "精神障害手帳", value: disabilityLevel3 },
            { label: "障害・または疾患名", value: client.disability_name },
            { label: "総合支援法", value: supportLaw },
            { label: "障害支援区分", value: supportLevel }, // issue546
            { label: "障害年金", value: pensionType },
            { label: "障害年金（級）", value: pensionLevel },
            { label: "障害年金受給者番号", value: client.recipient_no_1 },
            { label: "障害年金支給開始日", value: startDate },
            { label: "障害福祉サービス受給者証番号", value: client.recipient_no_2 },
            { label: "地域相談支援受給者番号", value: client.recipient_no_3 },
            { label: "通所受給者番号", value: client.recipient_no_4 },
            { label: "その他収入", value: otherIncome },
            { label: "その他収入の内容", value: client.other_income_detail },
            { label: "就労", value: clientWork },
            { label: "勤務先", value: client.workplace },
            { label: "雇用保険", value: employmentInsurance },
            { label: "厚生年金", value: welfarePension },
            { label: "健康保険", value: healthInsurance },
            { label: "医療", value: clientMedical },
            { label: "医療その他", value: medicalOther },
            { label: "医療その他の内容", value: client.medical_other_detail },
            { label: "モニタリング期間（開始年月）", value: startMonth },
            { label: "モニタリング基準月", value: referenceMonth },
            { label: "モニタリング頻度", value: frequency }, // issue454
        ]);
    };

    // スケジュール登録関連
    const getKey = (arr, val) => {
        const key = Object.keys(arr).find((key) => arr[key] === val);
        return key;
    };

    // target_month用の年数を返す
    const decideYear = (numThisMonth) => {
        const januaryColNo = 10;

        // 今月が1～3月の場合、今年の年数を返す
        if (numThisMonth < 4) {
            return thisYear;
        } else {
            // 1月以降は来年の年数を返す
            return numThisMonth < januaryColNo ? thisYear : nextYear;
        }
    };

    // 月を0埋めする
    const formatMonth = (numMonth) => {
        return ("0" + numMonth).slice(-2);
    };

    // reference_monthが今月だった場合のデータを作成
    const makeThisMonthData = (referenceMonth) => {
        const year = decideYear(numThisMonth);
        const month = formatMonth(numThisMonth);
        const targetMonth = `${year}-${month}-01`;

        const result = {
            column_no: referenceMonth,
            target_month: targetMonth,
        };

        return result;
    };

    // reference_monthが今月だった場合の残りのデータを作成
    const makeRestMonthData = (referenceMonth, frequency) => {
        const max = reference_month_list.length;
        const result = [];
        for (let ii = referenceMonth; ii < max; ii += frequency) {
            const numMonth = reference_month_list[ii];
            const year = decideYear(numMonth);
            const month = formatMonth(numMonth);
            const targetMonth = `${year}-${month}-01`;

            const data = {
                column_no: ii,
                target_month: targetMonth,
            };
            result.push(data);
        }
        return result;
    };

    const makeOtherMonthData = (referenceMonth, frequency, thisMonthKey) => {
        const max = reference_month_list.length;
        const result = [];
        for (let ii = referenceMonth; ii < max; ii += frequency) {
            if (Number(thisMonthKey) <= ii) {
                const numMonth = reference_month_list[ii];
                const year = decideYear(numMonth);
                const month = formatMonth(numMonth);
                const targetMonth = `${year}-${month}-01`;

                const data = {
                    column_no: ii,
                    target_month: targetMonth,
                };
                result.push(data);
            }
        }
        return result;
    };

    // 個別のデータを作成
    const makeIndividualData = (referenceMonth, frequency) => {
        const result = [];
        const max = reference_month_list.length;
        const thisMonthKey = getKey(reference_month_list, numThisMonth);
        const thisMonthIsReferenceMonth = numThisMonth === reference_month_list[referenceMonth]; // 今月がreferenceMonthか

        // 今月が基準月の場合、resultにデータを追加
        if (thisMonthIsReferenceMonth) {
            // 今月のデータを作成し、resultを更新する
            const thisMonthData = makeThisMonthData(referenceMonth);
            result.push(thisMonthData);

            const addedMonth = referenceMonth + frequency;
            const existRestMonth = addedMonth < max;
            if (existRestMonth) {
                const restMonth = makeRestMonthData(addedMonth, frequency);
                result.push(...restMonth);
            }
        } else {
            const restMonthData = makeOtherMonthData(referenceMonth, frequency, thisMonthKey);
            result.push(...restMonthData);
        }

        return result;
    };

    // 登録用データ作成
    const makeScheduleData = () => {
        const referenceMonth = clientData.reference_month; // 0~9
        const frequency = Number(clientData.frequency); // 1,2,3,4,5,6,12

        // 共通データ
        const commonData = {
            id: Number(id), // clients.id
            company_id: clientData.company_id,
            office_id: clientData.office_id,
            user_id: clientData.user_id,
            client_id: clientData.client_id,
            schedule_no: 2,
        };

        const individualData = makeIndividualData(referenceMonth, frequency); // 個別データ

        // 登録用データ作成
        const result = individualData.map((data) => {
            return { ...commonData, ...data };
        });

        return result;
    };

    // スケジュール登録
    const registerSchedule = async (e) => {
        e.preventDefault();

        // 登録データが存在しない場合、アラートを出して中止
        const hasData = true;
        const data = makeScheduleData(); // 登録用データ
        if (data.length === 0) {
            await swal({
                icon: "warning",
                text: "登録可能な予定月が存在しません。",
            }).then(() => {
                hasData = false;
            });
        }

        // キャンセル処理
        if (!hasData) {
            return;
        }

        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "予定を一括登録しますか？\n※「今月から年度末までの登録済みの予定」は全て削除・上書きされます。",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        const url = "schedules_bulk";
        const res = await api.post(url, data);

        if (res.data.success) {
            // 成功メッセージ
            swal(res.data.message, clientData.client_name, "success").then(() => {
                navigate("/client");
            });
        }
    };
    // スケジュール登録関連 END

    // 閉じるボタンをクリックしたら、利用者情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();
        navigate(`/client`);
    };

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        getClientData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>利用者情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        {canRegisterSchedule && (
                            <div className='mb-3'>
                                <Button variant='contained' color='primary' onClick={(e) => registerSchedule(e)}>
                                    スケジュール登録
                                </Button>
                            </div>
                        )}
                        <DataShow data={clientShowData}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
