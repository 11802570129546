// メニューの定義
// issue468 authorityを追加（権限ごとに要素を非表示にするため）
// authorityの値は非表示にしたい権限の数値
const menuConfig = [
    { auth: false, authority: 99, category: "ログイン", path: "/login", value: "ログイン" },
    { auth: true, authority: 99, category: "ホーム", path: "/home", value: "ホーム" },
    { auth: true, authority: 99, category: "日報", path: "/report", value: "日報" },
    // ---------- 20230325 issue613 ----------
    { auth: true, authority: 99, category: "相談支援関連", path: "/consultation_request", value: "相談支援依頼" },
    { auth: true, authority: 99, category: "相談支援関連", path: "/application", value: "相談支援支給申請" },
    { auth: true, authority: 99, category: "相談支援関連", path: "/assessment_1", value: "アセスメント情報" },
    { auth: true, authority: 99, category: "相談支援関連", path: "/proposed_plan_1", value: "サービス等利用計画案" },
    { auth: true, authority: 99, category: "相談支援関連", path: "/plan_1", value: "サービス等利用計画" },
    { auth: true, authority: 99, category: "相談支援関連", path: "/monitoring_1", value: "モニタリング報告書" },
    {
        auth: true,
        authority: 99,
        category: "加算関連",
        path: "/service_personnel_meeting",
        value: "サービス担当者会議"
    },
    {
        auth: true,
        authority: 99,
        category: "加算関連",
        path: "/institution_alignment",
        value: "医療・保育・教育機関等連携"
    },
    {
        auth: true,
        authority: 99,
        category: "加算関連",
        path: "/nursing_office_alignment",
        value: "居宅介護支援事業所等連携"
    },
    { auth: true, authority: 99, category: "加算関連", path: "/discharge_addition", value: "退院・退所加算" },
    {
        auth: true,
        authority: 99,
        category: "加算関連",
        path: "/hospitalization_info_document",
        value: "入院時情報提供書"
    },
    { auth: true, authority: 99, category: "加算関連", path: "/monitoring_draft", value: "モニタリング記録" },
    // ---------- 20230325 issue613 END ----------
    { auth: true, authority: 99, category: "スケジュール", path: "/schedule", value: "スケジュール" },
    { auth: true, authority: 99, category: "利用者情報", path: "/client", value: "利用者情報" },
    { auth: true, authority: 99, category: "アカウント", path: "/companies", value: "会社情報" },
    { auth: true, authority: 99, category: "アカウント", path: "/office", value: "事業所情報" },
    { auth: true, authority: 99, category: "アカウント", path: "/users", value: "ユーザー情報" },
    { auth: true, authority: 99, category: "アカウント", path: "/logout", value: "ログアウト" }
];

export default menuConfig;
