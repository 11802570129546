import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

// 表示用の各データに応じた値を配列で用意
const submitter_list = { 1: "申請者本人", 2: "申請者本人以外" };

function ApplicationDeletedShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のId
    const { client, id } = useParams();

    // 表示するapplication の状態を管理する
    const [applicationShowData, setApplicationShowData] = useState([]);

    //一覧情報を取得しステート application にセットする
    const getApplicationData = async (id) => {
        const url = `applications/${id}/deleted`;
        const res = await api.get(url);
        setApplicationRow(res.data.result);
    };

    // ステートに値をセット
    const setApplicationRow = (application) => {
        const applicationDateFormat = dateFormat(application.date, true);
        const applicationDate = applicationDateFormat.format_date;

        const clientBirthdayFormat = dateFormat(application.client_birthday, true);
        const clientBirthday = clientBirthdayFormat.format_date;

        const childBirthdayFormat = dateFormat(application.child_birthday, true);
        const childBirthday = childBirthdayFormat.format_date;

        const submitter = submitter_list[application.submitter];

        setApplicationShowData([
            { label: "会社", value: application.company_name },
            { label: "事業所", value: application.offices_office_name },
            { label: "担当職員", value: application.user_name },
            { label: "利用者ID", value: application.client_id },
            { label: "申請先", value: application.submission },
            { label: "申請年月日", value: applicationDate },
            { label: "利用者フリガナ", value: application.client_ruby },
            { label: "利用者氏名", value: application.client_name },
            { label: "生年月日", value: clientBirthday },
            { label: "郵便番号", value: application.client_zip_code },
            { label: "住所", value: application.client_address },
            { label: "電話番号", value: application.client_tel },
            { label: "児童フリガナ", value: application.child_ruby },
            { label: "児童氏名", value: application.child_name },
            { label: "児童生年月日", value: childBirthday },
            { label: "児童続柄", value: application.relationship },
            { label: "申請書提出者", value: submitter },
            { label: "申請者との関係", value: application.relationship2 },
            { label: "事業所名（カナ）", value: application.office_ruby },
            { label: "事業所名", value: application.office_name },
            { label: "事業所郵便番号", value: application.office_zip_code },
            { label: "事業所住所", value: application.office_address },
            { label: "事業所電話番号", value: application.office_tel },
        ]);
    };

    // 閉じるボタンをクリックしたら、【削除済み】相談支援支給申請一覧画面に遷移する
    const handleOnClickBack = (e) => {
        e.stopPropagation();

        navigate(`/application_deleted/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getApplicationData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】相談支援支給申請情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={applicationShowData}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickBack(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApplicationDeletedShow;
