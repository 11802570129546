import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Box, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataTable from "../../components/DataTable";
import FreeWordSearch from "../../components/FreeWordSearch";
import { UserContext } from "../../providers/UserProvider";
import BackHomeButton from "../../components/BackHomeButton"; // issue508

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        buttonHeight: {
            height: "37px",
            marginBottom: "0",
            lineHeight: "37px",
        },
    })
);

//見出しの配列定義
const headers = ["会社名", "事業所名", "編集", "削除"];
// 検索対象のカラムの配列定義
const columns = ["company_name", "office_name"];

// 事業所情報用配列の初期状態
const initOffices = { result: [], success: true };

function Office() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //offices の状態を管理する
    const [offices, setOffices] = useState(initOffices);

    // 検索された事業所データを格納するステート
    const [searchedOffices, setSearchedOffices] = useState([]);

    //offices を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //---------------------------issue{No.463} start-------------------------------
    // ログインユーザーの情報管理ステート
    const [userCompanyId, setUserCompanyId] = useState(""); // 会社コード
    const [userOfficeId, setUserOfficeId] = useState(0); // 事業所コード

    // ボタン表示フラグ
    const [displayRegisterButton, setDisplayRegisterButton] = useState(false); // 新規登録・削除
    const [displayEditButton, setDisplayEditButton] = useState(false); // 編集

    // ログインユーザーの情報取得
    const { userInfo } = useContext(UserContext);
    // 元データ
    const userCompanyIdForState = userInfo.user.company_id; // 会社コード
    const userOfficeIdOrigin = userInfo.user.office_id; // 事業所コード
    const userAuthorityOrigin = userInfo.user.authority; // 権限

    // 数値型に変換
    const userOfficeIdForState = Number(userOfficeIdOrigin); // 事業所コード
    const userAuthority = Number(userAuthorityOrigin); // 権限

    // 権限ごとにステート（ボタン表示フラグ及びログインユーザー情報）を調整(初回のみ実行)
    useEffect(() => {
        // 権限
        // 会社代表以上
        if (userAuthority < 30) {
            setDisplayRegisterButton(true);
        }

        // 事業所管理者以上
        if (userAuthority < 40) {
            setDisplayEditButton(true);
        }

        // ログインユーザー情報
        setUserCompanyId(userCompanyIdForState); // 会社コード
        setUserOfficeId(userOfficeIdForState); // 事業所コード
    }, []);
    //---------------------------issue{No.463} end-------------------------------

    //情報を取得しステート offices にセットする
    const getOfficesData = async () => {
        const res = await api.get("offices");
        setOffices(res.data);
    };

    //  登録ボタンクリック時
    const handleOnClickRegist = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(office_register)をたたく（RouterConfig.js）
        navigate(`/office_register`);
    };

    // -------------------- issue514 start --------------------
    //  復活ボタンクリック時
    const handleOnClickRestore = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(office_deleted)をたたく（RouterConfig.js）
        navigate(`/office_deleted`);
    };
    // -------------------- issue514 end --------------------

    // 一覧の行クリック時、事業所情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行の事業所のid(key)と一致するofficesステートの事業所情報を変数に代入
        const office = offices.result.find((office) => office.id === id);
        // officeに値が存在する（0, "", undefined, nullでない）場合
        if (office) {
            // URL(/office/show/${office.id})をたたく（RouterConfig.js）
            navigate(`/office/show/${id}`);
        }
    };

    // 一覧の編集ボタンクリック時、事業所情報編集画面へ
    const handleOnClickEdit = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // URL(/office/edit/${id})をたたく（RouterConfig.js）
        navigate(`/office/edit/${id}`);
    };

    //一覧の削除ボタンをクリック時、論理削除
    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        //論理削除
        const url = `offices/${id}`;
        const res = await api.delete(url);
        if (res.data.success) {
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除した事業所を除外した全事業所を配列化
                const newOffices = offices.result.filter((office) => office.id !== id);
                // ステートを更新
                setOffices({ result: newOffices, success: true });
            });
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/home");
    };
    //---------------------------issue{No.539} end-------------------------------

    // 画面初期表示時に事業所情報を取得する
    useEffect(() => {
        getOfficesData();
    }, []);

    //offices に応じて、画面表示用の配列を生成
    useEffect(() => {
        //---------------------------issue{No.59} start-----------------------------
        if (!searchedOffices || !offices.success) {
            return;
        }
        //---------------------------issue{No.59} end-------------------------------

        const newRows = searchedOffices.map((office) => {
            //---------------------------issue{No.463} start-------------------------------
            // 各事業所の情報
            let companyId = office.company_id; // 会社コード
            let officeId = office.office_id; // 事業所コード
            let isOther = true; // 他事業所フラグ

            // 自身の所属事業所の場合、他事業所フラグをfalseに変更
            if (userCompanyId === companyId && userOfficeId === officeId) {
                isOther = false;
            }
            //---------------------------issue{No.463} end-------------------------------
            return {
                id: office.id,
                isActive: office.deleted_at === null,
                data: {
                    company_name: office.company_name,
                    office_name: office.office_name,
                    editBtn: displayEditButton ? (
                        <Button variant='contained' color='primary' onClick={(e) => handleOnClickEdit(e, office.id)}>
                            編集
                        </Button>
                    ) : (
                        <p className={classes.buttonHeight}>-</p>
                    ),
                    // ボタン表示フラグ・他事業所フラグ共にtrueの場合のみボタンを表示
                    deleteBtn:
                        displayRegisterButton && isOther ? (
                            <Button variant='contained' onClick={(e) => handleOnClickDelete(e, office.id)}>
                                削除
                            </Button>
                        ) : (
                            <p className={classes.buttonHeight}>-</p>
                        ),
                },
            };
        });
        setRows(newRows);
    }, [searchedOffices]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>事業所情報一覧</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        {displayRegisterButton && (
                            <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                                <Button variant='contained' color='primary' onClick={(e) => handleOnClickRegist(e)}>
                                    新規登録
                                </Button>
                                {/* -------------------- issue514 start -------------------- */}
                                <Button className='ms-3' variant='contained' onClick={(e) => handleOnClickRestore(e)}>
                                    削除済み一覧
                                </Button>
                                {/* -------------------- issue514 end -------------------- */}
                            </Box>
                        )}
                        <FreeWordSearch
                            searchColumns={columns}
                            data={offices.result}
                            setSearchedData={setSearchedOffices}
                        />
                        {/* テーブル部分の定義 */}
                        <DataTable
                            headers={headers}
                            rows={rows}
                            handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                        />
                    </Card>
                    {/* -------------------- issue508 start -------------------- */}
                    <div className='card-footer'>
                        <BackHomeButton />
                    </div>
                    {/* -------------------- issue508 end -------------------- */}
                </div>
            </div>
        </div>
    );
}

export default Office;
