import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { UserProvider } from "./providers/UserProvider";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import RouterConfig from "./layout/RouterConfig";
import ScrollButtons from "./components/ScrollButtons";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        header: {
            margin: "10 0 10 0",
            backgroundColor: "#add8e6",
            display: "flex",
            justifyContent: "space-between",
        },
        footer: {
            textAlign: "center",
            borderTop: "1px solid #777",
            margin: "10 0 10 0",
        },
    })
);

function Root() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    return (
        <UserProvider>
            <BrowserRouter>
                <div className='container-lg'>
                    <div id='header' className={classes.header}>
                        <Header />
                    </div>
                    <div id='content'>
                        <RouterConfig />
                    </div>
                    <div id='footer' className={classes.footer}>
                        <Footer />
                    </div>
                    <ScrollButtons />
                </div>
            </BrowserRouter>
        </UserProvider>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Root />, rootElement);
