import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDeletedDataList from "../../components/ClientSelectToDeletedDataList";

// 見出しの配列定義
const headers = ["届出年月日"];

export default function ConsultationRequestDeleted() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDeletedDataList pageName='相談支援依頼' targetURL='consultation_request' headerDataList={headers} clientID={client} />;
}