import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
import { usePostalJp } from "use-postal-jp";
import { Select, MenuItem, Checkbox, FormControlLabel } from "@material-ui/core";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import { UserContext } from "../../providers/UserProvider";
import BackButton from "../../components/BackButton";

import { SUPPORT_LEVEL_LIST } from "../../common/constants";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

const initClient = { success: true };

// プルダウンメニュー選択肢用の各データに応じた値を配列で用意
const residence_types = { 1: "単身", 2: "同居有", 3: "GH", 4: "入所施設", 5: "医療機関", 6: "その他" };
const genders = { 1: "男性", 2: "女性" };
const disability_level_1_list = { 1: "1級", 2: "2級", 3: "3級", 4: "4級", 5: "5級", 6: "6級" };
const disability_level_2_list = { 1: "A1", 2: "A2", 3: "A3", 4: "B1", 5: "B2" };
const disability_level_3_list = { 1: "1級", 2: "2級", 3: "3級" };
const support_law_list = { 0: "未申請", 1: "申請中", 2: "有" };
const pension_types = { 0: "未受給または申請中", 1: "基礎", 2: "厚生" };
const pension_level_list = { 1: { 1: "1級", 2: "2級" }, 2: { 1: "1級", 2: "2級", 3: "3級" } };
const other_income_list = { 0: "なし", 1: "生活保護", 9: "その他手当" };
const work_list = { 0: "なし", 1: "就労継続支援A型", 2: "就労継続支援B型", 3: "一般就労", 4: "障害者雇用" };
const medical_list = { 0: "なし", 1: "障害者医療証", 2: "自立支援医療", 3: "生活保護（医療補助）" };
const frequency_list = [
    { value: "01", label: "１ヶ月毎" },
    { value: "02", label: "２ヶ月毎" },
    { value: "03", label: "３ヶ月毎" },
    { value: "04", label: "４ヶ月毎" },
    { value: "05", label: "５ヶ月毎" },
    { value: "06", label: "半年毎" },
    { value: "12", label: "１年毎" },
];
const reference_month_list = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

// 郵便番号が不正の場合に表示するメッセージ
const zipCodeErrorMessage = "存在しない郵便番号です";

//---------------------------issue{No.382} start-----------------------------
// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());
//---------------------------issue{No.382} end-------------------------------

export default function ClientEdit() {
    // ログイン中のユーザー情報を受け取る
    const { userInfo } = useContext(UserContext);

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //編集対象のクライアント情報のId
    const { id } = useParams();

    // client の状態を管理する
    const [client, setClient] = useState(initClient);

    // データベースから取得したユーザーのデータを管理する
    const [usersResult, setUsersResult] = useState([]);

    // プルダウンメニューに表示するユーザーのデータを管理する
    const [users, setUsers] = useState([]);

    // 選択に応じて表示される入力項目の切り替えに使うステート
    const [residenceType, setResidenceType] = useState(0);
    const [isChild, setIsChild] = useState(false);
    const [isDisability1, setIsDisability1] = useState(false);
    const [isDisability2, setIsDisability2] = useState(false);
    const [isDisability3, setIsDisability3] = useState(false);
    const [pensionType, setPensionType] = useState(0);
    const [otherIncome, setOtherIncome] = useState(0);
    const [clientWork, setClientWork] = useState(0);
    const [isMedicalOther, setIsMedicalOther] = useState(false);

    const [politicalJurisdiction, setPoliticalJurisdiction] = useState([]);

    const [canShowSupportLevel, setCanShowSupportLevel] = useState(false);

    //クライアント情報を取得しステート client にセットする
    const getClientData = async (id) => {
        const url = `clients/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setClient({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
            const defaultSupportLaw = res.data.result.support_law;
            if (defaultSupportLaw == 2) {
                setCanShowSupportLevel(true);
            }
        }
    };

    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users/office";
        const res = await api.get(url);
        if (res.data.success) {
            const users = res.data.result.map((user) => {
                return {
                    label: user.user_name,
                    value: user.user_id,
                    companyId: user.company_id,
                    officeId: user.office_id,
                };
            });
            setUsersResult(users);
        }
    };

    //会社情報を取得しステート companies にセットする
    const getPoliticalJurisdictionData = async () => {
        const url = "political_jurisdictions";
        const res = await api.get(url);
        if (res.data.success) {
            const result = res.data.result;
            setPoliticalJurisdiction(result);
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 登録ボタン押下時
    const clientSubmit = async (data) => {
        // 住所取得のAPI通信が行われている最中の場合
        // 処理を抜けてアラートを表示する
        if (loading) {
            swal({
                icon: "warning",
                title: "住所の取得中です",
                text: "再度お試しください",
                timer: 2000,
            });
            return;
        }

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // ClientControllerに渡す値をセット
        const clientData = {
            company_id: client.company_id,
            office_id: client.office_id,
            user_id: data.user_id,
            is_child: data.is_child,
            client_zip_code: data.client_zip_code,
            client_address1: data.client_address1,
            client_address2: data.client_address2,
            client_address3: data.client_address3,
            political_jurisdiction_id: data.political_jurisdiction_id,
            residence_type: data.residence_type,
            residence_type_detail: data.residence_type_detail,
            client_ruby: data.client_ruby,
            client_name: data.client_name,
            client_birthday: data.client_birthday,
            client_tel: data.client_tel,
            emergency_tel: data.emergency_tel,
            client_gender: data.client_gender,
            child_ruby: data.child_ruby,
            child_name: data.child_name,
            child_birthday: data.child_birthday,
            relationship: data.relationship,
            child_gender: data.child_gender,
            support_level: data.support_level,
            disability_1: data.disability_1,
            disability_level_1: data.disability_level_1,
            disability_2: data.disability_2,
            disability_level_2: data.disability_level_2,
            disability_3: data.disability_3,
            disability_level_3: data.disability_level_3,
            disability_name: data.disability_name,
            support_law: data.support_law,
            pension_type: data.pension_type,
            pension_level: data.pension_level,
            recipient_no_1: data.recipient_no_1,
            start_date: data.start_date,
            recipient_no_2: data.recipient_no_2,
            recipient_no_3: data.recipient_no_3,
            recipient_no_4: data.recipient_no_4,
            other_income: data.other_income,
            other_income_detail: data.other_income_detail,
            work: data.work,
            workplace: data.workplace,
            employment_insurance: data.employment_insurance,
            welfare_pension: data.welfare_pension,
            health_insurance: data.health_insurance,
            medical: data.medical,
            medical_other: data.medical_other,
            medical_other_detail: data.medical_other_detail,
            start_month: data.start_month,
            frequency: data.frequency,
            reference_month: data.reference_month === "" ? null : data.reference_month,
        };

        //---------------------------issue{No.330} start-----------------------------
        if (residenceType != 6) {
            clientData.residence_type_detail = null;
        }
        if (!isChild) {
            clientData.child_name = null;
            clientData.child_ruby = null;
            clientData.child_gender = null;
            clientData.child_birthday = null;
            clientData.relationship = null;
        }
        if (!isDisability1) {
            clientData.disability_level_1 = null;
        }
        if (!isDisability2) {
            clientData.disability_level_2 = null;
        }
        if (!isDisability3) {
            clientData.disability_level_3 = null;
        }
        if (otherIncome != "9") {
            clientData.other_income_detail = null;
        }
        if (clientWork == "0") {
            clientData.workplace = null;
        }
        if (!isMedicalOther) {
            clientData.medical_other_detail = null;
        }
        if (clientData.support_law != 2) {
            clientData.support_level = 0;
        }
        //---------------------------issue{No.330} end-------------------------------

        //登録
        const url = `clients/${client.id}`;
        const res = await api.put(url, clientData);
        if (res.status === 422) {
            setClient({
                ...client,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate("/client");
            });
        }
    };

    //画面初期表示時にデータベースから情報を取得する
    useEffect(() => {
        getClientData(id);
        getUsersData();
        getPoliticalJurisdictionData();
    }, []);

    // データ取得後に、フォームの初期値の入力を行う副作用フック
    // 同じ処理を、clientによる副作用フックにも含めている
    useEffect(() => {
        if (Object.keys(client).length > 0) {
            if (usersResult.length > 0) {
                const usersInCompany = usersResult.filter((user) => user.companyId === client.company_id);
                const usersInOffice = usersInCompany.filter((user) => user.officeId === client.office_id);
                setUsers(usersInOffice);
                setValue("user_id", client.user_id, { shouldValidate: false });
            }
        }
    }, [usersResult]);

    // use-postal-jpの初期設定
    const [zipCode, setZipCode] = useState("");
    const [address, loading, error] = usePostalJp(zipCode, zipCode.length === 7);

    // 郵便番号による住所取得が行われるたびに、以下の副作用フックを実行
    useEffect(() => {
        const setClientAddress = () => {
            // addressに正しく値が取得できている場合に、tryが実行される
            try {
                // addressから、必要な情報を分割代入
                const { prefecture, address1, address2 } = address;
                // 文字列の連結
                const clientAddress = prefecture + address1 + address2;
                // react-hook-formのsetValueでaddress1を更新
                setValue("client_address1", clientAddress, {
                    shouldValidate: true,
                });
            } catch {
                // 郵便番号が不正、もしくは初回の読み込みの際に値を空にする
                setValue("client_address1", "", { shouldValidate: false });
            }
        };
        // 上記の関数を実行
        setClientAddress();
        // addressの取得が済んで変更されたとき、この副作用フックを実行
    }, [address]);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        control,
        //---------------------------issue{No.558} start-----------------------------
        trigger,
        //---------------------------issue{No.558} end-------------------------------
    } = useForm();

    // inputの入力値が変わる度にそれぞれの項目に応じた処理を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                //---------------------------issue{No.558} start-----------------------------
                if (name === "client_zip_code") {
                    // バリデーションを実行
                    trigger("client_zip_code");
                    // zipCodeの値を更新
                    setZipCode(value.client_zip_code);
                }
                //---------------------------issue{No.558} end-------------------------------
                if (name === "residence_type") {
                    setResidenceType(value.residence_type);
                }
                if (name === "is_child") {
                    const checkIsChild = value.is_child;
                    setIsChild(checkIsChild);
                }
                if (name === "disability_1") {
                    const checkIsDisability1 = value.disability_1;
                    setIsDisability1(checkIsDisability1);
                }
                if (name === "disability_2") {
                    const checkIsDisability2 = value.disability_2;
                    setIsDisability2(checkIsDisability2);
                }
                if (name === "disability_3") {
                    const checkIsDisability3 = value.disability_3;
                    setIsDisability3(checkIsDisability3);
                }
                if (name === "pension_type") {
                    const checkPensionType = value.pension_type;
                    setPensionType(checkPensionType);
                    setValue("pension_level", "", { shouldValidate: false });
                }
                if (name === "other_income") {
                    const checkOtherIncome = value.other_income;
                    setOtherIncome(checkOtherIncome);
                }
                if (name === "work") {
                    const checkClientWork = value.work;
                    setClientWork(checkClientWork);
                }
                if (name === "medical_other") {
                    const checkIsMedicalOther = value.medical_other;
                    setIsMedicalOther(checkIsMedicalOther);
                }
                if (name === "support_law") {
                    const checkSupportLaw = value.support_law;
                    const canShow = "2";
                    if (checkSupportLaw === canShow) {
                        setCanShowSupportLevel(true);
                    } else {
                        setCanShowSupportLevel(false);
                    }
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(client).length > 1 && !getValues("client_name")) {
            if (usersResult.length > 0) {
                const usersInCompany = usersResult.filter((user) => user.companyId === client.company_id);
                const usersInOffice = usersInCompany.filter((user) => user.officeId === client.office_id);
                setUsers(usersInOffice);
                setValue("user_id", client.user_id, { shouldValidate: false });
            }
            if (client.is_child !== null) {
                setValue("is_child", client.is_child, { shouldValidate: false });
            }
            if (client.is_child == "1") {
                setIsChild(true);
            }
            setValue("is_child", client.is_child, { shouldValidate: false });
            if (client.client_zip_code !== null) {
                setValue("client_zip_code", client.client_zip_code, { shouldValidate: false });
            }
            if (client.client_address1 !== null) {
                setValue("client_address1", client.client_address1, { shouldValidate: false });
            }
            if (client.client_address2 !== null) {
                setValue("client_address2", client.client_address2, { shouldValidate: false });
            }
            if (client.client_address3 !== null) {
                setValue("client_address3", client.client_address3, { shouldValidate: false });
            }
            if (client.political_jurisdiction_id !== null) {
                setValue("political_jurisdiction_id", client.political_jurisdiction_id, { shouldValidate: false });
            }
            if (client.residence_type !== null) {
                setValue("residence_type", client.residence_type, { shouldValidate: false });
                setResidenceType(client.residence_type);
            }
            if (client.residence_type_detail !== null) {
                setValue("residence_type_detail", client.residence_type_detail, { shouldValidate: false });
            }
            if (client.client_ruby !== null) {
                setValue("client_ruby", client.client_ruby, { shouldValidate: false });
            }
            if (client.client_name !== null) {
                setValue("client_name", client.client_name, { shouldValidate: false });
            }
            if (client.client_birthday !== null) {
                setValue("client_birthday", client.client_birthday, { shouldValidate: false });
            }
            if (client.client_tel !== null) {
                setValue("client_tel", client.client_tel, { shouldValidate: false });
            }
            if (client.emergency_tel !== null) {
                setValue("emergency_tel", client.emergency_tel, { shouldValidate: false });
            }
            if (client.client_gender !== null) {
                setValue("client_gender", client.client_gender, { shouldValidate: false });
            }
            if (client.child_ruby !== null) {
                setValue("child_ruby", client.child_ruby, { shouldValidate: false });
            }
            if (client.child_name !== null) {
                setValue("child_name", client.child_name, { shouldValidate: false });
            }
            if (client.child_birthday !== null) {
                setValue("child_birthday", client.child_birthday, { shouldValidate: false });
            }
            if (client.relationship !== null) {
                setValue("relationship", client.relationship, { shouldValidate: false });
            }
            if (client.child_gender !== null) {
                setValue("child_gender", client.child_gender, { shouldValidate: false });
            }
            setValue("support_level", client.support_level, { shouldValidate: false });
            setValue("disability_1", client.disability_1, { shouldValidate: false });
            if (client.disability_1 == "1") {
                setIsDisability1(true);
            }
            if (client.disability_level_1 !== null) {
                setValue("disability_level_1", client.disability_level_1, { shouldValidate: false });
            }
            setValue("disability_2", client.disability_2, { shouldValidate: false });
            if (client.disability_2 == "1") {
                setIsDisability2(true);
            }
            if (client.disability_level_2 !== null) {
                setValue("disability_level_2", client.disability_level_2, { shouldValidate: false });
            }
            setValue("disability_3", client.disability_3, { shouldValidate: false });
            if (client.disability_3 == "1") {
                setIsDisability3(true);
            }
            if (client.disability_level_3 !== null) {
                setValue("disability_level_3", client.disability_level_3, { shouldValidate: false });
            }
            if (client.disability_name !== null) {
                setValue("disability_name", client.disability_name, { shouldValidate: false });
            }
            if (client.support_law !== null) {
                setValue("support_law", client.support_law, { shouldValidate: false });
            }
            setValue("pension_type", client.pension_type, { shouldValidate: false });
            setPensionType(client.pension_type);
            if (client.pension_level !== null) {
                setValue("pension_level", client.pension_level, { shouldValidate: false });
            }
            if (client.recipient_no_1 !== null) {
                setValue("recipient_no_1", client.recipient_no_1, { shouldValidate: false });
            }
            if (client.start_date !== null) {
                setValue("start_date", client.start_date, { shouldValidate: false });
            }
            if (client.recipient_no_2 !== null) {
                setValue("recipient_no_2", client.recipient_no_2, { shouldValidate: false });
            }
            if (client.recipient_no_3 !== null) {
                setValue("recipient_no_3", client.recipient_no_3, { shouldValidate: false });
            }
            if (client.recipient_no_4 !== null) {
                setValue("recipient_no_4", client.recipient_no_4, { shouldValidate: false });
            }
            if (client.other_income !== null) {
                setValue("other_income", client.other_income, { shouldValidate: false });
            }
            setOtherIncome(client.other_income);
            if (client.other_income_detail !== null) {
                setValue("other_income_detail", client.other_income_detail, { shouldValidate: false });
            }
            if (client.work !== null) {
                setValue("work", client.work, { shouldValidate: false });
            }
            setClientWork(client.work);
            if (client.workplace !== null) {
                setValue("workplace", client.workplace, { shouldValidate: false });
            }
            setValue("employment_insurance", client.employment_insurance, { shouldValidate: false });
            setValue("welfare_pension", client.welfare_pension, { shouldValidate: false });
            setValue("health_insurance", client.health_insurance, { shouldValidate: false });
            if (client.medical !== null) {
                setValue("medical", client.medical, { shouldValidate: false });
            }
            setValue("medical_other", client.medical_other, { shouldValidate: false });
            if (client.medical_other == "1") {
                setIsMedicalOther(true);
            }
            if (client.medical_other_detail !== null) {
                setValue("medical_other_detail", client.medical_other_detail, { shouldValidate: false });
            }
            if (client.start_month !== null) {
                setValue("start_month", client.start_month, { shouldValidate: false });
            }
            if (client.frequency !== null) {
                setValue("frequency", client.frequency, { shouldValidate: false });
            }
            if (client.reference_month !== null) {
                setValue("reference_month", client.reference_month, { shouldValidate: false });
            }

            //---------------------------issue{No.558} start-----------------------------
            setZipCode(client.client_zip_code);
            //---------------------------issue{No.558} end-------------------------------
        }
    }, [client]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>利用者情報編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(clientSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>会社</label>
                                <input
                                    type='text'
                                    defaultValue={client.company_name}
                                    className='form-control'
                                    disabled='disabled'
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>事業所</label>
                                <input
                                    type='text'
                                    defaultValue={client.office_name}
                                    className='form-control'
                                    disabled='disabled'
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    担当職員
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='user_id'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*担当職員を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {Object.keys(users).length > 0 && (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        選択してください
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_id && errors.user_id?.message}</small>
                                <small className='text-danger'>{client.success ? "" : client.error_list.user_id}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='client_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("client_name", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_name && errors.client_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_ruby'>
                                    利用者氏名（カナ）
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （全角カタカナ、全角スペースのみ）
                                    </small>
                                </label>
                                <input
                                    id='client_ruby'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("client_ruby", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名（カナ）を入力してください。",
                                        },
                                        pattern: {
                                            value: /^[ァ-ヶ]+[ァ-ヶー]*　?[ァ-ヶ]+[ァ-ヶー]*$/,
                                            message: "*入力内容を確認してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_ruby && errors.client_ruby?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_ruby}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    性別
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='client_gender'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*性別を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {Object.keys(genders).map((key, index) => (
                                                <MenuItem key={index} value={key}>
                                                    {genders[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_gender && errors.client_gender?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_gender}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_birthday'>
                                    生年月日
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>（0000/00/00の形式で入力）</small>
                                </label>
                                <input
                                    id='client_birthday'
                                    type='date'
                                    min='1900-01-01'
                                    max={today}
                                    className='form-control mb-1'
                                    {...register("client_birthday", {
                                        required: {
                                            value: true,
                                            message: "*生年月日を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const todayDate = new Date(today);
                                                const inputDate = new Date(value);
                                                if (inputDate > todayDate) {
                                                    return `*今日以降の日付は入力できません`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_birthday && errors.client_birthday?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_birthday}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_tel'>
                                    電話番号
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                </label>
                                <input
                                    id='client_tel'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='000-000-0000'
                                    {...register("client_tel", {
                                        required: {
                                            value: true,
                                            message: "*電話番号を入力してください",
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください",
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください",
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.client_tel && errors.client_tel?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_tel}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='emergency_tel'>
                                    緊急連絡先
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                </label>
                                <input
                                    id='emergency_tel'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='000-000-0000'
                                    {...register("emergency_tel", {
                                        required: {
                                            value: true,
                                            message: "*緊急連絡先を入力してください",
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください",
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください",
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.emergency_tel && errors.emergency_tel?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.emergency_tel}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_zip_code'>
                                    郵便番号
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （半角数字のみ、ハイフンなし）
                                    </small>
                                </label>
                                <br />
                                {/* 住所自動入力のエラーメッセージ */}
                                <small className='text-danger'>
                                    {!loading && error && zipCode.length === 7 && "※" + zipCodeErrorMessage}
                                </small>
                                <input
                                    id='client_zip_code'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("client_zip_code", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を入力してください",
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "*7桁で入力してください",
                                        },
                                        maxLength: {
                                            value: 7,
                                            message: "*7桁で入力してください",
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_zip_code && errors.client_zip_code?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_zip_code}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_address1'>
                                    住所1
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （都道府県、市区町村、市区町村配下）
                                    </small>
                                </label>
                                <input
                                    id='client_address1'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='郵便番号による自動入力'
                                    readOnly
                                    {...register("client_address1", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を確認してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_address1 && errors.client_address1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_address1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_address2'>
                                    住所2
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>（丁目、番地、号）</small>
                                </label>
                                <input
                                    id='client_address2'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("client_address2", {
                                        required: {
                                            value: true,
                                            message: "*住所を入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_address2 && errors.client_address2?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_address2}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_address3'>
                                    住所3
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （建物名、階数、部屋番号など）
                                    </small>
                                </label>
                                <input
                                    id='client_address3'
                                    type='text'
                                    className='form-control'
                                    {...register("client_address3")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_address3 && errors.client_address3?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.client_address3}
                                </small>
                            </div>
                            {politicalJurisdiction.length > 0 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1'>
                                        行政区分
                                        <RequiredMark />
                                    </label>
                                    <Controller
                                        name='political_jurisdiction_id'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*行政区分を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <Select {...field} value={field.value} className='form-control mb-1'>
                                                <MenuItem value='' disabled>
                                                    選択してください
                                                </MenuItem>
                                                {politicalJurisdiction.map((value, index) => (
                                                    <MenuItem key={index} value={value.political_jurisdiction_id}>
                                                        {value.political_jurisdiction_name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.political_jurisdiction_id && errors.political_jurisdiction_id?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {client.success ? "" : client.error_list.political_jurisdiction_id}
                                    </small>
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    住所区分
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='residence_type'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*住所区分を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {Object.keys(residence_types).map((key, index) => (
                                                <MenuItem key={index} value={key}>
                                                    {residence_types[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.residence_type && errors.residence_type?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.residence_type}
                                </small>
                            </div>
                            {residenceType == "6" && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='residence_type_detail'>
                                        その他の内容
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （住所区分にて「その他」選択時に入力）
                                        </small>
                                    </label>
                                    <input
                                        id='residence_type_detail'
                                        type='text'
                                        className='form-control'
                                        {...register("residence_type_detail")}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.residence_type_detail && errors.residence_type_detail?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {client.success ? "" : client.error_list.residence_type_detail}
                                    </small>
                                </div>
                            )}
                            <div className='form-group'>
                                <label className='fw-bold mb-1' htmlFor='is_child'>
                                    児童である
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （児童の場合チェックを入れる）
                                    </small>
                                </label>
                                <br />
                                <Controller
                                    id='is_child'
                                    name='is_child'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Checkbox
                                            {...field}
                                            className='mb-4'
                                            checked={field.value == true ? true : false}
                                        />
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.is_child && errors.is_child?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.is_child}
                                </small>
                            </div>
                            {isChild && (
                                <>
                                    <small className='fs-6 fw-normal text-secondary'>
                                        「児童である」にチェックを入れたときに、以下の項目を入力
                                    </small>
                                    <hr />
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='child_name'>
                                            児童氏名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='child_name'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("child_name", {
                                                required: {
                                                    value: true,
                                                    message: "*児童氏名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.child_name && errors.child_name?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.child_name}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='child_ruby'>
                                            児童氏名（カナ）
                                            <RequiredMark />
                                            <small className='fs-6 fw-normal text-secondary'>
                                                （全角カタカナ、全角スペースのみ）
                                            </small>
                                        </label>
                                        <input
                                            id='child_ruby'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("child_ruby", {
                                                required: {
                                                    value: true,
                                                    message: "*児童氏名（カナ）を入力してください。",
                                                },
                                                pattern: {
                                                    value: /^[ァ-ヶ]+[ァ-ヶー]*　?[ァ-ヶ]+[ァ-ヶー]*$/,
                                                    message: "*入力内容を確認してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.child_ruby && errors.child_ruby?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.child_ruby}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>児童性別</label>
                                        <Controller
                                            name='child_gender'
                                            defaultValue={""}
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        選択してください
                                                    </MenuItem>
                                                    {Object.keys(genders).map((key, index) => (
                                                        <MenuItem key={index} value={key}>
                                                            {genders[key]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.child_gender && errors.child_gender?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.child_gender}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='child_birthday'>
                                            児童生年月日
                                            <RequiredMark />
                                            <small className='fs-6 fw-normal text-secondary'>
                                                （yyyy/mm/ddの形式で入力）
                                            </small>
                                        </label>
                                        <input
                                            id='child_birthday'
                                            type='date'
                                            min='1900-01-01'
                                            max={today}
                                            className='form-control mb-1'
                                            {...register("child_birthday", {
                                                required: {
                                                    value: true,
                                                    message: "*児童生年月日を入力してください。",
                                                },
                                                validate: {
                                                    message: (value) => {
                                                        const todayDate = new Date(today);
                                                        const inputDate = new Date(value);
                                                        if (inputDate > todayDate) {
                                                            return `*今日以降の日付は入力できません`;
                                                        } else {
                                                            return null;
                                                        }
                                                    },
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.child_birthday && errors.child_birthday?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.child_birthday}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='relationship'>
                                            児童続柄
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='relationship'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("relationship", {
                                                required: {
                                                    value: true,
                                                    message: "*続柄を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.relationship && errors.relationship?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.relationship}
                                        </small>
                                    </div>
                                    <small className='fs-6 fw-normal text-secondary'>
                                        「児童である」場合に関する項目ここまで
                                    </small>
                                    <hr className='mb-5' />
                                </>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>身体障害者手帳</label>
                                <br />
                                <Controller
                                    name='disability_1'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.disability_1 && errors.disability_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.disability_1}
                                </small>
                                {isDisability1 && (
                                    <>
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （等級を選択してください）
                                        </small>
                                        <Controller
                                            name='disability_level_1'
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: "*等級を選択してください。" }}
                                            render={({ field, fieldState }) => (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        等級を選択してください
                                                    </MenuItem>
                                                    {Object.keys(disability_level_1_list).map((key, index) => (
                                                        <MenuItem key={index} value={key}>
                                                            {disability_level_1_list[key]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.disability_level_1 && errors.disability_level_1?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.disability_level_1}
                                        </small>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>療育手帳</label>
                                <br />
                                <Controller
                                    name='disability_2'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.disability_2 && errors.disability_2?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.disability_2}
                                </small>
                                {isDisability2 && (
                                    <>
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （等級を選択してください）
                                        </small>
                                        <Controller
                                            name='disability_level_2'
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: "*等級を選択してください。" }}
                                            render={({ field, fieldState }) => (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        等級を選択してください
                                                    </MenuItem>
                                                    {Object.keys(disability_level_2_list).map((key, index) => (
                                                        <MenuItem key={index} value={key}>
                                                            {disability_level_2_list[key]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.disability_level_2 && errors.disability_level_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.disability_level_2}
                                        </small>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>精神障害手帳</label>
                                <br />
                                <Controller
                                    name='disability_3'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.disability_3 && errors.disability_3?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.disability_3}
                                </small>
                                {isDisability3 && (
                                    <>
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （等級を選択してください）
                                        </small>
                                        <Controller
                                            name='disability_level_3'
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: "*等級を選択してください。" }}
                                            render={({ field, fieldState }) => (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        等級を選択してください
                                                    </MenuItem>
                                                    {Object.keys(disability_level_3_list).map((key, index) => (
                                                        <MenuItem key={index} value={key}>
                                                            {disability_level_3_list[key]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.disability_level_3 && errors.disability_level_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.disability_level_3}
                                        </small>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='disability_name'>
                                    障害・または疾患名
                                </label>
                                <input
                                    id='disability_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("disability_name")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.disability_name && errors.disability_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.disability_name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>総合支援法</label>
                                <Controller
                                    name='support_law'
                                    defaultValue={0}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {Object.keys(support_law_list).map((key, index) => (
                                                <MenuItem key={index} value={key}>
                                                    {support_law_list[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.support_law && errors.support_law?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.support_law}
                                </small>
                                {canShowSupportLevel && (
                                    <>
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （支援区分を選択してください）
                                        </small>
                                        <Controller
                                            name='support_level'
                                            control={control}
                                            rules={{ required: "*支援区分を選択してください。" }}
                                            render={({ field, fieldState }) => (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled selected>
                                                        選択してください
                                                    </MenuItem>
                                                    {Object.keys(SUPPORT_LEVEL_LIST).map(
                                                        (key, index) =>
                                                            index > 0 && (
                                                                <MenuItem key={index} value={key}>
                                                                    {SUPPORT_LEVEL_LIST[key]}
                                                                </MenuItem>
                                                            )
                                                    )}
                                                </Select>
                                            )}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.support_level && errors.support_level?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.support_level}
                                        </small>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>障害年金</label>
                                <Controller
                                    name='pension_type'
                                    defaultValue={0}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {Object.keys(pension_types).map((key, index) => (
                                                <MenuItem key={index} value={key}>
                                                    {pension_types[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.pension_type && errors.pension_type?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.pension_type}
                                </small>
                                {pensionType == "1" && (
                                    <>
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （障害年金の等級を選択してください）
                                        </small>
                                        <Controller
                                            name='pension_level'
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: "*障害年金の級を選択してください。" }}
                                            render={({ field, fieldState }) => (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        障害年金の級を選択してください
                                                    </MenuItem>
                                                    {Object.keys(pension_level_list[1]).map((key, index) => (
                                                        <MenuItem key={index} value={key}>
                                                            {pension_level_list[1][key]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.pension_level && errors.pension_level?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.pension_level}
                                        </small>
                                    </>
                                )}
                                {pensionType == "2" && (
                                    <>
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （障害年金の等級を選択してください）
                                        </small>
                                        <Controller
                                            name='pension_level'
                                            defaultValue={""}
                                            control={control}
                                            rules={{ required: "*障害年金の級を選択してください。" }}
                                            render={({ field, fieldState }) => (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        障害年金の級を選択してください
                                                    </MenuItem>
                                                    {Object.keys(pension_level_list[2]).map((key, index) => (
                                                        <MenuItem key={index} value={key}>
                                                            {pension_level_list[2][key]}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.pension_level && errors.pension_level?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {client.success ? "" : client.error_list.pension_level}
                                        </small>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='start_date'>
                                    障害年金支給開始日
                                    <small className='fs-6 fw-normal text-secondary'>（yyyy/mm/ddの形式で入力）</small>
                                </label>
                                <input
                                    id='start_date'
                                    type='date'
                                    min='1900-01-01'
                                    max='2200-12-31'
                                    className='form-control mb-1'
                                    {...register("start_date")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.start_date && errors.start_date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.start_date}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_1'>
                                    障害年金受給者番号
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （半角数字のみ、ハイフンなし）
                                    </small>
                                </label>
                                <input
                                    id='recipient_no_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("recipient_no_1", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.recipient_no_1 && errors.recipient_no_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.recipient_no_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_2'>
                                    障害福祉サービス受給者証番号
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （半角数字のみ、ハイフンなし）
                                    </small>
                                </label>
                                <input
                                    id='recipient_no_2'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("recipient_no_2", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.recipient_no_2 && errors.recipient_no_2?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.recipient_no_2}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_3'>
                                    地域相談支援受給者番号
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （半角数字のみ、ハイフンなし）
                                    </small>
                                </label>
                                <input
                                    id='recipient_no_3'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("recipient_no_3", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.recipient_no_3 && errors.recipient_no_3?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.recipient_no_3}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_4'>
                                    通所受給者番号
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （半角数字のみ、ハイフンなし）
                                    </small>
                                </label>
                                <input
                                    id='recipient_no_4'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("recipient_no_4", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.recipient_no_4 && errors.recipient_no_4?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.recipient_no_4}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>その他収入</label>
                                <Controller
                                    name='other_income'
                                    defaultValue={0}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {Object.keys(other_income_list).map((key, index) => (
                                                <MenuItem key={index} value={key}>
                                                    {other_income_list[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_income && errors.other_income?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.other_income}
                                </small>
                            </div>
                            {otherIncome == 9 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='other_income_detail'>
                                        その他手当の内容
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （その他収入にて「その他手当」選択時に入力）
                                        </small>
                                    </label>
                                    <input
                                        id='other_income_detail'
                                        type='text'
                                        className='form-control'
                                        {...register("other_income_detail")}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.other_income_detail && errors.other_income_detail?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {client.success ? "" : client.error_list.other_income_detail}
                                    </small>
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>就労</label>
                                <Controller
                                    name='work'
                                    defaultValue={0}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {Object.keys(work_list).map((key, index) => (
                                                <MenuItem key={index} value={key}>
                                                    {work_list[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.work && errors.work?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{client.success ? "" : client.error_list.work}</small>
                            </div>
                            {clientWork > 0 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='workplace'>
                                        勤務先
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （就労している場合に入力）
                                        </small>
                                    </label>
                                    <input
                                        id='workplace'
                                        type='text'
                                        className='form-control'
                                        {...register("workplace")}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.workplace && errors.workplace?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {client.success ? "" : client.error_list.workplace}
                                    </small>
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>雇用保険</label>
                                <br />
                                <Controller
                                    name='employment_insurance'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.employment_insurance && errors.employment_insurance?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.employment_insurance}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>厚生年金</label>
                                <br />
                                <Controller
                                    name='welfare_pension'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.welfare_pension && errors.welfare_pension?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.welfare_pension}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>健康保険</label>
                                <br />
                                <Controller
                                    name='health_insurance'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.health_insurance && errors.health_insurance?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.health_insurance}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>医療</label>
                                <Controller
                                    name='medical'
                                    defaultValue={0}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {Object.keys(medical_list).map((key, index) => (
                                                <MenuItem key={index} value={key}>
                                                    {medical_list[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.medical && errors.medical?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{client.success ? "" : client.error_list.medical}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>医療その他</label>
                                <br />
                                <Controller
                                    name='medical_other'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.medical_other && errors.medical_other?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.medical_other}
                                </small>
                            </div>
                            {isMedicalOther && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='medical_other_detail'>
                                        医療その他の内容
                                        <small className='fs-6 fw-normal text-secondary'>
                                            （医療その他にて「あり」選択時に入力）
                                        </small>
                                    </label>
                                    <input
                                        id='medical_other_detail'
                                        type='text'
                                        className='form-control'
                                        {...register("medical_other_detail")}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.medical_other_detail && errors.medical_other_detail?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {client.success ? "" : client.error_list.medical_other_detail}
                                    </small>
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='start_month'>
                                    モニタリング期間（開始年月）
                                    <RequiredMark />
                                    <br />
                                    <small className='fs-6 fw-normal text-secondary'>
                                        （yyyy/mm/ddの形式で入力、日付未定の場合は1日を入力）
                                    </small>
                                </label>
                                <input
                                    id='start_month'
                                    type='date'
                                    min='1900-01-01'
                                    max='2200-12-31'
                                    className='form-control mb-1'
                                    {...register("start_month", {
                                        required: {
                                            value: true,
                                            message: "*モニタリング期間（開始年月）を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.start_month && errors.start_month?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.start_month}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>モニタリング基準月</label>
                                <Controller
                                    name='reference_month'
                                    defaultValue={""}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value=''>選択してください</MenuItem>
                                            {reference_month_list.map((item, index) => (
                                                <MenuItem key={index} value={index}>
                                                    {item}月
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.reference_month && errors.reference_month?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.reference_month}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>モニタリング頻度</label>
                                <Controller
                                    name='frequency'
                                    defaultValue={""}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value=''>選択してください</MenuItem>
                                            {frequency_list.map((item, index) => (
                                                <MenuItem key={index} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.frequency && errors.frequency?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {client.success ? "" : client.error_list.frequency}
                                </small>
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
