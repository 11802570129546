import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import menuConfig from "../layout/menuConfig";
import { UserContext } from "../providers/UserProvider";

//スタイルの定義：開くメニューの表示位置をずらす
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontSize: "1.5rem",
            margin: "2rem 20%",
            padding: "0 5%",
            borderBottom: "2px solid #999",
        },
        grid: {
            width: "500px",
            margin: "0 auto",
        },
        MuiPopoverPaper: {
            top: "30px !important",
            left: "10px !important",
        },
    })
);

export default function FunctionList(props) {
    const { userInfo } = useContext(UserContext);
    const isLogin = userInfo.isLogin;

    //定義したスタイルを利用するための設定
    const classes = useStyles();
    //画面遷移するため
    const navigate = useNavigate();
    //プロパティ受け取り
    const category = props.category;

    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const open = Boolean(anchorEl);

    // const handleClick = (e) => {
    //     e.stopPropagation();
    //     setAnchorEl(e.currentTarget);
    // };
    // const handleClose = (e) => {
    //     e.stopPropagation();
    //     setAnchorEl(null);
    // };

    const handleSelect = (e, path) => {
        // console.log(path);
        e.stopPropagation();
        // setAnchorEl(null);
        navigate(path);
    };

    const menuItems = menuConfig.filter((item) => {
        if (item.category === category && item.auth === isLogin) {
            return item;
        }
    });

    if (menuItems.length > 0) {
        return (
            <>
                <h2 className={classes.title}>機能一覧</h2>
                <Grid container spacing={3} className={classes.grid}>
                    {menuItems.map((item, index) => {
                        return (
                            <Grid key={index} item xs='auto'>
                                <Button variant='outlined' onClick={(e) => handleSelect(e, item.path)}>
                                    {item.value}
                                </Button>
                            </Grid>
                        );
                    })}
                </Grid>
            </>
        );
    } else {
        return null;
    }
}
