import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import DataShowForRegister from "../../components/DataShowForRegister";
import NewOutputExcelButton from "../../components/NewOutputExcelButton";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        figure: {
            marginBottom: "0!important",
            fontWeight: "bold"
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center"
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px"
        }
    })
);

//---------------------------issue{No.440} start-----------------------------
export default function NursingOfficeAlignmentShow(props) {
    const { idFromRegister = undefined, forRegister = false, setRefCurrent = undefined } = props;
    //---------------------------issue{No.440} end-------------------------------

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、居宅介護支援事業所等連携情報のid
    const { client, id } = useParams();

    // 表示するnursingOfficeAlignment の状態を管理する
    const [nursingOfficeAlignmentData, setNursingOfficeAlignmentData] = useState([]);
    const [nursingOfficeAlignmentShowData, setNursingOfficeAlignmentShowData] = useState([]);

    //---------------------------issue{No.325} start-----------------------------
    const [downloadState, setDownloadState] = useState("");
    //---------------------------issue{No.325} end-------------------------------
    const [downloadFileName, setDownloadFileName] = useState("");

    // "yyyy-mm-dd"を"yyyy年mm月dd日"に変更
    const formatDate = (date) => {
        const dateArr = date.split("-");
        const result = `${dateArr[0]}年${dateArr[1]}月${dateArr[2]}日`;
        return result;
    };

    // "hh:ii:ss"を"hh:ii"に変更
    const formatTime = (time) => {
        const timeArr = time.split(":");
        const result = `${timeArr[0]}:${timeArr[1]}`;
        return result;
    };

    //情報を取得しステート nursingOfficeAlignment にセットする
    const getNursingOfficeAlignmentData = async (id) => {
        const url = `nursing_office_alignments/show/${id}`;
        const res = await api.get(url);

        if (res) {
            //---------------------------issue{No.325} start-----------------------------
            setNursingOfficeAlignmentData(res.data.result);
            //---------------------------issue{No.325} end-------------------------------
            setNursingOfficeAlignmentRow(res.data.result);
            setDownloadFileName(`${res.data.result.date}_${res.data.result.client_name}_居宅介護支援事業所等連携`);
        }
    };

    // ステートに値をセット
    const setNursingOfficeAlignmentRow = (nursingOfficeAlignment) => {
        const date = formatDate(nursingOfficeAlignment.date);
        const date1 = formatDate(nursingOfficeAlignment.date_1);
        const date2 = nursingOfficeAlignment.date_2 == null ? null : formatDate(nursingOfficeAlignment.date_2);
        const date3 = nursingOfficeAlignment.date_3 == null ? null : formatDate(nursingOfficeAlignment.date_3);
        const date4 = nursingOfficeAlignment.date_4 == null ? null : formatDate(nursingOfficeAlignment.date_4);
        const time1 = formatTime(nursingOfficeAlignment.time_1);
        const time2 = nursingOfficeAlignment.time_2 == null ? null : formatTime(nursingOfficeAlignment.time_2);
        const time3 = nursingOfficeAlignment.time_3 == null ? null : formatTime(nursingOfficeAlignment.time_3);
        const time4 = nursingOfficeAlignment.time_4 == null ? null : formatTime(nursingOfficeAlignment.time_4);

        //---------------------------issue{No.440} start-----------------------------
        const data = [
            { label: "作成日", value: date },
            { label: "利用者氏名", value: nursingOfficeAlignment.client_name },
            { label: "相談支援専門員氏名", value: nursingOfficeAlignment.user_name },
            { label: "【1】機関名（担当者）", value: nursingOfficeAlignment.pic_1 },
            { label: "【1】年月日", value: date1 },
            { label: "【1】時間", value: time1 },
            { label: "【1】場所", value: nursingOfficeAlignment.location_1 },
            { label: "【1】情報交換等の内容", value: nursingOfficeAlignment.detail_1 },
            { label: "【2】機関名（担当者）", value: nursingOfficeAlignment.pic_2 },
            { label: "【2】年月日", value: date2 },
            { label: "【2】時間", value: time2 },
            { label: "【2】場所", value: nursingOfficeAlignment.location_2 },
            { label: "【2】情報交換等の内容", value: nursingOfficeAlignment.detail_2 },
            { label: "【3】機関名（担当者）", value: nursingOfficeAlignment.pic_3 },
            { label: "【3】年月日", value: date3 },
            { label: "【3】時間", value: time3 },
            { label: "【3】場所", value: nursingOfficeAlignment.location_3 },
            { label: "【3】情報交換等の内容", value: nursingOfficeAlignment.detail_3 },
            { label: "【4】機関名（担当者）", value: nursingOfficeAlignment.pic_4 },
            { label: "【4】年月日", value: date4 },
            { label: "【4】時間", value: time4 },
            { label: "【4】場所", value: nursingOfficeAlignment.location_4 },
            { label: "【4】情報交換等の内容", value: nursingOfficeAlignment.detail_4 }
        ];

        setNursingOfficeAlignmentShowData(data);

        if (forRegister) {
            setRefCurrent(data);
        }
        //---------------------------issue{No.440} end-------------------------------
    };

    // 閉じるボタンをクリックしたら、居宅介護支援事業所等連携情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/nursing_office_alignment/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        //---------------------------issue{No.440} start-----------------------------
        getNursingOfficeAlignmentData(idFromRegister ? idFromRegister : id);
        //---------------------------issue{No.440} end-------------------------------
    }, []);

    return (
        <>
            {forRegister ? (
                <DataShowForRegister data={nursingOfficeAlignmentShowData}></DataShowForRegister>
            ) : (
                <div className='row justify-content-center'>
                    <div className='col-md-10 col-lg-10 mx-auto'>
                        <div className='card'>
                            <div className='card-header d-flex'>
                                <h1 className={classes.title}>居宅介護支援事業所等連携情報</h1>
                                {/* -------------------- issue539 start -------------------- */}
                                <div className='ms-auto'>
                                    <button
                                        className='btn btn-outline-secondary fs-3 lh-1 mb-5'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                            </div>
                            <div className='card-body'>
                                <NewOutputExcelButton
                                    fileName={downloadFileName}
                                    api_url={`nursing_office_alignments/${id}/export`}
                                    buttonTextIsFileName={false}
                                />
                                <DataShow data={nursingOfficeAlignmentShowData}></DataShow>
                            </div>
                            <div className='card-footer'>
                                <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                    閉じる
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
