import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import DataShow from "./DataShow";

function DataShowDialog(props) {
    const { onClose = (f) => f, onActions = [], title = "", data = [], open = false } = props;

    if (!open) {
        return null;
    }

    return (
        <Dialog onClose={onClose} open={open}>
            <DialogTitle className='card-header'>{title}</DialogTitle>
            <DialogContent className='card-body'>
                <DataShow data={data}></DataShow>
            </DialogContent>
            <DialogActions className='card-footer'>
                {onActions.map((item, index) => (
                    <Button key={index} onClick={item.action} variant='contained'>
                        {item.label}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
}

export default DataShowDialog;
