import React from "react";
import ScheduleRegisterTableInputSelect from "./ScheduleRegisterTableInputSelect";

// 予定表部分の表示用データ作成
export default function ScheduleRegisterTableInputRow(props) {
    const { showData, targetYear, targetName, user, postData, dispatch } = props;

    return (
        <>
            {/* 12か月分の配列を展開 */}
            {showData[targetName].map((data, dataIndex) => (
                <ScheduleRegisterTableInputSelect
                    key={`dataIndex_${dataIndex}`}
                    data={data}
                    columnNo={dataIndex}
                    clientId={showData.client.client_id}
                    targetYear={targetYear}
                    user={user}
                    postData={postData}
                    dispatch={dispatch}
                />
            ))}
        </>
    );
}
