import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDeletedDataList from "../../components/ClientSelectToDeletedDataList";

// 見出しの配列定義
const headers = ["作成日"];

export default function Assessment_1Deleted() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDeletedDataList pageName='アセスメント' targetURL='assessment_1' headerDataList={headers} clientID={client} />;
}