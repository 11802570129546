import React, { useState, useEffect, useReducer, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import {
    Button,
    Card,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
// 追記ここまで
import BackButton from "../../components/BackButton";
import { UserContext } from "../../providers/UserProvider";
import ScheduleReferenceMonthEditRow from "../../components/schedule/ScheduleReferenceMonthEditRow";

import swal from "sweetalert";

const headers = ["氏名", "開始月", "基準月", "頻度"];

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
            padding: "1rem",
            boxSizing: "border-box"
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

// ステートの初期状態
const initState = { result: [], success: false };

// reducer初期値
const initPostData = [];

// ディープコピーを行う関数
const deepCopy = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return obj; // オブジェクトでない場合やnullの場合はそのまま返す
    }

    if (Array.isArray(obj)) {
        // 配列の場合は要素をディープコピーして新しい配列を作成
        return obj.map((item) => deepCopy(item));
    }

    // オブジェクトの場合は各プロパティをディープコピーして新しいオブジェクトを作成
    const copiedObject = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copiedObject[key] = deepCopy(obj[key]);
        }
    }

    return copiedObject;
};

//reducer関係
const addData = (state, data) => {
    const result = [...state, data];
    return result;
};

const updateData = (state, data) => {
    const result = state.map((val) => {
        if (val.id === data.id) {
            return data;
        } else {
            return val;
        }
    });
    return result;
};

const removeData = (state, data) => {
    const result = state.filter((value) => {
        return value.id !== data.id;
    });
    return result;
};

const reducerFunc = (postData, action) => {
    //reducer関数にincrement、increment、reset処理を書く
    //どの処理を渡すかはactionを渡すことによって判断する
    switch (action.type) {
        case "addPostData":
            return addData(postData, action.data);
        case "updatePostData":
            return updateData(postData, action.data);
        case "removePostData":
            return removeData(postData, action.data);
        case "reset":
            return initPostData;
        default:
            return false;
    }
};

export default function ScheduleReferenceMonthEdit() {
    // 表示対象のユーザ情報のid
    const { id } = useParams();

    // ログイン中のユーザーIDを定義
    const { userInfo } = useContext(UserContext);
    const userName = userInfo.user.user_name;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //Client の状態を管理する
    const [clients, setClients] = useState(deepCopy(initState));

    // コントローラー用の値管理
    const [postData, dispatch] = useReducer(reducerFunc, initPostData);

    //情報を取得しステート mySchedule にセットする
    const getClientData = async (id) => {
        try {
            const url = `clients/reference_month/${id}`;
            const res = await api.get(url);
            const client = res.data.result;
            setClients({ result: client, success: true });
        } catch (error) {
            console.error("Error fetching schedule data:", error);
        }
    };

    // 登録後、データを初期化
    const resetData = () => {
        getClientData(id);
        dispatch({ type: "reset", data: initPostData });
    };

    // 更新ボタン押下時
    const handleClickEdit = async (e) => {
        e.preventDefault();

        let confirm = false;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "登録しますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        //更新
        const url = "clients/reference_month";
        const res = await api.post(url, postData);
        if (res.data.success) {
            swal(res.data.message, "", "success").then(() => {
                resetData();
            });
        } else {
            await swal({
                title: "エラー",
                text: "問題が生じたためホーム画面に戻ります。",
                icon: "warning"
            }).then(() => {
                navigate("/home");
            });
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/schedule");
    };
    //---------------------------issue{No.539} end-------------------------------

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        getClientData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>モニタリング基準月等一括変更</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        <div className='mt-3 mb-5'>
                            <h2>{userName}</h2>
                        </div>
                        {clients.success && clients.result.length > 0 ? (
                            <>
                                <Paper>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    {headers.map((headerItem, headerIndex) => (
                                                        <TableCell key={headerIndex}>{headerItem}</TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {clients.result.map((client) => (
                                                    <ScheduleReferenceMonthEditRow
                                                        key={client.id}
                                                        client={client}
                                                        postData={postData}
                                                        dispatch={dispatch}
                                                    />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                {postData.length > 0 && (
                                    <div className='text-center py-3'>
                                        <Button variant='contained' color='primary' onClick={(e) => handleClickEdit(e)}>
                                            更新
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <p>担当利用者が存在しません</p>
                        )}
                    </Card>
                    {/* -------------------- issue508 start -------------------- */}
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton />
                        </div>
                    </div>
                    {/* -------------------- issue508 start -------------------- */}
                </div>
            </div>
        </div>
    );
}
