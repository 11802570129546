import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import { Button, Select, MenuItem } from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";
import formatSupportLevelListForEdit from "../../common/formatSupportLevelListForEdit";

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        priority: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004",
        },
        priorityNum: { lineHeight: "2rem" },
    })
);

// 初期状態
const initPlan_1 = { success: true };

//---------------------------issue{No.513} start-----------------------------
//---------------------------issue{No.513} end-------------------------------

// 日付をyyyymmddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + m + d;
}
// 日付を取得
const numToday = Number(dayFormat(new Date()));

function Plan_1Edit() {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 利用者idと相談支援支給申請のidを保持している
    const { client, id } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付の入力制限を設ける為に日付の上限値として用いる定数を読み込みます
    const { MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // 入力値を管理する
    const [plan_1, setPlan_1] = useState(initPlan_1);

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // watch内で使用する生年月日
    const clientBirthdayRef = useRef(""); // issue516

    //---------------------------issue{No.513} start-----------------------------
    // 最新のクライアント情報に更新するボタンの表示の判定に使う
    const [clientChangeable, setClientChangeable] = useState(false);

    // レコードと最新のクライアント情報を比較し見つかった変更箇所の値を管理する
    const [implementClientData, setImplementClientData] = useState({});

    // リクエスト送信時に渡すべき利用者情報の連想配列を管理する
    const [storeClient, setStoreClient] = useState(clientData);

    // 更新内容を引用したかどうかを管理する
    const [citingClient, setCitingClient] = useState("");
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    // 今日以前の直近の作成済みレコードの日付を数値で保存する
    const [mostRecentDate, setMostRecentDate] = useState(0);

    //---------------------------issue{No.453} start-----------------------------
    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");
    //---------------------------issue{No.453} end-------------------------------

    // -------------------- issue516 start --------------------
    const [startDateMessage, setStartDateMessage] = useState("");
    // -------------------- issue516 end --------------------

    // 優先順位ごとの入力欄の表示個数を管理する
    const [priorityCount, setPriorityCount] = useState(1);

    // 情報を取得しステート plan_1にセットする
    const getPlan_1Data = async (id) => {
        const url = `plans_1/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 支援目標の入力欄の表示個数を格納する
            let newPriorityCount = 1;
            // 支援目標の登録数に応じて、必要な入力欄の個数を調べる
            for (let ii = 2; ii <= 5; ii++) {
                if (res.data.result[`target_${ii}`]) {
                    newPriorityCount = ii;
                }
            }
            // 結果をステートにセットし、入力欄を表示させる
            setPriorityCount(newPriorityCount);

            setPlan_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
        }
    };

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `plans_1/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                client_birthday: clientResult.is_child ? clientResult.child_birthday : clientResult.client_birthday, // issue516
                support_law: clientResult.support_law,
                support_level: clientResult.support_level,
                office_name: clientResult.office_name,
                //---------------------------issue{No.513} start-----------------------------
                // user_name: clientResult.user_name,
                //---------------------------issue{No.513} end-------------------------------
                recipient_no_2: clientResult.recipient_no_2,
                recipient_no_3: clientResult.recipient_no_3,
                recipient_no_4: clientResult.recipient_no_4,
                start_month: clientResult.start_month,
            });

            //---------------------------issue{No.516} start-------------------------------
            clientBirthdayRef.current =
                clientResult.is_child == 1 ? clientResult.child_birthday : clientResult.client_birthday;
            //---------------------------issue{No.516} end-------------------------------
        }
    };

    //---------------------------issue{No.471} start-----------------------------
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name,
                };
            });
            setUsers(usersName);
        }
    };
    //---------------------------issue{No.471} end-------------------------------

    // クライアントの各機能ごとに、すでにあるレコードのdateカラムのデータを取得する
    const getDateList = async (clientId) => {
        const url = `plans_1_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 今日以前の直近の日付のみ取得する
            const result = Math.max.apply(
                null,
                res.data.result
                    .map((item) => {
                        const date = item.date;
                        const formatDate = Number(date.replace(/[-]/g, ""));
                        if (formatDate <= numToday) {
                            return formatDate;
                        }
                    })
                    .filter((e) => e)
            );
            // ステートに保存する
            setMostRecentDate(result);

            //---------------------------issue{No.453} start-----------------------------
            // 取得したデータの作成日を被り対象から除外して、dateListを作成
            const resultList = res.data.result
                .filter((item) => item.date !== plan_1.date)
                .map((item) => {
                    return item.date;
                });
            dateList.current = resultList;
            //---------------------------issue{No.453} end-------------------------------
        }
    };

    // //利用者名クリック時、利用者情報表示画面へ
    // const handleOnClickShow = (e) => {
    //     // イベントの伝搬を中止
    //     e.stopPropagation();

    //     if (client) {
    //         // URL(/client/show/${client.id})をたたく（RouterConfig.js）
    //         navigate(`/client_select/show/${client}`);
    //     }
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 保存ボタン押下時
    const plan_1Submit = async (data) => {
        //---------------------------issue{No.453} start-----------------------------
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: "すでに登録されている日付です",
                text: "登録内容を見直してください",
                timer: 2000,
            });
            return;
        }
        //---------------------------issue{No.453} end-------------------------------

        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;
        //---------------------------issue{No.513} end-------------------------------

        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // Plan_1Controllerに渡す値をセット
        const plan_1Data = {
            company_id: plan_1.company_id,
            office_id: plan_1.office_id,
            user_id: plan_1.user_id,
            client_id: plan_1.client_id,
            date: data.date,
            client_name: storeClient.client_name,
            support_law: storeClient.support_law,
            support_level: storeClient.support_level,
            office_name: storeClient.office_name,
            user_name: storeClient.user_name,
            maximum_amount: data.maximum_amount,
            recipient_no_2: storeClient.recipient_no_2,
            recipient_no_3: storeClient.recipient_no_3,
            recipient_no_4: storeClient.recipient_no_4,
            start_month: storeClient.start_month,
            wish: data.wish,
            plan_1: data.plan_1,
            plan_2: data.plan_2,
            plan_3: data.plan_3,
            task_1: data.task_1,
            task_2: data.task_2 ? data.task_2 : null,
            task_3: data.task_3 ? data.task_3 : null,
            task_4: data.task_4 ? data.task_4 : null,
            task_5: data.task_5 ? data.task_5 : null,
            target_1: data.target_1,
            target_2: data.target_2 ? data.target_2 : null,
            target_3: data.target_3 ? data.target_3 : null,
            target_4: data.target_4 ? data.target_4 : null,
            target_5: data.target_5 ? data.target_5 : null,
            achievement_period_1: data.achievement_period_1,
            achievement_period_2: data.achievement_period_2 ? data.achievement_period_2 : null,
            achievement_period_3: data.achievement_period_3 ? data.achievement_period_3 : null,
            achievement_period_4: data.achievement_period_4 ? data.achievement_period_4 : null,
            achievement_period_5: data.achievement_period_5 ? data.achievement_period_5 : null,
            type_1: data.type_1,
            type_2: data.type_2 ? data.type_2 : null,
            type_3: data.type_3 ? data.type_3 : null,
            type_4: data.type_4 ? data.type_4 : null,
            type_5: data.type_5 ? data.type_5 : null,
            provider_1: data.provider_1,
            provider_2: data.provider_2 ? data.provider_2 : null,
            provider_3: data.provider_3 ? data.provider_3 : null,
            provider_4: data.provider_4 ? data.provider_4 : null,
            provider_5: data.provider_5 ? data.provider_5 : null,
            role_1: data.role_1,
            role_2: data.role_2 ? data.role_2 : null,
            role_3: data.role_3 ? data.role_3 : null,
            role_4: data.role_4 ? data.role_4 : null,
            role_5: data.role_5 ? data.role_5 : null,
            evaluation_period_1: data.evaluation_period_1,
            evaluation_period_2: data.evaluation_period_2 ? data.evaluation_period_2 : null,
            evaluation_period_3: data.evaluation_period_3 ? data.evaluation_period_3 : null,
            evaluation_period_4: data.evaluation_period_4 ? data.evaluation_period_4 : null,
            evaluation_period_5: data.evaluation_period_5 ? data.evaluation_period_5 : null,
            note_1: data.note_1 ?? null,
            note_2: data.note_2 ? data.note_2 : null,
            note_3: data.note_3 ? data.note_3 : null,
            note_4: data.note_4 ? data.note_4 : null,
            note_5: data.note_5 ? data.note_5 : null,
            start_date: data.start_date,
            daily_routine: data.daily_routine,
            other_service: data.other_service,
            total: data.total,
        };

        // 保存
        const url = `plans_1/${id}`;
        const res = await api.put(url, plan_1Data);
        if (res.status === 422) {
            setPlan_1({
                ...plan_1,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/plan_1/${client}`);
            });
        }
    };

    // 今回の編集対象が条件に当てはまった時のみ、最新のクライアント情報を適用するボタンを表示し、変更内容をステートに保存する
    useEffect(() => {
        if (Object.keys(plan_1).length > 1 && Object.keys(clientData).length > 0 && mostRecentDate) {
            const numDate = Number(plan_1.date.replace(/[-]/g, ""));
            if (numDate === mostRecentDate || numDate > numToday) {
                const checkClientChange = () => {
                    let changeData = {};
                    //---------------------------issue{No.513} start-----------------------------
                    for (let key in clientData) {
                        if (clientData[key] && plan_1[key] && clientData[key] != plan_1[key]) {
                            changeData[key] = clientData[key];
                        }
                    }
                    setImplementClientData(changeData);
                    //---------------------------issue{No.513} end-------------------------------
                };
                checkClientChange();
            }
        }
    }, [plan_1, clientData, mostRecentDate]);

    //---------------------------issue{No.513} start-----------------------------
    // 更新内容を引用する準備ができたらステートを更新する
    useEffect(() => {
        if (Object.keys(implementClientData).length > 0) {
            setClientChangeable(true);
            setCitingClient("ready");
        }
    }, [implementClientData]);
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 利用者情報の更新内容を引用し直す
    const implementClientChanges = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (Object.keys(implementClientData).length > 0) {
            if (citingClient === "ready") {
                // 確認のアラートを表示する
                await swal({
                    icon: "info",
                    title: "確認",
                    text: "登録済みの利用者情報を適用しますか？",
                    buttons: true,
                }).then(async (result) => {
                    if (result) {
                        if (implementClientData.user_id) {
                            // 確認のアラートを表示する
                            let confirm = false;

                            await swal({
                                icon: "warning",
                                title: "確認",
                                text: "担当職員が更新されています。適用しますか？",
                                buttons: true,
                            }).then((result) => {
                                if (result) {
                                    confirm = true;
                                }
                            });

                            // キャンセル処理
                            if (!confirm) {
                                return;
                            }
                        }

                        // 以下、引用のための処理
                        runCitingClient();
                    }
                });
            }
        }
    };

    // 引用した内容を編集前に戻す
    const putBackClientChanges = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        for (let key in implementClientData) {
            // 更新対象のフォーム内の項目を変更
            setValue(key, plan_1[key], { shouldValidate: false });
        }
        setStoreClient(plan_1);
        setCitingClient("ready");
    };
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    const runCitingClient = () => {
        if (Object.keys(clientData).length > 0 && citingClient === "ready") {
            if (clientData.office_name !== null) {
                setValue("office_name", clientData.office_name, { shouldValidate: false });
            }
            if (clientData.client_name !== null) {
                setValue("client_name", clientData.client_name, { shouldValidate: false });
            }
            if (clientData.recipient_no_2 !== null) {
                setValue("recipient_no_2", clientData.recipient_no_2, { shouldValidate: false });
            }
            if (clientData.recipient_no_3 !== null) {
                setValue("recipient_no_3", clientData.recipient_no_3, { shouldValidate: false });
            }
            if (clientData.recipient_no_4 !== null) {
                setValue("recipient_no_4", clientData.recipient_no_4, { shouldValidate: false });
            }
            if (clientData.start_month !== null) {
                setValue("start_month", clientData.start_month, { shouldValidate: false });
            }
            setStoreClient(clientData);
            setCitingClient("done");
        }
    };
    //---------------------------issue{No.513} end-------------------------------

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        getPlan_1Data(id);
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        getDateList(client);
    }, []);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        //---------------------------issue{No.471} start-----------------------------
        control,
        //---------------------------issue{No.471} end-------------------------------
        //---------------------------issue{No.453} start-----------------------------
        watch,
        //---------------------------issue{No.453} end-------------------------------
    } = useForm();

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(plan_1).length > 1 && !getValues("date")) {
            setValue("date", plan_1.date, { shouldValidate: false });
            //---------------------------issue{No.471} start-----------------------------
            setValue("user_name", plan_1.user_name, { shouldValidate: false });
            //---------------------------issue{No.471} end-------------------------------
            setValue("maximum_amount", plan_1.maximum_amount, { shouldValidate: false });
            setValue("wish", plan_1.wish, { shouldValidate: false });
            setValue("plan_1", plan_1.plan_1, { shouldValidate: false });
            setValue("plan_2", plan_1.plan_2, { shouldValidate: false });
            setValue("plan_3", plan_1.plan_3, { shouldValidate: false });
            for (let ii = 1; ii <= priorityCount; ii++) {
                setValue(`task_${ii}`, plan_1[`task_${ii}`], { shouldValidate: false });
                setValue(`target_${ii}`, plan_1[`target_${ii}`], {
                    shouldValidate: false,
                });
                setValue(`achievement_period_${ii}`, plan_1[`achievement_period_${ii}`], {
                    shouldValidate: false,
                });
                setValue(`type_${ii}`, plan_1[`type_${ii}`], { shouldValidate: false });
                setValue(`provider_${ii}`, plan_1[`provider_${ii}`], { shouldValidate: false });
                setValue(`role_${ii}`, plan_1[`role_${ii}`], { shouldValidate: false });
                setValue(`evaluation_period_${ii}`, plan_1[`evaluation_period_${ii}`], {
                    shouldValidate: false,
                });
                setValue(`note_${ii}`, plan_1[`note_${ii}`], { shouldValidate: false });
            }
            setValue("start_date", plan_1.start_date, { shouldValidate: false });
            setValue("daily_routine", plan_1.daily_routine, { shouldValidate: false });
            setValue("other_service", plan_1.other_service, { shouldValidate: false });
            setValue("total", plan_1.total, { shouldValidate: false });

            //---------------------------issue{No.513} start-----------------------------
            setValue("office_name", plan_1.office_name, { shouldValidate: false });
            setValue("client_name", plan_1.client_name, { shouldValidate: false });
            setValue("recipient_no_2", plan_1.recipient_no_2, { shouldValidate: false });
            setValue("recipient_no_3", plan_1.recipient_no_3, { shouldValidate: false });
            setValue("recipient_no_4", plan_1.recipient_no_4, { shouldValidate: false });
            setValue("start_month", plan_1.start_month, { shouldValidate: false });
            //---------------------------issue{No.513} end-------------------------------
            setStoreClient(plan_1);
        }
    }, [plan_1]);

    //---------------------------issue{No.453} start-----------------------------
    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                switch (name) {
                    case "date":
                        setDateMessage(
                            dateRegisteringErrorMessage(value.date, clientBirthdayRef.current, dateList.current)
                        );
                        break;
                    case "start_date":
                        setStartDateMessage(dateRegisteringErrorMessage(value.start_date, clientBirthdayRef.current));
                        break;
                    default:
                        break;
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    //---------------------------issue{No.453} end-------------------------------

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        if (priorityCount < 5) {
            setPriorityCount((priorityCount) => priorityCount + 1);
        }
    };

    // 支援目標の優先順位を入れ替える関数
    const handleOnClickPriorityChange = (e, num) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const higherPriority = {
            task: `task_${num}`,
            target: `target_${num}`,
            achievement_period: `achievement_period_${num}`,
            type: `type_${num}`,
            provider: `provider_${num}`,
            role: `role_${num}`,
            evaluation_period: `evaluation_period_${num}`,
            note: `note_${num}`,
        };

        // 優先順位が一つ上がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const lowerPriority = {
            task: `task_${num + 1}`,
            target: `target_${num + 1}`,
            achievement_period: `achievement_period_${num + 1}`,
            type: `type_${num + 1}`,
            provider: `provider_${num + 1}`,
            role: `role_${num + 1}`,
            evaluation_period: `evaluation_period_${num + 1}`,
            note: `note_${num + 1}`,
        };

        // 入れ替えの際に、値を一時的に控えておく定数オブジェクト
        const reserveValues = {
            task: null,
            target: null,
            achievement_period: null,
            type: null,
            provider: null,
            role: null,
            evaluation_period: null,
            note: null,
        };

        // 優先順位が下がる側の入力欄の値を控えておく
        for (let key of Object.keys(higherPriority)) {
            const value = getValues(higherPriority[key]);
            reserveValues[key] = value;
        }

        // 優先順位の下の入力欄の値を上の入力欄に移し替える
        for (let key of Object.keys(higherPriority)) {
            const newValue = getValues(lowerPriority[key]);
            setValue(higherPriority[key], newValue, { shouldValidate: false });
        }

        // 控えておいた値を優先順位の下の入力欄に差し替える
        for (let key of Object.keys(reserveValues)) {
            setValue(lowerPriority[key], reserveValues[key], { shouldValidate: false });
        }
    };

    // 支援目標の各優先順位の右上にある✕ボタン押下時に、支援目標を削除したり空欄に戻したりする処理
    const handleOnClickPriorityClear = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この支援目標を削除して続く優先順位を上に詰める、もしくはこの支援目標に関する入力内容をクリアしますか？",
            buttons: {
                catch: {
                    text: "入力内容をクリア",
                    value: "clear",
                    visible: true,
                    className:
                        "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                    closeModal: true,
                },
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
        }).then((value) => {
            switch (value) {
                case "clear":
                    // 選択範囲をクリアする
                    PriorityInputClear(num);
                    break;

                case "cancel":
                    break;

                case "delete":
                    // 先ず、対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    // 現在表示されている優先順位の個数分、優先順位を入れ替える関数を順に実行し、繰上げを行い
                    // ✕ボタンが押下され、上記の関数によって空欄になった入力欄を最下位に下げる
                    for (let ii = num; ii < priorityCount; ii++) {
                        handleOnClickPriorityChange(false, ii);
                    }
                    // 表示される優先順位の入力欄を減らす
                    setPriorityCount((priorityCount) => priorityCount - 1);
                    break;
                default:
                    break;
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const PriorityInputClear = (num) => {
        // 対象の優先順位の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = [
            `task_${num}`,
            `target_${num}`,
            `achievement_period_${num}`,
            `type_${num}`,
            `provider_${num}`,
            `role_${num}`,
            `evaluation_period_${num}`,
            `note_${num}`,
        ];

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, null, { shouldValidate: false });
        }
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>サービス等利用計画編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        {citingClient === "done" && (
                            <div className='text-end mb-2'>
                                <p className='d-inline-block text-decoration-underline'>
                                    ※保存時に現在の利用者情報が適用されます
                                </p>
                            </div>
                        )}
                        <div className='d-flex justify-content-between  mb-4'>
                            <p className='fs-6 pt-2'>
                                <RequiredMark />
                                <span className='text-secondary'>は必須項目です。</span>
                            </p>
                            <div>
                                {clientChangeable && (
                                    <div className='d-flex mb-2'>
                                        {citingClient === "done" && (
                                            <div className='pe-2'>
                                                <Button
                                                    type='button'
                                                    variant='contained'
                                                    onClick={(e) => {
                                                        putBackClientChanges(e);
                                                    }}>
                                                    利用者情報を
                                                    <br />
                                                    編集前に戻す
                                                </Button>
                                            </div>
                                        )}
                                        {citingClient === "ready" ? (
                                            <Button
                                                type='button'
                                                variant='contained'
                                                color='secondary'
                                                onClick={(e) => {
                                                    implementClientChanges(e);
                                                }}>
                                                登録されている利用者の情報と
                                                <br />
                                                異なる入力内容があります
                                            </Button>
                                        ) : (
                                            <Button type='button' variant='contained' color='primary'>
                                                登録されている利用者の内容を
                                                <br />
                                                引用済み
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(plan_1Submit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    計画作成日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*計画作成日を入力してください。",
                                        },
                                    })}
                                />
                                {/* 日付の重複によるエラーメッセージ */}
                                <small className='text-danger'>{dateMessage && dateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.date}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    計画作成担当者
                                    <RequiredMark />
                                </label>
                                {Object.keys(users).length > 0 && (
                                    <Controller
                                        name='user_name'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*計画作成担当者を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        {Object.keys(users).length === 0 ? (
                                                            <>ユーザーが登録されていません</>
                                                        ) : (
                                                            <>選択してください</>
                                                        )}
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.user_name}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                )}
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.user_name}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='maximum_amount'>
                                    利用者負担上限額
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>（半角数字のみ）</small>
                                </label>
                                <input
                                    id='maximum_amount'
                                    type='number'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    {...register("maximum_amount", {
                                        required: {
                                            value: true,
                                            message: "*利用者負担上限額を入力してください。",
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.maximum_amount && errors.maximum_amount?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.maximum_amount}
                                </small>
                            </div>
                            <hr />
                            <h3 className='border-bottom border-secondary'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、利用者情報による内容</span>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                </label>
                                <input
                                    id='client_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_name", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            {storeClient?.support_law === 2 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1'>障害支援区分</label>
                                    <div className='form-control mb-1'>
                                        {formatSupportLevelListForEdit(
                                            storeClient.support_law,
                                            storeClient.support_level
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_name'>
                                    相談支援事業者名
                                </label>
                                <input
                                    id='office_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("office_name", {
                                        required: {
                                            value: true,
                                            message: "*相談支援事業者名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_2'>
                                    障害福祉サービス受給者証番号
                                </label>
                                <input
                                    id='recipient_no_2'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_2", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_3'>
                                    地域相談支援受給者番号
                                </label>
                                <input
                                    id='recipient_no_3'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_3", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_4'>
                                    通所受給者番号
                                </label>
                                <input
                                    id='recipient_no_4'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_4", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='start_month'>
                                    モニタリング期間(開始年月)
                                </label>
                                <input
                                    id='start_month'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("start_month")}
                                />
                            </div>
                            <hr />
                            <h3 className='border-bottom border-secondary mb-3'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、サービス等利用計画案についての項目</span>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='wish'>
                                    利用者及びその家族の生活に対する意向（希望する生活）
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='wish'
                                    className='form-control mb-1'
                                    {...register("wish", {
                                        required: {
                                            value: true,
                                            message:
                                                "*利用者及びその家族の生活に対する意向（希望する生活）を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*利用者及びその家族の生活に対する意向（希望する生活）を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.wish && errors.wish?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.wish}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='plan_1'>
                                    総合的な支援の方針
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='plan_1'
                                    className='form-control mb-1'
                                    {...register("plan_1", {
                                        required: {
                                            value: true,
                                            message: "*総合的な支援の方針を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*総合的な支援の方針を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.plan_1 && errors.plan_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.plan_1}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='plan_2'>
                                    長期目標
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='plan_2'
                                    className='form-control mb-1'
                                    {...register("plan_2", {
                                        required: {
                                            value: true,
                                            message: "*長期目標を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*長期目標を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.plan_2 && errors.plan_2?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.plan_2}</small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='plan_3'>
                                    短期目標
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='plan_3'
                                    className='form-control mb-1'
                                    {...register("plan_3", {
                                        required: {
                                            value: true,
                                            message: "*短期目標を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*短期目標を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.plan_3 && errors.plan_3?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.plan_3}</small>
                            </div>
                            <p className='text-secondary fs-6'>以下、支援目標について優先順位ごとに入力</p>
                            <div className={classes.priority}>
                                <span className={classes.priorityNum}>【1】</span>
                                {priorityCount > 1 && (
                                    <>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 1);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='task_1'>
                                    解決すべき課題（本人のニーズ）
                                    <RequiredMark />
                                </label>
                                <input
                                    id='task_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("task_1", {
                                        required: {
                                            value: true,
                                            message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.task_1 && errors.task_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.task_1}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='target_1'>
                                    支援目標
                                    <RequiredMark />
                                </label>
                                <input
                                    id='target_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("target_1", {
                                        required: {
                                            value: true,
                                            message: "*支援目標を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.target_1 && errors.target_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.target_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='achievement_period_1'>
                                    達成期間
                                    <RequiredMark />
                                </label>
                                <input
                                    id='achievement_period_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("achievement_period_1", {
                                        required: {
                                            value: true,
                                            message: "*達成期間を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.achievement_period_1 && errors.achievement_period_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.achievement_period_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='type_1'>
                                    種類・内容・量
                                    <RequiredMark />
                                </label>
                                <input
                                    id='type_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("type_1", {
                                        required: {
                                            value: true,
                                            message: "*種類・内容・量を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.type_1 && errors.type_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.type_1}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='provider_1'>
                                    提供事業者名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='provider_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("provider_1", {
                                        required: {
                                            value: true,
                                            message: "*提供事業者名を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.provider_1 && errors.provider_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.provider_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='role_1'>
                                    課題解決のための本人の役割
                                    <RequiredMark />
                                </label>
                                <input
                                    id='role_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("role_1", {
                                        required: {
                                            value: true,
                                            message: "*課題解決のための本人の役割を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.role_1 && errors.role_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.role_1}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='evaluation_period_1'>
                                    評価時期
                                    <RequiredMark />
                                </label>
                                <input
                                    id='evaluation_period_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("evaluation_period_1", {
                                        required: {
                                            value: true,
                                            message: "*評価時期を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.evaluation_period_1 && errors.evaluation_period_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.evaluation_period_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='note_1'>
                                    その他留意事項
                                </label>
                                <input id='note_1' type='text' className='form-control mb-1' {...register("note_1")} />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.note_1 && errors.note_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.note_1}</small>
                            </div>
                            {priorityCount >= 2 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【2】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 2 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 2);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 2);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_2'>
                                            解決すべき課題（本人のニーズ）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_2", {
                                                required: {
                                                    value: true,
                                                    message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_2 && errors.task_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.task_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_2'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_2", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_2 && errors.target_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.target_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_2'>
                                            達成期間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_2", {
                                                required: {
                                                    value: true,
                                                    message: "*達成期間を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_2 && errors.achievement_period_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.achievement_period_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='type_2'>
                                            種類・内容・量
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='type_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("type_2", {
                                                required: {
                                                    value: true,
                                                    message: "*種類・内容・量を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.type_2 && errors.type_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.type_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='provider_2'>
                                            提供事業者名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='provider_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("provider_2", {
                                                required: {
                                                    value: true,
                                                    message: "*提供事業者名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.provider_2 && errors.provider_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.provider_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='role_2'>
                                            課題解決のための本人の役割
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='role_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("role_2", {
                                                required: {
                                                    value: true,
                                                    message: "*課題解決のための本人の役割を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.role_2 && errors.role_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.role_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='evaluation_period_2'>
                                            評価時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='evaluation_period_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("evaluation_period_2", {
                                                required: {
                                                    value: true,
                                                    message: "*評価時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.evaluation_period_2 && errors.evaluation_period_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.evaluation_period_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='note_2'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='note_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("note_2")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.note_2 && errors.note_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.note_2}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount >= 3 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【3】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 2);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 3 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 3);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 3);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_3'>
                                            解決すべき課題（本人のニーズ）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_3", {
                                                required: {
                                                    value: true,
                                                    message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_3 && errors.task_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.task_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_3'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_3", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_3 && errors.target_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.target_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_3'>
                                            達成期間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_3", {
                                                required: {
                                                    value: true,
                                                    message: "*達成期間を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_3 && errors.achievement_period_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.achievement_period_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='type_3'>
                                            種類・内容・量
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='type_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("type_3", {
                                                required: {
                                                    value: true,
                                                    message: "*種類・内容・量を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.type_3 && errors.type_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.type_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='provider_3'>
                                            提供事業者名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='provider_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("provider_3", {
                                                required: {
                                                    value: true,
                                                    message: "*提供事業者名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.provider_3 && errors.provider_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.provider_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='role_3'>
                                            課題解決のための本人の役割
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='role_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("role_3", {
                                                required: {
                                                    value: true,
                                                    message: "*課題解決のための本人の役割を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.role_3 && errors.role_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.role_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='evaluation_period_3'>
                                            評価時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='evaluation_period_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("evaluation_period_3", {
                                                required: {
                                                    value: true,
                                                    message: "*評価時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.evaluation_period_3 && errors.evaluation_period_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.evaluation_period_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='note_3'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='note_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("note_3")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.note_3 && errors.note_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.note_3}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount >= 4 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【4】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 3);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 4 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 4);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 4);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_4'>
                                            解決すべき課題（本人のニーズ）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_4", {
                                                required: {
                                                    value: true,
                                                    message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_4 && errors.task_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.task_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_4'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_4", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_4 && errors.target_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.target_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_4'>
                                            達成期間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_4", {
                                                required: {
                                                    value: true,
                                                    message: "*達成期間を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_4 && errors.achievement_period_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.achievement_period_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='type_4'>
                                            種類・内容・量
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='type_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("type_4", {
                                                required: {
                                                    value: true,
                                                    message: "*種類・内容・量を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.type_4 && errors.type_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.type_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='provider_4'>
                                            提供事業者名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='provider_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("provider_4", {
                                                required: {
                                                    value: true,
                                                    message: "*提供事業者名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.provider_4 && errors.provider_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.provider_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='role_4'>
                                            課題解決のための本人の役割
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='role_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("role_4", {
                                                required: {
                                                    value: true,
                                                    message: "*課題解決のための本人の役割を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.role_4 && errors.role_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.role_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='evaluation_period_4'>
                                            評価時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='evaluation_period_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("evaluation_period_4", {
                                                required: {
                                                    value: true,
                                                    message: "*評価時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.evaluation_period_4 && errors.evaluation_period_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.evaluation_period_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='note_4'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='note_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("note_4")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.note_4 && errors.note_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.note_4}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount === 5 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【5】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 4);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 5);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_5'>
                                            解決すべき課題（本人のニーズ）
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_5", {
                                                required: {
                                                    value: true,
                                                    message: "*解決すべき課題（本人のニーズ）を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_5 && errors.task_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.task_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_5'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_5", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_5 && errors.target_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.target_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_5'>
                                            達成期間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_5", {
                                                required: {
                                                    value: true,
                                                    message: "*達成期間を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_5 && errors.achievement_period_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.achievement_period_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='type_5'>
                                            種類・内容・量
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='type_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("type_5", {
                                                required: {
                                                    value: true,
                                                    message: "*種類・内容・量を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.type_5 && errors.type_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.type_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='provider_5'>
                                            提供事業者名
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='provider_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("provider_5", {
                                                required: {
                                                    value: true,
                                                    message: "*提供事業者名を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.provider_5 && errors.provider_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.provider_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='role_5'>
                                            課題解決のための本人の役割
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='role_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("role_5", {
                                                required: {
                                                    value: true,
                                                    message: "*課題解決のための本人の役割を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.role_5 && errors.role_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.role_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='evaluation_period_5'>
                                            評価時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='evaluation_period_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("evaluation_period_5", {
                                                required: {
                                                    value: true,
                                                    message: "*評価時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.evaluation_period_5 && errors.evaluation_period_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.evaluation_period_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='note_5'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='note_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("note_5")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.note_5 && errors.note_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {plan_1.success ? "" : plan_1.error_list.note_5}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount < 5 && (
                                <div className='w-100 mb-5'>
                                    <hr />
                                    <div className='text-center'>
                                        <button
                                            type='button'
                                            className='btn btn-outline-primary'
                                            onClick={(e) => {
                                                handleOnClickAddInput(e);
                                            }}>
                                            <span className='fw-bold fs-5 px-1'>＋</span>
                                            支援目標を追加する
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary mb-5'>
                                <span className='fs-2 text-danger'>■</span>
                                <span className='fs-4'>以下、週間計画表に関する項目</span>
                                <small className='fs-6 fw-normal text-secondary'>（二枚目の様式について）</small>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='start_date'>
                                    計画開始年月
                                    <RequiredMark />
                                    <small className='fs-6 fw-normal text-secondary'>（yyyy/mm/ddの形式で入力）</small>
                                </label>
                                <input
                                    id='start_date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    className='form-control mb-1'
                                    {...register("start_date", {
                                        required: {
                                            value: true,
                                            message: "*計画開始年月を入力してください。",
                                        },
                                    })}
                                />
                                {/* 生年月日以前の日付指定時のエラーメッセージ */}
                                <small className='text-danger'>{startDateMessage && startDateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.start_date && errors.start_date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.start_date}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='daily_routine'>
                                    主な日常生活上の活動
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='daily_routine'
                                    className='form-control mb-1'
                                    {...register("daily_routine", {
                                        required: {
                                            value: true,
                                            message: "*主な日常生活上の活動を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*主な日常生活上の活動を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.daily_routine && errors.daily_routine?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.daily_routine}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_service'>
                                    週単位以外のサービス
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='other_service'
                                    className='form-control mb-1'
                                    {...register("other_service", {
                                        required: {
                                            value: true,
                                            message: "*週単位以外のサービスを入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*週単位以外のサービスを入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_service && errors.other_service?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {plan_1.success ? "" : plan_1.error_list.other_service}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='total'>
                                    サービス提供によって実現する生活の全体像
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='total'
                                    className='form-control mb-1'
                                    {...register("total", {
                                        required: {
                                            value: true,
                                            message: "*サービス提供によって実現する生活の全体像を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*サービス提供によって実現する生活の全体像を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.total && errors.total?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{plan_1.success ? "" : plan_1.error_list.total}</small>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Plan_1Edit;
