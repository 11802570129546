import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../api/api";
import DataTable from "./DataTable";
import FreeWordSearch from "./FreeWordSearch";
// #82のみ追記
import { UserContext } from "../providers/UserProvider";
// 追記ここまで

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
    })
);

//見出しの配列定義
const headers = ["ユーザー名", "選択"];
// 検索対象のカラムの配列定義
const columns = ["user_name"];

// ステートの初期状態
const initUsersResult = { result: [], success: true };

function UserSelect(props) {
    // ログイン中のユーザーIDを定義
    const { userInfo } = useContext(UserContext);
    //---------------------------issue{No.147} start-----------------------------
    const intId = userInfo.user.id;
    //---------------------------issue{No.147} end-------------------------------
    const userId = userInfo.user.user_id;
    const userAuthority = userInfo.user.authority;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 親コンポーネントから受け取ったメソッドを分割代入
    const { setSelectedUser } = props;

    //User の状態を管理する
    const [usersResult, setUsersResult] = useState(initUsersResult);

    // 実際に表示するユーザのステート
    const [users, setUsers] = useState([]);

    // 検索されたユーザ情報を格納するステート
    const [searchedUsers, setSearchedUsers] = useState([]);

    //Users を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //情報を取得しステート Users にセットする
    const getUsersData = async () => {
        const res = await api.get("users");
        setUsersResult(res.data);
        // #82のみ追記
        setUsers(res.data.result);
        // 追記ここまで
    };

    // 一覧の行クリック時、ユーザ情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行のユーザのid(key)と一致するusersステートのユーザ情報を変数に代入
        const user = usersResult.result.find((user) => user.id === id);
        // userに値が存在する（0, "", undefined, nullでない）場合
        if (user) {
            // URL(/user/show/${user.id})をたたく（RouterConfig.js）
            navigate(`/user_select/show/${id}`);
        }
    };

    const handleOnClickSelect = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // ステートを更新する
        setSelectedUser(id);
    };

    // 画面初期表示時にユーザ情報を取得する
    useEffect(() => {
        getUsersData();
    }, []);

    // ログインユーザの権限に応じて、表示するユーザを絞り込む
    useEffect(() => {
        if (usersResult.result === true && userAuthority >= 40) {
            const usersLists = usersResult.result.filter((user) => user.user_id === userId);
            setUsers(usersLists);
        } else {
            setUsers(usersResult.result);
        }
    }, [usersResult.result]);

    //users に応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!searchedUsers || !usersResult.success) {
            return;
        }

        //---------------------------issue{No.147} start-----------------------------
        const newRows = searchedUsers
            .map((user) => {
                return {
                    id: user.id,
                    isActive: user.deleted_at === null,
                    data: {
                        user_name: user.user_name,
                        selectBtn: (
                            <Button variant='contained' onClick={(e) => handleOnClickSelect(e, user.id)}>
                                選択
                            </Button>
                        ),
                    },
                };
            })
            .filter((user) => user.id !== intId);
        //---------------------------issue{No.147} end-------------------------------
        setRows(newRows);
    }, [searchedUsers]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='text-center my-3'>ユーザを選択してください。</div>

                    <FreeWordSearch searchColumns={columns} data={users} setSearchedData={setSearchedUsers} />
                    <Card className={classes.card}>
                        {/* テーブル部分の定義 */}
                        <DataTable
                            headers={headers}
                            rows={rows}
                            handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                        />
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default UserSelect;
