import React from "react";

// 画面のコンテンツを表示する部分における標準の外枠
const PageOutline = ({children}) => (
    <div className='row justify-content-center'>
        <div className='col-md-12 mx-auto'>
            <div className='card'>
                {children}
            </div>
        </div>
    </div>
);
export default PageOutline;