import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

function CompanyShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の会社情報のId
    const { id } = useParams();

    // company の状態を管理する
    const [company, setCompany] = useState([]);

    //一覧情報を取得しステート company にセットする
    const getCompanyData = async (id) => {
        const url = `companies/${id}`;
        const res = await api.get(url);
        setCompanyRow(res.data.result);
    };

    // ステートに値をセット
    const setCompanyRow = (company) => {
        setCompany([
            { label: "法人番号", value: company.company_id },
            { label: "会社名", value: company.company_name },
            { label: "郵便番号", value: company.zip_code },
            { label: "住所1", value: company.address1 },
            { label: "住所2", value: company.address2 },
            { label: "住所3", value: company.address3 },
            { label: "電話番号", value: company.tel },
            { label: "FAX番号", value: company.fax },
            { label: "代表者名", value: company.representative_name },
        ]);
    };

    // 閉じるボタンをクリックしたら、会社情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();
        navigate(`/companies`);
    };

    //画面初期表示時に会社情報を取得する
    useEffect(() => {
        getCompanyData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>会社情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={company}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyShow;
