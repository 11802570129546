import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDeletedDataList from "../../components/ClientSelectToDeletedDataList";

// 見出しの配列定義
const headers = ["報告書作成日"];

export default function Monitoring_1Deleted() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDeletedDataList pageName='モニタリング報告書' targetURL='monitoring_1' headerDataList={headers} clientID={client} />;
}