import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";
//---------------------------issue{No.54} start-----------------------------
import { useForm } from "react-hook-form";
import { usePostalJp } from "use-postal-jp";
//---------------------------issue{No.54} end-------------------------------

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

//company の初期状態
const initCompany = { success: true };

//---------------------------issue{No.54} start-----------------------------
// 郵便番号が不正の場合に表示するメッセージ
const zipCodeErrorMessage = "存在しない郵便番号です";
//---------------------------issue{No.54} end-------------------------------

function CompanyEdit() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //編集対象の会社情報のId
    const { id } = useParams();

    //company の状態を管理する
    const [companyInput, setCompany] = useState(initCompany);

    //一覧情報を取得しステート company にセットする
    const getCompanyData = async (id) => {
        const url = `companies/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setCompany({
                ...res.data.result,
                error_list: null,
                success: res.data.success
            });
        }
    };

    // // 入力欄の値変更時
    // const handleInput = (e) => {
    //     // イベントハンドラ実行後、オブジェクトのプロパティにアクセス
    //     e.persist();
    //     // ステートに値をセット
    //     setCompany({ ...companyInput, [e.target.name]: e.target.value });
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    //---------------------------issue{No.54} start-----------------------------
    // 一部変更と追記
    // submitされた時かつ、バリデーションに問題がなかった時に実行
    const companySubmit = async (data) => {
        // 追記
        // 住所取得のAPI通信が行われている最中の場合
        // 処理を抜けてアラートを表示する
        if (loading) {
            swal({
                icon: "warning",
                title: "住所の取得中です",
                text: "再度お試しください",
                timer: 2000
            });
            return;
        }

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }
        // 追記ここまで

        // CompanyControllerに渡す値をセット
        const companyData = {
            company_id: data.company_id,
            company_name: data.company_name,
            zip_code: data.zip_code,
            address1: data.address1,
            address2: data.address2,
            address3: data.address3,
            tel: data.tel,
            fax: data.fax,
            representative_name: data.representative_name
        };

        //更新
        const url = `companies/${companyInput.id}`;
        const res = await api.put(url, companyData);
        if (res.status === 422) {
            setCompany({
                ...companyInput,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            // 成功メッセージ
            swal(res.data.message, res.data.result.company_name, "success").then(() => {
                navigate("/companies");
            });
        }
    };

    //画面初期表示時に会社情報を取得する
    useEffect(() => {
        getCompanyData(id);
    }, []);
    //---------------------------issue{No.54} end-------------------------------

    //---------------------------issue{No.54} start-----------------------------
    // 追記
    // use-postal-jpの初期設定
    const [zipCode, setZipCode] = useState("");
    const [address, loading, error] = usePostalJp(zipCode, zipCode.length === 7);

    // 郵便番号による住所取得が行われるたびに、以下の副作用フックを実行
    useEffect(() => {
        const setCompanyAddress = () => {
            // addressに正しく値が取得できている場合に、tryが実行される
            try {
                // addressから、必要な情報を分割代入
                const { prefecture, address1, address2 } = address;
                // 文字列の連結
                const companyAddress = prefecture + address1 + address2;
                // react-hook-formのsetValueでaddress1を更新
                setValue("address1", companyAddress, {
                    shouldValidate: true
                });
            } catch {
                // 郵便番号が不正、もしくは初回の読み込みの際に値を空にする
                setValue("address1", "", { shouldValidate: false });
            }
        };
        // 上記の関数を実行
        setCompanyAddress();
        // addressの取得が済んで変更されたとき、この副作用フックを実行
    }, [address]);
    //---------------------------issue{No.54} end-------------------------------

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        //---------------------------issue{No.558} start-----------------------------
        watch,
        trigger
        //---------------------------issue{No.558} end-------------------------------
    } = useForm();

    //---------------------------issue{No.558} start-----------------------------
    // inputの入力値が変わる度にそれぞれの項目に応じた処理を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                if (name === "zip_code") {
                    // バリデーションを実行
                    trigger("zip_code");
                    // zipCodeの値を更新
                    setZipCode(value.zip_code);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    //---------------------------issue{No.558} end-------------------------------

    // 以下、編集機能などでの、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(companyInput).length > 1 && !getValues("company_name")) {
            setValue("id", companyInput.id, { shouldValidate: false });
            setValue("company_id", companyInput.company_id, { shouldValidate: false });
            setValue("company_name", companyInput.company_name, { shouldValidate: false });
            setValue("zip_code", companyInput.zip_code, { shouldValidate: false });
            setValue("address1", companyInput.address1, { shouldValidate: false });
            setValue("address2", companyInput.address2, { shouldValidate: false });
            setValue("address3", companyInput.address3, { shouldValidate: false });
            setValue("tel", companyInput.tel, { shouldValidate: false });
            setValue("fax", companyInput.fax, { shouldValidate: false });
            setValue("representative_name", companyInput.representative_name, { shouldValidate: false });

            //---------------------------issue{No.558} start-----------------------------
            setZipCode(companyInput.zip_code);
            //---------------------------issue{No.558} end-------------------------------
        }
    }, [companyInput]);
    // 以上、初期値の必要な機能で必要になる副作用フック

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>会社情報編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        {/* submitした時の関数を修正 */}
                        <form onSubmit={handleSubmit(companySubmit)}>
                            {/* 修正ここまで */}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='company_id'>
                                    法人番号
                                </label>
                                <input
                                    id='company_id'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly='true'
                                    {...register("company_id", {})}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='company_name'>
                                    会社名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='company_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("company_name", {
                                        required: {
                                            value: true,
                                            message: "*会社名を入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.company_name && errors.company_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {companyInput.success ? "" : companyInput.error_list.company_name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='zip_code'>
                                    郵便番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字のみ、ハイフンなし）</small>
                                </label>
                                <br />
                                {/* 住所自動入力のエラーメッセージ */}
                                <small className='text-danger'>
                                    {!loading && error && zipCode.length === 7 && "※" + zipCodeErrorMessage}
                                </small>
                                <input
                                    id='zip_code'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("zip_code", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を入力してください"
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        maxLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.zip_code && errors.zip_code?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {companyInput.success ? "" : companyInput.error_list.zip_code}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='address1'>
                                    住所1
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>
                                        （都道府県、市区町村、市区町村配下）
                                    </small>
                                </label>
                                <input
                                    id='address1'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='郵便番号による自動入力'
                                    readOnly
                                    {...register("address1", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を確認してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.address1 && errors.address1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {companyInput.success ? "" : companyInput.error_list.address1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='address2'>
                                    住所2
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（丁目、番地、号）</small>
                                </label>
                                <input
                                    id='address2'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("address2", {
                                        required: {
                                            value: true,
                                            message: "*住所を入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.address2 && errors.address2?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {companyInput.success ? "" : companyInput.error_list.address2}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='address3'>
                                    住所3
                                    <small className='fw-normal text-secondary'>（建物名、階数、部屋番号など）</small>
                                </label>
                                <input id='address3' type='text' className='form-control' {...register("address3")} />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.address3 && errors.address3?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {companyInput.success ? "" : companyInput.error_list.address3}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='tel'>
                                    電話番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                </label>
                                <input
                                    id='tel'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='000-000-0000'
                                    {...register("tel", {
                                        required: {
                                            value: true,
                                            message: "*電話番号を入力してください"
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください"
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください"
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.tel && errors.tel?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {companyInput.success ? "" : companyInput.error_list.tel}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='fax'>
                                    FAX番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                </label>
                                <input
                                    id='fax'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='000-000-0000'
                                    {...register("fax", {
                                        required: {
                                            value: true,
                                            message: "*FAX番号を入力してください"
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください"
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください"
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.fax && errors.fax?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {companyInput.success ? "" : companyInput.error_list.fax}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='representative_name'>
                                    代表者名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='representative_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("representative_name", {
                                        required: {
                                            value: true,
                                            message: "*代表者名を入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.representative_name && errors.representative_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {companyInput.success ? "" : companyInput.error_list.representative_name}
                                </small>
                            </div>
                            <div className='form-group mb-4 d-flex'>
                                <BackButton />
                                {/* typeを変更、登録実行をこのボタンクリック時に変更 */}
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompanyEdit;
