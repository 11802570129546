import React, { useContext } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { UserContext } from "../providers/UserProvider";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        buttonWrapper: {
            position: "fixed",
            bottom: "30px",
            right: "30px",
            display: "flex",
            justifyContent: "space-between",
            width: "85px",
        },
        scrollTop: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
            border: "1px solid #222",
            boxShadow: "0 0 5px 5px #4449 inset",
            borderRadius: "50%",
            background: "#444",
            transform: "rotate(-90deg)",
            cursor: "pointer",
        },
        scrollBottom: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "40px",
            height: "40px",
            border: "1px solid #222",
            boxShadow: "0 0 5px 5px #4449 inset",
            borderRadius: "50%",
            background: "#444",
            transform: "rotate(90deg)",
            cursor: "pointer",
        },
        arrow: {
            display: "block",
            lineHeight: "0",
            color: "#fff",
            fontSize: "2rem",
            transform: "scale(1, .7)",
        },
    })
);

const scrollTop = (e) => {
    // イベントの伝搬を中止
    e.preventDefault();
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const scrollBottom = (e) => {
    // イベントの伝搬を中止
    e.preventDefault();
    document.getElementById("footer").scrollIntoView(true);
};

const ScrollButtons = () => {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    const { userInfo } = useContext(UserContext);
    const isLogin = userInfo.isLogin;

    return (
        <>
            {isLogin && (
                <div className={classes.buttonWrapper}>
                    <div
                        className={classes.scrollTop}
                        onClick={(e) => {
                            scrollTop(e);
                        }}>
                        <span className={classes.arrow}>➔</span>
                    </div>
                    <div
                        className={classes.scrollBottom}
                        onClick={(e) => {
                            scrollBottom(e);
                        }}>
                        <span className={classes.arrow}>➔</span>
                    </div>
                </div>
            )}
        </>
    );
};

export default ScrollButtons;
