const dateCollation = (inputDate, dateList = []) => Boolean(dateList.includes(inputDate));
// 第1引数に受け取ったdateを表す文字列と誕生日や日付一覧配列とのerror check
export default function dateRegisteringErrorMessage(inputDate, birthday = "", dateList = []) {
    let errorMessage = "";
    const duplicate = dateCollation(inputDate, dateList);
    if (dateList.length && duplicate) {
        errorMessage = "すでに登録済みの日付です。";
    }

    // 申請日最小値を利用者(または児童)の生年月日とする
    const clientBirthday = new Date(birthday); // 利用者の生年月日
    const targetDate = new Date(inputDate); // 対象の日付

    const beforeClientBirthdayErrorMessage = "利用者の生年月日(" + birthday + ")以降の日付を入力してください";

    // 対象の日付が誕生日より小さい場合エラーメッセージを出す
    if (clientBirthday > targetDate) {
        errorMessage += beforeClientBirthdayErrorMessage;
    }
    return errorMessage;
}
