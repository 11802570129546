import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

const task_list = { 1: "面談", 2: "モニタリング", 3: "電話対応", 4: "連絡事項" };

function ReportDeletedShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 表示対象の日報のid
    const { user, id } = useParams();

    // report の状態を管理する
    const [report, setReport] = useState([]);

    // 一覧情報を取得しステート report にセットする
    const getReportData = async (id) => {
        const url = `reports/${id}/deleted`;
        const res = await api.get(url);
        setReportRow(res.data.result);
    };

    // ステートに値をセット
    const setReportRow = (report) => {
        const reportDateFormat = dateFormat(report.date, true);
        const reportDate = reportDateFormat.format_date;

        let taskTimes = [];
        for (let ii = 1; ii <= 8; ii++) {
            if (report[`start_time_${ii}`] && report[`end_time_${ii}`]) {
                taskTimes[ii] = report[`start_time_${ii}`] + " ～ " + report[`end_time_${ii}`];
            }
        }

        setReport([
            { label: "日付", value: reportDate },
            { label: "会社名", value: report.company_name },
            { label: "事業所名", value: report.office_name },
            { label: "職員氏名", value: report.user_name },
            { label: "今日の連絡事項", value: report.message },
            { label: "電話対応", value: report.phone_support },
            { label: "【1】業務内容", value: task_list[report.task_1] },
            { label: "【1】時間", value: taskTimes[1] },
            { label: "【1】利用者名", value: report.client_name_1 },
            { label: "【1】内容", value: report.detail_1 },
            { label: "【2】業務内容", value: task_list[report.task_2] },
            { label: "【2】時間", value: taskTimes[2] },
            { label: "【2】利用者名", value: report.client_name_2 },
            { label: "【2】内容", value: report.detail_2 },
            { label: "【3】業務内容", value: task_list[report.task_3] },
            { label: "【3】時間", value: taskTimes[3] },
            { label: "【3】利用者名", value: report.client_name_3 },
            { label: "【3】内容", value: report.detail_3 },
            { label: "【4】業務内容", value: task_list[report.task_4] },
            { label: "【4】時間", value: taskTimes[4] },
            { label: "【4】利用者名", value: report.client_name_4 },
            { label: "【4】内容", value: report.detail_4 },
            { label: "【5】業務内容", value: task_list[report.task_5] },
            { label: "【5】時間", value: taskTimes[5] },
            { label: "【5】利用者名", value: report.client_name_5 },
            { label: "【5】内容", value: report.detail_5 },
            { label: "【6】業務内容", value: task_list[report.task_6] },
            { label: "【6】時間", value: taskTimes[6] },
            { label: "【6】利用者名", value: report.client_name_6 },
            { label: "【6】内容", value: report.detail_6 },
            { label: "【7】業務内容", value: task_list[report.task_7] },
            { label: "【7】時間", value: taskTimes[7] },
            { label: "【7】利用者名", value: report.client_name_7 },
            { label: "【7】内容", value: report.detail_7 },
            { label: "【8】業務内容", value: task_list[report.task_8] },
            { label: "【8】時間", value: taskTimes[8] },
            { label: "【8】利用者名", value: report.client_name_8 },
            { label: "【8】内容", value: report.detail_8 }
        ]);
    };

    // 閉じるボタンをクリックしたら、事業所情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();
        navigate(`/report_deleted/${user}`);
    };

    // 画面初期表示時に事業所情報を取得する
    useEffect(() => {
        getReportData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】日報情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={report}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportDeletedShow;
