import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button } from "@material-ui/core";

function BackButton(props) {
    // 画面遷移用
    const navigate = useNavigate();

    // props から遷移先のpathとボタン押下時の処理を分割代入
    // ※遷移先pathの規定値は直前の画面(-1)
    const { path = -1, onClickAction =e=>e } = props;
    // 戻るボタンをクリックしたらpathで指定された画面に戻る
    const handleOnClickBack = (e) => {
        e.stopPropagation();
        onClickAction();
        navigate(path);
    };

    return (
        <Box sx={{ width: "max-content", mr: 1 }}>
            <Button variant='contained' onClick={(e) => handleOnClickBack(e)}>
                戻る
            </Button>
        </Box>
    );
}

export default BackButton;