import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import ScheduleTable from "../../components/schedule/ScheduleTable";

import { Box, Button, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
// 追記ここまで
import BackHomeButton from "../../components/BackHomeButton"; // issue508
import { UserContext } from "../../providers/UserProvider";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
            padding: "1rem",
            boxSizing: "border-box"
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

// ステートの初期状態
const initState = { result: [], success: false };

// ディープコピーを行う関数
const deepCopy = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return obj; // オブジェクトでない場合やnullの場合はそのまま返す
    }

    if (Array.isArray(obj)) {
        // 配列の場合は要素をディープコピーして新しい配列を作成
        return obj.map((item) => deepCopy(item));
    }

    // オブジェクトの場合は各プロパティをディープコピーして新しいオブジェクトを作成
    const copiedObject = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copiedObject[key] = deepCopy(obj[key]);
        }
    }

    return copiedObject;
};

export default function Schedule() {
    // ログイン中のユーザーIDを定義
    const { userInfo } = useContext(UserContext);
    const userTableId = userInfo.user.id;
    const userId = userInfo.user.user_id;
    const userName = userInfo.user.user_name;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //Client の状態を管理する
    const [mySchedule, setMySchedule] = useState(deepCopy(initState));
    const [schedule, setSchedule] = useState(deepCopy(initState));

    //情報を取得しステート mySchedule にセットする
    const getScheduleData = async (id) => {
        try {
            const url = `schedules/${id}`;
            const res = await api.get(url);
            const user = res.data.result.user;
            const other = res.data.result.other;
            setMySchedule({ result: user, success: true });
            setSchedule({ result: other, success: true });
        } catch (error) {
            console.error("Error fetching schedule data:", error);
        }
    };

    // 登録ボタンクリック時
    const handleOnClickRegister = (e, url) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(schedule_register)をたたく（RouterConfig.js）
        navigate(url);
    };

    // 設定変更ボタン押下時
    const handleClickEdit = (e, id) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(schedule_register)をたたく（RouterConfig.js）
        navigate(`/schedule/reference_month/${id}`);
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/home");
    };
    //---------------------------issue{No.539} end-------------------------------

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        getScheduleData(userTableId);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>スケジュール一覧</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                            <Button
                                className='me-5'
                                variant='contained'
                                color='default'
                                onClick={(e) => handleClickEdit(e, userTableId)}>
                                基準月変更
                            </Button>
                            <Button
                                className='me-3'
                                variant='contained'
                                color='primary'
                                onClick={(e) => handleOnClickRegister(e, "/schedule/bulk_register")}>
                                一括登録
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                onClick={(e) => handleOnClickRegister(e, "/schedule_register")}>
                                手動登録
                            </Button>
                        </Box>
                        <div className='mb-5'>
                            <h2 className='h3 ms-4'>{userName}</h2>
                            {mySchedule.success && <ScheduleTable data={mySchedule.result} isOther={false} />}
                        </div>
                        {schedule.success && schedule.result.length > 0 && (
                            <>
                                <hr className='mb-5' />
                                <div>
                                    <h2 className='h3 ms-4'>他メンバー</h2>
                                    {/* テーブル部分の定義 */}
                                    {schedule.result.map((data, dataIndex) => (
                                        <ScheduleTable data={data} isOther={true} key={`dataIndex_${dataIndex}`} />
                                    ))}
                                </div>
                            </>
                        )}
                    </Card>
                    {/* -------------------- issue508 start -------------------- */}
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackHomeButton />
                        </div>
                    </div>
                    {/* -------------------- issue508 start -------------------- */}
                </div>
            </div>
        </div>
    );
}
