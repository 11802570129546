import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
import {
    Box,
    Button,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";
import { UserContext } from "../../providers/UserProvider";
import FreeWordSearch from "../../components/FreeWordSearch";
import DataTable from "../../components/DataTable";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        row: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004",
        },
        rowNum: {
            display: "block",
            marginRight: "auto",
            fontSize: "1rem",
            lineHeight: "2rem",
        },
        isDeleted: {
            background: "#6666",
            padding: "0 1rem 1rem 1rem",
            marginBottom: "2rem",
        },
    })
);

// 初期状態
const initReport = { result: [], success: true };

// 初期状態
const initUserData = { result: [], success: true };

//見出しの配列定義
const headers = ["利用者氏名", "フリガナ", "担当職員名"];

// 検索対象のカラムの配列定義
const columns = ["client_name", "client_ruby", "user_name"];

// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

// 日付をyyyymmddの形式にする
function numDayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + m + d;
}
// 日付を取得
const numToday = Number(numDayFormat(new Date()));

function ReportEdit() {
    // ログイン中のユーザーIDを定義
    const { userInfo } = useContext(UserContext);
    // const userId = userInfo.user.user_id;
    const userAuthority = userInfo.user.authority;
    const officeId = userInfo.user.office_id;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //利用者idと相談支援支給申請のidを保持している
    const { user, date } = useParams();

    // 日付けの入力制限のための日付の値の定数を取得
    const { MIN_DATE, MAX_DATE } = MIN_MAX_DATE;

    // 入力値を管理する
    const [report, setReport] = useState(initReport);

    // データベースから取得したユーザーの情報を管理する
    const [userData, setUserData] = useState(initUserData);

    // クライアントの名前一覧の初期状態を管理する
    const [clientNamesReserve, setClientNamesReserve] = useState([]);

    // 検索用のクライアント一覧の名前を保持
    const [clientNames, setClientNames] = useState([]);

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 優先順位ごとの入力欄の表示個数を管理する
    const [rowCount, setRowCount] = useState(1);
    const rowCountRef = useRef(1);

    // 検索された利用者情報を格納するステート
    const [searchedClientNames, setSearchedClientNames] = useState([]);

    // Clients を表示行に変換したものを保持する
    const [clientRows, setClientRows] = useState([]);

    // 利用者名検索用のダイアログ画面表示切替
    const [searchClientOpen, setSearchClientOpen] = useState(false);

    // 利用者の名前をsetValueする際に対象のフォームを判別するための値を保持する
    const formRowNum = useRef(1);

    const [deletedRows, setDeletedRows] = useState([]);

    //情報を取得しステート reportにセットする
    const getUsersReportData = async (userId, date) => {
        const url = `reports/users_report/${userId}/${date}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 支援目標の入力欄の表示個数を格納する
            let newRowCount = res.data.result.length;
            // 結果をステートにセットし、入力欄を表示させる
            setRowCount(newRowCount);
            rowCountRef.current = newRowCount;

            setReport({
                result: [...res.data.result],
                error_list: null,
                success: res.data.success,
            });
        }
    };

    // 職員情報を取得しステート userData にセットする
    const getUserData = async () => {
        const url = `reports/user/${user}`;
        const res = await api.get(url);
        if (res.data.success) {
            const userResult = res.data.result;
            setUserData({
                company_id: userResult.company_id,
                office_id: userResult.office_id,
                office_name: userResult.office_name,
                user_id: userResult.user_id,
                user_name: userResult.user_name,
            });
        }
    };
    // クライアント情報（名前）を取得しステート clientNames にセットする
    const getClientNames = async () => {
        const url = "clients";
        const res = await api.get(url);
        if (res.data.success) {
            let clientsResult;
            if (userAuthority >= 30) {
                clientsResult = res.data.result.filter((clientResult) => clientResult.office_id === officeId);
            } else {
                clientsResult = res.data.result;
            }

            const clientNamesResult = clientsResult.map((clientResult) => {
                return {
                    id: clientResult.id,
                    client_id: clientResult.client_id,
                    client_name: clientResult.is_child == 1 ? clientResult.child_name : clientResult.client_name,
                    client_ruby: clientResult.is_child == 1 ? clientResult.child_ruby : clientResult.client_ruby,
                    user_id: clientResult.user_id,
                    user_name: clientResult.user_name,
                    deleted_at: clientResult.deleted_at,
                };
            });
            setClientNamesReserve(clientNamesResult);
            setClientNames(clientNamesResult);
        }

        if (res.data.result.length === 0) {
            await swal({
                icon: "warning",
                title: "登録済みの利用者が存在しないため続行できません",
                text: "このメッセージを閉じて「日報一覧」ページに戻ります。",
            });
            navigate(`/report/${user}`);
        }
    };

    // クライアントの各機能ごとに、すでにあるレコードのdateカラムのデータを取得する
    const getDateList = async (clientId) => {
        const url = `reports_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 取得したデータの作成日を被り対象から除外して、dateListを作成
            const resultList = res.data.result
                .filter((item) => item.date !== report.date)
                .map((item) => {
                    return item.date;
                });
            dateList.current = resultList;
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 保存ボタン押下時
    const onSubmit = async (data) => {
        let confirm = false;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "登録しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // 先に二つ目以降の登録数分だけ、配列を作成する
        let editArray = [];

        for (let ii = 1; ii <= rowCountRef.current; ii++) {
            const obj = {
                id: data[`id_${ii}`] ? data[`id_${ii}`] : null,
                company_id: data[`company_id_${ii}`] ? data[`company_id_${ii}`] : null,
                office_id: data[`office_id_${ii}`] ? data[`office_id_${ii}`] : null,
                user_id: data[`user_id_${ii}`] ? data[`user_id_${ii}`] : null,
                client_id: data[`client_id_${ii}`] ? data[`client_id_${ii}`] : null,
                date: report.result[0]["date"],
                start_time: data[`start_time_${ii}`] ? data[`start_time_${ii}`] : null,
                end_time: data[`end_time_${ii}`] ? data[`end_time_${ii}`] : null,
                task: data[`task_${ii}`] ? data[`task_${ii}`] : null,
                detail: data[`detail_${ii}`] ? data[`detail_${ii}`] : null,
                is_deleted: data[`is_deleted_${ii}`] === true ? true : false,
            };

            editArray.push(obj);
        }

        let registerArray = [];

        for (let ii = rowCountRef.current + 1; ii <= rowCount; ii++) {
            const obj = {
                id: null,
                company_id: userData.company_id,
                office_id: userData.office_id,
                user_id: userData.user_id,
                client_id: data[`client_id_${ii}`] ? data[`client_id_${ii}`] : null,
                date: report.result[0]["date"],
                start_time: data[`start_time_${ii}`] ? data[`start_time_${ii}`] : null,
                end_time: data[`end_time_${ii}`] ? data[`end_time_${ii}`] : null,
                task: data[`task_${ii}`] ? data[`task_${ii}`] : null,
                detail: data[`detail_${ii}`] ? data[`detail_${ii}`] : null,
                is_deleted: false,
            };

            registerArray.push(obj);
        }
        // ReportControllerに渡す値をセット
        const reportData = [...editArray, ...registerArray];

        //保存
        const url = `reports_multi_edit`;
        const res = await api.post(url, reportData);
        if (res.status === 422) {
            setReport({
                ...report,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, "", "success").then(() => {
                navigate(`/report/${user}`);
            });
        }
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getUsersReportData(user, date);
        getUserData();
        getClientNames();
        getDateList(user);
    }, []);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        control,
        watch,
        clearErrors,
    } = useForm();

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(report.result).length > 0 && !getValues("date")) {
            setValue(`date`, report.result[0]["date"], { shouldValidate: false });
            Object.keys(report.result).map((key, index) => {
                const rowNumber = index + 1;
                setValue(`id_${rowNumber}`, report.result[key].id, { shouldValidate: false });
                setValue(`company_id_${rowNumber}`, report.result[key].company_id, { shouldValidate: false });
                setValue(`office_id_${rowNumber}`, report.result[key].office_id, { shouldValidate: false });
                setValue(`user_id_${rowNumber}`, report.result[key].user_id, { shouldValidate: false });
                setValue(`client_id_${rowNumber}`, report.result[key].client_id, { shouldValidate: false });
                setValue(`date_${rowNumber}`, report.result[key].date, { shouldValidate: false });
                setValue(`task_${rowNumber}`, report.result[key].task, { shouldValidate: false });
                setValue(`start_time_${rowNumber}`, report.result[key].start_time, { shouldValidate: false });
                setValue(`end_time_${rowNumber}`, report.result[key].end_time, { shouldValidate: false });
                setValue(
                    `client_name_${rowNumber}`,
                    report.result[key].child_name ? report.result[key].child_name : report.result[key].client_name,
                    {
                        shouldValidate: false,
                    }
                );
                setValue(`detail_${rowNumber}`, report.result[key].detail, { shouldValidate: false });
            });
        }
    }, [report]);

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        // if (rowCount - deletedRows.length < 8) {
        setRowCount((rowCount) => rowCount + 1);
        // }
    };

    // 支援目標の優先順位を入れ替える関数
    const handleOnClickRowChange = (e, num) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const higherRow = {
            task: `task_${num}`,
            start_time: `start_time_${num}`,
            end_time: `end_time_${num}`,
            client_name: `client_name_${num}`,
            client_id: `client_id_${num}`,
            detail: `detail_${num}`,
        };

        // 優先順位が一つ上がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const lowerRow = {
            task: `task_${num + 1}`,
            start_time: `start_time_${num + 1}`,
            end_time: `end_time_${num + 1}`,
            client_name: `client_name_${num + 1}`,
            client_id: `client_id_${num + 1}`,
            detail: `detail_${num + 1}`,
        };

        // 入れ替えの際に、値を一時的に控えておく定数オブジェクト
        const reserveValues = {
            task: null,
            start_time: null,
            end_time: null,
            client_name: null,
            client_id: null,
            detail: null,
        };

        // 優先順位が下がる側の入力欄の値を控えておく
        for (let key of Object.keys(higherRow)) {
            const value = getValues(higherRow[key]);
            reserveValues[key] = value;
        }

        // 優先順位の下の入力欄の値を上の入力欄に移し替える
        for (let key of Object.keys(higherRow)) {
            const newValue = getValues(lowerRow[key]);
            setValue(higherRow[key], newValue, { shouldValidate: false });
        }

        // 控えておいた値を優先順位の下の入力欄に差し替える
        for (let key of Object.keys(reserveValues)) {
            setValue(lowerRow[key], reserveValues[key], { shouldValidate: false });
        }
    };

    const handleOnClickReportDelete = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この内容を削除しますか？（保存時に削除されます。）",
            buttons: {
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
        }).then((value) => {
            if (value === "delete") {
                setDeletedRows([...deletedRows, num]);
                setValue(`is_deleted_${num}`, true, { shouldValidate: false });
            }
        });
    };

    const handleOnClickReportRestore = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        setDeletedRows(deletedRows.filter((item) => item != num));
    };

    // 入力欄の各行の右上にある✕ボタン押下時に、入力欄を削除したり空欄に戻したりする処理
    const handleOnClickRowDelete = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この内容を削除しますか？",
            buttons: {
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
        }).then((value) => {
            if (value === "delete") {
                // バリデーションメッセージをリセットする
                clearErrors();
                // 対象の行の入力欄を空欄にする
                RowInputClear(num);
                // 現在表示されている行の個数分、行を入れ替える関数を順に実行し、繰上げを行い
                // ✕ボタンが押下され、上記の関数によって空欄になった入力欄を最下位に下げる
                for (let ii = num; ii < rowCount; ii++) {
                    handleOnClickRowChange(false, ii);
                }
                // 表示される行の入力欄を減らす
                setRowCount((rowCount) => rowCount - 1);
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const RowInputClear = (num) => {
        // 対象の行の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = [
            `start_time_${num}`,
            `end_time_${num}`,
            `client_name_${num}`,
            `client_id_${num}`,
            `detail_${num}`,
        ];

        // ラジオボックスのみ、個別に初期化
        setValue(`task_${num}`, "0", { shouldValidate: false });

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, "", { shouldValidate: false });
        }
    };

    //一覧の行クリック時、利用者情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        const clientName = clientNames.find((client) => client.id === id);

        setValue(`client_name_${formRowNum.current}`, clientName["client_name"], { shouldValidate: true });
        setValue(`client_id_${formRowNum.current}`, clientName["client_id"], { shouldValidate: true });

        setSearchClientOpen(false);
        setClientNames(clientNamesReserve);
    };

    //clients に応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!searchedClientNames || !clientNamesReserve) {
            return;
        }

        const newClientRows = searchedClientNames.map((client) => {
            return {
                id: client.id,
                client_id: client.client_id,
                isActive: client.deleted_at === null,
                data: {
                    client_name: client.is_child ? client.child_name : client.client_name,
                    client_ruby: client.is_child ? client.child_ruby : client.client_ruby,
                    user_name: client.user_name,
                },
            };
        });
        setClientRows(newClientRows);
    }, [searchedClientNames]);

    const handleClickSearchClientOpen = (rowNum) => {
        setSearchClientOpen(true);
        formRowNum.current = rowNum;
    };

    const handleSearchClientClose = () => {
        setSearchClientOpen(false);
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>日報編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 pt-1 mb-5'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    日付
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    readOnly
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*日付を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{report.success ? "" : report.error_list.date}</small>
                            </div>
                            <hr />
                            <div className='d-flex mt-5 mb-2'>
                                <h4 className='d-flex align-items-center border border-2 px-3 fs-5 fw-bold'>
                                    日報内容
                                </h4>
                                <p className='text-secondary fs-6 ms-3'>
                                    <span>（保存後は並び替えて表示されます。）</span>
                                </p>
                            </div>
                            {Object.keys(report.result).length > 0 &&
                                [...Array(rowCount)].map((arrayItem, index) => {
                                    const rowNumber = index + 1;
                                    return (
                                        <div
                                            key={rowNumber}
                                            className={deletedRows.includes(rowNumber) ? classes.isDeleted : undefined}>
                                            <div className={classes.row}>
                                                <span className={classes.rowNum}>
                                                    {rowNumber <= rowCountRef.current
                                                        ? `【${rowNumber}】`
                                                        : `追加の内容【${rowNumber - rowCountRef.current}】`}
                                                    {deletedRows.includes(rowNumber) && "保存時に削除されます。"}
                                                </span>
                                                {rowNumber > rowCountRef.current ? (
                                                    <button
                                                        className='btn btn-outline-primary btn-sm'
                                                        type='button'
                                                        onClick={(e) => {
                                                            handleOnClickRowDelete(e, rowNumber);
                                                        }}>
                                                        <span className='fw-bold'>×</span>
                                                    </button>
                                                ) : (
                                                    <>
                                                        {deletedRows.includes(rowNumber) ? (
                                                            <div className='bg-light'>
                                                                <button
                                                                    className='btn btn-outline-primary btn-sm'
                                                                    type='button'
                                                                    onClick={(e) => {
                                                                        handleOnClickReportRestore(e, rowNumber);
                                                                    }}>
                                                                    <span className='fw-bold'>削除しない</span>
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <button
                                                                className='btn btn-outline-primary btn-sm'
                                                                type='button'
                                                                onClick={(e) => {
                                                                    handleOnClickReportDelete(e, rowNumber);
                                                                }}>
                                                                <span className='fw-bold'>削除する</span>
                                                            </button>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                            {rowNumber > rowCountRef.current && (
                                                <>
                                                    <input
                                                        id={`id_${rowNumber}`}
                                                        type='hidden'
                                                        readOnly
                                                        {...register(`id_${rowNumber}`)}
                                                    />
                                                    <input
                                                        id={`company_id_${rowNumber}`}
                                                        type='hidden'
                                                        readOnly
                                                        {...register(`company_id_${rowNumber}`)}
                                                    />
                                                    <input
                                                        id={`office_id_${rowNumber}`}
                                                        type='hidden'
                                                        readOnly
                                                        {...register(`office_id_${rowNumber}`)}
                                                    />
                                                    <input
                                                        id={`user_id_${rowNumber}`}
                                                        type='hidden'
                                                        readOnly
                                                        {...register(`user_id_${rowNumber}`)}
                                                    />
                                                    <input
                                                        id={`date_${rowNumber}`}
                                                        type='hidden'
                                                        readOnly
                                                        {...register(`date_${rowNumber}`)}
                                                    />
                                                    <input
                                                        id={`is_deleted_${rowNumber}`}
                                                        type='hidden'
                                                        readOnly
                                                        defaultValue={false}
                                                        {...register(`is_deleted_${rowNumber}`)}
                                                    />
                                                </>
                                            )}
                                            <div className='form-group mb-5'>
                                                <label className='fw-bold mb-3'>分類</label>
                                                <RequiredMark />
                                                <br />
                                                <Controller
                                                    name={`task_${rowNumber}`}
                                                    control={control}
                                                    defaultValue='0'
                                                    readOnly={deletedRows.includes(rowNumber)}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: "*分類を選択してください。",
                                                        },
                                                    }}
                                                    render={({ field, fieldState }) => (
                                                        <FormControl component='fieldset'>
                                                            <RadioGroup
                                                                row
                                                                aria-label={`task_${rowNumber}`}
                                                                {...field}
                                                                value={field.value}>
                                                                <FormControlLabel
                                                                    checked={field.value == "1"}
                                                                    value='1'
                                                                    control={<Radio />}
                                                                    label='面談'
                                                                    labelPlacement='end'
                                                                />
                                                                <FormControlLabel
                                                                    checked={field.value == "2"}
                                                                    value='2'
                                                                    control={<Radio />}
                                                                    label='モニタリング'
                                                                    labelPlacement='end'
                                                                />
                                                                <FormControlLabel
                                                                    checked={field.value == "3"}
                                                                    value='3'
                                                                    control={<Radio />}
                                                                    label='電話対応'
                                                                    labelPlacement='end'
                                                                />
                                                                <FormControlLabel
                                                                    checked={field.value == "4"}
                                                                    value='4'
                                                                    control={<Radio />}
                                                                    label='連絡事項'
                                                                    labelPlacement='end'
                                                                />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )}
                                                />
                                                <br />
                                                {!deletedRows.includes(rowNumber) && (
                                                    <>
                                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {errors[`task_${rowNumber}`] &&
                                                                errors[`task_${rowNumber}`]?.message}
                                                        </small>
                                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {report.success
                                                                ? ""
                                                                : report.error_list[`task_${rowNumber}`]}
                                                        </small>
                                                    </>
                                                )}
                                            </div>
                                            <div className='form-group mb-4'>
                                                <label className='fw-bold mb-1'>
                                                    実施時間
                                                    {/* <RequiredMark /> */}
                                                </label>
                                                <div className='d-flex justify-content-start'>
                                                    <input
                                                        id={`start_time_${rowNumber}`}
                                                        type='time'
                                                        readOnly={deletedRows.includes(rowNumber)}
                                                        className='form-control mb-1'
                                                        {...register(`start_time_${rowNumber}`, {})}
                                                    />
                                                    <span className='d-block p-2 lh-lg align-items-center'>～</span>
                                                    <input
                                                        id={`end_time_${rowNumber}`}
                                                        type='time'
                                                        readOnly={deletedRows.includes(rowNumber)}
                                                        className='form-control mb-1'
                                                        {...register(`end_time_${rowNumber}`, {})}
                                                    />
                                                </div>
                                                {!deletedRows.includes(rowNumber) && (
                                                    <>
                                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {errors[`start_time_${rowNumber}`] &&
                                                                errors[`start_time_${rowNumber}`]?.message}
                                                        </small>
                                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {report.success
                                                                ? ""
                                                                : report.error_list[`start_time_${rowNumber}`]}
                                                        </small>
                                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {errors[`end_time_${rowNumber}`] &&
                                                                errors[`end_time_${rowNumber}`]?.message}
                                                        </small>
                                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {report.success
                                                                ? ""
                                                                : report.error_list[`end_time_${rowNumber}`]}
                                                        </small>
                                                    </>
                                                )}
                                            </div>
                                            <div className='form-group mb-4'>
                                                <label className='fw-bold mb-1' htmlFor={`client_name_${rowNumber}`}>
                                                    利用者の名前
                                                    <RequiredMark />
                                                </label>
                                                <div className='d-flex'>
                                                    <div className='w-50'>
                                                        <input
                                                            id={`client_name_${rowNumber}`}
                                                            type='text'
                                                            placeholder='選択してください'
                                                            className='form-control mb-1'
                                                            readOnly
                                                            {...register(`client_name_${rowNumber}`, {
                                                                required: {
                                                                    value: true,
                                                                    message: "*利用者を選択してください。",
                                                                },
                                                            })}
                                                        />
                                                        <input
                                                            id={`client_id_${rowNumber}`}
                                                            type='hidden'
                                                            placeholder='選択してください'
                                                            className='form-control mb-1'
                                                            readOnly
                                                            {...register(`client_id_${rowNumber}`, {
                                                                required: {
                                                                    value: true,
                                                                    message: "*利用者を選択してください。",
                                                                },
                                                            })}
                                                        />
                                                    </div>
                                                    {!deletedRows.includes(rowNumber) && (
                                                        <Box
                                                            className='btn btn-light btn-sm mx-auto'
                                                            sx={{ width: "max-content" }}>
                                                            <Button
                                                                onClick={() => handleClickSearchClientOpen(rowNumber)}>
                                                                利用者を選択する
                                                            </Button>
                                                        </Box>
                                                    )}
                                                </div>
                                                {!deletedRows.includes(rowNumber) && (
                                                    <>
                                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {errors[`client_name_${rowNumber}`] &&
                                                                errors[`client_name_${rowNumber}`]?.message}
                                                        </small>
                                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {report.success
                                                                ? ""
                                                                : report.error_list[`client_name_${rowNumber}`]}
                                                        </small>
                                                    </>
                                                )}
                                            </div>
                                            <div className='form-group mb-4'>
                                                <label className='fw-bold mb-1' htmlFor={`detail_${rowNumber}`}>
                                                    内容
                                                    <RequiredMark />
                                                </label>
                                                <textarea
                                                    id={`detail_${rowNumber}`}
                                                    readOnly={deletedRows.includes(rowNumber)}
                                                    className='form-control mb-1'
                                                    {...register(`detail_${rowNumber}`, {
                                                        required: {
                                                            value: true,
                                                            message: "*内容を入力してください。",
                                                        },
                                                    })}
                                                />
                                                {!deletedRows.includes(rowNumber) && (
                                                    <>
                                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {errors[`detail_${rowNumber}`] &&
                                                                errors[`detail_${rowNumber}`]?.message}
                                                        </small>
                                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                                        <small className='text-danger'>
                                                            {report.success
                                                                ? ""
                                                                : report.error_list[`detail_${rowNumber}`]}
                                                        </small>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            <div className='w-100 mb-5'>
                                <hr />
                                <div className='text-center'>
                                    <button
                                        type='button'
                                        className='btn btn-outline-primary'
                                        onClick={(e) => {
                                            handleOnClickAddInput(e);
                                        }}>
                                        <span className='fw-bold fs-5 px-1'>＋</span>
                                        入力欄を追加する
                                    </button>
                                </div>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                        <Dialog
                            open={searchClientOpen}
                            onClose={handleSearchClientClose}
                            fullWidth={true}
                            maxWidth='md'>
                            <DialogTitle>利用者選択</DialogTitle>
                            <DialogContent>
                                <FreeWordSearch
                                    searchColumns={columns}
                                    data={clientNames}
                                    setSearchedData={setSearchedClientNames}
                                />
                                {/* テーブル部分の定義 */}
                                <DataTable
                                    headers={headers}
                                    rows={clientRows}
                                    handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleSearchClientClose}>閉じる</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportEdit;
