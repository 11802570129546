import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { Button, Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";

import SelectionArea from "@viselect/react";
import BackButton from "../../components/BackButton";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" }
        },
        menuBar: {
            display: "flex",
            //---------------------------issue{No.458} start-----------------------------
            justifyContent: "space-between",
            //---------------------------issue{No.458} end-------------------------------
            width: "100%"
        },
        help: {
            position: "relative",
            lineHeight: "2rem",
            padding: "0 2rem",
            verticalAlign: "middle",
            textDecoration: "underline",
            cursor: "pointer",
            "&::before": {
                content: '"?"',
                position: "absolute",
                top: "50%",
                left: "0",
                transform: "translateY(-50%)",
                width: "1.5rem",
                height: "1.5rem",
                lineHeight: "1.3rem",
                textAlign: "center",
                color: "#999",
                fontWeight: "bold",
                border: "2px solid #999",
                borderRadius: "50%"
            },
            "&:hover": {
                "& $helpWindow": {
                    display: "block",
                    opacity: "1",
                    transition: "opacity 1s"
                }
            }
        },
        helpWindow: {
            position: "absolute",
            top: "2rem",
            right: "0",
            display: "none",
            width: "300px",
            height: "70vh",
            padding: "10px",
            background: "#ddd",
            border: "1px solid #aaa",
            borderRadius: "5px",
            overflowY: "scroll",
            opacity: "0",
            zIndex: "1"
        },
        helpItem: {
            fontWeight: "bold"
        },
        tableLeft: {
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            width: "5%",
            paddingRight: "3px"
        },
        tableRight: {
            width: "93%"
        },
        timeList: {
            height: "1rem",
            lineHeight: "1rem"
        },
        cell: {
            height: "1rem",
            padding: "0 .5rem",
            boxShadow: "0 0 1px 0 #000 inset",
            backgroundClip: "content-box",
            "&:hover": {
                boxShadow: "0 0 1px 1px #f00 inset"
            }
        },
        contentTitle: {
            wordBreak: "break-word",
            lineHeight: "1rem",
            textAlign: "center",
            fontSize: ".9rem",
            color: "#fff",
            textShadow: "1px 1px 2px #000",
            zIndex: "0"
        },
        selection: {
            userSelect: "none",
            overflow: "hidden"
        },

        // backgroundClipを同一要素に別のCSSクラスとして重ねて適用する理由：↓
        // CSSで後から付与されたbackgroundプロパティがある場合、
        // 以前のbackgroundClipプロパティが無効にされるため
        bgColor1: { background: "#3d3dff", backgroundClip: "content-box" },
        bgColor2: { background: "#007b43", backgroundClip: "content-box" },
        bgColor3: { background: "#f8b500", backgroundClip: "content-box" },
        bgColor4: { background: "#e45e32", backgroundClip: "content-box" },
        bgColor5: { background: "#d3381c", backgroundClip: "content-box" },
        bgColor6: { background: "#68be8d", backgroundClip: "content-box" },
        bgColor7: { background: "#65318e", backgroundClip: "content-box" },
        bgColor8: { background: "#0095d9", backgroundClip: "content-box" },
        bgColor9: { background: "#ec6d71", backgroundClip: "content-box" },
        bgColor10: { background: "#ebd842", backgroundClip: "content-box" },
        bgColor11: { background: "#9e3d3f", backgroundClip: "content-box" }
    })
);

const day = ["月", "火", "水", "木", "金", "土", "日・祝"];

const timeList = {
    1: "5:00",
    2: "",
    3: "6:00",
    4: "",
    5: "7:00",
    6: "",
    7: "8:00",
    8: "",
    9: "9:00",
    10: "",
    11: "10:00",
    12: "",
    13: "11:00",
    14: "",
    15: "12:00",
    16: "",
    17: "13:00",
    18: "",
    19: "14:00",
    20: "",
    21: "15:00",
    22: "",
    23: "16:00",
    24: "",
    25: "17:00",
    26: "",
    27: "18:00",
    28: "",
    29: "19:00",
    30: "",
    31: "20:00",
    32: "",
    33: "21:00",
    34: "",
    35: "22:00",
    36: "",
    37: "23:00",
    38: "",
    39: "0:00",
    40: "",
    41: "1:00",
    42: "",
    43: "2:00",
    44: "",
    45: "3:00",
    46: "",
    47: "4:00",
    48: ""
};

// timeFieldステートの初期値作成用のテンプレート
const cellList = {
    1: { title: "", content: "" },
    2: { title: "", content: "" },
    3: { title: "", content: "" },
    4: { title: "", content: "" },
    5: { title: "", content: "" },
    6: { title: "", content: "" },
    7: { title: "", content: "" },
    8: { title: "", content: "" },
    9: { title: "", content: "" },
    10: { title: "", content: "" },
    11: { title: "", content: "" },
    12: { title: "", content: "" },
    13: { title: "", content: "" },
    14: { title: "", content: "" },
    15: { title: "", content: "" },
    16: { title: "", content: "" },
    17: { title: "", content: "" },
    18: { title: "", content: "" },
    19: { title: "", content: "" },
    20: { title: "", content: "" },
    21: { title: "", content: "" },
    22: { title: "", content: "" },
    23: { title: "", content: "" },
    24: { title: "", content: "" },
    25: { title: "", content: "" },
    26: { title: "", content: "" },
    27: { title: "", content: "" },
    28: { title: "", content: "" },
    29: { title: "", content: "" },
    30: { title: "", content: "" },
    31: { title: "", content: "" },
    32: { title: "", content: "" },
    33: { title: "", content: "" },
    34: { title: "", content: "" },
    35: { title: "", content: "" },
    36: { title: "", content: "" },
    37: { title: "", content: "" },
    38: { title: "", content: "" },
    39: { title: "", content: "" },
    40: { title: "", content: "" },
    41: { title: "", content: "" },
    42: { title: "", content: "" },
    43: { title: "", content: "" },
    44: { title: "", content: "" },
    45: { title: "", content: "" },
    46: { title: "", content: "" },
    47: { title: "", content: "" },
    48: { title: "", content: "" }
};

// timeFieldステートの初期値を作成する関数
// オブジェクトの関連付け（参照渡し）が残らないように処理
const lazyInitTimeField = () => {
    let initTimeField = {};
    for (let ii = 1; ii <= 7; ii++) {
        const newColumn = JSON.parse(JSON.stringify(cellList));
        initTimeField[ii] = newColumn;
    }
    return initTimeField;
};

//---------------------------issue{No.458} start-----------------------------
// 初期状態
const initProposedPlan_1 = { success: true };

// アセスメントの現在の生活から取得したデータを管理するステートの初期状態
const initAssessment_2 = {
    data: []
};

const proposedPlanColumns = [
    "company_id",
    "office_id",
    "user_id",
    "client_id",
    "proposed_plans_id",
    "day_week",
    "schedule_start_time",
    "schedule_end_time",
    "content"
];
//---------------------------issue{No.458} end-------------------------------

// Laravel側に送るデータを作成する際に使用するフォーマット
const sendDataFormat = {
    company_id: "",
    office_id: null,
    user_id: "",
    client_id: null,
    proposed_plans_id: null,
    day_week: null,
    schedule_start_time: null,
    schedule_end_time: null,
    content: ""
};

function ProposedPlan_2Register() {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 表示対象の利用者のidと、サービス等利用計画案情報のid
    const { client, id } = useParams();

    // 登録先のサービス等利用計画案情報を表示するためのステート
    const [proposedPlan_1, setProposedPlan_1] = useState(initProposedPlan_1);

    //---------------------------issue{No.458} start-----------------------------
    // アセスメントの現在の生活から引用したデータを管理するステート
    const [assessment_2, setAssessment_2] = useState(initAssessment_2);

    // 引用の準備の状態
    const [citing, setCiting] = useState("");
    //---------------------------issue{No.458} end-------------------------------

    // React上で表示する各セルの状態を管理するためのステート
    const [timeField, setTimeField] = useState(lazyInitTimeField);

    // イベントハンドラ内で使用するためのRefオブジェクト
    const timeFieldRef = useRef(null);
    timeFieldRef.current = timeField;

    const [clientData, setClientData] = useState({});

    // 登録する際に実際に送信するデータを保持するRefオブジェクト
    const proposedPlan_2 = useRef([]);

    // 範囲選択した際に選択済みのセル要素を格納するRefオブジェクト
    const selectedCells = useRef([]);

    // sendDataFormatを使い作成した、選択済みのセルに関するデータを
    // 一時時的に保持するステート
    const [sendData, setSendData] = useState([]);

    // makeStyleで作成したセルの色付け用のCSSクラスの呼び出しに使う数値を保持するRefオブジェクト
    // 変数にしてもいいかもしれないが・・・
    const bgColor = useRef(1);

    // makeStyleで作成済みのセルの色に関するCSSクラスを
    // 作成済みの内容のcontentの値と紐づけて保管するRefオブジェクト
    const colorClasses = useRef({});

    // セル部分を右クリック、右ドラッグの際に、範囲選択の処理を行なわないようにするための変数
    let eventStop = false;

    // ここから、API通信、ページ遷移などの処理を記入する

    // 登録先のサービス等利用計画案情報を取得しステート proposedPlan_1 にセットする
    const getProposedPlan_1Data = async (id) => {
        const url = `proposed_plans_1/show/${id}`;
        const res = await api.get(url);
        //---------------------------issue{No.458} start-----------------------------
        if (res.data.success) {
            setProposedPlan_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success
            });
        }
        //---------------------------issue{No.458} end-------------------------------
    };

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async (clientId) => {
        const url = `proposed_plans_2/client/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id
            });
            // データのひな形に値を追加する
            sendDataFormat.company_id = clientResult.company_id;
            sendDataFormat.office_id = clientResult.office_id;
            sendDataFormat.user_id = clientResult.user_id;
            sendDataFormat.client_id = clientResult.client_id;
            sendDataFormat.proposed_plans_id = id;
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    //---------------------------issue{No.458} start-----------------------------
    const getAssessment_2InPrevious = async (clientId, proposedPlanDate) => {
        const url = `assessments_2_get_previous/${clientId}/${proposedPlanDate}`;
        const res = await api.get(url);
        if (res.data.success) {
            setAssessment_2({
                data: [...res.data.result.data],
                date: res.data.result.date,
                error_list: null,
                success: res.data.success
            });
        }
    };

    const handleOnClickCitingData = async (e) => {
        e.stopPropagation();

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "すでに入力されている内容はリセットされます。続けますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        if (assessment_2.data.length > 1 && citing === "ready") {
            makeTable();

            setCiting("done");
        }
    };

    const makeTable = async () => {
        const { data } = assessment_2;

        // リセット後のセルの状態を取得する
        const newTimeField = await timeFieldReset();

        for (let item of data) {
            const content = item.content;
            const dayWeek = item.day_week;
            const startTime = item.schedule_start_time;
            const endTime = item.schedule_end_time;
            for (let ii = startTime; ii <= endTime; ii++) {
                if (ii === item.schedule_start_time) {
                    newTimeField[dayWeek][startTime].title = content;
                }
                newTimeField[dayWeek][ii].content = content;
            }
            drawColor(content);
        }

        // 取得したデータの内、使わないカラムを削除してRefオブジェクトを更新
        const newProposedPlan_2 = data.map((item) => {
            for (let key in item) {
                if (!proposedPlanColumns.includes(key)) {
                    delete item[key];
                }
            }

            // カラムを追加するRegisterコンポーネントだけの特記事項
            item["proposed_plans_id"] = Number(id);

            return JSON.parse(JSON.stringify(item));
        });

        proposedPlan_2.current = newProposedPlan_2;

        setTimeField(newTimeField);
    };

    const timeFieldReset = async () => {
        // セルの状態を管理するステートを複製する
        const newTimeField = JSON.parse(JSON.stringify(timeFieldRef.current));

        for (let column in newTimeField) {
            for (let row in newTimeField[column]) {
                newTimeField[column][row].title = "";
                newTimeField[column][row].content = "";
            }
        }

        // setTimeField(newTimeField);
        proposedPlan_2.current = [];
        selectedCells.current = [];
        setSendData([]);
        bgColor.current = 1;
        colorClasses.current = {};
        setCiting("ready");

        return newTimeField;
    };
    //---------------------------issue{No.458} end-------------------------------

    const handleOnClickReset = async (e) => {
        e.stopPropagation();

        if (proposedPlan_2.current.length === 0) {
            swal({
                icon: "warning",
                text: "内容が作成されていません"
            });
            return;
        }

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "warning",
            title: "確認",
            text: "作成した内容と変更をすべて破棄して、初期状態に戻します。よろしいですか？",
            buttons: {
                cancel: "キャンセル",
                confirm: "OK"
            }
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // セルの状態を管理するステートを複製する
        const newTimeField = JSON.parse(JSON.stringify(timeField));

        for (let column in newTimeField) {
            for (let row in newTimeField[column]) {
                newTimeField[column][row].title = "";
                newTimeField[column][row].content = "";
            }
        }

        setTimeField(newTimeField);
        proposedPlan_2.current = [];
        selectedCells.current = [];
        setSendData([]);
        bgColor.current = 1;
        colorClasses.current = {};

        //---------------------------issue{No.458} start-----------------------------
        setCiting("ready");
        //---------------------------issue{No.458} end-------------------------------
    };

    const handleOnClickSubmit = async (e) => {
        e.stopPropagation();

        if (proposedPlan_2.current.length === 0) {
            swal({
                icon: "warning",
                text: "内容が作成されていません"
            });
            return;
        }

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "この内容で登録します。よろしいですか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        const proposedPlan_2Data = {
            data: [...proposedPlan_2.current]
        };

        //登録
        const url = "proposed_plans_2";
        const res = await api.post(url, proposedPlan_2Data);
        if (res.data.success) {
            swal(res.data.message, res.data.result, "success").then(() => {
                navigate(`/proposed_plan_1/${client}`);
            });
        }
    };

    // ここまで、API通信、ページ遷移などの処理を記入する

    // makeContent関数について
    // 選択したセルに内容を登録するためのフォームを表示する関数
    // 発動する状況
    // セルを選択後、選択済みのセル上で右クリックを行なった際に
    // 選択済みのセルを元に、その範囲などからsendDataステート（contentなし）が更新される
    // そのsendDataステートの更新によりuseEffectから、以下の関数が実行される
    const makeContent = async () => {
        // のちに呼び出されるswal内のtextareaにfocusをスタンバイする
        setTimeout(() => {
            document.querySelector(".swal-content__textarea").focus();
        }, 500);
        // 以下、内容を入力するtextarea、選択範囲をクリアするボタンなどを表示
        await swal({
            text: "内容を入力して作成します",
            content: {
                element: "textarea"
            },
            buttons: {
                catch: {
                    text: "選択範囲をクリア",
                    value: "clear",
                    visible: true,
                    className:
                        "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                    closeModal: true
                },
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true
                },
                confirm: {
                    text: "作成する",
                    value: true,
                    visible: true,
                    className: "",
                    closeModal: true
                }
            }
        }).then((value) => {
            if (value === "clear") {
                // 選択範囲をクリアする
                selectedClear();
                return;
            }
            if (value === "cancel") {
                return;
            }
            if (value) {
                // textareaの値を取得し、定数に宣言
                const inputValue = document.querySelector(".swal-content__textarea").value;
                // 以下、簡易的なバリデーション
                if (!inputValue.trim()) {
                    // 値が空の文字列の場合
                    swal("入力されていません", { icon: "warning" });
                    return;
                } else {
                    // 入力された値をさらに次の処理へ
                    makeContentSubmit(inputValue.trim());
                }
            }
        });
    };

    // makeContentによって、submitされた値と、sendDataステートを元に
    // 送信用のデータを作成したり、React上で表示するためのさまざまな処理を行う
    const makeContentSubmit = (content) => {
        // sendDataステートから、配列を複製する
        const makeData = sendData.concat();
        for (let index in makeData) {
            // 値を配列内のオブジェクトに書き込む
            makeData[index].content = content;
        }

        // 送信用データを複製する
        const beforeData = proposedPlan_2.current.concat();
        // 送信用データの元データに、今回作成された新データを重複しない形で結合する
        const newData = Array.from(new Set([...beforeData, ...makeData]));
        // Refオブジェクトを更新する
        proposedPlan_2.current = newData;

        // sendDataステートに保持されているデータ一つずつを元に
        // 次の関数を複数回実行する
        for (let index in sendData) {
            const column = sendData[index].day_week;
            const row = sendData[index].schedule_start_time;
            editContent(content, [column, row]);
        }

        // 選択済みのセルの塗りつぶしを解除
        selectedClear();
        // 今回作成を行ったsendDataステートを初期化
        setSendData([]);
    };

    // 選択時に背景色を指定した要素のbootstrapクラスを外す
    const selectedClear = () => {
        const arrayLength = selectedCells.current.length;
        for (let ii = 0; ii < arrayLength; ii++) {
            selectedCells.current[0].classList.remove("bg-info");
        }

        // 選択範囲を保持していたRefオブジェクトを初期化
        selectedCells.current = [];
    };

    // 作成された内容が新規の内容（content）かを判断し、新規の場合にそのcontentの値に
    // 背景色用のCSSクラス名を結び付けたオブジェクトを新たに作成し追加する
    const drawColor = (content) => {
        if (!colorClasses.current[content]) {
            // オブジェクトの複製
            const beforeColorClasses = JSON.parse(JSON.stringify(colorClasses.current));
            // 新しいプロパティを作成
            const colorClass = { [content]: `bgColor${bgColor.current}` };
            // オブジェクトの結合
            const newColorClasses = { ...beforeColorClasses, ...colorClass };
            // Refオブジェクトに値を上書きして更新
            colorClasses.current = newColorClasses;

            // 次に使用する背景色用のCSSクラスのナンバーを更新
            if (bgColor.current == 11) {
                bgColor.current = 1;
            } else {
                bgColor.current++;
            }
        }
    };

    // makeContentSubmit関数、またはchangeContent関数に続いて処理を行う関数
    const editContent = (content, targetCoordinate) => {
        // 今回の入力内容を元に背景色用のCSSクラスを用意
        drawColor(content);

        // 引数として受け取った座標からその座標を含むまとまりについてのデータを取得する
        // (送信用データ配列の)index,week,startTime,endTimeというプロパティを返す
        const targetData = getTargetData(targetCoordinate[0], targetCoordinate[1]);

        // 対象となるセルの座標を全てを格納する
        let targetCells = {};
        // セルの行番号を一時的に保持する変数
        let newArray = [];
        // 曜日（列）内に同じ内容での飛び地があった場合に、その飛び地についてのデータのindexを格納する
        let sameContentDataIndex = [];
        // 曜日（列）の内、今回扱うcontentと同一の内容となっているセルの行番号の配列を作成する
        for (let ii = 1; ii <= 48; ii++) {
            if (ii >= targetData.startTime && ii <= targetData.endTime) {
                // 編集対象となったデータの範囲では全ての行番号を配列に格納
                newArray.push(ii);
            } else if (timeFieldRef.current[targetData.week][ii].content == content) {
                // 同一のcontentを列内に見つけたとき、そのデータのindexをsameContentDataIndex配列に追加
                const sameContentData = getTargetData(targetData.week, ii);
                if (!sameContentDataIndex.includes(sameContentData.index)) {
                    sameContentDataIndex.push(sameContentData.index);
                }
                // 行番号を配列に追加
                newArray.push(ii);
            }
        }
        // 上述の処理の結果をもとにオブジェクトを更新
        targetCells = { ...targetCells, [targetData.week]: newArray };

        // 以下に登場するmakeSendData関数では、座標の一覧から、まとまりを見出し
        // それぞれのまとまり毎にsendDataFormatをもとにしたデータの型枠を作成する
        const newSendData = makeSendData(targetCells);

        // makeContentSubmit関数の処理と同じ
        // contentの値を配列内のオブジェクトに書き込む
        for (let index in newSendData) {
            newSendData[index].content = content;
        }

        // 送信用データから配列を複製し、今回変更の対象となったデータをあらかじめ削除する
        const beforeData = proposedPlan_2.current.concat();
        // 先ず、今回この関数に侵入するきっかけとなった範囲のデータを削除する
        delete beforeData[targetData.index];
        // 同一のcontentのデータも同様に削除する
        for (const index of sameContentDataIndex) {
            delete beforeData[index];
        }

        // 新たに作成し直された配列と、もとの送信用データを結合して、配列を新たに構成する
        const mergeData = [...beforeData, ...newSendData];

        // 削除済みのプロパティを完全に削除して、配列を詰める
        const newData = mergeData.filter(Boolean);

        // Refオブジェクトに上書き
        proposedPlan_2.current = newData;

        // 以下、表示用の調整
        // セルの状態を管理するステートを複製する
        const newTimeField = JSON.parse(JSON.stringify(timeFieldRef.current));

        // 内容を書き換える対象のセルのプロパティの値を処理
        for (let column in targetCells) {
            for (const row of targetCells[column]) {
                newTimeField[column][row].title = "";
                newTimeField[column][row].content = content;
            }
        }

        // 送信用データとして新たにまとまったデータの先頭となったセルに
        // タイトルとしてcontentの値を書き込む
        for (let index in newSendData) {
            const column = newSendData[index].day_week;
            const startTime = newSendData[index].schedule_start_time;
            newTimeField[column][startTime].title = content;
        }

        // ステートを更新
        setTimeField((timeField) => ({ ...timeField, [targetData.week]: newTimeField[targetData.week] }));
    };

    // 同一の内容を含むデータを対象に一括で処理を行う処理
    const editAllContent = (content, newContent) => {
        const items = {};
        for (let ii = 1; ii <= 7; ii++) {
            const rows = Object.keys(timeFieldRef.current[ii]).filter((row) => {
                if (timeFieldRef.current[ii][row].content === content) {
                    return row;
                } else {
                    return false;
                }
            });
            items[ii] = rows;
        }

        const targetData = makeSendData(items);

        // targetDataに保持されているデータ一つずつを元に
        // 次の関数を複数回実行する
        for (let index in targetData) {
            const column = targetData[index].day_week;
            const row = targetData[index].schedule_start_time;
            editContent(newContent, [column, row]);
        }
    };

    // ある一つのセルの座標から、そのセルを範囲に含むデータを取得する関数
    const getTargetData = (column, row) => {
        const data = proposedPlan_2.current.concat();

        let proposedPlan_2DataIndex = 0;
        let week = "";
        let startTime = "";
        let endTime = "";

        for (let index in data) {
            if (data[index]) {
                if (
                    column == data[index].day_week &&
                    row >= data[index].schedule_start_time &&
                    row <= data[index].schedule_end_time
                ) {
                    week = data[index].day_week;
                    startTime = data[index].schedule_start_time;
                    endTime = data[index].schedule_end_time;
                    proposedPlan_2DataIndex = index;
                }
            }
        }

        const result = {
            index: proposedPlan_2DataIndex,
            week: week,
            startTime: startTime,
            endTime: endTime
        };

        return result;
    };

    // 以下、作成済みの内容、およびデータを編集する関数
    // 発生条件は各セルに登録した右クリック（contextmenu）イベントの発火による
    const changeContent = async (e) => {
        // まだ空欄のセル上での右クリックのときは関数を抜ける
        if (e.target.classList.contains("selectable")) {
            return;
        }

        // 右クリックされたセルの座標をIDから取得
        const targetCoordinate = e.target.id.split("-");

        // 右クリックされた内容の情報を取得
        const targetData = getTargetData(targetCoordinate[0], targetCoordinate[1]);

        // 右クリックで選択された内容のcontentの値を宣言する
        const targetContent = proposedPlan_2.current[targetData.index].content;

        // 初期値
        confirm = false;

        // 以下、右クリックした対象に行う処理を選択する
        await swal({
            icon: "info",
            text: "この内容を変更、または削除",
            buttons: {
                cancel: "キャンセル",
                catch: {
                    text: "削除",
                    value: "delete",
                    className: "btn btn-outline-primary d-block lh-lg px-4"
                },
                defeat: {
                    text: "変更",
                    value: "change"
                }
            }
        }).then((value) => {
            switch (value) {
                case "change":
                    // のちに呼び出されるswal内のtextareaにfocusをスタンバイする
                    setTimeout(() => {
                        const textareaElement = document.querySelector(".swal-content__textarea");
                        textareaElement.focus();
                        // さらにtextarea内に、現在のcontentの値を表示
                        textareaElement.innerHTML = targetContent;
                        // また、内容のテキストを選択済みの状態にする
                        textareaElement.select();
                    }, 500);

                    // 内容を変更するためのtextareaを表示
                    swal("変更する内容を入力してください", {
                        content: {
                            element: "textarea"
                        },
                        buttons: {
                            cancel: {
                                text: "キャンセル",
                                value: "cancel",
                                visible: true,
                                className: "",
                                closeModal: true
                            },
                            confirm: {
                                text: "変更する",
                                value: true,
                                visible: true,
                                className: "",
                                closeModal: true
                            }
                        }
                    }).then((value) => {
                        if (value === "cancel") {
                            return;
                        } else if (value) {
                            const inputValue = document.querySelector(".swal-content__textarea").value;
                            // 簡易的なバリデーション
                            if (!inputValue.trim()) {
                                swal("入力されていません", { icon: "warning" });
                                return;
                            } else if (targetContent === inputValue.trim()) {
                                swal("内容が変更されていません", { icon: "warning" });
                                return;
                            } else {
                                // 変更されたcontentを元に以下の関数を実行
                                editContent(inputValue.trim(), targetCoordinate);

                                // 他の同一の内容すべてに同じ変更を加えるか
                                swal({
                                    icon: "info",
                                    text: "他の同じ内容にも変更を適用しますか？",
                                    buttons: {
                                        confirm: "適用する",
                                        cancel: "スキップ"
                                    }
                                }).then((value) => {
                                    if (!value) {
                                        return;
                                    } else {
                                        editAllContent(targetContent, inputValue.trim());
                                    }
                                });
                            }
                        }
                    });
                    break;

                case "delete":
                    confirm = true;
                    break;

                default:
                    break;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // 以下、上述の分岐の中で、削除選択時のみ実行
        // 再度、初期値を設定
        confirm = false;

        // 削除確認のアラートを表示
        await swal({
            icon: "warning",
            title: "",
            text: "この内容一件を削除しますか？",
            buttons: {
                cancel: "キャンセル",
                confirm: "OK"
            }
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // 送信用データを複製
        const newData = proposedPlan_2.current.concat();
        // 対象のデータをひとつだけ取り除く（削除）
        newData.splice(targetData.index, 1);

        // Refオブジェクトを上書きして更新
        proposedPlan_2.current = newData;

        // ステートを複製
        const newTimeField = JSON.parse(JSON.stringify(timeFieldRef.current));
        // 対象だった内容の各プロパティを初期化
        for (let ii = targetData.startTime; ii <= targetData.endTime; ii++) {
            newTimeField[targetData.week][ii].title = "";
            newTimeField[targetData.week][ii].content = "";
        }
        // ステートを更新
        setTimeField(newTimeField);
    };

    // この関数は選択されたセルの座標一覧から、連続、または飛び地となっているまとまり毎に
    // sendDataFormatから、データの型枠を作成する関数である
    const makeSendData = (items) => {
        // 作成過程のデータを追加する配列
        let dataArray = [];
        // ここでのcolumnは列番号を引き出すindexとなっている
        // 余裕があれば for of に書き直したい
        for (let column in items) {
            const item = items[column];
            // 飛び地かどうかの判定に使う
            let beforeRow = null;
            // sendDataFormatのschedule_start_timeの値になる
            let startTime = null;
            // sendDataFormatのschedule_end_timeの値になる
            let endTime = null;
            // ここでのrowは行番号を引き出すindexとなっている
            // 余裕があれば for of に書き直したい
            for (let row in item) {
                if (item.length - 1 == row) {
                    // 同一列の内、今回扱う行が最後の処理になる場合
                    if (!beforeRow) {
                        // 同一列内で一つのみだった場合
                        // 始点を代入し次の else if を飛ばして次の処理へ
                        startTime = item[row];
                    } else if (beforeRow != item[row] - 1) {
                        // 今回の行が最後になるが、前回判定したセルと連続してつながっていない場合
                        // 先に前回判定までの範囲でデータを作成
                        endTime = beforeRow;

                        const makeData = { ...sendDataFormat };
                        makeData.day_week = Number(column);
                        makeData.schedule_start_time = Number(startTime);
                        makeData.schedule_end_time = Number(endTime);

                        dataArray = [...dataArray, { ...makeData }];

                        // ここで、今回判定したセルの行番号を始点とし、次の処理へ
                        startTime = item[row];
                    }
                    // ここでは、同一列内の最後となった一行分のセルについてのデータを作成する
                    endTime = item[row];

                    const makeData = { ...sendDataFormat };
                    makeData.day_week = Number(column);
                    makeData.schedule_start_time = Number(startTime);
                    makeData.schedule_end_time = Number(endTime);

                    dataArray = [...dataArray, { ...makeData }];
                } else if (!beforeRow) {
                    // 同一列内ので初登場であり、続きがある場合
                    startTime = item[row];
                    beforeRow = item[row];
                } else if (beforeRow == item[row] - 1) {
                    // 前回判定したセルと連続して続いている場合
                    beforeRow = item[row];
                } else if (beforeRow != item[row] - 1) {
                    // 連続が途切れた場合
                    endTime = beforeRow;

                    const makeData = { ...sendDataFormat };
                    makeData.day_week = Number(column);
                    makeData.schedule_start_time = Number(startTime);
                    makeData.schedule_end_time = Number(endTime);

                    dataArray = [...dataArray, { ...makeData }];

                    startTime = item[row];
                    beforeRow = item[row];
                }
            }
        }
        return dataArray;
    };

    // 以下より、viselect/reactの機能を制御、実行する
    // マウスのクリックイベント（右クリックも含む）により発火
    // if内は右クリック限定の処理で
    // あとは基本、後に続くonMoveなどに連鎖する
    const onStart = ({ event, selection }) => {
        if (event.button === 2 || event.buttons === 2) {
            // 右クリックだった場合クリック・ドラッグでの範囲選択を一時的に無効にする
            eventStop = true;
            // 以下、右クリックが、範囲選択されたセル上で行われた場合
            if (event.target.classList.contains("bg-info")) {
                if (sendData.length === 0) {
                    const selected = document.getElementsByClassName("bg-info");
                    // 選択範囲の座標を配列化する
                    let items = { 1: [], 2: [], 3: [], 4: [], 5: [], 6: [], 7: [] };
                    for (const el of selected) {
                        const elementId = el.id;
                        const coordinate = elementId.split("-");
                        items[coordinate[0]].push(coordinate[1]);
                    }

                    // 座標の配列を元に、sendDataステートの中身を作成する
                    const data = makeSendData(items);

                    // 選択範囲の要素をRefオブジェクトに格納
                    selectedCells.current = selected;
                    // sendDataステートに値をセットし、useEffectに続く
                    setSendData(data);
                }
            }
        }
        // 初期化
        selection.clearSelection();
    };

    // 以下、マウスドラッグ時の処理
    const onMove = ({
        store: {
            changed: { added, removed }
        }
    }) => {
        // 右クリックから開始されている場合は処理に入らない
        if (!eventStop) {
            // bootstrapクラスを付与することで、選択済みセルの判断や色付けを行っている
            for (const el of added) {
                if (el.classList.contains("bg-info")) {
                    el.classList.remove("bg-info");
                } else {
                    el.classList.add("bg-info");
                }
            }
            for (const el of removed) {
                el.classList.remove("bg-info");
            }
        }
    };

    // マウスドラッグ停止時の処理
    const onStop = ({ selection }) => {
        // 初期化
        eventStop = false;
        // 選択の停止
        selection.cancel();
    };

    // 読み込み時の副作用フック
    useEffect(() => {
        // 登録先のサービス等利用計画案情報を取得する
        getProposedPlan_1Data(id);

        // クライアントデータの取得
        getClientData(id);

        // JSXがまだ読み込まれていない時に処理を抜ける
        document.getElementById("contextmenu_off").oncontextmenu = function () {
            return false;
        };

        // 全セルの要素を取得
        const cells = document.getElementsByClassName("bg-gradient");

        // 各セルに右クリックで発火するイベントを追加していく
        for (const cell of cells) {
            cell.addEventListener("contextmenu", (e) => {
                // この関数は、作成済みの予定の上で右クリックした際に編集する機能
                changeContent(e);
            });
        }
    }, []);

    //---------------------------issue{No.458} start-----------------------------
    useEffect(() => {
        if (Object.keys(proposedPlan_1).length > 1) {
            getAssessment_2InPrevious(client, proposedPlan_1.date);
            setCiting("ready");
        }
    }, [proposedPlan_1]);
    //---------------------------issue{No.458} end-------------------------------

    // 範囲選択し、新規の内容が作成される段階で実行される副作用フック
    useEffect(() => {
        if (sendData.length > 0) {
            // ステートが準備されてから、以下の関数を実行する
            // swalで内容入力、および範囲選択のクリアを表示する
            makeContent();
        }
    }, [sendData]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>
                            サービス等利用計画案 - 【週間計画表】作成（
                            <span className={classes.clientName}>
                                {proposedPlan_1.client_name}|{proposedPlan_1.date}
                            </span>
                            ）
                        </h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div id='contextmenu_off' className='card-body'>
                        <div className={classes.menuBar}>
                            {assessment_2.data.length > 1 && (
                                <>
                                    {citing === "ready" && (
                                        <Tooltip
                                            title={`アセスメント（${assessment_2.date}）で作成した【現在の生活】の内容を以下の入力欄に引用します。`}
                                            placement='bottom'
                                            arrow>
                                            <Button
                                                type='button'
                                                variant='contained'
                                                color='primary'
                                                onClick={(e) => {
                                                    handleOnClickCitingData(e);
                                                }}>
                                                アセスメントで作成した【現在の生活】の内容を引用する
                                            </Button>
                                        </Tooltip>
                                    )}
                                    {citing === "done" && (
                                        <button className='d-block btn btn-outline-secondary' disabled>
                                            アセスメント（{assessment_2.date}）で作成した【現在の生活】の内容を引用済み
                                        </button>
                                    )}
                                </>
                            )}
                            <div id='help' className={`ms-auto ${classes.help}`}>
                                使い方
                                <div className={`${classes.helpWindow} helpWindow`}>
                                    <ul>
                                        <li className={classes.helpList}>
                                            <span className={classes.helpItem}>範囲を選択する</span>
                                            <br />
                                            <p className={classes.helpText}>
                                                セルをクリック、またはドラッグで範囲を選択します。
                                            </p>
                                        </li>
                                        <li className={classes.helpList}>
                                            <span className={classes.helpItem}>内容を書き込む</span>
                                            <br />
                                            <p className={classes.helpText}>
                                                選択した範囲で右クリックします。表示されるフォームに沿って内容を作成します。
                                            </p>
                                        </li>
                                        <li className={classes.helpList}>
                                            <span className={classes.helpItem}>選択範囲をまとめて解除する</span>
                                            <br />
                                            <p className={classes.helpText}>
                                                選択した範囲で右クリックを押し、メニューを表示します。
                                                <br />
                                                左下にある「選択範囲のクリア」ボタンを押下します。
                                            </p>
                                        </li>
                                        <li className={classes.helpList}>
                                            <span className={classes.helpItem}>作成済みの内容を変更する</span>
                                            <br />
                                            <p className={classes.helpText}>
                                                編集を行うセル上で右クリックし、表示されるメニューから「変更」ボタンを押下します。
                                                <br />
                                                表示されるフォームに沿って作業を進めます。
                                                <br />
                                                変更後に表示されるダイアログ画面にて、同一の内容に対して一括で変更を適用するか選択します。
                                                <br />
                                                （内容変更のみ可能です。時間の変更を行う場合は、削除してから作り直します。）
                                            </p>
                                        </li>
                                        <li className={classes.helpList}>
                                            <span className={classes.helpItem}>作成済みの内容を削除する</span>
                                            <br />
                                            <p className={classes.helpText}>
                                                編集を行うセル上で右クリックし、表示されるメニューから「削除」ボタンを押下します。
                                            </p>
                                        </li>
                                        <li className={classes.helpList}>
                                            <span className={classes.helpItem}>作成した内容を登録する</span>
                                            <br />
                                            <p className={classes.helpText}>
                                                内容をお確かめの上、画面下部の「この内容で登録する」ボタンを押下します。
                                            </p>
                                        </li>
                                        <li className={classes.helpList}>
                                            <span className={classes.helpItem}>初期状態に戻す</span>
                                            <br />
                                            <p className={classes.helpText}>
                                                画面右上の「リセット」ボタンを押下し、作成した内容と変更をすべて破棄して初期状態に戻します。
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <Tooltip
                                title='作成した内容と変更をすべて破棄して、初期状態に戻します。'
                                placement='bottom-start'
                                arrow>
                                <Button variant='contained' size='small' onClick={(e) => handleOnClickReset(e)}>
                                    リセット
                                </Button>
                            </Tooltip>
                        </div>
                        <h3 className='text-center p-3'>【週間計画表】</h3>
                        <div className='w-100 d-flex'>
                            <div className={classes.tableLeft}>
                                <div className='fs-6 pt-2 pb-3 mb-1'>時刻</div>
                                {Object.keys(timeList).map((row) => (
                                    <div key={row} className={classes.timeList}>
                                        {timeList[row]}
                                    </div>
                                ))}
                            </div>
                            <div className={classes.tableRight}>
                                <div className='row g-0 d-flex'>
                                    {day.map((day, index) => (
                                        <h4 key={index} className='col lh-lg m-0 text-center fw-bold'>
                                            {day}
                                        </h4>
                                    ))}
                                </div>
                                <SelectionArea
                                    className={classes.selection}
                                    onStart={onStart}
                                    onMove={onMove}
                                    onStop={onStop}
                                    selectables='.selectable'>
                                    {Object.keys(timeField).length > 0 &&
                                        Object.keys(timeField[1]).map((row) => {
                                            return (
                                                <div key={row} className='row g-0'>
                                                    {Object.keys(timeField).map((column) => {
                                                        return (
                                                            <div
                                                                key={column}
                                                                id={`${column}-${row}`}
                                                                className={`col ${
                                                                    timeField[column][row].content
                                                                        ? classes[
                                                                              colorClasses.current[
                                                                                  timeField[column][row].content
                                                                              ]
                                                                          ]
                                                                        : ""
                                                                } bg-gradient ${classes.cell} ${
                                                                    timeField[column][row].title
                                                                        ? classes.contentTitle
                                                                        : ""
                                                                } ${
                                                                    !timeField[column][row].content ? "selectable" : ""
                                                                }`}
                                                                data-key={`${column}-${row}`}>
                                                                {(() => {
                                                                    if (timeField[column][row].title) {
                                                                        const title = timeField[column][row].title;
                                                                        const texts = title
                                                                            .split(/(\n)/)
                                                                            .map((item, index) => {
                                                                                return (
                                                                                    <React.Fragment key={index}>
                                                                                        {item.match(/\n/) ? (
                                                                                            <br />
                                                                                        ) : (
                                                                                            item
                                                                                        )}
                                                                                    </React.Fragment>
                                                                                );
                                                                            });
                                                                        return <React.Fragment>{texts}</React.Fragment>;
                                                                    }
                                                                })()}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            );
                                        })}
                                </SelectionArea>
                            </div>
                        </div>
                    </div>
                    <div className='text-center my-4'>
                        <Button
                            variant='contained'
                            color='primary'
                            size='large'
                            onClick={(e) => handleOnClickSubmit(e)}>
                            この内容で登録する
                        </Button>
                    </div>
                    <div className='card-footer'>
                        <BackButton />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProposedPlan_2Register;
