import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import { Box, Button, Tooltip, Select, MenuItem, Checkbox, FormControlLabel, Collapse, Zoom } from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";
import formatSupportLevelListForEdit from "../../common/formatSupportLevelListForEdit";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        figure: {
            marginBottom: "0!important",
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center",
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px",
        },
        previewWrapper: {
            position: "relative",
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
        },
        preview: {
            maxWidth: "238px",
            maxHeight: "238px",
        },
        crossWrapper: {
            position: "absolute",
            top: "5px",
            right: "5px",
            width: "20px",
            height: "20px",
            borderRadius: "3px",
            backgroundColor: "#f9f9f9",
        },
        crossWrapper2: {
            position: "relative",
            width: "16px",
            height: "16px",
            margin: "2px",
        },
        cross: {
            position: "absolute",
            top: "0",
            right: "0",
            width: "100%",
            height: "3px",
            borderRadius: "3px",
            transform: "translate(0,200%) rotate(45deg)",
            backgroundColor: "#933",
        },
        cross2: {
            position: "absolute",
            top: "0",
            right: "0",
            width: "100%",
            height: "3px",
            borderRadius: "3px",
            transform: "translate(0,200%) rotate(-45deg)",
            backgroundColor: "#933",
        },
        imgDeleteButton: {
            padding: "6px 16px",
            border: "none",
            borderRadius: "4px",
            color: "#fff",
            backgroundColor: "#d40",
        },
    })
);

// 初期状態
const initAssessment_1 = { success: true };

//---------------------------issue{No.513} start-----------------------------
// プルダウンメニュー選択肢用の各データに応じた値を配列で用意
const residence_types = { 1: "単身", 2: "同居有", 3: "GH", 4: "入所施設", 5: "医療機関", 6: "その他" };
const genders = { 1: "男性", 2: "女性" };
const disability_level_1_list = { 1: "1級", 2: "2級", 3: "3級", 4: "4級", 5: "5級", 6: "6級" };
const disability_level_2_list = { 1: "A1", 2: "A2", 3: "A3", 4: "B1", 5: "B2" };
const disability_level_3_list = { 1: "1級", 2: "2級", 3: "3級" };
const support_law_list = { 0: "未申請", 1: "申請中", 2: "有" };
const pension_types = { 0: "未受給または申請中", 1: "基礎", 2: "厚生" };
const pension_level_list = { 1: { 1: "1級", 2: "2級" }, 2: { 1: "1級", 2: "2級", 3: "3級" } };
const other_income_list = { 0: "なし", 1: "生活保護", 9: "その他手当" };
const work_list = { 0: "なし", 1: "就労継続支援A型", 2: "就労継続支援B型", 3: "一般就労", 4: "障害者雇用" };
const medical_list = { 0: "なし", 1: "障害者医療証", 2: "自立支援医療", 3: "生活保護（医療補助）" };
//---------------------------issue{No.513} end-------------------------------

//---------------------------issue{No.513} start-----------------------------
// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

//---------------------------issue{No.513} end-------------------------------

//---------------------------issue{No.342} start-----------------------------
// 日付をyyyymmddの形式にする
function numDayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + m + d;
}
// 日付を取得
const numToday = Number(numDayFormat(new Date()));
//---------------------------issue{No.342} end-------------------------------

function Assessment_1Edit() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //利用者idと相談支援支給申請のidを保持している
    const { client, id } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付けの入力制限のための日付の値の定数を取得
    const { MIN_DATE, MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // 入力値を管理する
    const [assessment_1, setAssessment_1] = useState(initAssessment_1);

    // 画像関連
    const [family, setFamily] = useState(); // ファイル(家族構成)
    const [social, setSocial] = useState(); // ファイル(社会関係図)
    const [familyPreview, setFamilyPreview] = useState(); // 確認用画像(家族構成)
    const [socialPreview, setSocialPreview] = useState(); // 確認用画像(社会関係図)
    const [beforeFamily, setBeforeFamily] = useState(""); // 登録済み画像(家族構成)
    const [beforeSocial, setBeforeSocial] = useState(""); // 登録済み画像(社会関係図)
    const [beforeFamilyOrigin, setBeforeFamilyOrigin] = useState(""); // 保管用登録済み画像(家族構成)
    const [beforeSocialOrigin, setBeforeSocialOrigin] = useState(""); // 保管用登録済み画像(社会関係図)
    const [hasBeforeFamilyOrigin, setHasBeforeFamilyOrigin] = useState(false); // 保管用登録済み画像存在フラグ(家族構成)
    const [hasBeforeSocialOrigin, setHasBeforeSocialOrigin] = useState(false); // 保管用登録済み画像存在フラグ(社会関係図)
    const [isUpdateFamily, setIsUpdateFamily] = useState(false); // 画像更新フラグ（家族構成）
    const [isUpdateSocial, setIsUpdateSocial] = useState(false); // 画像更新フラグ（社会関係図）
    const [isDeleteFamily, setIsDeleteFamily] = useState(false); // 登録済み画像削除フラグ(家族構成)
    const [isDeleteSocial, setIsDeleteSocial] = useState(false); // 登録済み画像削除フラグ(社会関係図)
    const [loadFinish, setLoadFinish] = useState(false);

    //---------------------------issue{No.342} start-----------------------------
    //clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    //watch内で使用する生年月日
    const clientBirthdayRef = useRef(""); // issue516

    // 最新のクライアント情報に更新するボタンの表示の判定に使う
    const [clientChangeable, setClientChangeable] = useState(false);

    // レコードと最新のクライアント情報を比較し見つかった変更箇所の値を管理する
    const [implementClientData, setImplementClientData] = useState({});

    // 最新のクライアント情報をリクエスト送信に含めるかを管理する
    const [runImplementClientChanges, setRunImplementClientChanges] = useState(false);

    // 更新内容を引用したかどうかを管理する
    const [citingClient, setCitingClient] = useState("");

    // 今日以前の直近の作成済みレコードの日付を数値で保存する
    const [mostRecentDate, setMostRecentDate] = useState(0);
    //---------------------------issue{No.342} end-------------------------------

    //---------------------------issue{No.453} start-----------------------------
    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");
    const [changeDateMessage, setChangeDateMessage] = useState("");
    //---------------------------issue{No.453} end-------------------------------

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 利用者情報を書き換える必要がある場合に、書き換え可能にする（readOnly属性を切り替える）
    // デフォルト値はfalse（readOnly属性はfalseを!でひっくり返した値のtrueで編集不可）
    const [clientEdit, setClientEdit] = useState(false);

    // 選択に応じて表示される入力項目の切り替えに使うステート
    const [residenceType, setResidenceType] = useState(0);
    const [isDisability1, setIsDisability1] = useState(false);
    const [isDisability2, setIsDisability2] = useState(false);
    const [isDisability3, setIsDisability3] = useState(false);
    const [pensionType, setPensionType] = useState(0);
    const [otherIncome, setOtherIncome] = useState(0);
    const [clientWork, setClientWork] = useState(0);
    const [isMedicalOther, setIsMedicalOther] = useState(false);
    //---------------------------issue{No.513} end-------------------------------

    //情報を取得しステート assessment_1にセットする
    const getAssessment_1Data = async (id) => {
        const url = `assessments_1/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setAssessment_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
        }
    };

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `assessments_1/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id,
                office_name: clientResult.office_name,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                client_birthday: clientResult.is_child ? clientResult.child_birthday : clientResult.client_birthday,
                client_gender: clientResult.is_child ? clientResult.child_gender : clientResult.client_gender,
                client_address:
                    clientResult.client_address1 +
                    clientResult.client_address2 +
                    (clientResult.client_address3 ? clientResult.client_address3 : ""),
                residence_type: clientResult.residence_type,
                residence_type_detail: clientResult.residence_type_detail,
                client_tel: clientResult.client_tel,
                emergency_tel: clientResult.emergency_tel,
                disability_name: clientResult.disability_name,
                support_law: clientResult.support_law,
                support_level: clientResult.support_level,
                disability_1: clientResult.disability_1,
                disability_level_1: clientResult.disability_level_1,
                disability_2: clientResult.disability_2,
                disability_level_2: clientResult.disability_level_2,
                disability_3: clientResult.disability_3,
                disability_level_3: clientResult.disability_level_3,
                pension_type: clientResult.pension_type,
                pension_level: clientResult.pension_level,
                other_income: clientResult.other_income,
                other_income_detail: clientResult.other_income_detail,
                work: clientResult.work,
                workplace: clientResult.workplace,
                employment_insurance: clientResult.employment_insurance,
                welfare_pension: clientResult.welfare_pension,
                health_insurance: clientResult.health_insurance,
                medical: clientResult.medical,
                medical_other: clientResult.medical_other,
                medical_other_detail: clientResult.medical_other_detail,
                support_level: clientResult.support_level,
            });

            //---------------------------issue{No.516} start-------------------------------
            clientBirthdayRef.current =
                clientResult.is_child == 1 ? clientResult.child_birthday : clientResult.client_birthday;
            //---------------------------issue{No.516} end-------------------------------
        }
    };

    //---------------------------issue{No.471} start-----------------------------
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name,
                };
            });
            setUsers(usersName);
        }
    };
    //---------------------------issue{No.471} end-------------------------------

    // クライアントの各機能ごとに、すでにあるレコードのdateカラムのデータを取得する
    const getDateList = async (clientId) => {
        const url = `assessments_1_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 今日以前の直近の日付のみ取得する
            const result = Math.max.apply(
                null,
                res.data.result
                    .map((item) => {
                        const date = item.date;
                        const formatDate = Number(date.replace(/[-]/g, ""));
                        if (formatDate <= numToday) {
                            return formatDate;
                        }
                    })
                    .filter((e) => e)
            );
            // ステートに保存する
            setMostRecentDate(result);

            //---------------------------issue{No.453} start-----------------------------
            // 取得したデータの作成日を被り対象から除外して、dateListを作成
            const resultList = res.data.result
                .filter((item) => item.date !== assessment_1.date)
                .map((item) => {
                    return item.date;
                });
            dateList.current = resultList;
            //---------------------------issue{No.453} end-------------------------------
        }
    };

    // 年齢を計算する
    const clientAgeCalculation = () => {
        const dayFormat = today.split("-");
        const clientBirthdayFormat = assessment_1.client_birthday.split("-");

        const dayY = dayFormat[0];
        const dayMD = `${dayFormat[1]}${("00" + dayFormat[2]).slice(-2)}`;

        const birthdayY = clientBirthdayFormat[0];
        const birthdayMD = `${clientBirthdayFormat[1]}${("00" + dayFormat[2]).slice(-2)}`;

        let age = dayY - birthdayY;
        if (dayMD < birthdayMD) {
            age--;
        }

        return age;
    };

    // //利用者名クリック時、利用者情報表示画面へ
    // const handleOnClickShow = (e) => {
    //     // イベントの伝搬を中止
    //     e.stopPropagation();

    //     if (client) {
    //         // URL(/client/show/${client.id})をたたく（RouterConfig.js）
    //         navigate(`/client_select/show/${client}`);
    //     }
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 保存ボタン押下時
    const assessment_1Submit = async (data) => {
        //---------------------------issue{No.453} start-----------------------------
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "登録内容を見直してください",
                timer: 2000,
            });
            return;
        }
        //---------------------------issue{No.453} end-------------------------------

        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;

        let isClientChangeData = false;
        for (let key in clientData) {
            if (clientData[key] && data[key] && clientData[key] != data[key]) {
                isClientChangeData = true;
                break;
            }
        }

        // if (isClientChangeData) {
        //     await swal({
        //         icon: "warning",
        //         title: "注意",
        //         text: "ここで変更された利用者情報に関する内容は、利用者の情報には保存されません。",
        //         buttons: {
        //             cancel: "キャンセル",
        //             defeat: "確認しました"
        //         }
        //     }).then((result) => {
        //         if (result) {
        //             confirm = true;
        //         } else {
        //             confirm = false;
        //         }
        //     });

        // キャンセル処理
        if (!confirm) {
            return;
        }
        //---------------------------issue{No.513} end-------------------------------

        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // const client_age = clientAgeCalculation(data.date);
        // Assessment_1Controllerに渡す利用者情報の連想配列を選択
        const storeClient = citingClient === "done" ? clientData : assessment_1;

        // Assessment_1Controllerに渡す値をセット
        const assessment_1Data = {
            company_id: assessment_1.company_id,
            office_id: assessment_1.office_id,
            user_id: assessment_1.user_id,
            client_id: storeClient.client_id,
            office_name: assessment_1.office_name,
            //---------------------------issue{No.471} start-----------------------------
            user_name: data.user_name,
            //---------------------------issue{No.471} end-------------------------------
            client_name: storeClient.client_name,

            client_birthday: storeClient.client_birthday,

            client_age: storeClient.client_age,
            client_gender: storeClient.client_gender,
            client_address: storeClient.client_address,
            residence_type: storeClient.residence_type,
            residence_type_detail: storeClient.residence_type_detail,
            client_tel: storeClient.client_tel,
            emergency_tel: storeClient.emergency_tel,
            disability_name: storeClient.disability_name,
            support_law: storeClient.support_law,
            support_level: storeClient.support_level,
            disability_1: storeClient.disability_1,
            disability_level_1: storeClient.disability_level_1,
            disability_2: storeClient.disability_2,
            disability_level_2: storeClient.disability_level_2,
            disability_3: storeClient.disability_3,
            disability_level_3: storeClient.disability_level_3,
            pension_type: storeClient.pension_type,
            pension_level: storeClient.pension_level,
            other_income: storeClient.other_income,
            other_income_detail: storeClient.other_income_detail,
            work: storeClient.work,
            workplace: storeClient.workplace,
            employment_insurance: storeClient.employment_insurance,
            welfare_pension: storeClient.welfare_pension,
            health_insurance: storeClient.health_insurance,
            medical: storeClient.medical,
            medical_other: storeClient.medical_other,
            medical_other_detail: storeClient.medical_other_detail,
            date: data.date,
            summary: data.summary,
            life_history: data.life_history,
            medical_situation: data.medical_situation,
            self_complaint: data.self_complaint,
            family_complaint: data.family_complaint,
            public_support_name: data.public_support_name,
            public_support_provider: data.public_support_provider,
            public_support_detail: data.public_support_detail,
            public_support_frequency: data.public_support_frequency,
            public_support_contact: data.public_support_contact,
            other_support_name: data.other_support_name,
            other_support_provider: data.other_support_provider,
            other_support_detail: data.other_support_detail,
            other_support_frequency: data.other_support_frequency,
            other_support_contact: data.other_support_contact,
            daily_routine: data.daily_routine,
            other_service: data.other_service,
        };

        // 画像関連
        const sendData = new FormData();
        if (family) {
            sendData.append("family_structure", family);
        } else {
            sendData.append("family_structure", "[object FileList]");
        }
        if (social) {
            sendData.append("social_relationship_diagram", social);
        } else {
            sendData.append("social_relationship_diagram", "[object FileList]");
        }

        //---------------------------issue{No.513} start-----------------------------
        for (let key in assessment_1Data) {
            if (assessment_1Data[key] === true) {
                sendData.append(key, 1);
            } else if (assessment_1Data[key] === false) {
                sendData.append(key, 0);
            } else {
                sendData.append(key, assessment_1Data[key]);
            }
        }
        //---------------------------issue{No.513} end-------------------------------

        //---------------------------issue{No.513} start-----------------------------
        if (residenceType != 6) {
            sendData.append("residence_type_detail", null);
        }
        if (!isDisability1) {
            sendData.append("disability_level_1", null);
        }
        if (!isDisability2) {
            sendData.append("disability_level_2", null);
        }
        if (!isDisability3) {
            sendData.append("disability_level_3", null);
        }
        if (pensionType == "0") {
            sendData.append("pension_level", null);
        }
        if (otherIncome != "9") {
            sendData.append("other_income_detail", null);
        }
        if (clientWork == "0") {
            sendData.append("workplace", null);
        }
        if (!isMedicalOther) {
            sendData.append("medical_other_detail", null);
        }
        //---------------------------issue{No.513} end-------------------------------

        // 画像更新フラグ
        sendData.append("is_update_family", isUpdateFamily);
        sendData.append("is_update_social", isUpdateSocial);

        // 登録済み画像削除フラグ
        sendData.append("is_delete_family", isDeleteFamily);
        sendData.append("is_delete_social", isDeleteSocial);

        //保存
        const url = `assessments_1/${id}`;
        const res = await api.post(url, sendData, {
            headers: {
                "content-type": "multipart/form-data",
            },
        });
        if (res.status === 422) {
            setAssessment_1({
                ...assessment_1,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/assessment_1/${client}`);
            });
        }
    };

    // 以前登録した画像を取得
    const getBeforeFamily = async () => {
        // コントローラーに渡す値を作成
        const company = assessment_1.company_id;
        const office = assessment_1.office_id;
        const family2 = assessment_1.family_structure;
        const data = { path: `family/${company}/${office}/${client}/${family2}` };
        if (family2 != null) {
            const res = await api.post("file", data);
            // 値が存在する場合state(=imgのurl)を更新
            if (res) {
                const gotData = res.data.result;
                const adjustedData = JSON.parse(gotData); // 受け取ったデータをjsonに
                const mimeType = adjustedData.mimeType;
                const fileText = adjustedData.fileText;
                const beforeFamilyUrl = "data:" + mimeType + ";base64," + fileText;
                setBeforeFamily(beforeFamilyUrl);
                setBeforeFamilyOrigin(beforeFamilyUrl);
                setHasBeforeFamilyOrigin(true);
            }
        }
    };

    const getBeforeSocial = async () => {
        // コントローラーに渡す値を作成
        const company = assessment_1.company_id;
        const office = assessment_1.office_id;
        const social2 = assessment_1.social_relationship_diagram;
        const data = { path: `social/${company}/${office}/${client}/${social2}` };
        if (social2 != null) {
            const res = await api.post("file", data);
            // 値が存在する場合state(=imgのurl)を更新
            if (res) {
                const gotData = res.data.result;
                const adjustedData = JSON.parse(gotData); // 受け取ったデータをjsonに
                const mimeType = adjustedData.mimeType;
                const fileText = adjustedData.fileText;
                const beforeFamilyUrl = "data:" + mimeType + ";base64," + fileText;
                setBeforeSocial(beforeFamilyUrl);
                setBeforeSocialOrigin(beforeFamilyUrl);
                setHasBeforeSocialOrigin(true);
            }
        }
    };

    useEffect(async () => {
        if (Object.keys(assessment_1).length > 1) {
            await getBeforeFamily();
            await getBeforeSocial();
            setLoadFinish(true);
        }
    }, [assessment_1]);

    // 画像関連
    // 画像変更時の処理
    // 家族構成
    const changeFamily = (e) => {
        let file = e.target.files[0];
        if (file) {
            setFamily(file);
            setFamilyPreview(window.URL.createObjectURL(file));
            setIsUpdateFamily(true);
            setIsDeleteFamily(false);
        }
    };

    // 社会関係図
    const changeSocial = (e) => {
        let file = e.target.files[0];
        if (file) {
            setSocial(file);
            setSocialPreview(window.URL.createObjectURL(file));
            setIsUpdateSocial(true);
            setIsDeleteSocial(false);
        }
    };

    // プレビューの✕ボタン押下時
    // 家族構成
    const clickFamilyCross = () => {
        setFamily(undefined);
        setFamilyPreview(undefined);
        setIsUpdateFamily(false);
        document.getElementById("family_structure").value = "";
    };

    // 社会関係図
    const clickSocialCross = () => {
        setSocial(undefined);
        setSocialPreview(undefined);
        setIsUpdateSocial(false);
        document.getElementById("social_relationship_diagram").value = "";
    };

    //---------------------------issue{No.464} start-----------------------------

    // 登録済み画像削除
    // 家族構成
    const handleClickDeleteFamily = () => {
        // ボタン押下ごとに削除フラグを切り替え、それに応じた処理を行う
        if (isDeleteFamily) {
            setIsDeleteFamily(false);
            setBeforeFamily(beforeFamilyOrigin);
        } else {
            setIsDeleteFamily(true);
            setFamily(undefined);
            setFamilyPreview(undefined);
            setIsUpdateFamily(false);
            document.getElementById("family_structure").value = "";
        }
    };

    // 社会関係図
    const handleClickDeleteSocial = () => {
        // ボタン押下ごとに削除フラグを切り替え、それに応じた処理を行う
        if (isDeleteSocial) {
            setIsDeleteSocial(false);
            setBeforeSocial(beforeSocialOrigin);
        } else {
            setIsDeleteSocial(true);
            setSocial(undefined);
            setSocialPreview(undefined);
            setIsUpdateSocial(false);
            document.getElementById("social_relationship_diagram").value = "";
        }
    };
    //---------------------------issue{No.464} start-----------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 今回の編集対象が条件に当てはまった時のみ、最新のクライアント情報を適用するボタンを表示し、変更内容を入力欄に引用する
    useEffect(() => {
        if (Object.keys(assessment_1).length > 1 && Object.keys(clientData).length > 0 && mostRecentDate) {
            const numDate = Number(assessment_1.date.replace(/[-]/g, ""));
            if (numDate === mostRecentDate || numDate > numToday) {
                const checkClientChange = () => {
                    let changeData = {};
                    for (let key in clientData) {
                        if (clientData[key] && assessment_1[key] && clientData[key] != assessment_1[key]) {
                            changeData[key] = clientData[key];
                        }
                    }
                    setImplementClientData(changeData);
                };
                checkClientChange();
            }
        }
    }, [assessment_1, clientData, mostRecentDate]);
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 更新内容を引用する準備ができたらステートを更新する
    useEffect(() => {
        if (Object.keys(implementClientData).length > 0) {
            setClientChangeable(true);
            setCitingClient("ready");
        }
    }, [implementClientData]);
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 利用者情報の更新内容を引用し直す
    const implementClientChanges = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (Object.keys(implementClientData).length > 0) {
            if (citingClient === "ready") {
                // 確認のアラートを表示する
                await swal({
                    icon: "info",
                    title: "確認",
                    text: "登録済みの利用者情報を適用しますか？",
                    buttons: true,
                }).then(async (result) => {
                    if (result) {
                        if (implementClientData.user_id) {
                            // 確認のアラートを表示する
                            let confirm = false;

                            await swal({
                                icon: "warning",
                                title: "確認",
                                text: "担当職員が更新されています。適用しますか？",
                                buttons: true,
                            }).then((result) => {
                                if (result) {
                                    confirm = true;
                                }
                            });

                            // キャンセル処理
                            if (!confirm) {
                                return;
                            }

                            // サブミット時のuser_idの変更を通す状態にする
                            setRunImplementClientChanges(true);
                        }

                        // 以下、引用のための処理
                        // runCitingClient();
                        setCitingClient("done");
                    }
                });
            }
        }
    };

    //---------------------------issue{No.513} start-----------------------------
    const runCitingClient = () => {
        if (Object.keys(clientData).length > 0 && citingClient === "ready") {
            setValue("client_name", clientData.client_name, { shouldValidate: false });
            setValue("client_birthday", clientData.client_birthday, { shouldValidate: false });
            setValue("client_gender", clientData.client_gender, { shouldValidate: false });
            setValue("client_address", clientData.client_address, { shouldValidate: false });
            setValue("client_tel", clientData.client_tel, { shouldValidate: false });
            setValue("emergency_tel", clientData.emergency_tel, { shouldValidate: false });
            setValue("residence_type", clientData.residence_type, { shouldValidate: false });
            setResidenceType(clientData.residence_type);
            setValue("residence_type_detail", clientData.residence_type_detail, { shouldValidate: false });
            setValue("disability_name", clientData.disability_name, { shouldValidate: false });
            setValue("disability_1", clientData.disability_1, { shouldValidate: false });
            if (clientData.disability_1 == "1") {
                setIsDisability1(true);
            } else {
                setIsDisability1(false);
            }
            if (clientData.disability_level_1 !== null) {
                setValue("disability_level_1", clientData.disability_level_1, { shouldValidate: false });
            } else {
                setValue("disability_level_1", "", { shouldValidate: false });
            }
            setValue("disability_2", clientData.disability_2, { shouldValidate: false });
            if (clientData.disability_2 == "1") {
                setIsDisability2(true);
            } else {
                setIsDisability2(false);
            }
            if (clientData.disability_level_2 !== null) {
                setValue("disability_level_2", clientData.disability_level_2, { shouldValidate: false });
            } else {
                setValue("disability_level_2", "", { shouldValidate: false });
            }
            setValue("disability_3", clientData.disability_3, { shouldValidate: false });
            if (clientData.disability_3 == "1") {
                setIsDisability3(true);
            } else {
                setIsDisability3(false);
            }
            if (clientData.disability_level_3 !== null) {
                setValue("disability_level_3", clientData.disability_level_3, { shouldValidate: false });
            } else {
                setValue("disability_level_3", "", { shouldValidate: false });
            }
            setValue("support_law", clientData.support_law, { shouldValidate: false });
            setValue("support_level", clientData.support_level, { shouldValidate: false });
            setValue("pension_type", clientData.pension_type, { shouldValidate: false });
            setPensionType(clientData.pension_type);
            if (clientData.pension_level !== null) {
                setValue("pension_level", clientData.pension_level, { shouldValidate: false });
            } else {
                setValue("pension_level", "", { shouldValidate: false });
            }
            setValue("other_income", clientData.other_income, { shouldValidate: false });
            setOtherIncome(clientData.other_income);
            setValue("other_income_detail", clientData.other_income_detail, { shouldValidate: false });
            setValue("work", clientData.work, { shouldValidate: false });
            setClientWork(clientData.work);
            if (clientData.workplace !== null) {
                setValue("workplace", clientData.workplace, { shouldValidate: false });
            }
            setValue("employment_insurance", clientData.employment_insurance, { shouldValidate: false });
            setValue("welfare_pension", clientData.welfare_pension, { shouldValidate: false });
            setValue("health_insurance", clientData.health_insurance, { shouldValidate: false });
            setValue("medical", clientData.medical, { shouldValidate: false });
            setValue("medical_other", clientData.medical_other, { shouldValidate: false });
            if (clientData.medical_other == "1") {
                setIsMedicalOther(true);
            }
            setValue("medical_other_detail", clientData.medical_other_detail, { shouldValidate: false });
        }
    };
    //---------------------------issue{No.513} end-------------------------------

    // 引用した内容を編集前に戻す
    const putBackClientChanges = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        for (let key in implementClientData) {
            // 更新対象のフォーム内の項目を変更
            setValue(key, assessment_1[key], { shouldValidate: false });
        }
        setCitingClient("ready");
    };
    //---------------------------issue{No.513} end-------------------------------

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getAssessment_1Data(id);
        //---------------------------issue{No.342} start-----------------------------
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        //---------------------------issue{No.342} end-------------------------------
    }, []);

    //---------------------------issue{No.453} start-----------------------------
    // dateListを作成するときに、必要な値があるため、取得のタイミングを変更
    useEffect(() => {
        if (Object.keys(assessment_1).length > 1) {
            getDateList(client);
        }
    }, [assessment_1]);
    //---------------------------issue{No.453} end-------------------------------

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        //---------------------------issue{No.471} start-----------------------------
        control,
        //---------------------------issue{No.471} end-------------------------------
        //---------------------------issue{No.453} start-----------------------------
        watch,
        //---------------------------issue{No.453} end-------------------------------
    } = useForm();

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(assessment_1).length > 1 && !getValues("summary")) {
            setValue("date", assessment_1.date, { shouldValidate: false });
            //---------------------------issue{No.471} start-----------------------------
            setValue("user_name", assessment_1.user_name, { shouldValidate: false });
            //---------------------------issue{No.471} end-------------------------------
            setValue("summary", assessment_1.summary, { shouldValidate: false });
            setValue("life_history", assessment_1.life_history, { shouldValidate: false });
            setValue("medical_situation", assessment_1.medical_situation, { shouldValidate: false });
            setValue("self_complaint", assessment_1.self_complaint, { shouldValidate: false });
            setValue("family_complaint", assessment_1.family_complaint, { shouldValidate: false });
            setValue("public_support_name", assessment_1.public_support_name, { shouldValidate: false });
            setValue("public_support_provider", assessment_1.public_support_provider, { shouldValidate: false });
            setValue("public_support_detail", assessment_1.public_support_detail, { shouldValidate: false });
            setValue("public_support_frequency", assessment_1.public_support_frequency, { shouldValidate: false });
            setValue("public_support_contact", assessment_1.public_support_contact, { shouldValidate: false });
            setValue("other_support_name", assessment_1.other_support_name, { shouldValidate: false });
            setValue("other_support_provider", assessment_1.other_support_provider, { shouldValidate: false });
            setValue("other_support_detail", assessment_1.other_support_detail, { shouldValidate: false });
            setValue("other_support_frequency", assessment_1.other_support_frequency, { shouldValidate: false });
            setValue("other_support_contact", assessment_1.other_support_contact, { shouldValidate: false });
            setValue("daily_routine", assessment_1.daily_routine, { shouldValidate: false });
            setValue("other_service", assessment_1.other_service, { shouldValidate: false });

            //---------------------------issue{No.513} start-----------------------------
            setValue("client_name", assessment_1.client_name, { shouldValidate: false });
            setValue("client_birthday", assessment_1.client_birthday, { shouldValidate: false });
            setValue("client_gender", assessment_1.client_gender, { shouldValidate: false });
            setValue("client_address", assessment_1.client_address, { shouldValidate: false });
            setValue("client_tel", assessment_1.client_tel, { shouldValidate: false });
            setValue("emergency_tel", assessment_1.emergency_tel, { shouldValidate: false });
            setValue("residence_type", assessment_1.residence_type, { shouldValidate: false });
            setResidenceType(assessment_1.residence_type);
            setValue("residence_type_detail", assessment_1.residence_type_detail, { shouldValidate: false });
            setValue("disability_name", assessment_1.disability_name, { shouldValidate: false });
            setValue("disability_1", assessment_1.disability_1, { shouldValidate: false });
            if (assessment_1.disability_1 == "1") {
                setIsDisability1(true);
            }
            if (assessment_1.disability_level_1 !== null) {
                setValue("disability_level_1", assessment_1.disability_level_1, { shouldValidate: false });
            }
            setValue("disability_2", assessment_1.disability_2, { shouldValidate: false });
            if (assessment_1.disability_2 == "1") {
                setIsDisability2(true);
            }
            if (assessment_1.disability_level_2 !== null) {
                setValue("disability_level_2", assessment_1.disability_level_2, { shouldValidate: false });
            }
            setValue("disability_3", assessment_1.disability_3, { shouldValidate: false });
            if (assessment_1.disability_3 == "1") {
                setIsDisability3(true);
            }
            if (assessment_1.disability_level_3 !== null) {
                setValue("disability_level_3", assessment_1.disability_level_3, { shouldValidate: false });
            }
            setValue("support_law", assessment_1.support_law, { shouldValidate: false });
            setValue("support_level", assessment_1.support_level, { shouldValidate: false });

            if (assessment_1.pension_level !== null) {
                setValue("pension_type", assessment_1.pension_type, { shouldValidate: false });
            }
            setPensionType(assessment_1.pension_type);
            if (assessment_1.pension_level !== null) {
                setValue("pension_level", assessment_1.pension_level, { shouldValidate: false });
            }
            setValue("other_income", assessment_1.other_income, { shouldValidate: false });
            setOtherIncome(assessment_1.other_income);
            setValue("other_income_detail", assessment_1.other_income_detail, { shouldValidate: false });
            setValue("work", assessment_1.work, { shouldValidate: false });
            setClientWork(assessment_1.work);
            if (assessment_1.workplace !== null) {
                setValue("workplace", assessment_1.workplace, { shouldValidate: false });
            }
            setValue("employment_insurance", assessment_1.employment_insurance, { shouldValidate: false });
            setValue("welfare_pension", assessment_1.welfare_pension, { shouldValidate: false });
            setValue("health_insurance", assessment_1.health_insurance, { shouldValidate: false });
            setValue("medical", assessment_1.medical, { shouldValidate: false });
            setValue("medical_other", assessment_1.medical_other, { shouldValidate: false });
            if (assessment_1.medical_other == "1") {
                setIsMedicalOther(true);
            }
            setValue("medical_other_detail", assessment_1.medical_other_detail, { shouldValidate: false });
            //---------------------------issue{No.513} end-------------------------------
        }
    }, [assessment_1]);

    //---------------------------issue{No.453} start-----------------------------
    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change" && name === "date") {
                setDateMessage(dateRegisteringErrorMessage(value.date, clientBirthdayRef.current, dateList.current));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    const dateCollation = (inputDate) => {
        const result = dateList.current.includes(inputDate);
        if (result) {
            return true;
        } else {
            return false;
        }
    };
    //---------------------------issue{No.453} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // チェック時に、利用者情報に関する項目の変更が可能になる
    const handleChangeClientEdit = async (e, checked) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (checked) {
            // 確認のアラートを表示する
            await swal({
                icon: "warning",
                title: "確認",
                text: "ここで入力内容を修正する場合、利用者情報に関する該当項目は更新されません。続行しますか？",
                buttons: true,
            }).then((result) => {
                if (result) {
                    setClientEdit(true);
                } else {
                    setClientEdit(false);
                }
            });
        } else {
            setClientEdit(false);
        }
    };
    //---------------------------issue{No.513} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>アセスメント編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        {runImplementClientChanges && (
                            <div className='text-end mb-2'>
                                <p className='d-inline-block text-decoration-underline'>
                                    ※保存時に現在の利用者情報の担当職員が適用されます。
                                </p>
                            </div>
                        )}
                        <div className='d-flex justify-content-between mb-4'>
                            <p className='fs-6 pt-2'>
                                <RequiredMark />
                                <span className='text-secondary'>は必須項目です。</span>
                            </p>
                            {/* <div>
                                {clientChangeable && (
                                    <div className='d-flex mb-2'>
                                        {citingClient === "done" && (
                                            <div className='pe-2'>
                                                <Button
                                                    type='button'
                                                    variant='contained'
                                                    onClick={(e) => {
                                                        putBackClientChanges(e);
                                                    }}>
                                                    利用者情報を
                                                    <br />
                                                    編集前に戻す
                                                </Button>
                                            </div>
                                        )}
                                        {citingClient === "ready" ? (
                                            <Button
                                                type='button'
                                                variant='contained'
                                                color='secondary'
                                                onClick={(e) => {
                                                    implementClientChanges(e);
                                                }}>
                                                登録されている利用者の情報と
                                                <br />
                                                異なる入力内容があります
                                            </Button>
                                        ) : (
                                            <Button type='button' variant='contained' color='primary'>
                                                登録されている利用者の内容を
                                                <br />
                                                引用済み
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div> */}
                        </div>
                        <form onSubmit={handleSubmit(assessment_1Submit)} encType='multipart/form-data'>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    作成日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*作成日を入力してください。",
                                        },
                                    })}
                                />
                                {/* 日付の重複によるエラーメッセージ */}
                                <small className='text-danger'>{dateMessage && dateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.date}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    計画作成担当者
                                    <RequiredMark />
                                </label>
                                {Object.keys(users).length > 0 && (
                                    <Controller
                                        name='user_name'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*計画作成担当者を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        {Object.keys(users).length === 0 ? (
                                                            <>ユーザーが登録されていません</>
                                                        ) : (
                                                            <>選択してください</>
                                                        )}
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.user_name}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                )}
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.user_name}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='summary'>
                                    概要（支援経過・現状と課題等）
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='summary'
                                    className='form-control mb-1'
                                    {...register("summary", {
                                        required: {
                                            value: true,
                                            message: "*概要を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*概要を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.summary && errors.summary?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.summary}
                                </small>
                            </div>
                            <hr />
                            <h4 className='d-inline-block border border-2 px-3 fs-5 fw-bold mb-4'>利用者の状況</h4>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='family_structure'>
                                    家族構成
                                </label>
                                <input
                                    id='family_structure'
                                    type='file'
                                    className='form-control mb-1'
                                    accept='image/*'
                                    {...register("family_structure")}
                                    onChange={(e) => changeFamily(e)}
                                />
                                <div className='mt-4 d-flex justify-content-between'>
                                    <div>
                                        <figure className={classes.figure}>現在の画像</figure>
                                        <div className={classes.beforeWrapper}>
                                            {loadFinish && (
                                                <img
                                                    className={classes.before}
                                                    src={beforeFamily == "" ? "/img/no_image.jpg" : beforeFamily}
                                                />
                                            )}
                                        </div>
                                        {hasBeforeFamilyOrigin && (
                                            <div>
                                                <br />
                                                {isDeleteFamily && <p>上記画像を削除</p>}
                                                <button
                                                    className={classes.imgDeleteButton}
                                                    type='button'
                                                    onClick={() => {
                                                        handleClickDeleteFamily();
                                                    }}>
                                                    {isDeleteFamily ? "キャンセル" : "削除"}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {familyPreview !== undefined && (
                                        <div>
                                            <figure className={classes.figure}>登録する画像</figure>
                                            <div className={classes.previewWrapper} onClick={clickFamilyCross}>
                                                <div className={classes.crossWrapper}>
                                                    <div className={classes.crossWrapper2}>
                                                        <div className={classes.cross}></div>
                                                        <div className={classes.cross2}></div>
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <img className={classes.preview} src={familyPreview} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='social_relationship_diagram'>
                                    社会関係図
                                </label>
                                <input
                                    id='social_relationship_diagram'
                                    type='file'
                                    className='form-control mb-1'
                                    accept='image/*'
                                    {...register("social_relationship_diagram")}
                                    onChange={(e) => changeSocial(e)}
                                />
                                <div className='mt-4 d-flex justify-content-between'>
                                    <div>
                                        <figure className={classes.figure}>現在の画像</figure>
                                        <div className={classes.beforeWrapper}>
                                            {loadFinish && (
                                                <img
                                                    className={classes.before}
                                                    src={beforeSocial == "" ? "/img/no_image.jpg" : beforeSocial}
                                                />
                                            )}
                                        </div>
                                        {hasBeforeSocialOrigin && (
                                            <div>
                                                <br />
                                                {isDeleteSocial && <p>上記画像を削除</p>}
                                                <button
                                                    className={classes.imgDeleteButton}
                                                    type='button'
                                                    onClick={() => {
                                                        handleClickDeleteSocial();
                                                    }}>
                                                    {isDeleteSocial ? "キャンセル" : "削除"}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                    {socialPreview !== undefined && (
                                        <div>
                                            <figure className={classes.figure}>登録する画像</figure>
                                            <div className={classes.previewWrapper} onClick={clickSocialCross}>
                                                <div className={classes.crossWrapper}>
                                                    <div className={classes.crossWrapper2}>
                                                        <div className={classes.cross}></div>
                                                        <div className={classes.cross2}></div>
                                                    </div>
                                                </div>
                                                <div className='text-center'>
                                                    <img className={classes.preview} src={socialPreview} />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='life_history'>
                                    生活歴
                                </label>
                                <textarea
                                    id='life_history'
                                    className='form-control mb-1'
                                    {...register("life_history")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.life_history && errors.life_history?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.life_history}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='medical_situation'>
                                    医療の状況
                                </label>
                                <textarea
                                    id='medical_situation'
                                    className='form-control mb-1'
                                    {...register("medical_situation")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.medical_situation && errors.medical_situation?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.medical_situation}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='self_complaint'>
                                    本人の主訴
                                </label>
                                <textarea
                                    id='self_complaint'
                                    className='form-control mb-1'
                                    {...register("self_complaint")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.self_complaint && errors.self_complaint?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.self_complaint}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='family_complaint'>
                                    家族の主訴
                                </label>
                                <textarea
                                    id='family_complaint'
                                    className='form-control mb-1'
                                    {...register("family_complaint")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.family_complaint && errors.family_complaint?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.family_complaint}
                                </small>
                            </div>
                            <h4 className='d-inline-block border border-2 px-3 fs-5 fw-bold mb-4'>公的支援</h4>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='public_support_name'>
                                    名称
                                </label>
                                <input
                                    id='public_support_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("public_support_name")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.public_support_name && errors.public_support_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.public_support_name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='public_support_provider'>
                                    提供機関・提供者
                                </label>
                                <input
                                    id='public_support_provider'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("public_support_provider")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.public_support_provider && errors.public_support_provider?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.public_support_provider}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='public_support_detail'>
                                    支援内容
                                </label>
                                <textarea
                                    id='public_support_detail'
                                    className='form-control mb-1'
                                    {...register("public_support_detail")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.public_support_detail && errors.public_support_detail?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.public_support_detail}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='public_support_frequency'>
                                    頻度
                                </label>
                                <input
                                    id='public_support_frequency'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("public_support_frequency")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.public_support_frequency && errors.public_support_frequency?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.public_support_frequency}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='public_support_contact'>
                                    連絡先・備考
                                </label>
                                <textarea
                                    id='public_support_contact'
                                    className='form-control mb-1'
                                    {...register("public_support_contact")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.public_support_contact && errors.public_support_contact?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.public_support_contact}
                                </small>
                            </div>
                            <hr />
                            <h4 className='d-inline-block border border-2 px-3 fs-5 fw-bold mb-4'>その他の支援</h4>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_support_name'>
                                    名称
                                </label>
                                <input
                                    id='other_support_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("other_support_name")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_support_name && errors.other_support_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.other_support_name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_support_provider'>
                                    提供機関・提供者
                                </label>
                                <input
                                    id='other_support_provider'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("other_support_provider")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_support_provider && errors.other_support_provider?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.other_support_provider}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_support_detail'>
                                    支援内容
                                </label>
                                <textarea
                                    id='other_support_detail'
                                    className='form-control mb-1'
                                    {...register("other_support_detail")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_support_detail && errors.other_support_detail?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.other_support_detail}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_support_frequency'>
                                    頻度
                                </label>
                                <input
                                    id='other_support_frequency'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("other_support_frequency")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_support_frequency && errors.other_support_frequency?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.other_support_frequency}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='other_support_contact'>
                                    連絡先・備考
                                </label>
                                <textarea
                                    id='other_support_contact'
                                    className='form-control mb-1'
                                    {...register("other_support_contact")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_support_contact && errors.other_support_contact?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.other_support_contact}
                                </small>
                            </div>
                            <hr />
                            <h3 className='border-bottom border-secondary'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、利用者情報による内容</span>
                            </h3>

                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                </label>
                                <input
                                    id='client_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_name", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_birthday'>
                                    生年月日
                                </label>
                                <input
                                    id='client_birthday'
                                    type='date'
                                    min='1900-01-01'
                                    max={today}
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_birthday", {
                                        required: {
                                            value: true,
                                            message: "*生年月日を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const todayDate = new Date(today);
                                                const inputDate = new Date(value);
                                                if (inputDate > todayDate) {
                                                    return `*今日以降の日付は入力できません`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                            </div>

                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>性別</label>
                                <div className='form-control mb-1'>{genders[clientData?.client_gender ?? 0]}</div>
                            </div>

                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_address'>
                                    住所
                                </label>
                                <input
                                    id='client_address'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_address", {
                                        required: {
                                            value: true,
                                            message: "*住所を入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>住所区分</label>
                                <div className='form-control mb-1'>
                                    {residence_types[clientData?.residence_type ?? 0]}
                                </div>
                            </div>
                            {residenceType == "6" && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='residence_type_detail'>
                                        その他の内容
                                    </label>
                                    <input
                                        id='residence_type_detail'
                                        type='text'
                                        className='form-control'
                                        readOnly
                                        {...register("residence_type_detail")}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_tel'>
                                    本人連絡先
                                </label>
                                <input
                                    id='client_tel'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    placeholder='000-000-0000'
                                    {...register("client_tel", {
                                        required: {
                                            value: true,
                                            message: "*電話番号を入力してください",
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください",
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください",
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='emergency_tel'>
                                    緊急連絡先
                                </label>
                                <input
                                    id='emergency_tel'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    placeholder='000-000-0000'
                                    {...register("emergency_tel", {
                                        required: {
                                            value: true,
                                            message: "*緊急連絡先を入力してください",
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください",
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください",
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='disability_name'>
                                    障害または疾患名
                                </label>
                                <input
                                    id='disability_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("disability_name", {
                                        required: {
                                            value: true,
                                            message: "*障害または疾患名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>身体障害者手帳</label>
                                <br />
                                {!clientEdit && (
                                    <>
                                        {citingClient === "done" ? (
                                            <FormControlLabel
                                                control={<Checkbox checked={isDisability1} />}
                                                label='あり'
                                                className='mb-1'
                                            />
                                        ) : (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={assessment_1.disability_1 == true ? true : false}
                                                    />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        )}
                                    </>
                                )}
                                <Controller
                                    name='disability_1'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Collapse in={clientEdit} collapsedSize='0px'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} checked={field.value == true ? true : false} />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        </Collapse>
                                    )}
                                />
                                {/* {isDisability1 && ( */}
                                <div className='form-control mb-1'>
                                    {disability_level_1_list[clientData?.disability_level_1 ?? 0] ?? "　"}
                                </div>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>療育手帳</label>
                                <br />
                                {!clientEdit && (
                                    <>
                                        {citingClient === "done" ? (
                                            <FormControlLabel
                                                control={<Checkbox checked={isDisability2} />}
                                                label='あり'
                                                className='mb-1'
                                            />
                                        ) : (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={assessment_1.disability_2 == true ? true : false}
                                                    />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        )}
                                    </>
                                )}
                                <Controller
                                    name='disability_2'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Collapse in={clientEdit} collapsedSize='0px'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} checked={field.value == true ? true : false} />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        </Collapse>
                                    )}
                                />
                                {
                                    <div className='form-control mb-1'>
                                        {disability_level_2_list[clientData?.disability_level_2 ?? 0] ?? "　"}
                                    </div>
                                }
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>精神障害手帳</label>
                                <br />
                                {!clientEdit && (
                                    <>
                                        {citingClient === "done" ? (
                                            <FormControlLabel
                                                control={<Checkbox checked={isDisability3} />}
                                                label='あり'
                                                className='mb-1'
                                            />
                                        ) : (
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={assessment_1.disability_3 == true ? true : false}
                                                    />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        )}
                                    </>
                                )}
                                <Controller
                                    name='disability_3'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Collapse in={clientEdit} collapsedSize='0px'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} checked={field.value == true ? true : false} />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        </Collapse>
                                    )}
                                />
                                {
                                    <div className='form-control mb-1'>
                                        {disability_level_3_list[clientData?.disability_level_3 ?? 0] ?? "　"}
                                    </div>
                                }
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>総合支援法</label>
                                <div className='form-control mb-1'>
                                    {support_law_list[clientData?.support_law ?? 0]}
                                </div>
                            </div>
                            {clientData?.support_law === 2 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1'>障害支援区分</label>
                                    <div className='form-control mb-1'>
                                        {formatSupportLevelListForEdit(
                                            clientData.support_law,
                                            clientData.support_level
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>障害年金</label>
                                <div className='form-control mb-1'>{pension_types[clientData?.pension_type ?? 0]}</div>
                                <div className='d-flex flex-row w-25 mb-4'>
                                    <input
                                        id='pension_level'
                                        type='id'
                                        className='form-control mb-1'
                                        readOnly
                                        {...register("pension_level")}
                                    />
                                    <div className='mt-2 '>級</div>
                                </div>
                            </div>

                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>その他収入</label>
                                <div className='form-control mb-1'>
                                    {other_income_list[clientData?.other_income ?? 0]}
                                </div>
                            </div>
                            {otherIncome == 9 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='other_income_detail'>
                                        その他手当の内容
                                    </label>
                                    <input
                                        id='other_income_detail'
                                        type='text'
                                        className='form-control'
                                        readOnly
                                        {...register("other_income_detail")}
                                    />
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>就労</label>
                                <div className='form-control mb-1'>{work_list[clientData?.work ?? 0]}</div>
                            </div>
                            {clientWork > 0 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='workplace'>
                                        勤務先
                                    </label>
                                    <input
                                        id='workplace'
                                        type='text'
                                        className='form-control'
                                        readOnly
                                        {...register("workplace")}
                                    />
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>雇用保険</label>
                                <br />

                                {!clientEdit && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={assessment_1.employment_insurance == true ? true : false}
                                            />
                                        }
                                        label='あり'
                                        className='mb-1'
                                    />
                                )}
                                <Controller
                                    name='employment_insurance'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Collapse in={clientEdit} collapsedSize='0px'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} checked={field.value == true ? true : false} />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        </Collapse>
                                    )}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>厚生年金</label>
                                <br />
                                {!clientEdit && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={assessment_1.welfare_pension == true ? true : false} />
                                        }
                                        label='あり'
                                        className='mb-1'
                                    />
                                )}
                                <Controller
                                    name='welfare_pension'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Collapse in={clientEdit} collapsedSize='0px'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} checked={field.value == true ? true : false} />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        </Collapse>
                                    )}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>健康保険</label>
                                <br />
                                {!clientEdit && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={assessment_1.health_insurance == true ? true : false} />
                                        }
                                        label='あり'
                                        className='mb-1'
                                    />
                                )}
                                <Controller
                                    name='health_insurance'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Collapse in={clientEdit} collapsedSize='0px'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} checked={field.value == true ? true : false} />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        </Collapse>
                                    )}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>医療</label>
                                <div className='form-control mb-1'>{work_list[clientData?.work ?? 0]}</div>
                            </div>
                            <div className={`form-group mb-${isMedicalOther ? "4" : "5"}`}>
                                <label className='fw-bold mb-1'>医療その他</label>
                                <br />
                                {!clientEdit && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={assessment_1.medical_other == true ? true : false} />
                                        }
                                        label='あり'
                                        className='mb-1'
                                    />
                                )}
                                <Controller
                                    name='medical_other'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <Collapse in={clientEdit} collapsedSize='0px'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox {...field} checked={field.value == true ? true : false} />
                                                }
                                                label='あり'
                                                className='mb-1'
                                            />
                                        </Collapse>
                                    )}
                                />
                            </div>
                            {isMedicalOther && (
                                <div className='form-group mb-5'>
                                    <label className='fw-bold mb-1' htmlFor='medical_other_detail'>
                                        医療その他の内容
                                    </label>
                                    <input
                                        id='medical_other_detail'
                                        type='text'
                                        className='form-control'
                                        readOnly
                                        {...register("medical_other_detail")}
                                    />
                                </div>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary mb-5'>
                                <span className='fs-2 text-danger'>■</span>
                                <span className='fs-4'>以下、現在の生活に関する項目</span>
                                <small className='fs-6 fw-normal text-secondary'>（二枚目の様式について）</small>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='daily_routine'>
                                    主な日常生活上の活動
                                </label>
                                <textarea
                                    id='daily_routine'
                                    className='form-control mb-1'
                                    {...register("daily_routine")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.daily_routine && errors.daily_routine?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.daily_routine}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='other_service'>
                                    週単位以外のサービス
                                </label>
                                <textarea
                                    id='other_service'
                                    className='form-control mb-1'
                                    {...register("other_service")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_service && errors.other_service?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {assessment_1.success ? "" : assessment_1.error_list.other_service}
                                </small>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Assessment_1Edit;
