import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDataList from "../../components/ClientSelectToDataList";

//見出しの配列定義
const headers = ["実施年月日"];

export default function MonitoringDraft() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDataList
        pageName='モニタリング記録'
        targetURL='monitoring_draft'
        headerDataList={headers}
        clientID={client}
        accessibleDeleted={true} />;
}