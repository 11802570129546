import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";
//---------------------------issue{No.73} start-----------------------------
import { useForm, Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";
//---------------------------issue{No.73} end-------------------------------

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShowDialog from "../../components/DataShowDialog";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

//user の初期状態
const initUser = { success: true, company_id: "" };

//dialog の初期状態
const initDialog = { open: false, data: [] };

function UserEdit() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //編集対象のユーザー情報のId
    const { id } = useParams();

    //user の状態を管理する
    const [user, setUser] = useState(initUser);

    //authorities の状態を管理する
    const [authorities, setAuthorities] = useState([]);

    //companies の状態を管理する
    const [companies, setCompanies] = useState([]);

    //offices の状態を管理する
    const [offices, setOffices] = useState([]);

    //---------------------------issue{No.73} start-----------------------------
    // サブミット後、バリデーションを通った時に送られるフォームの入力データを格納する
    const [userInputData, setUserInputData] = useState([]);
    //---------------------------issue{No.73} end-------------------------------

    //dialog の表示状態を管理する
    const [dialog, setDialog] = useState(initDialog);

    //ユーザー情報を取得しステート user にセットする
    const getUserData = async (id) => {
        const url = `users/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setUser({
                ...res.data.result,
                error_list: null,
                success: res.data.success
            });
        }
    };

    // 権限情報を取得しステート authorities にセットする
    const getAuthoritiesData = async () => {
        const url = "system/authorities";
        const res = await api.get(url);
        if (res.data.success) {
            const authorities = res.data.result.map((authority) => {
                return { label: authority.name, value: authority.authority };
            });
            setAuthorities(authorities);
        }
    };
    //会社情報を取得しステート companies にセットする
    const getCompaniesData = async () => {
        const url = "companies";
        const res = await api.get(url);
        if (res.data.success) {
            const companies = res.data.result.map((company) => {
                return { label: company.company_name, value: company.company_id };
            });
            setCompanies(companies);
        }
    };
    //事業所情報を取得しステート offices にセットする
    const getOfficesData = async (company_id) => {
        if (!company_id) {
            return;
        }

        const url = `system/companies/${company_id}/offices`;
        const res = await api.get(url);
        if (res.data.success) {
            const offices = res.data.result.map((office) => {
                return { label: office.office_name, value: office.office_id };
            });
            setOffices(offices);
        }
    };

    // //画面入力項目の値を user に反映
    // const handleInput = (e) => {
    //     e.persist();
    //     setUser({ ...user, [e.target.name]: e.target.value });
    // };

    // const handleSelectCompany = (e) => {
    //     e.persist();
    //     getOfficesData(e.target.value);
    //     setUser({ ...user, [e.target.name]: e.target.value });
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    const registerSubmit = async (data) => {
        //---------------------------issue{No.73} start-----------------------------
        // ダイアログ画面に表示するデータを作成
        const office = offices.find((item) => item.value === data.office_id);
        const authority = authorities.find((item) => item.value === data.authority);

        const officeName = office.label;
        const authorityName = authority.label;

        const openData = [
            { label: "ユーザーID", value: data.user_id },
            { label: "名前", value: data.user_name },
            { label: "メールアドレス", value: data.email },
            { label: "会社", value: user.company_name },
            { label: "事業所", value: officeName },
            { label: "権限", value: authorityName }
        ];

        // 実際にデータベースに登録するデータをステートに保持
        setUserInputData(data);
        //---------------------------issue{No.73} end-------------------------------

        //中止できるように、確認ダイアログを表示
        setDialog({ open: true, data: openData });
    };

    //dialog のボタン（アクション）の定義
    const actionClose = () => {
        setDialog(initDialog);
    };

    const actionPerform = async () => {
        setDialog(initDialog);

        const data = {
            user_id: userInputData.user_id,
            user_name: userInputData.user_name,
            email: userInputData.email,
            office_id: userInputData.office_id,
            authority: userInputData.authority
        };

        //更新
        const url = `users/${user.id}`;
        const res = await api.put(url, data);
        if (res.status === 422) {
            console.log(res.data.result);
            setUser({
                ...user,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.user_name, "success").then(() => {
                navigate("/users");
            });
        }
    };

    const actions = [
        { label: "中止", action: actionClose },
        { label: "実行", action: actionPerform }
    ];

    //画面初期表示時にユーザー情報を取得する
    useEffect(() => {
        getCompaniesData();
        getAuthoritiesData();
        getUserData(id);
    }, []);

    // 事業所情報を取得する
    useEffect(() => {
        getOfficesData(user.company_id);
    }, [user.company_id]);

    //---------------------------issue{No.73} start-----------------------------
    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        control
    } = useForm();

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(user).length > 1 && !getValues("user_name")) {
            setValue("user_id", user.user_id, { shouldValidate: false });
            setValue("user_name", user.user_name, { shouldValidate: false });
            setValue("email", user.email, { shouldValidate: false });
            setValue("office_id", user.office_id, { shouldValidate: false });
            setValue("authority", user.authority, { shouldValidate: false });
        }
    }, [user]);
    //---------------------------issue{No.73} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>ユーザー情報編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(registerSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='user_id'>
                                    ユーザーID
                                </label>
                                <input
                                    id='user_id'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly='true'
                                    {...register("user_id", {})}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='user_name'>
                                    名前
                                    <RequiredMark />
                                </label>
                                <input
                                    id='user_name'
                                    type='text'
                                    autoComplete='off'
                                    className='form-control mb-1'
                                    {...register("user_name", {
                                        required: {
                                            value: true,
                                            message: "*名前を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.user_name}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='email'>
                                    メールアドレス
                                    <RequiredMark />
                                </label>
                                <input
                                    id='email'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "*メールアドレスを入力してください"
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                                            message: "*メールアドレスが正しいか確認してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.email && errors.email?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.email}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>会社</label>
                                <input
                                    type='text'
                                    defaultValue={user.company_name}
                                    className='form-control'
                                    disabled='disabled'
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    事業所
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='office_id'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*事業所を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {Object.keys(offices).length > 0 && (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        選択してください
                                                    </MenuItem>
                                                    {offices.map((item, index) => (
                                                        <MenuItem key={index} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_id && errors.office_id?.message}</small>
                                <small className='text-danger'>{user.success ? "" : user.error_list.office_id}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='authority'>
                                    権限
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='authority'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*権限を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {Object.keys(authorities).length > 0 && (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        選択してください
                                                    </MenuItem>
                                                    {authorities.map((item, index) => (
                                                        <MenuItem key={index} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.authority && errors.authority?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.authority}</small>
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <DataShowDialog
                title='ユーザー情報'
                open={dialog.open}
                data={dialog.data}
                onClose={actionClose}
                onActions={actions}
            />
        </div>
    );
}

export default UserEdit;
