import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { UserContext } from "../providers/UserProvider";

import MenuButton from "../components/MenuButton";
import MenuDown from "../components/MenuDown";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            padding: "9px",
            fontSize: "1.6rem"
        },
        loggedInTitle: {
            padding: "9px",
            fontSize: "1.6rem",
            cursor: "pointer"
        },
        menu: {
            padding: "9px"
        },
        navTop: {
            display: "flex",
            justifyContent: "space-between"
            // marginBottom: ".5rem"
            // borderBottom: "1px solid #666"
        }
    })
);

function Header() {
    const { userInfo } = useContext(UserContext);
    const isLogin = userInfo.isLogin;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移するため
    const navigate = useNavigate();

    const handleClick = (e, path) => {
        // イベント伝播阻止
        e.stopPropagation();
        navigate(path);
    };

    return (
        <>
            {/* -------------------- issue507start -------------------- */}
            {isLogin ? (
                <div className={classes.loggedInTitle} onClick={(e) => handleClick(e, "/home")}>
                    相談支援サポートシステム
                </div>
            ) : (
                <div className={classes.title}>相談支援サポートシステム</div>
            )}
            <div className={classes.menu}>
                <div className={classes.navTop}>
                    <MenuButton category='ログイン' />
                    {/* ---------- 20230325 issue613 ---------- */}
                    <MenuButton category='利用者情報' />
                    <MenuDown category='相談支援関連' />
                    <MenuDown category='加算関連' />
                    {/* ---------- 20230325 issue613 END ---------- */}
                    <MenuButton category='日報' />
                    <MenuButton category='スケジュール' />
                    <MenuDown category='アカウント' />
                </div>
            </div>
        </>
    );
}

export default Header;
