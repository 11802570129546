import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import { Button, Select, MenuItem } from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
    })
);

// 初期状態
const initMonitoringDraft = { success: true };

//---------------------------issue{No.342} start-----------------------------
// 日付をyyyymmddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + m + d;
}
// 日付を取得
const numToday = Number(dayFormat(new Date()));
//---------------------------issue{No.342} end-------------------------------

function MonitoringDraftEdit() {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 利用者idとモニタリング記録のidを保持している
    const { client, id } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付の入力制限を設ける為に日付の上限値として用いる定数を読み込みます
    const { MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // monitoringDraft の状態を管理する
    const [monitoringDraft, setMonitoringDraft] = useState(initMonitoringDraft);

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // watch内で使用する利用者の生年月日
    const clientBirthdayRef = useRef(""); // issue516

    //---------------------------issue{No.513} start-----------------------------
    // 最新のクライアント情報に更新するボタンの表示の判定に使う
    const [clientChangeable, setClientChangeable] = useState(false);

    // レコードと最新のクライアント情報を比較し見つかった変更箇所の値を管理する
    const [implementClientData, setImplementClientData] = useState({});

    // リクエスト送信時に渡すべき利用者情報の連想配列を管理する
    const [storeClient, setStoreClient] = useState(clientData);

    // 更新内容を引用したかどうかを管理する
    const [citingClient, setCitingClient] = useState("");
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    // 今日以前の直近の作成済みレコードの日付を数値で保存する
    const [mostRecentDate, setMostRecentDate] = useState(0);

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");

    // 情報を取得しステート monitoringDraft にセットする
    const getMonitoringDraftData = async (id) => {
        const url = `monitoring_drafts/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setMonitoringDraft({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
        }
    };

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `monitoring_drafts/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                //---------------------------issue{No.513} start-----------------------------
                // user_name: clientResult.user_name,
                //---------------------------issue{No.513} end-------------------------------
                client_id: clientResult.client_id,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
            });

            //---------------------------issue{No.516} start-------------------------------
            clientBirthdayRef.current =
                clientResult.is_child == 1 ? clientResult.child_birthday : clientResult.client_birthday;
            //---------------------------issue{No.516} end-------------------------------
        }
    };

    //---------------------------issue{No.471} start-----------------------------
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name,
                };
            });
            setUsers(usersName);
        }
    };
    //---------------------------issue{No.471} end-------------------------------

    // クライアントの各機能ごとに、すでにあるレコードのdateカラムのデータを取得する
    const getDateList = async (clientId) => {
        const url = `monitoring_drafts_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 今日以前の直近の日付のみ取得する
            const result = Math.max.apply(
                null,
                res.data.result
                    .map((item) => {
                        const date = item.date;
                        const formatDate = Number(date.replace(/[-]/g, ""));
                        if (formatDate <= numToday) {
                            return formatDate;
                        }
                    })
                    .filter((e) => e)
            );
            // ステートに保存する
            setMostRecentDate(result);

            //---------------------------issue{No.453} start-----------------------------
            // 取得したデータの作成日を被り対象から除外して、dateListを作成
            const resultList = res.data.result
                .filter((item) => item.date !== monitoringDraft.date)
                .map((item) => {
                    return item.date;
                });
            dateList.current = resultList;
            //---------------------------issue{No.453} end-------------------------------
        }
    };

    // //利用者名クリック時、利用者情報表示画面へ
    // const handleOnClickShow = (e) => {
    //     // イベントの伝搬を中止
    //     e.stopPropagation();

    //     if (client) {
    //         // URL(/client/show/${client.id})をたたく（RouterConfig.js）
    //         navigate(`/client_select/show/${client}`);
    //     }
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 保存ボタン押下時
    const monitoringDraftSubmit = async (data) => {
        //---------------------------issue{No.453} start-----------------------------
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "登録内容を見直してください",
                timer: 2000,
            });
            return;
        }
        //---------------------------issue{No.453} end-------------------------------

        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;
        //---------------------------issue{No.513} end-------------------------------

        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });
        // キャンセル処理
        if (!confirm) {
            return;
        }

        // MonitoringDraftControllerに渡す値をセット
        const monitoringDraftData = {
            company_id: monitoringDraft.company_id,
            office_id: monitoringDraft.office_id,
            user_id: monitoringDraft.user_id,
            client_id: monitoringDraft.client_id,
            //---------------------------issue{No.513} start-----------------------------
            client_name: storeClient.client_name,
            //---------------------------issue{No.471} start-----------------------------
            user_name: data.user_name,
            //---------------------------issue{No.471} end-------------------------------
            date: data.date,
            //---------------------------issue{No.513} end-------------------------------
            start_time: data.start_time,
            end_time: data.end_time,
            location: data.location,
            checklist: data.checklist,
            support: data.support,
            client_state: data.client_state,
            supplement: data.supplement,
        };

        // 保存
        const url = `monitoring_drafts/${id}`;
        const res = await api.put(url, monitoringDraftData);
        if (res.status === 422) {
            setMonitoringDraft({
                ...monitoringDraft,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/monitoring_draft/${client}`);
            });
        }
    };

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        getMonitoringDraftData(id);
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        getDateList(client);
    }, []);

    // 今回の編集対象が条件に当てはまった時のみ、最新のクライアント情報を適用するボタンを表示し、変更内容をステートに保存する
    useEffect(() => {
        if (Object.keys(monitoringDraft).length > 1 && Object.keys(clientData).length > 0 && mostRecentDate) {
            const numDate = Number(monitoringDraft.date.replace(/[-]/g, ""));
            if (numDate === mostRecentDate || numDate > numToday) {
                const checkClientChange = () => {
                    let changeData = {};
                    //---------------------------issue{No.513} start-----------------------------
                    for (let key in clientData) {
                        if (clientData[key] && monitoringDraft[key] && clientData[key] != monitoringDraft[key]) {
                            setClientChangeable(true);
                            changeData[key] = clientData[key];
                        }
                    }
                    setImplementClientData(changeData);
                    //---------------------------issue{No.513} end-------------------------------
                };
                checkClientChange();
            }
        }
    }, [monitoringDraft, clientData, mostRecentDate]);

    //---------------------------issue{No.513} start-----------------------------
    // 更新内容を引用する準備ができたらステートを更新する
    useEffect(() => {
        if (Object.keys(implementClientData).length > 0) {
            setClientChangeable(true);
            setCitingClient("ready");
        }
    }, [implementClientData]);
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 利用者情報の更新内容を引用し直す
    const implementClientChanges = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (Object.keys(implementClientData).length > 0) {
            if (citingClient === "ready") {
                // 確認のアラートを表示する
                await swal({
                    icon: "info",
                    title: "確認",
                    text: "登録済みの利用者情報を適用しますか？",
                    buttons: true,
                }).then(async (result) => {
                    if (result) {
                        if (implementClientData.user_id) {
                            // 確認のアラートを表示する
                            let confirm = false;

                            await swal({
                                icon: "warning",
                                title: "確認",
                                text: "担当職員が更新されています。適用しますか？",
                                buttons: true,
                            }).then((result) => {
                                if (result) {
                                    confirm = true;
                                }
                            });

                            // キャンセル処理
                            if (!confirm) {
                                return;
                            }
                        }

                        // 以下、引用のための処理
                        runCitingClient();
                    }
                });
            }
        }
    };

    // 引用した内容を編集前に戻す
    const putBackClientChanges = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        for (let key in implementClientData) {
            // 更新対象のフォーム内の項目を変更
            setValue(key, monitoringDraft[key], { shouldValidate: false });
        }
        setStoreClient(monitoringDraft);
        setCitingClient("ready");
    };
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    const runCitingClient = () => {
        if (Object.keys(clientData).length > 0 && citingClient === "ready") {
            setStoreClient(clientData);
            setCitingClient("done");
        }
    };
    //---------------------------issue{No.513} end-------------------------------

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        //---------------------------issue{No.471} start-----------------------------
        control,
        //---------------------------issue{No.471} end-------------------------------
        //---------------------------issue{No.453} start-----------------------------
        watch,
        //---------------------------issue{No.453} end-------------------------------
    } = useForm();

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(monitoringDraft).length > 1 && !getValues("date")) {
            setValue("date", monitoringDraft.date, { shouldValidate: false });
            //---------------------------issue{No.471} start-----------------------------
            setValue("user_name", monitoringDraft.user_name, { shouldValidate: false });
            //---------------------------issue{No.471} end-------------------------------
            setValue("start_time", monitoringDraft.start_time, { shouldValidate: false });
            setValue("end_time", monitoringDraft.end_time, { shouldValidate: false });
            setValue("location", monitoringDraft.location, { shouldValidate: false });
            setValue("checklist", monitoringDraft.checklist, { shouldValidate: false });
            setValue("support", monitoringDraft.support, { shouldValidate: false });
            setValue("client_state", monitoringDraft.client_state, { shouldValidate: false });
            setValue("supplement", monitoringDraft.supplement, { shouldValidate: false });

            setStoreClient(monitoringDraft);
        }
    }, [monitoringDraft]);

    //---------------------------issue{No.453} start-----------------------------
    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change" && name === "date") {
                setDateMessage(
                    dateRegisteringErrorMessage(value.date, clientBirthdayRef.current, dateList.current)
                );
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    //---------------------------issue{No.453} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>モニタリング記録編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        {citingClient === "done" && (
                            <div className='text-end mb-2'>
                                <p className='d-inline-block text-decoration-underline'>
                                    ※保存時に現在の利用者情報が適用されます
                                </p>
                            </div>
                        )}
                        <div className='d-flex justify-content-between  mb-4'>
                            <p className='fs-6 pt-2'>
                                <RequiredMark />
                                <span className='text-secondary'>は必須項目です。</span>
                            </p>
                            <div>
                                {clientChangeable && (
                                    <div className='d-flex mb-2'>
                                        {citingClient === "done" && (
                                            <div className='pe-2'>
                                                <Button
                                                    type='button'
                                                    variant='contained'
                                                    onClick={(e) => {
                                                        putBackClientChanges(e);
                                                    }}>
                                                    利用者情報を
                                                    <br />
                                                    編集前に戻す
                                                </Button>
                                            </div>
                                        )}
                                        {citingClient === "ready" ? (
                                            <Button
                                                type='button'
                                                variant='contained'
                                                color='secondary'
                                                onClick={(e) => {
                                                    implementClientChanges(e);
                                                }}>
                                                登録されている利用者の情報と
                                                <br />
                                                異なる入力内容があります
                                            </Button>
                                        ) : (
                                            <Button type='button' variant='contained' color='primary'>
                                                登録されている利用者の内容を
                                                <br />
                                                引用済み
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(monitoringDraftSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    実施年月日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*実施年月日を入力してください。",
                                        },
                                    })}
                                />
                                {/* 日付の重複によるエラーメッセージ */}
                                <small className='text-danger'>{dateMessage && dateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.date}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1'>
                                    相談支援員氏名
                                    <RequiredMark />
                                </label>
                                {Object.keys(users).length > 0 && (
                                    <Controller
                                        name='user_name'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*相談支援員氏名を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        {Object.keys(users).length === 0 ? (
                                                            <>ユーザーが登録されていません</>
                                                        ) : (
                                                            <>選択してください</>
                                                        )}
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.user_name}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                )}
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.user_name}
                                </small>
                            </div>
                            <hr className='mb-4' />
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                </label>
                                <div className='form-control mb-1'>{storeClient?.client_name}</div>
                            </div>
                            <hr className='mb-5' />
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    実施時間
                                    <RequiredMark />
                                </label>
                                <div className='d-flex justify-content-start'>
                                    <input
                                        id='start_time'
                                        type='time'
                                        className='form-control mb-1'
                                        {...register("start_time", {
                                            required: {
                                                value: true,
                                                message: "*開始時刻を入力してください。",
                                            },
                                            validate: {
                                                message: (value) => {
                                                    const endTime = getValues("end_time");
                                                    if (value && endTime && value > endTime) {
                                                        return `*実施時間を見直してください。`;
                                                    } else {
                                                        return null;
                                                    }
                                                },
                                            },
                                        })}
                                    />
                                    <span className='d-block p-2 lh-lg align-items-center'>～</span>
                                    <input
                                        id='end_time'
                                        type='time'
                                        className='form-control mb-1'
                                        {...register("end_time", {
                                            required: {
                                                value: true,
                                                message: "*終了時刻を入力してください。",
                                            },
                                        })}
                                    />
                                </div>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.start_time && errors.start_time?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.start_time}
                                </small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.end_time && errors.end_time?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.end_time}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='location'>
                                    実施場所
                                    <RequiredMark />
                                </label>
                                <input
                                    id='location'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("location", {
                                        required: {
                                            value: true,
                                            message: "*実施場所を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.location && errors.location?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.location}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='checklist'>
                                    確認事項
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='checklist'
                                    className='form-control mb-1'
                                    {...register("checklist", {
                                        required: {
                                            value: true,
                                            message: "*確認事項を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*確認事項を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.checklist && errors.checklist?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.checklist}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='support'>
                                    提供支援
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='support'
                                    className='form-control mb-1'
                                    {...register("support", {
                                        required: {
                                            value: true,
                                            message: "*提供支援を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*提供支援を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.support && errors.support?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.support}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_state'>
                                    利用者の様子
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='client_state'
                                    className='form-control mb-1'
                                    {...register("client_state", {
                                        required: {
                                            value: true,
                                            message: "*利用者の様子を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*利用者の様子を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_state && errors.client_state?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.client_state}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='supplement'>
                                    その他
                                </label>
                                <textarea id='supplement' className='form-control mb-1' {...register("supplement")} />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.supplement && errors.supplement?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.supplement}
                                </small>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MonitoringDraftEdit;
