import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDataList, {rowEnclosureButton} from "../../components/ClientSelectToDataList";

// 見出しの配列定義
const headers = ['開催年月日'];

export default function ServicePersonnelMeeting() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDataList
        pageName='サービス担当者会議記録'
        targetURL='service_personnel_meeting'
        headerDataList={headers}
        clientID={client} />;
}