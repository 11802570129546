import React, { useContext, useEffect } from "react";
import { Routes as BrowserRoutes, Route, useLocation } from "react-router-dom";
import { UserContext } from "../providers/UserProvider";
import swal from "sweetalert";

import Login from "../contents/Login";
import Logout from "../contents/Logout";
import Home from "../contents/Home";
import NotFound from "../contents/NotFound";

import User from "../contents/user/User";
import UserEdit from "../contents/user/UserEdit";
import UserRegister from "../contents/user/UserRegister";
import UserShow from "../contents/user/UserShow";
import UserDeleted from "../contents/user/UserDeleted";
import UserDeletedShow from "../contents/user/UserDeletedShow";

import Company from "../contents/company/Company";
import CompanyRegister from "../contents/company/CompanyRegister";
import CompanyShow from "../contents/company/CompanyShow";
import CompanyEdit from "../contents/company/CompanyEdit";
import CompanyDeleted from "../contents/company/CompanyDeleted";
import CompanyDeletedShow from "../contents/company/CompanyDeletedShow";

import Office from "../contents/office/Office";
import OfficeRegister from "../contents/office/OfficeRegister";
import OfficeShow from "../contents/office/OfficeShow";
import OfficeEdit from "../contents/office/OfficeEdit";
import OfficeDeleted from "../contents/office/OfficeDeleted";
import OfficeDeletedShow from "../contents/office/OfficeDeletedShow";

import Client from "../contents/client/Client";
import ClientRegister from "../contents/client/ClientRegister";
import ClientShow from "../contents/client/ClientShow";
import ClientEdit from "../contents/client/ClientEdit";
import ClientDeleted from "../contents/client/ClientDeleted";
import ClientDeletedShow from "../contents/client/ClientDeletedShow";

import ConsultationRequest from "../contents/consultation_request/ConsultationRequest";
import ConsultationRequestRegister from "../contents/consultation_request/ConsultationRequestRegister";
import ConsultationRequestShow from "../contents/consultation_request/ConsultationRequestShow";
import ConsultationRequestEdit from "../contents/consultation_request/ConsultationRequestEdit";
import ConsultationRequestDeleted from "../contents/consultation_request/ConsultationRequestDeleted";
import ConsultationRequestDeletedShow from "../contents/consultation_request/ConsultationRequestDeletedShow";

import UserSelectShow from "../components/UserSelectShow";
import Report from "../contents/report/Report";
import ReportRegister from "../contents/report/ReportRegister";
import ReportRegisterPerson from "../contents/report/ReportRegisterPerson";
import ReportShow from "../contents/report/ReportShow";
import ReportEdit from "../contents/report/ReportEdit";
import ReportDeleted from "../contents/report/ReportDeleted";
import ReportDeletedShow from "../contents/report/ReportDeletedShow";

import Application from "../contents/application/Application";
import ApplicationShow from "../contents/application/ApplicationShow";
import ApplicationEdit from "../contents/application/ApplicationEdit";
import ApplicationRegister from "../contents/application/ApplicationRegister";
import ApplicationDeleted from "../contents/application/ApplicationDeleted";
import ApplicationDeletedShow from "../contents/application/ApplicationDeletedShow";

import ClientSelectShow from "../components/ClientSelectShow";

import Assessment_1 from "../contents/assessment_1/Assessment_1";
import Assessment_1Show from "../contents/assessment_1/Assessment_1Show";
import Assessment_1Edit from "../contents/assessment_1/Assessment_1Edit";
import Assessment_1Register from "../contents/assessment_1/Assessment_1Register";
import Assessment_1Deleted from "../contents/assessment_1/Assessment_1Deleted";
import Assessment_1DeletedShow from "../contents/assessment_1/Assessment_1DeletedShow";

import Assessment_2Show from "../contents/assessment_2/Assessment_2Show";
import Assessment_2Edit from "../contents/assessment_2/Assessment_2Edit";
import Assessment_2Register from "../contents/assessment_2/Assessment_2Register";

import ProposedPlan_1 from "../contents/proposed_plan_1/ProposedPlan_1";
import ProposedPlan_1Show from "../contents/proposed_plan_1/ProposedPlan_1Show";
import ProposedPlan_1Edit from "../contents/proposed_plan_1/ProposedPlan_1Edit";
import ProposedPlan_1Register from "../contents/proposed_plan_1/ProposedPlan_1Register";
import ProposedPlan_1Deleted from "../contents/proposed_plan_1/ProposedPlan_1Deleted";
import ProposedPlan_1DeletedShow from "../contents/proposed_plan_1/ProposedPlan_1DeletedShow";

import ProposedPlan_2Show from "../contents/proposed_plan_2/ProposedPlan_2Show";
import ProposedPlan_2Edit from "../contents/proposed_plan_2/ProposedPlan_2Edit";
import ProposedPlan_2Register from "../contents/proposed_plan_2/ProposedPlan_2Register";

import Plan_1 from "../contents/plan_1/Plan_1";
import Plan_1Show from "../contents/plan_1/Plan_1Show";
import Plan_1Edit from "../contents/plan_1/Plan_1Edit";
import Plan_1Register from "../contents/plan_1/Plan_1Register";
import Plan_1Deleted from "../contents/plan_1/Plan_1Deleted";
import Plan_1DeletedShow from "../contents/plan_1/Plan_1DeletedShow";

import Plan_2Show from "../contents/plan_2/Plan_2Show";
import Plan_2Edit from "../contents/plan_2/Plan_2Edit";
import Plan_2Register from "../contents/plan_2/Plan_2Register";

import MonitoringDraft from "../contents/monitoring_draft/MonitoringDraft";
import MonitoringDraftShow from "../contents/monitoring_draft/MonitoringDraftShow";
import MonitoringDraftEdit from "../contents/monitoring_draft/MonitoringDraftEdit";
import MonitoringDraftRegister from "../contents/monitoring_draft/MonitoringDraftRegister";
import MonitoringDraftDeleted from "../contents/monitoring_draft/MonitoringDraftDeleted";
import MonitoringDraftDeletedShow from "../contents/monitoring_draft/MonitoringDraftDeletedShow";

import Monitoring_1 from "../contents/monitoring_1/Monitoring_1";
import Monitoring_1Show from "../contents/monitoring_1/Monitoring_1Show";
import Monitoring_1Edit from "../contents/monitoring_1/Monitoring_1Edit";
import Monitoring_1Register from "../contents/monitoring_1/Monitoring_1Register";
import Monitoring_1Deleted from "../contents/monitoring_1/Monitoring_1Deleted";
import Monitoring_1DeletedShow from "../contents/monitoring_1/Monitoring_1DeletedShow";

import Monitoring_2Show from "../contents/monitoring_2/Monitoring_2Show";
import Monitoring_2Edit from "../contents/monitoring_2/Monitoring_2Edit";
import Monitoring_2Register from "../contents/monitoring_2/Monitoring_2Register";

import DischargeAddition from "../contents/discharge_addition/DischargeAddition";
import DischargeAdditionShow from "../contents/discharge_addition/DischargeAdditionShow";
import DischargeAdditionEdit from "../contents/discharge_addition/DischargeAdditionEdit";
import DischargeAdditionRegister from "../contents/discharge_addition/DischargeAdditionRegister";

import ServicePersonnelMeeting from "../contents/service_personnel_meeting/ServicePersonnelMeeting";
import ServicePersonnelMeetingShow from "../contents/service_personnel_meeting/ServicePersonnelMeetingShow";
import ServicePersonnelMeetingEdit from "../contents/service_personnel_meeting/ServicePersonnelMeetingEdit";
import ServicePersonnelMeetingRegister from "../contents/service_personnel_meeting/ServicePersonnelMeetingRegister";

import InstitutionAlignment from "../contents/institution_alignment/InstitutionAlignment";
import InstitutionAlignmentShow from "../contents/institution_alignment/InstitutionAlignmentShow";
import InstitutionAlignmentEdit from "../contents/institution_alignment/InstitutionAlignmentEdit";
import InstitutionAlignmentRegister from "../contents/institution_alignment/InstitutionAlignmentRegister";

import HospitalizationInfoDocument from "../contents/hospitalization_info_document/HospitalizationInfoDocument";
import HospitalizationInfoDocumentShow from "../contents/hospitalization_info_document/HospitalizationInfoDocumentShow";
import HospitalizationInfoDocumentEdit from "../contents/hospitalization_info_document/HospitalizationInfoDocumentEdit";
import HospitalizationInfoDocumentRegister from "../contents/hospitalization_info_document/HospitalizationInfoDocumentRegister";

import NursingOfficeAlignment from "../contents/nursing_office_alignment/NursingOfficeAlignment";
import NursingOfficeAlignmentShow from "../contents/nursing_office_alignment/NursingOfficeAlignmentShow";
import NursingOfficeAlignmentEdit from "../contents/nursing_office_alignment/NursingOfficeAlignmentEdit";
import NursingOfficeAlignmentRegister from "../contents/nursing_office_alignment/NursingOfficeAlignmentRegister";
import Schedule from "../contents/schedule/Schedule";
import ScheduleRegister from "../contents/schedule/ScheduleRegister";
import ScheduleReferenceMonthEdit from "../contents/schedule/ScheduleReferenceMonthEdit";
import ScheduleBulkRegister from "../contents/schedule/ScheduleBulkRegister";

function RouterConfig() {
    const { userInfo } = useContext(UserContext);
    const isLogin = userInfo.isLogin;

    const isLoggedin = (component) => {
        if (isLogin) {
            return component;
        }
        return <NotFound />;
    };

    //---------------------------issue{No.387} start-----------------------------
    // URLの変化を検出する
    const location = useLocation();

    // ページ遷移時にSweetAlertが表示されているとき、SweetAlertのモーダルウィンドウを閉じる
    useEffect(() => {
        if (swal.getState().isOpen) {
            swal.close();
        }
    }, [location]);
    //---------------------------issue{No.387} end-------------------------------

    return (
        <BrowserRoutes>
            <Route path='/' element={<Login />} />
            <Route path='/login' element={<Login />} />
            <Route path='/logout' element={<Logout />} />
            <Route path='/home' element={isLoggedin(<Home />)} />
            <Route path='/users' element={isLoggedin(<User />)} />
            <Route path='/user/edit/:id' element={isLoggedin(<UserEdit />)} />
            <Route path='/user/show/:id' element={isLoggedin(<UserShow />)} />
            <Route path='/user_deleted' element={isLoggedin(<UserDeleted />)} />
            <Route path='/user_deleted/show/:id' element={isLoggedin(<UserDeletedShow />)} />
            <Route path='/register' element={isLoggedin(<UserRegister />)} />
            <Route path='/companies' element={isLoggedin(<Company />)} />
            <Route path='/company/show/:id' element={isLoggedin(<CompanyShow />)} />
            <Route path='/company/edit/:id' element={isLoggedin(<CompanyEdit />)} />
            <Route path='/company_register' element={isLoggedin(<CompanyRegister />)} />
            <Route path='/company_deleted' element={isLoggedin(<CompanyDeleted />)} />
            <Route path='/company_deleted/show/:id' element={isLoggedin(<CompanyDeletedShow />)} />
            <Route path='/office' element={isLoggedin(<Office />)} />
            <Route path='/office/show/:id' element={isLoggedin(<OfficeShow />)} />
            <Route path='/office/edit/:id' element={isLoggedin(<OfficeEdit />)} />
            <Route path='/office_register' element={isLoggedin(<OfficeRegister />)} />
            <Route path='/office_deleted' element={isLoggedin(<OfficeDeleted />)} />
            <Route path='/office_deleted/show/:id' element={isLoggedin(<OfficeDeletedShow />)} />

            <Route path='/client' element={isLoggedin(<Client />)} />
            <Route path='/client/show/:id' element={isLoggedin(<ClientShow />)} />
            <Route path='/client/edit/:id' element={isLoggedin(<ClientEdit />)} />
            <Route path='/client_register' element={isLoggedin(<ClientRegister />)} />
            <Route path='/client_deleted' element={isLoggedin(<ClientDeleted />)} />
            <Route path='/client_deleted/show/:id' element={isLoggedin(<ClientDeletedShow />)} />

            <Route path='/consultation_request' element={isLoggedin(<ConsultationRequest />)} />
            <Route path='/consultation_request/:client' element={isLoggedin(<ConsultationRequest />)} />
            <Route path='/consultation_request/show/:client/:id' element={isLoggedin(<ConsultationRequestShow />)} />
            <Route path='/consultation_request/edit/:client/:id' element={isLoggedin(<ConsultationRequestEdit />)} />
            <Route
                path='/consultation_request_register/:client'
                element={isLoggedin(<ConsultationRequestRegister />)}
            />
            <Route
                path='/consultation_request_register/:client/:id'
                element={isLoggedin(<ConsultationRequestRegister />)}
            />
            <Route path='/consultation_request_deleted/:client' element={isLoggedin(<ConsultationRequestDeleted />)} />
            <Route
                path='/consultation_request_deleted/show/:client/:id'
                element={isLoggedin(<ConsultationRequestDeletedShow />)}
            />

            <Route path='/application' element={isLoggedin(<Application />)} />
            <Route path='/application/:client' element={isLoggedin(<Application />)} />
            <Route path='/application/show/:client/:id' element={isLoggedin(<ApplicationShow />)} />
            <Route path='/application/edit/:client/:id' element={isLoggedin(<ApplicationEdit />)} />
            <Route path='/application_register/:client' element={isLoggedin(<ApplicationRegister />)} />
            <Route path='/application_register/:client/:id' element={isLoggedin(<ApplicationRegister />)} />
            <Route path='/application_deleted/:client' element={isLoggedin(<ApplicationDeleted />)} />
            <Route path='/application_deleted/show/:client/:id' element={isLoggedin(<ApplicationDeletedShow />)} />

            <Route path='/client_select/show/:id' element={isLoggedin(<ClientSelectShow />)} />

            <Route path='/assessment_1' element={isLoggedin(<Assessment_1 />)} />
            <Route path='/assessment_1/:client' element={isLoggedin(<Assessment_1 />)} />
            <Route path='/assessment_1/show/:client/:id' element={isLoggedin(<Assessment_1Show />)} />
            <Route path='/assessment_1/edit/:client/:id' element={isLoggedin(<Assessment_1Edit />)} />
            <Route path='/assessment_1_register/:client' element={isLoggedin(<Assessment_1Register />)} />
            <Route path='/assessment_1_register/:client/:id' element={isLoggedin(<Assessment_1Register />)} />
            <Route path='/assessment_1_deleted/:client' element={isLoggedin(<Assessment_1Deleted />)} />
            <Route path='/assessment_1_deleted/show/:client/:id' element={isLoggedin(<Assessment_1DeletedShow />)} />

            <Route path='/assessment_2/show/:client/:id' element={isLoggedin(<Assessment_2Show />)} />
            <Route path='/assessment_2/edit/:client/:id' element={isLoggedin(<Assessment_2Edit />)} />
            <Route path='/assessment_2_register/:client/:id' element={isLoggedin(<Assessment_2Register />)} />

            <Route path='/proposed_plan_1' element={isLoggedin(<ProposedPlan_1 />)} />
            <Route path='/proposed_plan_1/:client' element={isLoggedin(<ProposedPlan_1 />)} />
            <Route path='/proposed_plan_1/show/:client/:id' element={isLoggedin(<ProposedPlan_1Show />)} />
            <Route path='/proposed_plan_1/edit/:client/:id' element={isLoggedin(<ProposedPlan_1Edit />)} />
            <Route path='/proposed_plan_1_register/:client' element={isLoggedin(<ProposedPlan_1Register />)} />
            <Route path='/proposed_plan_1_register/:client/:id' element={isLoggedin(<ProposedPlan_1Register />)} />
            <Route path='/proposed_plan_1_deleted/:client' element={isLoggedin(<ProposedPlan_1Deleted />)} />
            <Route
                path='/proposed_plan_1_deleted/show/:client/:id'
                element={isLoggedin(<ProposedPlan_1DeletedShow />)}
            />

            <Route path='/proposed_plan_2/show/:client/:id' element={isLoggedin(<ProposedPlan_2Show />)} />
            <Route path='/proposed_plan_2/edit/:client/:id' element={isLoggedin(<ProposedPlan_2Edit />)} />
            <Route path='/proposed_plan_2_register/:client/:id' element={isLoggedin(<ProposedPlan_2Register />)} />

            <Route path='/plan_1' element={isLoggedin(<Plan_1 />)} />
            <Route path='/plan_1/:client' element={isLoggedin(<Plan_1 />)} />
            <Route path='/plan_1/show/:client/:id' element={isLoggedin(<Plan_1Show />)} />
            <Route path='/plan_1/edit/:client/:id' element={isLoggedin(<Plan_1Edit />)} />
            <Route path='/plan_1_register/:client' element={isLoggedin(<Plan_1Register />)} />
            <Route path='/plan_1_register/:client/:id' element={isLoggedin(<Plan_1Register />)} />
            <Route path='/plan_1_deleted/:client' element={isLoggedin(<Plan_1Deleted />)} />
            <Route path='/plan_1_deleted/show/:client/:id' element={isLoggedin(<Plan_1DeletedShow />)} />

            <Route path='/plan_2/show/:client/:id' element={isLoggedin(<Plan_2Show />)} />
            <Route path='/plan_2/edit/:client/:id' element={isLoggedin(<Plan_2Edit />)} />
            <Route path='/plan_2_register/:client/:id' element={isLoggedin(<Plan_2Register />)} />

            <Route path='/monitoring_draft' element={isLoggedin(<MonitoringDraft />)} />
            <Route path='/monitoring_draft/:client' element={isLoggedin(<MonitoringDraft />)} />
            <Route path='/monitoring_draft/show/:client/:id' element={isLoggedin(<MonitoringDraftShow />)} />
            <Route path='/monitoring_draft/edit/:client/:id' element={isLoggedin(<MonitoringDraftEdit />)} />
            <Route path='/monitoring_draft_register/:client' element={isLoggedin(<MonitoringDraftRegister />)} />
            <Route path='/monitoring_draft_register/:client/:id' element={isLoggedin(<MonitoringDraftRegister />)} />
            <Route path='/monitoring_draft_deleted/:client' element={isLoggedin(<MonitoringDraftDeleted />)} />
            <Route
                path='/monitoring_draft_deleted/show/:client/:id'
                element={isLoggedin(<MonitoringDraftDeletedShow />)}
            />

            <Route path='/monitoring_1' element={isLoggedin(<Monitoring_1 />)} />
            <Route path='/monitoring_1/:client' element={isLoggedin(<Monitoring_1 />)} />
            <Route path='/monitoring_1/show/:client/:id' element={isLoggedin(<Monitoring_1Show />)} />
            <Route path='/monitoring_1/edit/:client/:id' element={isLoggedin(<Monitoring_1Edit />)} />
            <Route path='/monitoring_1_register/:client' element={isLoggedin(<Monitoring_1Register />)} />
            <Route path='/monitoring_1_register/:client/:id' element={isLoggedin(<Monitoring_1Register />)} />
            <Route path='/monitoring_1_deleted/:client' element={isLoggedin(<Monitoring_1Deleted />)} />
            <Route path='/monitoring_1_deleted/show/:client/:id' element={isLoggedin(<Monitoring_1DeletedShow />)} />

            <Route path='/monitoring_2/show/:client/:id' element={isLoggedin(<Monitoring_2Show />)} />
            <Route path='/monitoring_2/edit/:client/:id' element={isLoggedin(<Monitoring_2Edit />)} />
            <Route path='/monitoring_2_register/:client/:id' element={isLoggedin(<Monitoring_2Register />)} />

            <Route path='/user_select/show/:id' element={isLoggedin(<UserSelectShow />)} />
            <Route path='/report' element={isLoggedin(<Report />)} />
            <Route path='/report/:user' element={isLoggedin(<Report />)} />
            <Route path='/report/show/:user/:date' element={isLoggedin(<ReportShow />)} />
            <Route path='/report/edit/:user/:date' element={isLoggedin(<ReportEdit />)} />
            <Route path='/report_register/:user' element={isLoggedin(<ReportRegister />)} />
            <Route path='/report_register_client/:client' element={isLoggedin(<ReportRegisterPerson />)} />

            <Route path='/discharge_addition' element={isLoggedin(<DischargeAddition />)} />
            <Route path='/discharge_addition/:client' element={isLoggedin(<DischargeAddition />)} />
            <Route path='/discharge_addition/show/:client/:id' element={isLoggedin(<DischargeAdditionShow />)} />
            <Route path='/discharge_addition/edit/:client/:id' element={isLoggedin(<DischargeAdditionEdit />)} />
            <Route path='/discharge_addition_register/:client' element={isLoggedin(<DischargeAdditionRegister />)} />
            <Route
                path='/discharge_addition_register/:client/:id'
                element={isLoggedin(<DischargeAdditionRegister />)}
            />

            <Route path='/service_personnel_meeting' element={isLoggedin(<ServicePersonnelMeeting />)} />
            <Route path='/service_personnel_meeting/:client' element={isLoggedin(<ServicePersonnelMeeting />)} />
            <Route
                path='/service_personnel_meeting/show/:client/:id'
                element={isLoggedin(<ServicePersonnelMeetingShow />)}
            />
            <Route
                path='/service_personnel_meeting/edit/:client/:id'
                element={isLoggedin(<ServicePersonnelMeetingEdit />)}
            />
            <Route
                path='/service_personnel_meeting_register/:client'
                element={isLoggedin(<ServicePersonnelMeetingRegister />)}
            />
            <Route
                path='/service_personnel_meeting_register/:client/:id'
                element={isLoggedin(<ServicePersonnelMeetingRegister />)}
            />

            <Route path='/institution_alignment' element={isLoggedin(<InstitutionAlignment />)} />
            <Route path='/institution_alignment/:client' element={isLoggedin(<InstitutionAlignment />)} />
            <Route path='/institution_alignment/show/:client/:id' element={isLoggedin(<InstitutionAlignmentShow />)} />
            <Route path='/institution_alignment/edit/:client/:id' element={isLoggedin(<InstitutionAlignmentEdit />)} />
            <Route
                path='/institution_alignment_register/:client'
                element={isLoggedin(<InstitutionAlignmentRegister />)}
            />
            <Route
                path='/institution_alignment_register/:client/:id'
                element={isLoggedin(<InstitutionAlignmentRegister />)}
            />

            <Route path='/hospitalization_info_document' element={isLoggedin(<HospitalizationInfoDocument />)} />
            <Route
                path='/hospitalization_info_document/:client'
                element={isLoggedin(<HospitalizationInfoDocument />)}
            />
            <Route
                path='/hospitalization_info_document/show/:client/:id'
                element={isLoggedin(<HospitalizationInfoDocumentShow />)}
            />
            <Route
                path='/hospitalization_info_document/edit/:client/:id'
                element={isLoggedin(<HospitalizationInfoDocumentEdit />)}
            />
            <Route
                path='/hospitalization_info_document_register/:client'
                element={isLoggedin(<HospitalizationInfoDocumentRegister />)}
            />
            <Route
                path='/hospitalization_info_document_register/:client/:id'
                element={isLoggedin(<HospitalizationInfoDocumentRegister />)}
            />

            <Route path='/nursing_office_alignment' element={isLoggedin(<NursingOfficeAlignment />)} />
            <Route path='/nursing_office_alignment/:client' element={isLoggedin(<NursingOfficeAlignment />)} />
            <Route
                path='/nursing_office_alignment/show/:client/:id'
                element={isLoggedin(<NursingOfficeAlignmentShow />)}
            />
            <Route
                path='/nursing_office_alignment/edit/:client/:id'
                element={isLoggedin(<NursingOfficeAlignmentEdit />)}
            />
            <Route
                path='/nursing_office_alignment_register/:client/:id'
                element={isLoggedin(<NursingOfficeAlignmentRegister />)}
            />
            <Route
                path='/nursing_office_alignment_register/:client'
                element={isLoggedin(<NursingOfficeAlignmentRegister />)}
            />
            <Route path='/schedule' element={isLoggedin(<Schedule />)} />
            <Route path='/schedule_register' element={isLoggedin(<ScheduleRegister />)} />
            <Route path='/schedule/reference_month/:id' element={isLoggedin(<ScheduleReferenceMonthEdit />)} />
            <Route path='/schedule/bulk_register' element={isLoggedin(<ScheduleBulkRegister />)} />
            <Route path='*' element={<NotFound />} />
        </BrowserRoutes>
    );
}

export default RouterConfig;
