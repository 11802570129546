import React from "react";
import {useParams} from "react-router-dom";
import ClientSelectToDataList from "../../components/ClientSelectToDataList";

//見出しの配列定義
const headers = ["作成日"];

export default function DischargeAddition() {
    //表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDataList
        pageName='退院・退所加算'
        targetURL='discharge_addition'
        headerDataList={headers}
        clientID={client} />;
}