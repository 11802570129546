import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import { Button, Select, MenuItem, Checkbox, FormControlLabel } from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE, FREQUENCY_LIST } from "../../common/constants";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";
import formatSupportLevelListForEdit from "../../common/formatSupportLevelListForEdit";

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        priority: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004",
        },
        priorityNum: { lineHeight: "2rem" },
    })
);

// 初期状態
const initMonitoring_1 = { success: true };

//---------------------------issue{No.513} start-----------------------------
//---------------------------issue{No.513} end-------------------------------

// 日付をyyyymmddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + m + d;
}
// 日付を取得
const numToday = Number(dayFormat(new Date()));

function Monitoring_1Edit() {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 利用者idと相談支援支給申請のidを保持している
    const { client, id } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付の入力制限を設ける為に日付の上下限値として用いる定数を読み込みます
    const { MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // 入力値を管理する
    const [monitoring_1, setMonitoring_1] = useState(initMonitoring_1);

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // watch内で使用する利用者の生年月日
    const clientBirthdayRef = useRef(""); // issue516

    //---------------------------issue{No.513} start-----------------------------
    // 最新のクライアント情報に更新するボタンの表示の判定に使う
    const [clientChangeable, setClientChangeable] = useState(false);

    // レコードと最新のクライアント情報を比較し見つかった変更箇所の値を管理する
    const [implementClientData, setImplementClientData] = useState({});

    // リクエスト送信時に渡すべき利用者情報の連想配列を管理する
    const [storeClient, setStoreClient] = useState(clientData);

    // 更新内容を引用したかどうかを管理する
    const [citingClient, setCitingClient] = useState("");
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    // 今日以前の直近の作成済みレコードの日付を数値で保存する
    const [mostRecentDate, setMostRecentDate] = useState(0);

    //---------------------------issue{No.453} start-----------------------------
    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");
    //---------------------------issue{No.453} end-------------------------------

    // 優先順位ごとの入力欄の表示個数を管理する
    const [priorityCount, setPriorityCount] = useState(1);

    // 情報を取得しステート monitoring_1にセットする
    const getMonitoring_1Data = async (id) => {
        const url = `monitorings_1/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 支援目標の入力欄の表示個数を格納する
            let newPriorityCount = 1;
            // 支援目標の登録数に応じて、必要な入力欄の個数を調べる
            for (let ii = 2; ii <= 5; ii++) {
                if (res.data.result[`target_${ii}`]) {
                    newPriorityCount = ii;
                }
            }
            // 結果をステートにセットし、入力欄を表示させる
            setPriorityCount(newPriorityCount);

            setMonitoring_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
        }
    };

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `monitorings_1/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id,
                office_name: clientResult.office_name,
                //---------------------------issue{No.513} start-----------------------------
                // user_name: clientResult.user_name,
                //---------------------------issue{No.513} end-------------------------------
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                support_law: clientResult.support_law,
                support_level: clientResult.support_level,
                recipient_no_2: clientResult.recipient_no_2,
                recipient_no_3: clientResult.recipient_no_3,
                recipient_no_4: clientResult.recipient_no_4,
                frequency: clientResult.frequency,
            });

            clientBirthdayRef.current = clientResult.is_child
                ? clientResult.child_birthday
                : clientResult.client_birthday; // issue516
        }
    };

    //---------------------------issue{No.471} start-----------------------------
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name,
                };
            });
            setUsers(usersName);
        }
    };
    //---------------------------issue{No.471} end-------------------------------

    // クライアントの各機能ごとに、すでにあるレコードのdateカラムのデータを取得する
    const getDateList = async (clientId) => {
        const url = `monitorings_1_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 今日以前の直近の日付のみ取得する
            const result = Math.max.apply(
                null,
                res.data.result
                    .map((item) => {
                        const date = item.date;
                        const formatDate = Number(date.replace(/[-]/g, ""));
                        if (formatDate <= numToday) {
                            return formatDate;
                        }
                    })
                    .filter((e) => e)
            );
            // ステートに保存する
            setMostRecentDate(result);

            //---------------------------issue{No.453} start-----------------------------
            // 取得したデータの作成日を被り対象から除外して、dateListを作成
            const resultList = res.data.result
                .filter((item) => item.date !== monitoring_1.date)
                .map((item) => {
                    return item.date;
                });
            dateList.current = resultList;
            //---------------------------issue{No.453} end-------------------------------
        }
    };

    // //利用者名クリック時、利用者情報表示画面へ
    // const handleOnClickShow = (e) => {
    //     // イベントの伝搬を中止
    //     e.stopPropagation();

    //     if (client) {
    //         // URL(/client/show/${client.id})をたたく（RouterConfig.js）
    //         navigate(`/client_select/show/${client}`);
    //     }
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 保存ボタン押下時
    const monitoring_1Submit = async (data) => {
        //---------------------------issue{No.453} start-----------------------------
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "登録内容を見直してください",
                timer: 2000,
            });
            return;
        }
        //---------------------------issue{No.453} end-------------------------------

        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;
        //---------------------------issue{No.513} end-------------------------------

        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // Monitoring_1Controllerに渡す値をセット
        const monitoring_1Data = {
            company_id: monitoring_1.company_id,
            office_id: monitoring_1.office_id,
            user_id: monitoring_1.user_id,
            client_id: monitoring_1.client_id,
            date: data.date,
            client_name: storeClient.client_name,
            office_name: storeClient.office_name,
            //---------------------------issue{No.471} start-----------------------------
            user_name: data.user_name,
            //---------------------------issue{No.471} end-------------------------------
            support_law: storeClient.support_law,
            support_level: storeClient.support_level,
            maximum_amount: data.maximum_amount,
            recipient_no_2: storeClient.recipient_no_2,
            recipient_no_3: storeClient.recipient_no_3,
            recipient_no_4: storeClient.recipient_no_4,
            creation_date: monitoring_1.creation_date,
            monitoring_date: monitoring_1.monitoring_date,
            last_date: monitoring_1.last_date,
            frequency: storeClient.frequency,
            plan: data.plan,
            situation: data.situation,
            target_1: data.target_1,
            target_2: data.target_2 ? data.target_2 : null,
            target_3: data.target_3 ? data.target_3 : null,
            target_4: data.target_4 ? data.target_4 : null,
            target_5: data.target_5 ? data.target_5 : null,
            achievement_period_1: data.achievement_period_1,
            achievement_period_2: data.achievement_period_2 ? data.achievement_period_2 : null,
            achievement_period_3: data.achievement_period_3 ? data.achievement_period_3 : null,
            achievement_period_4: data.achievement_period_4 ? data.achievement_period_4 : null,
            achievement_period_5: data.achievement_period_5 ? data.achievement_period_5 : null,
            service_1: data.service_1,
            service_2: data.service_2 ? data.service_2 : null,
            service_3: data.service_3 ? data.service_3 : null,
            service_4: data.service_4 ? data.service_4 : null,
            service_5: data.service_5 ? data.service_5 : null,
            impression_1: data.impression_1,
            impression_2: data.impression_2 ? data.impression_2 : null,
            impression_3: data.impression_3 ? data.impression_3 : null,
            impression_4: data.impression_4 ? data.impression_4 : null,
            impression_5: data.impression_5 ? data.impression_5 : null,
            achievement_1: data.achievement_1,
            achievement_2: data.achievement_2 ? data.achievement_2 : null,
            achievement_3: data.achievement_3 ? data.achievement_3 : null,
            achievement_4: data.achievement_4 ? data.achievement_4 : null,
            achievement_5: data.achievement_5 ? data.achievement_5 : null,
            task_1: data.task_1,
            task_2: data.task_2 ? data.task_2 : null,
            task_3: data.task_3 ? data.task_3 : null,
            task_4: data.task_4 ? data.task_4 : null,
            task_5: data.task_5 ? data.task_5 : null,
            service_type_1: data.service_type_1,
            service_type_2: data.service_type_2 ? data.service_type_2 : false,
            service_type_3: data.service_type_3 ? data.service_type_3 : false,
            service_type_4: data.service_type_4 ? data.service_type_4 : false,
            service_type_5: data.service_type_5 ? data.service_type_5 : false,
            service_volume_1: data.service_volume_1,
            service_volume_2: data.service_volume_2 ? data.service_volume_2 : false,
            service_volume_3: data.service_volume_3 ? data.service_volume_3 : false,
            service_volume_4: data.service_volume_4 ? data.service_volume_4 : false,
            service_volume_5: data.service_volume_5 ? data.service_volume_5 : false,
            weekly_plan_1: data.weekly_plan_1,
            weekly_plan_2: data.weekly_plan_2 ? data.weekly_plan_2 : false,
            weekly_plan_3: data.weekly_plan_3 ? data.weekly_plan_3 : false,
            weekly_plan_4: data.weekly_plan_4 ? data.weekly_plan_4 : false,
            weekly_plan_5: data.weekly_plan_5 ? data.weekly_plan_5 : false,
            other_1: data.other_1,
            other_2: data.other_2 ? data.other_2 : null,
            other_3: data.other_3 ? data.other_3 : null,
            other_4: data.other_4 ? data.other_4 : null,
            other_5: data.other_5 ? data.other_5 : null,
            start_date: monitoring_1.start_date,
            daily_routine: data.daily_routine,
            other_service: data.other_service,
            total: data.total,
        };

        // 保存
        const url = `monitorings_1/${id}`;
        const res = await api.put(url, monitoring_1Data);
        if (res.status === 422) {
            setMonitoring_1({
                ...monitoring_1,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/monitoring_1/${client}`);
            });
        }
    };

    // 今回の編集対象が条件に当てはまった時のみ、最新のクライアント情報を適用するボタンを表示し、変更内容をステートに保存する
    useEffect(() => {
        if (Object.keys(monitoring_1).length > 1 && Object.keys(clientData).length > 0 && mostRecentDate) {
            const numDate = Number(monitoring_1.date.replace(/[-]/g, ""));
            if (numDate === mostRecentDate || numDate > numToday) {
                const checkClientChange = () => {
                    let changeData = {};
                    //---------------------------issue{No.513} start-----------------------------
                    for (let key in clientData) {
                        if (clientData[key] && monitoring_1[key] && clientData[key] != monitoring_1[key]) {
                            changeData[key] = clientData[key];
                        }
                    }
                    setImplementClientData(changeData);
                    //---------------------------issue{No.513} end-------------------------------
                };
                checkClientChange();
            }
        }
    }, [monitoring_1, clientData, mostRecentDate]);

    //---------------------------issue{No.513} start-----------------------------
    // 更新内容を引用する準備ができたらステートを更新する
    useEffect(() => {
        if (Object.keys(implementClientData).length > 0) {
            setClientChangeable(true);
            setCitingClient("ready");
        }
    }, [implementClientData]);
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 利用者情報の更新内容を引用し直す
    const implementClientChanges = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (Object.keys(implementClientData).length > 0) {
            if (citingClient === "ready") {
                // 確認のアラートを表示する
                await swal({
                    icon: "info",
                    title: "確認",
                    text: "登録済みの利用者情報を適用しますか？",
                    buttons: true,
                }).then(async (result) => {
                    if (result) {
                        if (implementClientData.user_id) {
                            // 確認のアラートを表示する
                            let confirm = false;

                            await swal({
                                icon: "warning",
                                title: "確認",
                                text: "担当職員が更新されています。適用しますか？",
                                buttons: true,
                            }).then((result) => {
                                if (result) {
                                    confirm = true;
                                }
                            });

                            // キャンセル処理
                            if (!confirm) {
                                return;
                            }
                        }

                        // 以下、引用のための処理
                        runCitingClient();
                    }
                });
            }
        }
    };

    // 引用した内容を編集前に戻す
    const putBackClientChanges = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        for (let key in implementClientData) {
            // 更新対象のフォーム内の項目を変更
            setValue(key, proposedPlan_1[key], { shouldValidate: false });
        }
        setCitingClient("ready");
    };
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    const runCitingClient = () => {
        if (Object.keys(clientData).length > 0 && citingClient === "ready") {
            if (clientData.office_name !== null) {
                setValue("office_name", clientData.office_name, { shouldValidate: false });
            }
            if (clientData.client_name !== null) {
                setValue("client_name", clientData.client_name, { shouldValidate: false });
            }
            if (clientData.recipient_no_2 !== null) {
                setValue("recipient_no_2", clientData.recipient_no_2, { shouldValidate: false });
            }
            if (clientData.recipient_no_3 !== null) {
                setValue("recipient_no_3", clientData.recipient_no_3, { shouldValidate: false });
            }
            if (clientData.recipient_no_4 !== null) {
                setValue("recipient_no_4", clientData.recipient_no_4, { shouldValidate: false });
            }
            if (clientData.start_month !== null) {
                setValue("start_month", clientData.start_month, { shouldValidate: false });
            }
            setStoreClient(clientData);
            setCitingClient("done");
        }
    };
    //---------------------------issue{No.513} end-------------------------------

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        getMonitoring_1Data(id);
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        getDateList(client);
    }, []);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        control,
        //---------------------------issue{No.453} start-----------------------------
        watch,
        //---------------------------issue{No.453} end-------------------------------
    } = useForm();

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(monitoring_1).length > 1 && !getValues("date")) {
            setValue("date", monitoring_1.date, { shouldValidate: false });
            //---------------------------issue{No.471} start-----------------------------
            setValue("user_name", monitoring_1.user_name, { shouldValidate: false });
            //---------------------------issue{No.471} end-------------------------------
            setValue("maximum_amount", monitoring_1.maximum_amount, { shouldValidate: false });
            setValue("plan", monitoring_1.plan, { shouldValidate: false });
            setValue("situation", monitoring_1.situation, { shouldValidate: false });
            for (let ii = 1; ii <= priorityCount; ii++) {
                setValue(`target_${ii}`, monitoring_1[`target_${ii}`], { shouldValidate: false });
                setValue(`achievement_period_${ii}`, monitoring_1[`achievement_period_${ii}`], {
                    shouldValidate: false,
                });
                setValue(`service_${ii}`, monitoring_1[`service_${ii}`], { shouldValidate: false });
                setValue(`impression_${ii}`, monitoring_1[`impression_${ii}`], { shouldValidate: false });
                setValue(`achievement_${ii}`, monitoring_1[`achievement_${ii}`], { shouldValidate: false });
                setValue(`task_${ii}`, monitoring_1[`task_${ii}`], { shouldValidate: false });
                setValue(`service_type_${ii}`, monitoring_1[`service_type_${ii}`], { shouldValidate: false });
                setValue(`service_volume_${ii}`, monitoring_1[`service_volume_${ii}`], { shouldValidate: false });
                setValue(`weekly_plan_${ii}`, monitoring_1[`weekly_plan_${ii}`], { shouldValidate: false });
                setValue(`other_${ii}`, monitoring_1[`other_${ii}`], { shouldValidate: false });
            }
            setValue("daily_routine", monitoring_1.daily_routine, { shouldValidate: false });
            setValue("other_service", monitoring_1.other_service, { shouldValidate: false });
            setValue("total", monitoring_1.total, { shouldValidate: false });

            //---------------------------issue{No.513} start-----------------------------
            setValue("office_name", monitoring_1.office_name, { shouldValidate: false });
            setValue("client_name", monitoring_1.client_name, { shouldValidate: false });
            setValue("recipient_no_2", monitoring_1.recipient_no_2, { shouldValidate: false });
            setValue("recipient_no_3", monitoring_1.recipient_no_3, { shouldValidate: false });
            setValue("recipient_no_4", monitoring_1.recipient_no_4, { shouldValidate: false });
            setValue("start_month", monitoring_1.start_month, { shouldValidate: false });
            //---------------------------issue{No.513} end-------------------------------
            setStoreClient(monitoring_1);
        }
    }, [monitoring_1]);

    //---------------------------issue{No.453} start-----------------------------
    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change" && name === "date") {
                setDateMessage(dateRegisteringErrorMessage(value.date, clientBirthdayRef.current, dateList.current));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    //---------------------------issue{No.453} end-------------------------------

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        if (priorityCount < 5) {
            setPriorityCount((priorityCount) => priorityCount + 1);
        }
    };

    // 支援目標の優先順位を入れ替える関数
    const handleOnClickPriorityChange = (e, num) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const higherPriority = {
            target: `target_${num}`,
            achievementPeriod: `achievement_period_${num}`,
            service: `service_${num}`,
            impression: `impression_${num}`,
            achievement: `achievement_${num}`,
            task: `task_${num}`,
            serviceType: `service_type_${num}`,
            serviceVolume: `service_volume_${num}`,
            weeklyPlan: `weekly_plan_${num}`,
            other: `other_${num}`,
        };

        // 優先順位が一つ上がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const lowerPriority = {
            target: `target_${num + 1}`,
            achievementPeriod: `achievement_period_${num + 1}`,
            service: `service_${num + 1}`,
            impression: `impression_${num + 1}`,
            achievement: `achievement_${num + 1}`,
            task: `task_${num + 1}`,
            serviceType: `service_type_${num + 1}`,
            serviceVolume: `service_volume_${num + 1}`,
            weeklyPlan: `weekly_plan_${num + 1}`,
            other: `other_${num + 1}`,
        };

        // 入れ替えの際に、値を一時的に控えておく定数オブジェクト
        const reserveValues = {
            target: null,
            achievementPeriod: null,
            service: null,
            impression: null,
            achievement: null,
            task: null,
            serviceType: null,
            serviceVolume: null,
            weeklyPlan: null,
            other: null,
        };

        // 優先順位が下がる側の入力欄の値を控えておく
        for (let key of Object.keys(higherPriority)) {
            const value = getValues(higherPriority[key]);
            reserveValues[key] = value;
        }

        // 優先順位の下の入力欄の値を上の入力欄に移し替える
        for (let key of Object.keys(higherPriority)) {
            const newValue = getValues(lowerPriority[key]);
            setValue(higherPriority[key], newValue, { shouldValidate: false });
        }

        // 控えておいた値を優先順位の下の入力欄に差し替える
        for (let key of Object.keys(reserveValues)) {
            setValue(lowerPriority[key], reserveValues[key], { shouldValidate: false });
        }
    };

    // 支援目標の各優先順位の右上にある✕ボタン押下時に、支援目標を削除したり空欄に戻したりする処理
    const handleOnClickPriorityClear = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この支援目標を削除して続く優先順位を上に詰める、もしくはこの支援目標に関する入力内容をクリアしますか？",
            buttons: {
                catch: {
                    text: "入力内容をクリア",
                    value: "clear",
                    visible: true,
                    className:
                        "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                    closeModal: true,
                },
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
        }).then((value) => {
            switch (value) {
                case "clear":
                    // 選択範囲をクリアする
                    PriorityInputClear(num);
                    break;

                case "cancel":
                    break;

                case "delete":
                    // 先ず、対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    // 現在表示されている優先順位の個数分、優先順位を入れ替える関数を順に実行し、繰上げを行い
                    // ✕ボタンが押下され、上記の関数によって空欄になった入力欄を最下位に下げる
                    for (let ii = num; ii < priorityCount; ii++) {
                        handleOnClickPriorityChange(false, ii);
                    }
                    // 表示される優先順位の入力欄を減らす
                    setPriorityCount((priorityCount) => priorityCount - 1);
                    break;
                default:
                    break;
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const PriorityInputClear = (num) => {
        // 対象の優先順位の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = [
            `target_${num}`,
            `achievement_period_${num}`,
            `service_${num}`,
            `impression_${num}`,
            `achievement_${num}`,
            `task_${num}`,
            `service_type_${num}`,
            `service_volume_${num}`,
            `weekly_plan_${num}`,
            `other_${num}`,
        ];

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, null, { shouldValidate: false });
        }
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>モニタリング報告書編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        {citingClient === "done" && (
                            <div className='text-end mb-2'>
                                <p className='d-inline-block text-decoration-underline'>
                                    ※保存時に現在の利用者情報が適用されます
                                </p>
                            </div>
                        )}
                        <div className='d-flex justify-content-between  mb-4'>
                            <p className='fs-6 pt-2'>
                                <RequiredMark />
                                <span className='text-secondary'>は必須項目です。</span>
                            </p>
                            <div>
                                {clientChangeable && (
                                    <div className='d-flex mb-2'>
                                        {citingClient === "done" && (
                                            <div className='pe-2'>
                                                <Button
                                                    type='button'
                                                    variant='contained'
                                                    onClick={(e) => {
                                                        putBackClientChanges(e);
                                                    }}>
                                                    利用者情報を
                                                    <br />
                                                    編集前に戻す
                                                </Button>
                                            </div>
                                        )}
                                        {citingClient === "ready" ? (
                                            <Button
                                                type='button'
                                                variant='contained'
                                                color='secondary'
                                                onClick={(e) => {
                                                    implementClientChanges(e);
                                                }}>
                                                登録されている利用者の情報と
                                                <br />
                                                異なる入力内容があります
                                            </Button>
                                        ) : (
                                            <Button type='button' variant='contained' color='primary'>
                                                登録されている利用者の内容を
                                                <br />
                                                引用済み
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <form onSubmit={handleSubmit(monitoring_1Submit)} encType='multipart/form-data'>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    報告書作成日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*報告書作成日を入力してください。",
                                        },
                                    })}
                                />
                                {/* 日付の重複によるエラーメッセージ */}
                                <small className='text-danger'>{dateMessage && dateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.date}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1'>
                                    計画作成担当者
                                    <RequiredMark />
                                </label>
                                {Object.keys(users).length > 0 && (
                                    <Controller
                                        name='user_name'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*計画作成担当者を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        {Object.keys(users).length === 0 ? (
                                                            <>ユーザーが登録されていません</>
                                                        ) : (
                                                            <>選択してください</>
                                                        )}
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.user_name}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                )}
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.user_name}
                                </small>
                            </div>
                            <hr />
                            <h3 id='client_form' className='border-bottom border-secondary'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、利用者情報による内容</span>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                </label>
                                <input
                                    id='client_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_name", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>障害支援区分</label>
                                <div className='form-control mb-1'>
                                    {formatSupportLevelListForEdit(clientData.support_law, clientData.support_level)}
                                </div>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_name'>
                                    相談支援事業者名
                                </label>
                                <input
                                    id='office_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("office_name", {
                                        required: {
                                            value: true,
                                            message: "*相談支援事業者名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='maximum_amount'>
                                    利用者負担上限額
                                </label>
                                <input
                                    id='maximum_amount'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("maximum_amount", {
                                        required: {
                                            value: true,
                                            message: "*利用者負担上限額を入力してください。",
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.maximum_amount && (
                                        <>
                                            {errors.maximum_amount?.message}
                                            <br />
                                            ※サービス等利用計画の編集機能から変更して下さい
                                            {!monitoring_1.success && <br />}
                                        </>
                                    )}
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    {monitoring_1.success ? "" : monitoring_1.error_list.maximum_amount}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_2'>
                                    障害福祉サービス受給者証番号
                                </label>
                                <input
                                    id='recipient_no_2'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_2", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_3'>
                                    地域相談支援
                                </label>
                                <input
                                    id='recipient_no_3'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_3", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_4'>
                                    通所受給者番号
                                </label>
                                <input
                                    id='recipient_no_4'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_4", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>モニタリング頻度</label>
                                <div className='form-control mb-1'>{FREQUENCY_LIST?.[storeClient?.frequency]}</div>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='creation_date'>
                                    計画作成日
                                </label>
                                <input
                                    id='creation_date'
                                    type='date'
                                    min={monitoring_1.date}
                                    max={monitoring_1.date}
                                    defaultValue={monitoring_1.date}
                                    className='form-control mb-1'
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='monitoring_date'>
                                    モニタリング実施日
                                </label>
                                <input
                                    id='monitoring_date'
                                    type='date'
                                    min={monitoring_1.date}
                                    max={monitoring_1.date}
                                    defaultValue={monitoring_1.date}
                                    className='form-control mb-1'
                                    readOnly
                                    disabled
                                />
                            </div>
                            {monitoring_1.last_date && (
                                <div className='form-group mb-5'>
                                    <label className='fw-bold mb-1' htmlFor='last_date'>
                                        前回のモニタリング実施日
                                    </label>
                                    <input
                                        id='last_date'
                                        type='date'
                                        min={monitoring_1.last_date}
                                        max={monitoring_1.last_date}
                                        defaultValue={monitoring_1.last_date}
                                        className='form-control mb-1'
                                        disabled
                                    />
                                </div>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary mb-3'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、モニタリング報告書についての項目</span>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='plan'>
                                    総合的な援助の方針
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='plan'
                                    className='form-control mb-1'
                                    {...register("plan", {
                                        required: {
                                            value: true,
                                            message: "*総合的な援助の方針を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*総合的な援助の方針を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.plan && errors.plan?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.plan}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='situation'>
                                    全体の状況
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='situation'
                                    className='form-control mb-1'
                                    {...register("situation", {
                                        required: {
                                            value: true,
                                            message: "*全体の状況を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*全体の状況を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.situation && errors.situation?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.situation}
                                </small>
                            </div>
                            <p className='text-secondary fs-6'>以下、支援目標について優先順位ごとに入力</p>
                            <div className={classes.priority}>
                                <span className={classes.priorityNum}>【1】</span>
                                {priorityCount > 1 && (
                                    <>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 1);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='target_1'>
                                    支援目標
                                    <RequiredMark />
                                </label>
                                <input
                                    id='target_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("target_1", {
                                        required: {
                                            value: true,
                                            message: "*支援目標を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.target_1 && errors.target_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.target_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='achievement_period_1'>
                                    達成時期
                                    <RequiredMark />
                                </label>
                                <input
                                    id='achievement_period_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("achievement_period_1", {
                                        required: {
                                            value: true,
                                            message: "*達成時期を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.achievement_period_1 && errors.achievement_period_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='service_1'>
                                    サービス提供状況
                                    <RequiredMark />
                                </label>
                                <input
                                    id='service_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("service_1", {
                                        required: {
                                            value: true,
                                            message: "*サービス提供状況を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.service_1 && errors.service_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.service_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='impression_1'>
                                    本人の感想・満足度
                                    <RequiredMark />
                                </label>
                                <input
                                    id='impression_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("impression_1", {
                                        required: {
                                            value: true,
                                            message: "*本人の感想・満足度を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.impression_1 && errors.impression_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.impression_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='achievement_1'>
                                    支援目標の達成度
                                    <RequiredMark />
                                </label>
                                <input
                                    id='achievement_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("achievement_1", {
                                        required: {
                                            value: true,
                                            message: "*支援目標の達成度を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.achievement_1 && errors.achievement_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.achievement_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='task_1'>
                                    今後の課題・解決方法
                                    <RequiredMark />
                                </label>
                                <input
                                    id='task_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("task_1", {
                                        required: {
                                            value: true,
                                            message: "*今後の課題・解決方法を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.task_1 && errors.task_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.task_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>サービス種類の変更</label>
                                <br />
                                <Controller
                                    name='service_type_1'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                <br />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.service_type_1 && errors.service_type_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.service_type_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>サービス量の変更</label>
                                <br />
                                <Controller
                                    name='service_volume_1'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                <br />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.service_volume_1 && errors.service_volume_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>週間計画の変更</label>
                                <br />
                                <Controller
                                    name='weekly_plan_1'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                <br />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.weekly_plan_1 && errors.weekly_plan_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_1'>
                                    その他留意事項
                                </label>
                                <input
                                    id='other_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("other_1")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.other_1 && errors.other_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.other_1}
                                </small>
                            </div>
                            {priorityCount >= 2 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【2】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 2 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 2);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 2);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_2'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_2", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_2 && errors.target_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.target_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_2'>
                                            達成時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_2", {
                                                required: {
                                                    value: true,
                                                    message: "*達成時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_2 && errors.achievement_period_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='service_2'>
                                            サービス提供状況
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='service_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("service_2", {
                                                required: {
                                                    value: true,
                                                    message: "*サービス提供状況を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_2 && errors.service_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='impression_2'>
                                            本人の感想・満足度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='impression_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("impression_2", {
                                                required: {
                                                    value: true,
                                                    message: "*本人の感想・満足度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.impression_2 && errors.impression_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.impression_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_2'>
                                            支援目標の達成度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_2", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標の達成度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_2 && errors.achievement_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_2'>
                                            今後の課題・解決方法
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_2", {
                                                required: {
                                                    value: true,
                                                    message: "*今後の課題・解決方法を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_2 && errors.task_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.task_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス種類の変更</label>
                                        <br />
                                        <Controller
                                            name='service_type_2'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_type_2 && errors.service_type_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_type_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス量の変更</label>
                                        <br />
                                        <Controller
                                            name='service_volume_2'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_volume_2 && errors.service_volume_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>週間計画の変更</label>
                                        <br />
                                        <Controller
                                            name='weekly_plan_2'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.weekly_plan_2 && errors.weekly_plan_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other_2'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='other_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("other_2")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.other_2 && errors.other_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.other_2}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount >= 3 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【3】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 2);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 3 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 3);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 3);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_3'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_3", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_3 && errors.target_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.target_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_3'>
                                            達成時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_3", {
                                                required: {
                                                    value: true,
                                                    message: "*達成時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_3 && errors.achievement_period_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='service_3'>
                                            サービス提供状況
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='service_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("service_3", {
                                                required: {
                                                    value: true,
                                                    message: "*サービス提供状況を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_3 && errors.service_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='impression_3'>
                                            本人の感想・満足度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='impression_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("impression_3", {
                                                required: {
                                                    value: true,
                                                    message: "*本人の感想・満足度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.impression_3 && errors.impression_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.impression_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_3'>
                                            支援目標の達成度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_3", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標の達成度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_3 && errors.achievement_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_3'>
                                            今後の課題・解決方法
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_3", {
                                                required: {
                                                    value: true,
                                                    message: "*今後の課題・解決方法を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_3 && errors.task_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.task_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス種類の変更</label>
                                        <br />
                                        <Controller
                                            name='service_type_3'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_type_3 && errors.service_type_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_type_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス量の変更</label>
                                        <br />
                                        <Controller
                                            name='service_volume_3'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_volume_3 && errors.service_volume_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>週間計画の変更</label>
                                        <br />
                                        <Controller
                                            name='weekly_plan_3'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.weekly_plan_3 && errors.weekly_plan_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other_3'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='other_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("other_3")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.other_3 && errors.other_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.other_3}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount >= 4 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【4】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 3);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 4 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 4);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 4);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_4'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_4", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_4 && errors.target_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.target_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_4'>
                                            達成時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_4", {
                                                required: {
                                                    value: true,
                                                    message: "*達成時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_4 && errors.achievement_period_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='service_4'>
                                            サービス提供状況
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='service_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("service_4", {
                                                required: {
                                                    value: true,
                                                    message: "*サービス提供状況を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_4 && errors.service_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='impression_4'>
                                            本人の感想・満足度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='impression_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("impression_4", {
                                                required: {
                                                    value: true,
                                                    message: "*本人の感想・満足度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.impression_4 && errors.impression_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.impression_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_4'>
                                            支援目標の達成度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_4", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標の達成度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_4 && errors.achievement_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_4'>
                                            今後の課題・解決方法
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_4", {
                                                required: {
                                                    value: true,
                                                    message: "*今後の課題・解決方法を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_4 && errors.task_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.task_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス種類の変更</label>
                                        <br />
                                        <Controller
                                            name='service_type_4'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_type_4 && errors.service_type_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_type_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス量の変更</label>
                                        <br />
                                        <Controller
                                            name='service_volume_4'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_volume_4 && errors.service_volume_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>週間計画の変更</label>
                                        <br />
                                        <Controller
                                            name='weekly_plan_4'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.weekly_plan_4 && errors.weekly_plan_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other_4'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='other_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("other_4")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.other_4 && errors.other_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.other_4}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount === 5 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【5】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 4);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 5);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_5'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_5", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_5 && errors.target_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.target_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_5'>
                                            達成時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_5", {
                                                required: {
                                                    value: true,
                                                    message: "*達成時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_5 && errors.achievement_period_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='service_5'>
                                            サービス提供状況
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='service_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("service_5", {
                                                required: {
                                                    value: true,
                                                    message: "*サービス提供状況を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_5 && errors.service_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='impression_5'>
                                            本人の感想・満足度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='impression_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("impression_5", {
                                                required: {
                                                    value: true,
                                                    message: "*本人の感想・満足度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.impression_5 && errors.impression_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.impression_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_5'>
                                            支援目標の達成度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_5", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標の達成度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_5 && errors.achievement_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_5'>
                                            今後の課題・解決方法
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_5", {
                                                required: {
                                                    value: true,
                                                    message: "*今後の課題・解決方法を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_5 && errors.task_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.task_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス種類の変更</label>
                                        <br />
                                        <Controller
                                            name='service_type_5'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_type_5 && errors.service_type_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_type_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス量の変更</label>
                                        <br />
                                        <Controller
                                            name='service_volume_5'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_volume_5 && errors.service_volume_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>週間計画の変更</label>
                                        <br />
                                        <Controller
                                            name='weekly_plan_5'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.weekly_plan_5 && errors.weekly_plan_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other_5'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='other_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("other_5")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.other_5 && errors.other_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.other_5}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount < 5 && (
                                <div className='w-100 mb-5'>
                                    <hr />
                                    <div className='text-center'>
                                        <button
                                            type='button'
                                            className='btn btn-outline-primary'
                                            onClick={(e) => {
                                                handleOnClickAddInput(e);
                                            }}>
                                            <span className='fw-bold fs-5 px-1'>＋</span>
                                            支援目標を追加する
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary mb-5'>
                                <span className='fs-2 text-danger'>■</span>
                                <span className='fs-4'>以下、週間計画表に関する項目</span>
                                <small className='fs-6 fw-normal text-secondary'>（二枚目の様式について）</small>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='daily_routine'>
                                    主な日常生活上の活動
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='daily_routine'
                                    className='form-control mb-1'
                                    {...register("daily_routine", {
                                        required: {
                                            value: true,
                                            message: "*主な日常生活上の活動を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*主な日常生活上の活動を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.daily_routine && errors.daily_routine?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.daily_routine}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_service'>
                                    週単位以外のサービス
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='other_service'
                                    className='form-control mb-1'
                                    {...register("other_service", {
                                        required: {
                                            value: true,
                                            message: "*週単位以外のサービスを入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*週単位以外のサービスを入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_service && errors.other_service?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.other_service}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='total'>
                                    サービス提供によって実現する生活の全体像
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='total'
                                    className='form-control mb-1'
                                    {...register("total", {
                                        required: {
                                            value: true,
                                            message: "*サービス提供によって実現する生活の全体像を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*サービス提供によって実現する生活の全体像を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.total && errors.total?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.total}
                                </small>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Monitoring_1Edit;
