import React from "react";
import { useNavigate } from "react-router-dom";

export default function CloseButton(props) {
    // 画面遷移用
    const navigate = useNavigate();

    const homePath = "/home";
    // props から遷移先のpathとボタン押下時の処理を分割代入
    // ※遷移先pathの規定値はhome画面
    const { path = homePath, onClickAction =e=>e } = props;

    // 閉じるボタンをクリックしたらpathで指定された画面に戻る
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        onClickAction();
        navigate(path);
    };

    return(
        <div className='ms-auto'>
            <button className='btn btn-outline-secondary fs-3 lh-1' onClick={(e) => handleOnClickClose(e)}>x</button>
        </div>
    );
}
