import { SUPPORT_LEVEL_LIST } from "./constants";

const formatSupportLevelList = (law, level) => {
    if (law == 2) {
        return SUPPORT_LEVEL_LIST[level];
    } else {
        return "";
    }
};

export default formatSupportLevelList;
