import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "../api/api";
import { UserContext } from "../providers/UserProvider";

const Logout = () => {
    //画面遷移用
    const navigate = useNavigate();

    //userInfo に、ログイン中のユーザー情報を保持している
    const { userInfo, setUserInfo } = useContext(UserContext);
    const user = userInfo.user;

    //ログアウト
    const postLogout = async () => {
        const url = "logout";
        const res = await api.post(url);
        if (res.data.success) {
            //ログイン中のユーザー情報をクリアする
            setUserInfo({ isLogin: false });
            swal(user.user_name, res.data.message, "success").then(() => {
                navigate("/login", { replace: true });
            });
        }
    };

    useEffect(() => {
        //ログアウト
        postLogout();
    }, []);

    return <div>ログアウト中...</div>;
};

export default Logout;
