import React, {useState, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import { Tooltip } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import {api} from "../api/api";

// 表示している画面の名前を表示するヘッダ部分のスタイルの定義
const clientNameStyles = makeStyles((theme) =>
    createStyles({
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        }
    })
);

// 利用者情報詳細画面への遷移リンク付き表示対象の項目名を表示します
export default function ClientSelectShowLinkInPageTitle(props) {
    // 定義したスタイルを利用する設定
    const classesClientName = clientNameStyles();

    // 画面遷移用
    const navigate = useNavigate();

    const { pageName = "モニタリング記録", clientID = "" } = props;

    const location = useLocation();
    const deletedPageName = location.pathname.includes('deleted') ? '【削除済み】' : '';

    // 引数の利用者IDに対応する利用者の氏名
    const [clientName, setClientName] = useState("");

    // 利用者の名前を取得しステート clientName にセットします
    const getClientName = async (clientId) => {
        const res = await api.get(`clients/office/${clientId}`);
        if (res.data.success) {
            const clientResult = res.data.result;
            const name = clientResult.is_child ? clientResult.child_name : clientResult.client_name;
            setClientName(name);
        }
    };

    // 選択済み利用者の状態に応じて,利用者に紐付くデータを取得
    useEffect(() => clientID && getClientName(clientID), [clientID]);

    // 利用者名クリック時,利用者情報表示画面へ遷移
    const handleOnClientShow = (e, clientId) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (clientId) {
            // URL(/client_select/show/${client.id})を叩く(RouterConfig.js)
            navigate(`/client_select/show/${clientId}`);
        }
    };

    return(
        <>
            {deletedPageName}{pageName}一覧(
            <Tooltip title='利用者情報を確認する' placement='right-end' arrow>
                <span
                    className={classesClientName.clientName}
                    onClick={(e) => handleOnClientShow(e, clientID)}>
                    {clientName}
                </span>
            </Tooltip>
            )
        </>
    );
}
