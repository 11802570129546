import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
        title: {
            fontSize: "1.2rem",
        },
    })
);

function NotFound() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //閉じるボタンをクリックしたら、???
    const handleOnClickClose = (e) => {
        e.stopPropagation();
        navigate("/");
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header'></div>
                    <div className='card-body'>
                        <span className={classes.title}>ページは見つかりませんでした。</span>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
