import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDataList from "../../components/ClientSelectToDataList";

// 見出しの配列定義
const headers = ['届出年月日'];

export default function ConsultationRequest() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDataList
        pageName='相談支援依頼'
        targetURL='consultation_request'
        headerDataList={headers}
        clientID={client}
        accessibleDeleted={true} />;
}