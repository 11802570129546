import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";

import { Button, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataTable from "../../components/DataTable";
import { UserContext } from "../../providers/UserProvider";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        buttonHeight: {
            height: "37px",
            marginBottom: "0",
            lineHeight: "37px",
        },
    })
);

//見出しの配列定義
const headers = ["日付", "復活"];

// 初期状態
const initReports = { result: [], success: true };

function ReportDeleted() {
    // ログインしているユーザーのid（idカラムの数値）を取得
    const { userInfo } = useContext(UserContext);
    const intId = userInfo.user.id;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の職員のId
    const { user } = useParams();

    //reports の状態を管理する
    const [reports, setReports] = useState(initReports);

    //reports を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    const [userName, setUserName] = useState("");

    // 日報を取得しステート Report にセットする
    const getReportsData = async (userId) => {
        const url = `reports_deleted/${userId}`;
        const res = await api.get(url);
        setReports(res.data);
    };

    // 担当職員ユーザの名前を取得しステート userName にセットする
    const getUserName = async (userId) => {
        const url = `reports/user/${userId}`;
        const res = await api.get(url);
        if (res.data.success) {
            const userResult = res.data.result;
            setUserName(userResult.user_name);
        }
    };

    //一覧の行クリック時、情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行のid(key)と一致するreportsステートの情報を変数に代入
        const report = reports.result.find((report) => report.id === id);
        // reportに値が存在する（0, "", undefined, nullでない）場合
        if (report) {
            // URL（/report_deleted/show/${report.id}）のリクエスト（RouterConfig.js）
            navigate(`/report_deleted/show/${user}/${id}`);
        }
    };

    // 戻るボタンをクリックしたら、日報情報一覧画面に戻る
    // ここでのidはパラメータで送られたid
    const handleOnClickBack = (e) => {
        e.stopPropagation();
        navigate(`/report/${user}`);
    };

    //一覧の復活ボタンクリック時、論理削除済み情報を復活
    const handleOnClickRestore = async (e, id) => {
        e.stopPropagation();

        //復活
        const url = `reports/${id}/restore`;
        const res = await api.put(url);
        if (res.data.success) {
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 復活した情報を除外した全情報を配列化
                const newReports = reports.result.filter((reports) => reports.id !== id);
                // ステートを更新
                setReports({ result: newReports, success: true });
            });
        }
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getUserName(user);
        getReportsData(user);
    }, []);

    //reports に応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!reports || !reports.success) {
            return;
        }

        const newRows = reports.result.map((report) => {
            return {
                id: report.id,
                isActive: report.deleted_at === null,
                data: {
                    date: report.date,
                    deleteBtn:
                        user == intId ? (
                            <Button variant='contained' onClick={(e) => handleOnClickRestore(e, report.id)}>
                                復活
                            </Button>
                        ) : (
                            <p className={classes.buttonHeight}>-</p>
                        ),
                },
            };
        });
        setRows(newRows);
    }, [reports]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】日報一覧（{userName}）</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        {/* テーブル部分の定義 */}
                        <DataTable
                            headers={headers}
                            rows={rows}
                            handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                        />
                    </Card>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickBack(e)}>
                            戻る
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReportDeleted;
