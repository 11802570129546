import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataTable from "../../components/DataTable";
import DataShowDialog from "../../components/DataShowDialog";
import { UserContext } from "../../providers/UserProvider";
import FreeWordSearch from "../../components/FreeWordSearch";
import BackHomeButton from "../../components/BackHomeButton"; // issue508

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        buttonHeight: {
            height: "37px",
            marginBottom: "0",
            lineHeight: "37px",
        },
    })
);

//見出しの配列定義
const headers = ["ユーザーID", "名前", "会社名", "事業所名", "権限", "編集", "削除"];

//---------------------------issue{No.46} start-----------------------------
// 検索対象のカラムの配列定義
const columns = ["user_id", "user_name", "company_name", "office_name", "authority"];
//---------------------------issue{No.46} end-------------------------------

//users の初期状態
const initUsers = { result: [], success: true };

//dialog の初期状態
const initDialog = { open: false, data: [] };

function User() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //userInfo に、ログイン中のユーザー情報を保持している
    const { userInfo } = useContext(UserContext);

    //users の状態を管理する
    const [users, setUsers] = useState(initUsers);

    //users を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //dialog の表示状態を管理する
    const [dialog, setDialog] = useState(initDialog);

    //---------------------------issue{No.46} start-----------------------------
    // 検索されたユーザーのデータを格納するステート
    const [searchedUsers, setSearchedUsers] = useState([]);
    //---------------------------issue{No.46} end-------------------------------

    //---------------------------issue{No.463} start-------------------------------
    // ログインユーザー情報
    const userAuthorityOrigin = userInfo.user.authority; // 権限
    const userAuthorityForState = Number(userAuthorityOrigin); // 権限（数値型）
    const loginUserIdOrigin = userInfo.user.user_id;

    // ボタン表示フラグ
    const [displayRegisterButton, setDisplayRegisterButton] = useState(false); // 新規登録・削除
    const [displayEditButton, setDisplayEditButton] = useState(false); // 編集

    // ユーザー権限
    const [loginUserAuthority, setLoginUserAuthority] = useState("");
    // ユーザーID
    const [loginUserId, setLoginUserId] = useState("");

    // 権限ごとにステート（ボタン表示フラグ及びログインユーザー情報）を調整(初回のみ実行)
    useEffect(() => {
        // 権限
        // 会社代表以上
        if (userAuthorityForState < 40) {
            setDisplayRegisterButton(true);
        }

        // 事業所管理者以上
        if (userAuthorityForState < 50) {
            setDisplayEditButton(true);
        }

        // ログインユーザー情報
        setLoginUserId(loginUserIdOrigin); // ユーザーID
        setLoginUserAuthority(userAuthorityForState);
    }, []);
    //---------------------------issue{No.463} end-------------------------------

    //情報を取得しステート users にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        setUsers(res.data);
    };

    //  登録ボタンクリック時
    const handleOnClickRegist = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(register)をたたく（RouterConfig.js）
        navigate(`/register`);
    };

    // -------------------- issue514 start --------------------
    //  復活ボタンクリック時
    const handleOnClickRestore = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(register)をたたく（RouterConfig.js）
        navigate(`/user_deleted`);
    };
    // -------------------- issue514 end --------------------

    //一覧の編集ボタンをクリックしたら、ユーザー情報編集画面へ
    const handleOnClickEdit = (e, id) => {
        e.stopPropagation();
        navigate(`/user/edit/${id}`);
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/home");
    };
    //---------------------------issue{No.539} end-------------------------------

    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        //論理削除
        const url = `users/${id}`;
        const res = await api.delete(url);
        if (res.data.success) {
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除したユーザを除外した全ユーザを配列化
                const newUsers = users.result.filter((user) => user.id !== id);
                // ステートを更新
                setUsers({ result: newUsers, success: true });
            });
        }
    };

    // 一覧の行クリック時、ユーザ情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行のユーザのid(key)と一致するusersステートのユーザ情報を変数に代入
        const user = users.result.find((user) => user.id === id);
        // companyに値が存在する（0, "", undefined, nullでない）場合
        if (user) {
            // URL(/company/show/${company.id})をたたく（RouterConfig.js）
            navigate(`/user/show/${id}`);
        }
    };

    //画面初期表示時にユーザー情報を取得する
    useEffect(() => {
        getUsersData();
    }, []);

    //---------------------------issue{No.46} start-----------------------------
    // 一部変更 { 以下の、useEffect内で扱うステートを一部、usersからsearchedUsersに変更した }
    // searchedUsersに応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!searchedUsers || !users.success) {
            return;
        }

        const newRows = searchedUsers.map((user) => {
            //---------------------------issue{No.463} start-------------------------------
            let targetUserId = user.user_id; // 各ユーザーのユーザーID
            let targetUserAuthority = user.authority;
            let isOther = true; // 他ユーザーフラグ
            let isSubordinateSuperiorAuthority = true; // 下位権限フラグ

            // 自身の所属会社の場合、非所属会社フラグをfalseに変更
            if (loginUserId === targetUserId) {
                isOther = false;
            }

            // 自分より上位の権限の場合、false（自分と同じ権限の権限の場合はtrueのまま）
            if (loginUserAuthority > targetUserAuthority) {
                isSubordinateSuperiorAuthority = false;
            }
            //---------------------------issue{No.463} end-------------------------------
            return {
                id: user.id,
                isActive: user.deleted_at === null,
                data: {
                    userId: user.user_id,
                    user_name: user.user_name,
                    company_name: user.company_name,
                    office_name: user.office_name,
                    authority: user.authority_name,
                    editBtn: displayEditButton ? (
                        <Button variant='contained' color='primary' onClick={(e) => handleOnClickEdit(e, user.id)}>
                            編集
                        </Button>
                    ) : (
                        <p className={classes.buttonHeight}>-</p>
                    ),
                    // ボタン表示フラグ・他ユーザーフラグ共にtrueの場合のみボタンを表示
                    deleteBtn:
                        displayRegisterButton && isOther && isSubordinateSuperiorAuthority ? (
                            <Button variant='contained' onClick={(e) => handleOnClickDelete(e, user.id)}>
                                削除
                            </Button>
                        ) : (
                            <p className={classes.buttonHeight}>-</p>
                        ),
                },
            };
        });
        setRows(newRows);
    }, [searchedUsers]);
    //---------------------------issue{No.46} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    {/* -------------------- issue539 start -------------------- */}
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>ユーザー情報一覧</h1>
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                    </div>
                    {/* -------------------- issue539 end -------------------- */}
                    <div className='card-body'>
                        {displayRegisterButton && (
                            <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                                <Button variant='contained' color='primary' onClick={(e) => handleOnClickRegist(e)}>
                                    新規登録
                                </Button>
                                {/* -------------------- issue514 start -------------------- */}
                                <Button className='ms-3' variant='contained' onClick={(e) => handleOnClickRestore(e)}>
                                    削除済み一覧
                                </Button>
                                {/* -------------------- issue514 end -------------------- */}
                            </Box>
                        )}
                        <FreeWordSearch
                            searchColumns={columns}
                            data={users.result}
                            setSearchedData={setSearchedUsers}
                        />
                        <DataTable
                            headers={headers}
                            rows={rows}
                            handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                        />
                    </div>
                    {/* -------------------- issue514 start -------------------- */}
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackHomeButton />
                        </div>
                    </div>
                    {/* -------------------- issue514 start -------------------- */}
                </div>
            </div>
        </div>
    );
}

export default User;
