export const MIN_MAX_DATE = {
    MIN_DATE: "1900-01-01",
    MAX_DATE: "2100-12-31",
};
// 相談支援利用者に対するモニタリング頻度(月単位)
export const FREQUENCY_LIST = {
    "01": "1ヶ月毎",
    "02": "2ヶ月毎",
    "03": "3ヶ月毎",
    "04": "4ヶ月毎",
    "05": "5ヶ月毎",
    "06": "半年毎",
    12: "１年毎",
};

export const SUPPORT_LAW_LIST = { 0: "なし", 1: "申請中", 2: "有" };

export const SUPPORT_LEVEL_LIST = {
    0: "",
    1: "区分1",
    2: "区分2",
    3: "区分3",
    4: "区分4",
    5: "区分5",
    6: "区分6",
};
