import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataTable from "../../components/DataTable";
import FreeWordSearch from "../../components/FreeWordSearch";
// #82のみ追記
import { UserContext } from "../../providers/UserProvider";
// 追記ここまで
import BackHomeButton from "../../components/BackHomeButton"; // issue508

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

//見出しの配列定義
const headers = ["利用者氏名", "フリガナ", "担当職員名", "事業所名", "会社名", "編集", "削除"];
// 検索対象のカラムの配列定義
const columns = ["client_name", "client_ruby", "child_name", "child_ruby", "user_name", "office_name", "company_name"];

// ステートの初期状態
const initClientsResult = { result: [], success: true };

function Client() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // ログイン中のユーザーIDを定義
    const { userInfo } = useContext(UserContext);
    const userId = userInfo.user.user_id;
    const userAuthority = userInfo.user.authority;
    const officeId = userInfo.user.office_id;

    //Client の状態を管理する
    const [clientsResult, setClientsResult] = useState(initClientsResult);
    // 変更ここまで

    // 追加
    // 実際に表示する利用者のステート
    const [clients, setClients] = useState([]);
    // 追加ここまで

    // 検索された利用者情報を格納するステート
    const [searchedClients, setSearchedClients] = useState([]);

    //Clients を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //情報を取得しステート Clients にセットする
    const getClientsData = async () => {
        const res = await api.get("clients");
        if (res.data.success) {
            const responseResult = res.data;
            if (responseResult && userAuthority >= 30) {
                const officesClients = responseResult.result.filter((client) => client.office_id === officeId);
                setClientsResult({ ...clientsResult, result: [...officesClients] });
                setClients(officesClients);
            } else {
                setClientsResult(responseResult);
                setClients(responseResult.result);
            }
        }
    };

    //  登録ボタンクリック時
    const handleOnClickRegist = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(client_register)をたたく（RouterConfig.js）
        navigate(`/client_register`);
    };

    // -------------------- issue514 start --------------------
    // 復活ボタンクリック時
    const handleOnClickRestore = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(client_deleted)をたたく（RouterConfig.js）
        navigate(`/client_deleted`);
    };
    // -------------------- issue514 end --------------------

    //一覧の行クリック時、利用者情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行の利用者のid(key)と一致するclientsステートの利用者情報を変数に代入
        const showClient = clientsResult.result.find((client) => client.id === id);
        // clientに値が存在する（0, "", undefined, nullでない）場合
        if (showClient) {
            // URL(/client/show/${client.id})をたたく（RouterConfig.js）
            navigate(`/client/show/${id}`);
        }
    };

    // 一覧の編集ボタンクリック時、利用者情報編集画面へ
    const handleOnClickEdit = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // URL(/client/edit/${id})をたたく（RouterConfig.js）
        navigate(`/client/edit/${id}`);
    };

    //一覧の削除ボタンをクリック時、論理削除
    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        //論理削除
        const url = `clients/${id}`;
        const res = await api.delete(url);
        if (res.data.success) {
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除した利用者を除外した全利用者を配列化
                const newClients = clientsResult.result.filter((client) => client.id !== id);
                // ステートを更新
                setClientsResult({ result: newClients, success: true });
                // 表示用のステートはさらに処理を加えて更新
                const elem = document.getElementById("own_client_checkbox");
                if (elem.checked === true) {
                    const usersClients = newClients.filter((client) => client.user_id === userId);
                    setClients(usersClients);
                } else {
                    setClients(newClients);
                }
            });
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/home");
    };
    //---------------------------issue{No.539} end-------------------------------

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        getClientsData();
    }, []);

    // #82のみ追記
    // チェックボックスの状態に応じてログインユーザーの担当の利用者のみに絞り込む
    const handleChangeOwnClient = (e) => {
        if (e.target.checked) {
            const usersClients = clientsResult.result.filter((client) => client.user_id === userId);
            setClients(usersClients);
        } else {
            setClients(clientsResult.result);
        }
    };
    // 追記ここまで

    //clients に応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!searchedClients || !clientsResult.success) {
            return;
        }

        const newRows = searchedClients.map((client) => {
            return {
                id: client.id,
                isActive: client.deleted_at === null,
                data: {
                    client_name: client.is_child ? client.child_name : client.client_name,
                    client_ruby: client.is_child ? client.child_ruby : client.client_ruby,
                    user_name: client.user_name,
                    office_name: client.office_name,
                    company_name: client.company_name,
                    editBtn: (
                        <Button variant='contained' color='primary' onClick={(e) => handleOnClickEdit(e, client.id)}>
                            編集
                        </Button>
                    ),
                    deleteBtn: (
                        <Button variant='contained' onClick={(e) => handleOnClickDelete(e, client.id)}>
                            削除
                        </Button>
                    )
                }
            };
        });
        setRows(newRows);
    }, [searchedClients]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>利用者情報一覧</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                            <Button variant='contained' color='primary' onClick={(e) => handleOnClickRegist(e)}>
                                新規登録
                            </Button>
                            {/* -------------------- issue514 start -------------------- */}
                            <Button className='ms-3' variant='contained' onClick={(e) => handleOnClickRestore(e)}>
                                削除済み一覧
                            </Button>
                            {/* -------------------- issue514 end -------------------- */}
                        </Box>
                        <FreeWordSearch searchColumns={columns} data={clients} setSearchedData={setSearchedClients} />

                        <Box className='d-flex btn btn-light btn-sm' sx={{ width: "max-content", m: "1rem auto" }}>
                            <input
                                id='own_client_checkbox'
                                type='checkbox'
                                name='own_client_checkbox'
                                onChange={(e) => handleChangeOwnClient(e)}
                            />
                            <Box sx={{ pl: 1 }}>
                                <label htmlFor='own_client_checkbox'>担当の利用者のみを表示</label>
                            </Box>
                        </Box>
                        {/* テーブル部分の定義 */}
                        <DataTable
                            headers={headers}
                            rows={rows}
                            handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                        />
                    </Card>
                    {/* -------------------- issue508 start -------------------- */}
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackHomeButton />
                        </div>
                    </div>
                    {/* -------------------- issue508 start -------------------- */}
                </div>
            </div>
        </div>
    );
}

export default Client;
