import React from "react";
import { useFormContext } from "react-hook-form";

// input formの初期状態
const emptyDataList = {error_list: {}, success: false};

// 登録・編集画面等のinput formの直下にvalidation errorが発生した場合のmessage表示します
export const InputValidationErrorMessageArea = ({labelID = "start_time", formData = emptyDataList}) => {
    // react-hook-formの使用する機能を継承します
    const {formState: {errors}} = useFormContext({});

    return (<small className='text-danger'>
        {/* react-hook-formのバリデーションによるエラーメッセージ */}
        {errors?.[labelID] && errors?.[labelID]?.message}
        {/* react-hook-formとサーバ側両方のバリデーションによるエラーメッセージが存在すれば改行を挟みます */}
        {errors?.[labelID] && !formData.success && formData?.error_list?.[labelID] && <br /> }
        {/* サーバ側のバリデーションによるエラーメッセージ */}
        {!formData.success && formData.error_list?.[labelID]}
    </small>);
}
export default InputValidationErrorMessageArea;