import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import dateFormat from "../../common/dateFormat";
import DataShow from "../../components/DataShow";
import DataShowForRegister from "../../components/DataShowForRegister";
import NewOutputExcelButton from "../../components/NewOutputExcelButton";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        figure: {
            marginBottom: "0!important",
            fontWeight: "bold"
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center"
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px"
        }
    })
);

//---------------------------issue{No.440} start-----------------------------
export default function InstitutionAlignmentShow(props) {
    const { idFromRegister = undefined, forRegister = false, setRefCurrent = undefined } = props;
    //---------------------------issue{No.440} end-------------------------------

    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 表示対象の利用者のidと、医療・保育・教育機関等連携情報のid
    const { client, id } = useParams();

    // 表示するinstitutionAlignment の状態を管理する
    const [institutionAlignmentShowData, setInstitutionAlignmentShowData] = useState([]);

    const [downloadFileName, setDownloadFileName] = useState("");

    // "hh:ii:ss"を"hh:ii"に変更
    const formatTime = (time) => {
        const timeArr = time.split(":");
        const result = `${timeArr[0]}:${timeArr[1]}`;
        return result;
    };

    // 情報を取得しステート institutionAlignment にセットする
    const getInstitutionAlignmentData = async (id) => {
        const url = `institution_alignments/show/${id}`;
        const res = await api.get(url);

        if (res) {
            const createdDate = res.data.result.date.replace(/-/g, "");
            const clientName = res.data.result.client_name;
            setDownloadFileName(`${createdDate}_${clientName}_医療・保育・教育機関等連携`);

            setInstitutionAlignmentRow(res.data.result);
        }
    };

    // ステートに値をセット
    const setInstitutionAlignmentRow = (institutionAlignment) => {
        const date1 = dateFormat(institutionAlignment.date_1).format_date;
        const date2 = institutionAlignment.date_2 == null ? null : dateFormat(institutionAlignment.date_2).format_date;
        const date3 = institutionAlignment.date_3 == null ? null : dateFormat(institutionAlignment.date_3).format_date;
        const time1 = formatTime(institutionAlignment.time_1);
        const time2 = institutionAlignment.time_2 == null ? null : formatTime(institutionAlignment.time_2);
        const time3 = institutionAlignment.time_3 == null ? null : formatTime(institutionAlignment.time_3);

        //---------------------------issue{No.440} start-----------------------------
        const data = [
            { label: "利用者氏名", value: institutionAlignment.client_name },
            { label: "相談支援専門員氏名", value: institutionAlignment.user_name },
            { label: "【1】機関名（担当者）", value: institutionAlignment.pic_1 },
            { label: "【1】年月日", value: date1 },
            { label: "【1】時間", value: time1 },
            { label: "【1】場所", value: institutionAlignment.location_1 },
            { label: "【1】情報交換等の内容", value: institutionAlignment.detail_1 },
            { label: "【2】機関名（担当者）", value: institutionAlignment.pic_2 },
            { label: "【2】年月日", value: date2 },
            { label: "【2】時間", value: time2 },
            { label: "【2】場所", value: institutionAlignment.location_2 },
            { label: "【2】情報交換等の内容", value: institutionAlignment.detail_2 },
            { label: "【3】機関名（担当者）", value: institutionAlignment.pic_3 },
            { label: "【3】年月日", value: date3 },
            { label: "【3】時間", value: time3 },
            { label: "【3】場所", value: institutionAlignment.location_3 },
            { label: "【3】情報交換等の内容", value: institutionAlignment.detail_3 },
            { label: "反映事項", value: institutionAlignment.reflection_matter }
        ];

        setInstitutionAlignmentShowData(data);

        if (forRegister) {
            setRefCurrent(data);
        }
        //---------------------------issue{No.440} end-------------------------------
    };

    // 閉じるボタンをクリックしたら、医療・保育・教育機関等連携情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/institution_alignment/${client}`);
    };

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        //---------------------------issue{No.440} start-----------------------------
        getInstitutionAlignmentData(idFromRegister ? idFromRegister : id);
        //---------------------------issue{No.440} end-------------------------------
    }, []);

    return (
        <>
            {forRegister ? (
                <DataShowForRegister data={institutionAlignmentShowData}></DataShowForRegister>
            ) : (
                <div className='row justify-content-center'>
                    <div className='col-md-10 col-lg-10 mx-auto'>
                        <div className='card'>
                            <div className='card-header d-flex'>
                                <h1 className={classes.title}>医療・保育・教育機関等連携情報</h1>
                                {/* -------------------- issue539 start -------------------- */}
                                <div className='ms-auto'>
                                    <button
                                        className='btn btn-outline-secondary fs-3 lh-1'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                            </div>
                            <div className='card-body'>
                                <NewOutputExcelButton
                                    fileName={downloadFileName}
                                    api_url={`institution_alignments/${id}/export`}
                                    buttonTextIsFileName={false}
                                />
                                <DataShow data={institutionAlignmentShowData} />
                            </div>
                            <div className='card-footer'>
                                <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                    閉じる
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
