import React, { useEffect, useState } from "react";

import { Button } from "@material-ui/core";

const DateSearch = (props) => {
    // 検索年月日を格納するステート
    const [searchYear, setSearchYear] = useState("");
    const [searchMonth, setSearchMonth] = useState("");
    const [searchDate, setSearchDate] = useState("");

    // データベースの応答を待ち、検索を待機する状態を宣言するステート
    const [waitForResponse, setWaitForResponse] = useState(false);
    // propsから、親コンポーネントの関数を取得
    const { data, setSearchedData } = props;

    const [yearList, setYearList] = useState([]);

    useEffect(() => {
        if (data.length > 0) {
            const getYears = () => {
                let years = [];
                for (let key in data) {
                    let item = data[key].date;
                    let array = item.split("-");
                    let year = array[0];
                    if (!years.includes(year)) {
                        years.push(year);
                    }
                }

                let yearsList = [];
                for (let key in years) {
                    yearsList.push(
                        <option key={key} value={years[key]}>
                            {years[key]}
                        </option>
                    );
                }

                setYearList(yearsList);
            };

            getYears();
        }
    }, [data]);

    let monthList = [];
    for (let i = 1; i <= 12; i++) {
        let value = ("00" + i).slice(-2);
        monthList.push(
            <option key={i} value={value}>
                {i}
            </option>
        );
    }

    const handleChangeSearchYear = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 検索年月日を空白文字で区切って配列に格納
        let year = e.target.value;

        // ステートを更新
        setSearchYear(year);
    };

    // 検索年月日の入力が変更される度に、検索年月を取得し、ステートを更新
    const handleChangeSearchMonth = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // 検索年月日を空白文字で区切って配列に格納
        let month = e.target.value;

        // ステートを更新
        setSearchMonth(month);
    };

    // 検索年月日の入力が変更される度に、検索年月日を取得し、ステートを更新
    const handleChangeSearchDate = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();
        // 検索年月日を空白文字で区切って配列に格納
        let date = e.target.value;

        // ステートを更新
        setSearchDate(date);
    };

    const handleClickClear = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        const form = document.getElementById("search_form");
        form.reset();

        // ステートを更新
        setSearchYear("");
        setSearchMonth("");
        setSearchDate("");
    };

    const searchInDate = (word) => {
        return data.filter((item) => item["date"].indexOf(word) !== -1);
    };

    const searchDateFormat = (date) => {
        return ("00" + date).slice(-2);
    };

    // 検索年月日による検索を行う関数
    const getSearchedData = () => {
        // 検索結果を格納する配列
        let result = [];
        // もし検索年月日が空("")の場合
        if (searchYear === "" && searchMonth === "" && searchDate === "") {
            // 全てのデータを検索結果として代入
            result = data;
            // 以下、検索年月日がある場合
        } else if (searchYear && searchMonth === "" && searchDate === "") {
            result = searchInDate(searchYear);
        } else if (searchYear && searchMonth && searchDate === "") {
            result = searchInDate(`${searchYear}-${searchMonth}`);
        } else if (searchYear && searchMonth === "" && searchDate) {
            const regexp = new RegExp(`^${searchYear}-[0-9]{2}-${searchDateFormat(searchDate)}$`, "g");
            result = data.filter((item) => item["date"].match(regexp));
        } else if (searchYear === "" && searchMonth && searchDate === "") {
            result = searchInDate(`-${searchMonth}-`);
        } else if (searchYear && searchMonth && searchDate) {
            result = searchInDate(`${searchYear}-${searchMonth}-${searchDateFormat(searchDate)}`);
        } else if (searchYear === "" && searchMonth && searchDate) {
            result = searchInDate(`-${searchMonth}-${searchDateFormat(searchDate)}`);
        } else if (searchYear === "" && searchMonth === "" && searchDate) {
            result = data.filter((item) => item["date"].slice(-2).indexOf(searchDateFormat(searchDate)) !== -1);
        }
        // 検索結果をステートにセット
        setSearchedData(result);
    };

    // 検索年月日が更新されるたびに、以下の副作用フックを実行
    useEffect(() => {
        // データベースからのデータの取得が済んでいるか
        if (data.length > 0) {
            // データの取得が済んでいる場合、検索を実行
            getSearchedData();
        } else {
            // データの取得が済んでいない場合は、
            // データベースの応答を待ち、検索を待機する状態にする
            setWaitForResponse(true);
        }
    }, [searchYear, searchMonth, searchDate]);

    // データベースからのデータの取得が済む前に検索が行なわれた場合のための副作用フック
    useEffect(() => {
        // データベースからのデータの取得が済んだとき、かつ検索の実行が待機されているとき
        if (waitForResponse && data.length > 0) {
            // 検索結果取得の実行
            getSearchedData();
        }
        //---------------------------issue{No.59} start-----------------------------
        // 表示可能なデータが0件になった場合
        if (data.length === 0) {
            setSearchedData([]);
        }
        //---------------------------issue{No.59} end-------------------------------
    }, [data]);

    return (
        <form id='search_form' className='d-flex col-md-5 mx-auto mt-3 mb-4'>
            <div className='d-flex w-25 align-items-end'>
                <select
                    className='form-select w-75'
                    onChange={(e) => {
                        handleChangeSearchYear(e);
                    }}>
                    <option value=''>----</option>
                    {yearList}
                </select>
                <span>年</span>
            </div>
            <div className='d-flex w-25 align-items-end'>
                <select
                    className='form-select w-75'
                    onChange={(e) => {
                        handleChangeSearchMonth(e);
                    }}>
                    <option value=''>--</option>
                    {monthList}
                </select>
                <span>月</span>
            </div>
            <label className='d-flex w-25 align-items-end'>
                <input
                    className='form-control w-75'
                    type='number'
                    min='1'
                    max='31'
                    onChange={(e) => handleChangeSearchDate(e)}
                />
                <span>日</span>
            </label>
            <Button
                variant='outlined'
                color='primary'
                onClick={(e) => {
                    handleClickClear(e);
                }}>
                <span className='small text-nowrap '>クリア</span>
            </Button>
        </form>
    );
};

export default DateSearch;
