import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../api/api";
import DataTable from "../components/DataTable";
import FreeWordSearch from "../components/FreeWordSearch";
// #82のみ追記
import { UserContext } from "../providers/UserProvider";
// 追記ここまで

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        }
    })
);

//見出しの配列定義
const headers = ["利用者氏名", "フリガナ", "選択"];
// 検索対象のカラムの配列定義
const columns = ["client_name", "client_ruby", "child_name", "child_ruby"];

// ステートの初期状態
const initClientsResult = { result: [], success: true };

function ClientSelect(props) {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // ログイン中のユーザーIDを定義
    const { userInfo } = useContext(UserContext);
    const userId = userInfo.user.user_id;
    const userAuthority = userInfo.user.authority;
    const officeId = userInfo.user.office_id;

    // 親コンポーネントから受け取ったメソッドを分割代入
    const { setSelectedClient } = props;

    //Client の状態を管理する
    const [clientsResult, setClientsResult] = useState(initClientsResult);

    // 実際に表示する利用者のステート
    const [clients, setClients] = useState([]);

    // 検索された利用者情報を格納するステート
    const [searchedClients, setSearchedClients] = useState([]);

    //Clients を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //情報を取得しステート Clients にセットする
    const getClientsData = async () => {
        const res = await api.get("clients");
        if (res.data.success) {
            // -------------------- issue433 start --------------------
            const responseResult = res.data;
            if (responseResult && userAuthority >= 30) {
                const officesClients = responseResult.result.filter((client) => client.office_id === officeId);
                setClientsResult({ ...clientsResult, result: [...officesClients] });
                setClients(officesClients);
            } else {
                setClientsResult(responseResult);
                setClients(responseResult.result);
            }
            // -------------------- issue433 end --------------------
        }
    };

    //一覧の行クリック時、利用者情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行の利用者のid(key)と一致するclientsステートの利用者情報を変数に代入
        const client = clientsResult.result.find((client) => client.id === id);
        // clientに値が存在する（0, "", undefined, nullでない）場合
        if (client) {
            // URL(/client/show/${client.id})をたたく（RouterConfig.js）
            navigate(`/client_select/show/${id}`);
        }
    };

    const handleOnClickSelect = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // ステートを更新する
        setSelectedClient(id);
    };

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        getClientsData();
    }, []);

    // -------------------- issue433 start --------------------

    // チェックボックスの状態に応じてログインユーザーの担当の利用者以外も表示に含めるようにする
    const handleChangeOwnClient = (e) => {
        if (e.target.checked) {
            const usersClients = clientsResult.result.filter((client) => client.user_id === userId);
            setClients(usersClients);
        } else {
            setClients(clientsResult.result);
        }
    };
    // -------------------- issue433 end --------------------

    //clients に応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!searchedClients || !clientsResult.success) {
            return;
        }

        const newRows = searchedClients.map((client) => {
            return {
                id: client.id,
                isActive: client.deleted_at === null,
                data: {
                    client_name: client.is_child ? client.child_name : client.client_name,
                    client_ruby: client.is_child ? client.child_ruby : client.client_ruby,
                    selectBtn: (
                        <Button variant='contained' color='primary' onClick={(e) => handleOnClickSelect(e, client.id)}>
                            選択
                        </Button>
                    )
                }
            };
        });
        setRows(newRows);
    }, [searchedClients]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='text-center my-3'>利用者を選択してください。</div>
                <Card className={classes.card}>
                    <FreeWordSearch searchColumns={columns} data={clients} setSearchedData={setSearchedClients} />
                    <Box className='d-flex btn btn-light btn-sm' sx={{ width: "max-content", m: "1rem auto" }}>
                        <input
                            id='own_client_checkbox'
                            type='checkbox'
                            name='own_client_checkbox'
                            onChange={(e) => handleChangeOwnClient(e)}
                        />
                        <Box sx={{ pl: 1 }}>
                            <label htmlFor='own_client_checkbox'>担当の利用者のみを表示</label>
                        </Box>
                    </Box>
                    {/* テーブル部分の定義 */}
                    <DataTable
                        headers={headers}
                        rows={rows}
                        handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                    />
                </Card>
            </div>
        </div>
    );
}

export default ClientSelect;
