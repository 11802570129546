import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import {
    Paper,
    Button,
    Tooltip,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
    Slide,
    Fade,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
} from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import { UserContext } from "../../providers/UserProvider";
import BackButton from "../../components/BackButton";
import DataShowForRegister from "../../components/DataShowForRegister";
import Plan_1Show from "../plan_1/Plan_1Show";
import MonitoringDraftShow from "../monitoring_draft/MonitoringDraftShow";
import Monitoring_1Show from "./Monitoring_1Show";
import { MIN_MAX_DATE, FREQUENCY_LIST } from "../../common/constants";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";
import { SUPPORT_LAW_LIST, SUPPORT_LEVEL_LIST } from "../../common/constants";

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        dataLink: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        priority: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004",
        },
        priorityNum: { lineHeight: "2rem" },
        supports: { backgroundColor: "#e9ecef" },
        labelCell: {
            backgroundColor: "#e3f7fe",
        },
    })
);

// 初期状態
const initMonitoring_1 = { result: {}, error_list: null, success: true };

//---------------------------issue{No.440} start-----------------------------
// 初期状態
const initLastMonitoring_1 = { success: true };

// 初期状態
const initPlan_1 = { success: true };

// 初期状態
const initReport = { success: false };

// 初期状態
const initMonitoringDraft = { success: true };
//---------------------------issue{No.440} end-------------------------------
//---------------------------issue{No.513} start-----------------------------
//---------------------------issue{No.513} end-------------------------------

// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

export default function Monitoring_1Register() {
    //---------------------------issue{No.471} start-----------------------------
    // ログイン中のユーザー情報を受け取る
    const { userInfo } = useContext(UserContext);
    //---------------------------issue{No.471} end-------------------------------

    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 利用者のidと、前回作成したデータのidを保持している
    const { client, id } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付の入力制限を設ける為に日付の上下限値として用いる定数を読み込みます
    const { MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // 入力値を管理する
    const [monitoring_1, setMonitoring_1] = useState(initMonitoring_1);

    //---------------------------issue{No.440} start-----------------------------
    // 前回作成時の値を管理する
    const [lastMonitoring_1, setLastMonitoring_1] = useState(initLastMonitoring_1);

    // 作成時に参照するサービス等利用計画の値を管理する
    const [plan_1, setPlan_1] = useState(initPlan_1);

    // 作成時に参照するモニタリング記録の値を管理する
    const [monitoringDraft, setMonitoringDraft] = useState(initMonitoringDraft);

    // 引用に関して
    const [citing, setCiting] = useState("");

    // 最新の日報（モニタリング関連のみ）
    const [report, setReport] = useState(initReport);

    // 参照するデータを所持しておくRefオブジェクト
    const lastMonitoring_1Ref = useRef(null);
    const setLastMonitoring_1RefCurrent = (data) => {
        lastMonitoring_1Ref.current = data;
    };

    const plan_1Ref = useRef(null);
    const setPlan_1RefCurrent = (data) => {
        plan_1Ref.current = data;
    };

    const monitoringDraftRef = useRef(null);
    const setMonitoringDraftRefCurrent = (data) => {
        monitoringDraftRef.current = data;
    };

    // MUIのSlideタグの属性値を操作する
    const [slideOn, setSlideOn] = useState(false);

    // MUIのFadeタグの属性値を操作する（初期値：true）
    const [fadeOn, setFadeOn] = useState(true);

    // 参照用のコンポーネントの見出しに使う文字列を管理する
    const [slideTitle, setSlideTitle] = useState("");

    // 参照しているデータの識別子
    const [displayDataNum, setDisplayDataNum] = useState(0);
    //---------------------------issue{No.440} end-------------------------------

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // watch内で使用する利用者の生年月日
    const clientBirthdayRef = useRef(""); // issue516

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");

    // 優先順位ごとの入力欄の表示個数を管理する
    const [priorityCount, setPriorityCount] = useState(1);

    // 情報を取得しステート lastMonitoring_1にセットする
    const getLastMonitoring_1Data = async (id) => {
        const url = `monitorings_1/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 支援目標の入力欄の表示個数を格納する
            let newPriorityCount = 1;
            // 支援目標の登録数に応じて、必要な入力欄の個数を調べる
            for (let ii = 2; ii <= 5; ii++) {
                if (res.data.result[`target_${ii}`]) {
                    newPriorityCount = ii;
                }
            }

            //---------------------------issue{No.440} start-----------------------------
            setLastMonitoring_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
                newPriorityCount: newPriorityCount,
            });
        }
    };

    // 情報を取得しステート plan_1にセットする
    const getPlan_1Data = async (clientId) => {
        const url = `plans_1_get_latest/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            if (!res.data.result) {
                await swal({
                    icon: "warning",
                    title: "サービス等利用計画が存在しないため続行できません",
                    text: "このメッセージを閉じて「モニタリング報告書一覧」ページに戻ります。",
                });
                navigate(`/monitoring_1/${client}`);

                //---------------------------issue{No.505} start-----------------------------
                return;
                //---------------------------issue{No.505} end-------------------------------
            }

            // 支援目標の入力欄の表示個数を格納する
            let newPriorityCount = 1;
            // 支援目標の登録数に応じて、必要な入力欄の個数を調べる
            for (let ii = 2; ii <= 5; ii++) {
                if (res.data.result[`target_${ii}`]) {
                    newPriorityCount = ii;
                }
            }

            setPlan_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
                newPriorityCount: newPriorityCount,
            });
        }
    };

    // 情報を取得しステート plan_1にセットする
    const getMonitoringDraftData = async (clientId) => {
        const url = `monitoring_drafts_get_latest/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            if (!res.data.result) {
                await swal({
                    icon: "warning",
                    title: "モニタリング記録が存在しないため続行できません",
                    text: "このメッセージを閉じて「モニタリング報告書一覧」ページに戻ります。",
                });
                navigate(`/monitoring_1/${client}`);

                //---------------------------issue{No.505} start-----------------------------
                return;
                //---------------------------issue{No.505} end-------------------------------
            }

            setMonitoringDraft({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
        }
    };
    //---------------------------issue{No.440} end-------------------------------

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `monitorings_1/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                client_id: clientResult.client_id,
                office_name: clientResult.office_name,
                // ---------------------------issue{No.513} start-----------------------------
                // user_name: clientResult.user_name,
                //---------------------------issue{No.513} end-------------------------------
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                support_law: clientResult.support_law,
                support_level: clientResult.support_level,
                recipient_no_2: clientResult.recipient_no_2,
                recipient_no_3: clientResult.recipient_no_3,
                recipient_no_4: clientResult.recipient_no_4,
                frequency: clientResult.frequency,
            });

            clientBirthdayRef.current = clientResult.is_child
                ? clientResult.child_birthday
                : clientResult.client_birthday; // issue516
        }
    };

    //---------------------------issue{No.471} start-----------------------------
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name,
                };
            });
            setUsers(usersName);
        }
    };
    //---------------------------issue{No.471} end-------------------------------

    //
    const getLatestMonitoringReport = async (id, today) => {
        const url = `reports_latest_monitoring/${id}/${today}`;
        const res = await api.get(url);
        if (res.data.success && res.data.result !== null) {
            setReport({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
        }
    };

    const getDateList = async (clientId) => {
        const url = `monitorings_1_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            const result = res.data.result.map((item) => {
                return item.date;
            });
            dateList.current = result;
        }

        // データ取得時に本日の日付がすでに作成されていないかを判定
        setDateMessage(dateRegisteringErrorMessage(today, clientBirthdayRef.current, dateList.current));
    };

    // //利用者名クリック時、利用者情報表示画面へ
    // const handleOnClickShow = async (e) => {
    //     // イベントの伝搬を中止
    //     e.stopPropagation();

    //     // 確認のアラートを表示する
    //     let confirm = false;
    //     await swal({
    //         icon: "warning",
    //         title: "入力内容は破棄されます",
    //         text: "利用者情報を確認するため、ページを離れますか？",
    //         buttons: true,
    //     }).then((result) => {
    //         if (result) {
    //             confirm = true;
    //         }
    //     });

    //     // キャンセル処理
    //     if (!confirm) {
    //         return;
    //     }

    //     if (client) {
    //         // URL(/client/show/${client.id})をたたく（RouterConfig.js）
    //         navigate(`/client_select/show/${client}`);
    //     }
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 作成ボタン押下時
    const monitoring_1Submit = async (data) => {
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "作成内容を見直してください",
                timer: 2000,
            });
            return;
        }

        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;
        //---------------------------issue{No.513} end-------------------------------

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "作成しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // Monitoring_1Controllerに渡す値をセット
        const monitoring_1Data = {
            ...clientData,
            user_name: data.user_name,
            date: data.date,
            maximum_amount: data.maximum_amount,
            creation_date: plan_1.date,
            monitoring_date: monitoringDraft.date,
            last_date: lastMonitoring_1.monitoring_date ? lastMonitoring_1.monitoring_date : null,
            plan: data.plan,
            situation: data.situation,
            target_1: data.target_1,
            target_2: data.target_2 ? data.target_2 : null,
            target_3: data.target_3 ? data.target_3 : null,
            target_4: data.target_4 ? data.target_4 : null,
            target_5: data.target_5 ? data.target_5 : null,
            achievement_period_1: data.achievement_period_1,
            achievement_period_2: data.achievement_period_2 ? data.achievement_period_2 : null,
            achievement_period_3: data.achievement_period_3 ? data.achievement_period_3 : null,
            achievement_period_4: data.achievement_period_4 ? data.achievement_period_4 : null,
            achievement_period_5: data.achievement_period_5 ? data.achievement_period_5 : null,
            service_1: data.service_1,
            service_2: data.service_2 ? data.service_2 : null,
            service_3: data.service_3 ? data.service_3 : null,
            service_4: data.service_4 ? data.service_4 : null,
            service_5: data.service_5 ? data.service_5 : null,
            impression_1: data.impression_1,
            impression_2: data.impression_2 ? data.impression_2 : null,
            impression_3: data.impression_3 ? data.impression_3 : null,
            impression_4: data.impression_4 ? data.impression_4 : null,
            impression_5: data.impression_5 ? data.impression_5 : null,
            achievement_1: data.achievement_1,
            achievement_2: data.achievement_2 ? data.achievement_2 : null,
            achievement_3: data.achievement_3 ? data.achievement_3 : null,
            achievement_4: data.achievement_4 ? data.achievement_4 : null,
            achievement_5: data.achievement_5 ? data.achievement_5 : null,
            task_1: data.task_1,
            task_2: data.task_2 ? data.task_2 : null,
            task_3: data.task_3 ? data.task_3 : null,
            task_4: data.task_4 ? data.task_4 : null,
            task_5: data.task_5 ? data.task_5 : null,
            service_type_1: data.service_type_1,
            service_type_2: data.service_type_2 ? data.service_type_2 : false,
            service_type_3: data.service_type_3 ? data.service_type_3 : false,
            service_type_4: data.service_type_4 ? data.service_type_4 : false,
            service_type_5: data.service_type_5 ? data.service_type_5 : false,
            service_volume_1: data.service_volume_1,
            service_volume_2: data.service_volume_2 ? data.service_volume_2 : false,
            service_volume_3: data.service_volume_3 ? data.service_volume_3 : false,
            service_volume_4: data.service_volume_4 ? data.service_volume_4 : false,
            service_volume_5: data.service_volume_5 ? data.service_volume_5 : false,
            weekly_plan_1: data.weekly_plan_1,
            weekly_plan_2: data.weekly_plan_2 ? data.weekly_plan_2 : false,
            weekly_plan_3: data.weekly_plan_3 ? data.weekly_plan_3 : false,
            weekly_plan_4: data.weekly_plan_4 ? data.weekly_plan_4 : false,
            weekly_plan_5: data.weekly_plan_5 ? data.weekly_plan_5 : false,
            other_1: data.other_1 ? data.other_1 : null,
            other_2: data.other_2 ? data.other_2 : null,
            other_3: data.other_3 ? data.other_3 : null,
            other_4: data.other_4 ? data.other_4 : null,
            other_5: data.other_5 ? data.other_5 : null,
            start_date: plan_1.start_date,
            daily_routine: data.daily_routine,
            other_service: data.other_service,
            total: data.total,
        };

        // 作成
        const url = "monitorings_1";
        const res = await api.post(url, monitoring_1Data);
        if (res.status === 422) {
            setMonitoring_1({
                // 下一行は暫定対策
                result: { ...monitoring_1Data },
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/monitoring_1/${client}`);
            });
        }
    };

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        //---------------------------issue{No.440} start-----------------------------
        // 前回のデータを取得する
        if (id) {
            getLastMonitoring_1Data(id);
        }
        // 関連するテーブルから最新のレコードを取得
        getPlan_1Data(client);
        getMonitoringDraftData(client);
        //---------------------------issue{No.440} end-------------------------------
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        getDateList(client);
        getLatestMonitoringReport(client, today);
    }, []);

    //---------------------------issue{No.509} start-----------------------------
    useEffect(() => {
        if (Object.keys(plan_1).length > 1) {
            setCiting("ready");
        }
    }, [plan_1]);
    //---------------------------issue{No.509} end-------------------------------

    // サービス等利用計画案から取得したデータを入力フォームの値管理用のステートに格納する
    const handleOnClickCitingData = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "入力されている内容は上書きされる場合があります。続けますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        setPriorityCount(plan_1.newPriorityCount);

        const newMonitoring_1 = {
            maximum_amount: plan_1.maximum_amount,
            plan: plan_1.plan_1,
            target_1: plan_1.target_1,
            target_2: plan_1.target_2,
            target_3: plan_1.target_3,
            target_4: plan_1.target_4,
            target_5: plan_1.target_5,
            achievement_period_1: plan_1.achievement_period_1,
            achievement_period_2: plan_1.achievement_period_2,
            achievement_period_3: plan_1.achievement_period_3,
            achievement_period_4: plan_1.achievement_period_4,
            achievement_period_5: plan_1.achievement_period_5,
            other_1: plan_1.note_1,
            other_2: plan_1.note_2,
            other_3: plan_1.note_3,
            other_4: plan_1.note_4,
            other_5: plan_1.note_5,
            daily_routine: plan_1.daily_routine,
            other_service: plan_1.other_service,
            total: plan_1.total,
        };

        const oldMonitoring_1 = monitoring_1.result;

        setMonitoring_1({ result: { ...oldMonitoring_1, ...newMonitoring_1 }, error_list: null, success: true });

        // 実行済みとして、ステートを書き換える
        setCiting("done");
    };
    //---------------------------issue{No.440} end-------------------------------

    //---------------------------issue{No.513} start-----------------------------
    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(clientData).length > 1) {
            if (clientData.office_name !== null) {
                setValue("office_name", clientData.office_name, { shouldValidate: false });
            }
            if (clientData.client_name !== null) {
                setValue("client_name", clientData.client_name, { shouldValidate: false });
            }
            if (clientData.recipient_no_2 !== null) {
                setValue("recipient_no_2", clientData.recipient_no_2, { shouldValidate: false });
            }
            if (clientData.recipient_no_3 !== null) {
                setValue("recipient_no_3", clientData.recipient_no_3, { shouldValidate: false });
            }
            if (clientData.recipient_no_4 !== null) {
                setValue("recipient_no_4", clientData.recipient_no_4, { shouldValidate: false });
            }
            if (clientData.start_month !== null) {
                setValue("start_month", clientData.start_month, { shouldValidate: false });
            }
        }
    }, [clientData]);
    //---------------------------issue{No.513} end-------------------------------

    // 前回作成時のデータを引用する
    useEffect(() => {
        if (Object.keys(monitoring_1.result).length > 1) {
            // すべてのエラーを削除
            clearErrors();
            setValue("maximum_amount", monitoring_1.result.maximum_amount, { shouldValidate: false });
            setValue("plan", monitoring_1.result.plan, { shouldValidate: false });
            setValue("situation", monitoring_1.result.situation, { shouldValidate: false });
            for (let ii = 1; ii <= priorityCount; ii++) {
                setValue(`target_${ii}`, monitoring_1.result[`target_${ii}`], { shouldValidate: false });
                setValue(`achievement_period_${ii}`, monitoring_1.result[`achievement_period_${ii}`], {
                    shouldValidate: false,
                });
                setValue(`service_${ii}`, monitoring_1.result[`service_${ii}`], { shouldValidate: false });
                setValue(`impression_${ii}`, monitoring_1.result[`impression_${ii}`], { shouldValidate: false });
                setValue(`achievement_${ii}`, monitoring_1.result[`achievement_${ii}`], { shouldValidate: false });
                setValue(`task_${ii}`, monitoring_1.result[`task_${ii}`], { shouldValidate: false });
                //---------------------------issue{No.440} start-----------------------------
                // 引用時にmonitoring_1のこれらに対応するカラムはないため、初期化のみ
                setValue(`service_type_${ii}`, false, { shouldValidate: false });
                setValue(`service_volume_${ii}`, false, { shouldValidate: false });
                setValue(`weekly_plan_${ii}`, false, { shouldValidate: false });
                //---------------------------issue{No.440} end-------------------------------
                setValue(`other_${ii}`, monitoring_1.result[`other_${ii}`], { shouldValidate: false });
            }
            setValue("daily_routine", monitoring_1.result.daily_routine, { shouldValidate: false });
            setValue("other_service", monitoring_1.result.other_service, { shouldValidate: false });
            setValue("total", monitoring_1.result.total, { shouldValidate: false });
        }
    }, [monitoring_1]);

    //---------------------------issue{No.513} start-----------------------------
    useEffect(() => {
        if (Object.keys(plan_1).length > 1) {
            setValue("maximum_amount", plan_1.maximum_amount, { shouldValidate: false });
            setValue("creation_date", plan_1.date, { shouldValidate: false });
        }
    }, [plan_1]);

    useEffect(() => {
        if (Object.keys(monitoring_1.result).length > 1) {
            setValue("monitoring_date", monitoring_1.result.date, { shouldValidate: false });
        }
    }, [monitoringDraft]);
    //---------------------------issue{No.513} end-------------------------------

    //---------------------------issue{No.471} start-----------------------------
    // データ取得後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (users.length > 0) {
            setValue("user_name", userInfo.user.user_name, { shouldValidate: true });
        }
    }, [users]);
    //---------------------------issue{No.471} end-------------------------------

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        control,
        clearErrors,
    } = useForm();

    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change" && name === "date") {
                setDateMessage(dateRegisteringErrorMessage(value.date, clientBirthdayRef.current, dateList.current));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        if (priorityCount < 5) {
            setPriorityCount((priorityCount) => priorityCount + 1);
        }
    };

    // 支援目標の優先順位を入れ替える関数
    const handleOnClickPriorityChange = (e, num) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const higherPriority = {
            target: `target_${num}`,
            achievementPeriod: `achievement_period_${num}`,
            service: `service_${num}`,
            impression: `impression_${num}`,
            achievement: `achievement_${num}`,
            task: `task_${num}`,
            serviceType: `service_type_${num}`,
            serviceVolume: `service_volume_${num}`,
            weeklyPlan: `weekly_plan_${num}`,
            other: `other_${num}`,
        };

        // 優先順位が一つ上がる支援目標についての入力欄のnameにあたる値一覧を作成して格納
        const lowerPriority = {
            target: `target_${num + 1}`,
            achievementPeriod: `achievement_period_${num + 1}`,
            service: `service_${num + 1}`,
            impression: `impression_${num + 1}`,
            achievement: `achievement_${num + 1}`,
            task: `task_${num + 1}`,
            serviceType: `service_type_${num + 1}`,
            serviceVolume: `service_volume_${num + 1}`,
            weeklyPlan: `weekly_plan_${num + 1}`,
            other: `other_${num + 1}`,
        };

        // 入れ替えの際に、値を一時的に控えておく定数オブジェクト
        const reserveValues = {
            target: null,
            achievementPeriod: null,
            service: null,
            impression: null,
            achievement: null,
            task: null,
            serviceType: false,
            serviceVolume: false,
            weeklyPlan: false,
            other: null,
        };

        // 優先順位が下がる側の入力欄の値を控えておく
        for (let key of Object.keys(higherPriority)) {
            const value = getValues(higherPriority[key]);
            reserveValues[key] = value;
        }

        // 優先順位の下の入力欄の値を上の入力欄に移し替える
        for (let key of Object.keys(higherPriority)) {
            const newValue = getValues(lowerPriority[key]);
            setValue(higherPriority[key], newValue, { shouldValidate: false });
        }

        // 控えておいた値を優先順位の下の入力欄に差し替える
        for (let key of Object.keys(reserveValues)) {
            setValue(lowerPriority[key], reserveValues[key], { shouldValidate: false });
        }
    };

    // 支援目標の各優先順位の右上にある✕ボタン押下時に、支援目標を削除したり空欄に戻したりする処理
    const handleOnClickPriorityClear = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        await swal({
            icon: "info",
            title: "確認",
            text: "この支援目標を削除して続く優先順位を上に詰める、もしくはこの支援目標に関する入力内容をクリアしますか？",
            buttons: {
                catch: {
                    text: "入力内容をクリア",
                    value: "clear",
                    visible: true,
                    className:
                        "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                    closeModal: true,
                },
                cancel: {
                    text: "キャンセル",
                    value: "cancel",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
                confirm: {
                    text: "削除する",
                    value: "delete",
                    visible: true,
                    className: "",
                    closeModal: true,
                },
            },
        }).then((value) => {
            switch (value) {
                case "clear":
                    // 選択範囲をクリアする
                    PriorityInputClear(num);
                    break;

                case "cancel":
                    break;

                case "delete":
                    // 先ず、対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    // 現在表示されている優先順位の個数分、優先順位を入れ替える関数を順に実行し、繰上げを行い
                    // ✕ボタンが押下され、上記の関数によって空欄になった入力欄を最下位に下げる
                    for (let ii = num; ii < priorityCount; ii++) {
                        handleOnClickPriorityChange(false, ii);
                    }
                    // 表示される優先順位の入力欄を減らす
                    setPriorityCount((priorityCount) => priorityCount - 1);
                    break;
                default:
                    break;
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const PriorityInputClear = (num) => {
        // 対象の優先順位の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = [
            `target_${num}`,
            `achievement_period_${num}`,
            `service_${num}`,
            `impression_${num}`,
            `achievement_${num}`,
            `task_${num}`,
            `service_type_${num}`,
            `service_volume_${num}`,
            `weekly_plan_${num}`,
            `other_${num}`,
        ];

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, null, { shouldValidate: false });
        }
    };

    // 参照用のコンポーネントを閉じる
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setSlideOn(false);

        setTimeout(() => {
            setSlideTitle("");
            setDisplayDataNum(0);
        }, 250);
    };

    // 参照用のコンポーネントを開くきっかけとなるステートの値を更新し参照用コンポーネントを表示する
    const handleOnClickDataShow = (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setDisplayDataNum(num);

        // MUIのFadeタグの属性値を切り替えて、アニメーションを実行させる
        if (slideOn) {
            setFadeOn(false);
            setTimeout(() => {
                setFadeOn(true);
            }, 100);
        }

        switch (num) {
            case 1:
                setSlideTitle(`サービス等利用計画（${plan_1.date}）`);
                break;
            case 2:
                setSlideTitle(`モニタリング記録（${monitoringDraft.date}）`);
                break;
            case 3:
                setSlideTitle(`モニタリング報告書（${lastMonitoring_1.date}）`);
                break;
            case 4:
                setSlideTitle(`日報（${report.date}）`);
                break;
            default:
                break;
        }
    };

    // MUIのSlideタグの属性値を切り替えて、アニメーションを実行させる
    useEffect(() => {
        if (slideTitle && !slideOn) {
            setSlideOn(true);
        }
    }, [slideTitle]);
    //---------------------------issue{No.440} end-------------------------------

    return (
        <div className='d-flex row justify-content-center'>
            {slideOn && (
                <div className='col-md-6 col-lg-6 mx-auto'>
                    <Slide
                        className='position-sticky top-0 vh-100'
                        direction='right'
                        in={slideOn}
                        mountOnEnter
                        unmountOnExit>
                        <div>
                            <div className='card d-block'>
                                <div className='card-header position-sticky top-0 d-flex justify-content-between bg-light'>
                                    <span className={classes.title}>{slideTitle}情報</span>
                                    <button
                                        className='btn btn-outline-primary btn-sm'
                                        type='button'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                                <Fade in={fadeOn}>
                                    <div className='card-body overflow-auto h-75'>
                                        {displayDataNum == 1 && (
                                            <>
                                                {plan_1Ref.current === null ? (
                                                    <Plan_1Show
                                                        idFromRegister={plan_1.id}
                                                        forRegister={true}
                                                        setRefCurrent={setPlan_1RefCurrent}
                                                    />
                                                ) : (
                                                    <DataShowForRegister data={plan_1Ref.current}></DataShowForRegister>
                                                )}
                                            </>
                                        )}
                                        {displayDataNum == 2 && (
                                            <>
                                                {monitoringDraftRef.current === null ? (
                                                    <MonitoringDraftShow
                                                        idFromRegister={monitoringDraft.id}
                                                        forRegister={true}
                                                        setRefCurrent={setMonitoringDraftRefCurrent}
                                                    />
                                                ) : (
                                                    <DataShowForRegister
                                                        data={monitoringDraftRef.current}></DataShowForRegister>
                                                )}
                                            </>
                                        )}
                                        {displayDataNum == 3 && (
                                            <>
                                                {lastMonitoring_1Ref.current === null ? (
                                                    <Monitoring_1Show
                                                        idFromRegister={lastMonitoring_1.id}
                                                        forRegister={true}
                                                        setRefCurrent={setLastMonitoring_1RefCurrent}
                                                    />
                                                ) : (
                                                    <DataShowForRegister
                                                        data={lastMonitoring_1Ref.current}></DataShowForRegister>
                                                )}
                                            </>
                                        )}
                                        {displayDataNum == 4 && (
                                            <Paper>
                                                <TableContainer>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className={classes.labelCell}>
                                                                    実施時間
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {report.start_time}～{report.end_time}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell className={classes.labelCell}>
                                                                    内容
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>{report.detail}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        )}
                                    </div>
                                </Fade>
                                <div className='card-footer bg-light d-flex justify-content-between'>
                                    <div
                                        className='btn-group'
                                        role='group'
                                        aria-label='Show data select toggle button group'>
                                        <label className={`btn btn-${displayDataNum == 1 ? "" : "outline-"}primary`}>
                                            サービス等利用計画
                                            <input
                                                type='radio'
                                                className='btn-check'
                                                name='radio_btn'
                                                autoComplete='off'
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, 1);
                                                }}
                                            />
                                        </label>
                                        <label className={`btn btn-${displayDataNum == 2 ? "" : "outline-"}primary`}>
                                            モニタリング記録
                                            <input
                                                type='radio'
                                                className='btn-check'
                                                name='radio_btn'
                                                autoComplete='off'
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, 2);
                                                }}
                                            />
                                        </label>
                                        {Object.keys(lastMonitoring_1).length > 1 && (
                                            <label
                                                className={`btn btn-${displayDataNum == 3 ? "" : "outline-"}primary`}>
                                                前回の報告書
                                                <input
                                                    type='radio'
                                                    className='btn-check'
                                                    name='radio_btn'
                                                    autoComplete='off'
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 3);
                                                    }}
                                                />
                                            </label>
                                        )}
                                        {report.success && (
                                            <label
                                                className={`btn btn-${displayDataNum == 4 ? "" : "outline-"}primary`}>
                                                最新の日報
                                                <input
                                                    type='radio'
                                                    className='btn-check'
                                                    name='radio_btn'
                                                    autoComplete='off'
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 4);
                                                    }}
                                                />
                                            </label>
                                        )}
                                    </div>
                                    <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                        閉じる
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Slide>
                </div>
            )}
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>モニタリング報告書作成</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <div className='d-flex justify-content-between mb-5'>
                            <div className='card w-50 p-2 bg-light'>
                                <p>各機能の最新から参照する</p>
                                {Object.keys(plan_1).length > 1 && (
                                    <>
                                        <Tooltip
                                            title='サービス等利用計画を画面の左側に表示する。'
                                            placement='left-end'
                                            arrow>
                                            <div
                                                className={classes.dataLink}
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, 1);
                                                }}>
                                                サービス等利用計画
                                                <br />（{plan_1.date}）
                                            </div>
                                        </Tooltip>
                                        <br />
                                    </>
                                )}
                                {Object.keys(monitoringDraft).length > 1 && (
                                    <>
                                        <Tooltip
                                            title='モニタリング記録を画面の左側に表示する。'
                                            placement='left-end'
                                            arrow>
                                            <div
                                                className={classes.dataLink}
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, 2);
                                                }}>
                                                モニタリング記録
                                                <br />（{monitoringDraft.date}）
                                            </div>
                                        </Tooltip>
                                        <br />
                                    </>
                                )}
                                {Object.keys(lastMonitoring_1).length > 1 && (
                                    <>
                                        <Tooltip
                                            title='モニタリング記録を画面の左側に表示する。'
                                            placement='left-end'
                                            arrow>
                                            <div
                                                className={classes.dataLink}
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, 3);
                                                }}>
                                                前回の報告書
                                                <br />（{lastMonitoring_1.date}）
                                            </div>
                                        </Tooltip>
                                        <br />
                                    </>
                                )}
                                {Object.keys(report).length > 1 && (
                                    <Tooltip
                                        title='モニタリング記録を画面の左側に表示する。'
                                        placement='left-end'
                                        arrow>
                                        <div
                                            className={classes.dataLink}
                                            onClick={(e) => {
                                                handleOnClickDataShow(e, 4);
                                            }}>
                                            日報
                                            <br />（{report.date}）
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                            <div className='w-50 ps-3'>
                                {Object.keys(plan_1).length > 1 && citing === "ready" && (
                                    <Tooltip
                                        title={`サービス等利用計画（${plan_1.date}）の内容を以下の入力欄に引用します。`}
                                        placement='bottom'
                                        arrow>
                                        <Button
                                            className='mb-3 bg-success'
                                            type='button'
                                            variant='contained'
                                            color='primary'
                                            onClick={(e) => {
                                                handleOnClickCitingData(e);
                                            }}>
                                            サービス等利用計画の内容を引用する
                                            <br />
                                            （※利用者の情報は対象外）
                                        </Button>
                                    </Tooltip>
                                )}
                                <Tooltip
                                    title='関連する作成済みの情報を画面の左側に表示する。'
                                    placement='bottom'
                                    arrow>
                                    <button
                                        className='d-block btn btn-primary'
                                        onClick={(e) => {
                                            handleOnClickDataShow(e, 1);
                                        }}>
                                        関連する作成済みの情報を並べて表示する
                                    </button>
                                </Tooltip>
                                {citing === "done" && (
                                    <button className='d-block btn btn-outline-secondary mt-3' disabled>
                                        サービス等利用計画（{plan_1.date}）の内容を引用済み
                                    </button>
                                )}
                            </div>
                        </div>
                        <p className='fs-6 pt-1'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(monitoring_1Submit)} encType='multipart/form-data'>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    報告書作成日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    defaultValue={today}
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*報告書作成日を入力してください。",
                                        },
                                    })}
                                />
                                {/* 日付の重複によるエラーメッセージ */}
                                <small className='text-danger'>{dateMessage && dateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.date}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1'>
                                    計画作成担当者
                                    <RequiredMark />
                                </label>
                                {Object.keys(users).length > 0 && (
                                    <Controller
                                        name='user_name'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*計画作成担当者を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        {Object.keys(users).length === 0 ? (
                                                            <>ユーザーが登録されていません</>
                                                        ) : (
                                                            <>選択してください</>
                                                        )}
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.user_name}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                )}
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.user_name}
                                </small>
                            </div>
                            <hr />
                            <h3 id='client_form' className='border-bottom border-secondary'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、利用者情報による内容</span>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                </label>
                                <input
                                    id='client_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("client_name", {
                                        required: {
                                            value: true,
                                            message: "*利用者氏名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>総合支援法</label>
                                <div className='form-control mb-1'>{SUPPORT_LAW_LIST[clientData?.support_law]}</div>
                            </div>
                            {clientData?.support_law === 2 && (
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1'>障害支援区分</label>
                                    <div className='form-control mb-1'>
                                        {SUPPORT_LEVEL_LIST[clientData?.support_level ?? 0]}
                                    </div>
                                </div>
                            )}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_name'>
                                    相談支援事業者名
                                </label>
                                <input
                                    id='office_name'
                                    type='text'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("office_name", {
                                        required: {
                                            value: true,
                                            message: "*相談支援事業者名を入力してください。",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='maximum_amount'>
                                    利用者負担上限額
                                </label>
                                <input
                                    id='maximum_amount'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("maximum_amount", {
                                        required: {
                                            value: true,
                                            message: "*利用者負担上限額を入力してください。",
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.maximum_amount && (
                                        <>
                                            {errors.maximum_amount?.message}
                                            <br />
                                            ※サービス等利用計画の編集機能から変更して下さい
                                            {!monitoring_1.success && <br />}
                                        </>
                                    )}
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    {monitoring_1.success ? "" : monitoring_1.error_list.maximum_amount}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_2'>
                                    障害福祉サービス受給者証番号
                                </label>
                                <input
                                    id='recipient_no_2'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_2", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_3'>
                                    地域相談支援受給者番号
                                </label>
                                <input
                                    id='recipient_no_3'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_3", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='recipient_no_4'>
                                    通所受給者番号
                                </label>
                                <input
                                    id='recipient_no_4'
                                    type='text'
                                    inputMode='numeric'
                                    className='form-control mb-1'
                                    readOnly
                                    {...register("recipient_no_4", {
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください",
                                        },
                                    })}
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>モニタリング頻度</label>
                                <div className='form-control mb-1'>{FREQUENCY_LIST?.[clientData?.frequency]}</div>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='creation_date'>
                                    計画作成日
                                </label>
                                <input
                                    id='creation_date'
                                    type='date'
                                    min={plan_1.date}
                                    max={plan_1.date}
                                    defaultValue={plan_1.date}
                                    className='form-control mb-1'
                                    readOnly
                                    disabled
                                />
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='monitoring_date'>
                                    モニタリング実施日
                                </label>
                                <input
                                    id='monitoring_date'
                                    type='date'
                                    min={monitoringDraft.date}
                                    max={monitoringDraft.date}
                                    defaultValue={monitoringDraft.date}
                                    className='form-control mb-1'
                                    disabled
                                />
                            </div>
                            {Object.keys(lastMonitoring_1).length > 1 && (
                                <div className='form-group mb-5'>
                                    <label className='fw-bold mb-1' htmlFor='last_date'>
                                        前回のモニタリング実施日
                                    </label>
                                    <input
                                        id='last_date'
                                        type='date'
                                        min={lastMonitoring_1.monitoring_date}
                                        max={lastMonitoring_1.monitoring_date}
                                        defaultValue={lastMonitoring_1.monitoring_date}
                                        className='form-control mb-1'
                                        readOnly
                                        disabled
                                    />
                                </div>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary mb-3'>
                                <span className='fs-2'>■</span>
                                <span className='fs-4'>以下、モニタリング報告書についての項目</span>
                            </h3>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='plan'>
                                    総合的な援助の方針
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='plan'
                                    className='form-control mb-1'
                                    {...register("plan", {
                                        required: {
                                            value: true,
                                            message: "*総合的な援助の方針を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*総合的な援助の方針を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.plan && errors.plan?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.plan}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='situation'>
                                    全体の状況
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='situation'
                                    className='form-control mb-1'
                                    {...register("situation", {
                                        required: {
                                            value: true,
                                            message: "*全体の状況を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*全体の状況を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.situation && errors.situation?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.situation}
                                </small>
                            </div>
                            <p className='text-secondary fs-6'>以下、支援目標について優先順位ごとに入力</p>
                            <div className={classes.priority}>
                                <span className={classes.priorityNum}>【1】</span>
                                {priorityCount > 1 && (
                                    <>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 1);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='target_1'>
                                    支援目標
                                    <RequiredMark />
                                </label>
                                <input
                                    id='target_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("target_1", {
                                        required: {
                                            value: true,
                                            message: "*支援目標を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.target_1 && errors.target_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.target_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='achievement_period_1'>
                                    達成時期
                                    <RequiredMark />
                                </label>
                                <input
                                    id='achievement_period_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("achievement_period_1", {
                                        required: {
                                            value: true,
                                            message: "*達成時期を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.achievement_period_1 && errors.achievement_period_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='service_1'>
                                    サービス提供状況
                                    <RequiredMark />
                                </label>
                                <input
                                    id='service_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("service_1", {
                                        required: {
                                            value: true,
                                            message: "*サービス提供状況を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.service_1 && errors.service_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.service_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='impression_1'>
                                    本人の感想・満足度
                                    <RequiredMark />
                                </label>
                                <input
                                    id='impression_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("impression_1", {
                                        required: {
                                            value: true,
                                            message: "*本人の感想・満足度を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.impression_1 && errors.impression_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.impression_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='achievement_1'>
                                    支援目標の達成度
                                    <RequiredMark />
                                </label>
                                <input
                                    id='achievement_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("achievement_1", {
                                        required: {
                                            value: true,
                                            message: "*支援目標の達成度を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.achievement_1 && errors.achievement_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.achievement_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='task_1'>
                                    今後の課題・解決方法
                                    <RequiredMark />
                                </label>
                                <input
                                    id='task_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("task_1", {
                                        required: {
                                            value: true,
                                            message: "*今後の課題・解決方法を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.task_1 && errors.task_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.task_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>サービス種類の変更</label>
                                <br />
                                <Controller
                                    name='service_type_1'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                <br />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.service_type_1 && errors.service_type_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.service_type_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>サービス量の変更</label>
                                <br />
                                <Controller
                                    name='service_volume_1'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                <br />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.service_volume_1 && errors.service_volume_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>週間計画の変更</label>
                                <br />
                                <Controller
                                    name='weekly_plan_1'
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox {...field} checked={field.value == true ? true : false} />
                                            }
                                            label='あり'
                                            className='mb-1'
                                        />
                                    )}
                                />
                                <br />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.weekly_plan_1 && errors.weekly_plan_1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_1'>
                                    その他留意事項
                                </label>
                                <input
                                    id='other_1'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("other_1")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.other_1 && errors.other_1?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.other_1}
                                </small>
                            </div>
                            {priorityCount >= 2 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【2】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 1);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 2 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 2);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 2);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_2'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_2", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_2 && errors.target_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.target_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_2'>
                                            達成時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_2", {
                                                required: {
                                                    value: true,
                                                    message: "*達成時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_2 && errors.achievement_period_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='service_2'>
                                            サービス提供状況
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='service_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("service_2", {
                                                required: {
                                                    value: true,
                                                    message: "*サービス提供状況を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_2 && errors.service_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='impression_2'>
                                            本人の感想・満足度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='impression_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("impression_2", {
                                                required: {
                                                    value: true,
                                                    message: "*本人の感想・満足度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.impression_2 && errors.impression_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.impression_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_2'>
                                            支援目標の達成度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_2", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標の達成度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_2 && errors.achievement_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_2'>
                                            今後の課題・解決方法
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_2", {
                                                required: {
                                                    value: true,
                                                    message: "*今後の課題・解決方法を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_2 && errors.task_2?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.task_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス種類の変更</label>
                                        <br />
                                        <Controller
                                            name='service_type_2'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_type_2 && errors.service_type_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_type_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス量の変更</label>
                                        <br />
                                        <Controller
                                            name='service_volume_2'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_volume_2 && errors.service_volume_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>週間計画の変更</label>
                                        <br />
                                        <Controller
                                            name='weekly_plan_2'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.weekly_plan_2 && errors.weekly_plan_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_2}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other_2'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='other_2'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("other_2")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.other_2 && errors.other_2?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.other_2}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount >= 3 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【3】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 2);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 3 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 3);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 3);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_3'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_3", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_3 && errors.target_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.target_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_3'>
                                            達成時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_3", {
                                                required: {
                                                    value: true,
                                                    message: "*達成時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_3 && errors.achievement_period_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='service_3'>
                                            サービス提供状況
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='service_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("service_3", {
                                                required: {
                                                    value: true,
                                                    message: "*サービス提供状況を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_3 && errors.service_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='impression_3'>
                                            本人の感想・満足度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='impression_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("impression_3", {
                                                required: {
                                                    value: true,
                                                    message: "*本人の感想・満足度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.impression_3 && errors.impression_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.impression_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_3'>
                                            支援目標の達成度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_3", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標の達成度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_3 && errors.achievement_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_3'>
                                            今後の課題・解決方法
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_3", {
                                                required: {
                                                    value: true,
                                                    message: "*今後の課題・解決方法を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_3 && errors.task_3?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.task_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス種類の変更</label>
                                        <br />
                                        <Controller
                                            name='service_type_3'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_type_3 && errors.service_type_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_type_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス量の変更</label>
                                        <br />
                                        <Controller
                                            name='service_volume_3'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_volume_3 && errors.service_volume_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>週間計画の変更</label>
                                        <br />
                                        <Controller
                                            name='weekly_plan_3'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.weekly_plan_3 && errors.weekly_plan_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_3}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other_3'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='other_3'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("other_3")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.other_3 && errors.other_3?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.other_3}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount >= 4 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【4】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 3);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        {priorityCount > 4 && (
                                            <button
                                                className='btn btn-secondary btn-sm mx-1'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityChange(e, 4);
                                                }}>
                                                優先順位を下げる<span className='fs-6 px-1'>▼</span>
                                            </button>
                                        )}
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 4);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_4'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_4", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_4 && errors.target_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.target_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_4'>
                                            達成時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_4", {
                                                required: {
                                                    value: true,
                                                    message: "*達成時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_4 && errors.achievement_period_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='service_4'>
                                            サービス提供状況
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='service_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("service_4", {
                                                required: {
                                                    value: true,
                                                    message: "*サービス提供状況を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_4 && errors.service_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='impression_4'>
                                            本人の感想・満足度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='impression_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("impression_4", {
                                                required: {
                                                    value: true,
                                                    message: "*本人の感想・満足度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.impression_4 && errors.impression_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.impression_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_4'>
                                            支援目標の達成度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_4", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標の達成度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_4 && errors.achievement_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_4'>
                                            今後の課題・解決方法
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_4", {
                                                required: {
                                                    value: true,
                                                    message: "*今後の課題・解決方法を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_4 && errors.task_4?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.task_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス種類の変更</label>
                                        <br />
                                        <Controller
                                            name='service_type_4'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_type_4 && errors.service_type_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_type_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス量の変更</label>
                                        <br />
                                        <Controller
                                            name='service_volume_4'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_volume_4 && errors.service_volume_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>週間計画の変更</label>
                                        <br />
                                        <Controller
                                            name='weekly_plan_4'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.weekly_plan_4 && errors.weekly_plan_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_4}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other_4'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='other_4'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("other_4")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.other_4 && errors.other_4?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.other_4}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount === 5 && (
                                <>
                                    <div className={classes.priority}>
                                        <span className={classes.priorityNum}>【5】</span>
                                        <button
                                            className='btn btn-secondary btn-sm mx-1'
                                            type='button'
                                            onClick={(e) => {
                                                handleOnClickPriorityChange(e, 4);
                                            }}>
                                            <span className='fs-6 px-1'>▲</span>優先順位を上げる
                                        </button>
                                        <div className='ms-auto'>
                                            <button
                                                className='btn btn-outline-primary btn-sm'
                                                type='button'
                                                onClick={(e) => {
                                                    handleOnClickPriorityClear(e, 5);
                                                }}>
                                                <span className='fw-bold'>×</span>
                                            </button>
                                        </div>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='target_5'>
                                            支援目標
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='target_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("target_5", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.target_5 && errors.target_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.target_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_period_5'>
                                            達成時期
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_period_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_period_5", {
                                                required: {
                                                    value: true,
                                                    message: "*達成時期を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_period_5 && errors.achievement_period_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_period_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='service_5'>
                                            サービス提供状況
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='service_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("service_5", {
                                                required: {
                                                    value: true,
                                                    message: "*サービス提供状況を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_5 && errors.service_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='impression_5'>
                                            本人の感想・満足度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='impression_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("impression_5", {
                                                required: {
                                                    value: true,
                                                    message: "*本人の感想・満足度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.impression_5 && errors.impression_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.impression_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='achievement_5'>
                                            支援目標の達成度
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='achievement_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("achievement_5", {
                                                required: {
                                                    value: true,
                                                    message: "*支援目標の達成度を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.achievement_5 && errors.achievement_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.achievement_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='task_5'>
                                            今後の課題・解決方法
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='task_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("task_5", {
                                                required: {
                                                    value: true,
                                                    message: "*今後の課題・解決方法を入力してください。",
                                                },
                                            })}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>{errors.task_5 && errors.task_5?.message}</small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.task_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス種類の変更</label>
                                        <br />
                                        <Controller
                                            name='service_type_5'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_type_5 && errors.service_type_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_type_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>サービス量の変更</label>
                                        <br />
                                        <Controller
                                            name='service_volume_5'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.service_volume_5 && errors.service_volume_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.service_volume_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>週間計画の変更</label>
                                        <br />
                                        <Controller
                                            name='weekly_plan_5'
                                            control={control}
                                            render={({ field, fieldState }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={field.value == true ? true : false}
                                                        />
                                                    }
                                                    label='あり'
                                                    className='mb-1'
                                                />
                                            )}
                                        />
                                        <br />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.weekly_plan_5 && errors.weekly_plan_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.weekly_plan_5}
                                        </small>
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other_5'>
                                            その他留意事項
                                        </label>
                                        <input
                                            id='other_5'
                                            type='text'
                                            className='form-control mb-1'
                                            {...register("other_5")}
                                        />
                                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {errors.other_5 && errors.other_5?.message}
                                        </small>
                                        {/* サーバー側のバリデーションによるエラーメッセージ */}
                                        <small className='text-danger'>
                                            {monitoring_1.success ? "" : monitoring_1.error_list.other_5}
                                        </small>
                                    </div>
                                </>
                            )}
                            {priorityCount < 5 && (
                                <div className='w-100 mb-5'>
                                    <hr />
                                    <div className='text-center'>
                                        <button
                                            type='button'
                                            className='btn btn-outline-primary'
                                            onClick={(e) => {
                                                handleOnClickAddInput(e);
                                            }}>
                                            <span className='fw-bold fs-5 px-1'>＋</span>
                                            支援目標を追加する
                                        </button>
                                    </div>
                                </div>
                            )}
                            <hr />
                            <h3 className='border-bottom border-secondary'>
                                <span className='fs-2 text-danger'>■</span>
                                <span className='fs-4'>以下、週間計画表に関する項目</span>
                                <small className='fs-6 fw-normal text-secondary'>（二枚目の様式について）</small>
                            </h3>
                            <p className='fs-6 fw-normal text-secondary mb-5'>
                                ※週間計画表の表部分は、新規作成後に別途作成します
                            </p>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='daily_routine'>
                                    主な日常生活上の活動
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='daily_routine'
                                    className='form-control mb-1'
                                    {...register("daily_routine", {
                                        required: {
                                            value: true,
                                            message: "*主な日常生活上の活動を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*主な日常生活上の活動を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.daily_routine && errors.daily_routine?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.daily_routine}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='other_service'>
                                    週単位以外のサービス
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='other_service'
                                    className='form-control mb-1'
                                    {...register("other_service", {
                                        required: {
                                            value: true,
                                            message: "*週単位以外のサービスを入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*週単位以外のサービスを入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.other_service && errors.other_service?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.other_service}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='total'>
                                    サービス提供によって実現する生活の全体像
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='total'
                                    className='form-control mb-1'
                                    {...register("total", {
                                        required: {
                                            value: true,
                                            message: "*サービス提供によって実現する生活の全体像を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*サービス提供によって実現する生活の全体像を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.total && errors.total?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoring_1.success ? "" : monitoring_1.error_list.total}
                                </small>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    作成
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
