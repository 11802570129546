import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDeletedDataList from "../../components/ClientSelectToDeletedDataList";

// 見出しの配列定義
const headers = ["申請年月日"];

export default function ApplicationDeleted() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDeletedDataList pageName='相談支援支給申請' targetURL='application' headerDataList={headers} clientID={client} />;
}