import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import {
    Button,
    Select,
    MenuItem,
    Slide,
    Fade,
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Tooltip,
} from "@material-ui/core";
//---------------------------issue{No.513} end-------------------------------

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import { MIN_MAX_DATE } from "../../common/constants";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";
import { UserContext } from "../../providers/UserProvider";

import MonitoringDraftShow from "./MonitoringDraftShow";
import Monitoring_1Show from "../monitoring_1/Monitoring_1Show";
import DataShowForRegister from "../../components/DataShowForRegister";

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
        labelCell: {
            backgroundColor: "#e3f7fe",
        },
        dataLink: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" },
        },
    })
);

// 初期状態
const initLatestMonitoringDraft = { success: false };

// 初期状態
const initMonitoringDraft = { result: {}, error_list: null, success: true };

// 初期状態
const initMonitoring_1 = { success: false };

// 初期状態
const initReport = { success: false };

// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

function MonitoringDraftRegister() {
    //---------------------------issue{No.471} start-----------------------------
    // ログイン中のユーザー情報を受け取る
    const { userInfo } = useContext(UserContext);
    //---------------------------issue{No.471} end-------------------------------

    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 利用者のidを保持している
    const { client } = useParams();

    //---------------------------issue{No.453} start-----------------------------
    // 日付の入力制限を設ける為に日付の上限値として用いる定数を読み込みます
    const { MAX_DATE } = MIN_MAX_DATE;
    //---------------------------issue{No.453} end-------------------------------

    // 入力値を管理する
    const [monitoringDraft, setMonitoringDraft] = useState(initMonitoringDraft);

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // watch内で使用する利用者の生年月日
    const clientBirthdayRef = useRef(""); // issue516

    //---------------------------issue{No.471} start-----------------------------
    // データベースから取得したユーザーの名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    const [report, setReport] = useState(initReport);

    const [monitoring_1, setMonitoring_1] = useState(initMonitoring_1);

    const [latestMonitoringDraft, setLatestMonitoringDraft] = useState(initLatestMonitoringDraft);

    // 引用に関して
    const [citing, setCiting] = useState("");

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");

    // 関数handleOnClickDataShowの第2引数
    const [dataShowNo, setDataShowNo] = useState(0);

    // 参照するデータを所持しておくRefオブジェクト
    const latestMonitoringDraftRef = useRef(null);
    const setLatestMonitoringDraftRefCurrent = (data) => {
        latestMonitoringDraftRef.current = data;
    };

    const monitoring_1Ref = useRef(null);
    const setMonitoring_1RefCurrent = (data) => {
        monitoring_1Ref.current = data;
    };

    // MUIのSlideタグの属性値を操作する
    const [slideOn, setSlideOn] = useState(false);

    // MUIのFadeタグの属性値を操作する（初期値：true）
    const [fadeOn, setFadeOn] = useState(true);

    // 参照用のコンポーネントの見出しに使う文字列を管理する
    const [slideTitle, setSlideTitle] = useState("");

    // 参照しているデータの識別子
    const [displayDataNum, setDisplayDataNum] = useState(0);

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `monitoring_drafts/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                //---------------------------issue{No.513} start-----------------------------
                // user_name: clientResult.user_name,
                //---------------------------issue{No.513} end-------------------------------
                client_id: clientResult.client_id,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
            });

            //---------------------------issue{No.516} start-------------------------------
            clientBirthdayRef.current =
                clientResult.is_child == 1 ? clientResult.child_birthday : clientResult.client_birthday;
            //---------------------------issue{No.516} end-------------------------------
        }
    };

    //---------------------------issue{No.471} start-----------------------------
    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersName = res.data.result.map((user) => {
                return {
                    user_name: user.user_name,
                };
            });
            setUsers(usersName);
        }
    };
    //---------------------------issue{No.471} end-------------------------------

    const getDateList = async (clientId) => {
        const url = `monitoring_drafts_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            const result = res.data.result.map((item) => {
                return item.date;
            });
            dateList.current = result;
        }

        // データ取得時に、本日の日付がすでに作成されていないかを判定
        setDateMessage(dateRegisteringErrorMessage(today, clientBirthdayRef.current, dateList.current));
    };

    const getLatestMonitoringReport = async (id, today) => {
        const url = `reports_latest_monitoring/${id}/${today}`;
        const res = await api.get(url);
        if (res.data.success && res.data.result !== null) {
            setReport({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
        }
    };

    // 情報を取得しステート latestMonitoringにセットする
    const getLatestMonitoring_1Data = async (clientId) => {
        const url = `monitorings_1_get_latest/${clientId}`;
        const res = await api.get(url);
        if (res.data.success && res.data.result !== null) {
            setMonitoring_1({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
        }
    };

    // 情報を取得しステート latestMonitoringにセットする
    const getLatestMonitoringDraftData = async (clientId) => {
        const url = `monitoring_drafts_get_latest/${clientId}`;
        const res = await api.get(url);
        if (res.data.success && res.data.result !== null) {
            setLatestMonitoringDraft({
                ...res.data.result,
                error_list: null,
                success: res.data.success,
            });
            setCiting("ready");
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 作成ボタン押下時
    const monitoringDraftSubmit = async (data) => {
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "作成内容を見直してください",
                timer: 2000,
            });
            return;
        }

        //---------------------------issue{No.513} start-----------------------------
        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;
        //---------------------------issue{No.513} end-------------------------------

        await swal({
            icon: "info",
            title: "確認",
            text: "作成しますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // MonitoringDraftControllerに渡す値をセット
        const monitoringDraftData = {
            ...clientData,
            //---------------------------issue{No.471} start-----------------------------
            user_name: data.user_name,
            //---------------------------issue{No.471} end-------------------------------
            date: data.date,
            start_time: data.start_time,
            end_time: data.end_time,
            location: data.location,
            checklist: data.checklist,
            support: data.support,
            client_state: data.client_state,
            supplement: data.supplement,
        };

        // 作成
        const url = "monitoring_drafts";
        const res = await api.post(url, monitoringDraftData);
        if (res.status === 422) {
            setMonitoringDraft({
                ...monitoringDraft,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                navigate(`/monitoring_draft/${client}`);
            });
        }
    };

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        getClientData(client);
        //---------------------------issue{No.471} start-----------------------------
        getUsersData();
        //---------------------------issue{No.471} end-------------------------------
        getDateList(client);
        getLatestMonitoringReport(client, today);
        getLatestMonitoringDraftData(client);
        getLatestMonitoring_1Data(client);
    }, []);

    useEffect(() => {
        if (monitoring_1.success) {
            setDataShowNo(1);
        } else if (latestMonitoringDraft.success) {
            setDataShowNo(2);
        } else if (report.success) {
            setDataShowNo(3);
        }
    }, [monitoring_1, latestMonitoringDraft, report]);

    //---------------------------issue{No.471} start-----------------------------
    // データ取得後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (users.length > 0) {
            setValue("user_name", userInfo.user.user_name, { shouldValidate: true });
        }
    }, [users]);
    //---------------------------issue{No.471} end-------------------------------

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        //---------------------------issue{No.471} start-----------------------------
        setValue,
        //---------------------------issue{No.471} end-------------------------------
        getValues,
        watch,
        //---------------------------issue{No.471} start-----------------------------
        control,
        //---------------------------issue{No.471} end-------------------------------
    } = useForm();

    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change" && name === "date") {
                setDateMessage(dateRegisteringErrorMessage(value.date, clientBirthdayRef.current, dateList.current));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // 参照用のコンポーネントを閉じる
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setSlideOn(false);

        setTimeout(() => {
            setSlideTitle("");
            setDisplayDataNum(0);
        }, 250);
    };

    // 参照用のコンポーネントを開くきっかけとなるステートの値を更新し参照用コンポーネントを表示する
    const handleOnClickDataShow = (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setDisplayDataNum(num);

        // MUIのFadeタグの属性値を切り替えて、アニメーションを実行させる
        if (slideOn) {
            setFadeOn(false);
            setTimeout(() => {
                setFadeOn(true);
            }, 100);
        }

        switch (num) {
            case 1:
                setSlideTitle(`モニタリング報告書（${monitoring_1.date}）`);
                break;
            case 2:
                setSlideTitle(`モニタリング記録（${latestMonitoringDraft.date}）`);
                break;
            case 3:
                setSlideTitle(`日報（${report.date}）`);
                break;
            default:
                break;
        }
    };

    // モニタリング記録から取得したデータを入力フォームの値管理用のステートに格納する
    const handleOnClickCitingData = async (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "入力されている内容は上書きされる場合があります。続けますか？",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        const newMonitoringDraft = {
            checklist: latestMonitoringDraft.checklist,
            support: latestMonitoringDraft.support,
            client_state: latestMonitoringDraft.client_state,
            supplement: latestMonitoringDraft.supplement,
        };

        const oldMonitoringDraft = monitoringDraft.result;

        setMonitoringDraft({
            result: { ...oldMonitoringDraft, ...newMonitoringDraft },
            error_list: null,
            success: true,
        });

        // 実行済みとして、ステートを書き換える
        setCiting("done");
    };

    // MUIのSlideタグの属性値を切り替えて、アニメーションを実行させる
    useEffect(() => {
        if (slideTitle && !slideOn) {
            setSlideOn(true);
        }
    }, [slideTitle]);

    useEffect(() => {
        if (citing === "done") {
            setValue("checklist", latestMonitoringDraft.checklist, { shouldValidate: false });
            setValue("support", latestMonitoringDraft.support, { shouldValidate: false });
            setValue("client_state", latestMonitoringDraft.client_state, { shouldValidate: false });
            setValue("supplement", latestMonitoringDraft.supplement, { shouldValidate: false });
        }
    }, [citing]);

    return (
        <div className='d-flex row justify-content-center'>
            {slideOn && (
                <div className='col-md-6 col-lg-6 mx-auto'>
                    <Slide
                        className='position-sticky top-0 vh-100'
                        direction='right'
                        in={slideOn}
                        mountOnEnter
                        unmountOnExit>
                        <div>
                            <div className='card d-block'>
                                <div className='card-header position-sticky top-0 d-flex justify-content-between bg-light'>
                                    <span className={classes.title}>{slideTitle}情報</span>
                                    <button
                                        className='btn btn-outline-primary btn-sm'
                                        type='button'
                                        onClick={(e) => handleOnClickClose(e)}>
                                        ×
                                    </button>
                                </div>
                                <Fade in={fadeOn}>
                                    <div className='card-body overflow-auto h-75'>
                                        {displayDataNum == 1 && (
                                            <>
                                                {monitoring_1Ref.current === null ? (
                                                    <Monitoring_1Show
                                                        idFromRegister={monitoring_1.id}
                                                        forRegister={true}
                                                        setRefCurrent={setMonitoring_1RefCurrent}
                                                    />
                                                ) : (
                                                    <DataShowForRegister
                                                        data={monitoring_1Ref.current}></DataShowForRegister>
                                                )}
                                            </>
                                        )}
                                        {displayDataNum == 2 && (
                                            <>
                                                {latestMonitoringDraftRef.current === null ? (
                                                    <MonitoringDraftShow
                                                        idFromRegister={latestMonitoringDraft.id}
                                                        forRegister={true}
                                                        setRefCurrent={setLatestMonitoringDraftRefCurrent}
                                                    />
                                                ) : (
                                                    <DataShowForRegister
                                                        data={latestMonitoringDraftRef.current}></DataShowForRegister>
                                                )}
                                            </>
                                        )}
                                        {displayDataNum == 3 && (
                                            <Paper>
                                                <TableContainer>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell className={classes.labelCell}>
                                                                    実施時間
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>
                                                                    {report.start_time}～{report.end_time}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell className={classes.labelCell}>
                                                                    内容
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>{report.detail}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        )}
                                    </div>
                                </Fade>
                                <div className='card-footer bg-light d-flex justify-content-between'>
                                    <div
                                        className='btn-group'
                                        role='group'
                                        aria-label='Show data select toggle button group'>
                                        {latestMonitoringDraft.success && (
                                            <label
                                                className={`btn btn-${displayDataNum == 1 ? "" : "outline-"}primary`}>
                                                モニタリング報告書
                                                <input
                                                    type='radio'
                                                    className='btn-check'
                                                    name='radio_btn'
                                                    autoComplete='off'
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 1);
                                                    }}
                                                />
                                            </label>
                                        )}
                                        {monitoring_1.success && (
                                            <label
                                                className={`btn btn-${displayDataNum == 2 ? "" : "outline-"}primary`}>
                                                前回の記録
                                                <input
                                                    type='radio'
                                                    className='btn-check'
                                                    name='radio_btn'
                                                    autoComplete='off'
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 2);
                                                    }}
                                                />
                                            </label>
                                        )}
                                        {report.success && (
                                            <label
                                                className={`btn btn-${displayDataNum == 3 ? "" : "outline-"}primary`}>
                                                最新の日報
                                                <input
                                                    type='radio'
                                                    className='btn-check'
                                                    name='radio_btn'
                                                    autoComplete='off'
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 3);
                                                    }}
                                                />
                                            </label>
                                        )}
                                    </div>
                                    <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                                        閉じる
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Slide>
                </div>
            )}
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>モニタリング記録作成</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        {dataShowNo > 0 && (
                            <div className='d-flex justify-content-between mb-5'>
                                <div className='card w-50 p-2 bg-light'>
                                    <p>各機能の最新から参照する</p>
                                    {monitoring_1.success && (
                                        <>
                                            <Tooltip
                                                title='モニタリング報告書を画面の左側に表示する。'
                                                placement='left-end'
                                                arrow>
                                                <div
                                                    className={classes.dataLink}
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 1);
                                                    }}>
                                                    モニタリング報告書
                                                    <br />（{monitoring_1.date}）
                                                </div>
                                            </Tooltip>
                                            <br />
                                        </>
                                    )}
                                    {latestMonitoringDraft.success && (
                                        <>
                                            <Tooltip
                                                title='モニタリング記録を画面の左側に表示する。'
                                                placement='left-end'
                                                arrow>
                                                <div
                                                    className={classes.dataLink}
                                                    onClick={(e) => {
                                                        handleOnClickDataShow(e, 2);
                                                    }}>
                                                    モニタリング記録
                                                    <br />（{latestMonitoringDraft.date}）
                                                </div>
                                            </Tooltip>
                                            <br />
                                        </>
                                    )}
                                    {report.success && (
                                        <Tooltip title='日報を画面の左側に表示する。' placement='left-end' arrow>
                                            <div
                                                className={classes.dataLink}
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, 3);
                                                }}>
                                                日報
                                                <br />（{report.date}）
                                            </div>
                                        </Tooltip>
                                    )}
                                </div>
                                <div className='w-50 ps-3'>
                                    {latestMonitoringDraft.success && citing === "ready" && (
                                        <Tooltip
                                            title={`前回のモニタリング記録（${latestMonitoringDraft.date}）の内容を以下の入力欄に引用します。`}
                                            placement='bottom'
                                            arrow>
                                            <Button
                                                className='mb-3 bg-success'
                                                type='button'
                                                variant='contained'
                                                color='primary'
                                                onClick={(e) => {
                                                    handleOnClickCitingData(e);
                                                }}>
                                                前回のモニタリング記録の内容を引用する
                                                <br />
                                                （※利用者の情報は対象外）
                                            </Button>
                                        </Tooltip>
                                    )}
                                    {citing === "done" && (
                                        <button className='d-block btn btn-outline-secondary mt-3' disabled>
                                            以前のモニタリング記録（{latestMonitoringDraft.date}）の内容を引用済み
                                        </button>
                                    )}
                                    {dataShowNo > 0 && (
                                        <Tooltip
                                            title='関連する作成済みの情報を画面の左側に表示する。'
                                            placement='bottom'
                                            arrow>
                                            <button
                                                className='d-block btn btn-primary'
                                                onClick={(e) => {
                                                    handleOnClickDataShow(e, dataShowNo);
                                                }}>
                                                関連する作成済みの情報を並べて表示する
                                            </button>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                        )}
                        <div className='d-flex justify-content-between mb-5'>
                            <p className='fs-6 pt-1'>
                                <RequiredMark />
                                <span className='text-secondary'>は必須項目です。</span>
                            </p>
                        </div>
                        <form onSubmit={handleSubmit(monitoringDraftSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='date'>
                                    実施年月日
                                    <RequiredMark />
                                </label>
                                <input
                                    id='date'
                                    type='date'
                                    min={clientBirthdayRef.current}
                                    max={MAX_DATE}
                                    defaultValue={today}
                                    className='form-control mb-1'
                                    {...register("date", {
                                        required: {
                                            value: true,
                                            message: "*実施年月日を入力してください。",
                                        },
                                    })}
                                />
                                {/* 日付の重複によるエラーメッセージ */}
                                <small className='text-danger'>{dateMessage && dateMessage}</small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.date && errors.date?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.date}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1'>
                                    相談支援員氏名
                                    <RequiredMark />
                                </label>
                                {Object.keys(users).length > 0 && (
                                    <Controller
                                        name='user_name'
                                        defaultValue={""}
                                        control={control}
                                        rules={{ required: "*相談支援員氏名を選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <>
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        {Object.keys(users).length === 0 ? (
                                                            <>ユーザーが登録されていません</>
                                                        ) : (
                                                            <>選択してください</>
                                                        )}
                                                    </MenuItem>
                                                    {users.map((item, index) => (
                                                        <MenuItem key={index} value={item.user_name}>
                                                            {item.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                )}
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.user_name}
                                </small>
                            </div>
                            <hr className='mb-4' />
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_name'>
                                    利用者氏名
                                </label>
                                <div className='form-control mb-1'>{clientData?.client_name}</div>
                            </div>
                            <hr className='mb-5' />
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    実施時間
                                    <RequiredMark />
                                </label>
                                <div className='d-flex justify-content-start'>
                                    <input
                                        id='start_time'
                                        type='time'
                                        className='form-control mb-1'
                                        {...register("start_time", {
                                            required: {
                                                value: true,
                                                message: "*開始時刻を入力してください。",
                                            },
                                            validate: {
                                                message: (value) => {
                                                    const endTime = getValues("end_time");
                                                    if (value && endTime && value > endTime) {
                                                        return `*実施時間を見直してください。`;
                                                    } else {
                                                        return null;
                                                    }
                                                },
                                            },
                                        })}
                                    />
                                    <span className='d-block p-2 lh-lg align-items-center'>～</span>
                                    <input
                                        id='end_time'
                                        type='time'
                                        className='form-control mb-1'
                                        {...register("end_time", {
                                            required: {
                                                value: true,
                                                message: "*終了時刻を入力してください。",
                                            },
                                        })}
                                    />
                                </div>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.start_time && errors.start_time?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.start_time}
                                </small>
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.end_time && errors.end_time?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.end_time}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='location'>
                                    実施場所
                                    <RequiredMark />
                                </label>
                                <input
                                    id='location'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("location", {
                                        required: {
                                            value: true,
                                            message: "*実施場所を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.location && errors.location?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.location}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='checklist'>
                                    確認事項
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='checklist'
                                    className='form-control mb-1'
                                    {...register("checklist", {
                                        required: {
                                            value: true,
                                            message: "*確認事項を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*確認事項を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.checklist && errors.checklist?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.checklist}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='support'>
                                    提供支援
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='support'
                                    className='form-control mb-1'
                                    {...register("support", {
                                        required: {
                                            value: true,
                                            message: "*提供支援を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*提供支援を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.support && errors.support?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.support}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='client_state'>
                                    利用者の様子
                                    <RequiredMark />
                                </label>
                                <textarea
                                    id='client_state'
                                    className='form-control mb-1'
                                    {...register("client_state", {
                                        required: {
                                            value: true,
                                            message: "*利用者の様子を入力してください。",
                                        },
                                        validate: {
                                            message: (value) => {
                                                const trimValue = value.trim();
                                                if (!trimValue) {
                                                    return `*利用者の様子を入力してください。（※改行や空白文字のみ入力されています。）`;
                                                } else {
                                                    return null;
                                                }
                                            },
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.client_state && errors.client_state?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.client_state}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='supplement'>
                                    その他
                                </label>
                                <textarea id='supplement' className='form-control mb-1' {...register("supplement")} />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.supplement && errors.supplement?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {monitoringDraft.success ? "" : monitoringDraft.error_list.supplement}
                                </small>
                            </div>
                            <hr />
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    作成
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MonitoringDraftRegister;
