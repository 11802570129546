import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.513} start-----------------------------
import {
    Button,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        clientName: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" }
        },
        dataLink: {
            cursor: "pointer",
            "&:hover": { textDecoration: "underline" }
        },
        priority: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004"
        },
        priorityNum: { lineHeight: "2rem" },
        //---------------------------issue{No.440} start-----------------------------
        figure: {
            marginBottom: "0!important",
            fontWeight: "bold"
        },
        beforeWrapper: {
            width: "240px",
            maxHeight: "240px",
            marginTop: "10px",
            border: "1px dashed #888",
            boxSizing: "border-box",
            textAlign: "center"
        },
        before: {
            maxWidth: "238px",
            maxHeight: "238px"
        }
        //---------------------------issue{No.440} end-------------------------------
    })
);

// 初期状態
const initServicePersonnelMeeting = { success: true };

// 最大値に使う年月日を取得
function getMaxDay(date) {
    const y = date.getFullYear() + 1;
    date.setDate(date.getDate() - 1);
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 定数に格納
const MAX_DATE = getMaxDay(new Date());
// 優先順位のある入力欄の最大表示個数
const MAX_PRIORITY_COUNT = 8;

export default function ServicePersonnelMeetingEdit() {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 画面遷移用
    const navigate = useNavigate();

    // 利用者のidと、前回作成したデータのidを保持している
    const {client, id} = useParams();
    // 移動先pageのaddress
    const backLink = `/service_personnel_meeting/${client}`;
    // 入力値を管理する
    const [servicePersonnelMeeting, setServicePersonnelMeeting] = useState(initServicePersonnelMeeting);

    // 読み込みが完了しているか
    const [loaded, setLoaded] = useState(false);

    //---------------------------issue{No.440} end-------------------------------

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // データベースから取得したユーザーのIDと名前を管理する
    const [users, setUsers] = useState([]);
    //---------------------------issue{No.471} end-------------------------------

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");

    // 優先順位の入力対象たる値のキー名
    const priorityColumnList = ["attendee_affiliation", "attendee_name"];

    // 優先順位ごとの入力欄の表示個数を管理する
    const [priorityCount, setPriorityCount] = useState(1);

    // 情報を取得しステート proposedPlan_1にセットする
    const getServicePersonnelMeeting = async (id) => {
        const url = `service_personnel_meetings/show/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            // 会議主席者の入力欄の表示個数を格納する
            const newPriorityCount = Object.keys(res.data.result).filter(columnName =>
                columnName.startsWith(`attendee_affiliation_`) && res.data.result[columnName]).length;
            // 結果をステートにセットし、入力欄を表示させる
            setPriorityCount(newPriorityCount);

            setServicePersonnelMeeting({
                ...res.data.result,
                error_list: null,
                success: res.data.success
            });
        }
    };

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const url = `service_personnel_meetings/client/${client}`;
        const res = await api.get(url);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                user_name: clientResult.user_name,
                client_id: clientResult.client_id,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                client_birthday: clientResult.is_child ? clientResult.child_birthday : clientResult.client_birthday
            });
        }
    };

    // ユーザー情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersResult = res.data.result.map((user) => ({
                user_id: user.user_id,
                user_name: user.user_name
            }));
            setUsers(usersResult);
        }
    };

    // 指定した利用者に紐づくサービス担当者会議レコードの開催年月日dateカラムのデータを取得する
    const getDateList = async (clientId) => {
        const url = `service_personnel_meetings_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success && Object.keys(servicePersonnelMeeting).length > 1 && servicePersonnelMeeting.success) {
            dateList.current = res.data.result.filter((item) => item.date !== servicePersonnelMeeting.date).map((item) => item.date);
        }
    };

    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        setLoaded(false);
        navigate(backLink);
    };

    // 更新ボタン押下時
    const onSubmit = async (data) => {
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "作成内容を見直してください",
                timer: 2000
            });
            return;
        }

        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか?",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // const selectedUser = users.find(user => data.user_id === user.user_id);
        // 更新
        const url = `service_personnel_meetings/${id}`;
        const res = await api.put(url, data);
        if (res.status === 422) {
            setServicePersonnelMeeting({
                ...servicePersonnelMeeting,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.client_name, "success").then(() => {
                setServicePersonnelMeeting(initServicePersonnelMeeting);
                setLoaded(false);
                navigate(backLink);
            });
        }
    };

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        getServicePersonnelMeeting(id);
        getClientData();
        getUsersData();
    }, []);

    // 画面初期表示時の情報の取得完了後に重複確認用の日付一覧を取得する
    useEffect(() => {
        if (
            Object.keys(servicePersonnelMeeting).length > 1 && servicePersonnelMeeting.success &&
            Object.keys(users).length > 0 &&
            Object.keys(clientData).length > 0
        ) {
            setLoaded(true);
            getDateList(client);
        } else {
            setLoaded(false);
        }
    }, [users, servicePersonnelMeeting, clientData]);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: {errors},
        setValue,
        getValues,
        watch,
        control,
    } = useForm();

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        if (priorityCount < MAX_PRIORITY_COUNT) {
            setPriorityCount((priorityCount) => priorityCount + 1);
        }
    };

    // 支援目標の優先順位を入れ替える関数
    const handleOnClickPriorityChange = (e, num = 1) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる項目の入力欄のnameにあたる値一覧を作成して格納
        const higherPriority = new Map(priorityColumnList.map(column => ([`${column}`, `${column}_${num}`])));

        // 優先順位が一つ上がる項目の入力欄のnameにあたる値一覧を作成して格納
        const lowerPriority = new Map(priorityColumnList.map(column => ([`${column}`, `${column}_${num + 1}`])));

        // 優先順位が下がる側の入力欄の値
        const reserveValues = new Map(priorityColumnList.map(column => ([`${column}`, getValues(`${column}_${num}`)])));

        // 優先順位が下の入力欄と上の入力欄の値を移し替える
        for (let key of higherPriority.keys()) {
            const newValue = getValues(lowerPriority.get(key));
            setValue(higherPriority.get(key), newValue, {shouldValidate: false});
            setValue(lowerPriority.get(key), reserveValues.get(key), {shouldValidate: false});
        }
    };

    // 各優先順位の右上にある✕ボタン押下時に入力項目を削除したり空欄に戻したりする処理
    const handleOnClickPriorityClear = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // sweetAlertに共通して表示するbutton
        const commonClearButtons = {
            catch: {
                text: "入力内容をクリア",
                value: "clear",
                visible: true,
                className:
                    "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                closeModal: true
            },
            cancel: {
                text: "キャンセル",
                value: "cancel",
                visible: true,
                className: "",
                closeModal: true
            }
        };

        //  sweetAlertにて項目を削除して非表示にするbutton
        const deleteValueButton = {
            confirm: {
                text: "削除する",
                value: "delete",
                visible: priorityCount >= 2,
                className: "",
                closeModal: true
            }
        };

        // 優先順位の項目が2つ以上表示されているの場合は削除Buttonとmessageを追加表示する
        const priorityClearButtons = priorityCount >= 2 ?
            {...commonClearButtons, ...deleteValueButton} : commonClearButtons;
        const priorityClearConfirmMessage = (priorityCount >= 2 ? 'この項目を削除して続く優先順位を上に詰める,もしくは' : "") + "この項目に関する入力内容をクリアしますか?";

        await swal({
            icon: "info",
            title: "確認",
            text: priorityClearConfirmMessage,
            buttons: priorityClearButtons
        }).then((value) => {
            switch (value) {
                case "clear":
                    // 対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    break;
                case "delete":
                    // 先ず,対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    // 誤動作の予防の為に優先順位の入力項目が2つ以上の場合に実行
                    if (priorityCount >= 2) {
                        // 現在表示されている優先順位の個数分,優先順位を入れ替える関数を順に実行して繰上げを行い
                        // ✕ボタンが押下され,上記の関数によって空欄になった入力欄を最下位に下げる
                        for (let ii = num; ii < priorityCount; ii++) {
                            handleOnClickPriorityChange(false, ii);
                        }
                        // 表示される優先順位の入力欄を減らす
                        setPriorityCount((priorityCount) => priorityCount - 1);
                    }
                    break;
                case "cancel":
                default:
                    break;
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const PriorityInputClear = (num) => {
        // 対象の優先順位の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = priorityColumnList.map(column => `${column}_${num}`);

        // それぞれの入力欄の値を書き換え、空欄にする
        for (let key of keys) {
            setValue(key, null, {shouldValidate: false});
        }
    };

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change" && name === "date") {
                setDateMessage(dateRegisteringErrorMessage(value.date, clientData?.client_birthday, dateList.current));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // 優先順位の入力欄
    const PriorityInputRows = ({priorityNum = 1, servicePersonnelMeeting={priorityFormData}}) =>
        <><div className='form-group mb-4'>
            <label className='fw-bold mb-1' htmlFor={`attendee_affiliation_${priorityNum}`}>
                所属(職種)
                <RequiredMark />
            </label>
            <input
                id={`attendee_affiliation_${priorityNum}`}
                type='text'
                className='form-control mb-1'
                defaultValue={
                    servicePersonnelMeeting?.[`attendee_affiliation_${priorityNum}`]
                }
                {...register(
                    `attendee_affiliation_${priorityNum}`,
                    priorityNum == 1 && {
                        required: {
                            value: true,
                            message: "*所属(職種)を入力してください。"
                        }
                    }
                )}
            />
            {/* react-hook-formのバリデーションによるエラーメッセージ */}
            <small className='text-danger'>
                {errors?.[`attendee_affiliation_${priorityNum}`] &&
                    errors?.[`attendee_affiliation_${priorityNum}`]?.message}
            {/* サーバー側のバリデーションによるエラーメッセージ */}
                {servicePersonnelMeeting.success
                    ? ""
                    : servicePersonnelMeeting.error_list?.[
                            `attendee_affiliation_${priorityNum}`
                        ]}
            </small>
        </div>
        <div className='form-group mb-4'>
            <label className='fw-bold mb-1' htmlFor={`attendee_name_${priorityNum}`}>
                氏名
                <RequiredMark />
            </label>
            <input
                id={`attendee_name_${priorityNum}`}
                type='text'
                className='form-control mb-1'
                defaultValue={servicePersonnelMeeting?.[`attendee_name_${priorityNum}`]}
                {...register(
                    `attendee_name_${priorityNum}`,
                    priorityNum == 1 && {
                        required: {
                            value: true,
                            message: "*氏名を入力してください。"
                        }
                    }
                )}
            />
            {/* react-hook-formのバリデーションによるエラーメッセージ */}
            <small className='text-danger'>
                {errors?.[`attendee_name_${priorityNum}`] &&
                    errors?.[`attendee_name_${priorityNum}`]?.message}
            {/* サーバー側のバリデーションによるエラーメッセージ */}
                {servicePersonnelMeeting.success
                    ? ""
                    : servicePersonnelMeeting.error_list?.[`attendee_name_${priorityNum}`]}
            </small>
        </div></>;

    // 優先順位の入力欄の表示領域
    const PriorityInputAreas = ({inputFormName = "会議出席者", priorityFormData = {}}) => (<><hr />{
        [...Array(priorityCount)].map((_, ii) => {
            ii++;
            return (
                <React.Fragment key={ii}>
                    <div className={classes.priority}>
                        <span className={`fw-bold ${classes.priorityNum}`}>
                            {inputFormName}【{ii}】
                        </span>
                        {ii > 1 && (
                            <button
                                className='btn btn-secondary btn-sm mx-1'
                                type='button'
                                onClick={(e) => {
                                    handleOnClickPriorityChange(e, ii - 1);
                                }}>
                                <span className='fs-6 px-1'>▲</span>並び順を上げる
                            </button>
                        )}
                        {priorityCount !== ii && (
                            <button
                                className='btn btn-secondary btn-sm mx-1'
                                type='button'
                                onClick={(e) => {
                                    handleOnClickPriorityChange(e, ii);
                                }}>
                                並び順を下げる<span className='fs-6 px-1'>▼</span>
                            </button>
                        )}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-primary btn-sm'
                                type='button'
                                onClick={(e) => {
                                    handleOnClickPriorityClear(e, ii);
                                }}>
                                <span className='fw-bold'>×</span>
                            </button>
                        </div>
                    </div>
                    <PriorityInputRows priorityNum={ii} servicePersonnelMeeting={priorityFormData} />
                </React.Fragment>
            );
        })
    }
    {
        priorityCount < MAX_PRIORITY_COUNT && (
            <div className='w-100 mb-5'>
                <hr />
                <div className='text-center'>
                    <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={(e) => {
                            handleOnClickAddInput(e);
                        }}>
                        <span className='fw-bold fs-5 px-1'>+</span>
                        {inputFormName}を追加する
                    </button>
                </div>
            </div>
        )
    }
    <hr />
    </>);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>サービス担当者会議記録編集</h1>
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                    </div>
                    {loaded ? (
                        <div className='card-body'>
                            <p className='fs-6 pt-1 mb-5'>
                                <RequiredMark />
                                <span className='text-secondary'>は必須項目です。</span>
                            </p>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='date'>
                                        開催年月日
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='date'
                                        type='date'
                                        min={clientData?.client_birthday}
                                        max={MAX_DATE}
                                        className='form-control mb-1'
                                        defaultValue={servicePersonnelMeeting?.date}
                                        {...register("date", {
                                            required: {
                                                value: true,
                                                message: "*開催年月日を入力してください。"
                                            }
                                        })}
                                    />
                                    {/* 日付の重複によるエラーメッセージ */}
                                    <small className='text-danger'>{dateMessage}{(dateMessage && (errors.date?.message|| !servicePersonnelMeeting.success
                                            && servicePersonnelMeeting.error_list?.date)) && <br/>}
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    {errors.date && errors.date?.message}
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}{dateMessage && errors.date?.message && !servicePersonnelMeeting.success
                                            && servicePersonnelMeeting.error_list?.date && <br/>}
                                        {!servicePersonnelMeeting.success
                                            && servicePersonnelMeeting.error_list?.date}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='client_name'>
                                        利用者名
                                    </label>
                                    <div className='form-control mb-1'>{servicePersonnelMeeting?.client_name}</div>
                                </div>
                                <div className='form-group mb-5'>
                                    <label className='fw-bold mb-1'>
                                        相談支援専門員氏名
                                        <RequiredMark />
                                    </label>
                                    <Controller
                                        name='user_name'
                                        defaultValue={servicePersonnelMeeting?.user_name}
                                        control={control}
                                        rules={{ required: "*相談支援専門員氏名を選択してください。" }}
                                        render={({ field }) => (
                                            <>
                                                <Select {...field} className='form-control mb-1'>
                                                    {users.map((user) => (
                                                        <MenuItem
                                                            key={user.user_id}
                                                            value={user.user_name}>
                                                            {user.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </>
                                        )}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors?.user_name && errors.user_name?.message}
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.user_name}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='start_time'>
                                        開催開始時間
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='start_time'
                                        type='time'
                                        className='form-control mb-1'
                                        defaultValue={servicePersonnelMeeting?.start_time}
                                        {...register("start_time", {
                                            required: {
                                                value: true,
                                                message: "*開催開始時間を入力してください。"
                                            }
                                        })}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.start_time && errors.start_time?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.start_time}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='end_time'>
                                        開催終了時間
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='end_time'
                                        type='time'
                                        className='form-control mb-1'
                                        defaultValue={servicePersonnelMeeting?.end_time}
                                        {...register("end_time", {
                                            required: {
                                                value: true,
                                                message: "*開催終了時間を入力してください。"
                                            }
                                        })}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>{errors.end_time && errors.end_time?.message}</small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.end_time}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='venue'>
                                        開催場所
                                        <RequiredMark />
                                    </label>
                                    <input
                                        id='venue'
                                        type='text'
                                        className='form-control mb-1'
                                        defaultValue={servicePersonnelMeeting?.venue}
                                        {...register("venue", {
                                            required: {
                                                value: true,
                                                message: "*開催場所を入力してください。"
                                            }
                                        })}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>{errors.venue && errors.venue?.message}</small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.venue}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1'>
                                        区分
                                        <RequiredMark />
                                    </label>
                                    <br />
                                    <Controller
                                        id='division'
                                        name='division'
                                        defaultValue={servicePersonnelMeeting?.division}
                                        control={control}
                                        rules={{ required: "*選択してください。" }}
                                        render={({ field, fieldState }) => (
                                            <RadioGroup {...field} className='mb-4'>
                                                <FormControlLabel
                                                    value='1'
                                                    control={<Radio checked={field.value == 1 ? true : false} />}
                                                    label='サービス利用支援'
                                                />
                                                <FormControlLabel
                                                    value='2'
                                                    control={<Radio checked={field.value == 2 ? true : false} />}
                                                    label='継続サービス利用支援'
                                                />
                                            </RadioGroup>
                                        )}
                                    />
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>{errors.division && errors.division?.message}</small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.division}
                                    </small>
                                </div>
                                <PriorityInputAreas priorityFormData={servicePersonnelMeeting} />
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='considered_item'>
                                        検討した項目
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='considered_item'
                                        className='form-control mb-1'
                                        defaultValue={servicePersonnelMeeting?.considered_item}
                                        {...register("considered_item", {
                                            required: {
                                                value: true,
                                                message: "*検討した項目を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.considered_item && errors.considered_item?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.considered_item}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='considered_detail'>
                                        検討した内容
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='considered_detail'
                                        className='form-control mb-1'
                                        defaultValue={servicePersonnelMeeting?.considered_detail}
                                        {...register("considered_detail", {
                                            required: {
                                                value: true,
                                                message: "*検討した内容を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.considered_detail && errors.considered_detail?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.considered_detail}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='considered_result'>
                                        検討した結果
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='considered_result'
                                        className='form-control mb-1'
                                        defaultValue={servicePersonnelMeeting?.considered_result}
                                        {...register("considered_result", {
                                            required: {
                                                value: true,
                                                message: "*検討した結果を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {errors.considered_result && errors.considered_result?.message}
                                    </small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.considered_result}
                                    </small>
                                </div>
                                <div className='form-group mb-4'>
                                    <label className='fw-bold mb-1' htmlFor='other'>
                                        その他
                                        <RequiredMark />
                                    </label>
                                    <textarea
                                        id='other'
                                        className='form-control mb-1'
                                        defaultValue={servicePersonnelMeeting?.other}
                                        {...register("other", {
                                            required: {
                                                value: true,
                                                message: "*その他を入力してください。"
                                            }
                                        })}></textarea>
                                    {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>{errors.other && errors.other?.message}</small>
                                    {/* サーバー側のバリデーションによるエラーメッセージ */}
                                    <small className='text-danger'>
                                        {servicePersonnelMeeting.success
                                            ? ""
                                            : servicePersonnelMeeting.error_list.other}
                                    </small>
                                </div>
                                <div className='form-group mb-3 d-flex'>
                                    <BackButton />
                                    <Button type='submit' variant='contained' color='primary'>
                                        更新
                                    </Button>
                                </div>
                            </form>
                        </div>
                    ) : (
                        <p>読み込み中です</p>
                    )}
                </div>
            </div>
        </div>
    );
}
