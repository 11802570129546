import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useFormContext } from "react-hook-form";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        priority: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            padding: ".5rem 0",
            marginBottom: "1rem",
            borderBottom: "1px solid #0004"
        },
        priorityNum: { lineHeight: "2rem" },
    })
);

// 入力値の初期状態
const emptyDataList = {success: false};

// 項目数が可変な入力欄
export default function MultipleInputForms({
    maxPriorityCount = 8,
    inputFormName = "会議出席者",
    priorityColumnList = [],
    priorityFormData = emptyDataList,
    PriorityInputRows = ({priorityNum = 1, priorityFormData = emptyDataList}) => (priorityNum, priorityFormData),
    citing = ""}) {
    // 定義したスタイルを利用するための設定
    const classes = useStyles();

    // 優先順位毎の入力欄の表示個数を管理する
    const [priorityCount, setPriorityCount] = useState(1);

    // react-hook-formの使用する機能を継承します
    const {setValue, getValues, formState: { errors }} = useFormContext();

    // 入力フォームを追加する
    const handleOnClickAddInput = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 多重クリックによる誤動作の予防
        if (priorityCount < maxPriorityCount) {
            setPriorityCount(priorityCount + 1);
        }
    };

    // 支援目標の優先順位を入れ替える関数
    const handleOnClickPriorityChange = (e, num = 1) => {
        if (e) {
            // イベントの伝搬を中止
            e.stopPropagation();
        }

        // 優先順位が一つ下がる項目の入力欄のnameにあたる値一覧を作成して格納
        const higherPriority = new Map(priorityColumnList.map(column => ([`${column}`, `${column}_${num}`])));

        // 優先順位が一つ上がる項目の入力欄のnameにあたる値一覧を作成して格納
        const lowerPriority = new Map(priorityColumnList.map(column => ([`${column}`, `${column}_${num + 1}`])));

        // 優先順位が下がる側の入力欄の値
        const reserveValues = new Map(priorityColumnList.map(column => ([`${column}`, getValues(`${column}_${num}`)])));

        // 優先順位が下の入力欄と上の入力欄の値を移し替える
        for (let key of higherPriority.keys()) {
            const newValue = getValues(lowerPriority.get(key));
            setValue(higherPriority.get(key), newValue, {shouldValidate: false});
            setValue(lowerPriority.get(key), reserveValues.get(key), {shouldValidate: false});
        }
    };

    // 各優先順位の右上にある✕ボタン押下時に入力項目を削除したり空欄に戻したりする処理
    const handleOnClickPriorityClear = async (e, num) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // sweetAlertに共通して表示するbutton
        const commonClearButtons = {
            catch: {
                text: "入力内容をクリア",
                value: "clear",
                visible: true,
                className:
                    "btn btn-outline-primary d-block position-absolute end-50 translate-middle text-nowrap lh-lg px-2",
                closeModal: true
            },
            cancel: {
                text: "キャンセル",
                value: "cancel",
                visible: true,
                className: "",
                closeModal: true
            }
        };

        // sweetAlertにて項目を削除して非表示にするbutton
        const deleteValueButton = {
            confirm: {
                text: "削除する",
                value: "delete",
                visible: priorityCount >= 2,
                className: "",
                closeModal: true
            }
        };

        // 優先順位の項目が2つ以上表示されているの場合は削除Buttonとmessageを追加表示する
        const priorityClearButtons = priorityCount >= 2 ?
            {...commonClearButtons, ...deleteValueButton} : commonClearButtons;
        const priorityClearConfirmMessage = (priorityCount >= 2 ? 'この項目を削除して続く優先順位を上に詰める,もしくは' : "") + "この項目に関する入力内容をクリアしますか?";

        await swal({
            icon: "info",
            title: "確認",
            text: priorityClearConfirmMessage,
            buttons: priorityClearButtons
        }).then((value) => {
            switch (value) {
                case "clear":
                    // 対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    break;
                case "delete":
                    // 先ず,対象の優先順位の入力欄を空欄にする
                    PriorityInputClear(num);
                    // 誤動作の予防の為に優先順位の入力項目が2つ以上の場合に実行
                    if (priorityCount >= 2) {
                        // 現在表示されている優先順位の個数分,優先順位を入れ替える関数を順に実行して繰上げを行い
                        // ✕ボタンが押下され,上記の関数によって空欄になった入力欄を最下位に下げる
                        for (let count = num; count < priorityCount; count++) {
                            handleOnClickPriorityChange(false, count);
                        }
                        // 表示される優先順位の入力欄を減らす
                        setPriorityCount(priorityCount - 1);
                    }
                    break;
                case "cancel":
                default:
                    break;
            }
        });
    };

    // 対象の優先順位の入力欄を空欄にする関数
    const PriorityInputClear = (num) => {
        // 対象の優先順位の各入力欄のnameにあたる値の一覧を作成して格納する
        const keys = priorityColumnList.map(column => `${column}_${num}`);

        // それぞれの入力欄の値を書き換えて空欄にする
        for (let key of keys) {
            setValue(key, null, {shouldValidate: false});
        }
    };

    // 引用状況が変わった際に実行する処理
    useEffect(() => {
        // 入力値に以前作成した情報等を引用完了した時
        if (citing === "done") {
            setPriorityCount(getPriorityCount(getValues(), `${priorityColumnList[0]}_`));
        }
    }, [citing]);

    // 複数個存在する項目の数が上下限値を超えたら上下限値に再指定します
    useEffect(() => {
        if (priorityCount < 1) {
            setPriorityCount(1);
        } else if (priorityCount > maxPriorityCount) {
            setPriorityCount(maxPriorityCount);
        }
    }, [priorityCount]);

    // 優先順位の入力欄の表示領域
    const PriorityInputAreas = ({inputFormName = "会議出席者", priorityFormData = {}}) => (<><hr />{
        [...Array(priorityCount)].map((_, count) => {
            count++;
            return (
                <React.Fragment key={count}>
                    <div className={classes.priority}>
                        <span className={`fw-bold ${classes.priorityNum}`}>
                            {inputFormName}【{count}】
                        </span>
                        {count > 1 && (
                            <button
                                className='btn btn-secondary btn-sm mx-1'
                                type='button'
                                onClick={(e) => {
                                    handleOnClickPriorityChange(e, count - 1);
                                }}>
                                <span className='fs-6 px-1'>▲</span>並び順を上げる
                            </button>
                        )}
                        {priorityCount !== count && (
                            <button
                                className='btn btn-secondary btn-sm mx-1'
                                type='button'
                                onClick={(e) => {
                                    handleOnClickPriorityChange(e, count);
                                }}>
                                並び順を下げる<span className='fs-6 px-1'>▼</span>
                            </button>
                        )}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-primary btn-sm'
                                type='button'
                                onClick={(e) => {
                                    handleOnClickPriorityClear(e, count);
                                }}>
                                <span className='fw-bold'>x</span>
                            </button>
                        </div>
                    </div>
                    <PriorityInputRows priorityNum={count} priorityFormData={priorityFormData} />
                </React.Fragment>
            );
        })
    }
        {priorityCount < maxPriorityCount && (
            <div className='w-100 mb-5'>
                <hr />
                <div className='text-center'>
                    <button
                        type='button'
                        className='btn btn-outline-primary'
                        onClick={(e) => {
                            handleOnClickAddInput(e);
                        }}>
                        <span className='fw-bold fs-5 px-1'>+</span>
                        {inputFormName}を追加する
                    </button>
                </div>
            </div>
        )}
        <hr />
    </>);

    return <PriorityInputAreas inputFormName={inputFormName} priorityFormData={priorityFormData} />;
}


// 第1引数に受け取ったデータから優先順位のあるデータの登録数を取得して返します
export const getPriorityCount = (formData = emptyDataList, multipleColumnName = `attendee_affiliation_`, maxPriorityCount = 8) => {
    const priorityCount = Object.keys(formData).filter(columnName =>
        columnName.startsWith(multipleColumnName) && formData[columnName]).length ?? 1;
    return priorityCount <= maxPriorityCount ? priorityCount : maxPriorityCount;
}

// 第1引数に受け取ったデータから優先順位のあるデータの通し番号の最大値を取得して返します
const getMaxPriorityCount = (formData = emptyDataList, multipleColumnName = `attendee_affiliation_`, maxPriorityCount = 8) => {
    const priorityCount = Object.keys(formData).filter(columnName =>
        columnName.startsWith(multipleColumnName) && formData[columnName]).reduce((priority, columnName) => {
            const underBarIndex = columnName.lastIndexOf('_'); // 列名のキーにおけるunder bar'_'の位置
            // 列名からunder barより後の通し番号部分を取り出し数値として取得します
            const priorityNum = parseInt(columnName.slice(underBarIndex + 1, columnName.length));

            return priority < priorityNum ? priorityNum : priority;
        }, 1);

    return priorityCount <= maxPriorityCount ? priorityCount : maxPriorityCount;
}

// 第1引数に受け取ったformDataの複数存在しうるデータの中に値が無い物があればそのデータを省いて続くデータを詰め直して返します
export const leaveOutEmptyPriority = (formData = emptyDataList, priorityColumnList = [], maxPriorityCount = 8) => {
    const priorityCountFormData = getMaxPriorityCount(formData, `${priorityColumnList[0]}_`, maxPriorityCount);

    // 複数個存在するデータを取得した順番に格納する配列
    const multipleData = [];
    for (let count = 1; count <= priorityCountFormData; count++) {
        if (priorityColumnList.some(column => formData[`${column}_${count}`])) {
            const multiRows = {};
            priorityColumnList.forEach(column => {
                multiRows[column] = formData[`${column}_${count}`]
            });
            multipleData.push(multiRows);
        }
    }

    // 複数個存在しうる項目の取得出来た個数が最大数未満だった場合に
    if (multipleData.length < maxPriorityCount) {
        // 複数個ある項目の取得数+1~最大数迄のキーに対応する要素がformDataにあらば逐次削除します
        for (let count = multipleData.length + 1; count <= maxPriorityCount; count++) {
            priorityColumnList.forEach(column => {
                if (`${column}_${count}` in formData) {
                    delete formData[`${column}_${count}`];
                }
            });
        }
    }
    // 複数個存在しうる項目のデータに抜けがあった時に飛ばして詰め直します
    if (multipleData.length < priorityCountFormData) {
        multipleData.map((rowData, index) =>
            Object.keys(rowData).forEach(column =>
                formData[`${column}_${index + 1}`] = rowData[column]
            )
        );
    }

    Object.keys(formData).map((inputID, index) => {
        // formDataからキーは存在すれど中身に値が無い要素を削除します
        if (inputID in formData && !formData[inputID]) {
            delete formData[inputID];
        }
    });

    return formData;
}