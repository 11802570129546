import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
//---------------------------issue{No.72} start-----------------------------
import { useForm, Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";
//---------------------------issue{No.72} end-------------------------------

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShowDialog from "../../components/DataShowDialog";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

const initUser = { success: true };

//---------------------------issue{No.72} start-----------------------------
// 追記
//dialog の初期状態
const initDialog = { open: false, data: [] };
//---------------------------issue{No.72} end-------------------------------

function UserRegister() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    const [user, setUser] = useState(initUser);

    //---------------------------issue{No.72} start-----------------------------
    // 追記
    //authorities の状態を管理する
    const [authorities, setAuthorities] = useState([]);

    //companies の状態を管理する
    const [companies, setCompanies] = useState([]);

    // ドロップダウンリストから選択された会社の法人番号を管理
    const [selectedCompany, setSelectedCompany] = useState();

    //offices の状態を管理する
    const [offices, setOffices] = useState([]);

    // サブミット後、バリデーションを通った時に送られるフォームの入力データを格納する
    const [userInputData, setUserInputData] = useState([]);

    //---------------------------issue{No.97} start-----------------------------
    // 確認用パスワードの検証用のステート
    const [passwordCompare, setPasswordCompare] = useState(false);
    //---------------------------issue{No.97} end-------------------------------

    //dialog の表示状態を管理する
    const [dialog, setDialog] = useState(initDialog);

    // 権限情報を取得しステート authorities にセットする
    const getAuthoritiesData = async () => {
        const url = "system/authorities";
        const res = await api.get(url);
        if (res.data.success) {
            const authorities = res.data.result.map((authority) => {
                return { label: authority.name, value: authority.authority };
            });
            setAuthorities(authorities);
        }
    };

    //会社情報を取得しステート companies にセットする
    const getCompaniesData = async () => {
        const url = "companies";
        const res = await api.get(url);
        if (res.data.success) {
            const companies = res.data.result.map((company) => {
                return { label: company.company_name, value: company.company_id };
            });
            setCompanies(companies);
        }
    };

    //事業所情報を取得しステート offices にセットする
    const getOfficesData = async (company_id) => {
        if (!company_id) {
            return;
        }

        const url = `system/companies/${company_id}/offices`;
        const res = await api.get(url);
        if (res.data.success) {
            const offices = res.data.result.map((office) => {
                return { label: office.office_name, value: office.office_id };
            });
            setOffices(offices);
        }
    };
    //---------------------------issue{No.72} end-------------------------------

    // const handleInput = (e) => {
    //     e.persist();
    //     setUser({ ...user, [e.target.name]: e.target.value });
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    //---------------------------issue{No.72} start-----------------------------
    // 追記
    const registerSubmit = (data) => {
        //---------------------------issue{No.97} start-----------------------------
        // 追記
        if (!passwordCompare) {
            swal({
                icon: "warning",
                title: "",
                text: "パスワードが一致しません。",
            });

            // キャンセル処理
            return;
        }
        //---------------------------issue{No.97} end-------------------------------

        // ダイアログ画面に表示するデータを作成
        const company = companies.find((item) => item.value === data.company_id);
        const office = offices.find((item) => item.value === data.office_id);
        const authority = authorities.find((item) => item.value === data.authority);

        const companyName = company.label;
        const officeName = office.label;
        const authorityName = authority.label;

        const openData = [
            { label: "ユーザーID", value: data.user_id },
            { label: "名前", value: data.user_name },
            { label: "メールアドレス", value: data.email },
            { label: "会社", value: companyName },
            { label: "事業所", value: officeName },
            { label: "権限", value: authorityName },
        ];

        setUserInputData(data);

        //中止できるように、確認ダイアログを表示
        setDialog({ open: true, data: openData });
    };

    //dialog のボタン（アクション）の定義
    const actionClose = () => {
        setDialog(initDialog);
    };
    //---------------------------issue{No.72} end-------------------------------

    const actionPerform = async () => {
        setDialog(initDialog);

        const data = {
            user_id: userInputData.user_id,
            user_name: userInputData.user_name,
            email: userInputData.email,
            password: userInputData.password,
            password_confirmation: userInputData.password_confirmation,
            company_id: userInputData.company_id,
            office_id: userInputData.office_id,
            authority: userInputData.authority,
        };

        //登録
        const url = "users";
        const res = await api.post(url, data);
        if (res.status === 422) {
            setUser({
                ...user,
                error_list: res.data.result,
                success: res.data.success,
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.user_name, "success").then(() => {
                navigate("/users");
            });
        }
    };

    //---------------------------issue{No.72} start-----------------------------
    // 追記
    const actions = [
        { label: "中止", action: actionClose },
        { label: "実行", action: actionPerform },
    ];

    // 画面初期表示時に会社及び権限情報を取得する
    useEffect(() => {
        getCompaniesData();
        getAuthoritiesData();
    }, []);

    // 会社が選択されたとき、もしくは取得した会社情報が一件の場合
    // 事業所情報を取得する
    useEffect(() => {
        if (companies.length === 1) {
            getOfficesData(companies[0].value);
        }
        if (selectedCompany) {
            getOfficesData(selectedCompany);
        }
    }, [companies, selectedCompany]);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        control,
    } = useForm();

    // データ取得後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (companies.length === 1) {
            setValue("company_id", companies[0].value, { shouldValidate: true });
        } else {
            setValue("company_id", "", { shouldValidate: false });
        }
    }, [companies]);

    // データ取得後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (offices.length === 1) {
            setValue("office_id", offices[0].value, { shouldValidate: true });
        } else {
            setValue("office_id", "", { shouldValidate: false });
        }
    }, [offices]);

    // データ取得後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (authorities.length === 1) {
            setValue("authority", authorities[0].value, { shouldValidate: true });
        } else {
            setValue("authority", "", { shouldValidate: false });
        }
    }, [authorities]);

    //---------------------------issue{No.72} end-------------------------------

    //---------------------------issue{No.97} start-----------------------------
    // 入力値の変更に応じで処理を行う
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            // 会社選択時に、ステートを更新する
            if (name === "company_id" && type === "change") {
                setSelectedCompany(value.company_id);
            }
            // 入力されたパスワードの確認
            if (name === "password" || name === "password_confirmation") {
                const inputPassword = value.password;
                const comparePassword = value.password_confirmation;

                if (inputPassword && comparePassword) {
                    if (inputPassword === comparePassword) {
                        setPasswordCompare(true);
                    } else {
                        setPasswordCompare(false);
                    }
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    //---------------------------issue{No.97} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>ユーザー情報登録</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(registerSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='user_id'>
                                    ユーザーID
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>
                                        （6～16文字、半角英数字、ハイフンまたはアンダースコアのみ）
                                    </small>
                                </label>
                                <input
                                    id='user_id'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("user_id", {
                                        required: {
                                            value: true,
                                            message: "*ユーザーIDを入力してください。",
                                        },
                                        minLength: {
                                            value: 6,
                                            message: "*6文字以上で入力してください。",
                                        },
                                        maxLength: {
                                            value: 16,
                                            message: "*16文字以内で入力してください。",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9\-_]+$/,
                                            message:
                                                "*半角英数字、ハイフン(-)またはアンダースコア(_)のみ使用可能です。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_id && errors.user_id?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.user_id}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='user_name'>
                                    名前
                                    <RequiredMark />
                                </label>
                                <input
                                    id='user_name'
                                    type='text'
                                    autoComplete='off'
                                    className='form-control mb-1'
                                    {...register("user_name", {
                                        required: {
                                            value: true,
                                            message: "*名前を入力してください。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.user_name && errors.user_name?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.user_name}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='email'>
                                    メールアドレス
                                    <RequiredMark />
                                </label>
                                <input
                                    id='email'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "*メールアドレスを入力してください",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/,
                                            message: "*メールアドレスが正しいか確認してください",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.email && errors.email?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.email}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='password'>
                                    パスワード
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（6～16文字、半角英数字のみ）</small>
                                </label>
                                <input
                                    id='password'
                                    type='password'
                                    autoComplete='new-password'
                                    className='form-control mb-1'
                                    {...register("password", {
                                        required: {
                                            value: true,
                                            message: "*パスワードを入力してください。",
                                        },
                                        minLength: {
                                            value: 6,
                                            message: "*6文字以上で入力してください。",
                                        },
                                        maxLength: {
                                            value: 16,
                                            message: "*16文字以内で入力してください。",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9]+$/,
                                            message: "*半角英数字のみ使用可能です。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.password && errors.password?.message}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='password_confirmation'>
                                    パスワード確認
                                    <RequiredMark />
                                </label>
                                <input
                                    id='password_confirmation'
                                    type='password'
                                    className='form-control mb-1'
                                    {...register("password_confirmation", {
                                        required: {
                                            value: true,
                                            message: "*パスワード確認のためもう一度入力してください。",
                                        },
                                        minLength: {
                                            value: 6,
                                            message: "*6文字以上で入力してください。",
                                        },
                                        maxLength: {
                                            value: 16,
                                            message: "*16文字以内で入力してください。",
                                        },
                                        pattern: {
                                            value: /^[a-zA-Z0-9]+$/,
                                            message: "*半角英数字のみ使用可能です。",
                                        },
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.password_confirmation && errors.password_confirmation?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.password}</small>
                                <small className='text-danger'>
                                    {user.success ? "" : user.error_list.password_confirmation}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    会社
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='company_id'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*会社を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {Object.keys(companies).length > 0 && (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        選択してください
                                                    </MenuItem>
                                                    {companies.map((item, index) => (
                                                        <MenuItem key={index} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.company_id && errors.company_id?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.company_id}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>
                                    事業所
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='office_id'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*事業所を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {offices && (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    {!selectedCompany ? (
                                                        <MenuItem value='' disabled>
                                                            会社を選択してください
                                                        </MenuItem>
                                                    ) : (
                                                        <MenuItem value='' disabled>
                                                            選択してください
                                                        </MenuItem>
                                                    )}
                                                    {offices.map((item, index) => (
                                                        <MenuItem key={index} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_id && errors.office_id?.message}</small>
                                <small className='text-danger'>{user.success ? "" : user.error_list.office_id}</small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='authority'>
                                    権限
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='authority'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*権限を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <>
                                            {Object.keys(authorities).length > 0 && (
                                                <Select {...field} value={field.value} className='form-control mb-1'>
                                                    <MenuItem value='' disabled>
                                                        選択してください
                                                    </MenuItem>
                                                    {authorities.map((item, index) => (
                                                        <MenuItem key={index} value={item.value}>
                                                            {item.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.authority && errors.authority?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{user.success ? "" : user.error_list.authority}</small>
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    登録
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* issue72追記 */}
            <DataShowDialog
                title='ユーザー情報'
                open={dialog.open}
                data={dialog.data}
                onClose={actionClose}
                onActions={actions}
            />
            {/* 追記ここまで */}
        </div>
    );
}

export default UserRegister;
