import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

function MonitoringDraftDeletedShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のId
    const { client, id } = useParams();

    // 表示するmonitoringDraft の状態を管理する
    const [monitoringDraftShowData, setMonitoringDraftShowData] = useState([]);

    //一覧情報を取得しステート monitoringDraft にセットする
    const getMonitoringDraftData = async (id) => {
        const url = `monitoring_drafts/${id}/deleted`;
        const res = await api.get(url);
        setMonitoringDraftRow(res.data.result);
    };

    // ステートに値をセット
    const setMonitoringDraftRow = (monitoringDraft) => {
        const monitoringDraftDateFormat = dateFormat(monitoringDraft.date, true);
        const monitoringDraftDate = monitoringDraftDateFormat.format_date;

        const time = monitoringDraft.start_time + " ～ " + monitoringDraft.end_time;

        setMonitoringDraftShowData([
            { label: "会社名", value: monitoringDraft.company_name },
            { label: "事業所名", value: monitoringDraft.office_name },
            { label: "担当職員", value: monitoringDraft.user_name },
            { label: "利用者ID", value: monitoringDraft.client_id },
            { label: "利用者氏名", value: monitoringDraft.client_name },
            { label: "実施年月日", value: monitoringDraftDate },
            { label: "実施時間", value: time },
            { label: "実施場所", value: monitoringDraft.location },
            { label: "確認事項", value: monitoringDraft.checklist },
            { label: "提供支援", value: monitoringDraft.support },
            { label: "利用者の様子", value: monitoringDraft.client_state },
            { label: "その他", value: monitoringDraft.supplement },
        ]);
    };

    // 閉じるボタンをクリックしたら、【削除済み】モニタリング記録一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/monitoring_draft_deleted/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getMonitoringDraftData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】モニタリング記録情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={monitoringDraftShowData}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MonitoringDraftDeletedShow;
