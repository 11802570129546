import React, { useState } from "react";
import { TableRow, TableCell, Select, MenuItem } from "@material-ui/core";

const months = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
const frequency_list = [
    { value: "01", label: "１ヶ月毎" },
    { value: "02", label: "２ヶ月毎" },
    { value: "03", label: "３ヶ月毎" },
    { value: "04", label: "４ヶ月毎" },
    { value: "05", label: "５ヶ月毎" },
    { value: "06", label: "半年毎" },
    { value: "12", label: "１年毎" }
];

// 予定表部分の表示用データ作成
export default function ScheduleReferenceMonthEditRow(props) {
    const { client, postData, dispatch } = props;
    const [displayedData, setDisplayedData] = useState(client);

    const handleChangeSelect = (e) => {
        const name = e.target.name;
        const val = e.target.value === "" ? null : e.target.value;
        const latestData = { ...displayedData, [name]: val }; // 初期状態との比較用（stateが瞬時に更新されないため）
        setDisplayedData(latestData);
        const postData = {
            id: client.id,
            reference_month: latestData.reference_month,
            frequency: latestData.frequency
        };
        const dispatchName = decideDispatchName(latestData);
        dispatch({ type: dispatchName, data: postData });
    };

    // 初期値と更新値の値を比較
    // 全て同じ場合は更新対象から除外、一部でも異なる場合は更新対象に追加
    const decideDispatchName = (data) => {
        const isSameReferenceMonth = data.reference_month === client.reference_month;
        const isSameFrequency = data.frequency === client.frequency;
        const isSameAll = isSameReferenceMonth && isSameFrequency;

        // 初期状態（client）と等しい場合はpostDataから削除
        if (isSameAll) {
            return "removePostData";
        }

        // postDataにデータが存在する場合は更新、存在しない場合は追加
        const isExist = checkExist(data);
        if (isExist) {
            return "updatePostData";
        } else {
            return "addPostData";
        }
    };

    // postDataにデータに対象利用者が存在するか確認
    const checkExist = (data) => {
        const arr = postData.filter((post) => {
            return post.id === data.id;
        });

        return arr.length > 0;
    };

    return (
        <TableRow>
            <TableCell>
                {displayedData.child_name === null ? displayedData.client_name : displayedData.child_name}
            </TableCell>
            <TableCell>{displayedData.start_month}</TableCell>
            <TableCell>
                <Select
                    name='reference_month'
                    value={displayedData.reference_month === null ? "" : displayedData.reference_month}
                    onChange={(e) => handleChangeSelect(e)}>
                    <MenuItem value=''>-</MenuItem>
                    {months.map((month, monthIndex) => (
                        <MenuItem key='monthIndex' value={monthIndex}>
                            {month}月
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell>
                <Select
                    name='frequency'
                    value={displayedData.frequency === null ? "" : displayedData.frequency}
                    onChange={(e) => handleChangeSelect(e)}>
                    <MenuItem value=''>-</MenuItem>
                    {frequency_list.map((frequency, frequencyIndex) => (
                        <MenuItem key={frequencyIndex} value={frequency.value}>
                            {frequency.label}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
        </TableRow>
    );
}
