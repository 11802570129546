import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import OutputWordButton from "../../components/OutputWordButton";
import dateFormat from "../../common/dateFormat";
import separateZipCodeByHyphen from "../../common/separateZipCodeByHyphen";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

// 表示用の各データに応じた値を配列で用意
const division_list = { 1: "新規", 2: "変更" };

function ConsultationRequestShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のidと、相談支援依頼のid
    const { client, id } = useParams();

    const page_name = "相談支援依頼";

    // 表示するconsultationRequest の状態を管理する
    const [consultationRequestShowData, setConsultationRequestShowData] = useState([]);
    // downloadするWord file用の値を管理する
    const [consultationRequestInputTexts, setConsultationRequestInputTexts] = useState({});

    //情報を取得しステート consultationRequest にセットする
    const getConsultationRequestData = async (id) => {
        const url = `consultation_requests/show/${id}`;
        const res = await api.get(url);
        setConsultationRequestRow(res.data.result);
    };

    // ステートに値をセット
    const setConsultationRequestRow = (consultationRequest) => {
        const consultationRequestDateFormat = dateFormat(consultationRequest.date, true);
        const consultationRequestDate = consultationRequestDateFormat.format_date;

        const clientBirthdayFormat = dateFormat(consultationRequest.client_birthday, true);
        const clientBirthday = clientBirthdayFormat.format_date;

        const childBirthdayFormat = dateFormat(consultationRequest.child_birthday, true);
        const childBirthday = childBirthdayFormat.format_date;

        const division = division_list[consultationRequest.division];

        const changeDateFormat = dateFormat(consultationRequest.change_date, true);
        const changeDate = changeDateFormat.format_date;

        const clientZipCode = separateZipCodeByHyphen(consultationRequest.client_zip_code);
        const officeZipCode = separateZipCodeByHyphen(consultationRequest.office_zip_code);

        setConsultationRequestShowData([
            { label: "会社", value: consultationRequest.company_name },
            { label: "事業所", value: consultationRequest.offices_office_name },
            { label: "担当職員", value: consultationRequest.user_name },
            { label: "利用者ID", value: consultationRequest.client_id },
            { label: "届出先", value: consultationRequest.submission },
            { label: "届出年月日", value: consultationRequestDate },
            { label: "新規/変更区分", value: division },
            { label: "利用者フリガナ", value: consultationRequest.client_ruby },
            { label: "利用者氏名", value: consultationRequest.client_name },
            { label: "利用者生年月日", value: clientBirthday },
            { label: "利用者郵便番号", value: clientZipCode},
            { label: "利用者住所", value: consultationRequest.client_address },
            { label: "利用者電話番号", value: consultationRequest.client_tel },
            { label: "児童フリガナ", value: consultationRequest.child_ruby },
            { label: "児童氏名", value: consultationRequest.child_name },
            { label: "児童生年月日", value: childBirthday },
            { label: "児童続柄", value: consultationRequest.relationship },
            { label: "事業所名（カナ）", value: consultationRequest.office_ruby },
            { label: "事業所名", value: consultationRequest.office_name },
            {label: "事業所郵便番号", value: officeZipCode},
            { label: "事業所住所", value: consultationRequest.office_address },
            { label: "事業所電話番号", value: consultationRequest.office_tel },
            { label: "理由", value: consultationRequest.reason },
            { label: "変更年月日", value: changeDate },
        ]);
        setConsultationRequestInputTexts({
            ...consultationRequest,
            notification_date: consultationRequestDate,
            child_birthday:    childBirthday,
            client_birthday:   clientBirthday,
            client_zip_code:   clientZipCode,
            office_zip_code:   officeZipCode,
            change_date:       changeDate
        });
    };

    // 閉じるボタンをクリックしたら、相談支援依頼一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();

        navigate(`/consultation_request/${client}`);
    };

    //画面初期表示時に情報を取得する
    useEffect(() => {
        getConsultationRequestData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>{page_name}情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        {Object.keys(consultationRequestInputTexts).length > 0 &&
                            OutputWordButton("consultation_request.docx", page_name, consultationRequestInputTexts)
                        }
                        <DataShow data={consultationRequestShowData}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConsultationRequestShow;
