import React, { useEffect, useRef, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Box, Select, MenuItem, Chip } from "@material-ui/core";

import { api } from "../../api/api";
import FreeWordSearch from "../../components/FreeWordSearch";
import ReportDataTableRow from "./ReportDataTableRow";
import NewOutputExcelButton from "../../components/NewOutputExcelButton";

const useStyles = makeStyles((theme) =>
    createStyles({
        table: {
            minWidth: 650
        },
        tableHead: {
            // backgroundColor: purple["A100"],
        },
        tableCell: {
            padding: 3
        },
        chips: {
            display: "flex",
            flexWrap: "wrap"
        },
        chip: {
            margin: 2
        }
    })
);

// 初期値を設定
const initClientReportsAsc = { result: [], success: true };
const initClientReportsDesc = { result: [], success: true };

const taskList = { 1: "面談", 2: "モニタリング", 3: "電話対応", 4: "連絡事項" };

// フリーワード検索の対象カラム
const columns = ["date", "start_time", "end_time", "detail"];

// 日付をyyyy-mm-ddの形式にする
function dayFormat(date) {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + "-" + m + "-" + d;
}
// 日付を取得
const today = dayFormat(new Date());

export default function ReportDataTable(props) {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    // 選択されたクライアントのidを分割代入
    const { selectedClient = "", clientName = "" } = props;

    const [clientReportsAsc, setClientReportsAsc] = useState(initClientReportsAsc);
    const clientReportsAscRef = useRef([]);
    const [clientReportsDesc, setClientReportsDesc] = useState(initClientReportsDesc);
    const clientReportsDescRef = useRef([]);

    const [searchedClientReports, setSearchedClientReports] = useState([]);

    const [orderByAsc, setOrderByAsc] = useState(false);

    const [selectedTask, setSelectedTask] = useState([]);

    const [oldestDate, setOldestDate] = useState("");
    const oldestDateRef = useRef("");

    const [latestDate, setLatestDate] = useState("");

    const [isActive, setIsActive] = useState(true); // 編集中の行以外の有効フラグ

    // ファイル名関係
    const pageName = "支援記録一覧";
    const [fileOldestDate, setFileOldestDate] = useState("");
    const [fileLatestDate, setFileLatestDate] = useState("");
    const [urlOldestDate, setUrlOldestDate] = useState("");
    const [urlLatestDate, setUrlLatestDate] = useState("");
    const [isDesc, setIsDesc] = useState(1);

    // 日報を取得しステート Report にセットする
    const getClientReportsAsc = async (id) => {
        const url = `reports/client_reports/asc/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setClientReportsAsc(res.data);
            clientReportsAscRef.current = res.data.result;

            oldestDateRef.current = res.data.result[0].date;

            const date = res.data.result[0].date.replace(/-/g, "");
            setFileOldestDate(date);
            setUrlOldestDate(res.data.result[0].date);
        }
    };

    // 日報を取得しステート Report にセットする
    const getClientReportsDesc = async (id) => {
        const url = `reports/client_reports/desc/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setClientReportsDesc(res.data);
            clientReportsDescRef.current = res.data.result;

            const date = res.data.result[0].date.replace(/-/g, "");
            setFileLatestDate(date);
            setUrlLatestDate(res.data.result[0].date);
        }
    };

    const handleOnChangeStartDate = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        const date = e.target.value;
        if (date) {
            setOldestDate(date);
            const formattedDate = date.replace(/-/g, "");
            setFileOldestDate(formattedDate);
            setUrlOldestDate(date);
        } else {
            const formattedDate = date.replace(/-/g, "");
            setFileOldestDate(formattedDate);
            setOldestDate(clientReportsAsc.result[0].date);
            setUrlOldestDate(clientReportsAsc.result[0].date);
        }
    };

    const handleOnChangeEndDate = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        const date = e.target.value;
        if (date) {
            setLatestDate(date);
            const formattedDate = date.replace(/-/g, "");
            setFileLatestDate(formattedDate);
            setUrlLatestDate(date);
        } else {
            setLatestDate("");
            setUrlLatestDate(latestDate);
        }
    };

    const handleOnChangeOrderBy = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setOrderByAsc(!orderByAsc);
    };

    const handleOnTaskSelect = (e, value) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (selectedTask.includes(value) === true) {
            setSelectedTask(selectedTask.filter((selectedTask) => selectedTask !== value));
        } else {
            setSelectedTask([...selectedTask, value]);
        }
    };

    // 削除
    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "データを削除しますか？",
            buttons: ["キャンセル", "実行"]
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        //物理削除
        const url = `reports/${id}`;
        const res = await api.delete(url);
        // 成否の確認
        if (res.data.success) {
            // 削除成功時
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除したレコードを除外した全日報を配列化
                const newAscReports = clientReportsAsc.result.filter((report) => report.id !== id);
                const newDescReports = clientReportsDesc.result.filter((report) => report.id !== id);
                // ステートを更新
                setClientReportsAsc({ result: newAscReports, success: true });
                setClientReportsDesc({ result: newDescReports, success: true });
            });
        }
    };

    useEffect(() => {
        getClientReportsAsc(selectedClient);
        getClientReportsDesc(selectedClient);
    }, []);

    useEffect(() => {
        const oldestLimit = oldestDate !== "" ? oldestDate : oldestDateRef.current;
        const latestLimit = latestDate !== "" ? latestDate : today;

        const newAscData = clientReportsAscRef.current.filter(
            (item) => oldestLimit <= item.date && latestLimit >= item.date
        );
        const newDescData = clientReportsDescRef.current.filter(
            (item) => oldestLimit <= item.date && latestLimit >= item.date
        );

        setClientReportsAsc({ ...clientReportsAsc, result: newAscData });
        setClientReportsDesc({ ...clientReportsDesc, result: newDescData });
    }, [oldestDate, latestDate]);

    useEffect(() => {
        if (orderByAsc) {
            setIsDesc(0);
        } else {
            setIsDesc(1);
        }
    }, [orderByAsc]);

    return (
        <div>
            <FreeWordSearch
                searchColumns={columns}
                data={orderByAsc ? clientReportsAsc.result : clientReportsDesc.result}
                setSearchedData={setSearchedClientReports}
            />
            <Box
                className='d-flex btn btn-light btn-sm align-items-center'
                sx={{ width: "max-content", m: "1rem auto" }}>
                <Box sx={{ display: "flex", mr: "2rem" }}>
                    <input
                        id='start_date'
                        className='form-control'
                        type='date'
                        name='start_date'
                        min={oldestDateRef.current}
                        max={latestDate ? latestDate : today}
                        defaultValue={oldestDateRef.current}
                        onChange={(e) => handleOnChangeStartDate(e)}
                    />
                    <span className='d-flex align-items-center mx-3'>～</span>
                    <input
                        id='end_date'
                        className='form-control'
                        type='date'
                        name='end_date'
                        min={oldestDateRef.current}
                        max={today}
                        defaultValue={today}
                        onChange={(e) => handleOnChangeEndDate(e)}
                    />
                </Box>
                <input
                    id='task_only_checkbox'
                    type='checkbox'
                    name='task_only_checkbox'
                    onChange={(e) => handleOnChangeOrderBy(e)}
                />
                <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
                    <label htmlFor='task_only_checkbox'>古い記録から順に表示する</label>
                </Box>
                {fileOldestDate && fileLatestDate && urlOldestDate && urlLatestDate && (
                    <Box className='ms-5 mt-3'>
                        <NewOutputExcelButton
                            fileName={`${fileOldestDate}-${fileLatestDate}_${clientName}_${pageName}`}
                            api_url={`reports/${selectedClient}/${urlOldestDate}/${urlLatestDate}/${isDesc}/export`}
                            buttonTextIsFileName={false}
                        />
                    </Box>
                )}
            </Box>
            <Box sx={{ width: "max-content", mx: "auto", mb: "2rem" }}>
                <div className={classes.chips}>
                    {Object.keys(taskList).map((value, index) => {
                        const numValue = Number(value);
                        return (
                            <Chip
                                key={index}
                                className={classes.chip}
                                label={taskList[numValue]}
                                clickable
                                color={selectedTask.includes(numValue) === true ? "primary" : "default"}
                                onClick={(e) => {
                                    handleOnTaskSelect(e, numValue);
                                }}
                            />
                        );
                    })}
                </div>
            </Box>
            <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='simple table'>
                    {/* ヘッダー部分 */}
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell align='center'>日付</TableCell>
                            <TableCell align='center'>時間</TableCell>
                            <TableCell align='center'>分類</TableCell>
                            <TableCell align='center' className='w-50'>
                                内容
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {/* ボディ部分 */}
                    <TableBody>
                        {Object.keys(searchedClientReports).length > 0 &&
                            searchedClientReports.map((item, index) => {
                                if (selectedTask.length === 0 || selectedTask.includes(item["task"]) === true) {
                                    return (
                                        <ReportDataTableRow
                                            key={index}
                                            id={item.id}
                                            date={item.date}
                                            startTime={item.start_time}
                                            endTime={item.end_time}
                                            task={item.task}
                                            detail={item.detail}
                                            handleOnClickDelete={handleOnClickDelete}
                                            getClientReportsAsc={getClientReportsAsc}
                                            getClientReportsDesc={getClientReportsDesc}
                                            selectedClient={selectedClient}
                                            isActive={isActive}
                                            setIsActive={setIsActive}
                                        />
                                    );
                                }
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}
