import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useFormContext } from "react-hook-form";
import { Button, Tooltip } from "@material-ui/core";

// 初期状態
const emptyDataList = { result: [], success: false };

// 以前作成した情報を受け取って呼び出した画面の入力フォームに引用します
export const CiteButton = ({
    pageName = "サービス担当者会議記録",
    getRecentlyCreated = async (getAPI) => await getAPI,
    rejectKeys = [],
    setCite = (conditionStr = "") => conditionStr,
}) => {
    // inputの内で引数で受け取った排除対象のキー名に加えて利用者情報に由来する物や日付情報を指す
    // 引用させないキーを宣言します
    const omitKeys = rejectKeys.concat([
        "id",
        "company_id",
        "company_name",
        "office_id",
        "office_name",
        "client_id",
        "client_name",
        "date",
        "created_at",
        "updated_at",
        "deleted_at",
    ]);

    // react-hook-formの使用する機能を継承します
    const { setValue, clearErrors } = useFormContext({});
    // 引用の状況を管理します
    const [citing, setCiting] = useState("");
    // 引用する情報を格納します
    const [formData, setFormData] = useState(emptyDataList);

    // 画面初期表示時に情報を取得する
    useEffect(async () => {
        if (citing === "") {
            const recentlyData = await getRecentlyCreated();
            if (Object.keys(recentlyData).length > 10) {
                // 引用の準備を完了する
                setCiting("ready");
                setFormData(recentlyData);
            }
        }
    }, [citing]);

    // 引数で受け取った情報を引用します
    const citeReceivedData = (data) => {
        // 引用しないキーを省きます
        const columnsDBTable = Object.keys(data).filter((key) => !omitKeys.includes(key));

        if (columnsDBTable.length > 1) {
            // 全てのエラーを削除
            clearErrors();
            for (let key of columnsDBTable) {
                if (data[key]) {
                    setValue(key, data[key], { shouldValidate: false });
                }
            }
            // 実行済みとしてステートを書き換える
            setCiting("done");
            setCite("done");
        }
    };

    // ボタンクリック時に以前作成した時の内容をインプット内に引用します
    const handleOnClickCitingLastData = async (e, data = {}) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "入力されている内容は上書きされる場合があります。続けますか?",
            buttons: true,
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }
        citeReceivedData(data);
    };

    const citeCondition = () => {
        switch (citing) {
            case "ready":
                return (
                    <Tooltip
                        title={`直近の${pageName}(${formData?.date})の内容を以下の入力欄に引用します`}
                        placement='bottom'
                        arrow>
                        <Button
                            className='mb-3 mt-2'
                            type='button'
                            variant='contained'
                            color='primary'
                            onClick={(e) => handleOnClickCitingLastData(e, formData)}>
                            前回作成した{pageName}の内容を引用する
                            <br />
                            (※利用者の情報は対象外)
                        </Button>
                    </Tooltip>
                );
                break;
            case "done":
                return (
                    <div className='d-block btn btn-outline-secondary mt-3' disabled>
                        {pageName}({formData?.date})の内容を引用済み
                    </div>
                );
                break;
            case "":
            default:
                return;
                break;
        }
    };

    return <div className='w-50 ps-3'>{citeCondition()}</div>;
};

export default CiteButton;
