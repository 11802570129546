import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Box } from "@material-ui/core";

import { api } from "../api/api";
import getApiUrlFromTargetUrl from "../common/getApiUrlFromTargetUrl";

import ClientSelect from "./ClientSelect";
import ClientSelectShowLinkInPageTitle from "./ClientSelectShowLinkInPageTitle";
import DataTable from "./DataTable";
import DateSearch from "./DateSearch";
import PageCardHeaderAndFooter from "./PageCardHeaderAndFooter";
import PageOutline from "./PageOutline";

// 週間計画表画面への遷移ボタン
const EnclosureButton = ({ rowData = {}, enclosureURL = "monitoring_2", enclosureName = "週間計画表" }) => {
    // 画面遷移用
    const navigate = useNavigate();

    // 対応する週間計画表画面へ遷移します
    const handleOnClickEnclosure = (e, rowData = {}, url = "monitoring_2") => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (rowData?.id && rowData?.client_id) {
            const showParam = `${rowData?.client_id}/${rowData?.id}`;
            // URL(/${url}/show/${clientId}/${id})を叩く(RouterConfig.js)
            navigate(`/${url}/show/${showParam}`);
        }
    };

    return (
        <Button variant='contained' color='default' onClick={(e) => handleOnClickEnclosure(e, rowData, enclosureURL)}>
            {enclosureName}を見る
        </Button>
    );
};

// データ行の連想配列に週間計画表に遷移するボタンを追加する際のフォーマット
// 他のボタンや機能を行中に追加したい場合に参考にして下さい
export const rowEnclosureButton = (rowData = {}, enclosureURL = "monitoring_2", enclosureName = "週間計画表") => ({
    enclosureBtn: <EnclosureButton rowData={rowData} enclosureURL={enclosureURL} enclosureName={enclosureName} />,
});

// 見出しボタン部分の配列定義
const headerButtons = ["編集", "削除"];

// 初期状態
const emptyDataList = { result: [], success: false };

// 日付をyyyymmddの数値にする
const dayFormat = (date) => {
    const y = date.getFullYear();
    const m = ("0" + (date.getMonth() + 1)).slice(-2);
    const d = ("0" + date.getDate()).slice(-2);
    return y + m + d;
};
// 画面を表示した時点での日付を数値として取得
const numToday = Number(dayFormat(new Date()));

/**
 * 利用者を選択しその利用者に紐付く情報を一覧表示する画面
 * @param {string} pageName 目的の情報名:画面上部の見出しに表示します
 * @param {string} targetURL 目的の情報に対応するURL文字列
 * (api通信時は'_'の手前若しくは末尾に's'を付けるので付いてないURLを渡して下さい)
 * @param {Array} headerDataList 情報一覧の見出し用の配列
 * @param {*} clientID 表示対象の利用者のID
 * @param {Boolean} accessibleDeleted 削除済み情報一覧画面への遷移の可否
 * @param {Object} rowItems データ行の連想配列に追加したい項目とbutton等の持つ処理
 * 情報一覧画面に追加表示したい項目があらば設定して下さい
 * @returns 利用者選択画面又は既に利用者が選択済みの場合はその利用者に紐付く情報一覧画面を表示します
 */
export default function ClientSelectToDataList({
    pageName = "モニタリング記録",
    targetURL = "monitoring_draft",
    headerDataList = ["実施年月日"],
    clientID = "",
    accessibleDeleted = false,
    rowItems = (rowData) => rowData,
}) {
    // 画面遷移用
    const navigate = useNavigate();
    const location = useLocation();

    // 選択された利用者のidを格納するステート
    const [selectedClient, setSelectedClient] = useState(clientID);

    // 選択された利用者に紐付く情報を管理するステート
    const [targetDataPerClient, setTargetDataPerClient] = useState(emptyDataList);

    // 検索された情報一覧を保存するステート
    const [searchedDataPerClient, setSearchedDataPerClient] = useState([]);

    // 引数に渡された目的の情報を指すtargetURLからapi通信用のURLを生成します
    const apiURL = getApiUrlFromTargetUrl(targetURL);

    // api通信でDBから選択された利用者に紐付く情報を取得しステート targetDataPerClient にセットする
    const getTargetData = async (clientId) => {
        const res = await api.get(`${apiURL}/${clientId}`);

        if (res.data.success) {
            setTargetDataPerClient(res.data);
        }
    };

    // 新規登録ボタンをクリックすると目的のデータの新規登録画面に遷移します
    const handleOnClickRegister = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();

        // URL(${targetURL}_register)を叩く(RouterConfig.js)
        navigate(`/${targetURL}_register/${selectedClient}/${recentlyCreatedID}`);
    };

    // 削除済み一覧ボタンクリック時
    const handleOnClickDeleted = (e) => {
        if (accessibleDeleted) {
            // イベントの伝搬を中止
            e.preventDefault();

            // URL(${targetURL}_deleted)をパラメータ付きでたたく(RouterConfig.js)
            navigate(`/${targetURL}_deleted/${selectedClient}`);
        }
    };

    // 一覧の行のどこかをクリックするとその行に対応する要素の情報表示画面へ遷移します
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行のid(key)と一致するtargetDataPerClientステートのidを変数に代入
        const targetData = targetDataPerClient.result.find((item) => item.id === id);
        // 値が存在する(0, "", undefined, nullでない)場合
        if (targetData) {
            // URL(/${targetURL}/show/${selectedClient}/${id})を叩く(RouterConfig.js)
            navigate(`/${targetURL}/show/${selectedClient}/${id}`);
        }
    };

    // 一覧の編集ボタンをクリックすると情報編集画面へ遷移します
    const handleOnClickEdit = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // URL(/${targetURL}/edit/${selectedClient}/${id})を叩く(RouterConfig.js)
        navigate(`/${targetURL}/edit/${selectedClient}/${id}`);
    };

    // 戻るボタンがクリックされた時
    // 選択済み利用者及び利用者に紐付く情報を初期値にリセットし
    // レンダリングされるコンポーネントを書き換える
    const initializeSelectedClient = () => {
        setTargetDataPerClient(emptyDataList);
        setSearchedDataPerClient([]);
        setSelectedClient("");
    };

    // 一覧の削除ボタンクリック時に対象レコードを削除する
    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "削除しますか?",
            buttons: ["キャンセル", "実行"],
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        // 削除
        const res = await api.delete(`${apiURL}/${id}`);
        // 成否の確認
        if (res.data.success) {
            // 削除成功時はモーダルウィンドウで削除成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除したデータを除外した全データを配列化
                const remainingData = targetDataPerClient.result.filter((item) => item.id !== id);
                // ステートを更新
                setTargetDataPerClient({ result: remainingData, success: true });
            });
        } else {
            // 削除失敗時にエラーに応じた処理を行う
            processErrorResponse(res, () => {}, navigate);
        }
    };

    // 前回作成したデータのIDを取得する副作用フック
    const recentlyCreatedID = useMemo(() => {
        if (targetDataPerClient.result.length > 0) {
            let recentlyDataID = "";
            // 直近の日付が対象のデータのIDを捜索してrecentlyDataIDに格納します
            targetDataPerClient.result.reduce(function (recentlyDate, data) {
                // データの持つ日付を数値変換
                const numericDate = Number(data.date.replace(/[-]/g, ""));
                if (numericDate > recentlyDate && numericDate <= numToday) {
                    recentlyDataID = data.id;
                    return numericDate;
                } else {
                    return recentlyDate;
                }
            }, 0);
            return recentlyDataID;
        } else {
            // 削除された事でデータが0件となった時の処理
            return "";
        }
    }, [targetDataPerClient]);

    // targetDataPerClient に応じて画面表示用の配列を生成
    const rows = useMemo(() => {
        if (
            !searchedDataPerClient ||
            searchedDataPerClient.length < 1 ||
            !targetDataPerClient.success ||
            targetDataPerClient.result.length < 1
        ) {
            return [];
        }

        // 取得したデータに対応する行を逐次生成します
        const newRows = searchedDataPerClient.map((data) => ({
            id: data.id,
            isActive: !data?.deleted_at,
            data: {
                date: data.date,
                // 画面毎の独自項目rowItemsを引数から受け取れていた場合は行中に追加します
                ...(rowItems() ? rowItems(data) : null),
                editBtn: (
                    <Button variant='contained' color='primary' onClick={(e) => handleOnClickEdit(e, data.id)}>
                        編集
                    </Button>
                ),
                deleteBtn: (
                    <Button variant='contained' onClick={(e) => handleOnClickDelete(e, data.id)}>
                        削除
                    </Button>
                ),
            },
        }));
        return newRows;
    }, [searchedDataPerClient]);

    // 選択済み利用者の状態に応じて,利用者に紐付くデータを取得
    useEffect(() => {
        if (selectedClient) {
            getTargetData(selectedClient);

            // もし,URLにclientパラメータが含まれていない場合に,アドレスバーを書き替える
            if (!clientID) {
                const url = `${location.pathname}/${selectedClient}`;
                history.pushState(null, null, url);
            }
        }
    }, [selectedClient]);

    // 一覧から利用者を選択する画面
    const clientSelector = (
        <PageCardHeaderAndFooter pageName={pageName}>
            <ClientSelect setSelectedClient={setSelectedClient} />
        </PageCardHeaderAndFooter>
    );

    // 利用者に紐付く情報テーブルの上に表示したい要素
    const aboveDataTable = (
        <>
            {targetDataPerClient.success && selectedClient && (
                <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                    <Button variant='contained' color='primary' onClick={(e) => handleOnClickRegister(e)}>
                        新規作成
                    </Button>
                    {accessibleDeleted && (
                        <Box sx={{ width: "max-content", ml: 2 }}>
                            <Button variant='contained' onClick={(e) => handleOnClickDeleted(e)}>
                                削除済み一覧
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
            <DateSearch data={targetDataPerClient.result} setSearchedData={setSearchedDataPerClient} />
        </>
    );

    // 目的の情報を取得後に表示する情報一覧画面
    const targetDataList = (
        <PageCardHeaderAndFooter
            pageName={<ClientSelectShowLinkInPageTitle pageName={pageName} clientID={selectedClient} />}
            closePath={`/${targetURL}`}
            onClickClose={() => initializeSelectedClient()}>
            {aboveDataTable}
            {/* テーブル部分の定義 */}
            <DataTable
                headers={headerDataList.concat(headerButtons)}
                rows={rows}
                handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
            />
        </PageCardHeaderAndFooter>
    );

    return <PageOutline>{!clientID && !selectedClient ? clientSelector : targetDataList}</PageOutline>;
}
