import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

import { Button } from "@material-ui/core";

import { api } from "../api/api";
import { UserContext } from "../providers/UserProvider";

function Login() {
    //画面遷移用
    const navigate = useNavigate();

    //ログイン情報を保持する
    const { userInfo, setUserInfo } = useContext(UserContext);

    //入力値を保持する
    const [loginInput, setLogin] = useState({
        user_id: "",
        password: "",
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    };

    const loginSubmit = async (e) => {
        e.preventDefault();

        const data = {
            user_id: loginInput.user_id,
            password: loginInput.password,
        };

        await api.get("/sanctum/csrf-cookie");

        const res = await api.post("/login", data);
        if (res.data.success) {
            setUserInfo({ isLogin: true, user: res.data.result });
            navigate("/home");
        } else {
            swal(res.statusText, loginInput.user_id, "error").then(() => {});
        }
    };

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header'>
                        <h4>Login</h4>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={loginSubmit}>
                            <div className='form-group mb-3'>
                                <label>ユーザーID</label>
                                <input
                                    type='text'
                                    name='user_id'
                                    onChange={handleInput}
                                    value={loginInput.user_id}
                                    className='form-control'
                                />
                                <span>{loginInput.success ? "" : loginInput.error_list.user_id}</span>
                            </div>
                            <div className='form-group mb-3'>
                                <label>Password</label>
                                <input
                                    type='password'
                                    name='password'
                                    onChange={handleInput}
                                    value={loginInput.password}
                                    className='form-control'
                                />
                                <span>{loginInput.success ? "" : loginInput.error_list.password}</span>
                            </div>
                            <div className='form-group mb-3'>
                                <Button type='submit' variant='contained'>
                                    ログイン
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
