import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDataList, {rowEnclosureButton} from "../../components/ClientSelectToDataList";

// 見出しの配列定義
const headers = ["作成日", "現在の生活"];

export default function Assessment_1() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDataList
        pageName='アセスメント'
        targetURL='assessment_1'
        headerDataList={headers}
        clientID={client}
        accessibleDeleted={true}
        rowItems={rowData => rowEnclosureButton(rowData, 'assessment_2', '現在の生活')} />;
}