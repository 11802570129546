import React, { useState, useEffect, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller, FormProvider  } from "react-hook-form";
import {
    Button,
    Select,
    MenuItem,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@material-ui/core";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";
import CiteButton from "../../components/CiteButton";
import HeaderPageTitle from "../../components/HeaderPageTitle";
import InputValidationErrorMessageArea from "../../components/InputValidationErrorMessageArea";
import MultipleInputForms, {leaveOutEmptyPriority} from "../../components/MultipleInputForms";
import TextInputForm from "../../components/TextInputForm";
import dateRegisteringErrorMessage from "../../common/dateRegisteringErrorMessage";
import displayHyphenatedDate from "../../common/displayHyphenatedDate";

import { SERVICE_PERSONNEL_MEETING_PAGE_NAME, SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES, SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COUNT, getServicePersonnelMeeting, ServicePersonnelMeetingPriorityInputRows } from "./ServicePersonnelMeetingCommon";

// yyyy-mm-dd形式の日付を取得
const today = displayHyphenatedDate(new Date());

// 最大値に使う年月日を定数に格納
const MAX_DATE = displayHyphenatedDate(new Date(), 1);

// 優先順位のある入力欄の最大表示個数
const MAX_PRIORITY_COUNT = SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COUNT; // 8;

// サービス担当者会議記録登録画面
export default function ServicePersonnelMeetingRegister() {
    // 画面遷移用
    const navigate = useNavigate();

    // 利用者のidと前回作成したデータのidを保持している
    const { client, id } = useParams();
    // 移動先pageのaddress
    const backLink = `/service_personnel_meeting/${client}`;
    // 入力値を管理する
    const [servicePersonnelMeeting, setServicePersonnelMeeting] = useState({ success: true });

    // 引用に関して
    const [citing, setCiting] = useState("");
    // 引用しない値のキー名
    const citingOmitKeys = ["start_time", "end_time"];

    // clientData の状態を管理する
    const [clientData, setClientData] = useState({});

    // inputの内hiddenの物のキー名
    const inputHiddenList = ["company_id", "office_id", "user_id", "client_id","client_name"];
    // clientDataの内DB登録に必要な要素のみ抽出して管理します
    // const [clientInput, setClientInput] = useState({});

    // データベースから取得したユーザの名前を管理する
    const [users, setUsers] = useState([]);

    // 日付の重複確認用の日付のリストを格納する
    const dateList = useRef([]);

    // 優先順位の入力対象たる値のキー名
    const priorityColumnList = ["attendee_affiliation", "attendee_name"];

    // 日付の重複時のメッセージを管理する
    const [dateMessage, setDateMessage] = useState("");

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        control,
        clearErrors
    } = useForm();

    // 利用者情報を取得しステート clientData にセットする
    const getClientData = async () => {
        const res = await api.get(`clients/office/${client}`);
        if (res.data.success) {
            const clientResult = res.data.result;
            setClientData({
                company_id: clientResult.company_id,
                office_id: clientResult.office_id,
                user_id: clientResult.user_id,
                user_name: clientResult.user_name,
                client_id: clientResult.client_id,
                client_name: clientResult.is_child ? clientResult.child_name : clientResult.client_name,
                client_birthday: clientResult.is_child ? clientResult.child_birthday : clientResult.client_birthday
            });
        }
    };

    // 担当者ユーザ情報を取得しステート usersResult にセットする
    const getUsersData = async () => {
        const url = "users";
        const res = await api.get(url);
        if (res.data.success) {
            const usersResult = res.data.result.map((user) => ({
                user_id: user.user_id,
                user_name: user.user_name
            }));
            setUsers(usersResult);
        }
    };

    // 指定した利用者に紐づくサービス担当者会議レコードの開催年月日dateカラムのデータを取得する
    const getDateList = async (clientId) => {
        const url = `service_personnel_meetings_get_date/${clientId}`;
        const res = await api.get(url);
        if (res.data.success) {
            const result = res.data.result.map((item) => {
                return item.date;
            });
            dateList.current = result;
        }
        // データ取得時に本日の日付がすでに作成されていないかを判定
        setDateMessage(dateRegisteringErrorMessage(today, clientData?.client_birthday, dateList.current));
    };

    // 作成ボタン押下時
    const onSubmit = async (data) => {
        if (dateMessage !== "") {
            swal({
                icon: "warning",
                title: dateMessage,
                text: "作成内容を見直してください",
                timer: 2000
            });
            return;
        }

        // 確認のアラートの初期値
        // ifを通るため初期値はtrue
        let confirm = true;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "作成しますか?",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            } else {
                confirm = false;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // 入力したdataの連想配列から値が未入力の物を取り除きます
        const inputDataTrimmedEmpty = leaveOutEmptyPriority(data, SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COLUMN_NAMES, SERVICE_PERSONNEL_MEETING_MAX_PRIORITY_COUNT);

        // ServicePersonnelMeetingControllerに渡す値をセット
        const servicePersonnelMeetingData = {
            ...inputDataTrimmedEmpty,
            ...clientInput,
        };

        // 作成
        const url = "service_personnel_meetings";
        const res = await api.post(url, servicePersonnelMeetingData);
        if (res.status === 422) {
            setServicePersonnelMeeting({
                ...servicePersonnelMeeting,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            // 新規作成成功dialogを表示してサービス担当者会議記録一覧画面に戻ります
            swal(res.data.message, res.data.result.client_name, "success").then(() => navigate(backLink));
        }
    };

    // clientDataの内DB登録に必要な要素のみ抽出して管理します
    const clientInput = useMemo(() => {
        if (Object.keys(clientData).length > 0) {
            return Object.assign(...inputHiddenList.map(hiddenKey => ({[hiddenKey]: clientData[hiddenKey]})));
        }
        return {};
    }, [Object.keys(clientData).length]);

    // 画面初期表示時に情報を取得する
    useEffect(() => {
        getClientData();
        getUsersData();
    }, []);

    // 画面初期表示時に情報を取得完了した場合
    useEffect(() => {
        if (
            Object.keys(servicePersonnelMeeting).length > 0 &&
            Object.keys(users).length > 0 &&
            Object.keys(clientData).length > 0
        ) {
            getDateList(client);
        }
    }, [users, servicePersonnelMeeting, clientData]);

    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change" && name === "date") {
                setDateMessage(dateRegisteringErrorMessage(value.date, clientData?.client_birthday, dateList.current));
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <HeaderPageTitle closePath={backLink} >{SERVICE_PERSONNEL_MEETING_PAGE_NAME}作成</HeaderPageTitle>
                    <FormProvider {...{register, formState: {errors}, setValue, getValues, watch, control, clearErrors}}>
                        {/* 以前作成した情報を入力欄に引用するボタン */}
                        <CiteButton
                            getRecentlyCreated={async () => await getServicePersonnelMeeting(id)}
                            rejectKeys={citingOmitKeys}
                            setCite={setCiting} />
                        {/* 画面初期表示時の情報が取得完了していれば全情報の読み込み完了 */}
                        {(Object.keys(users).length > 0 && Object.keys(clientData).length > 0) ? (
                            <div className='card-body'>
                                <p className='fs-6 pt-1 mb-5'>
                                    <RequiredMark />
                                    <span className='text-secondary'>は必須項目です</span>
                                </p>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='date'>
                                            開催年月日
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='date'
                                            type='date'
                                            min={clientData?.client_birthday}
                                            max={MAX_DATE}
                                            className='form-control mb-1'
                                            defaultValue={today}
                                            {...register("date", {
                                                required: {
                                                    value: true,
                                                    message: "*開催年月日を入力して下さい"
                                                }
                                            })}
                                        />
                                        {/* 日付の重複によるエラーメッセージ */}
                                        <small className='text-danger'>{dateMessage && dateMessage}</small>
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="date" formData={servicePersonnelMeeting} />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='client_name'>
                                            利用者名
                                        </label>
                                        <div className='form-control mb-1'>{clientData?.client_name}</div>
                                    </div>
                                    <div className='form-group mb-5'>
                                        <label className='fw-bold mb-1'>
                                            相談支援専門員氏名
                                            <RequiredMark />
                                        </label>
                                        <Controller
                                            name='user_name'
                                            defaultValue={clientData?.user_name ?? ""}
                                            control={control}
                                            rules={{ required: "*相談支援専門員氏名を選択してください。" }}
                                            render={({ field, fieldState }) => (
                                                <Select
                                                    {...field}
                                                    value={field.value}
                                                    className='form-control mb-1'>
                                                    <MenuItem key={0} value='' disabled>
                                                        {Object.keys(users).length === 0 ?
                                                            'ユーザが登録されていません'
                                                            :
                                                            '選択して下さい'
                                                        }
                                                    </MenuItem>
                                                    {users.map((user) => (
                                                        <MenuItem key={user.user_id} value={user.user_name}>
                                                            {user.user_name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="user_name" formData={servicePersonnelMeeting} />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='start_time'>
                                            開催開始時間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='start_time'
                                            type='time'
                                            className='form-control mb-1'
                                            {...register("start_time", {
                                                required: {
                                                    value: true,
                                                    message: "*開催開始時間を入力して下さい"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="start_time" formData={servicePersonnelMeeting} />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='end_time'>
                                            開催終了時間
                                            <RequiredMark />
                                        </label>
                                        <input
                                            id='end_time'
                                            type='time'
                                            className='form-control mb-1'
                                            {...register("end_time", {
                                                required: {
                                                    value: true,
                                                    message: "*開催終了時間を入力して下さい"
                                                }
                                            })}
                                        />
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="end_time" formData={servicePersonnelMeeting} />
                                    </div>
                                    <TextInputForm
                                        inputLabelID='venue'
                                        inputLabelName='開催場所'
                                        defaultValue=''
                                        requiredFlag={true}
                                        formData={servicePersonnelMeeting}
                                    />
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1'>
                                            区分
                                            <RequiredMark />
                                        </label>
                                        <br />
                                        <Controller
                                            name='division'
                                            defaultValue=''
                                            control={control}
                                            rules={{ required: "*区分を選択して下さい" }}
                                            render={({ field, fieldState }) => (
                                                <RadioGroup {...field} className='mb-4'>
                                                    <FormControlLabel
                                                        value='1'
                                                        control={<Radio checked={field.value == 1 ? true : false} />}
                                                        label='サービス利用支援'
                                                    />
                                                    <FormControlLabel
                                                        value='2'
                                                        control={<Radio checked={field.value == 2 ? true : false} />}
                                                        label='継続サービス利用支援'
                                                    />
                                                </RadioGroup>
                                            )}
                                        />
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="division" formData={servicePersonnelMeeting} />
                                    </div>
                                    {/* 項目数が可変な入力項目 */}
                                    <MultipleInputForms
                                        maxPriorityCount={MAX_PRIORITY_COUNT}
                                        priorityColumnList={priorityColumnList}
                                        priorityFormData={servicePersonnelMeeting}
                                        PriorityInputRows={ServicePersonnelMeetingPriorityInputRows}
                                        citing={citing} />
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='considered_item'>
                                            検討した項目
                                            <RequiredMark />
                                        </label>
                                        <textarea
                                            id='considered_item'
                                            className='form-control mb-1'
                                            {...register("considered_item", {
                                                required: {
                                                    value: true,
                                                    message: "*検討した項目を入力して下さい"
                                                }
                                            })} />
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="considered_item" formData={servicePersonnelMeeting} />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='considered_detail'>
                                            検討した内容
                                            <RequiredMark />
                                        </label>
                                        <textarea
                                            id='considered_detail'
                                            className='form-control mb-1'
                                            {...register("considered_detail", {
                                                required: {
                                                    value: true,
                                                    message: "*検討した内容を入力して下さい"
                                                }
                                            })} />
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="considered_detail" formData={servicePersonnelMeeting} />
                                        </div>
                                        <div className='form-group mb-4'>
                                            <label className='fw-bold mb-1' htmlFor='considered_result'>
                                                検討した結果
                                                <RequiredMark />
                                            </label>
                                            <textarea
                                                id='considered_result'
                                                className='form-control mb-1'
                                                {...register("considered_result", {
                                                    required: {
                                                        value: true,
                                                        message: "*検討した結果を入力して下さい"
                                                    }
                                                })} />
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="considered_result" formData={servicePersonnelMeeting} />
                                    </div>
                                    <div className='form-group mb-4'>
                                        <label className='fw-bold mb-1' htmlFor='other'>
                                            その他
                                            <RequiredMark />
                                        </label>
                                        <textarea
                                            id='other'
                                            className='form-control mb-1'
                                            {...register("other", {
                                                required: {
                                                    value: true,
                                                    message: "*その他を入力して下さい"
                                                }
                                            })} />
                                        {/* react-hook-formとサーバ側のバリデーションによるエラーメッセージ */}
                                        <InputValidationErrorMessageArea labelID="other" formData={servicePersonnelMeeting} />
                                    </div>
                                    <div className='form-group mb-3 d-flex'>
                                        <BackButton path={backLink} />
                                        <Button type='submit' variant='contained' color='primary'>
                                            作成
                                        </Button>
                                    </div>
                                </form>
                            </div>
                        ) :
                            <p>読み込み中です</p>
                        }
                    </FormProvider>
                </div>
            </div>
        </div>
    );
}