import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import CloseButton from "./CloseButton";

// 表示している画面の名前を表示するヘッダ部分のスタイルの定義
const headerTitleStyles = makeStyles((theme) =>
    createStyles({
        title: {
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
        }
    })
);

// 画面上部に表示対象の項目名を記載するヘッダ
export default function HeaderPageTitle(props) {
    // 定義したスタイルを利用する設定
    const classesHeader = headerTitleStyles();

    const homePath = "/home";
    // props から閉じるボタン押下時の遷移先のpathと実行したい処理を分割代入
    // ※遷移先closePathの規定値はhome画面
    const { closePath = homePath, onClickClose =e=>e, children } = props;

    return(
        <div className='card-header d-flex'>
            <h1 className={classesHeader.title}>{children}</h1>
            <CloseButton path={closePath} onClickAction={(e) => onClickClose(e)} />
        </div>
    );
}
