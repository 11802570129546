import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDeletedDataList from "../../components/ClientSelectToDeletedDataList";

// 見出しの配列定義
const headers = ["計画案作成日"];

export default function ProposedPlan_1Deleted() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDeletedDataList pageName='サービス等利用計画案' targetURL='proposed_plan_1' headerDataList={headers} clientID={client} />;
}