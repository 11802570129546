import React from "react";
import { TableCell, Select, MenuItem } from "@material-ui/core";

//内容の配列定義
const scheduleNumbers = ["　", "計", "モ"];
const months = ["04", "05", "06", "07", "08", "09", "10", "11", "12", "01", "02", "03"];

const maxThisYearColumnNo = 9;
// 予定表部分の表示用データ作成
export default function ScheduleRegisterTableInputSelect(props) {
    const { data, columnNo, clientId, targetYear, user, postData, dispatch } = props;
    const month = `${months[columnNo]}-01`;

    const targetMonth = columnNo < maxThisYearColumnNo ? `${targetYear}-${month}` : `${targetYear + 1}-${month}`; // 指定月用の値を作成

    // reducerのaction.typeの値を作成
    const classifyType = (post, insert) => {
        if (insert.id) {
            // DBにデータ有り
            const existPost = post.filter((value) => {
                return value?.id === insert.id;
            });
            if (existPost.length > 0) {
                // reducerに要素が存在
                // 上書き
                return "updatePostData";
            } else {
                // 挿入
                // reducerに要素がない
                return "createPostData";
            }
        } else {
            // DBにデータなし
            const existInsert = post.filter((value) => {
                return (
                    value?.client_id === insert.client_id &&
                    value?.target_month === insert.target_month &&
                    value?.column_no === insert.column_no
                );
            });

            if (insert.schedule_no === 0) {
                // 対象要素削除
                return "removeInsertData";
            } else {
                if (existInsert.length > 0) {
                    // 要素更新
                    return "updatePostData";
                } else {
                    // 要素追加
                    return "createPostData";
                }
            }
        }
    };

    // プルダウンの値変更時
    const handleOnchangeScheduleId = (e) => {
        e.preventDefault();
        const val = e.target.value;

        // reducerに渡す値を作成
        const insertData = {
            company_id: user.company_id,
            office_id: user.office_id,
            user_id: user.user_id,
            client_id: clientId,
            id: data.id,
            schedule_no: val,
            column_no: columnNo,
            target_month: targetMonth
        };

        const typeName = classifyType(postData, insertData);
        dispatch({ type: typeName, data: insertData });
    };

    return (
        <TableCell className='p-1 text-center'>
            <Select name='scheduleNo' defaultValue={data.schedule_no} onChange={(e) => handleOnchangeScheduleId(e)}>
                {scheduleNumbers.map((scheduleNumber, scheduleNumberIndex) => (
                    <MenuItem key={`scheduleNumberIndex_${scheduleNumberIndex}`} value={scheduleNumberIndex}>
                        {scheduleNumber}
                    </MenuItem>
                ))}
            </Select>
        </TableCell>
    );
}
