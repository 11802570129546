import insertStr from "./insertStr";
// 目的の情報を表示する画面を指すURL文字列からapi通信用のURLを生成して返します
export default function getApiUrlFromTargetUrl(targetURL='monitorings_1') {
        const underBarIndex = targetURL.lastIndexOf('_');

        if (underBarIndex !== 1 && targetURL.match(/\d$/)) {
            // 画面表示addressであるtargetURLの末尾が数字であり且つURL文字列内に'_'があらばその手前に's'を追加します
            // (例: 'monitoring_1'-> 'monitorings_1')
            return insertStr(targetURL, underBarIndex, 's');
        } else {
            return targetURL + 's'; // 末尾に's'を追加します
        }
    };