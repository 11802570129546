import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";
import dateFormat from "../../common/dateFormat";

import { SUPPORT_LEVEL_LIST } from "../../common/constants";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

const residence_types = { 1: "単身", 2: "同居有", 3: "GH", 4: "入所施設", 5: "医療機関", 6: "その他" };
const genders = { 1: "男性", 2: "女性" };
const disability_level_1_list = { 1: "1級", 2: "2級", 3: "3級", 4: "4級", 5: "5級", 6: "6級" };
const disability_level_2_list = { 1: "A1", 2: "A2", 3: "A3", 4: "B1", 5: "B2" };
const disability_level_3_list = { 1: "1級", 2: "2級", 3: "3級" };
const support_law_list = { 1: "申請中", 2: "有" };
const pension_types = { 0: "未受給または申請中", 1: "基礎", 2: "厚生" };
const pension_level_list = { 1: { 1: "1級", 2: "2級" }, 2: { 1: "1級", 2: "2級", 3: "3級" } };
const other_income_list = { 1: "生活保護", 9: "その他手当" };
const work_list = { 1: "就労継続支援A型", 2: "就労継続支援B型", 3: "一般就労", 4: "障害者雇用" };
const medical_list = { 1: "障害者医療証", 2: "自立支援医療", 3: "生活保護（医療補助）" };
const frequency_list = {
    "01": "1ヶ月毎",
    "02": "2ヶ月毎",
    "03": "3ヶ月毎",
    "04": "4ヶ月毎",
    "05": "5ヶ月毎",
    "06": "半年毎",
    12: "１年毎",
}; // issue454
const reference_month_list = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];

export default function ClientDeletedShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //表示対象の利用者のId
    const { id } = useParams();

    // 表示するclient の状態を管理する
    const [clientShowData, setClientShowData] = useState([]);

    //一覧情報を取得しステート client にセットする
    const getClientData = async (id) => {
        const url = `clients/${id}/deleted`;
        const res = await api.get(url);
        setClientRow(res.data.result);
    };

    // ステートに値をセット
    const setClientRow = (client) => {
        const clientGender = genders[client.client_gender];
        const residenceType = residence_types[client.residence_type];

        const clientBirthdayFormat = dateFormat(client.client_birthday, true);
        const clientBirthday = clientBirthdayFormat.format_date;

        const childGender = genders[client.child_gender];

        const childBirthdayFormat = dateFormat(client.child_birthday, true);
        const childBirthday = childBirthdayFormat.format_date;

        let disabilityLevel1 = "";
        if (client.disability_1) {
            disabilityLevel1 = disability_level_1_list[client.disability_level_1];
        }

        let disabilityLevel2 = "";
        if (client.disability_2) {
            disabilityLevel2 = disability_level_2_list[client.disability_level_2];
        }

        let disabilityLevel3 = "";
        if (client.disability_3) {
            disabilityLevel3 = disability_level_3_list[client.disability_level_3];
        }

        const supportLaw = support_law_list[client.support_law];
        const pensionType = pension_types[client.pension_type];

        let pensionLevel = "";
        if (client.pension_type) {
            pensionLevel = pension_level_list[client.pension_type][client.pension_level];
        }

        const startDateFormat = dateFormat(client.start_date, true);
        const startDate = startDateFormat.format_date;

        const otherIncome = other_income_list[client.other_income];
        const clientWork = work_list[client.work];

        let employmentInsurance = "";
        if (client.employment_insurance) {
            employmentInsurance = "有";
        }

        let welfarePension = "";
        if (client.welfare_pension) {
            welfarePension = "有";
        }

        let healthInsurance = "";
        if (client.health_insurance) {
            healthInsurance = "有";
        }

        const clientMedical = medical_list[client.medical];

        let medicalOther = "";
        if (client.medical_other) {
            medicalOther = "有";
        }

        const startMonthFormat = dateFormat(client.start_month, true, "Y-m");
        const startMonth = startMonthFormat.format_date;

        const supportLevel = SUPPORT_LEVEL_LIST[client.support_level]; // issue546

        const frequency = client.frequency ? frequency_list[client.frequency] : ""; // issue454
        const referenceMonth = client.reference_month ? `${reference_month_list[client.reference_month]}月` : "";

        setClientShowData([
            { label: "会社", value: client.company_name },
            { label: "事業所", value: client.office_name },
            { label: "担当職員", value: client.user_name },
            { label: "利用者ID", value: client.client_id },
            { label: "利用者氏名", value: client.client_name },
            { label: "利用者氏名（カナ）", value: client.client_ruby },
            { label: "性別", value: clientGender },
            { label: "生年月日", value: clientBirthday },
            { label: "電話番号", value: client.client_tel },
            { label: "緊急連絡先", value: client.emergency_tel },
            { label: "郵便番号", value: client.client_zip_code },
            { label: "住所１", value: client.client_address1 },
            { label: "住所２", value: client.client_address2 },
            { label: "住所３", value: client.client_address3 },
            { label: "行政区分", value: client.political_jurisdiction_name },
            { label: "住所区分", value: residenceType },
            { label: "その他の内容", value: client.residence_type_detail },
            { label: "児童氏名", value: client.child_name },
            { label: "児童氏名（カナ）", value: client.child_ruby },
            { label: "児童生年月日", value: childBirthday },
            { label: "児童続柄", value: client.relationship },
            { label: "児童性別", value: childGender },
            { label: "障害支援区分", value: supportLevel }, // issue546
            { label: "身体障害者手帳", value: disabilityLevel1 },
            { label: "療育手帳", value: disabilityLevel2 },
            { label: "精神障害手帳", value: disabilityLevel3 },
            { label: "障害・または疾患名", value: client.disability_name },
            { label: "総合支援法", value: supportLaw },
            { label: "障害年金", value: pensionType },
            { label: "障害年金（級）", value: pensionLevel },
            { label: "障害年金受給者番号", value: client.recipient_no_1 },
            { label: "障害年金支給開始日", value: startDate },
            { label: "障害福祉サービス受給者証番号", value: client.recipient_no_2 },
            { label: "地域相談支援受給者番号", value: client.recipient_no_3 },
            { label: "通所受給者番号", value: client.recipient_no_4 },
            { label: "その他収入", value: otherIncome },
            { label: "その他収入の内容", value: client.other_income_detail },
            { label: "就労", value: clientWork },
            { label: "勤務先", value: client.workplace },
            { label: "雇用保険", value: employmentInsurance },
            { label: "厚生年金", value: welfarePension },
            { label: "健康保険", value: healthInsurance },
            { label: "医療", value: clientMedical },
            { label: "医療その他", value: medicalOther },
            { label: "医療その他の内容", value: client.medical_other_detail },
            { label: "モニタリング期間（開始年月）", value: startMonth },
            { label: "モニタリング基準月", value: referenceMonth },
            { label: "モニタリング頻度", value: frequency }, // issue454
        ]);
    };

    // 閉じるボタンをクリックしたら、【削除済み】利用者情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();
        navigate(`/client_deleted`);
    };

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        getClientData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>【削除済み】利用者情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={clientShowData}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
