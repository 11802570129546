import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Button, Select, MenuItem } from "@material-ui/core";
import { api } from "../../api/api";

const taskList = { 1: "面談", 2: "モニタリング", 3: "電話対応", 4: "連絡事項" };
const inputList = ["id", "date", "start_time", "end_time", "task", "detail"];

export default function ReportDataTableRow(props) {
    const [canEdit, setCanEdit] = useState(false); // 編集フラグ
    const [editData, setEditData] = useState(null); // 編集フラグ
    //定義したスタイルを利用するための設定
    // 選択されたクライアントのidを分割代入
    const {
        id,
        date,
        startTime,
        endTime,
        task,
        detail,
        handleOnClickDelete,
        getClientReportsAsc,
        getClientReportsDesc,
        selectedClient,
        isActive,
        setIsActive,
    } = props;
    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        trigger,
        control,
        watch,
    } = useForm();
    useEffect(() => {
        setValue("id", id, { shouldValidate: false });
        setValue("date", date, { shouldValidate: false });
    }, []);
    // dateの入力値が変わる度に日付の重複を確認する関数を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                trigger();
                const data = getValues();
                setEditData(data);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    // 更新
    const handleOnClickUpdate = async (e, data) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        if (Object.keys(errors).length > 0) {
            await swal({
                icon: "error",
                title: "データに誤りがあります",
            });
            return;
        }
        if (data == null) {
            await swal({
                icon: "info",
                title: "データに変更がありません",
            });
            return;
        }

        // 確認のための変数を定義
        let confirm = false;

        // 確認画面を表示
        await swal({
            icon: "info",
            title: "データを更新しますか？",
            buttons: ["キャンセル", "実行"],
        }).then((value) => {
            if (value) {
                confirm = true;
            }
        });

        // キャンセルの場合は処理を中断する
        if (!confirm) {
            return;
        }

        //物理削除
        const url = `reports_edit`;
        const res = await api.post(url, data);
        // 成否の確認
        if (res.data.success) {
            // 削除成功時
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                setCanEdit(false);
                setIsActive(true);
                getClientReportsAsc(selectedClient);
                getClientReportsDesc(selectedClient);
                setEditData(null);
            });
        }
    };
    const handleOnClickEdit = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setIsActive(false);
        setCanEdit(true);
    };

    const handleOnClickCancel = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        setCanEdit(false);
        setIsActive(true);
        setEditData(null);
    };
    return (
        <>
            {canEdit ? (
                <TableRow>
                    <TableCell align='center'>
                        <input type='hidden' name='id' value={id} {...register("id")} />
                        <input type='date' defaultValue={date} disabled={true} {...register("date")} />
                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                        <small className='text-danger'>{errors.date && errors.date?.message}</small>
                    </TableCell>
                    <TableCell align='center' size={"small"}>
                        <input
                            type='time'
                            className='form-control mb-1'
                            defaultValue={startTime}
                            {...register("start_time", {
                                // required: {
                                //     value: true,
                                //     message: "開始時間を入力してください。"
                                // }
                            })}
                        />
                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                        <small className='text-danger'>{errors.start_time && errors.start_time?.message}</small>
                        ～
                        <input
                            type='time'
                            className='form-control mb-1'
                            defaultValue={endTime}
                            {...register("end_time", {
                                // required: {
                                //     value: true,
                                //     message: "終了時間を入力してください。",
                                // },
                            })}
                        />
                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                        <small className='text-danger'>{errors.end_time && errors.end_time?.message}</small>
                    </TableCell>
                    <TableCell align='center'>
                        <Controller
                            name='task'
                            className='form-control mb-1'
                            defaultValue={task}
                            control={control}
                            rules={{ required: "分類を選択してください。" }}
                            render={({ field, fieldState }) => (
                                <Select {...field} value={field.value}>
                                    {Object.keys(taskList).map((key, index) => (
                                        <MenuItem key={index} value={key}>
                                            {taskList[key]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                        />
                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                        <small className='text-danger'>{errors.task && errors.task?.message}</small>
                    </TableCell>
                    <TableCell align='center' className='w-50'>
                        <textarea
                            className='form-control mb-1'
                            defaultValue={detail}
                            {...register("detail", {
                                required: {
                                    value: true,
                                    message: "*内容を入力してください。",
                                },
                                validate: {
                                    message: (value) => {
                                        const trimValue = value.trim();
                                        if (!trimValue) {
                                            return `*内容を入力してください。（※改行や空白文字のみ入力されています。）`;
                                        } else {
                                            return null;
                                        }
                                    },
                                },
                            })}
                        />
                        {/* react-hook-formのバリデーションによるエラーメッセージ */}
                        <small className='text-danger'>{errors.detail && errors.detail?.message}</small>
                    </TableCell>
                    <TableCell>
                        <Button
                            variant='contained'
                            color='primary'
                            className='m-2'
                            onClick={(e) => handleOnClickUpdate(e, editData)}>
                            更新
                        </Button>
                        <Button variant='contained' className='m-2' onClick={handleOnClickCancel}>
                            中止
                        </Button>
                    </TableCell>
                    <TableCell>-</TableCell>
                </TableRow>
            ) : (
                <TableRow className={`${isActive ? "" : "bg-light"}`}>
                    <TableCell align='center'>{date}</TableCell>
                    <TableCell align='center' size={"small"}>
                        {startTime === null ? "" : startTime.substr(0, 5)}～
                        {endTime === null ? "" : endTime.substr(0, 5)}
                    </TableCell>
                    <TableCell align='center'>{taskList[task]}</TableCell>
                    <TableCell align='center' className='w-50'>
                        {detail}
                    </TableCell>
                    <TableCell>
                        {isActive ? (
                            <Button variant='contained' color='primary' onClick={handleOnClickEdit}>
                                編集
                            </Button>
                        ) : (
                            "-"
                        )}
                    </TableCell>
                    <TableCell>
                        {isActive ? (
                            <Button
                                variant='contained'
                                onClick={(e) => {
                                    handleOnClickDelete(e, id);
                                }}>
                                削除
                            </Button>
                        ) : (
                            "-"
                        )}
                    </TableCell>
                </TableRow>
            )}
        </>
    );
}
