import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";
//---------------------------issue{No.103} start-----------------------------
import { usePostalJp } from "use-postal-jp";
//---------------------------issue{No.103} end-------------------------------
//---------------------------issue{No.75} start-----------------------------
import { useForm, Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";
//---------------------------issue{No.75} end-------------------------------

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1)
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

//office の初期状態
const initOffice = { success: true };

//---------------------------issue{No.75} start-----------------------------
// 事業名を定義
const businessNameList = ["相談支援", "就労継続支援A型", "就労継続支援B型", "就労移行支援"];
//---------------------------issue{No.75} end-------------------------------

//---------------------------issue{No.103} start-----------------------------
// 郵便番号が不正の場合に表示するメッセージ
const zipCodeErrorMessage = "存在しない郵便番号です";
//---------------------------issue{No.103} end-------------------------------

function OfficeEdit() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 編集対象の事業所情報のid
    const { id } = useParams();

    //office の状態を管理する
    const [office, setOffice] = useState(initOffice);

    //一覧情報を取得しステート offices にセットする
    const getOfficeData = async (id) => {
        const url = `offices/${id}`;
        const res = await api.get(url);
        if (res.data.success) {
            setOffice({
                ...res.data.result,
                error_list: null,
                success: res.data.success
            });
        }
    };

    // // 入力欄の値変更時
    // const handleInput = (e) => {
    //     // イベントハンドラ実行後、オブジェクトのプロパティにアクセス
    //     e.persist();
    //     // ステートに値をセット
    //     setOffice({ ...office, [e.target.name]: e.target.value });
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    //---------------------------issue{No.75} start-----------------------------
    // 一部変更
    // 更新ボタン押下時
    const officeSubmit = async (data) => {
        //---------------------------issue{No.103} start-----------------------------
        // 追記
        // 住所取得のAPI通信が行われている最中の場合
        // 処理を抜けてアラートを表示する
        if (loading) {
            swal({
                icon: "warning",
                title: "住所の取得中です",
                text: "再度お試しください",
                timer: 2000
            });
            return;
        }
        //---------------------------issue{No.103} end-------------------------------

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "保存しますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // OfficeControllerに渡す値をセット
        const officeData = {
            id: office.id,
            office_no: data.office_no,
            office_name: data.office_name,
            office_ruby: data.office_ruby,
            office_zip_code: data.office_zip_code,
            office_address1: data.office_address1,
            office_address2: data.office_address2,
            office_address3: data.office_address3,
            office_tel: data.office_tel,
            office_fax: data.office_fax,
            business_name: data.business_name,
            manager_name: data.manager_name
        };

        //更新
        const url = `offices/${office.id}`;
        const res = await api.put(url, officeData);
        if (res.status === 422) {
            setOffice({
                ...office,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            // 成功メッセージ
            swal(res.data.message, res.data.result.office_name, "success").then(() => {
                navigate("/office");
            });
        }
    };
    //---------------------------issue{No.75} end-------------------------------

    //画面初期表示時に事業所情報を取得する
    useEffect(() => {
        getOfficeData(id);
    }, []);

    //---------------------------issue{No.103} start-----------------------------
    // 追記
    // use-postal-jpの初期設定
    const [zipCode, setZipCode] = useState("");
    const [address, loading, error] = usePostalJp(zipCode, zipCode.length === 7);

    // 郵便番号による住所取得が行われるたびに、以下の副作用フックを実行
    useEffect(() => {
        const setOfficeAddress = () => {
            // addressに正しく値が取得できている場合に、tryが実行される
            try {
                // addressから、必要な情報を分割代入
                const { prefecture, address1, address2 } = address;
                // 文字列の連結
                const companyAddress = prefecture + address1 + address2;
                // react-hook-formのsetValueでaddress1を更新
                setValue("office_address1", companyAddress, {
                    shouldValidate: true
                });
            } catch {
                // 郵便番号が不正、もしくは初回の読み込みの際に値を空にする
                setValue("office_address1", "", { shouldValidate: false });
            }
        };
        // 上記の関数を実行
        setOfficeAddress();
        // addressの取得が済んで変更されたとき、この副作用フックを実行
    }, [address]);
    //---------------------------issue{No.103} end-------------------------------

    //---------------------------issue{No.75} start-----------------------------
    // 追記
    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        getValues,
        //---------------------------issue{No.558} start-----------------------------
        watch,
        trigger
        //---------------------------issue{No.558} end-------------------------------
    } = useForm();

    //---------------------------issue{No.558} start-----------------------------
    // inputの入力値が変わる度にそれぞれの項目に応じた処理を実行する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                if (name === "office_zip_code") {
                    // バリデーションを実行
                    trigger("office_zip_code");
                    // zipCodeの値を更新
                    setZipCode(value.office_zip_code);
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    //---------------------------issue{No.558} end-------------------------------

    // 以下、あらかじめinputに値を入力するための副作用フック
    useEffect(() => {
        // getValuesについて
        // 必須項目かつ、setValueでのみ値の入る項目の値がないことを条件にして、一度だけ初期値が入力されるようにしている
        if (Object.keys(office).length > 1 && !getValues("office_name")) {
            setValue("office_no", office.office_no, { shouldValidate: false });
            setValue("office_name", office.office_name, { shouldValidate: false });
            setValue("office_ruby", office.office_ruby, { shouldValidate: false });
            setValue("office_zip_code", office.office_zip_code, { shouldValidate: false });
            setValue("office_address1", office.office_address1, { shouldValidate: false });
            setValue("office_address2", office.office_address2, { shouldValidate: false });
            setValue("office_address3", office.office_address3, { shouldValidate: false });
            setValue("office_tel", office.office_tel, { shouldValidate: false });
            setValue("office_fax", office.office_fax, { shouldValidate: false });
            setValue("business_name", office.business_name, { shouldValidate: false });
            setValue("manager_name", office.manager_name, { shouldValidate: false });

            //---------------------------issue{No.558} start-----------------------------
            setZipCode(office.office_zip_code);
            //---------------------------issue{No.558} end-------------------------------
        }
    }, [office]);
    //---------------------------issue{No.75} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>事業所情報編集</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(officeSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1'>会社</label>
                                <input
                                    type='text'
                                    defaultValue={office.company_name}
                                    className='form-control'
                                    disabled='disabled'
                                />
                            </div>
                            <div className='form-group mb-3'>
                                <label className='fw-bold mb-1' htmlFor='office_no'>
                                    事業所番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字のみ、ハイフンなし）</small>
                                </label>
                                <input
                                    id='office_no'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_no", {
                                        required: {
                                            value: true,
                                            message: "*事業所番号を入力してください"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "*10桁で入力してください"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "*10桁で入力してください"
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_no && errors.office_no?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_no}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_name'>
                                    事業所名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='office_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_name", {
                                        required: {
                                            value: true,
                                            message: "*事業所名を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_name && errors.office_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_ruby'>
                                    事業所名（カナ）
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>
                                        （全角カタカナのみ、空白文字・スペース禁止）
                                    </small>
                                </label>
                                <input
                                    id='office_ruby'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_ruby", {
                                        required: {
                                            value: true,
                                            message: "*事業所名（カナ）を入力してください。"
                                        },
                                        pattern: {
                                            value: /^[ァ-ヶ]+[ァ-ヶー]*$/,
                                            message: "*全角カタカナのみで入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_ruby && errors.office_ruby?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_ruby}
                                </small>
                            </div>{" "}
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_zip_code'>
                                    郵便番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字のみ、ハイフンなし）</small>
                                </label>
                                <br />
                                {/* 住所自動入力のエラーメッセージ */}
                                <small className='text-danger'>
                                    {!loading && error && zipCode.length === 7 && "※" + zipCodeErrorMessage}
                                </small>
                                <input
                                    id='office_zip_code'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_zip_code", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を入力してください"
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        maxLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_zip_code && errors.office_zip_code?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_zip_code}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address1'>
                                    住所1
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>
                                        （都道府県、市区町村、市区町村配下）
                                    </small>
                                </label>
                                <input
                                    id='office_address1'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='郵便番号による自動入力'
                                    readOnly
                                    {...register("office_address1", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を確認してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address1 && errors.office_address1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_address1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address2'>
                                    住所2
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（丁目、番地、号）</small>
                                </label>
                                <input
                                    id='office_address2'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_address2", {
                                        required: {
                                            value: true,
                                            message: "*住所を入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address2 && errors.office_address2?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_address2}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address3'>
                                    住所3
                                    <small className='fw-normal text-secondary'>（建物名、階数、部屋番号など）</small>
                                </label>
                                <input
                                    id='office_address3'
                                    type='text'
                                    className='form-control'
                                    {...register("office_address3")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address3 && errors.office_address3?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_address3}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_tel'>
                                    電話番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                </label>
                                <input
                                    id='office_tel'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='000-000-0000'
                                    {...register("office_tel", {
                                        required: {
                                            value: true,
                                            message: "*電話番号を入力してください"
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください"
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください"
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_tel && errors.office_tel?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_tel}
                                </small>
                            </div>
                            <div className='form-group mb-3'>
                                <label className='fw-bold mb-1' htmlFor='office_fax'>
                                    FAX番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                </label>
                                <input
                                    id='office_fax'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='000-000-0000'
                                    {...register("office_fax", {
                                        required: {
                                            value: true,
                                            message: "*FAX番号を入力してください"
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください"
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください"
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_fax && errors.office_fax?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_fax}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='business_name'>
                                    事業名
                                    <RequiredMark />
                                </label>
                                <Controller
                                    name='business_name'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*事業名を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} value={field.value} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {businessNameList.map((item, index) => (
                                                <MenuItem key={index} value={item} selected={item === field.value}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.business_name && errors.business_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.business_name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='manager_name'>
                                    管理者名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='manager_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("manager_name", {
                                        required: {
                                            value: true,
                                            message: "*管理者名を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.manager_name && errors.manager_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.manager_name}
                                </small>
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    保存
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OfficeEdit;
