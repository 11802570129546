import React from "react";
import { useParams } from "react-router-dom";

import ClientSelectToDeletedDataList from "../../components/ClientSelectToDeletedDataList";

// 見出しの配列定義
const headers = ["実施年月日"];

export default function MonitoringDraftDeleted() {
    // 表示対象の利用者のId
    const {client} = useParams();

    return <ClientSelectToDeletedDataList pageName='モニタリング記録' targetURL='monitoring_draft' headerDataList={headers} clientID={client} />;
}