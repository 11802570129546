import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataShow from "../../components/DataShow";

const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
    })
);

function UserShow() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 表示対象のユーザ情報のid
    const { id } = useParams();

    // user の状態を管理する
    const [user, setUser] = useState([]);

    //一覧情報を取得しステート user にセットする
    const getUserData = async (id) => {
        const url = `users/${id}`;
        const res = await api.get(url);
        setUserRow(res.data.result);
    };

    // ステートに値をセット
    const setUserRow = (user) => {
        setUser([
            { label: "ユーザーID", value: user.user_id },
            { label: "名前", value: user.user_name },
            { label: "メールアドレス", value: user.email },
            { label: "法人番号", value: user.company_id },
            { label: "会社名", value: user.company_name },
            { label: "事業所コード", value: user.office_id },
            { label: "事業所", value: user.office_name },
            { label: "権限", value: user.authority_name },
        ]);
    };

    // 閉じるボタンをクリックしたら、ユーザ情報一覧画面に遷移する
    const handleOnClickClose = (e) => {
        e.stopPropagation();
        navigate(`/users`);
    };

    // 画面初期表示時にユーザ情報を取得する
    useEffect(() => {
        getUserData(id);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-10 col-lg-10 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>ユーザー情報</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <DataShow data={user}></DataShow>
                    </div>
                    <div className='card-footer'>
                        <Button variant='contained' onClick={(e) => handleOnClickClose(e)}>
                            閉じる
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserShow;
