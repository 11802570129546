import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Box, Card } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import DataTable from "../../components/DataTable";
import FreeWordSearch from "../../components/FreeWordSearch";
import { UserContext } from "../../providers/UserProvider";
import BackHomeButton from "../../components/BackHomeButton"; // issue508

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem",
            //---------------------------issue{No.539} end-------------------------------
        },
        buttonHeight: {
            height: "37px",
            marginBottom: "0",
            lineHeight: "37px",
        },
    })
);

//見出しの配列定義
const headers = ["法人番号", "会社名", "編集", "削除"];

//---------------------------issue{No.30} start-----------------------------
// 検索対象のカラムの配列定義
const columns = ["company_name"];
//---------------------------issue{No.30} end-------------------------------

// 会社情報用配列の初期状態
const initCompanies = { result: [], success: true };

function Company() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //companies の状態を管理する
    const [companies, setCompanies] = useState(initCompanies);

    //---------------------------issue{No.30} start-----------------------------
    // 検索された会社データを格納するステート
    const [searchedCompanies, setSearchedCompanies] = useState([]);
    //---------------------------issue{No.30} end-------------------------------

    //companies を表示行に変換したものを保持する
    const [rows, setRows] = useState([]);

    //---------------------------issue{No.463} start-------------------------------
    // ログインユーザーの情報取得
    const [userCompanyId, setUserCompanyId] = useState(""); // 会社コードステート

    const { userInfo } = useContext(UserContext);
    const userAuthorityOrigin = userInfo.user.authority; // 権限
    const userAuthority = Number(userAuthorityOrigin); // 権限（数値型）
    const userCompanyIdForState = userInfo.user.company_id; // 会社コード

    // ボタン表示フラグ
    const [displayRegisterButton, setDisplayRegisterButton] = useState(false); // 新規登録・削除
    const [displayEditButton, setDisplayEditButton] = useState(false); // 編集

    // 権限ごとにステート（ボタン表示フラグ及びログインユーザー情報）を調整(初回のみ実行)
    useEffect(() => {
        // 権限
        //DNS
        if (userAuthority < 20) {
            setDisplayRegisterButton(true);
        }

        // 会社代表
        if (userAuthority < 30) {
            setDisplayEditButton(true);
        }

        // ログインユーザー情報
        setUserCompanyId(userCompanyIdForState); // 会社コード
    }, []);
    //---------------------------issue{No.463} end-------------------------------

    //情報を取得しステート users にセットする
    const getCompaniesData = async () => {
        const res = await api.get("companies");
        setCompanies(res.data);
    };

    //  登録ボタンクリック時
    const handleOnClickRegist = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();
        // URL(company_reg)をたたく（RouterConfig.js）
        navigate(`/company_register`);
    };

    // -------------------- issue514 start --------------------
    //  復活ボタンクリック時
    const handleOnClickRestore = (e) => {
        // イベントの伝搬を中止
        e.preventDefault();
        // URL(company_deleted)をたたく（RouterConfig.js）
        navigate(`/company_deleted`);
    };
    // --------------------issue514 end--------------------

    //一覧の行クリック時、会社情報表示画面へ
    const handleOnRowClick = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // クリックした行の会社のid(key)と一致するcompaniesステートの会社情報を変数に代入
        const company = companies.result.find((company) => company.id === id);
        // companyに値が存在する（0, "", undefined, nullでない）場合
        if (company) {
            // URL(/company/show/${company.id})をたたく（RouterConfig.js）
            navigate(`/company/show/${id}`);
        }
    };

    // 一覧の編集ボタンクリック時、会社情報編集画面へ
    const handleOnClickEdit = (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();
        // URL(/company/edit/${id})をたたく（RouterConfig.js）
        navigate(`/company/edit/${id}`);
    };

    //一覧の削除ボタンをクリック時、論理削除
    const handleOnClickDelete = async (e, id) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        //論理削除
        const url = `companies/${id}`;
        const res = await api.delete(url);
        if (res.data.success) {
            // モーダルウィンドウで成功メッセージを表示
            swal(res.data.message, "", "success").then(() => {
                // 削除した会社を除外した全会社を配列化
                const newCompanies = companies.result.filter((company) => company.id !== id);
                // ステートを更新
                setCompanies({ result: newCompanies, success: true });
            });
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/home");
    };
    //---------------------------issue{No.539} end-------------------------------

    //画面初期表示時に会社情報を取得する
    useEffect(() => {
        getCompaniesData();
    }, []);

    //---------------------------issue{No.30} start-----------------------------
    // 一部変更 { 以下の、useEffect内で扱うステートを一部、companiesからsearchedCompaniesに変更した }
    //searchedCompanies に応じて、画面表示用の配列を生成
    useEffect(() => {
        if (!searchedCompanies || !companies.success) {
            return;
        }

        const newRows = searchedCompanies.map((company) => {
            //---------------------------issue{No.463} start-------------------------------
            let companyId = company.company_id; // 会社コード
            let isOther = true; // 他社フラグ

            // 自身の所属会社の場合、他社フラグをfalseに変更
            if (userCompanyId === companyId) {
                isOther = false;
            }
            //---------------------------issue{No.463} end-------------------------------
            return {
                id: company.id,
                isActive: company.deleted_at === null,
                data: {
                    company_id: company.company_id,
                    company_name: company.company_name,
                    editBtn: displayEditButton ? (
                        <Button variant='contained' color='primary' onClick={(e) => handleOnClickEdit(e, company.id)}>
                            編集
                        </Button>
                    ) : (
                        <p className={classes.buttonHeight}>-</p>
                    ),
                    // ボタン表示フラグ・他社フラグ共にtrueの場合のみボタンを表示
                    deleteBtn:
                        displayRegisterButton && isOther ? (
                            <Button variant='contained' onClick={(e) => handleOnClickDelete(e, company.id)}>
                                削除
                            </Button>
                        ) : (
                            <p className={classes.buttonHeight}>-</p>
                        ),
                },
            };
        });
        setRows(newRows);
    }, [searchedCompanies]);
    //---------------------------issue{No.30} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>会社情報一覧</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        {displayRegisterButton && (
                            <Box className='card-body' sx={{ display: "flex", width: "max-content", ml: "auto" }}>
                                <Button variant='contained' color='primary' onClick={(e) => handleOnClickRegist(e)}>
                                    新規登録
                                </Button>
                                {/* -------------------- issue514 start -------------------- */}
                                <Button className='ms-3' variant='contained' onClick={(e) => handleOnClickRestore(e)}>
                                    削除済み一覧
                                </Button>
                                {/* -------------------- issue514 end -------------------- */}
                            </Box>
                        )}
                        <FreeWordSearch
                            searchColumns={columns}
                            data={companies.result}
                            setSearchedData={setSearchedCompanies}
                        />
                        {/* テーブル部分の定義 */}
                        <DataTable
                            headers={headers}
                            rows={rows}
                            handleOnRowClick={(e, row) => handleOnRowClick(e, row.id)}
                        />
                    </Card>
                    {/* -------------------- issue508 start -------------------- */}
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackHomeButton />
                        </div>
                    </div>
                    {/* -------------------- issue508 start -------------------- */}
                </div>
            </div>
        </div>
    );
}

export default Company;
