import React, { useState, useEffect, useContext, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    Card
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
// 追記ここまで
import BackButton from "../../components/BackButton";
import { UserContext } from "../../providers/UserProvider";

import swal from "sweetalert";

import ScheduleBulkRegisterInputRow from "../../components/schedule/ScheduleBulkRegisterInputRow";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        card: {
            margin: theme.spacing(1),
            padding: "1rem",
            boxSizing: "border-box"
        },
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        },
        wrapper: {
            padding: "1rem",
            overflow: "scroll",
            maxHeight: "500px"
        }
    })
);

// ステートの初期状態
const initState = { result: [], success: false };

// reducer初期値
const initPostData = [];

const reference_month_list = [4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3];
const headers = ["", "氏名", "基準月", "頻度"];

// スケジュール登録用（日時）
const now = new Date();
const thisYear = now.getFullYear();
const nextYear = thisYear + 1;
const numThisMonth = now.getMonth() + 1;

// ディープコピーを行う関数
const deepCopy = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return obj; // オブジェクトでない場合やnullの場合はそのまま返す
    }

    if (Array.isArray(obj)) {
        // 配列の場合は要素をディープコピーして新しい配列を作成
        return obj.map((item) => deepCopy(item));
    }

    // オブジェクトの場合は各プロパティをディープコピーして新しいオブジェクトを作成
    const copiedObject = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            copiedObject[key] = deepCopy(obj[key]);
        }
    }

    return copiedObject;
};

// reducer用の関数
// 追加
const addData = (state, data) => {
    const result = [...state, ...data];
    return result;
};

// 削除
const removeData = (state, data) => {
    // 削除対象以外の要素を取り出し、配列化
    const result = state.filter((value) => {
        return value.id !== data;
    });
    return result;
};

const reducerFunc = (postData, action) => {
    //reducer関数にincrement、increment、reset処理を書く
    //どの処理を渡すかはactionを渡すことによって判断する
    switch (action.type) {
        case "addData":
            return addData(postData, action.data);
        case "removeData":
            return removeData(postData, action.data);
        case "reset":
            return initPostData;
        default:
            return false;
    }
};

export default function ScheduleBulkRegister() {
    const { userInfo } = useContext(UserContext);
    const userTableId = userInfo.user.id;
    const userId = userInfo.user.user_id;
    const userName = userInfo.user.user_name;

    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    //Schedule の状態を管理する
    const [clients, setClients] = useState(deepCopy(initState));
    const [defaultSchedules, setDefaultSchedules] = useState(deepCopy(initState));
    const [canDisplay, setCanDisplay] = useState(false);

    // コントローラー用の値管理
    const [postData, dispatch] = useReducer(reducerFunc, initPostData);

    // スケジュールデータ関連
    const getKey = (arr, val) => {
        const key = Object.keys(arr).find((key) => arr[key] === val);
        return key;
    };

    // target_month用の年数を返す
    const decideYear = (numThisMonth) => {
        const januaryColNo = 10;

        // 今月が1～3月の場合、今年の年数を返す
        if (numThisMonth < 4) {
            return thisYear;
        } else {
            // 1月以降は来年の年数を返す
            return numThisMonth < januaryColNo ? thisYear : nextYear;
        }
    };

    // 月を0埋めする
    const formatMonth = (numMonth) => {
        return ("0" + numMonth).slice(-2);
    };

    // reference_monthが今月だった場合のデータを作成
    const makeThisMonthData = (referenceMonth) => {
        const year = decideYear(numThisMonth);
        const month = formatMonth(numThisMonth);
        const targetMonth = `${year}-${month}-01`;

        const result = {
            column_no: referenceMonth,
            target_month: targetMonth
        };

        return result;
    };

    // reference_monthが今月だった場合の残りのデータを作成
    const makeRestMonthData = (referenceMonth, frequency) => {
        const max = reference_month_list.length;
        const result = [];
        for (let ii = referenceMonth; ii < max; ii += frequency) {
            const numMonth = reference_month_list[ii];
            const year = decideYear(numMonth);
            const month = formatMonth(numMonth);
            const targetMonth = `${year}-${month}-01`;

            const data = {
                column_no: ii,
                target_month: targetMonth
            };
            result.push(data);
        }
        return result;
    };

    const makeOtherMonthData = (referenceMonth, frequency, thisMonthKey) => {
        const max = reference_month_list.length;
        const result = [];
        for (let ii = referenceMonth; ii < max; ii += frequency) {
            if (Number(thisMonthKey) <= ii) {
                const numMonth = reference_month_list[ii];
                const year = decideYear(numMonth);
                const month = formatMonth(numMonth);
                const targetMonth = `${year}-${month}-01`;

                const data = {
                    column_no: ii,
                    target_month: targetMonth
                };
                result.push(data);
            }
        }
        return result;
    };

    // 個別のデータを作成
    const makeIndividualData = (referenceMonth, frequency) => {
        const result = [];
        const max = reference_month_list.length;
        const thisMonthKey = getKey(reference_month_list, numThisMonth);
        const thisMonthIsReferenceMonth = numThisMonth === reference_month_list[referenceMonth]; // 今月がreferenceMonthか

        // 今月が基準月の場合、resultにデータを追加
        if (thisMonthIsReferenceMonth) {
            // 今月のデータを作成し、resultを更新する
            const thisMonthData = makeThisMonthData(referenceMonth);
            result.push(thisMonthData);

            const addedMonth = referenceMonth + frequency;
            const existRestMonth = addedMonth < max;
            if (existRestMonth) {
                const restMonth = makeRestMonthData(addedMonth, frequency);
                result.push(...restMonth);
            }
        } else {
            const restMonthData = makeOtherMonthData(referenceMonth, frequency, thisMonthKey);
            result.push(...restMonthData);
        }

        return result;
    };

    // 登録用データ作成
    const makeScheduleData = (clientData) => {
        const referenceMonth = clientData.reference_month; // 0~9
        const frequency = Number(clientData.frequency); // 1,2,3,4,5,6,12

        // 共通データ
        const commonData = {
            id: clientData.id, // clients.id
            company_id: clientData.company_id,
            office_id: clientData.office_id,
            user_id: userId,
            client_id: clientData.client_id,
            schedule_no: 2
        };

        const individualData = makeIndividualData(referenceMonth, frequency); // 個別データ

        // 登録用データ作成
        const result = individualData.map((data) => {
            return { ...commonData, ...data };
        });

        return result;
    };
    // スケジュールデータ関連 END

    // 各利用者用コンポーネント用データ作成
    const makeClientsData = (dataArr) => {
        const result = dataArr.map((clientData) => {
            const schedule = makeScheduleData(clientData); //スケジュール作成
            const obj = {
                id: clientData.id,
                client_name: clientData.child_name ?? clientData.client_name,
                reference_month: clientData.reference_month,
                frequency: clientData.frequency,
                schedule: schedule
            };
            return obj;
        });
        return result;
    };

    // 初期データ作成
    const makeInitialData = async (id) => {
        // ユーザー担当かつ表示可能な利用者（reference_month,frequency共に値が存在する）を取得
        const res = await api.get(`clients/schedule_registrable/${id}`);
        if (res.data.success) {
            const dataArr = res.data.result;
            const clientsData = makeClientsData(dataArr); // 各利用者コンポーネントに渡すデータ
            const clientsSchedule = clientsData.map((item) => item.schedule).flat(); // 全利用者のスケジュール
            setClients(clientsData);
            setDefaultSchedules(clientsSchedule);
            dispatch({ type: "addData", data: clientsSchedule });
            setCanDisplay(true);
        }
    };

    //  登録ボタンクリック時
    const scheduleSubmit = async (e) => {
        e.preventDefault();
        let confirm = false;

        // 確認のアラートを表示する
        await swal({
            icon: "info",
            title: "確認",
            text: "予定を一括登録しますか？\n※選択された対象利用者の「今月から年度末までの登録済みの予定」は全て削除・上書きされます。",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        //作成
        const url = "schedules_bulk";
        const res = await api.post(url, postData);
        if (res.data.success) {
            swal(res.data.message, "", "success").then(() => {
                navigate("/schedule");
            });
        } else {
            await swal({
                title: "エラー",
                text: "問題が生じたためホーム画面に戻ります。",
                icon: "warning"
            }).then(() => {
                navigate("/home");
            });
        }
    };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickClose = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate("/schedule");
    };

    //画面初期表示時に利用者情報を取得する
    useEffect(() => {
        makeInitialData(userTableId);
    }, []);

    return (
        <div className='row justify-content-center'>
            <div className='col-md-12'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>スケジュール一括登録</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickClose(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <Card className={classes.card}>
                        <Box
                            className='card-body'
                            sx={{
                                display: "flex",
                                width: "max-content"
                            }}>
                            <h2 className='d-inline-block pe-5'>{userName}</h2>
                        </Box>
                        {/* テーブル部分の定義 */}
                        {canDisplay ? (
                            <>
                                {clients.length > 0 ? (
                                    <>
                                        <div className={classes.wrapper}>
                                            <Paper>
                                                <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                {headers.map((header, headerIndex) => (
                                                                    <TableCell key={headerIndex}>{header}</TableCell>
                                                                ))}
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {clients.map((client) => (
                                                                <ScheduleBulkRegisterInputRow
                                                                    key={client.id}
                                                                    client={client}
                                                                    dispatch={dispatch}
                                                                />
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Paper>
                                        </div>
                                        {postData.length > 0 ? (
                                            <div className='text-center my-4'>
                                                <Button
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={(e) => scheduleSubmit(e)}>
                                                    登録
                                                </Button>
                                            </div>
                                        ) : (
                                            <p className='text-center my-4'>登録可能な予定が存在しません。</p>
                                        )}
                                    </>
                                ) : (
                                    <div className={classes.wrapper}>
                                        <p>予定を登録可能な利用者が存在しません</p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className={classes.wrapper}>
                                <p>読み込み中・・・</p>
                            </div>
                        )}
                    </Card>
                    {/* -------------------- issue508 start -------------------- */}
                    <div className='card-footer'>
                        <div className='form-group mb-3 d-flex'>
                            <BackButton />
                        </div>
                    </div>
                    {/* -------------------- issue508 start -------------------- */}
                </div>
            </div>
        </div>
    );
}
