import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { useForm, Controller } from "react-hook-form";
//---------------------------issue{No.103} start-----------------------------
import { usePostalJp } from "use-postal-jp";
//---------------------------issue{No.103} end-------------------------------
import { Select, MenuItem } from "@material-ui/core";

import { Button } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import { api } from "../../api/api";
import RequiredMark from "../../components/RequiredMark";
import BackButton from "../../components/BackButton";

//スタイルの定義
const useStyles = makeStyles((theme) =>
    createStyles({
        title: {
            //---------------------------issue{No.539} start-----------------------------
            fontSize: "1.6rem",
            margin: 0,
            lineHeight: "2.45rem"
            //---------------------------issue{No.539} end-------------------------------
        }
    })
);

const initOffice = { success: true };

const businessNameList = ["相談支援", "就労継続支援A型", "就労継続支援B型", "就労移行支援"];

//---------------------------issue{No.103} start-----------------------------
// 郵便番号が不正の場合に表示するメッセージ
const zipCodeErrorMessage = "存在しない郵便番号です";
//---------------------------issue{No.103} end-------------------------------

function OfficeRegister() {
    //定義したスタイルを利用するための設定
    const classes = useStyles();

    //画面遷移用
    const navigate = useNavigate();

    // 入力値を管理する
    const [office, setOffice] = useState(initOffice);

    //companies の状態を管理する
    const [companies, setCompanies] = useState([]);

    //---------------------------issue{No.103} start-----------------------------
    const [selectedCompanyId, setSelectedCompanyId] = useState("");
    //---------------------------issue{No.103} end-------------------------------

    //会社情報を取得しステート companies にセットする
    const getCompaniesData = async () => {
        const url = "companies";
        const res = await api.get(url);
        if (res.data.success) {
            const companies = res.data.result.map((company) => {
                return {
                    label: company.company_name,
                    value: company.company_id,
                    zipCode: company.zip_code,
                    address: [company.address1, company.address2, company.address3],
                    tel: company.tel,
                    fax: company.fax
                };
            });
            setCompanies(companies);
        }
    };

    // // 入力欄の値変更時
    // const handleInput = (e) => {
    //     // イベントハンドラ実行後、オブジェクトのプロパティにアクセス
    //     e.persist();
    //     // ステートに値をセット
    //     setOffice({ ...office, [e.target.name]: e.target.value });
    // };

    //---------------------------issue{No.539} start-----------------------------
    const handleOnClickBack = (e) => {
        // イベントの伝搬を中止
        e.stopPropagation();

        navigate(-1);
    };
    //---------------------------issue{No.539} end-------------------------------

    // 登録ボタン押下時
    const officeSubmit = async (data) => {
        //---------------------------issue{No.103} start-----------------------------
        // 追記
        // 住所取得のAPI通信が行われている最中の場合
        // 処理を抜けてアラートを表示する
        if (loading) {
            swal({
                icon: "warning",
                title: "住所の取得中です",
                text: "再度お試しください",
                timer: 2000
            });
            return;
        }
        //---------------------------issue{No.103} end-------------------------------

        // 確認のアラートを表示する
        let confirm = false;
        await swal({
            icon: "info",
            title: "確認",
            text: "登録しますか？",
            buttons: true
        }).then((result) => {
            if (result) {
                confirm = true;
            }
        });

        // キャンセル処理
        if (!confirm) {
            return;
        }

        // OfficeControllerに渡す値をセット
        const officeData = {
            company_id: data.company_id,
            office_no: data.office_no,
            office_name: data.office_name,
            office_ruby: data.office_ruby,
            office_zip_code: data.office_zip_code,
            office_address1: data.office_address1,
            office_address2: data.office_address2,
            office_address3: data.office_address3,
            office_tel: data.office_tel,
            office_fax: data.office_fax,
            business_name: data.business_name,
            manager_name: data.manager_name
        };

        //登録
        const url = "offices";
        const res = await api.post(url, officeData);
        if (res.status === 422) {
            setOffice({
                ...office,
                error_list: res.data.result,
                success: res.data.success
            });
        }
        if (res.data.success) {
            swal(res.data.message, res.data.result.office_name, "success").then(() => {
                navigate("/office");
            });
        }
    };

    // const handleSelectCompany = (e) => {
    //     setOffice({ ...office, [e.target.name]: e.target.value });
    //     console.log(office);
    // };

    //画面初期表示時に会社情報を取得する
    useEffect(() => {
        getCompaniesData();
    }, []);

    // react-hook-formの使用する機能を宣言
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        watch,
        control
    } = useForm();

    // Watanabe { DropDownListの初期値をステートに格納するための副作用フックを追加 }
    useEffect(() => {
        if (companies.length > 0) {
            setOffice({ ...office, company_id: companies[0].value });
        }
    }, [companies]);

    // データ取得後に、フォームの初期値の入力を行う副作用フック
    useEffect(() => {
        if (companies.length === 1) {
            setValue("company_id", companies[0].value, { shouldValidate: true });
        } else {
            setValue("company_id", "", { shouldValidate: false });
        }
    }, [companies]);

    //---------------------------issue{No.103} start-----------------------------
    // use-postal-jpの初期設定
    const [zipCode, setZipCode] = useState("");
    const [address, loading, error] = usePostalJp(zipCode, zipCode.length === 7);

    // 郵便番号による住所取得が行われるたびに、以下の副作用フックを実行
    useEffect(() => {
        const setOfficeAddress = () => {
            // addressに正しく値が取得できている場合に、tryが実行される
            try {
                // addressから、必要な情報を分割代入
                const { prefecture, address1, address2 } = address;
                // 文字列の連結
                const companyAddress = prefecture + address1 + address2;
                // react-hook-formのsetValueでaddress1を更新
                setValue("office_address1", companyAddress, {
                    shouldValidate: true
                });
            } catch {
                // 郵便番号が不正、もしくは初回の読み込みの際に値を空にする
                setValue("office_address1", "", { shouldValidate: false });
            }
        };
        // 上記の関数を実行
        setOfficeAddress();
        // addressの取得が済んで変更されたとき、この副作用フックを実行
    }, [address]);

    // 住所などのinputに会社情報を入力する関数
    const companyDataInput = async (selectedCompanyId) => {
        const check = document.getElementById("same_checkbox").checked;
        if (!selectedCompanyId) {
            selectedCompanyId = await getValues("company_id");
        }
        if (selectedCompanyId && check) {
            const selectedCompany = companies.filter((company) => company.value === selectedCompanyId);
            const companyZipCode = selectedCompany[0].zipCode;
            const companyAddress1 = selectedCompany[0].address[0];
            const companyAddress2 = selectedCompany[0].address[1];
            const companyAddress3 = selectedCompany[0].address[2];
            const companyTel = selectedCompany[0].tel;
            const companyFax = selectedCompany[0].fax;
            setValue("office_zip_code", companyZipCode, {
                shouldValidate: true
            });
            setValue("office_address1", companyAddress1, {
                shouldValidate: true
            });
            setValue("office_address2", companyAddress2, {
                shouldValidate: true
            });
            setValue("office_address3", companyAddress3, {
                shouldValidate: true
            });
            setValue("office_tel", companyTel, {
                shouldValidate: true
            });
            setValue("office_fax", companyFax, {
                shouldValidate: true
            });

            //---------------------------issue{No.558} start-----------------------------
            setZipCode(companyZipCode);
            //---------------------------issue{No.558} end-------------------------------
        }
        if (!check) {
            setValue("office_zip_code", "", {
                shouldValidate: false
            });
            setValue("office_address1", "", {
                shouldValidate: false
            });
            setValue("office_address2", "", {
                shouldValidate: false
            });
            setValue("office_address3", "", {
                shouldValidate: false
            });
            setValue("office_tel", "", {
                shouldValidate: false
            });
            setValue("office_fax", "", {
                shouldValidate: false
            });

            //---------------------------issue{No.558} start-----------------------------
            setZipCode("");
            //---------------------------issue{No.558} end-------------------------------
        }
    };

    // 会社が選択されたときに、会社の法人番号を取得する
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (type === "change") {
                if (name === "company_id") {
                    setSelectedCompanyId(value.company_id);
                }
                //---------------------------issue{No.558} start-----------------------------
                if (name === "office_zip_code") {
                    // zipCodeの値を更新
                    setZipCode(value.office_zip_code);
                }
                //---------------------------issue{No.558} end-------------------------------
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    // 会社の選択が変更されたとき、会社情報を入力する関数を実行
    useEffect(() => {
        if (selectedCompanyId) {
            companyDataInput(selectedCompanyId);
        }
    }, [selectedCompanyId]);
    //---------------------------issue{No.103} end-------------------------------

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 col-lg-6 mx-auto'>
                <div className='card'>
                    <div className='card-header d-flex'>
                        <h1 className={classes.title}>事業所情報登録</h1>
                        {/* -------------------- issue539 start -------------------- */}
                        <div className='ms-auto'>
                            <button
                                className='btn btn-outline-secondary fs-3 lh-1'
                                onClick={(e) => handleOnClickBack(e)}>
                                ×
                            </button>
                        </div>
                        {/* -------------------- issue539 end -------------------- */}
                    </div>
                    <div className='card-body'>
                        <p className='fs-6 mb-4'>
                            <RequiredMark />
                            <span className='text-secondary'>は必須項目です。</span>
                        </p>
                        <form onSubmit={handleSubmit(officeSubmit)}>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='company_id'>
                                    会社
                                    <RequiredMark />
                                </label>
                                <Controller
                                    id='company_id'
                                    name='company_id'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*会社を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {companies.map((item, index) => (
                                                <MenuItem key={index} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.company_id && errors.company_id?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.company_id}
                                </small>
                            </div>
                            <div className='form-group mb-3'>
                                <label className='fw-bold mb-1' htmlFor='office_no'>
                                    事業所番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字のみ、ハイフンなし）</small>
                                </label>
                                <input
                                    id='office_no'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_no", {
                                        required: {
                                            value: true,
                                            message: "*事業所番号を入力してください"
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "*10桁で入力してください"
                                        },
                                        maxLength: {
                                            value: 10,
                                            message: "*10桁で入力してください"
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_no && errors.office_no?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_no}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_name'>
                                    事業所名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='office_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_name", {
                                        required: {
                                            value: true,
                                            message: "*事業所名を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_name && errors.office_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_name}
                                </small>
                            </div>
                            <div className='form-group mb-5'>
                                <label className='fw-bold mb-1' htmlFor='office_ruby'>
                                    事業所名（カナ）
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>
                                        （全角カタカナのみ、空白文字・スペース禁止）
                                    </small>
                                </label>
                                <input
                                    id='office_ruby'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_ruby", {
                                        required: {
                                            value: true,
                                            message: "*事業所名（カナ）を入力してください。"
                                        },
                                        pattern: {
                                            value: /^[ァ-ヶ]+[ァ-ヶー]*$/,
                                            message: "*全角カタカナのみで入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_ruby && errors.office_ruby?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_ruby}
                                </small>
                            </div>
                            <hr />
                            <div className='form-group my-4'>
                                <input
                                    id='same_checkbox'
                                    type='checkbox'
                                    name='same_checkbox'
                                    onChange={() => {
                                        companyDataInput();
                                    }}
                                />
                                <label className='fw-normal text-secondary ps-1' htmlFor='same_checkbox'>
                                    郵便番号からＦＡＸ番号までの情報を会社の情報で入力する
                                </label>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_zip_code'>
                                    郵便番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字のみ、ハイフンなし）</small>
                                </label>
                                <br />
                                {/* 住所自動入力のエラーメッセージ */}
                                <small className='text-danger'>
                                    {!loading && error && zipCode.length === 7 && "※" + zipCodeErrorMessage}
                                </small>
                                <input
                                    id='office_zip_code'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_zip_code", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を入力してください"
                                        },
                                        minLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        maxLength: {
                                            value: 7,
                                            message: "*7桁で入力してください"
                                        },
                                        pattern: {
                                            value: /^[0-9]+$/,
                                            message: "*半角数字で入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_zip_code && errors.office_zip_code?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_zip_code}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address1'>
                                    住所1
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>
                                        （都道府県、市区町村、市区町村配下）
                                    </small>
                                </label>
                                <input
                                    id='office_address1'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='郵便番号による自動入力'
                                    readOnly
                                    {...register("office_address1", {
                                        required: {
                                            value: true,
                                            message: "*郵便番号を確認してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address1 && errors.office_address1?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_address1}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address2'>
                                    住所2
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（丁目、番地、号）</small>
                                </label>
                                <input
                                    id='office_address2'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("office_address2", {
                                        required: {
                                            value: true,
                                            message: "*住所を入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address2 && errors.office_address2?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_address2}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_address3'>
                                    住所3
                                    <small className='fw-normal text-secondary'>（建物名、階数、部屋番号など）</small>
                                </label>
                                <input
                                    id='office_address3'
                                    type='text'
                                    className='form-control'
                                    {...register("office_address3")}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.office_address3 && errors.office_address3?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_address3}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='office_tel'>
                                    電話番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                </label>
                                <input
                                    id='office_tel'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='000-000-0000'
                                    {...register("office_tel", {
                                        required: {
                                            value: true,
                                            message: "*電話番号を入力してください"
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください"
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください"
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_tel && errors.office_tel?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_tel}
                                </small>
                            </div>
                            <div className='form-group mb-3'>
                                <label className='fw-bold mb-1' htmlFor='office_fax'>
                                    FAX番号
                                    <RequiredMark />
                                    <small className='fw-normal text-secondary'>（半角数字・ハイフンあり）</small>
                                </label>
                                <input
                                    id='office_fax'
                                    type='text'
                                    className='form-control mb-1'
                                    placeholder='000-000-0000'
                                    {...register("office_fax", {
                                        required: {
                                            value: true,
                                            message: "*FAX番号を入力してください"
                                        },
                                        minLength: {
                                            value: 12,
                                            message: "*12文字以上（ハイフン込み）で入力してください"
                                        },
                                        maxLength: {
                                            value: 13,
                                            message: "*13文字以下（ハイフン込み）で入力してください"
                                        },
                                        pattern: {
                                            value: /\d{2,4}-\d{2,4}-\d{4}/,
                                            message: "*半角数字・半角ハイフンで入力してください"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>{errors.office_fax && errors.office_fax?.message}</small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.office_fax}
                                </small>
                            </div>
                            <hr className='mb-5' />
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='business_name'>
                                    事業名
                                    <RequiredMark />
                                </label>
                                <Controller
                                    id='business_name'
                                    name='business_name'
                                    defaultValue={""}
                                    control={control}
                                    rules={{ required: "*事業名を選択してください。" }}
                                    render={({ field, fieldState }) => (
                                        <Select {...field} className='form-control mb-1'>
                                            <MenuItem value='' disabled>
                                                選択してください
                                            </MenuItem>
                                            {businessNameList.map((item, index) => (
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.business_name && errors.business_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.business_name}
                                </small>
                            </div>
                            <div className='form-group mb-4'>
                                <label className='fw-bold mb-1' htmlFor='manager_name'>
                                    管理者名
                                    <RequiredMark />
                                </label>
                                <input
                                    id='manager_name'
                                    type='text'
                                    className='form-control mb-1'
                                    {...register("manager_name", {
                                        required: {
                                            value: true,
                                            message: "*管理者名を入力してください。"
                                        }
                                    })}
                                />
                                {/* react-hook-formのバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {errors.manager_name && errors.manager_name?.message}
                                </small>
                                {/* サーバー側のバリデーションによるエラーメッセージ */}
                                <small className='text-danger'>
                                    {office.success ? "" : office.error_list.manager_name}
                                </small>
                            </div>
                            <div className='form-group mb-3 d-flex'>
                                <BackButton />
                                <Button type='submit' variant='contained' color='primary'>
                                    登録
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OfficeRegister;
